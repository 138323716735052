import { theme } from '$theme';
import { StylesConfig } from 'react-select';
import { Option } from './selector-element';

export type reactSelectCustomStylesProps = {
    isInvalid?: boolean;
    menuPlacement?: 'top' | 'bottom' | 'auto';
};

export const customStyles = ({
    isInvalid,
    menuPlacement
}: reactSelectCustomStylesProps): StylesConfig<Option<any>> => ({
    menu: (provided) => ({
        ...provided,
        margin: 0,
        borderRadius: 0,
    }),

    placeholder: (provided) => ({
        ...provided,
        color: isInvalid ? theme.colors.red : theme.colors.grey80,
    }),

    input: (provided) => ({
        ...provided,
        marginLeft: 0,
    }),

    valueContainer: (provided) => ({
        ...provided,
        paddingLeft: '16px',
    }),

    control: (provided) => ({
        ...provided,

        border: '2px solid #E7E5E5',
        borderRadius: 0,
        boxShadow: 'none',
        minHeight: '54px',

        '&:hover': {
            border: '2px solid #E7E5E5',
        },
    }),

    option: (provided, { isDisabled, isFocused, isSelected }) => ({
        ...provided,
        backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? theme.colors.blue
            : isFocused
            ? theme.colors.grey30
            : undefined,

        ':active': {
            ...provided[':active'],
            backgroundColor: !isDisabled
                ? isSelected
                    ? theme.colors.blue90
                    : theme.colors.grey40
                : undefined,
        },
        paddingLeft: '20px',
    }),

    clearIndicator: (provided) => ({
        ...provided,
        color: theme.colors.grey90,

        ':hover': {
            color: theme.colors.defaultText,
        }
    }),

    menuPortal: (provided) => (menuPlacement === 'auto' ? {
    } : {...provided}),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
            ...provided,
            opacity,
            transition,
            fontSize: '17px',
            marginLeft: 0,
            color: theme.colors.defaultText,
        };
    },
});
