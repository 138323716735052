import { framePrefetch } from '$shared/utils/frame/utils/frame-prefetch';
import { translationPrefetch } from '$shared/utils/translation/utils/translation-prefetch';
import { QueryClient } from '@tanstack/react-query';
import { GetServerSidePropsContext, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { IPages } from '~/lib/data-contract';
import { pagePrefetch } from './page-prefetch';

export interface PrefetchContext {
    serverContext: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>;
    httpHeaders: HeadersInit;
    page: IPages;
    queryClient: QueryClient;
}

export async function prefetchData(context: PrefetchContext): Promise<void> {
    await pagePrefetch(context);
    await framePrefetch(context);
    await translationPrefetch(context);
}
