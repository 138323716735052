import React from 'react';
import { IM120AccordionListModule } from '~/lib/data-contract';
import { ModuleContainer } from '../module-container';
import { M120 } from './m120-accordion';

export const M120AccordionBlock = ({ headline, accordionItems, id }: IM120AccordionListModule) => {
    return (
        <ModuleContainer id={id}>
            <M120 headline={headline} accordionItems={accordionItems} />
        </ModuleContainer>
    );
};
