import styled from '@emotion/styled';
import { useTranslation } from '$shared/utils';
import { FC, useMemo } from 'react';
import { StockStatus } from '$features/stock/stock-status';

interface DeliveryMessageProps {
    status: StockStatus;
}

const StyledDeliveryMessage = styled.span({
    display: 'inline-block',
    minHeight: '1em',
    width: '100%',
    textAlign: 'left',
});

export const DeliveryMessage: FC<DeliveryMessageProps> = (props) => {
    const { translate } = useTranslation();

    const stockMessage = useMemo(() => {
        return translate(`delivery.delivery-message-${props.status}`);
    }, [props.status]);

    return <StyledDeliveryMessage children={stockMessage} />;
};
