import { Icon } from '$shared/components/icon';
import { formatString } from '$shared/utils';
import React, { useEffect, useRef } from 'react';
import { INavigationNode } from '~/lib/data-contract';
import { useTranslation } from '~/shared/utils/translation';
import { NodeList } from '../NodeList';
import {
    StyledBackButton,
    StyledCloseButton,
    StyledHeader,
    StyledHeaderIcon,
    StyledHeaderLink,
    StyledHeaderLogoButton,
    StyledMenuHeadline,
    StyledMenuPage,
} from './styled';

type MenuPageProps = {
    nodes: INavigationNode[];
    parentNode?: INavigationNode;
    headlineText?: string;
    headlineLinkUrl?: string;
    backText?: string;
    topLevel?: boolean;
    onSelectNode?: (state?: INavigationNode) => void;
    onGoBack?: () => void;
    onClose?: () => void;
};

export const MenuPage = ({
    nodes,
    topLevel,
    headlineText,
    headlineLinkUrl,
    backText,
    onSelectNode,
    onGoBack,
    onClose,
}: MenuPageProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const { translate } = useTranslation();

    /**
     * Focus first link on open
     */
    useEffect(() => {
        const element = ref.current;

        if (element) {
            const firstFocusableElement = element.querySelector<HTMLAnchorElement>('a, button');
            firstFocusableElement?.focus();
        }
    }, [nodes]);

    /**
     * Support keyboard navigation up and down within the navigation
     */
    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!ref.current) {
            return;
        }

        const activeElement = document.activeElement as HTMLAnchorElement;
        const focusableElements =
            ref.current?.querySelectorAll<HTMLAnchorElement>('a, button') || [];
        const currentFocusIndex = Array.from(focusableElements).indexOf(activeElement);

        if (event.key === 'ArrowDown') {
            const focusIndex = Math.min(currentFocusIndex + 1, focusableElements.length - 1);
            focusableElements[focusIndex]?.focus();
            event.preventDefault();
        } else if (event.key === 'ArrowUp') {
            const focusIndex = Math.max(currentFocusIndex - 1, 0);
            focusableElements[focusIndex]?.focus();
            event.preventDefault();
        }
    };

    return (
        <StyledMenuPage ref={ref} onKeyDown={onKeyDownHandler}>
            {headlineText && (
                <StyledHeader hasGap={!topLevel}>
                    <StyledCloseButton onClick={onClose}>
                        <Icon icon="close" size={'md'} />
                    </StyledCloseButton>

                    {topLevel ? (
                        <StyledHeaderLogoButton onClick={onClose}>
                            <StyledHeaderIcon icon="logo" />
                        </StyledHeaderLogoButton>
                    ) : (
                        <>
                            {backText && (
                                <StyledBackButton onClick={onGoBack}>
                                    <Icon icon="chevronLeft" color="blue" />
                                    <span>
                                        {formatString(
                                            translate('navigation.back-button'),
                                            backText.toLocaleLowerCase()
                                        )}
                                    </span>
                                </StyledBackButton>
                            )}
                            <StyledMenuHeadline size={3} children={headlineText} />
                            {headlineLinkUrl && (
                                <StyledHeaderLink
                                    href={headlineLinkUrl}
                                    children={translate('navigation.see-all')}
                                />
                            )}
                        </>
                    )}
                </StyledHeader>
            )}
            {nodes?.length ? <NodeList nodes={nodes} onSelectNode={onSelectNode} /> : null}
        </StyledMenuPage>
    );
};
