import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import { getCultureInfo } from '$shared/utils/market/helpers/culture-info.helper';
import { buildURL, cancellableFetch } from '$shared/utils/request';
import { useQuery } from '@tanstack/react-query';
import { IKyoceraFrame } from '~/lib/data-contract';

export type FrameQueryOptions = [[string, string], () => Promise<IKyoceraFrame>];
const { API_URL } = process.env;
const clientFrameUrl = '/api/proxy/content/frame';
const serverFrameUrl = API_URL + '/api/content/frame';

export function useFrame(): Partial<IKyoceraFrame> {
    const { asPath } = useKyoceraRouter();
    const { culture } = getCultureInfo(asPath);

    const { data } = useQuery(
        ['createFrameQueryOptions', `/${culture}`],
        () => {
            const serializedUrl = buildURL(clientFrameUrl, { url: `/${culture}` });
            return cancellableFetch<IKyoceraFrame>({ url: serializedUrl });
        },
        {
            keepPreviousData: true,
            staleTime: 10 * 60 * 1000,
            cacheTime: 60 * 1000,
            refetchOnWindowFocus: true,
        }
    );

    return data || {};
}

function createFrameQueryOptions(
    frameUrl: string,
    currentPath: string,
    httpHeaders?: HeadersInit
): FrameQueryOptions {
    const { culture } = getCultureInfo(currentPath);
    const serializedUrl = buildURL(frameUrl, { url: `/${culture}` });
    const options = httpHeaders ? { headers: httpHeaders } : undefined;
    const queryKey: [string, string] = ['createFrameQueryOptions', `/${culture}`];
    const request = () => cancellableFetch<IKyoceraFrame>({ url: serializedUrl, options });

    return [queryKey, request];
}

export function createFrameQueryOptionsForServer(
    currentPath: string,
    httpHeaders: HeadersInit
): FrameQueryOptions {
    return createFrameQueryOptions(serverFrameUrl, currentPath, httpHeaders);
}
