import React from 'react';
import { HeadlineProps } from './headline';

type UseHeadlineAsProps = {
    as?: React.ElementType<any>;
    size?: HeadlineProps['size'];
};

export const useHeadlineAs = ({ as: defaultAs, size }: UseHeadlineAsProps) => {
    return (
        defaultAs ||
        (size === 'p'
            ? (size as 'p')
            : ((size && size >= 1 && size <= 6 ? `h${size}` : 'h1') as
                  | 'h1'
                  | 'h2'
                  | 'h3'
                  | 'h4'
                  | 'h5'
                  | 'h6'))
    );
};
