export type Search = { query: string };
export type SearchSuggestionsResponse = { suggestions?: string[] }; // TODO: should come from swagger...

export type SearchCommerceItem = {
    Description?: string;
    ImageUrl?: string;
    ItemNumber?: string;
    Name?: string;
    ProductUrl?: string;
};
export type SearchCommerceResponse = {
    Items?: SearchCommerceItem[];
    ItemTotalCount?: number;
    PageNumber?: number;
};
export enum searchKeys {
    queryParamKey = 'search',
    productTab = 'Products',
    contentTab = 'Pages',
}
