import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints, theme } from '$theme';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { inputStyle} from '../input-element/styled';

export const StyledDatepickerIntoText = styled.div(() => ({
    // Children has to be a block element, otherwise styling breaks https://reactdatepicker.com/#example-children
    clear: 'both', // Because react-datepicker is build with FLOATs
    padding: theme.space[2],
    borderTop: '1px solid #aeaeae',
  
}));
export const DatePickerContainer = styled.div(({ theme }) => ({
    '& *': {
        boxSizing: 'initial',
    },

    '& .react-datepicker': {
        border: `2px solid ${theme.colors.borderColor}`,
        borderRadius: 0,
    },

    '& .react-datepicker-popper': {
        zIndex: theme.zIndices[1],
    },

    '& .react-datepicker--time-only .react-datepicker__time-container': {
        width: '100%',
    },

    '& .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle': {
        left: '50% !important',
        transform: 'translate3d(-50%, 0px, 0px) !important',
        marginLeft: 0,
    },

    '& .react-datepicker__month-container': {
        ':not(:last-of-type)': {
            borderRight: '1px solid #aeaeae',
        },
    },

    '& .react-datepicker__time-container': {
        borderLeft: 0,
    },

    '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    '& .react-datepicker__header': {
        padding: `${theme.space[4]} 0 ${theme.space[2]}`,
        borderRadius: 0,
    },

    '& .react-datepicker__navigation': {
        paddingTop: '10px',
    },

    '& .react-datepicker__day-name, .react-datepicker__day': {
        margin: 0,
        padding: '2px',

        [breakpoints.md]: {
            padding: theme.space[3],
        },
    },

    '& .react-datepicker__month': {
        margin: 0,
    },

    '& .react-datepicker__day': {
        color: theme.colors.black,

        '&:hover': {
            color: theme.colors.white,
            backgroundColor: theme.colors.primary,
            borderRadius: 0,
        },
    },

    '& .react-datepicker__day--disabled': {
        color: theme.colors.grey50,
        backgroundColor: theme.colors.grey30,
        '&:hover': {
            color: theme.colors.grey50,
            backgroundColor: theme.colors.grey30,
        },
    },

    '& .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected': {
        backgroundColor: 'transparent',
        color: theme.colors.black,
        position: 'relative',

        '&:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: theme.space[2],
            border: `1px solid ${theme.colors.black}`,
            borderRadius: '50%',
        },
        '&:hover:after': {
            borderColor: theme.colors.white,
        },
    },
    '& .react-datepicker__day--outside-month': {
        visibility: 'hidden',
    },
}));
export const StyledDatepicker = styled(DatePicker)<{withLabel: boolean}&ReactDatePickerProps<string, boolean | undefined>>(
    ({ theme }) => ({
        ...inputStyle ,
        boxSizing: 'border-box',
        borderRadius: '0',
        [breakpoints.xs]: {
            // important for iOS, otherwise iOS will zoom in everytime you click on the input field
            fontSize: theme.fontSizes.md,
        },
    }),
    ifProp('isInvalid', ({ theme }) => ({
        color: theme.colors.negative,
        [`&::placeholder`]: {
            color: theme.colors.negative,
        },
    })),
    ifProp({ isActive: false }, ({ theme }) => ({
        '&:hover': {
            borderColor: theme.colors.grey30,
        },
    })),
    ifProp('withLabel', ({ theme }) => ({
        padding: `25px ${theme.space[4]} 5px`,
    })),
    ifProp(
        { withLabel: true, isActive: false },
        {
            [`&::placeholder`]: {
                color: 'transparent',
            },
           
        }
    )
);
