import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { IProductMaterial } from '~/lib/data-contract';

type M21GridItemMaterialProps = {
    material: IProductMaterial[] | undefined;
};

const getCorrectColorFormat = (hex: string) => (hex.charAt(0) !== '#' ? `#${hex}` : hex);
export const M21GridItemMaterials = ({ material }: M21GridItemMaterialProps) => {
    return (
        <StyledMaterialWrapper>
            {material &&
                material.map((item, index) => {
                    return (
                        <StyledMaterialItem
                            bgColor={getCorrectColorFormat(item.cssColor ?? '#fff')}
                            key={`material_${index}_${item.name}`}
                            title={item.altDescription}
                        >
                            <span>{item.name}</span>
                        </StyledMaterialItem>
                    );
                })}
        </StyledMaterialWrapper>
    );
};

const StyledMaterialWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    gap: '7px',
    [breakpoints.msm]: {
        marginTop: theme.space['2'],
        justifyContent: 'left',
    },
});

const StyledMaterialItem = styled.div(({ bgColor }: { bgColor: string }) => ({
    height: theme.space['5'],
    width: theme.space['5'],
    borderRadius: 100,
    backgroundColor: bgColor,
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: theme.fontWeights['bold'],
    fontSize: theme.fontSizes['2xs'],
    [breakpoints.xs]: {
        fontSize: theme.fontSizes['3xs'],
        height: theme.space['4.5'],
        width: theme.space['4.5'],
    },
}));
