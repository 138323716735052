import React from 'react';
import Headline from '$shared/components/headline';
import { useProduct } from '$shared/utils/product';
import { StyledCoreDataSubheadline, StyledPdpCoreWrapper } from './style';

export const PdpCoreDataHeadlines = () => {
    const {
        description1: title,
        description2: description,
        description3: secondaryDescription,
    } = useProduct();

    return (
        <StyledPdpCoreWrapper>
            <Headline children={title} noMargin size={2} />
            {description && <StyledCoreDataSubheadline children={description} />}
            {secondaryDescription && <StyledCoreDataSubheadline children={secondaryDescription} />}
        </StyledPdpCoreWrapper>
    );
};
