import React, { useCallback, useEffect, useMemo } from 'react';
import { BasketSummary } from '$shared/components/basket-summary/basket-summary';
import { BasketSummaryHeaderLink } from '$shared/components/basket-summary/basket-summary-header-link';
import { useFrame, useTranslation } from '$shared/utils';
import { useCheckoutSteps } from '~/features/checkout/hooks/useCheckoutSteps';
import { DeliveryAddress } from './delivery-address/delivery-address';
import { OrderReference } from './order-reference/order-reference';
import { useInformationForm } from '$features/checkout/hooks/use-information-form';
import { useOrderStore } from '$features/checkout/hooks/use-order-store';
import { FormProvider } from 'react-hook-form';
import { ContactInformation } from './contact-information/contact-information';
import { InformationForm } from '$features/checkout/models/information-form';
import {
    StyledCheckoutButton,
    StyledCheckoutTwoColWrapper,
} from '$templates/pages/components/P310CheckoutPage/style';
import { useCreateQuote } from '$features/checkout/hooks/use-create-quote';
import { QuoteSummaryLines } from '../shared/quote-summary-lines';

export const InformationStep = () => {
    const { basketPage } = useFrame();
    const { goToNextStep } = useCheckoutSteps();
    const { translate } = useTranslation();
    const orderStore = useOrderStore();
    const form = useInformationForm(orderStore);
    const { createNewQuote, creatingQuoteIsLoading } = useCreateQuote();

    useEffect(() => {
        createNewQuote();
    }, []);

    const isSubmitEnabled = useMemo(() => !form.formState.submitCount || form.formState.isValid, [
        form.formState,
    ]);

    const submitForm = useCallback(
        (data: InformationForm) => {
            orderStore.setFromForm(data);
            goToNextStep();
        },
        [goToNextStep, orderStore.setFromForm]
    );

    const resetForm = useCallback(
        (values: InformationForm) => {
            form.reset(values, {
                keepErrors: true,
                keepIsSubmitted: true,
            });
        },
        [form]
    );

    return (
        <StyledCheckoutTwoColWrapper>
            <div>
                <FormProvider {...form}>
                    <OrderReference />
                    <DeliveryAddress onTabChange={(values) => resetForm(values)} />
                    <ContactInformation onTabChange={(values) => resetForm(values)} />
                </FormProvider>
            </div>
            <div>
                <BasketSummary
                    actionLink={
                        <StyledCheckoutButton
                            children={translate('checkout-page.continue')}
                            disabled={!isSubmitEnabled || creatingQuoteIsLoading}
                            isLoading={creatingQuoteIsLoading}
                            onClick={form.handleSubmit(submitForm)}
                        ></StyledCheckoutButton>
                    }
                    headerLink={
                        <BasketSummaryHeaderLink
                            children={translate('checkout-page.edit-order')}
                            href={basketPage?.url || ''}
                        />
                    }
                    listItems={<QuoteSummaryLines />}
                    showHeader={true}
                />
            </div>
        </StyledCheckoutTwoColWrapper>
    );
};
