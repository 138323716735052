import { BasketSummaryHeaderLink } from '$shared/components/basket-summary/basket-summary-header-link';
import { BasketSummaryTerms } from '$shared/components/basket-summary/basket-summary-terms';
import { useRawHtml } from '$shared/components/raw-html';
import { useFrame, useTranslation } from '$shared/utils';
import React, { useEffect, useState } from 'react';
import { ConfirmOrderDetails } from './confirm-order-details/confirm-order-details';
import { DeliveryIntervals } from '../delivery-step/delivery-intervals/delivery-intervals';
import { BasketSummary } from '$shared/components/basket-summary/basket-summary';
import { QuoteSummaryLines } from '../shared/quote-summary-lines';
import { useCreateOrder } from '$features/checkout/hooks/use-create-order';
import {
    StyledCheckoutButton,
    StyledCheckoutHeadline,
    StyledCheckoutTwoColWrapper,
} from '$templates/pages/components/P310CheckoutPage/style';
import { useCreateQuote } from '$features/checkout/hooks/use-create-quote';

export const ConfirmOrderStep = () => {
    const { basketPage } = useFrame();
    const { translate } = useTranslation();
    const [termsAccepted, setTermsAccepted] = useState(false);
    const { isLoading, submitOrder } = useCreateOrder();

    const { createNewQuote } = useCreateQuote();

    useEffect(() => {
        createNewQuote();
    }, []);

    return (
        <StyledCheckoutTwoColWrapper>
            <div>
                <StyledCheckoutHeadline size="h3">
                    {translate('checkout-page.billing-details')}
                </StyledCheckoutHeadline>
                <ConfirmOrderDetails />
                <DeliveryIntervals initAsOpen={false} />
            </div>
            <div>
                <BasketSummary
                    actionLink={
                        <StyledCheckoutButton
                            disabled={!termsAccepted}
                            onClick={submitOrder}
                            isLoading={isLoading}
                        >
                            {translate('checkout-page.confirm-order')}
                        </StyledCheckoutButton>
                    }
                    headerLink={
                        <BasketSummaryHeaderLink
                            children={translate('checkout-page.edit-order')}
                            href={basketPage?.url || ''}
                        />
                    }
                    listItems={<QuoteSummaryLines />}
                    terms={
                        <BasketSummaryTerms
                            label={useRawHtml(translate('checkout-page.terms-and-conditions'))}
                            onChange={setTermsAccepted}
                            value={termsAccepted}
                        />
                    }
                    showHeader={true}
                />
            </div>
        </StyledCheckoutTwoColWrapper>
    );
};
