import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { switchProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { Severity } from '../hooks/useNotificationModel';

export const StyledList = styled(motion.ul)(({ theme }) => ({
    alignItems: 'flex-end',
    bottom: theme.space[4],
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
    position: 'fixed',
    right: theme.space[4],
    left: theme.space[4],
    zIndex: theme.zIndices.max,
}));

export const StyledNotification = styled(motion.div)(({ theme }) => ({
    background: theme.colors.white,
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
    color: theme.colors.black,
    cursor: 'pointer',
    fontSize: theme.fontSizes.sm,
    overflow: 'hidden',
    marginBottom: theme.space[2],
    pointerEvents: 'auto',
    zIndex: 1,
}));

export const StyledContent = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.space[3],
    padding: `${theme.space[3]}`,

    [breakpoints.sm]: {
        gap: theme.space[4],
    },
}));

export const StyledTitle = styled.span(({ theme }) => ({
    fontSize: theme.fontSizes.md,
    fontWeight: theme.fontWeights.semiBold,
}));

export const StyledText = styled.span(({ theme }) => ({
    fontSize: theme.fontSizes['3xs'],
    color: theme.colors.grey90,
}));

export const StyledTextContent = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[1],
    width: '20ch',
    flexBasis: '20ch',
    padding: `${theme.space[2]} 0`,

    [breakpoints.sm]: {
        width: '30ch',
        flexBasis: '30ch',
    },
}));

export const StyledCloseButton = styled.button(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: 'none',
    border: 'none',
    padding: theme.space[2],
    margin: `calc(${theme.space[2]} * -1)`,
    cursor: 'pointer',
    maxWidth: 580,
    svg: {
        height: 'calc(24em/14)',
        width: 'calc(24em/14)',
    },
}));

export const StyledNotificationTimer = styled(motion.div)<{ severity: Severity }>(
    () => ({
        height: '4px',
        transformOrigin: 'left',
    }),
    switchProp('severity', {
        error: ({ theme }) => ({
            backgroundColor: theme.colors.negative,
        }),
        info: ({ theme }) => ({
            backgroundColor: theme.colors.primary,
        }),
        success: ({ theme }) => ({
            backgroundColor: theme.colors.positive,
        }),
    })
);

export const StyledNotificationList = styled.ul(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[1],
}));

export const StyledIconWrapper = styled.span(({ theme }) => ({
    paddingTop: theme.space[2],
}));
