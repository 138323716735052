import { StyledButton } from '$shared/components/button';
import { StyledSpotContent } from '$shared/components/spot-content';
import { StyledSpotMedia } from '$shared/components/spot-media';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { M75Props } from './m75-media-text';

type StyledM75Props = Pick<M75Props, 'backgroundColor'>;
type StyledM75ContentProps = Pick<
    M75Props,
    'backgroundColor' | 'mediaAlignment' | 'mediaSize' | 'contentPosition'
>;
type StyledM75StyledActionsProps = {
    alignment?: 'left' | 'center' | 'right';
};

export const StyledActions = styled.div<StyledM75StyledActionsProps>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.space['2'],
        marginTop: theme.space['3'],
        width: '100%',

        [`& ${StyledButton}`]: {
            marginTop: 0,
            paddingTop: 0,
            marginRight: '0 !important', // FIXME
        },
    }),
    switchProp('alignment', {
        left: {
            alignItems: 'flex-start',
        },
        right: {
            alignItems: 'flex-end',
            textAlign: 'right',
        },
        center: {
            alignItems: 'center',
            textAlign: 'center',
        },
    })
);

export const StyledM75 = styled.div<StyledM75Props>(
    ({ theme }) => ({
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
        maxWidth: theme.grid.maxWidth,

        [breakpoints.sm]: {
            display: 'grid',
        },

        [`${StyledSpotMedia}`]: {
            margin: `0 -${theme.space[4]}`,

            [breakpoints.sm]: {
                margin: 0,
            },
        },
    }),
    ifProp('backgroundColor', ({ backgroundColor }) => ({
        ':before': {
            content: '""',
            position: 'absolute',
            height: '100%',
            width: `calc(100% + (${theme.space[4]} * 2))`,
            marginLeft: `calc(${theme.space[4]} * -1)`,
            backgroundColor: backgroundColor,
            zIndex: -1,

            [breakpoints.sm]: {
                width: '100%',
                marginLeft: 0,
            },
        },
    }))
);

export const StyledM75Content = styled.div<StyledM75ContentProps>(
    ({ mediaAlignment, theme }) => ({
        height: 'auto',
        boxSizing: 'border-box',
        width: '100%',
        margin: '0 auto',
        [breakpoints.sm]: {
            display: 'grid',
            gridAutoFlow: 'column',
            gridTemplateAreas: mediaAlignment === 'left' ? '"media content"' : '"content media"',
        },
        [`${StyledSpotContent}`]: {
            paddingTop: theme.space['4'],
            paddingBottom: theme.space['4'],
            [breakpoints.sm]: {
                padding: theme.space['4'],
            },
            [breakpoints.md]: {
                padding: theme.space['7'],
            },
        },
    }),

    switchProp('mediaSize', {
        default: {
            gridAutoColumns: '50%',
        },
        small: ({ mediaAlignment }) => ({
            gridAutoColumns: mediaAlignment === 'left' ? '32% 68%' : '68% 32%',
        }),
    }),
    switchProp('contentPosition', {
        topWithoutPadding: {
            [`${StyledSpotContent}`]: {
                [breakpoints.sm]: {
                    paddingTop: '0',
                },
            },
        },
    })
);
