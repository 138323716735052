import { absoluteCenter } from './../../../utils/style-helpers/position.style.helper';
import { ifNotProp } from 'styled-tools';
import { theme } from '$theme';
import styled from '@emotion/styled';
import { Arrow } from '@radix-ui/react-hover-card';

type toolTipProps = {
    allowLineBreak?: boolean;
};
export const StyledToolTipText = styled.div<toolTipProps>(
    {
        color: theme.colors.white,
    },
    ifNotProp('allowLineBreak', () => ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }))
);

export const StyledToolTipWrapper = styled.div(() => ({
    display: 'flex',
}));

export const StyledToolTip = styled.div(() => ({
    position: 'relative',
    maxWidth: 500,
    backgroundColor: theme.colors.grey90,
    padding: `${theme.space[1]} ${theme.space[4]}`,
    margin: `0px ${theme.space[1]} `,

    '&:before': {
        content: '""',
        position: 'absolute',
    },
}));

export const StyledTooltipArrow = styled(Arrow)(() => ({
    fill: theme.colors.grey90,
}));

export const StyledToolTipIcon = styled.div(() => ({
    position: 'relative',
    padding: '10px',
    marginLeft: theme.space[1],
    color: theme.colors.white,
    background: theme.colors.primary,
    borderRadius: '100%',

    ':after': {
        content: '"?"',
        fontSize: theme.fontSizes.md,
        ...absoluteCenter,
    },
}));
