import MaxWidth, { StyledMaxWidth } from '$shared/components/max-width';
import { StyledM10Content } from '$templates/blocks/m10-hero/style';
import { StyledModule } from '$templates/blocks/module-container';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';

export const StyledModulesWrapper = styled.div(() => ({
    [`${StyledModule}:first-of-type`]: {
        paddingTop: 0,
    },
}));

export const StyledMainArea = styled(MaxWidth)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    [breakpoints.md]: {
        flexWrap: 'nowrap',
    },

    [`${StyledMaxWidth}`]: {
        padding: 0, // Avoiding nested/double container padding
    },
}));

const spaceBetweenAsideAndContent = theme.space[10];
export const StyledAsideArea = styled.aside(() => ({
    display: 'none',

    [breakpoints.sm]: {
        paddingTop: theme.global.moduleBottomSpacing,
    },

    [breakpoints.md]: {
        display: 'block',
        flexShrink: 0,
        width: '33%',
        maxWidth: '350px',
        marginRight: spaceBetweenAsideAndContent,
    },
}));

export const StyledContentArea = styled.section<{ hasSideMenu?: boolean }>(() => ({
    flexGrow: 1,
    width: `calc(66% - ${spaceBetweenAsideAndContent})`,

    // Tweaks to components squeezed into this area:
    [`${StyledM10Content}`]: {
        padding: `0 ${theme.space.gutter}`,
    },
}));
