import { usePage } from '$templates/pages';
import { useRouter } from 'next/router';
import { getCultureInfoUrlObj } from '$shared/utils/market/helpers/culture-info.helper';

/*
 *
 * Router wrapping the nextjs router
 * Needed because FE needs to maintain the CultureInfo of the url, e.g. "/da-dk/..."
 *
 */

export const useKyoceraRouter = () => {
    const { push, ...router } = useRouter();
    const { culture, market } = usePage();

    // type ensures alignment with the acctual push method of ther nextjs router.
    type KyoceraPush = (...parameters: Parameters<typeof push>) => ReturnType<typeof push>;

    const kyoceraPush: KyoceraPush = (url, ...args) => {
        return push(getCultureInfoUrlObj(url, culture, market), ...args);
    };

    return { ...router, push: kyoceraPush };
};
