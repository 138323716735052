import { theme } from '$theme';
import styled from '@emotion/styled';
import { Icon } from '$shared/components/icon';
import Spinner from '$shared/components/spinner';

export const StyledSearchBarWrapper = styled.div({
    fontSize: theme.fontSizes.md,
    position: 'relative',
});

export const StyledSearchBarIcon = styled(Icon)({
    color: theme.colors.black,
    fontSize: 'inherit',
    left: theme.space[3],
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
});

export const StyledSearchBarInput = styled.input(({ theme }) => ({
    backgroundColor: theme.colors.grey20,
    border: `1px solid ${theme.colors.grey20}`,
    color: theme.colors.black,
    fontSize: 'inherit',
    height: '3.125em',
    outline: 'none',
    padding: `0 ${theme.space[8]}`,
    textOverflow: 'ellipsis',
    width: '100%',

    ':focus': {
        backgroundColor: theme.colors.grey10,
        borderColor: theme.colors.blue90,
    },

    '::placeholder': {
        color: theme.colors.grey90,

        ':focus': {
            color: 'transparent',
        },
    },
}));

export const StyledSpinner = styled(Spinner)(() => ({
    position: 'absolute',
    top: '50%',
    right: theme.space[4],
    transform: 'translate(0, -50%)',
}));

export const StyledCancelButton = styled.button(({ theme }) => ({
    backgroundColor: theme.colors.black,
    border: 'none',
    borderRadius: '100%',
    color: theme.colors.white,
    cursor: 'pointer',
    display: 'flex',
    padding: '1px',
    position: 'absolute',
    top: '50%',
    transition: 'transform 150ms',
    transform: 'translate(0, -50%)',
    right: theme.space[4],

    ':focus-visible': {
        outline: `2px solid ${theme.colors.blue50}`,
    },

    ':hover': {
        backgroundColor: theme.colors.grey70,
    },

    ':active': {
        backgroundColor: theme.colors.grey90,
        transform: 'translate(0, -50%) scale(.9)',
    },
}));

export const StyledCancelIcon = styled(Icon)(() => ({
    color: 'inherit',
    width: '20px',
    height: '20px',
}));
