import React, { FC } from 'react';
import {
    StyledHeaderButton,
    StyledHeaderButtonWrapper,
    StyledLabel,
    StyledIcon,
    StyledAuthenticationPanelContainer,
    StyledSignOutButton,
    StyledNavItem,
    MyPagesNavContainer,
    StyledSignInButton,
    StyledSignUpLink,
} from './style';
import { useFrame, useTranslation } from '$shared/utils';
import { useAuthentication } from '$shared/hooks/useAuthentication';
import { Popover } from '$shared/components/popover/popover';
import { Icon } from '$shared/components/icon';
import { ActOnBehalf } from '$features/authentication/act-on-behalf';

export const AuthenticationPanel: FC = () => {
    const { header, basketPage } = useFrame();
    const { translate } = useTranslation();
    const { isAuthenticated, userFound, signIn, signOut } = useAuthentication();

    return (
        <>
            {isAuthenticated ? (
                <Popover
                    fullwidthOnMobile
                    closeOnRouteChange
                    content={
                        <StyledAuthenticationPanelContainer>
                            {userFound && (
                                <>
                                    <ActOnBehalf />
                                    <MyPagesNavContainer>
                                        {header?.myPages?.map((myPageLink) => (
                                            <StyledNavItem
                                                key={myPageLink.id}
                                                href={myPageLink.url}
                                                target={myPageLink.target}
                                                title={myPageLink.title}
                                            >
                                                {myPageLink.text}
                                                {myPageLink.url?.startsWith('https://') && (
                                                    <Icon
                                                        icon="externalLink"
                                                        color="grey90"
                                                        size={24}
                                                    />
                                                )}
                                            </StyledNavItem>
                                        ))}
                                    </MyPagesNavContainer>
                                </>
                            )}
                            <StyledSignOutButton onClick={signOut}>
                                {translate('authentication.sign-out')}
                            </StyledSignOutButton>
                        </StyledAuthenticationPanelContainer>
                    }
                >
                    <StyledHeaderButtonWrapper>
                        <StyledHeaderButton>
                            <StyledIcon icon="profile" size="lg" />
                            <StyledLabel>{translate('authentication.my-pages')}</StyledLabel>
                        </StyledHeaderButton>
                    </StyledHeaderButtonWrapper>
                </Popover>
            ) : (
                <Popover
                    fullwidthOnMobile
                    closeOnRouteChange
                    content={
                        <StyledAuthenticationPanelContainer>
                            <StyledSignInButton
                                children={translate('authentication.sign-in')}
                                onClick={() => signIn()}
                                variant={'primary'}
                            />
                            {header?.signup && (
                                <StyledSignUpLink
                                    children={header.signup.text}
                                    href={header.signup.url}
                                    variant={'secondary'}
                                />
                            )}
                        </StyledAuthenticationPanelContainer>
                    }
                >
                    <StyledHeaderButtonWrapper>
                        <StyledHeaderButton>
                            <StyledIcon icon="profile" size="lg" />
                            <StyledLabel>{translate('authentication.sign-in')}</StyledLabel>
                        </StyledHeaderButton>
                        <StyledHeaderButton>
                            <StyledIcon icon="cart" size="lg" />
                            <StyledLabel>{basketPage?.title}</StyledLabel>
                        </StyledHeaderButton>
                    </StyledHeaderButtonWrapper>
                </Popover>
            )}
        </>
    );
};
