import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { SearchDialog } from '../search-dialog/search-dialog';

export const StyledSearch = styled.div(() => ({
    position: 'relative',
    width: '100%',
    margin: `0 0 ${theme.space[4]}`,

    [breakpoints.md]: {
        maxWidth: '630px',
        margin: `0 ${theme.space[6]}`,
    },
}));

export const StyledSearchDialog = styled(SearchDialog)(() => ({
    marginTop: theme.space[2],
    maxWidth: `calc(100vw - ${theme.space.gutter} - ${theme.space.gutter})`,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    width: '936px',
    zIndex: 99998,

    [breakpoints.frame]: {
        marginTop: theme.space[4],
    },
}));
