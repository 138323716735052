import { ReactNode, FC } from 'react';
import styled from '@emotion/styled';
import Link from '../link';

interface BasketSummaryActionProps {
    children: ReactNode;
    className?: string;
    enabled: boolean;
    href: string;
    isLoading?: boolean;
    onClickOverwrite?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const StyledBasketSummaryAction = styled(Link)({
    width: '100%',
});

export const BasketSummaryAction: FC<BasketSummaryActionProps> = (props) => {
    return (
        <StyledBasketSummaryAction
            variant="primary"
            disabled={!props.enabled || props.isLoading}
            children={props.children}
            href={props.href}
            isLoading={props.isLoading}
            passHref
            onClick={props?.onClickOverwrite}
        />
    );
};
