import React, { useImperativeHandle, useRef, useState } from 'react';
import { useInputField } from '$shared/utils';
import {
    StyledInputFieldWrapper,
    StyledInputField,
    StyledLabel,
    StyledInput,
    StyledAdditionalContent,
    StyledInvalidMessage,
    StyledHelpText,
} from './style';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type InputFieldProps = Omit<InputProps, 'value'> & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * This component is uncontrolled - you will need to controll the value.
     */
    value: string;

    /**
     * When active the label will move up above the actual input field
     */
    isActive?: boolean;

    /**
     * Append a string or a component to the input field
     */
    append?: React.ReactNode;
};

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
    (
        { label, helpText, invalidMessage, id, children, isActive, isInvalid, append, ...rest },
        ref
    ) => {
        const [isValidityValid, setIsValidityValid] = useState(true);
        const [hasFocus, setHasFocus] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);
        const inputFieldRef = useRef<HTMLDivElement>(null);

        const {
            fieldId,
            labelId,
            helpTextId,
            invalidMessageId,
            showHelpText,
            showInvalidMessage,
        } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        const { value, defaultValue } = rest;

        const hasValue = value === undefined ? !!defaultValue : !!value;
        const isInputActive = isActive || hasFocus || hasValue;
        const isValid = !showInvalidMessage && isValidityValid;

        const onFocusHandler = () => setHasFocus(true);
        const onBlueHandler = () => {
            setHasFocus(false);
            setIsValidityValid(inputRef.current?.validity.valid ? true : false);
        };

        const onInputFieldClick = (event: React.MouseEvent) => {
            if (inputRef.current !== event.target) {
                inputRef.current?.focus();
                inputRef.current?.click();
                event?.preventDefault();
                event?.stopPropagation();
            }
        };

        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        return (
            <div>
                <StyledInputFieldWrapper
                    key={fieldId}
                    onFocus={onFocusHandler}
                    onBlur={onBlueHandler}
                    isValid={isValid}
                >
                    <StyledInputField onMouseDown={onInputFieldClick} ref={inputFieldRef}>
                        {label ? (
                            <StyledLabel
                                id={labelId}
                                htmlFor={fieldId}
                                title={label}
                                children={label}
                                isActive={isInputActive}
                                isValid={isValid}
                                hasFocus={hasFocus}
                            />
                        ) : null}

                        <StyledInput
                            withLabel={!!label}
                            isActive={isInputActive}
                            id={fieldId}
                            ref={inputRef}
                            {...rest}
                        />

                        {append ? <StyledAdditionalContent children={append} /> : null}
                    </StyledInputField>
                    {children}
                </StyledInputFieldWrapper>
                {showInvalidMessage ? (
                    <StyledInvalidMessage id={invalidMessageId} children={invalidMessage} />
                ) : null}
                {showHelpText ? <StyledHelpText id={helpTextId} children={helpText} /> : null}
            </div>
        );
    }
);

export default InputField;
