import { useEffect, RefObject, useState, useCallback } from 'react';

export const useDetectHorisontalScroll = (
    wrapperRef: RefObject<HTMLElement>
) => {
    const [hasScrolled, setHasScrolledLeft] = useState<boolean>();

    const handleScroll = useCallback(() => {
        const element = wrapperRef.current;
        if (element) {
            const elemHasScrollLeft = !!element?.scrollLeft;
            if (hasScrolled !== elemHasScrollLeft) {
                setHasScrolledLeft(elemHasScrollLeft);
            }
        }
    }, [hasScrolled, wrapperRef]);

    useEffect(() => {
        const elm = wrapperRef.current;
        if (elm) {
            elm.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', handleScroll);

            return () => {
                elm.removeEventListener('scroll', handleScroll);
                window.removeEventListener('resize', handleScroll);
            };
        }
    }, [wrapperRef, handleScroll]);

    return {
        hasScrolled,
    };
};
