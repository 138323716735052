import React, { FC } from 'react';
import { useNotification } from '$features/notifications/hooks';
import { useTranslation, downloadPdf } from '$shared/utils';
import { StyledOrderButton } from '../style';

type Props = {
    ariaLabel?: string;
    orderNumber?: string | null;
};
export const OrderDownload: FC<Props> = ({ ariaLabel, orderNumber }) => {
    const { translate } = useTranslation();
    const { push } = useNotification();

    const handleDownload = (orderNumber: string) => {
        const dismiss = push({
            severity: 'info',
            headline: translate('my-pages.orders.download.notification-headline'),
            text: translate('my-pages.orders.download.notification-text'),
            duration: 40000,
        });

        const handleError = () => {
            dismiss();
            push({
                severity: 'error',
                headline: translate('my-pages.orders.download.notification-error-headline'),
                text: translate('my-pages.orders.download.notification-error-text'),
            });
        };

        downloadPdf(orderNumber, false, dismiss, handleError);
    };

    return (
        <StyledOrderButton
            aria-label={ariaLabel}
            disabled={!orderNumber}
            onClick={() => handleDownload(orderNumber as string)}
        >
            {orderNumber}
        </StyledOrderButton>
    );
};
