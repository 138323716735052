import { Skeleton } from '$shared/components/skeleton';
import { useTranslation } from '$shared/utils';
import { FC } from 'react';

type PriceProps = {
    isFetchingPriceAndStockCount?: boolean;
    productPrice?: string | number | null;
};

export const Price: FC<PriceProps> = (props) => {
    const { translate } = useTranslation();
    return (
        <>
            {props.isFetchingPriceAndStockCount ? (
                <Skeleton
                    pulsating
                    height={'1em'}
                    width={'100%'}
                    css={{ maxWidth: '100px', minWidth: '66px' }}
                />
            ) : (
                <>
                    {props.productPrice ||
                        translate('product-detail-page.core-data.contact-for-price')}
                </>
            )}
        </>
    );
};
