import React, { useEffect, useMemo } from 'react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { ThemeProvider } from '$theme';
import PageMeta from '$layouts/head/page-meta';
import { removeCookieItem, RequestProvider, setCookieItem, useMarket } from '~/shared/utils';
import { CookiebotScript } from '~/scripts';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useGtm } from '$shared/hooks/useGTM/use-gtm';
import { GoogleTrackingScripts } from '$scripts/gtm';
import { SessionProvider } from 'next-auth/react';
import { Session } from 'next-auth';
import { DynamicPageProps } from '$templates/pages';
import { useUserStore } from '$features/authentication/use-user-store';
import {
    ACT_ON_BEHALF_KEY,
    ACT_ON_BEHALF_MARKET_KEY,
} from '$features/authentication/act-on-behalf-constants';
import { marketKeys } from '$shared/utils/market/model/marketModel';

// import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export const App = ({
    Component,
    pageProps: { session, ...pageProps },
}: AppProps<{ session?: Session | null } & DynamicPageProps>): JSX.Element => {
    // Sign in on behalf link, no need to render content since we should redirect the user
    if (pageProps?.isCrmLogin) {
        return <Component {...pageProps} />;
    }

    if (!pageProps?.page) {
        return <></>;
    }

    const {
        dehydratedState,
        impersonate,
        customerMarket,
        page: { market, culture },
    } = pageProps;

    const { initGtm, flushPreloadBuffer } = useGtm();

    const onGtmLoad = () => {
        initGtm();
        flushPreloadBuffer();
    };

    const setMarket = useMarket((state) => state.setMarket);
    useEffect(() => {
        if (market && culture) {
            setMarket({ market, culture });
        }
    }, [market, culture]);

    const {
        actOnBehalfOfCustomer,
        setActOnBehalfOfCustomer,
        actOnBehalfOfCustomerMarket,
        setActOnBehalfOfCustomerMarket,
        user,
    } = useUserStore();
    useEffect(() => {
        // If the server tells us to impersonate, we impersonate
        if (impersonate) {
            setActOnBehalfOfCustomer(impersonate);
            if (customerMarket) {
                setActOnBehalfOfCustomerMarket(customerMarket);
                setCookieItem(marketKeys.marketActOnHehalfCookieKey, customerMarket, '');
            }
        }
        // If we're already impersonating, but the server doesn't know, we refresh.
        else if (location && actOnBehalfOfCustomer) {
            const url = new URL(location.href);
            url.searchParams.append(ACT_ON_BEHALF_KEY, actOnBehalfOfCustomer);
            if (actOnBehalfOfCustomerMarket) {
                url.searchParams.append(ACT_ON_BEHALF_MARKET_KEY, actOnBehalfOfCustomerMarket);
            } else {
                const actOnBehalfData = user?.actOnBehalfOn?.find(
                    (act) => act.companyNumber === actOnBehalfOfCustomer
                );

                if (actOnBehalfData?.customerMarket) {
                    url.searchParams.append(
                        ACT_ON_BEHALF_MARKET_KEY,
                        actOnBehalfData.customerMarket
                    );
                }
            }
            location.href = url.href;
        }
    }, []);
    useEffect(() => {
        // we have impersonate data, but are missing the correct customerMarket
        // we have to redirect and let the server know
        if (impersonate && !customerMarket && user && location && actOnBehalfOfCustomer) {
            const url = new URL(location.href);
            const actOnBehalfData = user?.actOnBehalfOn?.find(
                (act) => act.companyNumber === impersonate
            );

            if (actOnBehalfData?.customerMarket) {
                const hasImpersonateQuery = url.searchParams.get(ACT_ON_BEHALF_KEY);
                if (!hasImpersonateQuery) {
                    url.searchParams.append(ACT_ON_BEHALF_KEY, impersonate);
                }
                url.searchParams.append(ACT_ON_BEHALF_MARKET_KEY, actOnBehalfData.customerMarket);
                location.href = url.href;
            }
        }
    }, [user, actOnBehalfOfCustomer]);

    useEffect(() => {
        if (actOnBehalfOfCustomerMarket) {
            setCookieItem(marketKeys.marketActOnHehalfCookieKey, actOnBehalfOfCustomerMarket, '');
        } else {
            removeCookieItem(marketKeys.marketActOnHehalfCookieKey);
        }
    }, [actOnBehalfOfCustomerMarket]);

    const pageTitle = useMemo(() => pageProps.page.metaTitle, [pageProps.page]);

    return (
        <RequestProvider dehydratedState={dehydratedState}>
            <SessionProvider session={session} refetchInterval={5 * 60}>
                <ThemeProvider>
                    <PageMeta
                        keyId={pageProps?.page?.id}
                        metaTitle={pageTitle}
                        metaTags={pageProps?.page?.metaTags}
                    />
                    <Component {...pageProps} />
                    <CookiebotScript />
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </SessionProvider>
            <GoogleTrackingScripts onGtmLoad={onGtmLoad} />
            {/* <ToastContainer position="bottom-right" limit={2} /> */}
        </RequestProvider>
    );
};

export function reportWebVitals(metric: NextWebVitalsMetric): void {
    console.info('Webvitals', metric.name, Math.round(metric.value * 100) / 100, 'ms');
}

export default App;
