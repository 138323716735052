import { useTranslation } from '$shared/utils';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    StyledCityInput,
    StyledFieldset,
    StyledInput,
    StyledZipCodeInput,
} from './delivery-address-form-styles';

const unregisterOptions = {
    keepValue: true,
};

type props = {
    country?: string;
};

export const DeliveryAddressForm: FC<props> = ({ country }) => {
    const { translate } = useTranslation();
    const { unregister } = useFormContext();

    useEffect(() => {
        return () => {
            unregister('deliveryAddress.name', unregisterOptions);
            unregister('deliveryAddress.address', unregisterOptions);
            unregister('deliveryAddress.zipCode', unregisterOptions);
            unregister('deliveryAddress.city', unregisterOptions);
            unregister('deliveryAddress.country', unregisterOptions);
            // unregister('deliveryAddress.persistDeliveryAddress', unregisterOptions);
        };
    }, []);

    return (
        <StyledFieldset>
            <StyledInput
                label={translate('checkout-page.delivery-address-form.company-name')}
                name="deliveryAddress.name"
                rules={{
                    maxLength: {
                        value: 40,
                        message: translate(
                            'checkout-page.delivery-address-form.validation-message-company-name-length'
                        ),
                    },
                    required: translate(
                        'checkout-page.delivery-address-form.validation-message-company-name'
                    ),
                }}
            />

            <StyledInput
                label={translate('checkout-page.delivery-address-form.address')}
                name="deliveryAddress.address"
                rules={{
                    maxLength: {
                        value: 40,
                        message: translate(
                            'checkout-page.delivery-address-form.validation-message-address-length'
                        ),
                    },
                    required: translate(
                        'checkout-page.delivery-address-form.validation-message-address'
                    ),
                }}
            />

            <StyledZipCodeInput
                label={translate('checkout-page.delivery-address-form.zip-code')}
                name="deliveryAddress.zipCode"
                rules={{
                    maxLength: {
                        value: 15,
                        message: translate(
                            'checkout-page.delivery-address-form.validation-message-zip-code-length'
                        ),
                    },
                    required: translate(
                        'checkout-page.delivery-address-form.validation-message-zip-code'
                    ),
                }}
            />

            <StyledCityInput
                label={translate('checkout-page.delivery-address-form.city')}
                name="deliveryAddress.city"
                rules={{
                    maxLength: {
                        value: 30,
                        message: translate(
                            'checkout-page.delivery-address-form.validation-message-city-length'
                        ),
                    },
                    required: translate(
                        'checkout-page.delivery-address-form.validation-message-city'
                    ),
                }}
            />
            {country && (
                <StyledInput
                    label={translate('checkout-page.delivery-address-form.country')}
                    name="deliveryAddress.country"
                    readOnly={true}
                    value={country}
                />
            )}

            {/* Disabled: KYOC-1445 */}
            {/* <StyledCheckbox
                label={translate('checkout-page.delivery-address-form.save-address')}
                name="deliveryAddress.persistDeliveryAddress"
            /> */}
        </StyledFieldset>
    );
};
