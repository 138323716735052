import styled from '@emotion/styled';
import { breakpoints } from '$theme';

export const StyledFooter = styled.div(({ theme }) => ({
    borderTop: `5px solid ${theme.colors.black}`,
    paddingTop: theme.space[10],
    paddingBottom: theme.space[5],
    overflow: 'hidden',
}));

export const StyledGrid = styled.div(({ theme }) => ({
    display: 'grid',
    rowGap: theme.space[6],
    columnGap: '8.3%',
    gridAutoRows: 'max-content',
    gridTemplateAreas: `"contact"
                        "menu"
                        "newsletter"
                        "social"
                        "meta"`,
    [breakpoints.sm]: {
        gridTemplateColumns: '41.7% 50%',
        gridTemplateAreas: `"contact    social"
                            "menu      menu"
                            "meta     newsletter"`,
    },
    [breakpoints.md]: {
        gridTemplateAreas: `"contact    menu"
                            "newsletter menu"
                            "social     menu"
                            "meta       menu"`,
    },
}));
