import { Checkbox, Input } from "$shared/components/form";
import styled from "@emotion/styled";

export const StyledFieldset = styled.fieldset(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[3],
    width: '100%',
}));

export const StyledInput = styled(Input)(() => ({
    maxWidth: '42em',
}));

export const StyledCheckbox = styled(Checkbox)(() => ({
}));
