import { useUserStore } from '$features/authentication/use-user-store';
import { useOrderStore } from '$features/checkout/hooks/use-order-store';
import { useSavedContacts } from '$features/checkout/hooks/use-saved-contacts';
import { InformationForm } from '$features/checkout/models/information-form';
import Spinner from '$shared/components/spinner';
import { TabMenu } from '$shared/components/tab-menu';
import { useTranslation } from '$shared/utils';
import { StyledCheckoutHeadline } from '$templates/pages/components/P310CheckoutPage/style';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ContactInformationForm } from '../contact-information-form/contact-information-form';
import { SelectorTab } from '../selector-tab/selector-tab';

interface ContactInformationProps {
    onTabChange: (values: InformationForm) => void;
}

export const ContactInformation: FC<ContactInformationProps> = (props) => {
    const { translate } = useTranslation();
    const { useExistingContactInfo } = useOrderStore();
    const userEmail = useUserStore((state) => state.user?.email);
    const { getValues, watch, setValue } = useFormContext<InformationForm>();
    const { contactOptions, isLoadingContacts, savedContacts } = useSavedContacts();

    const selectedOption = watch('existingContactInfoOption');
    const showTabs = useMemo(() => !isLoadingContacts && contactOptions.length, [
        isLoadingContacts,
        contactOptions,
    ]);

    useEffect(() => {
        // Change context to new user info if no contacts available
        if (!isLoadingContacts && !contactOptions.length) {
            const currentValues = getValues();
            props.onTabChange({
                ...currentValues,
                useExistingContactInfo: false,
            });
        }
    }, [contactOptions, isLoadingContacts]);

    const selectionSummary = useMemo(() => {
        const selection = selectedOption
            ? savedContacts.find((c) => c.contactId === selectedOption.value)
            : savedContacts[0];

        return selection && [selection.name, selection.email].join('<br>');
    }, [savedContacts, selectedOption]);

    const onTabChange = useCallback(
        (useSavedContact: boolean) => {
            const currentValues = getValues();
            props.onTabChange({
                ...currentValues,
                useExistingContactInfo: useSavedContact,
            });
        },
        [getValues, props.onTabChange]
    );

    useEffect(() => {
        if (userEmail && savedContacts && !selectedOption) {
            const preselectedOption =
                contactOptions.find((sc) => sc.option?.email === userEmail) || contactOptions[0];
            if (preselectedOption) {
                setValue('existingContactInfoOption', preselectedOption);
            }
        }
    }, [userEmail, savedContacts, selectedOption]);

    const contactInformationForm = <ContactInformationForm />;

    return (
        <>
            <StyledCheckoutHeadline
                children={translate('checkout-page.contact-information')}
                size="h3"
            ></StyledCheckoutHeadline>
            {!isLoadingContacts && !!showTabs && (
                <TabMenu
                    onTabChange={(_tab, i) => onTabChange(!i)}
                    size={'small'}
                    storeOpenTabInLocation={false}
                    initialTabIndex={useExistingContactInfo ? 0 : 1}
                    tabs={[
                        {
                            content: (
                                <SelectorTab
                                    fieldName="existingContactInfoOption"
                                    options={contactOptions}
                                    selectionSummary={selectionSummary}
                                />
                            ),
                            tabName: translate('checkout-page.existing-contact'),
                        },
                        {
                            content: contactInformationForm,
                            tabName: translate('checkout-page.new-contact'),
                        },
                    ]}
                />
            )}
            {!isLoadingContacts && !showTabs && contactInformationForm}
            {isLoadingContacts && <Spinner />}
        </>
    );
};
