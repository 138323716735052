import Button, { StyledIcon } from '$shared/components/button';
import { Icon } from '$shared/components/icon';
import { theme } from '$theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { inputRequiredStyle } from '../input-element/styled';

export const StyledFileList = styled.ul(() => ({
    marginTop: theme.space[2],
}));

export const StyledFileListItem = styled.li(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSizes['2xs'],
    color: theme.colors.grey70,
    margin: `${theme.space[2]} 0`,
}));

export const StyledFileListItemName = styled.span(() => ({
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

export const StyledFileListItemRemoveButton = styled(Button)(() => ({
    flexShrink: 0,
    [`${StyledIcon}`]: {
        '&:first-of-type:last-of-type': {
            padding: '0 0 0 6px',
        },
    },
}));
export const StyledAttachedFileIcon = styled(Icon)(() => ({
    flexShrink: 0,
    marginRight: '10px',
}));

export const StyledFileInputLabel = styled.label<{ required?: boolean }>(
    () => ({
        marginRight: '10px',
    }),
    ifProp('required', { ...inputRequiredStyle })
);

export const FileInputBaseStyle: React.CSSProperties = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

export const FileInputFocusedStyle = {
    borderColor: theme.colors.primary,
};

export const FileInputAcceptStyle = {
    borderColor: '#00e676',
};

export const FileInputRejectStyle = {
    borderColor: theme.colors.negative,
};

export const StyledRejectionListItem = styled.li({
    color: theme.colors.negative,
    marginBottom: theme.space[3],
    fontSize: theme.fontSizes['2xs'],
});
