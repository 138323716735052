import { theme } from '$theme';
import styled from '@emotion/styled';
import { HighlightWord } from '$shared/components/highlight-word';
import { Link } from '$shared/components/link';

export const StyledSearchContent = styled(Link)(({ theme }) => ({
    padding: `${theme.space[4]} 0`,
    borderBottom: `1px solid ${theme.colors.borderColor}`,
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.defaultText,

    '&:hover': {
        color: 'inherit',
    },
}));

export const StyledCategory = styled(StyledSearchContent)<{ isLoading?: boolean }>(() => ({
    paddingBottom: theme.space[2],
}));

export const StyledProduct = styled(StyledSearchContent)<{ isLoading?: boolean }>(() => ({
    flexDirection: 'row',
    padding: `${theme.space[2]} 0`,
}));

export const StyledProductLabel = styled.span({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingLeft: theme.space[4],
});

export const StyledProductName = styled.p(() => ({
    color: theme.colors.black,
    marginBottom: '0.25em',
}));

export const StyledProductSecondaryName = styled.p(() => ({
    color: theme.colors.grey80,
    fontSize: theme.fontSizes.xs,
    marginBottom: '0.25em',
}));

export const StyledProductItemNumber = styled.p(() => ({
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
}));

export const StyledProductPrice = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    color: theme.colors.grey90,
}));

export const StyledProductImage = styled.div(({ theme }) => ({
    flexShrink: 0,
    width: '46px',
    height: '46px',
    border: `1px solid ${theme.colors.borderColor}`,
    alignSelf: 'center',

    img: {
        background: theme.colors.white,
    },
}));

export const HighlightDescription = styled(HighlightWord)(({ theme }) => ({
    display: 'block',
    color: theme.colors.grey80,
}));
