import { useTranslation } from '$shared/utils';
import { useState, ReactNode, FC } from 'react';
import { BasketSummarySkeleton } from './basket-summary-skeleton';
import {
    StyledBasketSummary,
    StyledBasketSummaryHeader,
    StyledBasketSummaryList,
    StyledBasketSummaryTerms,
    StyledBasketSummaryTitle,
} from './basket-summary-styles';

let increment = 1;

interface BasketSummaryProps {
    actionLink: ReactNode;
    className?: string;
    headerLink?: ReactNode;
    isLoading?: boolean;
    listItems: ReactNode;
    showHeader: boolean;
    terms?: ReactNode;
}

export const BasketSummary: FC<BasketSummaryProps> = (props) => {
    const [headerDomId] = useState(`basket-summary-${increment++}`);
    const { translate } = useTranslation();
    const heading = translate('checkout-page.order-summary');

    return (
        <StyledBasketSummary
            aria-labelledby={props.showHeader ? headerDomId : undefined}
            aria-label={props.showHeader ? undefined : heading}
            className={props.className}
            hasHeader={props.showHeader}
            role="complementary"
        >
            {props.isLoading ? (
                <BasketSummarySkeleton showHeader={props.showHeader} terms={props.terms} />
            ) : (
                <>
                    {props.showHeader && (
                        <StyledBasketSummaryHeader id={headerDomId}>
                            <StyledBasketSummaryTitle children={heading} />
                            {props.headerLink || null}
                        </StyledBasketSummaryHeader>
                    )}
                    <StyledBasketSummaryList children={props.listItems} />
                    {props.terms && <StyledBasketSummaryTerms children={props.terms} />}
                </>
            )}
            {props.actionLink}
        </StyledBasketSummary>
    );
};
