import { useForm } from 'react-hook-form';
import { InformationForm } from '../models/information-form';
import { OrderState } from '../models/order-state';

export const useInformationForm = (orderState: OrderState) =>
    useForm<InformationForm>({
        criteriaMode: 'all',
        defaultValues: {
            contactInfo: orderState.newContactInfo,
            customerReferenceNumber: orderState.customerReferenceNumber,
            deliveryAddress: orderState.deliveryAddress,
            deliveryAddressOption: orderState.deliveryAddressOption,
            existingContactInfoOption: orderState.existingContactInfoChosenOption,
            useDeliveryAddressId: orderState.useDeliveryAddressId,
            useExistingContactInfo: orderState.useExistingContactInfo,
        },
        shouldFocusError: false,
    });
