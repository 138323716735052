import { breakpoints } from '$theme';
import styled, { CSSObject } from '@emotion/styled';
import React, { ComponentProps } from 'react';
import { ifProp, switchProp } from 'styled-tools';
import { useHeadlineAs } from './utils';
import { HeaderTag } from '~/lib/data-contract';

export interface HeadlineProps {
    /**
     * The styling size
     *
     * If you want to not apply any font-size or override using `css={}` set to `null`
     */
    size?: HeaderTag | 'h6' | 1 | 2 | 3 | 4 | 5 | 6 | null;
    noMargin?: boolean;
    hideOnMobile?: boolean;
    textTransform?: CSSObject['textTransform'];
}

export const StyledHeadline = styled.h1<HeadlineProps & { asText?: boolean }>(
    ({ theme, noMargin }) => ({
        color: theme.colors.black,
        margin: 0,
        marginBottom: noMargin ? 0 : theme.space[3],
    }),
    ifProp({ asText: false }, ({ theme, textTransform }) => ({
        lineHeight: theme.lineHeights.headline,
        fontFamily: theme.fonts.headline,
        fontWeight: theme.fontWeights.bold,
        letterSpacing: theme.letterSpacings.sm,
        textTransform: textTransform || 'uppercase',
        wordBreak: 'break-word',
    })),
    ifProp('hideOnMobile', {
        [breakpoints.xs]: {
            display: 'none',
        },
    }),
    switchProp('size', {
        '1': ({ theme }) => ({
            fontSize: theme.fontSizes['3xl'],
            [breakpoints.md]: {
                fontSize: theme.fontSizes['4xl'],
            },
        }),
        '2': ({ theme }) => ({
            fontSize: theme.fontSizes['2xl'],
            [breakpoints.md]: {
                fontSize: theme.fontSizes['3xl'],
            },
        }),
        '3': ({ theme }) => ({
            fontSize: theme.fontSizes['xl'],
            [breakpoints.md]: {
                fontSize: theme.fontSizes['2xl'],
            },
        }),
        '4': ({ theme }) => ({
            fontSize: theme.fontSizes['lg'],
            [breakpoints.md]: {
                fontSize: theme.fontSizes['xl'],
            },
        }),
        '5': ({ theme }) => ({
            fontSize: theme.fontSizes['md'],
            [breakpoints.md]: {
                fontSize: theme.fontSizes['lg'],
            },
        }),
        '6': ({ theme }) => ({
            fontSize: theme.fontSizes['sm'],
        }),
    })
);

/**
 * Merge of StyledHeadline's own props with those inherited from `E` - the underlying dynamic element type (`"h1"` by default)
 */

/**
 * Use `size` to choose headline style. Markup will follow suit (ie. `size={3}` renderes as `<h3>` by default)
 *
 * Override rendered markup with `as` attribute. For instance you can render a h3 like a size 1:
 *
 * ```tsx
 * <Headline as="h3" size={1}>An h3 in markup. Styled like an h1.</Headline>
 * ```
 */

export const Headline = React.forwardRef<HTMLHeadingElement, ComponentProps<typeof StyledHeadline>>(
    ({ size = 1, as, ...restProps }, ref) => {
        function getHeaderSize(sizeProp?: HeadlineProps['size']) {
            if (typeof sizeProp === 'number') {
                return sizeProp;
            }

            switch (sizeProp) {
                case 'h1': {
                    return 1;
                }
                case 'h2': {
                    return 2;
                }
                case 'h3': {
                    return 3;
                }
                case 'h4': {
                    return 4;
                }
                case 'h5': {
                    return 5;
                }
                case 'h6': {
                    return 6;
                }
                case 'p': {
                    return 'p';
                }
                default: {
                    return null;
                }
            }
        }

        const headerSize = getHeaderSize(size);

        const headlineAs = useHeadlineAs({ as, size: headerSize });
        return (
            <StyledHeadline
                ref={ref}
                as={headlineAs}
                asText={headerSize === 'p'}
                {...restProps}
                size={headerSize}
            />
        );
    }
);
export default Headline;
