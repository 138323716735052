import { switchProp } from 'styled-tools';
import styled from '@emotion/styled';
import { SpotMediaProps } from './spot-media';

export const StyledSpotMedia = styled.div<SpotMediaProps>(
    {
        gridArea: 'media',
        overflow: 'hidden',
    },
    switchProp('cover', {
        true: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
    })
);
