import Link from '$shared/components/link';
import { prettyDate } from '$shared/utils/pretty-date.helper';
import { FC } from 'react';
import { JobVacancyTableProps } from '../../models/models';
import { StyledJobTitle, StyledJobsMobileTable } from './style';

const MobileJobLayout: FC<JobVacancyTableProps> = ({ items, formattingCulture }) => {
    return (
        <StyledJobsMobileTable>
            {items?.map((item, i) => (
                <tr key={`${item.jobTitle}${i}`}>
                    <Link href={item.link?.url} target="_blank">
                        <StyledJobTitle>{item.jobTitle}</StyledJobTitle>
                        <div> {item.businessAreaName} </div>
                        <div> {item.locationName} </div>
                        <div> {prettyDate(item.publishedDate, formattingCulture)} </div>
                        <br />
                    </Link>
                </tr>
            ))}
        </StyledJobsMobileTable>
    );
};
export default MobileJobLayout;
