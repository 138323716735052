import { theme } from '$theme';
import styled from '@emotion/styled';

export const StyledFormOverlay = styled.div(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    padding: theme.space[4],
}));
