import { toast } from 'react-toastify';
import { http } from '~/lib/http';
export const fetcher = <T>(url: string, options?: RequestInit) => async () => {
    const response = await http(url, options);
    const contentType = response.headers.get('content-type') || '';

    if (!response.ok) {
        const errorMessage = `${response.status} ${response.statusText} at ${url}`;
        toast.error(errorMessage);
        throw new Error(errorMessage);

        // Currently only support JSON
    } else if (!contentType.includes('application/json')) {
        throw new Error(`Content type ${contentType} handling not implemented`);
    }

    const data: T = await response.json();
    return data;
};
