import React from 'react';
import { getTextColor, PotentialThemeColorType, usePotentialThemeColor } from '$theme';
import { StyledSpotContent } from './style';
import { SpotMediaProps } from '../spot-media';
import { VerticalAlignment } from '~/lib/data-contract';

export type SpotContentProps = {
    /**
     * Horizontal align content
     */
    alignment?: 'left' | 'center' | 'right';

    /**
     * Horizontal align content
     */
    position?: VerticalAlignment;

    /**
     * Background color for the container and content.
     * This is also how it calculates the text color if not provided.
     */
    backgroundColor?: PotentialThemeColorType;

    /**
     * Content text color.
     */
    textColor?: PotentialThemeColorType;

    /**
     * Indicated if there is media and what type of media
     */
    mediaType?: SpotMediaProps['type'];

    children: React.ReactNode;
};

export const SpotContent = ({
    backgroundColor = 'none',
    alignment = 'left',
    position = VerticalAlignment.top,
    textColor,
    ...rest
}: SpotContentProps) => {
    const backgroundColorValue = usePotentialThemeColor(backgroundColor);
    const textColorValue = textColor
        ? usePotentialThemeColor(textColor)
        : getTextColor(backgroundColorValue);

    return (
        <StyledSpotContent
            backgroundColor={backgroundColorValue}
            textColor={textColorValue}
            alignment={alignment}
            position={position}
            {...rest}
        />
    );
};

export default SpotContent;
