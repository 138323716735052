import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';

export const StyledOrderDetailsWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr',
    columnGap: theme.space[3],
    rowGap: theme.space[6],
    marginBottom: theme.space[6],

    [breakpoints.xxs]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
});

export const StyledOrderDetailsTitle = styled.p({
    fontWeight: theme.fontWeights.semiBold,
    marginBottom: theme.space[2],
    fontSize: theme.fontSizes.xs,

    [breakpoints.sm]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        fontSize: theme.fontSizes.md,
    },
});

export const StyledOrderDetailsText = styled.div({
    color: theme.colors.grey90,
    lineHeight: theme.lineHeights.base,
    fontSize: theme.fontSizes.xs,
});
