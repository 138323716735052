import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from '$shared/utils';
import { RegisterOptions } from 'react-hook-form';
import { FormElementInputType, IFormBaseElements, IFormElement } from '~/lib/data-contract';
import { DateEpiField } from '../date-epi-field';
import { FileUploadEpiField } from '../file-upload-epi-field';
import { InputEpiField } from '../input-epi-field';
import { SelectorEpiField } from '../selector-epi-field';
import { TextareaEpiField } from '../textarea-epi-field';
import { ChoiceEpiField } from '../choice-epi-field/choice-epi-field';
import { SingleChoiceEpiField } from '../single-choice-epi-field/single-choice-epi-field';

const formElementMap = (element: IFormElement) => {
    switch (element.inputType) {
        case FormElementInputType.textarea:
            return <TextareaEpiField {...element} />;
        case FormElementInputType.text:
        case FormElementInputType.number:
        case FormElementInputType.email:
            return <InputEpiField {...element} />;
        default:
            return null;
    }
};

const fieldMap = (element: IDynamicFieldProps) => {
    switch (element.type) {
        case 'dateFormElement':
            return <DateEpiField {...element} />;
        case 'selectionFormElement':
            return <SelectorEpiField {...element} />;
        case 'choiceFormElement':
            return <ChoiceEpiField {...element} />;
        case 'singleChoiceFormElement':
            return <SingleChoiceEpiField {...element} />;
        case 'fileUploadFormElement':
            return <FileUploadEpiField {...element} />;
        default:
            return formElementMap(element);
    }
};

export type IDynamicFieldProps = IFormBaseElements & { rules?: RegisterOptions };

export const DynamicField: FC<IDynamicFieldProps> = (props) => {
    const { translate } = useTranslation();
    const { type } = props;

    const mappedValidationRules = {
        ...(props.value ? { value: props.value } : {}),
        required: props.requiredMessage || props.required,
        ...(props.regex
            ? {
                  pattern: {
                      value: new RegExp(props.regex),
                      message: props.regexMessage || '',
                  },
              }
            : props.inputType === FormElementInputType.number
            ? {
                  pattern: {
                      value: new RegExp('^[0-9]*$'),
                      message:
                          translate('forms.fields.number.not-a-valid-number-validation-text') ||
                          props.regexMessage ||
                          '',
                  },
              }
            : {}),
    };

    const Field = fieldMap({ ...props, rules: mappedValidationRules });

    if (!Field) {
        console.error(`Couldn't map form field of type ${type}`);
        return null;
    }

    return Field;
};
