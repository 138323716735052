import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { HierarchyTreeNode } from '$features/search/hierarchy-tree/hierarchy-tree-node';
import { SidePanel } from '$shared/components/side-panel/side-panel';
import { useWindowSize } from '$shared/hooks/use-window-size';
import { formatString, useTranslation } from '$shared/utils';
import { Accordion } from '$shared/components/accordion';
import {
    StyledAccordionHeader,
    StyledHierarchyDesktopWrapper,
    StyledHierarchyTree,
    StyledSearchProductsTab,
    MobileSearchProductsHeader,
    MobileCta,
    StyledAccordionContainer,
} from './search-products-tab-styles';
import { Icon } from '$shared/components/icon';
import { findNodeById } from '$features/search/hierarchy-tree/hierarchy-tree-utils';
import { StyledHierarchySelection } from '$features/search/hierarchy-tree/hierarchy-tree-styles';
import { useFilter } from '$shared/utils/filter';
import { FilterProductList } from '$templates/blocks/m170-filter/components/filter-product-list';
import ResetFilterButton from '$shared/components/facets/reset-filter-button/facet-search-bar';
import { StockFacet } from '$templates/blocks/m170-filter/components/specific-facets/stock-facet';
import { IntelligentSortingFacet } from '$templates/blocks/m170-filter/components/specific-facets/intelligent-sorting-facet';
import { DynamicFacets } from '$templates/blocks/m170-filter/components/dynamic-facets';
import {
    MobileFacetsContainer,
    StyledMobileFilterCta,
    StyledMobileFilterCtas,
} from '$templates/blocks/m170-filter/style';
import Container from '$shared/components/container';
import { useAuthentication } from '$shared/hooks/useAuthentication';

interface SearchProductsTabProps {
    hierarchyNodes: HierarchyTreeNode[];
    selectedHierarchyId: string | undefined;
    setSelectedHierarchyId: (selection: string | undefined) => void;
    onProductCountChange: (productCount: number) => void;
    searchTerm?: string;
}

const ACCORDION_ID = 'categories';

export const SearchProductsTab: FC<SearchProductsTabProps> = (props) => {
    const [showHierarchyPopup, setShowHierarchyPopup] = useState(false);
    const [showMobileFilterPopup, setShowMobileFilterPopup] = useState(false);
    const [showCategories, setShowCategories] = useState<string>(ACCORDION_ID); // open: ACCORDION_ID, closed: ''
    const { isAuthenticated, userFound } = useAuthentication();
    const { isDesktop } = useWindowSize();
    const { translate } = useTranslation();
    const { setSearchText, resetFilter, resultsTotal, searchText } = useFilter();

    useEffect(() => {
        resultsTotal !== undefined && props.onProductCountChange(resultsTotal);
    }, [resultsTotal]);

    const onNewSearch = (search: string) => {
        props.setSelectedHierarchyId?.(undefined);
        setSearchText?.(search);
        setShowCategories(ACCORDION_ID);
    };

    useEffect(() => {
        // setting filter searchfacet
        !!props.searchTerm && !searchText && setSearchText?.(props.searchTerm);
        !!props.searchTerm &&
            !!searchText &&
            props.searchTerm !== searchText &&
            onNewSearch?.(props.searchTerm); // KYOC-1188
    }, [props.searchTerm, searchText]);

    const hierarchyTree = useMemo(
        () => (
            <StyledHierarchyTree
                isDesktop={!!isDesktop}
                hierarchyId={searchText}
                nodes={props.hierarchyNodes}
                onSelectionChanged={(id) => {
                    props.setSelectedHierarchyId(id);
                    setShowHierarchyPopup(false);
                    if (id) {
                        setShowCategories('');
                    }
                }}
                selection={props.selectedHierarchyId}
            />
        ),
        [props.hierarchyNodes, props.selectedHierarchyId]
    );

    const selectedNode = useMemo(
        () => findNodeById(props.hierarchyNodes, props.selectedHierarchyId),
        [props.hierarchyNodes, props.selectedHierarchyId]
    );

    const onResetFilter = useCallback(() => {
        resetFilter?.();
        // The search term is locked for now, so we set it straight away after resetting.
        !!props.searchTerm && props.searchTerm !== searchText && setSearchText?.(props.searchTerm);
    }, [props.searchTerm, searchText]);

    return isDesktop === undefined ? null : (
        <StyledSearchProductsTab>
            {isDesktop && (
                <StyledAccordionContainer>
                    <Accordion
                        value={showCategories}
                        header={
                            <StyledAccordionHeader>
                                {translate('search.search-results-page.categories')}
                                {!!selectedNode && !showCategories && (
                                    <StyledHierarchySelection>
                                        {` / ${selectedNode?.label}`}
                                    </StyledHierarchySelection>
                                )}
                            </StyledAccordionHeader>
                        }
                        icon={<Icon icon="chevronUp" size={15} />}
                        onValueChange={setShowCategories}
                        overrideId={ACCORDION_ID}
                    >
                        <StyledHierarchyDesktopWrapper>
                            {hierarchyTree}
                        </StyledHierarchyDesktopWrapper>
                    </Accordion>
                    {((isAuthenticated && userFound) || !!props.selectedHierarchyId) && (
                        <Accordion
                            initAsOpen
                            header={
                                <StyledAccordionHeader>
                                    {translate('search.search-results-page.filters')}
                                </StyledAccordionHeader>
                            }
                            contentContainerStyle={{ padding: 2 }}
                            icon={<Icon icon="chevronUp" size={15} />}
                        >
                            <StockFacet />
                            <IntelligentSortingFacet />
                            {!!props.selectedHierarchyId && <DynamicFacets />}
                            <ResetFilterButton handleClick={onResetFilter} />
                        </Accordion>
                    )}
                </StyledAccordionContainer>
            )}
            {!isDesktop && (
                <MobileSearchProductsHeader>
                    <MobileCta onClick={() => setShowHierarchyPopup(true)}>
                        <Icon icon="list" size={20} />
                        <span>
                            {translate('search.search-results-page.categories')}
                            {!!selectedNode && (
                                <StyledHierarchySelection>
                                    {` / ${selectedNode?.label}`}
                                </StyledHierarchySelection>
                            )}
                        </span>
                    </MobileCta>
                    {((isAuthenticated && userFound) || !!props.selectedHierarchyId) && (
                        <MobileCta onClick={() => setShowMobileFilterPopup(true)}>
                            {translate('search.search-results-page.filters')}
                            <Icon icon="filter" size={20} />
                        </MobileCta>
                    )}
                </MobileSearchProductsHeader>
            )}

            <Container>
                <FilterProductList id={`${props.searchTerm}`} />
            </Container>

            {!isDesktop && (
                <SidePanel
                    children={hierarchyTree}
                    maxWidth={500}
                    onChange={setShowHierarchyPopup}
                    show={showHierarchyPopup}
                    title={translate('search.search-results-page.categories')}
                />
            )}
            {!isDesktop && (
                <SidePanel
                    show={showMobileFilterPopup}
                    onChange={setShowMobileFilterPopup}
                    maxWidth={414}
                    maxContentHeight={'calc(100vh - 160px)'} // Header + fixed bottom
                    title={translate('product-list-page.mobile-filters-title')}
                >
                    <MobileFacetsContainer noExtraSpacing>
                        <StockFacet />
                        <IntelligentSortingFacet />
                        {!!props.selectedHierarchyId && <DynamicFacets />}
                    </MobileFacetsContainer>
                    <StyledMobileFilterCtas>
                        <StyledMobileFilterCta onClick={onResetFilter} variant="secondary">
                            {translate('product-filter.clear-filter')}
                        </StyledMobileFilterCta>
                        <StyledMobileFilterCta
                            onClick={() => setShowMobileFilterPopup(false)}
                            variant="primary"
                        >
                            {formatString(
                                translate('product-list-page.mobile-filters-see-results'),
                                resultsTotal
                            )}
                        </StyledMobileFilterCta>
                    </StyledMobileFilterCtas>
                </SidePanel>
            )}
        </StyledSearchProductsTab>
    );
};
