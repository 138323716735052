import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { theme } from '$theme';
import { mapOfAllIcons } from '~/icons';

export interface IconProps {
    /**
     * Choose from the list of icon names.
     */
    icon: keyof typeof mapOfAllIcons;
    /**
     * The color of the icon
     */
    color?: keyof typeof theme.colors;
    /**
     * Flip the svg
     */
    inverted?: boolean;
    /**
     * Pick from the default sizes, or choose to give a number which will be rendered in pixels.
     */
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'default' | number;
}

export const Icon: FC<IconProps> = ({ icon, size, color = theme.colors.black, ...restProps }) => {
    if (!icon) {
        return null;
    }

    const SvgFromMap = mapOfAllIcons[icon];

    const StyledSvg = useMemo(
        () =>
            styled(SvgFromMap)(
                ({ size }) =>
                    typeof size === 'number' && {
                        width: size,
                        height: size,
                    },
                switchProp('size', {
                    sm: () => ({
                        height: '.5rem',
                        width: '.5rem',
                    }),
                    default: () => ({
                        height: '1rem',
                        width: '1rem',
                    }),
                    md: () => ({
                        height: '1.5rem',
                        width: '1.5rem',
                    }),
                    lg: () => ({
                        height: '2rem',
                        width: '2rem',
                    }),
                    xl: () => ({
                        height: '4rem',
                        width: '4rem',
                    }),
                }),
                ({ color }: { color: keyof typeof theme.colors }) => ({
                    color: theme.colors[color],
                }),
                ({ inverted }) =>
                    inverted
                        ? {
                              transform: 'rotate(180deg)',
                          }
                        : {}
            ),
        [icon]
    );

    return <StyledSvg size={size} color={color} aria-hidden="true" {...restProps} />;
};

export default Icon;
