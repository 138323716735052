import { theme } from '$theme';
import styled from '@emotion/styled';

export const StockCount = styled.div({
    color: 'inherit',
});

export const StyledStockOnHoverWrapper = styled.div({
    display: 'inline-block',
    position: 'relative',
});

const stockHoverColor = 'rgba(0, 0, 0, 0.1)';

export const StyledStockOnHoverButton = styled.button({
    alignItems: 'center',
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    borderRadius: 4,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.grey90,
    display: 'flex',
    gap: 8,
    maxWidth: '100%',
    padding: 0,
    position: 'relative',

    ':hover, &[data-state="open"]': {
        '&:after': {
            content: '""',
            position: 'absolute',
            borderRadius: 4,
            inset: -5,
            backgroundColor: stockHoverColor,
        },
    },
});
