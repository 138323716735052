import React from 'react';
import { useTranslation } from '~/shared/utils';
import { Icon } from '$shared/components/icon';
import {
    StyledBackButton,
    StyledBasketHeader,
} from '$templates/pages/components/P300BasketPage/style';

type Props = { onBack?: () => void };

export const BackArrow = ({ onBack }: Props) => {
    const { translate } = useTranslation();

    return (
        <StyledBasketHeader>
            <StyledBackButton onClick={onBack}>
                <Icon icon="arrowLeft" color="blue90" size={20} />
                {translate('basket.back')}
            </StyledBackButton>
        </StyledBasketHeader>
    );
};
