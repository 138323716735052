import { AddressDto } from '$order-models/*';
import { cancellableFetch } from '../../shared/utils/request';
import { GET_ADDRESSES_URL } from './order-endpoints';

const url = GET_ADDRESSES_URL;
const method = 'GET';
const headers = { 'Content-Type': 'application/json' };

export function getAddresses() {
    return cancellableFetch<AddressDto[]>({
        url,
        options: {
            method,
            headers,
        },
    });
}
