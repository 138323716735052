import React, { FC } from 'react';
import styled from '@emotion/styled';
import Flex from '../flex';
import Spinner from '../spinner';

type Props = {
    showBackdrop?: boolean;
};

export const Loading: FC<React.PropsWithChildren<Props>> = (
    props: React.PropsWithChildren<Props>
) => {
    return (
        <Flex style={{ position: 'relative' }} alignItems="center" justifyContent="center" grow>
            {props.showBackdrop && <Backdrop />}
            {props.children ? props.children : <Spinner size="medium" />}
        </Flex>
    );
};

const Backdrop = styled.div(({ theme }) => ({
    position: 'fixed',
    backgroundColor: theme.colors.black,
    height: '100%',
    opacity: '50%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndices.loadingOverlay,
    width: '100%',
}));
