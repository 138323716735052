import { StyledButton } from '$shared/components/button';
import Headline from '$shared/components/headline';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';

const breakpoint = breakpoints.lg;

export const StyledQuantityBasketLine = styled.div({
    display: 'grid',
    gap: `${theme.space[1]} ${theme.space[3]}`,
    grid: `${theme.sizes.buttonHeight}px auto auto / 1fr auto auto`,
    alignItems: 'center',

    [breakpoint]: {
        grid: `${theme.sizes.buttonHeight}px auto / 1fr auto auto auto`,
    },

    [`${StyledButton}`]: {
        justifyContent: 'center',
        gridArea: '3 / 1 / 4 / 4',

        [breakpoint]: {
            gridArea: '1 / 4 / 2 / 5',
        },
    },
});

export const StyledQuantityPriceHeadline = styled(Headline)<{ discount?: boolean }>(
    ({ discount }) => ({
        color: discount ? theme.colors.red50 : 'inherit',
    })
);

export const StyledQuantityAdditionalText = styled.div({
    gridRow: 2,
    gridColumn: 3,
    fontSize: 11,
    maxWidth: '130px',
    textAlign: 'left',

    [breakpoints.md]: {
        fontSize: theme.fontSizes['3xs'],
    },
});

export const StyledDiv = styled.div({
    alignSelf: 'flex-start',
    gridArea: '1 / 3 / 3 / 4',
    position: 'relative',

    [breakpoint]: {
        gridArea: '1 / 3 / 3 / 4',
    },
});
