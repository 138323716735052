import styled from '@emotion/styled';

export const StyledQuantitySpinner = styled.div(({ theme }) => {
    const size = theme.space[6];

    return {
        display: 'grid',
        justifyContent: 'start',
        grid: `${size} / ${size} auto ${size}`,
        position: 'relative',
    };
});

export const StyledSpinnerButton = styled.button<{ isIncrement: boolean }>(
    ({ isIncrement, theme }) => ({
        appearance: 'none',
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.grey40}`,
        borderWidth: isIncrement ? '1px 1px 1px 0' : '1px 0 1px 1px',
        color: theme.colors.grey90,
        cursor: 'pointer',
        overflow: 'hidden',
        position: 'relative',
        padding: 0,

        '&[aria-disabled=true]': {
            color: theme.colors.grey40,
            cursor: 'default',
        },

        ':focus': {
            outline: `2px solid ${theme.colors.blue50}`,
            zIndex: 1,
        },

        ':before, :after': {
            background: 'currentColor',
            height: 2,
            left: '50%',
            position: 'absolute',
            top: '50%',
            width: 15,
        },

        ':before': {
            content: '""',
            transform: 'translate(-50%, -50%)',
        },

        ':after': {
            content: isIncrement ? '""' : undefined,
            transform: 'translate(-50%, -50%) rotate(90deg)',
        },
    })
);

export const StyledSpinnerInput = styled.input<{ characterLength: number }>(
    ({ characterLength, theme }) => ({
        border: `1px solid ${theme.colors.grey40}`,
        borderRadius: 0,
        color: theme.colors.grey90,
        fontSize: theme.fontSizes['md'],
        textAlign: 'center',
        padding: 0,
        minWidth: '6ch',
        width: `${characterLength + 4}ch`,

        '&[readonly]': {
            color: theme.colors.grey40,
            cursor: 'default',
        },

        ':focus': {
            outline: `2px solid ${theme.colors.blue50}`,
            zIndex: 1,
        },
    })
);
