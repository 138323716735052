import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as RadixDialog from '@radix-ui/react-dialog';

export const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});

export const contentShow = keyframes({
    '0%': { transform: 'translate(100%)' },
    '100%': { transform: 'translate(0)' },
});

export const DialogOverlay = styled(RadixDialog.Overlay)(({ theme }) => ({
    backgroundColor: 'rgba(0,0,0, 0.6)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndices[1],
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 200ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
}));

export const DialogContent = styled(RadixDialog.Content)<{ maxWidth: number }>(
    ({ maxWidth, theme }) => ({
        backgroundColor: 'white',
        boxShadow:
            'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        maxWidth: `${maxWidth}px`,
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme.zIndices[1] + 1,
        '@media (prefers-reduced-motion: no-preference)': {
            animation: `${contentShow} 300ms cubic-bezier(0.16, 1, 0.3, 1)`,
            willChange: 'transform',
        },
        '&:focus': { outline: 'none' },
    })
);

export const DialogClose = styled(RadixDialog.Close)({
    all: 'unset',
    borderRadius: '20px',
    width: '25px',
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
});

export const DialogContentWrapper = styled.div({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
});

export const DialogHeader = styled.div(({ theme }) => ({
    height: '100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.space[4]} ${theme.space.gutter}`,
}));

export const DialogChildrenContainer = styled.div({
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
});
