import React, { FC, useState } from 'react';
import { productImageSrc } from '$shared/utils';
import { ProductImage } from '../image';
import { Overlay } from '../overlay';
import { OverlayImageWrapper, StyledProductLineImage, StyledSpinner } from './style';

type Props = {
    imageUrl?: string;
    alt?: string;
};

export const ProductLineImage: FC<Props> = ({ imageUrl, alt }) => {
    const [isLoadingZoomedImage, setIsLoadingZoomedImage] = useState(true);

    return (
        <Overlay
            disabled={!imageUrl}
            content={
                <OverlayImageWrapper>
                    {isLoadingZoomedImage && <StyledSpinner />}
                    <img
                        alt={alt}
                        key={imageUrl}
                        src={productImageSrc(imageUrl)}
                        onLoad={() => setIsLoadingZoomedImage(false)}
                        onError={() => setIsLoadingZoomedImage(false)}
                    />
                </OverlayImageWrapper>
            }
        >
            <StyledProductLineImage noImage={!imageUrl}>
                <ProductImage
                    alt={alt}
                    src={imageUrl}
                    layout="intrinsic"
                    width={118}
                    height={118}
                    objectFit="contain"
                />
            </StyledProductLineImage>
        </Overlay>
    );
};
