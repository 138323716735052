import { getContacts } from "$features/order-service/get-contacts";
import { ContactDto } from "$order-models/*";
import { Option } from "$shared/components/form";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

const queryKey = "useSavedContacts";

export function useSavedContacts() {
    const { data = [], isLoading } = useQuery(
        [queryKey],
        getContacts
    );

    const options = useMemo(() => data.map<Option<ContactDto>>((contact, i) => ({
        checked: !i,
        label: contact.name || '',
        option: contact,
        value: contact.contactId || '',
    })), [data]);

    return {
        contactOptions: options,
        isLoadingContacts: isLoading,
        savedContacts: data,
    }
}