import { useUrlQueryState } from '$utils/query-state';
import { stringToNumber } from '$utils/string-format.helper';
import { useState, useEffect } from 'react';
import { FacetActionData, FilterActions, IParamsOfArrays } from '..';
import { FILTER_PAGE_KEY, FILTER_SEARCH_TEXT_KEY } from '../service/constants';
import { useFilterQueryParams } from './useFilterQueryParams';

export type UseFilterQueryStateProps = FilterActions & {
    activeQueryState?: IParamsOfArrays;
};

export const useFilterQueryState = (filterId: string): UseFilterQueryStateProps => {
    const { paramsToString, stringToParams } = useFilterQueryParams();
    const { queryState, setQueryState } = useUrlQueryState();
    const [activeQueryState, setActiveQueryState] = useState<IParamsOfArrays>();
    
    useEffect(() => {
        const [initialFilterState] = queryState?.[filterId] || [];
        const params = stringToParams(initialFilterState);
        setActiveQueryState(params || {});
    }, [filterId]);

    function toggleFacetOption({ facet, options, overwrite }: FacetActionData) {
        if (!facet || !options) return;

        const { [facet]: foundFacet, ...filteredFacets } = activeQueryState || {};

        // Ensure options as array:
        const optionsAsArray = Array.isArray(options) ? options : [options];

        const filteredOptions = foundFacet?.filter((AO) => !optionsAsArray.find((o) => o === AO));
        const foundOptions = foundFacet?.filter((AO) => optionsAsArray.find((o) => o === AO));
        const nextOptions = optionsAsArray.filter((o) => !foundOptions?.find((FO) => FO === o));

        // Setup the new value for this facet
        const nextFacetValue = {
            [facet]: overwrite ? optionsAsArray : [...(filteredOptions || []), ...nextOptions],
        };

        // Check if facet has any options left - we might have removed its only
        const removeFacet =
            !nextFacetValue[facet]?.length || nextFacetValue[facet]?.every((v) => !v);

        // Setup next value for activeFacets
        const nextActiveFacetsValue = {
            ...(filteredFacets || {}),
            ...(removeFacet ? {} : nextFacetValue),
        };

        // Reset pagination
        if (facet !== FILTER_PAGE_KEY) delete nextActiveFacetsValue[FILTER_PAGE_KEY];

        setQueryState(filterId, paramsToString(nextActiveFacetsValue));
        setActiveQueryState(nextActiveFacetsValue);
    }

    function setSearchText(query: string) {
        toggleFacetOption({
            facet: FILTER_SEARCH_TEXT_KEY,
            options: [query],
            overwrite: true,
        });
    }

    function resetFilter() {
        setQueryState(filterId);
        setActiveQueryState({});
    }

    // Pagination:

    function getPageIndex() {
        const { [FILTER_PAGE_KEY]: pageParam } = activeQueryState || {};
        const [currentIndex] = pageParam || [1]; // [1] being the initial/default (page) value.
        return stringToNumber(currentIndex);
    }

    function goToNextPage() {
        toggleFacetOption({
            facet: FILTER_PAGE_KEY,
            options: `${getPageIndex() + 1}`,
            overwrite: true,
        });
    }

    function goToPrevPage() {
        toggleFacetOption({
            facet: FILTER_PAGE_KEY,
            options: `${getPageIndex() - 1}`,
            overwrite: true,
        });
    }

    // Actions

    const actions: FilterActions = {
        setFacet(action) {
            toggleFacetOption(action);
        },
        setSearchText,
        goToNextPage,
        goToPrevPage,
        resetFilter,
    };

    // Hook return:

    return { activeQueryState, ...actions };
};
