import { breakpoints } from '$theme';
import { Content, Root } from '@radix-ui/react-popover';
import { keyframes } from '@emotion/react';
import { theme } from '$theme';
import styled from '@emotion/styled';

export const StyledPopover = styled.div<{ fullwidthOnMobile?: boolean }>(
    ({ theme, fullwidthOnMobile }) => ({
        backgroundColor: theme.colors.white,
        borderBottom: `solid 5px ${theme.colors.primary}`,
        boxShadow: theme.shadows.popShadow,
        margin: `${theme.space[2]} auto`,
        width: '100vw',
        maxWidth: fullwidthOnMobile ? '100vw' : '90%',

        [breakpoints.sm]: {
            width: 'auto',
            maxWidth: 'max-content',
            margin: theme.space[2],
        },
    })
);

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

export const StyledAppearOnRoot = styled(Root)({
    display: 'inline-block',
});

export const StyledAppearOnHoverContent = styled(Content)(() => ({
    outline: 'none',
    zIndex: theme.zIndices.max,
    '@media (prefers-reduced-motion: no-preference)': {
        animationDuration: '400ms',
        animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
        willChange: 'transform, opacity',
        '&[data-state="open"]': {
            '&[data-side="top"]': { animationName: slideDownAndFade },
            '&[data-side="right"]': { animationName: slideLeftAndFade },
            '&[data-side="bottom"]': { animationName: slideUpAndFade },
            '&[data-side="left"]': { animationName: slideRightAndFade },
        },
    },
}));
