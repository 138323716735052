import { productImageSrc } from '$utils/product-image-src';
import { ImageProps } from 'next/image';
import { FC } from 'react';
import { Image, ImagePropsWithOptionalSrc } from '../image';

const customLoader = ({ src, width }: { src?: string; width?: number }) => {
    return `${productImageSrc(src)}?width=${width}&height=${width}`;
};

// why have it? - to have a custom loader for the product service...

export const ProductImage: FC<ImagePropsWithOptionalSrc> = ({
    width = 1,
    height = 1,
    objectFit = 'contain',
    ...imageProps
}) => {
    return (
        <Image
            {...(imageProps as ImageProps)}
            width={width}
            height={height}
            objectFit={objectFit}
            loader={customLoader}
        />
    );
};
