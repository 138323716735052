import styled from '@emotion/styled';
import { theme } from '$theme';

export const StyledCoreProductList = styled.dl({
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    color: theme.colors.grey80,
    fontSize: theme.fontSizes.xs,

    'dt,dd': {
        padding: 10,
        '&:nth-of-type(2n+1)': {
            backgroundColor: theme.colors.grey20,
        },
        '&:nth-of-type(2n)': {
            backgroundColor: theme.colors.white,
        },
    },
    dt: {
        flex: '50%',
    },
    dd: {
        flex: '50%',
    },
});
