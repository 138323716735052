import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

// GRID
export const StyledCheckoutLineGrid = styled.div(() => ({
    display: 'grid',
    padding: theme.space[3],
    fontSize: theme.fontSizes.xs,
    columnGap: theme.space[4],
    rowGap: theme.space[3],
    alignItems: 'center',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'clamp(95px, 25vw, 130px) 2fr',
    gridTemplateAreas: `
    "image name"
    "image pricestock"
    "total total"
    "services services"`,

    [breakpoints.sm]: {
        padding: `${theme.space[3]} `,
        alignItems: 'center',
        gridTemplateColumns: '1fr 2fr 2fr 1fr 2fr',
        gridTemplateAreas: `"image name price status total"
                            "services services services services services"`,
    },
}));

// HEADINGS
export const StyledBasketLineHeaderGrid = styled(StyledCheckoutLineGrid)<{
    hideOnMobile?: boolean;
}>(
    () => ({
        display: 'flex',
        alignContent: 'center',
        marginBottom: 0,
        [breakpoints.sm]: {
            width: '100%',
        },
    }),
    ifProp('hideOnMobile', {
        display: 'none',
        [breakpoints.md]: {
            display: 'grid',
        },
    })
);

//LIST ITEMS
export const StyledBasketLineImage = styled.div(() => ({
    gridArea: 'image',
    alignSelf: 'start',

    img: {
        backgroundColor: theme.colors.white,
    },

    [breakpoints.sm]: {
        maxWidth: '75px',
    },
}));

export const StyledBasketLineName = styled.div(() => ({
    gridArea: 'name',
    alignSelf: 'baseline',
    color: theme.colors.black,
    lineHeight: theme.lineHeights.base,

    [breakpoints.sm]: {
        alignSelf: 'center',
    },
}));

export const StyledBasketLinePrimary = styled.p(() => ({
    fontWeight: theme.fontWeights.semiBold,
    marginBottom: theme.space[1],

    [breakpoints.sm]: {
        marginBottom: '0',
    },
}));

export const StyledBasketLineSecondary = styled.p(() => ({
    color: theme.colors.blue,
}));

export const StyledBasketLineItemNo = styled.p(() => ({
    color: theme.colors.grey80,
}));

export const StyledBasketLinePrice = styled.div(() => ({
    gridArea: 'price',
    textAlign: 'right',
    fontWeight: theme.fontWeights.regular,
    color: theme.colors.grey90,
}));

export const StyledPriceStockWrapper = styled.div(() => ({
    gridArea: 'pricestock',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: theme.space[2],

    [breakpoints.sm]: {
        display: 'contents',
        gridArea: 'auto',
    },
}));

export const StyledBasketLineAmount = styled.div(() => ({
    gridArea: 'status',
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.space[3],
}));

export const StyledBasketLinebasket = styled.div(() => ({
    gridArea: 'basket',
    display: 'inline-flex',
    gap: theme.space[3],
    justifyContent: 'space-between',
    justifySelf: 'left',
    alignItems: 'center',
}));

export const StyledBasketLineTotalPrice = styled.div(() => ({
    gridArea: 'total',
    textAlign: 'right',
    flex: 1,
    fontWeight: theme.fontWeights.semiBold,
}));

export const StyledServiceLines = styled.div({
    gridArea: 'services',
    display: 'flex',
    flexDirection: 'column',
});

export const StyledServiceLine = styled.div(() => ({
    display: 'grid',
    padding: `${theme.space[1]} 0`,
    fontSize: theme.fontSizes.xs,
    columnGap: theme.space[4],
    rowGap: theme.space[1],
    alignItems: 'center',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '25px clamp(95px, 25vw, 130px) 2fr',
    gridTemplateAreas: `
    "servicepretext servicename serviceprice"
    ". servicenote servicenote"
`,

    [breakpoints.sm]: {
        alignItems: 'center',
        gridTemplateColumns: '1fr 2fr 2fr 1fr 2fr',
        gridTemplateAreas: `"servicepretext servicename serviceprice servicenote servicenote"`,
    },
}));

export const ServiceLinePreText = styled.span(({ theme }) => ({
    gridArea: 'servicepretext',
    lineHeight: theme.lineHeights.base,
    textAlign: 'right',
}));

export const ServiceLineName = styled.span(({ theme }) => ({
    gridArea: 'servicename',
    lineHeight: theme.lineHeights.base,
}));

export const ServiceLinePrice = styled.span(({ theme }) => ({
    gridArea: 'serviceprice',
    fontWeight: theme.fontWeights.regular,
    color: theme.colors.grey90,
    textAlign: 'right',
}));

export const ServiceLineNote = styled.span({
    gridArea: 'servicenote',
    fontStyle: 'italic',
    color: theme.colors.grey90,
});
