import { breakpointSizes } from '$theme';
import { useCallback, useEffect } from 'react';
import { useMedia } from 'react-use';
import { useScrollLock } from '~/shared/hooks/useScrollLock';
import { useMobileMenuState } from './useMobileMenuState';

/**
 * Use to open and close the mobile menu.
 * It ensures a scroll lock when open.
 */
export const useMobileMenu = () => {
    const forceClose = useMedia(`(min-width: ${breakpointSizes.frame}px)`, false);
    const { isOpen, setIsOpen } = useMobileMenuState();
    const { lock, unlock } = useScrollLock();

    const open = useCallback(() => {
        setIsOpen(true);
        lock();
    }, [lock, setIsOpen]);

    const close = useCallback(() => {
        setIsOpen(false);
        unlock();
    }, [setIsOpen, unlock]);

    const toggle = useCallback(() => {
        isOpen ? close() : open();
    }, [isOpen, close, open]);

    /**
     * Close in case the user breaks out of mobile
     * breakpoint - while open.
     */
    useEffect(() => {
        forceClose && close();
    }, [forceClose, close]);

    return {
        isOpen,
        open,
        close,
        toggle,
    };
};
