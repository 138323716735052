import React, { FC, useMemo } from 'react';
import { ProductLineHeader } from '$shared/components/product-line/product-line-header';
import { StyledGenericList } from '$templates/pages/components/MyPages/style';
import { ProductLineSkeleton } from '../product-line-skeleton/product-line-skeleton';

interface ProductListSkeletonProps {
    className?: string;
    numberOfItems?: number;
}

export const ProductListSkeleton: FC<ProductListSkeletonProps> = ({
    className,
    numberOfItems = 20,
}) => {
    const productLineArr = useMemo(() => new Array(numberOfItems).fill(undefined), [numberOfItems]);

    return <StyledGenericList className={className}>
        <li key="plh">
            <ProductLineHeader hideOnMobile={true} />
        </li>
        {productLineArr.map((_, index) => (
            <li key={index}>
                <ProductLineSkeleton />
            </li>
        ))}
    </StyledGenericList>
    };
