import React, { FC } from 'react';
import { IN03MainNavigationWithSubItemsPage } from '~/lib/data-contract';
import MegaSubSubSubMenu from '../mega-sub-sub-sub-menu';

import {
    StyledHeadline,
    StyledMegaSubSubMenu,
    StyledSubNavLink,
    StyledSubSubMenuList,
} from './style';
export type MegaSubSubMenuProps = {
    lists?: IN03MainNavigationWithSubItemsPage[];
    shownWithoutParent?: boolean;
};

export const MegaSubSubMenu: FC<MegaSubSubMenuProps> = ({ lists, ...restProps }) => {
    if (!lists?.length) {
        return null;
    }

    return (
        <StyledMegaSubSubMenu>
            {lists?.map(({ id, link, children }) => (
                <StyledSubSubMenuList {...restProps} key={id}>
                    <StyledSubNavLink href={link?.url} plain={true}>
                        <StyledHeadline size={4} noMargin>
                            {link?.title || link?.text}
                        </StyledHeadline>
                    </StyledSubNavLink>
                    <MegaSubSubSubMenu lists={children} />
                </StyledSubSubMenuList>
            ))}
        </StyledMegaSubSubMenu>
    );
};
export default MegaSubSubMenu;
