import React, { FC, useEffect, useState } from 'react';
import { useFrame } from '$shared/utils';
import { ScrollFade } from '$shared/components/scroll-fade';
import Flex from '$shared/components/flex';
import MegaMenu from './mega-menu';
import {
    StyledHeadline,
    StyledMainMenuNav,
    StyledMainNavigationListItem,
    StyledNavLink,
} from './style';
import { ILink } from '~/lib/data-contract';
import { useNavigate } from '$shared/utils/use-navigate';

export type MainNavigationListItemProps = {
    activeMenuIndex?: number | null;
    link: ILink;
    index: number;
    onHover: (index: number) => void;
};

const HOVER_DELAY_ON_NAVIGATION = 400;

export const MainNavigationListItem: FC<MainNavigationListItemProps> = ({
    onHover,
    link,
    index,
    activeMenuIndex,
}) => {
    let timeoutID: number;
    useEffect(() => cleanup, []);
    function cleanup() {
        clearTimeout(timeoutID);
    }

    return (
        <StyledMainNavigationListItem
            onMouseEnter={() => {
                timeoutID = setTimeout(onHover, HOVER_DELAY_ON_NAVIGATION, index);
            }}
            onMouseLeave={cleanup}
        >
            <StyledNavLink href={link?.url} plain={true}>
                {({ isActive }) => (
                    <StyledHeadline
                        size={5}
                        isActive={isActive}
                        renderAsActive={index === activeMenuIndex}
                        noMargin
                        cursorType={'default'}
                    >
                        {link?.title || link?.text}
                    </StyledHeadline>
                )}
            </StyledNavLink>
        </StyledMainNavigationListItem>
    );
};

export const MainNavigation = () => {
    const { mainMenu } = useFrame();
    const { navigateStart } = useNavigate();
    const { navigationTopLevelNodes = [] } = mainMenu || {};

    const [activeMenuIndex, setActiveMenuIndex] = useState<number | null>(null);
    const megaMenuContent =
        activeMenuIndex !== null ? navigationTopLevelNodes?.[activeMenuIndex] : undefined;

    useEffect(() => {
        setActiveMenuIndex(null);
    }, [navigateStart]);

    return (
        <StyledMainMenuNav
            onMouseEnter={() => setActiveMenuIndex(activeMenuIndex)}
            onMouseLeave={() => setActiveMenuIndex(null)}
        >
            <ScrollFade>
                <Flex as={'ul'}>
                    {navigationTopLevelNodes?.map(({ link, id }, index) => (
                        <MainNavigationListItem
                            key={id}
                            link={link}
                            index={index}
                            activeMenuIndex={activeMenuIndex}
                            onHover={setActiveMenuIndex}
                        />
                    ))}
                </Flex>
            </ScrollFade>
            {!!megaMenuContent?.children?.length && <MegaMenu menu={megaMenuContent} />}
        </StyledMainMenuNav>
    );
};

export default MainNavigation;
