import styled from '@emotion/styled';
import Link from '$shared/components/link';
import { ifProp } from 'styled-tools';

export const StyledMegaSubSubSubMenu = styled.div(() => ({
    display: 'flex',
}));

export const StyledSubNavList = styled.ul(({ theme }) => ({
    padding: `${theme.space[4]} 0 0`,
    paddingRight: theme.space[7],

    ':not(:first-of-type)': {
        paddingLeft: theme.space[7],
    },
    ':last-of-type': {
        paddingRight: 0,
    },
}));
export const StyledSubNavListItem = styled.li(() => ({
    ':last-of-type': {
        [`${StyledSubNavLink}`]: {
            paddingBottom: 0,
        },
    },
}));
export const StyledSubNavLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: `${theme.colors.defaultText}`,
    fontSize: theme.fontSizes?.sm,
    paddingBottom: `${theme.space[3]}`,

    ':hover': {
        color: `${theme.colors.blue}`,
        textDecoration: 'none',
    },
}));
type StyledSpanProps = {
    isActive?: boolean;
};
export const StyledSpan = styled.span<StyledSpanProps>(
    ifProp('isActive', ({ theme }) => ({
        color: `${theme.colors.primary}`,
    }))
);
