import { useMarket } from './../shared/utils/market/hooks/useMarket';
import { getSession } from 'next-auth/react';
import { getCultureInfoString } from '$shared/utils/market/helpers/culture-info.helper';
import { useUserStore } from '$features/authentication/use-user-store';
import { toast } from 'react-toastify';

type IHeaders = RequestInit['headers'];

async function addHeaders(extraHeaders: IHeaders, withAuth = true): Promise<IHeaders> {
    const { market, culture } = useMarket.getState();
    const { actOnBehalfOfCustomer, user, actOnBehalfOfCustomerMarket } = useUserStore.getState();
    const CultureInfo = getCultureInfoString(culture, market);
    const CustomerMarket = user?.customerMarket;

    let defaultHeaders: IHeaders = {
        Accept: 'application/json',
        ...(CultureInfo ? { CultureInfo } : {}),
        ...(actOnBehalfOfCustomerMarket
            ? { CustomerMarket: actOnBehalfOfCustomerMarket }
            : CustomerMarket
            ? { CustomerMarket }
            : {}),
        ...(actOnBehalfOfCustomer ? { CustomerNumber: actOnBehalfOfCustomer } : {}),
    };

    if (withAuth) {
        const session = await getSession();
        if (session?.accessToken) {
            defaultHeaders = {
                ...defaultHeaders,
                Authorization: `Bearer ${session.accessToken}`,
            };
        }
    }
    return { ...defaultHeaders, ...extraHeaders };
}

export async function http(
    input: RequestInfo,
    init: RequestInit = {},
    withAuth?: boolean
): Promise<Response> {
    try {
        const preparedHeaders = await addHeaders(init.headers, withAuth);
        return fetch(input, { ...init, headers: preparedHeaders });
    } catch (e) {
        console.error(`Fetch error: ${input}`);
        toast.error('An error occurred. Please try again later.');
        throw e;
    }
}
