import React, { FC, useEffect, useState } from 'react';
import { EFacetType_Generic, Facet, FilterFacetOption, useFilter } from '$shared/utils/filter';
import { facetOptionMapper } from '../utils/facetOptionMapper';
import FacetCheckbox from '$shared/components/facets/facet-checkbox';
import { FacetWrapper } from '$shared/components/facets/shared/facet-wrapper';
import FacetSelector from '$shared/components/facets/facet-selector';
import { IFacetOption } from '$shared/components/facets';
import { FacetSkeleton } from './facet-skeleton';

type MappedOption = IFacetOption<FilterFacetOption>;
type FacetWithMappedOptions = {
    facet: Facet;
    mappedOptions: MappedOption[];
};

export const DynamicFacets: FC = () => {
    const { facets, setFacet, isLoadingInitially } = useFilter();
    const [facetsWithMappedOptions, setFacetsWithMappedOptions] = useState<
        FacetWithMappedOptions[]
    >();

    useEffect(() => {
        if (facets !== undefined) {
            setFacetsWithMappedOptions(
                facets?.map((facet) => ({
                    facet,
                    mappedOptions: (facet.options || []).map(facetOptionMapper),
                }))
            );
        }
    }, [facets]);

    const handleFacetChange = <T,>({
        facet,
        change,
        overwrite,
    }: {
        change: IFacetOption<T> | IFacetOption<T>[];
        facet: Facet;
        overwrite?: boolean;
    }) => {
        // Because our Facets requre their options in a specific model,
        // we only need the { option } of a returned change.
        // so here we map over retuned option changes, to end with an array of filter options.
        const options = Array.isArray(change) ? change.map((c) => `${c.key}`) : change.key;
        setFacet?.({ facet: `${facet.id}`, options, overwrite });
    };

    // Only show the active filter in dropdown Kyoc-1300
    const filterActiveOptions = (mappedOptions: MappedOption[]) => {
        const activeFilter = mappedOptions.find((option) => option.selected);
        if (activeFilter) {
            return mappedOptions.filter((filter) => filter.key === activeFilter.key);
        } else {
            return mappedOptions;
        }
    };

    const facetSwitch = (facet: Facet, mappedOptions: MappedOption[]) => {
        switch (facet.type) {
            case EFacetType_Generic.CHECKBOX:
                return (
                    <FacetCheckbox
                        options={mappedOptions}
                        initialLoadSize={7}
                        handleChange={(change) => handleFacetChange({ change, facet })}
                    />
                );
            case EFacetType_Generic.DROPDOWN:
                return (
                    <FacetSelector
                        menuPlacement="auto"
                        options={filterActiveOptions(mappedOptions)}
                        handleChange={(change, overwrite) =>
                            handleFacetChange({ change, facet, overwrite })
                        }
                    />
                );
        }
    };

    return (
        <>
            {facetsWithMappedOptions?.map(({ facet, mappedOptions }) => (
                <FacetWrapper
                    key={facet.id}
                    headline={`${facet.title} ${facet.suffix && `(${facet.suffix})`}`}
                    documentUrl={facet.url}
                >
                    {isLoadingInitially ? (
                        <FacetSkeleton facetType={facet.type} />
                    ) : (
                        facetSwitch(facet, mappedOptions)
                    )}
                </FacetWrapper>
            ))}
        </>
    );
};
