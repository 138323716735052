import React from 'react';
import { INavigationNode } from '~/lib/data-contract';
import { weakKey } from '~/shared/utils/jsx';
import { NodeItem } from '../NodeItem';
import { StyledListItem } from './styled';

type NodeListProps = {
    nodes: INavigationNode[];
    onSelectNode?: (node: INavigationNode) => void;
};
export const NodeList = ({ nodes, onSelectNode }: NodeListProps) => (
    <ul>
        {nodes?.map((node) => (
            <StyledListItem key={weakKey(node)}>
                <NodeItem node={node} onSelectNode={onSelectNode} />
            </StyledListItem>
        ))}
    </ul>
);
