import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CheckboxElement from '$shared/components/form/components/controllable-elements/checkbox-element';
import { IFacetComponent, IFacetOption } from '../.';
import { StyledFacetCheckbox } from './style';
import { useTranslation } from '$utils/translation';
import Button from '$shared/components/button';

export type IFacetCheckboxProps<T> = IFacetComponent<T> & {
    initialLoadSize?: number;
};

const FacetCheckbox = <T,>({
    options,
    initialLoadSize = 6,
    handleChange,
}: IFacetCheckboxProps<T>) => {
    const originalOptions = options || [];

    const { translate } = useTranslation();
    const [toggleShowState, setToggleShowState] = useState(true);
    const [mappedOptions, setMappedOptions] = useState<IFacetOption<T>[]>(originalOptions);

    useEffect(() => setMappedOptions(originalOptions), [originalOptions]);

    const onToggleOption = useCallback(
        (option: IFacetOption<T>, index: number): void => {
            const interimOptions = mappedOptions.slice();
            interimOptions[index].selected = !option.selected;
            setMappedOptions(interimOptions);
            handleChange && handleChange(option);
        },
        [handleChange, mappedOptions]
    );

    const slicedOptions = useMemo(
        () => mappedOptions.slice(0, toggleShowState ? initialLoadSize : undefined),
        [initialLoadSize, mappedOptions, toggleShowState]
    );

    return (
        <>
            {slicedOptions.map((o, i) => (
                <StyledFacetCheckbox key={i}>
                    <CheckboxElement
                        checked={!!o.selected}
                        disabled={o.disabled}
                        label={`${o.name} ${o.count ? `(${o.count})` : ''}`}
                        onChange={() => onToggleOption(o, i)}
                    />
                </StyledFacetCheckbox>
            ))}
            {mappedOptions.length > initialLoadSize && (
                <Button variant="linkish" onClick={() => setToggleShowState(!toggleShowState)}>
                    {toggleShowState
                        ? translate('product-filter.show-more')
                        : translate('product-filter.show-less')}
                </Button>
            )}
        </>
    );
};

export default FacetCheckbox;
