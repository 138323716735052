import { CSSObject } from '@emotion/react';

/**
 * Position something at center of relative parent
 */
export const absoluteCenter: CSSObject = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};
