import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { StyledLink } from '../link/style';
import { StyledText } from '../text';

export const StyledRichText = styled.div(({ theme }) => ({
    wordBreak: 'break-word',
    lineHeight: theme.lineHeights.base,

    img: {
        maxWidth: '100%',
        height: 'auto',
    },

    [`p, ol, ul, pre, table`]: {
        marginBottom: theme.space[3],
    },

    p: {
        minHeight: '1em',
    },

    li: {
        marginBottom: theme.space[2],
    },

    ol: {
        paddingLeft: 25,
        listStyle: 'decimal',
    },

    ul: {
        paddingLeft: 25,
        listStyle: 'disc',
    },

    pre: {
        backgroundColor: theme.colors.grey20,
        padding: 15,
    },

    [`${StyledText}`]: {
        color: 'inherit',
    },

    [`${StyledLink}`]: {
        marginBottom: theme.space[2],
    },
}));

const epiColumnGutter = theme.space['5'];

export const StyledEpiColumnParent = styled.div<{ column?: string }>(
    ({ column }) => ({
        display: 'grid',
        columnGap: epiColumnGutter,
        gridTemplateColumns: '1fr',

        [breakpoints.md]: {
            gridTemplateColumns:
                column === '33-66' || column === '66-33'
                    ? `repeat(3, minmax(0, 1fr))`
                    : `repeat(${column}, minmax(0, 1fr))`,
        },
    }),
    ifProp({ column: '33-66' }, () => ({
        [breakpoints.md]: {
            [`${StyledEpiColumn}`]: {
                '&:last-of-type': {
                    gridColumn: '2 / span 2',
                },
            },
        },
    })),
    ifProp({ column: '66-33' }, () => ({
        [breakpoints.md]: {
            [`${StyledEpiColumn}`]: {
                '&:first-of-type': {
                    gridColumn: '1 / span 2',
                },
            },
        },
    }))
);
export const StyledEpiColumn = styled.div({
    width: '100%',
});

export const StyledCenterer = styled.div({
    display: 'flex',
    justifyContent: 'center',
});
