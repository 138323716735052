import { isBrowser } from '$shared/utils';
import { ORDERSERVICE_API_URL } from '$shared/utils/search/service/endpoints';
import { http } from '~/lib/http';

/**
 * For fetching PDFs of orders or drawings on products.
 * The drawing part is not needed for as is, but is kept for juicy future what ifs.
 * @param documentId either drawing or orderId based on value in isDrawing
 * @param isDrawing whether to fetch a drawing or not.
 * @param cb callback for successful pdf response
 * @param onError callback for error handling
 */
export const downloadPdf = async (
    documentId: string,
    isDrawing: boolean,
    cb?: () => void,
    onError?: () => void
) => {
    if (!isBrowser) return;
    const pdfResponse = await http(
        isDrawing
            ? `${ORDERSERVICE_API_URL}/drawing/GetPdf?originalItemNumber=${encodeURIComponent(
                  documentId
              )}`
            : `${ORDERSERVICE_API_URL}/order/downloadPdf?orderNumber=${documentId}`
    );

    if (!pdfResponse.ok) {
        onError?.();
        return;
    } else {
        cb?.();
    }

    const blob = await pdfResponse.blob();
    const url = window.URL.createObjectURL(blob);
    const aTag = document.createElement('a');
    aTag.href = url;
    aTag.download = `${documentId}.pdf`;
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
};
