import {
    StyledItemWrapper,
    StyledExtraServiceInput,
    StyledExtraServiceListItem,
    StyledServiceSpan,
    StyledPrice,
} from './style';
import React, { FC, useEffect, useRef, useState } from 'react';
import CheckboxElement from '$shared/components/form/components/controllable-elements/checkbox-element';
import { useTranslation } from '$shared/utils';

export type ExtraServiceItemProps = {
    enabled?: boolean;
    editable?: boolean;
    id?: number;
    onSelectionChange: (checked: boolean, userText?: string) => void;
    presetValue?: string;
    price?: string;
    selected: boolean;
    text?: string;
};

export const ExtraServicesItem: FC<ExtraServiceItemProps> = ({
    enabled = true,
    editable = false,
    id,
    text,
    price,
    selected,
    presetValue,
    onSelectionChange,
}) => {
    const [value, setValue] = useState(presetValue || '');
    const [maxReached, setMaxReached] = useState(false);
    const { translate } = useTranslation();
    const [inputIsInvalid, setInputIsInvalid] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (selected && editable) {
            setInputIsInvalid(false);
        }
    }, [selected, editable]);

    return (
        <StyledExtraServiceListItem key={id} hasInput={selected && editable}>
            <StyledServiceSpan>
                <StyledItemWrapper>
                    <CheckboxElement
                        checked={selected}
                        disabled={!enabled}
                        label={text}
                        type="checkbox"
                        onChange={({ target: { checked } }) => {
                            onSelectionChange(checked, checked ? value : '');
                            if (!checked) {
                                setValue('');
                            }
                            if (checked) {
                                setTimeout(() => {
                                    inputRef.current?.focus();
                                }, 0);
                            }
                        }}
                    />
                </StyledItemWrapper>
                {!!price && <StyledPrice>+{price}</StyledPrice>}
            </StyledServiceSpan>

            {selected && editable && (
                <StyledExtraServiceInput
                    ref={inputRef}
                    invalidMessage={
                        inputIsInvalid
                            ? maxReached
                                ? translate('product-detail-page.core-data.service-marking-max-reached')
                                : translate(
                                      'product-detail-page.core-data.service-marking-placeholder'
                                  )
                            : undefined
                    }
                    isInvalid={inputIsInvalid}
                    placeholder={translate(
                        'product-detail-page.core-data.service-marking-placeholder'
                    )}
                    required={true}
                    maxLength={30}
                    value={value}
                    onBlur={() => {
                        if (selected && !value) {
                            setInputIsInvalid(true);
                        } else {
                            setInputIsInvalid(false);
                        }
                        onSelectionChange(selected, value);
                    }}
                    onFocus={() => {
                        if (selected && value.length >= 30) {
                            setMaxReached(true);
                            setInputIsInvalid(true);
                        }
                        onSelectionChange(selected, value);
                    }}
                    onChange={({ target: { value } }) => {
                        setValue(value);
                        if (value.length >= 30) {
                            setMaxReached(true);
                            setInputIsInvalid(true);
                        } else {
                            setMaxReached(false);
                            setInputIsInvalid(false);
                        }
                    }}
                    helpText={translate('product-detail-page.core-data.service-marking-help')}
                    helpTextPlacement="right"
                />
            )}
        </StyledExtraServiceListItem>
    );
};
