import { useUpdateEffect } from './../../update-effect/update-effect.hook';
import { FilterDataExchanger, IParamsOfArrays } from './../models/filter-models';
import { useInfiniteQuery } from '@tanstack/react-query';
import { buildURL, cancellableFetch, hashCode, useFrame } from '$shared/utils';
import { ISimpleMetaFieldViewModel, SearchResponseViewModel } from '~/lib/data-contract';
import {
    FILTER_PAGE_KEY,
    FILTER_PAGE_SIZE,
    FILTER_PRODUCTS,
    FILTER_REQUEST_FACET_PARAM,
    FILTER_SEARCH_TEXT_KEY,
    FILTER_FAVORITES_OPTION_KEY,
    FILTER_PROMOTED_PRODUCTS_OPTION_KEY,
    FILTER_CUSTOMERS_PURCHASES_OPTION_KEY,
    FILTER_INTELLIGENT_KEY,
    FILTER_SHOW_ONLY_IN_STOCK_KEY,
    FILTER_SHOW_ONLY_IN_STOCK_OPTION_KEY,
    FILTER_REQUEST_HIERARCHY_PARAM,
    FILTER_SHOW_ONLY_IN_STOCK_MAX,
} from './constants';
import { useMemo, useState, useCallback } from 'react';
import { filterMapper } from '../utils/filter-mappers';
import { stringToNumber } from '$utils/string-format.helper';
import { useAuthentication } from '$shared/hooks/useAuthentication';

type FetchPageParameters = {
    pageIndex?: number;
    parameters?: IParamsOfArrays;
    searchText?: string;
    favoriteProduct?: boolean;
    customersPurchased?: boolean;
    promotedProducts?: boolean;
    showOnlyInStock?: boolean;
};

function filterFacetOptionRules(facetOption?: string | null) {
    return facetOption?.replace('↕', '~');
}

function calcFilterQuery(activeFacets?: IParamsOfArrays): IParamsOfArrays | undefined {
    if (!activeFacets) return;

    const facetOptions = Object.entries(activeFacets)?.reduce((acc, [, val]) => {
        return [...acc, ...(val?.map((o) => `${filterFacetOptionRules(o)}`) || [])];
    }, [] as string[]);

    if (facetOptions) {
        return {
            [FILTER_REQUEST_FACET_PARAM]: facetOptions,
        };
    }
}

export type AdditionalFilterQueryOptions = {
    facetsFromCMS?: ISimpleMetaFieldViewModel[];
    hierarchyNode?: string;
};

export const useFilterQuery: FilterDataExchanger<AdditionalFilterQueryOptions> = ({
    filterId,
    facetsFromCMS,
    hierarchyNode,
    params: activeQueryState,
}) => {
    const { userFound } = useAuthentication();
    const { market } = useFrame();
    const [lastPage, setLastPage] = useState<number>();
    const [
        {
            pageIndex,
            parameters,
            searchText,
            favoriteProduct,
            customersPurchased,
            promotedProducts,
            showOnlyInStock,
        },
        setCurrentPageParams,
    ] = useState<FetchPageParameters>({});

    const calcParams = useCallback(() => {
        if (!activeQueryState) return [];

        const {
            [FILTER_PAGE_KEY]: pageNumberParam,
            [FILTER_SEARCH_TEXT_KEY]: searchTextParam,
            [FILTER_INTELLIGENT_KEY]: userFilterParams,
            [FILTER_SHOW_ONLY_IN_STOCK_KEY]: stockParams,
            ...pageParams
        } = activeQueryState || {};

        const [pageNumber] = pageNumberParam || [];
        const [searchText] = searchTextParam || [''];

        const favoriteProduct = !!userFilterParams?.includes(FILTER_FAVORITES_OPTION_KEY);
        const customersPurchased = !!userFilterParams?.includes(
            FILTER_CUSTOMERS_PURCHASES_OPTION_KEY
        );
        const promotedProducts = !!userFilterParams?.includes(FILTER_PROMOTED_PRODUCTS_OPTION_KEY);
        const showOnlyInStock = stockParams?.includes(FILTER_SHOW_ONLY_IN_STOCK_OPTION_KEY);

        const index = pageNumber ? stringToNumber(pageNumber) : 1;

        setCurrentPageParams({
            pageIndex: index,
            parameters: pageParams,
            searchText,
            favoriteProduct,
            customersPurchased,
            promotedProducts,
            showOnlyInStock,
        });
    }, [activeQueryState]);

    useUpdateEffect(() => {
        calcParams();
    }, [activeQueryState]);

    const filterQueryParams = useMemo(() => calcFilterQuery(parameters), [parameters]);

    const buildSerializedUrl = useCallback(
        (pageNumber?: number) => {
            return buildURL(FILTER_PRODUCTS, {
                searchText,
                favoriteProduct,
                customersPurchased,
                promotedProducts,
                from: showOnlyInStock
                    ? 0
                    : FILTER_PAGE_SIZE * (pageNumber ? (pageNumber || 0) - 1 : 0),
                take: showOnlyInStock
                    ? FILTER_SHOW_ONLY_IN_STOCK_MAX
                    : FILTER_PAGE_SIZE * (pageNumber ? 1 : pageIndex || 1),
                ...(hierarchyNode ? { [FILTER_REQUEST_HIERARCHY_PARAM]: hierarchyNode } : {}), // Doing this spread to avoid a case of "pageFilters=undefined"
                ...(!userFound ? { customerMarket: market?.anonymousCustomerMarketCode } : {}),
                ...filterQueryParams,
            });
        },
        [userFound, filterQueryParams, pageIndex, searchText, hierarchyNode]
    );

    const filterQueryParamsKey = useMemo(() => hashCode(JSON.stringify(filterQueryParams)) || '', [
        filterQueryParams,
    ]);

    const {
        data,
        isFetching,
        fetchNextPage,
        isLoading,
    } = useInfiniteQuery<SearchResponseViewModel>(
        [
            filterId,
            filterQueryParamsKey,
            searchText,
            favoriteProduct,
            customersPurchased,
            promotedProducts,
            showOnlyInStock,
            hierarchyNode,
        ],
        ({ signal, pageParam }) =>
            cancellableFetch({ signal, url: `${buildSerializedUrl(pageParam)}` }),
        {
            getNextPageParam: (lastPage) => (lastPage.pageNumber || 0) + 1,
            enabled: Boolean(!!parameters && userFound !== undefined),
            keepPreviousData: true,
        }
    );

    useUpdateEffect(() => {
        if (pageIndex && lastPage && lastPage < pageIndex) {
            fetchNextPage?.({ pageParam: pageIndex });
        }
        setLastPage(pageIndex);
    }, [pageIndex]);

    const flattenedData = useMemo(
        () =>
            data?.pages?.slice(0, pageIndex).reduce((acc, curr) => {
                return {
                    products: [...(acc?.products || []), ...(curr?.products || [])],
                    facets: curr?.facets,
                    total: curr?.total,
                };
            }, {} as SearchResponseViewModel),
        [data, pageIndex]
    );

    const filter = useMemo(
        () =>
            filterMapper({
                ...flattenedData,
                facetsFromCMS,
                allowEmptyFacets: !flattenedData, // Enables skeleton view, because we then know the number of facets from CMS.
            }),
        [flattenedData]
    );

    return {
        ...filter,
        isLoading: isFetching,
        searchText,
        isLoadingInitially: isLoading,
        userFilters: {
            favoriteProduct,
            customersPurchased,
            promotedProducts,
            showOnlyInStock,
        },
    };
};
