import { Badge, EBadgeType } from '$shared/components/badge/badge';
import { ExtraServices } from '$shared/components/extra-services/extra-services';
import { AddToBasketProvider } from '$shared/utils/add-to-basket/AddToBasketProvider';
import { useProduct } from '$shared/utils/product';
import React, { FC } from 'react';
import { PdpCoreDataHeadlines, PdpCoreItems } from './components/pdp-core-data';
import { PdpQuantityToBasket } from './components/pdp-quantity-to-basket';
import { StyledPdpCoreBottomSpace } from './style';

export const PdpCore: FC = () => {
    const productAndStock = useProduct();
    const hasExtraServices = !!productAndStock.extraServices?.length;

    return (
        <AddToBasketProvider
            {...productAndStock}
            isQuantityReady={
                !productAndStock.isFetchingPriceAndStockCount && !!productAndStock.minimumOrderSize
            }
        >
            {productAndStock.promotedProduct && <Badge type={EBadgeType.PROMOTED_PRODUCT} />}
            <PdpCoreDataHeadlines />
            <StyledPdpCoreBottomSpace>
                <PdpCoreItems />
            </StyledPdpCoreBottomSpace>
            {hasExtraServices && (
                <StyledPdpCoreBottomSpace>
                    <ExtraServices arrow={false} />
                </StyledPdpCoreBottomSpace>
            )}
            <PdpQuantityToBasket />
        </AddToBasketProvider>
    );
};
