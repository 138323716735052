import React from 'react';
import ContactArea from './components/contact-area';
import MenuArea from './components/menu-area';
import GridItem from './components/grid-item';
import { StyledFooter, StyledGrid } from './style';
import SocialArea from './components/social-area';
import MetaArea from './components/meta-area';
import NewsletterArea from './components/newsletter-area';
import Container from '$shared/components/container';
import { useFrame } from '$shared/utils';

export const Footer = () => {
    const { footer } = useFrame();
    const { contact, menu, social, meta } = footer || {};

    return (
        <StyledFooter>
            <Container>
                <StyledGrid>
                    <ContactArea {...contact} />
                    <NewsletterArea />
                    {social ? <SocialArea {...social} /> : null}
                    {meta ? <MetaArea {...meta} /> : null}
                    {menu ? <MenuArea {...menu} /> : null}
                </StyledGrid>
            </Container>
        </StyledFooter>
    );
};

Footer.Grid = StyledGrid;
Footer.GridItem = GridItem;

export default Footer;
