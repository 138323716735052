import * as React from 'react';
import { FC, useMemo } from 'react';
import CheckboxElement from '$shared/components/form/components/controllable-elements/checkbox-element';
import RadioButtonElement from '$shared/components/form/components/controllable-elements/radio-button-element';
import { useOrderStore } from '$features/checkout/hooks/use-order-store';
import { useTranslation } from '$shared/utils';
import {
    StyledDeliveryDescription,
    StyledRadioButtonGroup,
    StyledPickupWrapper,
    StyledDeliveryOptions,
} from './style';
import { useUserStore } from '$features/authentication/use-user-store';

interface DeliveryOptionsProps {
    companiesWithPickup?: string;
}

export const DeliveryOptions: FC<DeliveryOptionsProps> = ({ companiesWithPickup }) => {
    const { translate } = useTranslation();
    const { pickupOrder, setDeliveryOptions, splitOrder } = useOrderStore();
    const { user } = useUserStore();

    const allowPickup = useMemo(() => {
        const allowedCompanyIds = companiesWithPickup?.split(',');
        if (!allowedCompanyIds) {
            return false;
        }
        return allowedCompanyIds.some((id) => id === user?.company?.toString());
    }, [companiesWithPickup, user]);

    return (
        <StyledDeliveryOptions>
            <StyledDeliveryDescription children={translate('checkout-page.delivery-help-text')} />
            <StyledRadioButtonGroup>
                <RadioButtonElement
                    checked={splitOrder}
                    label={translate('checkout-page.split-order')}
                    name="deliveryOptionsRadioGroup"
                    onChange={() => setDeliveryOptions(pickupOrder, true)}
                />
                <RadioButtonElement
                    checked={!splitOrder}
                    label={translate('checkout-page.complete-order')}
                    name="deliveryOptionsRadioGroup"
                    onChange={() => setDeliveryOptions(pickupOrder, false)}
                />
            </StyledRadioButtonGroup>
            {allowPickup && (
                <StyledPickupWrapper>
                    <CheckboxElement
                        label={translate('checkout-page.i-will-pick-up-the-order-myself')}
                        checked={pickupOrder}
                        onChange={() => setDeliveryOptions(!pickupOrder, splitOrder)}
                    />
                </StyledPickupWrapper>
            )}
        </StyledDeliveryOptions>
    );
};
