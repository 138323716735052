import React, { FC } from 'react';
import { FileInput } from '$shared/components/form';
import { RegisterOptions } from 'react-hook-form';
import { IFileUploadFormElement } from '~/lib/data-contract';

export type FileUploadEpiFieldProps = IFileUploadFormElement & {
    rules?: RegisterOptions;
};

export const FileUploadEpiField: FC<FileUploadEpiFieldProps> = ({
    name = '',
    label,
    rules,
    tooltipText,
    allowMultipleFiles,
    allowedExtensions,
    fileSizeMaxMb,
}) => {
    return (
        <FileInput
            name={name}
            label={label}
            rules={rules}
            helpText={tooltipText}
            allowMultipleFiles={allowMultipleFiles}
            allowedExtensions={allowedExtensions}
            fileSizeMaxMb={fileSizeMaxMb}
        />
    );
};

export default FileUploadEpiField;
