import { Checkbox, Input } from "$shared/components/form";
import styled from "@emotion/styled";

export const StyledFieldset = styled.fieldset(({ theme }) => ({
    display: 'grid',
    gap: theme.space[3],
    grid: 'auto-flow / auto 1fr',
    width: '100%',
}));

export const StyledInput = styled(Input)(() => ({
    gridColumnEnd: 'span 2',
    maxWidth: '42em',
}));

export const StyledZipCodeInput = styled(Input)(() => ({
    maxWidth: '9em',
}));

export const StyledCityInput = styled(Input)(() => ({
    maxWidth: '20em',
}));

export const StyledCheckbox = styled(Checkbox)(() => ({
    gridColumnEnd: 'span 2',
}));
