import Alert from './alert.svg';
import ArrowDown from './arrow-down.svg';
import ArrowLeft from './arrow-left.svg';
import ArrowRight from './arrow-right.svg';
import ArrowUp from './arrow-up.svg';
import Cart from './cart.svg';
import Checkmark from './checkmark.svg';
import CheckmarkThin from './checkmark-thin.svg';
import ChevronDown from './chevron-down.svg';
import ChevronLeft from './chevron-left.svg';
import ChevronRight from './chevron-right.svg';
import ChevronUp from './chevron-up.svg';
import Close from './close.svg';
import Contact from './contact.svg';
import Document from './document.svg';
import DocumentPDF from './document-pdf.svg';
import Download from './download.svg';
import ExternalLink from './external-link.svg';
import Facebook from './facebook.svg';
import Favicon from './favicon.svg';
import FilterIcon from './filter.svg';
import Info from './info.svg';
import Instagram from './instagram.svg';
import Language from './language.svg';
import Linkedin from './linkedin.svg';
import List from './list.svg';
import Location from './location.svg';
import Logo from './logo.svg';
import MobileMenu from './mobile-menu.svg';
import Offices from './offices.svg';
import PaperClip from './paperclip.svg';
import Pinterest from './pinterest.svg';
import Play from './play.svg';
import Profile from './profile.svg';
import Search from './search.svg';
import ShoppingBag from './shopping-bag.svg';
import StarActive from './star-active.svg';
import StarInactive from './star-inactive.svg';
import StarOutline from './star-outline.svg';
import Support from './support.svg';
import Trash from './trash.svg';
import Youtube from './youtube.svg';

/**
 * Here we import and name the icons, from which the Icon component get's its model and list to pick from.
 */

export const mapOfAllIcons = {
    alert: Alert,
    arrowDown: ArrowDown,
    arrowLeft: ArrowLeft,
    arrowRight: ArrowRight,
    arrowUp: ArrowUp,
    cart: Cart,
    checkmark: Checkmark,
    checkmarkThin: CheckmarkThin,
    chevronDown: ChevronDown,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    chevronUp: ChevronUp,
    close: Close,
    contact: Contact,
    document: Document,
    documentPDF: DocumentPDF,
    download: Download,
    externalLink: ExternalLink,
    facebook: Facebook,
    favicon: Favicon,
    filter: FilterIcon,
    info: Info,
    instagram: Instagram,
    language: Language,
    linkedin: Linkedin,
    list: List,
    location: Location,
    logo: Logo,
    mobileMenu: MobileMenu,
    offices: Offices,
    paperClip: PaperClip,
    pinterest: Pinterest,
    play: Play,
    profile: Profile,
    search: Search,
    shoppingBag: ShoppingBag,
    starActive: StarActive,
    starInactive: StarInactive,
    starOutline: StarOutline,
    support: Support,
    trash: Trash,
    youtube: Youtube,
};
