import { TextOnImage } from '$shared/components/text-on-image-box';
import React from 'react';
import { FC } from 'react';
import { IN02MainNavigationWithCenterItemsPage } from '~/lib/data-contract';
import MegaSubSubMenu from '../mega-sub-sub-menu/mega-sub-sub-menu';
import { MegaSubMenuPromotions, StyledSubSubMenuArea } from './style';

export type MegaMenuContentProps = IN02MainNavigationWithCenterItemsPage & {
    shownWithoutParent?: boolean;
};

export const MegaMenuContent: FC<MegaMenuContentProps> = ({
    children,
    promotions,
    shownWithoutParent,
}) => {
    return (
        <StyledSubSubMenuArea>
            <MegaSubSubMenu lists={children} shownWithoutParent={shownWithoutParent} />

            <MegaSubMenuPromotions>
                {promotions?.map((props) => (
                    <TextOnImage key={props?.id} {...props} />
                ))}
            </MegaSubMenuPromotions>
        </StyledSubSubMenuArea>
    );
};
export default MegaMenuContent;
