import React from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { Image } from '$shared/components/image';

export interface ImageMediaProps {
    cover?: boolean;
    src?: string;
    width?: number;
    height?: number;
    alt?: string;
    title?: string;
    /**
     * size attr for images: ex. (max-width: ${breakpointSizes.sm}px) 100vw, 300px
     */
    imageSizes?: string;
}

export const StyledImageMedia = styled.div<ImageMediaProps>(
    {
        width: '100%',
        height: '100%',
        display: 'block',
    },
    switchProp('cover', {
        true: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
        },
    })
);

export const ImageMedia = ({
    src = '',
    cover = false,
    width = 16,
    height = 9,
    imageSizes,
    ...rest
}: ImageMediaProps) => (
    <StyledImageMedia cover={cover}>
        <Image src={src} width={width} height={height} sizes={imageSizes} {...rest} />
    </StyledImageMedia>
);

export default ImageMedia;
