import Table from '$shared/components/table';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';

export const StyledJobTitle=styled.div(() => ({
    fontWeight: theme.fontWeights['bold'],
    marginTop: theme.space['2'],
}));
export const StyledJobsMobileTable=styled(Table)(() => ({
     [breakpoints.frame]: {
         // Switch from device to desktop design
         display: 'none',
     },
 }));