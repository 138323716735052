import Headline from '$shared/components/headline';
import Link from '$shared/components/link';
import { useRawHtmlWithNewLinesAsBreaks } from '$shared/components/raw-html';
import React from 'react';
import { HeaderTag } from '~/lib/data-contract';
import { ModuleContainer } from '../module-container';
import { MediaText, MediaTextProps } from './components';
import { StyledGrid, StyledHeader } from './style';

export type M80ItemLayout = 'normal' | 'narrow';
export type M80Props = {
    /**
     * Module headline
     */
    columns?: number;

    /**
     * Module headline
     */
    headline?: string;

    /**
     * Define the headline HTML tag. Useful for SEO
     */
    headlineTag?: HeaderTag;

    /**
     * Define the headline HTML tag. Useful for SEO
     */
    headlineStyleAs?: HeaderTag;

    headlineHidden?: boolean;
    /**
     * The text for the button. If this is left empty
     * the button will not show
     */
    actionText?: string;

    /**
     * The url for the button
     */
    actionUrl?: string;

    items: MediaTextProps[];
    layoutVariant?: M80ItemLayout;
    id?: string;
};

type ModuleHeaderProps = Pick<
    M80Props,
    'headline' | 'headlineTag' | 'headlineStyleAs' | 'headlineHidden' | 'actionText' | 'actionUrl'
>;

const ModuleHeader = ({
    headline,
    headlineTag = 'h2',
    headlineStyleAs,
    headlineHidden,
    actionUrl,
    actionText,
}: ModuleHeaderProps) => (
    <StyledHeader>
        {headline && (
            <Headline
                as={headlineTag}
                size={headlineStyleAs}
                hideOnMobile={headlineHidden}
                children={useRawHtmlWithNewLinesAsBreaks(headline)}
                noMargin
            />
        )}
        {actionText && <Link href={actionUrl} variant="plain" children={actionText} />}
    </StyledHeader>
);

export const M80 = ({
    columns,
    headline,
    headlineTag,
    headlineStyleAs,
    headlineHidden,
    actionText,
    actionUrl,
    items,
    layoutVariant,
    ...props
}: M80Props) => {
    const columnCount = columns || 3;

    return (
        <ModuleContainer {...props}>
            <ModuleHeader
                headline={headline}
                headlineTag={headlineTag}
                headlineStyleAs={headlineStyleAs}
                headlineHidden={headlineHidden}
                actionText={actionText}
                actionUrl={actionUrl}
            />
            <StyledGrid columnCount={columnCount} layoutVariant={layoutVariant}>
                {items?.length
                    ? items.map((item, index) => (
                          <MediaText
                              key={`${item.headline?.text}-${index}`}
                              {...item}
                              columnCount={columnCount}
                              layoutVariant={layoutVariant}
                          />
                      ))
                    : null}
            </StyledGrid>
        </ModuleContainer>
    );
};

export default M80;
