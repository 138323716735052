import { StyledIcon } from '$shared/components/button';
import { Icon } from '$shared/components/icon';
import Link from '$shared/components/link';
import { getTarget } from '$shared/utils';
import styled from '@emotion/styled';
import { ILink } from '~/lib/data-contract';
import GridItem, { GridItemProps } from './grid-item';

type ContactAreaProps = GridItemProps & {
    links?: ILink[];
};

const StyledLinksContainer = styled.div(() => ({
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: 'max-content',

    [`${StyledIcon}`]: {
        '&:first-of-type:last-of-type': {
            padding: '12px 14px 12px 0',
        },
    },
}));

export const ContactArea = ({ headline, subHeadline, links = [] }: ContactAreaProps) => (
    <GridItem headline={headline} subHeadline={subHeadline} area="contact">
        <StyledLinksContainer>
            {links.map(
                ({ target, text, url, title, id }) =>
                    url && (
                        <div key={id}>
                            <Link
                                variant="plain"
                                title={title}
                                children={text}
                                href={url}
                                target={target || getTarget(url)}
                                iconLeft={<Icon icon="arrowRight" color="blue90" size={25} />}
                            />
                        </div>
                    )
            )}
        </StyledLinksContainer>
    </GridItem>
);

export default ContactArea;
