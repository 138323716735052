import { FC, useCallback, useMemo } from 'react';
import { useOrderStore } from '$features/checkout/hooks/use-order-store';
import { useSavedDeliveryAddresses } from '$features/checkout/hooks/use-saved-delivery-addresses';
import { InformationForm } from '$features/checkout/models/information-form';
import Spinner from '$shared/components/spinner';
import { TabMenu } from '$shared/components/tab-menu';
import { useTranslation } from '$shared/utils';
import { useFormContext } from 'react-hook-form';
import { DeliveryAddressForm } from '../delivery-address-form/delivery-address-form';
import { SelectorTab } from '../selector-tab/selector-tab';
import {
    StyledCheckoutBlockSpacing,
    StyledCheckoutHeadline,
} from '$templates/pages/components/P310CheckoutPage/style';

interface DeliveryAddressProps {
    onTabChange: (values: InformationForm) => void;
}

export const DeliveryAddress: FC<DeliveryAddressProps> = (props) => {
    const { translate } = useTranslation();
    const { getValues, watch } = useFormContext<InformationForm>();
    const {
        addressOptions,
        isLoadingAddresses: isBusy,
        savedAddresses,
    } = useSavedDeliveryAddresses();

    const { useDeliveryAddressId } = useOrderStore();

    const selectedOption = watch('deliveryAddressOption');
    const showTabs = useMemo(() => !isBusy && addressOptions.length, [isBusy, addressOptions]);

    const selectionSummary = useMemo(() => {
        const selection = selectedOption
            ? savedAddresses.find((a) => a.addressNumber === selectedOption.value)
            : savedAddresses[0];

        return selection && [selection.name, selection.street, selection.zipAndCity].join('<br>');
    }, [savedAddresses, selectedOption]);

    const onTabChange = useCallback(
        (useSavedAddress: boolean) => {
            const currentValues = getValues();
            props.onTabChange({
                ...currentValues,
                useDeliveryAddressId: useSavedAddress,
            });
        },
        [getValues, props.onTabChange]
    );

    const deliveryForm = (
        <DeliveryAddressForm
            country={savedAddresses.find((a) => a.addressNumber === 'NORM')?.country || ''}
        />
    );

    return (
        <StyledCheckoutBlockSpacing>
            <StyledCheckoutHeadline
                children={translate('checkout-page.delivery-address')}
                size="h3"
            ></StyledCheckoutHeadline>

            {!isBusy && showTabs && (
                <TabMenu
                    onTabChange={(_tab, i) => onTabChange(!i)}
                    size={'small'}
                    storeOpenTabInLocation={false}
                    initialTabIndex={useDeliveryAddressId ? 0 : 1}
                    tabs={[
                        {
                            content: (
                                <SelectorTab
                                    fieldName="deliveryAddressOption"
                                    options={addressOptions}
                                    selectionSummary={selectionSummary}
                                />
                            ),
                            tabName: translate('checkout-page.saved-address'),
                        },
                        {
                            content: deliveryForm,
                            tabName: translate('checkout-page.new-address'),
                        },
                    ]}
                />
            )}

            {!isBusy && !showTabs && deliveryForm}

            {isBusy && <Spinner />}
        </StyledCheckoutBlockSpacing>
    );
};
