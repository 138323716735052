import { Icon } from '$shared/components/icon';
import Link, { LinkProps } from '$shared/components/link';
import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { BreadcrumbProps } from './breadcrumb';

export const StyledBreadcrumbs = styled.section(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: `${theme.space.gutter} 0`,

    [breakpoints.sm]: {},
}));

export const StyledBreadcrumbLink = styled(Link)<LinkProps>(({ theme, href }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    lineHeight: 1,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: theme.colors.grey70,
    fontWeight: theme.fontWeights.bold,
    textTransform: 'uppercase',
    fontSize: theme.fontSizes?.['2xs'],
    textDecoration: 'none',

    ':hover': {
        color: href ? theme.colors.blue80 : theme.colors.grey70,
        [`${StyledBreadcrumbIcon}`]: {
            color: theme.colors.grey70,
        },
        textDecoration: href ? 'underline' : 'none',
    },

    ':last-child': {
        [`${StyledBreadcrumbIcon}`]: {
            display: 'none',
        },
    },
}));

export const StyledBreadcrumbText = styled.span<BreadcrumbProps>(
    ({ theme }) => ({
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: theme.fontWeights.semiBold,
    }),
    switchProp('fontSize', {
        xs: ({ theme }) => ({
            fontSize: theme.fontSizes?.xs,
        }),
        default: ({ theme }) => ({
            fontSize: theme.fontSizes['3xs'],

            [breakpoints.sm]: {
                fontSize: theme.fontSizes['2xs'],
            },
        }),

        sm: ({ theme }) => ({
            fontSize: theme.fontSizes?.sm,
        }),
        md: ({ theme }) => ({
            fontSize: theme.fontSizes?.lg,
        }),
        lg: ({ theme }) => ({
            fontSize: theme.fontSizes?.xl,
        }),
    })
);

export const StyledBreadcrumbIcon = styled(Icon)(({ theme }) => ({
    color: theme.colors.blue80,
    path: {
        fill: 'currentColor',
    },
    marginLeft: '4px',
    marginRight: '4px',
    width: '0.85em',
    height: '0.85em',
    minWidth: '0.85em',
    minHeight: '0.85em',

    [breakpoints.sm]: {
        marginLeft: '7px',
        marginRight: '7px',
    },
}));

export const StyledDotsDiv = styled.button(({ theme }) => ({
    all: 'unset',
    cursor: 'pointer',
    display: 'inline-block',
    fontWeight: theme.fontWeights.bold,
    fontSize: theme.fontSizes?.['3xs'],
    color: theme.colors.blue80,
    letterSpacing: 1,
    marginRight: '7px',

    [breakpoints.sm]: {
        fontSize: theme.fontSizes?.['lg'],
        color: theme.colors.blue80,
        marginLeft: '4px',
        marginRight: '14px',
        letterSpacing: 1,
    },
}));
