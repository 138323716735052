import { FC } from 'react';
import {
    StyledOrderDetailsWrapper,
    StyledOrderDetailsTitle,
    StyledOrderDetailsText,
} from './style';

export type OrderDetailsProps = {
    details?: DetailItem[];
};

export type DetailItem = {
    title?: string;
    lines?: (string | undefined | null)[];
};

export const OrderDetails: FC<OrderDetailsProps> = ({ details }) => {
    return (
        <StyledOrderDetailsWrapper>
            {details?.map((summary) => (
                <div key={summary?.title}>
                    <StyledOrderDetailsTitle>{summary?.title}</StyledOrderDetailsTitle>
                    <StyledOrderDetailsText>
                        {summary?.lines?.map((t) => (
                            <p key={t}>{t}</p>
                        ))}
                    </StyledOrderDetailsText>
                </div>
            ))}
        </StyledOrderDetailsWrapper>
    );
};
