import React, { FC, useState } from 'react';
import { ModuleContainer } from '../module-container';
import { Filter } from '$shared/utils/filter/components';
import { DynamicFacets } from './components/dynamic-facets';
import {
    MobileFacetsContainer,
    ProductFilterGrid,
    StyledFilterColumn,
    StyledMobileFilterCta,
    StyledMobileFilterCtas,
    StyledProductColumn,
} from './style';
import ResetFilterButton from '$shared/components/facets/reset-filter-button/facet-search-bar';
import { IM170ProductFilterModule, IP35CategoryDetailPage } from '~/lib/data-contract';
import { FilterProductList } from './components/filter-product-list';
import { FacetSearchBar } from '$shared/components/facets/facet-search-bar/facet-search-bar';
import { FilterHeader } from './components/filter-header';
import { SidePanel } from '$shared/components/side-panel/side-panel';
import { formatString, useTranslation } from '$shared/utils';
import { usePage } from '$templates/pages';
import { useMedia } from 'react-use';
import { breakpointSizes } from '$theme';
import { IntelligentSortingFacet } from './components/specific-facets/intelligent-sorting-facet';

import { StockFacet } from './components/specific-facets/stock-facet';
import {
    AdditionalFilterQueryOptions,
    useFilterQuery,
} from '$shared/utils/filter/service/useFilterQuery';

export const M170Filter: FC<IM170ProductFilterModule> = ({ id }) => {
    const [showMobileFilters, setShowMobileFilters] = useState(false);
    const { translate } = useTranslation();
    const page = usePage() || {};
    const { productGroup, metaTitle, pageElements, name } = (page as IP35CategoryDetailPage) || {};
    const { hierarchy } = productGroup || {};
    const isDesktop = useMedia(`(min-width: ${breakpointSizes.frame}px)`, false);

    const additionalOptions: AdditionalFilterQueryOptions = {
        // facetsFromCMS: productGroup?.facets,
        facetsFromCMS: [],
        hierarchyNode: productGroup?.hierarchy,
    };

    return (
        <>
            <ModuleContainer id={hierarchy} fullWidth>
                <Filter
                    filterDataExchanger={useFilterQuery}
                    filterScopeId={hierarchy}
                    {...additionalOptions}
                >
                    {({ resultsTotal, resetFilter, isLoading }) => (
                        <>
                            <FilterHeader
                                title={
                                    pageElements.findIndex((pe) => pe.type === 'm10HeroModule') < 0
                                        ? name || metaTitle
                                        : undefined
                                }
                                onFiltersToggled={
                                    isDesktop ? undefined : () => setShowMobileFilters(true)
                                }
                            />
                            <ProductFilterGrid>
                                {isDesktop && (
                                    <StyledFilterColumn>
                                        <FacetSearchBar />
                                        <ResetFilterButton handleClick={resetFilter} />
                                        <StockFacet />
                                        <IntelligentSortingFacet />
                                        <DynamicFacets />
                                        <ResetFilterButton handleClick={resetFilter} />
                                    </StyledFilterColumn>
                                )}
                                <StyledProductColumn>
                                    <FilterProductList id={id} />
                                </StyledProductColumn>
                            </ProductFilterGrid>
                            {!isDesktop && (
                                <SidePanel
                                    show={showMobileFilters}
                                    onChange={setShowMobileFilters}
                                    maxWidth={414}
                                    maxContentHeight={'calc(100vh - 160px)'} // Header + fixed bottom
                                    title={translate('product-list-page.mobile-filters-title')}
                                >
                                    <MobileFacetsContainer>
                                        <FacetSearchBar />
                                        <StockFacet />
                                        <IntelligentSortingFacet />
                                        <DynamicFacets />
                                    </MobileFacetsContainer>
                                    <StyledMobileFilterCtas>
                                        <StyledMobileFilterCta
                                            onClick={resetFilter}
                                            variant="secondary"
                                        >
                                            {translate('product-filter.clear-filter')}
                                        </StyledMobileFilterCta>
                                        <StyledMobileFilterCta
                                            onClick={() => setShowMobileFilters(false)}
                                            isLoading={isLoading}
                                            disabled={false} // Because loading should not disable this button
                                            variant="primary"
                                        >
                                            {formatString(
                                                translate(
                                                    'product-list-page.mobile-filters-see-results'
                                                ),
                                                resultsTotal
                                            )}
                                        </StyledMobileFilterCta>
                                    </StyledMobileFilterCtas>
                                </SidePanel>
                            )}
                        </>
                    )}
                </Filter>
            </ModuleContainer>
        </>
    );
};

export default M170Filter;
