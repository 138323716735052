import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';

export const QuantityDiscountPopoverWrapper = styled.div({
    [breakpoints.sm]: {
        width: '100vw',
        maxWidth: '320px',
    },
});

export const QuantityDiscountPopoverTitle = styled.div({
    fontWeight: theme.fontWeights.semiBold,
    padding: theme.space[3],
    marginTop: '-1px',
    borderBottom: `1px solid ${theme.colors.grey30}`,
});

export const QuantityDiscountPopoverItem = styled.div<{ disabled?: boolean }>(({ disabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    gap: theme.space[3],
    padding: theme.space[3],
    marginTop: '-1px',
    borderBottom: `1px solid ${theme.colors.grey30}`,

    ...(disabled && {
        color: theme.colors.grey50,
        pointerEvents: 'none',
    }),

    '&:hover': {
        background: theme.colors.grey30,
    },

    [breakpoints.sm]: {},
}));

export const QuantityDiscountPopoverValues = styled.div(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.space[3],
}));

export const DiscountLabelAndGrossPrice = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: theme.space[3],
}));

export const StyledLabelAndPriceText = styled.div(() => ({
    display: 'flex',
    gap: theme.space[2],
}));

export const StyledPriceHoverLabel = styled.p(() => ({
    color: theme.colors.black,
    whiteSpace: 'nowrap',
    fontSize: theme.fontSizes.sm,
}));

export const StyledPriceHoverValue = styled.p(() => ({
    color: theme.colors.grey80,
    whiteSpace: 'nowrap',
    fontSize: theme.fontSizes.sm,
}));
