import React from 'react';
import { IAccordionItem, IHeadline } from '~/lib/data-contract';
import styled from '@emotion/styled';
import { Accordion } from '$shared/components/accordion';
import Headline from '$shared/components/headline';
import { RawHtml } from '$shared/components/raw-html';

export interface M120Props {
    /**
     * Accordion headline style preferences
     */
    headline?: IHeadline | undefined;

    /**
     * Accordion Items
     */
    accordionItems: IAccordionItem[];
}

const Styled120 = styled.div({
    width: '100%',
});

const Styled120Headline = styled.div({
    marginBottom: '10px',
});

export const M120 = ({ headline, accordionItems }: M120Props) => {
    if (accordionItems?.length == 0) {
        return null;
    }

    return (
        <Styled120>
            {headline?.tag && (
                <Styled120Headline>
                    <Headline
                        as={headline?.tag}
                        size={headline?.style}
                        hideOnMobile={headline?.hideOnMobile}
                    >
                        {headline?.text}
                    </Headline>
                </Styled120Headline>
            )}
            {accordionItems.map((item) => (
                <Accordion header={item?.headline} key={item?.headline}>
                    <RawHtml html={item?.body?.html} />
                </Accordion>
            ))}
        </Styled120>
    );
};
