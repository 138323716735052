import {
    flushPreloadBuffer,
    initGtm,
    trackVirtualPageview,
} from '$shared/services/gtm/gtm.service';

export const useGtm = () => {
    return {
        flushPreloadBuffer,
        initGtm,
        trackVirtualPageview,
    };
};
