import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { retrievePriceAndStock } from '$features/order-service/retrieve-price-and-stock';

type Params = {
    primaryProductNumber: string | null | undefined;
    sanitizedItemNumber: string | null | undefined;
    quantityValue?: number;
    canFetch: boolean;
};

export const useFetchPriceAndStock = (params: Params) => {
    const fetchPrices = async () => {
        if (!params.primaryProductNumber) {
            return null;
        }
        return await retrievePriceAndStock([params.primaryProductNumber], undefined, false);
    };

    const { data: listOfPrices, isLoading, isError } = useQuery(
        [
            'useFetchPriceAndStock',
            params.primaryProductNumber,
            params.sanitizedItemNumber,
            params.quantityValue,
        ],
        fetchPrices,
        {
            enabled: !!params.primaryProductNumber && params.canFetch,
        }
    );

    const mappedPrices = useMemo(() => {
        if (isError || isLoading || !listOfPrices) {
            return [];
        }

        return listOfPrices?.[0]?.priceStock?.prices?.map((p) => {
            return {
                netPrice: p?.netPrice,
                grossPrice: p?.grossPrice,
                prettyNetPrice: p?.prettyNetPrice,
                prettyGrossPrice: p?.prettyGrossPrice,
                discountLabel: p?.discountLabel,
                quantity: p?.quantity,
            };
        });
    }, [listOfPrices, isError, isLoading]);

    return {
        mappedPrices,
        isLoading,
        isError,
    };
};
