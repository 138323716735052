import React, { InputHTMLAttributes, useImperativeHandle, useRef, useState } from 'react';
import { StyledInvalidMessage } from '../../invalid-message';
import { useInputField } from '../../../hooks/useInputField';
import { StyledHelpText } from '../../help-text';
import { StyledLabel, StyledTextarea } from './styled';

import { controllableInputElementsProps } from '$shared/components/form';
import { StyledAdditionalContent, StyledInputFieldWrapper } from '../input-element/styled';

export type TextareaElementProps = Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'value'> &
    controllableInputElementsProps;

export const TextareaElement = React.forwardRef<HTMLTextAreaElement, TextareaElementProps>(
    (
        {
            append,
            children,
            helpText,
            id,
            invalidMessage,
            isActive,
            isInvalid,
            isRequired,
            label,
            ...rest
        },
        ref
    ) => {
        useImperativeHandle(ref, () => inputRef.current as HTMLTextAreaElement, [ref]);

        const [hasFocus, setHasFocus] = useState(false);
        const inputRef = useRef<HTMLTextAreaElement>(null);
        const isInputActive = isActive || hasFocus;

        const isValid = !invalidMessage && !isInvalid;

        const { fieldId, helpTextId, invalidMessageId, showHelpText } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        const onFocusHandler = () => setHasFocus(true);
        const onBlueHandler = () => {
            setHasFocus(false);
        };

        return (
            <label>
                <StyledInputFieldWrapper
                    key={fieldId}
                    onFocus={onFocusHandler}
                    onBlur={onBlueHandler}
                    isValid={isValid}
                >
                    <StyledTextarea
                        withLabel={!!label}
                        isActive={isInputActive}
                        id={fieldId}
                        ref={inputRef}
                        {...rest}
                    />

                    {label && (
                        <StyledLabel
                            title={label}
                            isActive={isInputActive}
                            isValid={isValid}
                            hasFocus={hasFocus}
                            required={isRequired}
                        >
                            {label}
                        </StyledLabel>
                    )}
                </StyledInputFieldWrapper>

                {children}

                {append ? <StyledAdditionalContent children={append} /> : null}

                {invalidMessage ? (
                    <StyledInvalidMessage id={invalidMessageId} children={invalidMessage} />
                ) : null}

                {showHelpText ? <StyledHelpText id={helpTextId} children={helpText} /> : null}
            </label>
        );
    }
);
