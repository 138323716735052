import { Headline } from '$shared/components/headline';
import Table from '$shared/components/table';
import { breakpoints, theme} from '$theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { JobVacancyTableProps } from '../../models/models';

export const StyledJobsDesktopTable=styled(Table)(() => ({
   display: 'none',
    [breakpoints.frame]: {
        // Switch from device to desktop design
        display: 'table',
    },
}));
 export const JobsTableHeadline = styled(Headline)<JobVacancyTableProps>(() => ({
  display: 'inline-block', 
  position: 'relative',
  whiteSpace: 'nowrap',
  paddingRight: theme.space[5],
   }),
   ifProp({isColumnSortable: true}, ({theme}) => ({    
    ':hover': {
         color: theme.colors.primary,
        cursor: 'pointer',
      },
    })));
 export const StyledJobTableHeadlineIcon =styled.span( () => ({
        display: 'flex',      
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
 }));

