import React from 'react';
import { StyledHeader, StyledHeaderLogoWrapper, StyledHeaderNavigation } from './styled';
import { StepNavigation } from '~/features/checkout';
import HeaderLogo from '$layouts/header/components/header-logo';
import { StyledCheckoutMainArea } from '$templates/pages/components/P310CheckoutPage/style';

export const CheckoutHeader = () => {
    return (
        <>
            <StyledHeader>
                <StyledHeaderLogoWrapper>
                    <HeaderLogo />
                </StyledHeaderLogoWrapper>
                <StyledHeaderNavigation>
                    <StyledCheckoutMainArea>
                        <StepNavigation />
                    </StyledCheckoutMainArea>
                </StyledHeaderNavigation>
            </StyledHeader>
        </>
    );
};
