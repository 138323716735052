import create from 'zustand';
import { ContactInfo } from '../models/contact-info';
import { DeliveryAddress } from '../models/delivery-address';
import { InformationForm } from '../models/information-form';
import { OrderActions } from '../models/order-actions';
import { OrderState } from '../models/order-state';

const emptyValue = '';

const defaultValues: OrderState = {
    newContactInfo: {
        emailAddress: '',
        fullName: '',
        persistContactInfo: false,
        phoneNumber: '',
    },
    customerReferenceNumber: '',
    deliveryAddress: {
        address: '',
        city: '',
        name: '',
        persistDeliveryAddress: false,
        zipCode: '',
    },
    deliveryAddressId: '',
    pickupOrder: false,
    useDeliveryAddressId: true,
    splitOrder: true,
    deliveryAddressOption: undefined,
    existingContactInfo: {},
    existingContactInfoChosenOption: undefined,
    useExistingContactInfo: true,
    quote: {},
};

export const useOrderStore = create<OrderActions & OrderState>((set, get) => ({
    ...defaultValues,
    createQuoteRequest: () => {
        const state: OrderState = get();

        const contactInfo = state.useExistingContactInfo
            ? state.existingContactInfo
            : state.newContactInfo;

        return {
            contact: {
                fullname: contactInfo?.fullName || emptyValue,
                eMail: contactInfo?.emailAddress || emptyValue,
                phone: contactInfo?.phoneNumber || emptyValue,
                mobile: contactInfo?.phoneNumber || emptyValue,
            },
            deliveryAddress: state.useDeliveryAddressId
                ? {
                      addressNo: state.deliveryAddressId,
                      addressLine1: state.deliveryAddressOption?.option?.street || emptyValue,
                      city: state.deliveryAddressOption?.option?.zipAndCity || emptyValue,
                      countryName: state.deliveryAddressOption?.option?.country || emptyValue,
                      name1: emptyValue,
                      name2: state.deliveryAddressOption?.option?.name || emptyValue,
                      postcalCode: state.deliveryAddressOption?.option?.zipCode || emptyValue,
                      saveAddress: false,
                  }
                : {
                      addressNo: emptyValue,
                      addressLine1: state.deliveryAddress?.address,
                      city: state.deliveryAddress?.city,
                      countryName: emptyValue,
                      name1: emptyValue,
                      name2: state.deliveryAddress?.name,
                      postcalCode: state.deliveryAddress?.zipCode,
                      saveAddress: false, // Disabled: KYOC-1445
                  },
            customerOrderNumber: state.customerReferenceNumber,
            pickup: state.pickupOrder,
            splitDelivery: state.splitOrder,
        };
    },

    resetOrderStore: () => set({ ...defaultValues }),

    setNewContactInfo: (newContactInfo: ContactInfo) =>
        set({
            newContactInfo,
            useExistingContactInfo: false,
        }),

    setExistingContactInfo: (existingContactInfo?: ContactInfo) =>
        set({
            existingContactInfo,
            useExistingContactInfo: true,
        }),

    setLastSelectedExistingContactInfoOption: (option) =>
        set({
            existingContactInfoChosenOption: option,
        }),

    setDeliveryAddressOption: (option) =>
        set({
            deliveryAddressOption: option,
        }),

    setDeliveryAddress: (deliveryAddress: DeliveryAddress) =>
        set({
            deliveryAddress,
            useDeliveryAddressId: false,
        }),

    setDeliveryAddressId: (deliveryAddressId: string) =>
        set({
            deliveryAddressId,
            useDeliveryAddressId: true,
        }),

    setDeliveryOptions: (pickupOrder?: boolean, splitOrder?: boolean) =>
        set({
            pickupOrder,
            splitOrder,
        }),

    setFromForm: (informationForm: InformationForm) => {
        set({
            customerReferenceNumber: informationForm.customerReferenceNumber,
        });

        if (informationForm.useExistingContactInfo) {
            const { name, email } = informationForm.existingContactInfoOption?.option || {};

            get().setExistingContactInfo({
                fullName: name || '',
                emailAddress: email || '',
            });
            get().setLastSelectedExistingContactInfoOption(
                informationForm.existingContactInfoOption
            );
        } else {
            get().setNewContactInfo(informationForm.contactInfo);
        }

        if (informationForm.useDeliveryAddressId) {
            get().setDeliveryAddressId(informationForm.deliveryAddressOption.value);
            get().setDeliveryAddressOption(informationForm.deliveryAddressOption);
        } else {
            get().setDeliveryAddress(informationForm.deliveryAddress);
        }
    },

    setQuote: (quote) => set({ quote }),
}));
