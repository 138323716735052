import styled from '@emotion/styled';
import { breakpoints, theme } from '$theme';

export const StyledOrderListItem = styled.li(() => ({
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    padding: `${theme.space[4]}`,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.grey90,
    columnGap: theme.space[2],
    rowGap: theme.space[1],
    gridTemplateColumns: '30px 1.5fr 1.5fr 1fr',
    gridTemplateAreas: `" icon number contact status"
    "icon number date status"
    "trackandtrace trackandtrace trackandtrace trackandtrace"`,

    [breakpoints.sm]: {
        rowGap: 0,
        columnGap: theme.space[3],
        gridTemplateColumns: '30px 3fr 2fr 2fr 1fr 2fr',
        gridTemplateAreas: `" icon number contact date status trackandtrace"`,
        padding: `${theme.space[4]} ${theme.space[5]}`,
    },
}));

export const StyledOrderNumber = styled.div({
    gridArea: 'number',
    fontWeight: theme.fontWeights.bold,
});

export const StyledOrderButton = styled.button(() => ({
    all: 'unset',
    color: theme.colors.blue,
    cursor: 'pointer',
}));

export const StyledSecondOrderItem = styled.div({
    gridArea: 'number',
    fontSize: theme.fontSizes['3xs'],
    color: theme.colors.grey90,
    fontWeight: theme.fontWeights.regular,
});

export const StyledOrderContact = styled.div({
    gridArea: 'contact',
});

export const StyledOrderIcon = styled.div({
    gridArea: 'icon',
    display: 'flex',
});

export const StyledOrderDate = styled.div({
    gridArea: 'date',
});

export const StyledOrderStatus = styled.div({
    gridArea: 'status',
    textAlign: 'right',

    [breakpoints.sm]: {
        textAlign: 'left',
    },
});

export const StyledOrderTrackAndTrace = styled.div({
    gridArea: 'trackandtrace',
    textAlign: 'left',
    paddingTop: theme.space[2],

    [breakpoints.sm]: {
        textAlign: 'right',
        paddingTop: 0,
    },
});
