import React from 'react';

/*
 * Usage example:
 */

// export const useSearch = () => {
//     const [searchQuery, setSearchQuery] = useState('');
//     const debouncedSearchQuery = useDebounce(searchQuery, 200);

//     const { data, isLoading } = useQuery<SearchSuggestionsResponse>(
//         ['searchSuggestions', debouncedSearchQuery],
//         () => cancellableFetch(debouncedSearchQuery),
//         {
//             enabled: !!debouncedSearchQuery,
//         }
//     );

//     return { setSearchQuery, data, isLoading };
// };

export function useDebounce<T>(value: T, delay = 500) {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
        const handler: ReturnType<typeof setTimeout> = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}
