import { TabMenu } from '$shared/components/tab-menu';
import { useTranslation } from '$shared/utils';
import { useProduct } from '$shared/utils/product';
import React, { FC, ReactNode, useMemo } from 'react';
import { PdpDownloads } from './pdp-downloads';
import { ProductListTab } from './product-list-tab/product-list-tab';

export const PdpTabs: FC = () => {
    const { translate } = useTranslation();
    const {
        associatedProductOriginalItemNumbers: accessories = [],
        dataSheetPaths,
        sparePartsProductOriginalItemNumbers: spareParts = [],
        similarProductOriginalItemNumbers: similarProducts = [],
    } = useProduct();

    const tabs = useMemo(() => {
        const tabs = new Array<{ tabName: string; content: ReactNode }>();

        if (accessories.length) {
            tabs.push({
                tabName: translate('product-detail-page.tabs.accessories'),
                content: <ProductListTab sanitizedItemNumbers={accessories} />,
            });
        }

        if (spareParts.length) {
            tabs.push({
                tabName: translate('product-detail-page.tabs.spare-parts'),
                content: <ProductListTab sanitizedItemNumbers={spareParts} />,
            });
        }

        if (similarProducts.length) {
            tabs.push({
                tabName: translate('product-detail-page.tabs.similar-products'),
                content: <ProductListTab sanitizedItemNumbers={similarProducts} />,
            });
        }

        if (dataSheetPaths?.length) {
            tabs.push({
                tabName: translate('product-detail-page.tabs.downloads'),
                content: <PdpDownloads />,
            });
        }

        return tabs;
    }, [accessories, dataSheetPaths, similarProducts, spareParts]);

    return tabs.length ? <TabMenu tabs={tabs} /> : null;
};
