import { theme } from '$theme';
import styled from '@emotion/styled';

export const StyledProgressBarContainer = styled.div({
    width: `calc(100% - ${theme.space[6]})`,
    textAlign: 'center',
    margin: '0px auto',
});
export const StyledProgressBar = styled.div({
    position: 'relative',
    width: '100%',
    height: theme.space[1],
    backgroundColor: theme.colors.grey40,
    overflow: 'hidden',
    margin: `${theme.space[4]} auto`,
});

type LoadByProps = {
    progressInPercent: number;
};
export const StyledLoadedBar = styled.div<LoadByProps>(({ progressInPercent }) => ({
    position: 'absolute',
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.primary,
    transition: 'transform 0.2s ease-in-out',
    transform: `translateX(-${100 - progressInPercent}%)`,
}));
export const StyledToLoadBar = styled(StyledLoadedBar)(({ progressInPercent }) => ({
    opacity: 0.3,
    transform: `translateX(-${100 - progressInPercent}%)`,
}));
