import { ifProp } from 'styled-tools';
import styled from '@emotion/styled';
import { OptionProps } from './option';
import { StyledInputField } from '$shared/components/deprecatedDropdown/deprecateInput';

type StyledOptionProps = Pick<OptionProps, 'selected' | 'disabled'>;

export const StyledOption = styled.span<StyledOptionProps>(
    ({ theme }) => ({
        display: 'block',
        listStyle: 'none',
        padding: `${theme.space[3]} ${theme.space[4]}`,
        cursor: 'pointer',
    }),
    ifProp('selected', ({ theme }) => ({
        backgroundColor: theme.colors.grey20,
    })),
    ifProp('disabled', {
        opacity: 0.6,
    })
);

export const StyledOptions = styled.ul(({ theme }) => ({
    padding: 0,
    margin: 0,
    position: 'absolute',
    top: '100%',
    zIndex: 2,
    left: 0,
    width: '100%',
    backgroundColor: theme.colors.white,
    border: `2px solid ${theme.colors.grey40}`,
    borderTop: 'none',
    boxSizing: 'border-box',
    outline: 'none',
    '&:empty': {
        border: 'none',
    },
}));

export const StyledSelector = styled.div({
    [`${StyledInputField}, input`]: {
        cursor: 'pointer',
    },
});

export const StyledIcon = styled.span<{ isOpen: boolean }>(({ isOpen }) => ({
    transition: '0.1s ease-in-out',
    transform: isOpen ? 'rotate(180deg)' : '',
    lineHeight: 0,
}));
