import { Notifications } from '$features/notifications/components';
import Footer from '$layouts/footer';
import Header from '$layouts/header';
import Breadcrumb from '$shared/components/breadcrumb';
import Container from '$shared/components/container';
import LanguageSelector from '$shared/components/language-selector';
import { PageTransitionContent } from '$shared/components/page-transition';
import { useMarket } from '$shared/utils/market/hooks/useMarket';
import { queryMarketBannerData } from '$shared/utils/market/hooks/queryMarketBannerData';
import { marketKeys } from '$shared/utils/market/model/marketModel';
import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { usePage } from '../../hooks/usePage';
import { MyPagesNestedLayout } from './MyPagesNestedLayout';

type Props = {
    children?: React.ReactNode;
    hideHeader?: boolean;
    hideBreadcrumb?: boolean;
    isMyPage?: boolean;
};

const StyledLayout = styled.div(({ theme }) => ({
    borderTop: `${theme.global.headerBorderThickness} solid ${theme.colors.blue}`,
}));

export const Layout = ({ children, hideHeader, hideBreadcrumb, isMyPage }: Props): JSX.Element => {
    const { breadcrumb } = usePage();
    const { languageSelectorIsOpen, setIsOpen } = useMarket();
    const { data: marketBannerData } = queryMarketBannerData() || {};

    useEffect(() => {
        if (
            sessionStorage.getItem(marketKeys.marketChosen) != marketKeys.marketIsChosen &&
            marketBannerData != undefined
        ) {
            setIsOpen(true);
        }
    }, [marketBannerData]);

    return (
        <StyledLayout>
            {languageSelectorIsOpen && <LanguageSelector />}
            {!hideHeader && <Header />}

            {isMyPage ? (
                <MyPagesNestedLayout breadcrumb={breadcrumb}>
                    <Main children={children} />
                </MyPagesNestedLayout>
            ) : (
                <PageTransitionContent>
                    {!hideBreadcrumb && (
                        <Container>
                            <Breadcrumb items={breadcrumb?.items} />
                        </Container>
                    )}
                    <Main children={children} />
                </PageTransitionContent>
            )}

            <Footer />
            <Notifications />
        </StyledLayout>
    );
};

const Main = styled.main(({ theme }) => ({
    minHeight: `calc(100vh - ${theme.global.headerHeightSM})`,
    paddingBottom: theme.space[8],
    position: 'relative',

    [breakpoints.md]: {
        // Switch from device to desktop design
        minHeight: `calc(100vh - ${theme.global.headerHeightLG})`,
    },
}));
