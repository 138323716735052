import React, { FC, ReactNode } from 'react';
import { StyledFacetWrapper, StyledFacetWrapperHeadline, StyledSelectionWrapper } from './style';
import { DocumentDownload } from './document-download';
import { ImageServiceUrl } from '$shared/utils';
import { usePage } from '$templates/pages';

type IFacetWrapper = {
    headline?: string;
    documentUrl?: string;
    children?: ReactNode;
    additionalContent?: ReactNode;
};

export const FacetWrapper: FC<IFacetWrapper> = ({
    headline,
    additionalContent,
    documentUrl,
    children,
}) => {
    const { culture } = usePage();
    const sheetUrl = ImageServiceUrl(documentUrl?.replace(/^\//, '')?.replace('[LANG]', culture)); // Replace initial slash in documentUrl, and replaceing [LANG] with site culture: KYOC-982

    const wrapperHeader = (
        <StyledFacetWrapperHeadline size={6} textTransform="none" noMargin>
            {headline}
            {additionalContent}
            {sheetUrl && <DocumentDownload url={sheetUrl} />}
        </StyledFacetWrapperHeadline>
    );
    return (
        <StyledFacetWrapper>
            <StyledSelectionWrapper>
                {wrapperHeader}
                {children}
            </StyledSelectionWrapper>
        </StyledFacetWrapper>
    );
};
