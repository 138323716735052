import { ISimpleMetaFieldViewModel, Option, SimpleProductViewModel } from '~/lib/data-contract';

/***
 *
 *  Filter setup models
 *
 */

type FilterExtension = {
    inStockTotal?: number;
};
type FilterResultExtension = SimpleProductViewModel;
type FacetExtension = Partial<ISimpleMetaFieldViewModel>;
type FilterFacetOptionExtension = Pick<Option, 'count'>;
type FilterDataExchangerReturnExtension = {
    userFilters?: {
        favoriteProduct?: boolean;
        customersPurchased?: boolean;
        promotedProducts?: boolean;
        showOnlyInStock?: boolean;
    };
};

/***
 *
 *  Generic models
 *
 */

export type Filter = FilterExtension & {
    facets?: Facet[];
    results?: FilterResult[];
    resultsCurrent?: number;
    resultsTotal?: number;
    /* hood?: FilterExtension;
    & {
        something?: string;
    }; // Settings...? */
};

export type FilterResult = FilterResultExtension & {
    itemNumber?: string | null;
    name?: string | null;
    description?: string | null;
    unit?: string | null;
    imageUrl?: string | null;
};

export enum EFacetType_Generic {
    CHECKBOX = 'CHECKBOX',
    DROPDOWN = 'DROPDOWN',
}

export type Facet = FacetExtension & {
    id?: string | null;
    title?: string | null;
    type?: EFacetType_Generic | null;
    options?: FilterFacetOption[] | null;
    url?: string | null;
};

export type FilterFacetOption = FilterFacetOptionExtension & {
    key?: string | null;
    displayValue?: string | null;
    selected?: boolean | null;
    count?: number | null;
};

export type ActiveFacet = { facet?: Facet; activeOptions?: FilterFacetOption[] };
export type FacetActionData = { facet?: string; options?: string | string[]; overwrite?: boolean };

export type FilterActions = {
    setFacet?(facetInfo: FacetActionData): void;
    setSearchText?(searchText: string): void;
    resetFilter?(): void;

    // Pagination
    goToNextPage?(): void;
    goToPrevPage?(): void;
    setPageIndex?(pageIndex: number): void;
};

export type FilterStates = {
    isLoading?: boolean;
    isLoadingInitially?: boolean;
    searchText?: string;
};

export type FilterContextProps = Filter &
    FilterDataExchangerReturnExtension &
    FilterActions &
    FilterStates & {
        activeQueryState?: IParamsOfArrays;
    };

// UTILS
export type IParamsOfArrays = Record<string, string[]>;

export type FilterDataExchanger<T> = (
    options: {
        params?: IParamsOfArrays;
        filterId?: string;
    } & T
) => Filter & FilterStates & FilterDataExchangerReturnExtension;
