import React, { FC } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { IFormElement } from '~/lib/data-contract';
import { Input } from '$shared/components/form';

export type InputEpiFieldProps = IFormElement & {
    rules?: RegisterOptions;
};

export const InputEpiField: FC<InputEpiFieldProps> = ({
    name = '',
    label,
    placeholder,
    tooltipText,
    value,
    rules,
}) => {
    return (
        <Input
            name={name}
            defaultValue={value}
            label={label}
            placeholder={placeholder}
            helpText={tooltipText}
            rules={rules}
        />
    );
};

export default InputEpiField;
