import React from 'react';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { theme } from '$theme';

interface VideoContainerProps {
    playing?: boolean;
    cover?: boolean;
    noConsent?: boolean;
}

const StyledVideoContainer = styled.div<VideoContainerProps>(
    {
        position: 'relative',
        width: 'calc(100% + 4px)', // Related to comment on margin
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        margin: '-2px', // To accommodate sneaky black sub-pixel borders caused by the embedded youtube player.
    },
    switchProp('playing', {
        false: { cursor: 'pointer' },
    }),
    switchProp('cover', {
        true: { height: '100%' },
    }),
    ifProp('noConsent', {
        background: theme.colors.grey30,
    })
);

export const VideoContainer: React.FC<React.ComponentProps<typeof StyledVideoContainer>> = ({
    playing = false,
    cover = false,
    ...rest
}: VideoContainerProps) => <StyledVideoContainer playing={playing} cover={cover} {...rest} />;

export default VideoContainer;
