import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { switchProp } from 'styled-tools';

export type SpinnerProps = {
    active?: boolean;
    className?: string;
    size?: 'small' | 'medium' | 'large';
};

const spinnerAnimation = keyframes`
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
`;

const StyledSpinnerWrapper = styled.span({
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledSpinner = styled.span<SpinnerProps>(
    ({ active = true }) => ({
        display: 'inline-block',
        borderWidth: '0.15em',
        borderStyle: 'solid',
        borderColor: 'currentColor',
        borderLeftColor: 'rgba(255, 255, 255, 0)',
        borderRadius: '50%',
        animation: `0.65s linear ${spinnerAnimation} infinite`,
        animationPlayState: active ? 'running' : 'paused',
        transformOrigin: 'center',
        boxSizing: 'border-box',
    }),
    switchProp(
        'size',
        {
            small: {
                width: '24px',
                height: '24px',
            },
            medium: {
                width: '50px',
                height: '50px',
            },
            large: {
                width: '75px',
                height: '75px',
            },
        },
        {
            width: '24px',
            height: '24px',
        }
    )
);

export const Spinner = ({ className, ...props }: SpinnerProps) => (
    <StyledSpinnerWrapper className={className}>
        <StyledSpinner {...props} />
    </StyledSpinnerWrapper>
);

export default Spinner;
