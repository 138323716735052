import React, { FC, useEffect, useState } from 'react';
import Link from '$shared/components/link';
import { ILink } from '~/lib/data-contract';
import { ScrollFade } from '$shared/components/scroll-fade';
import {
    StyledSubmenu,
    StyledTabMenu,
    StyledTabButton,
    StyledFadeWrapper,
    StyledDeviceButton,
} from './style';

export type TabMenuModuleProps = {
    buttonLink?: ILink;
    className?: string;
    initialTabIndex?: number;
    onTabChange?: (tab: Tab, i: number) => void;
    size?: 'normal' | 'small';
    storeOpenTabInLocation?: boolean;
    tabs?: Tab[];
};

type Tab = {
    content?: React.ReactNode;
    isBusy?: boolean;
    tabHash?: string;
    tabName?: string;
};

const setUrlHash = (hash: string | undefined) => {
    if (hash) {
        window.location.hash = hash;
    }
};

const parseUrlHashToOpenTabIndex = (tabs: Tab[] | undefined) => {
    if (tabs) {
        if (typeof window !== 'undefined') {
            const hash = window.location.hash.replace(/%20/g, ' ').replace('#', '');
            for (let i = 0; i < tabs.length; i++) {
                if ((tabs[i].tabHash || tabs[i].tabName) == hash) {
                    return i;
                }
            }
        }
    }
    return 0;
};

export const TabMenu: FC<TabMenuModuleProps> = ({
    buttonLink,
    className,
    initialTabIndex = 0,
    onTabChange = () => {
        /* No-op */
    },
    size = 'normal',
    storeOpenTabInLocation = true,
    tabs,
}) => {
    const [openTab, setOpenTab] = useState<number>(initialTabIndex);

    useEffect(() => {
        if (tabs && storeOpenTabInLocation) {
            setOpenTab(parseUrlHashToOpenTabIndex(tabs));
        }
    }, [storeOpenTabInLocation, tabs]);

    const LinkButton = (
        <Link
            children={buttonLink?.text}
            href={buttonLink?.url}
            target={buttonLink?.target}
            variant="primary"
        />
    );

    return (
        <StyledTabMenu className={className}>
            <StyledSubmenu>
                <StyledFadeWrapper>
                    <ScrollFade>
                        {tabs?.map((tab, i) => {
                            return (
                                <StyledTabButton
                                    active={i == openTab}
                                    isBusy={!!tab.isBusy}
                                    isCompact={size === 'small'}
                                    key={i}
                                    onClick={() => {
                                        storeOpenTabInLocation &&
                                            setUrlHash(tab?.tabHash || tab?.tabName);
                                        setOpenTab(i);
                                        onTabChange(tab, i);
                                    }}
                                >
                                    {tab.tabName}
                                </StyledTabButton>
                            );
                        })}
                    </ScrollFade>
                </StyledFadeWrapper>
                {buttonLink && LinkButton}
            </StyledSubmenu>

            {buttonLink && <StyledDeviceButton>{LinkButton}</StyledDeviceButton>}
            <section>{tabs ? tabs[openTab || 0]?.content : ''}</section>
        </StyledTabMenu>
    );
};
