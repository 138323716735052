import create from 'zustand';
import { IMarketContext } from '../types';

export const useMarket = create<IMarketContext>((set) => ({
    culture: '',
    market: '',
    languageSelectorIsOpen: false,
    setIsOpen: (languageSelectorIsOpen) => set({ languageSelectorIsOpen }),
    setMarket: (context) => set(context),
}));
