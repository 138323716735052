import { Icon } from '$shared/components/icon';
import React from 'react';
import { INavigationNode } from '~/lib/data-contract';
import { StyledContent, StyledItem, StyledLink, StyledSuffix } from './styled';

type NodeItemProps = {
    node: INavigationNode;
    onSelectNode?: (node: INavigationNode) => void;
};

export const NodeItem = ({ node, onSelectNode }: NodeItemProps) => {
    const { children, link } = node;
    const asButton = !!children?.length;

    const onClickHandler = () => onSelectNode?.(node);

    if (asButton) {
        return (
            <StyledItem as="button" onClick={onClickHandler}>
                <StyledContent children={link?.title} />
                <StyledSuffix
                    children={
                        asButton ? (
                            <Icon color="blue" icon="chevronRight" aria-hidden="true" />
                        ) : null
                    }
                />
            </StyledItem>
        );
    }

    return (
        <StyledLink href={link?.url || ''} passHref>
            <StyledItem as="a" target={link.target} title={link.title}>
                <StyledContent children={link?.title} />
                <StyledSuffix
                    children={
                        asButton ? (
                            <Icon color="blue" icon="chevronRight" aria-hidden="true" />
                        ) : null
                    }
                />
            </StyledItem>
        </StyledLink>
    );
};
