import Button from '$shared/components/button';
import MaxWidth, { maxWidthStyles } from '$shared/components/max-width';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';

export const ProductFilterGrid = styled(MaxWidth)(() => ({
    gap: theme.space[2],
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr ',
    gridTemplateAreas: `
    "name"
    "product"
    `,

    [breakpoints.frame]: {
        display: 'grid',
        gap: theme.space[6],
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr 3fr',
        gridTemplateAreas: `"name name"
        "filter product"
      `,
    },
}));

export const StyledProductListHeader = styled.div(({ theme }) => ({
    position: 'sticky',
    background: theme.colors.white,
    top: 0,
    right: 0,
    left: 0,
    zIndex: theme.zIndices[1],
    gridArea: 'name',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: `${theme.space[4]} ${theme.space.gutter}`,

    p: {
        fontSize: theme.fontSizes.sm,
        textTransform: 'uppercase',
        color: theme.colors.grey90,
        lineHeight: '140%',
    },

    [breakpoints.frame]: {
        ...maxWidthStyles(theme),
        position: 'static',
        display: 'flex',
    },
}));

export const StyledFilterButton = styled.button(({ theme }) => ({
    all: 'unset',
    lineHeight: '150%',
    fontWeight: theme.fontWeights.semiBold,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: theme.space[2],
}));

export const StyledFilterColumn = styled.div(() => ({
    display: 'none',

    [breakpoints.frame]: {
        display: 'block',
        gridArea: 'filter',
    },
}));

export const StyledProductColumn = styled.div(() => ({
    gridArea: 'product',
}));

const MOBILE_FILTER_HEIGHT = 60;

export const MobileFacetsContainer = styled.div<{noExtraSpacing?: boolean}>(({ theme, noExtraSpacing }) => ({
    padding: noExtraSpacing ? `0 ${theme.space.gutter} 50px` : `0 ${theme.space.gutter} calc(50vh - 100px)`,
    [breakpoints.frame]: {
        padding: noExtraSpacing ? `0 ${theme.space.gutter} 20px` : `0 ${theme.space.gutter} calc(50vh - 100px)`,
    },
}));

export const StyledMobileFilterCtas = styled.div(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderTop: `1px solid ${theme.colors.borderColor}`,
    bottom: 0,
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    gap: 10,
    height: MOBILE_FILTER_HEIGHT,
    padding: theme.space.gutter,
    position: 'fixed',
    width: '100%',
    zIndex: 100,
}));

export const StyledMobileFilterCta = styled(Button)({
    flex: 1,
});
