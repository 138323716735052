import Text from '$shared/components/text';
import Headline, { HeadlineProps } from '$shared/components/headline';
import styled from '@emotion/styled';
import { Icon } from '$shared/components/icon';

type StyledTextColorProp = {
    textColor?: string;
};
type StyledTextOnImageHeadlineProps = HeadlineProps & StyledTextColorProp;
export const StyledTextOnImageHeadline = styled(Headline)<StyledTextOnImageHeadlineProps>(
    ({ theme, textColor }) => ({
        color: textColor || theme.colors.white,
    })
);

type StyledTextOnImageTextProps = HeadlineProps & StyledTextColorProp;
export const StyledTextOnImageText = styled(Text)<StyledTextOnImageTextProps>(
    ({ textColor, theme }) => ({
        color: textColor || theme.colors.white,
    })
);
export const StyledTextOnImageWrapper = styled.div<{ backgroundColor: string }>(
    ({ backgroundColor }) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor,
    })
);
export const StyledTextOnImageContentWrapper = styled.div<StyledTextColorProp>(
    ({ theme, textColor }) => ({
        position: 'absolute',
        bottom: 0,
        left: 0,

        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        padding: theme.space.gutter,
        borderBottom: `8px solid ${theme.colors.blue}`,

        [`${StyledIcon}`]: {
            color: textColor,
        },
    })
);
export const StyledTextOnImageContent = styled.div(() => ({}));
export const StyledTextOnImage = styled.div(() => ({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    minWidth: '280px',
    maxWidth: '300px',
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
    flexShrink: 0,
    marginLeft: `${theme.space[4]}`,
    width: '18px',
    height: '18px',
}));
