import { buildURL } from '$utils/request';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const baseUrl = publicRuntimeConfig.NEXT_PUBLIC_API_IMAGESERVICE_URL;
export const GET_PHOTO_URL = baseUrl + '/imageandfile/getphoto';
export const GET_PDF_URL = baseUrl + '/imageandfile/getpdf';
export const GET_DATASHEET_URL = baseUrl + '/datasheets';

export type ImageSrcProps = {
    path?: string | null;
    width?: number;
    height?: number;
};

function isString(props: ImageSrcProps | string): props is string {
    return typeof (props as string) === 'string';
}

export const ImageServiceUrl = (src?: string | null) => {
    if (!src) return;
    return `${baseUrl}/${src}`;
};

export const productImageSrc = (prop?: ImageSrcProps | string | null) => {
    if (!prop) return;

    if (isString(prop)) {
        return `${GET_PHOTO_URL}/${prop}`;
    }

    const { path, width, height } = prop;
    if (!path) return;

    return `${GET_PHOTO_URL}/${buildURL(path, { width, height })}`;
};

export const productPDFSrc = (src?: string | null) => {
    if (!src) return;
    return `${GET_PDF_URL}/${src}`;
};

export const getDatasheetSrc = (src?: string | null) => {
    if (!src) return;
    return `${GET_DATASHEET_URL}/${src}`;
};
