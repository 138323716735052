import { animate, MotionValue } from 'framer-motion';
import { useImperativeHandle } from 'react';
import { CarouselRef } from '..';

type UseCarouselLightRefProps = {
    ref: React.Ref<CarouselRef>;
    scrollElementRef: React.RefObject<HTMLDivElement>;
    scrollModeRef: React.MutableRefObject<'auto' | 'drag' | 'initial'>;
    scrollDistance: number;
    dragValue: MotionValue<number>;
};
/**
 * Expose a scrollBy method through the forwarded ref.
 * Using it directly on the scrollElement will not work if
 * framer-motion is still animating drag
 */
export const useCarouselLightRef = ({
    ref,
    scrollDistance,
    scrollElementRef,
    scrollModeRef,
    dragValue,
}: UseCarouselLightRefProps) => {
    useImperativeHandle(ref, () => {
        return {
            get scrollDistance() {
                return scrollDistance;
            },
            get scrollLeft() {
                return scrollElementRef.current?.scrollLeft || 0;
            },
            scrollBy: (options?: ScrollToOptions) => {
                const { current: scrollElement } = scrollElementRef;
                const { left = 0 } = options || {};

                if (scrollElement) {
                    // iOS does not support scrollBehaivour smooth.
                    // Using framer-motion to animate the scroll
                    if ('scrollBehavior' in document.documentElement.style) {
                        scrollModeRef.current = 'auto';
                        scrollElement.scrollBy(options);
                    } else {
                        const scrollLeft = scrollElement.scrollLeft;
                        const target = scrollLeft + left;
                        const cappedTarget = Math.max(0, Math.min(scrollDistance, target));
                        const distance = cappedTarget - scrollLeft;

                        // Magic calculation. Between distance/1500 and 0.3s
                        const duration = Math.max(Math.abs(distance) / 1500, 0.3);

                        // Animating the drag value to hook into existing computed
                        // scrolling. Animate will stop if dragValue is updated outside
                        // of the animation
                        animate(dragValue, -cappedTarget, {
                            type: 'tween',
                            ease: 'easeInOut',
                            duration,
                            onPlay() {
                                scrollModeRef.current = 'drag';
                            },
                        });
                    }
                }
            },
        };
    });
};
