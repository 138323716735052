import React from 'react';
import { IM80MediaAndTextBundleModule } from '~/lib/data-contract';
import { M80 } from './m80-media-text-bundle';

export const M80MediaTextBundleBlock = ({
    headline,
    callToAction,
    mediaAndTextItems,
    numberOfColumns,
    id,
}: IM80MediaAndTextBundleModule) => {
    return (
        <M80
            columns={numberOfColumns}
            headline={headline?.text}
            headlineTag={headline?.tag}
            headlineStyleAs={headline?.style}
            headlineHidden={headline?.hideOnMobile}
            actionText={callToAction?.text}
            actionUrl={callToAction?.url}
            items={mediaAndTextItems || []}
            id={id}
        />
    );
};
