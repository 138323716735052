import styled from '@emotion/styled';
import { breakpoints } from '$theme';
import { StyledOrderListItem } from '../style';

export const StyledOrderHeaderGrid = styled(StyledOrderListItem)({
    display: 'none',
    [breakpoints.sm]: {
        display: 'grid',
    },
});

export const StyledOrderIconHeader = styled.div({
    gridArea: 'icon',
});

export const StyledOrderNumberHeader = styled.div({
    gridArea: 'number',
});

export const StyledOrderContactHeader = styled.div({
    gridArea: 'contact',
});

export const StyledOrderDateHeader = styled.div({
    gridArea: 'date',
});

export const StyledOrderStatusHeader = styled.div({
    gridArea: 'status',
});

export const StyledOrderTrackAndTraceHeader = styled.div({
    gridArea: 'trackandtrace',
    textAlign: 'right',
});
