import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { cancellableFetch, hashCode } from '$shared/utils';
import { CREATE_QUOTE_URL } from '$features/order-service/order-endpoints';
import { CreateQuoteRequest, CreateQuoteResponseViewModel } from '$order-models/*';
import { useState } from 'react';
import { useOrderStore } from './use-order-store';

export const createQuoteQueryKey = 'CreateQuote';

export function useCreateQuote() {
    const { createQuoteRequest, setQuote } = useOrderStore();

    const [quoteRequest, setQuoteRequest] = useState<CreateQuoteRequest>();
    const hashedQueryKey = useMemo(() => hashCode(JSON.stringify(quoteRequest)) || '', [
        quoteRequest,
    ]);

    const { data, isFetching, isLoading, isRefetching } = useQuery<CreateQuoteResponseViewModel>(
        [createQuoteQueryKey, hashedQueryKey],
        ({ signal }) =>
            cancellableFetch({
                signal,
                url: CREATE_QUOTE_URL,
                options: {
                    method: 'POST',
                    body: JSON.stringify(quoteRequest),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            }),
        {
            enabled: Boolean(quoteRequest),
        }
    );

    const createNewQuote = () => {
        const request = createQuoteRequest();
        setQuoteRequest(request);
    };

    useEffect(() => {
        if (data) {
            setQuote(data);
        }
    }, [data]);

    return {
        createNewQuote,
        creatingQuoteIsLoading: isFetching,
        creatingQuoteInitialLoading: isLoading,
        isRefetching,
    };
}
