import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';

export const StyledGrabWrapper = styled(motion.div)(
    ifProp('drag', {
        cursor: 'grab',
        ':active': { cursor: 'grabbing' },
    })
);

export const StyledItemsWrapper = styled.div(({ theme: { space } }) => ({
    position: 'relative',
    display: 'flex',
    gap: space[4],
}));

export const StyledCarouselItem = styled.div(({ theme: { space, colors } }) => ({
    position: 'relative',
    cursor: 'pointer',

    img: {
        backgroundColor: colors.white,
        padding: `${space[1]} !important`,
    },

    '&:after': {
        content: '""',
        position: 'absolute',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
}));
