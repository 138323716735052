import { HierarchyTreeNode } from './hierarchy-tree-node';

/**
 * Searches the provided nodes recursively for a node matching the provided path.
 * @param nodes The hierachy-tree nodes to search.
 * @param searchPath The node-path to search for.
 * @returns The found node, or `undefined`.
 */
export function findNodeByPath(
    nodes: HierarchyTreeNode[],
    searchPath: string
): HierarchyTreeNode | undefined {
    if (!searchPath) return;
    for (let i = 0; i < nodes.length; i++) {
        const nodePath = nodes[i].path;
        if (searchPath === nodePath) {
            return nodes[i];
        } else if (searchPath.startsWith(nodePath)) {
            return findNodeByPath(nodes[i].children, searchPath);
        }
    }

    return undefined;
}

/**
 * Searches the provided nodes recursively for a node matching the provided path.
 * @param nodes The hierachy-tree nodes to search.
 * @param searchId The node-id to search for.
 * @returns The found node, or `undefined`.
 */
export function findNodeById(
    nodes: HierarchyTreeNode[],
    searchId?: string
): HierarchyTreeNode | undefined {
    if (!searchId) return;
    for (let i = 0; i < nodes.length; i++) {
        const nodeId = nodes[i].id;
        if (searchId === nodeId) {
            return nodes[i];
        } else if (nodes[i].children.length) {
            const foundNode = findNodeById(nodes[i].children, searchId)
            if(foundNode) {
                return foundNode
            }
        }
    }

    return undefined;
}

/**
 * Searches the provided nodes recursively for the parent node to the provided path.
 * @param nodes The hierachy-tree nodes to search.
 * @param searchPath The node-path to find the parent of.
 * @returns The found parent node, or `undefined`.
 */
export function findParent(
    nodes: HierarchyTreeNode[],
    searchPath: string
): HierarchyTreeNode | undefined {
    const slashIndex = searchPath.lastIndexOf('/');
    return slashIndex > 0 ? findNodeByPath(nodes, searchPath.substring(0, slashIndex)) : undefined;
}

/**
 * @param nodes The node-tree to flatten.
 * @param openNodes An array of currently expanded paths.
 * @returns A flat array of all expanded node-paths.
 */
export function flattenPaths(nodes: HierarchyTreeNode[], openNodes: string[]): string[] {
    return nodes.reduce((a, v) => {
        a.push(v.path);
        if (openNodes.includes(v.path) || (openNodes.length === 0 && v.children.length))
            a.push(...flattenPaths(v.children, openNodes));
        return a;
    }, new Array<string>());
}

export function findAncestors(
    nodes: HierarchyTreeNode[],
    childNode: HierarchyTreeNode
): HierarchyTreeNode[] {
    return nodes.reduce((a, c) => {
        a.push(c);
        if (childNode.path.includes(c.path) && c.path !== childNode.path) {
            a.push(...findAncestors(c.children, childNode));
        }
        return a;
    }, new Array<HierarchyTreeNode>());
}
