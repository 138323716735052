import { IM10HeroModule, IModules } from "~/lib/data-contract";

export const getMyPagesTitle = (pageElements?: IModules[]) => {
    const pageHero = pageElements?.find(
        (element) => element.type === 'm10HeroModule'
    ) as IM10HeroModule;
    if (pageHero && !!pageHero?.headline?.text) {
        return pageHero.headline.text;
    }
    return undefined
}
