import { StyledHeader } from '$layouts/header/style';
import Headline, { StyledHeadline } from '$shared/components/headline';
import styled from '@emotion/styled';

export const StyledFacetWrapper = styled.div(({ theme }) => ({
    [`${StyledHeader}`]: {
        display: 'flex',
        gap: theme.space[1],
    },
}));

export const StyledSelectionWrapper = styled.div(({ theme }) => ({
    marginBottom: theme.space[4],
    paddingTop: theme.space[3],

    [`${StyledHeadline}`]: {
        marginBlockEnd: theme.space[2],
    },
}));

export const StyledFacetWrapperHeadline = styled(Headline)(() => ({
    display: 'flex',
    alignItems: 'center',
}));
