import { useTranslation } from '$shared/utils';
import { useCallback } from 'react';
import { TranslationKey } from '~/lib/data-contract';
import { weakKey } from '~/shared/utils/jsx';
import { Notification, NotificationOptions, Severity } from './useNotificationModel';
import { useNotificationState } from './useNotificationState';

/**
 * Exposes functions and notifications
 * Use to update notification state
 */
export const useNotification = () => {
    const { notifications, add, remove } = useNotificationState();
    const { translate } = useTranslation();

    const dismiss = useCallback((id?: string) => remove(id), [remove]);
    const push = (notification: NotificationOptions) => {
        const id = weakKey(notification);
        const configuredNotification: Notification = {
            headline: translate(getDefaultHeadline(notification.severity)),
            text: translate(getDefaultText(notification.severity)),
            severity: 'error',
            duration: 5000,
            id,
            ...notification,
        };

        add(configuredNotification);

        return () => dismiss(id);
    };

    return { notifications, push, dismiss };
};

const getDefaultHeadline = (severity: Severity | undefined): TranslationKey => {
    switch (severity) {
        case 'success':
            return 'notifications.headline-success';
        case 'info':
            return 'notifications.headline-info';
        default:
            return 'notifications.headline-error';
    }
};

const getDefaultText = (severity: Severity | undefined): TranslationKey => {
    switch (severity) {
        case 'success':
            return 'notifications.text-success';
        default:
            // 'info' does not make sense to have default text for, as they should come with correlated text.
            // Please overwrite
            return 'notifications.text-success';
    }
};
