import { useFrame } from '$shared/utils';
import { INavigationNode } from '~/lib/data-contract';

export const useNavigation = (pageId: string) => {
    const { navigation } = useFrame();

    function getObject(
        obj?: INavigationNode | INavigationNode[],
        accParentIndices?: number[]
    ): null | { node: INavigationNode; accParentIndices?: number[] } {
        let result = null;

        if (obj instanceof Array) {
            for (let i = 0; i < obj.length; i++) {
                result = getObject(obj[i], [...(accParentIndices || []), i]);
                if (result) {
                    break;
                }
            }
        } else {
            if (obj?.id == pageId) {
                return { node: obj, accParentIndices };
            }
            if (obj?.children) {
                result = getObject(obj.children, accParentIndices);
            }
        }
        return result;
    }

    const { accParentIndices } = getObject(navigation?.children) || {};

    const currentSideNav =
        accParentIndices && navigation?.children?.[accParentIndices?.[0]]?.children;

    return { currentSideNav, initialOpenMenuIndices: accParentIndices };
};
