import { Button } from '$shared/components/button';
import Headline from '$shared/components/headline';
import { Image } from '$shared/components/image/image';
import { RawHtml, useRawHtmlWithNewLinesAsBreaks } from '$shared/components/raw-html';
import { productImageSrc } from '$shared/utils';
import { breakpointSizes, getEpiButtonVariant, theme } from '$theme';
import { M21GridItemProps } from '../../m21-grid-item';
import { M21GridItemMaterials } from './m21-grid-item-materials';
import {
    StyledActions,
    StyledContentTextIcon,
    StyledContentTextImageWrapper,
    StyledContentTextSpotContent,
    StyledContentTextSpotContentTexts,
    StyledContentWrapper,
} from './style';

export const M21GridItemContent = ({
    headline,
    text,
    callToActions,
    columnCount,
    horizontalAlignment,
    verticalAlignment,
    commerceImage,
    overwriteImage,
    buttonText,
    productMaterial,
}: M21GridItemProps) => {
    const { mobile, caption } = overwriteImage || {};
    const image = mobile;
    const mediaItem = image;
    const imageSizes = `(max-width: ${breakpointSizes.sm}px) 100vw, ${
        columnCount ? theme.grid.maxWidth / columnCount + 'px' : '100vw'
    }`;

    return (
        <StyledContentWrapper>
            <StyledContentTextImageWrapper>
                {overwriteImage ? (
                    <Image
                        src={mediaItem?.src || ''}
                        alt={(mediaItem as any)?.alt} // Yet another property missing on the models from BE...
                        title={caption}
                        width={16}
                        height={9}
                        sizes={imageSizes}
                    />
                ) : (
                    <Image
                        src={productImageSrc(commerceImage)}
                        width={16}
                        height={9}
                        objectFit="contain"
                        sizes={`(max-width: ${breakpointSizes.sm}px) 100px, ${
                            columnCount ? theme.grid.maxWidth / columnCount + 'px' : '100vw'
                        }`}
                    />
                )}
            </StyledContentTextImageWrapper>
            <StyledContentTextSpotContent
                alignment={horizontalAlignment}
                position={verticalAlignment}
            >
                <StyledContentTextSpotContentTexts>
                    {headline?.text && (
                        <Headline
                            children={useRawHtmlWithNewLinesAsBreaks(headline.text)}
                            as={headline.tag}
                            size={5}
                            hideOnMobile={headline.hideOnMobile}
                        />
                    )}

                    {text?.html && <RawHtml html={text?.html} />}
                    {productMaterial && productMaterial.length > 0 && (
                        <M21GridItemMaterials material={productMaterial} />
                    )}
                </StyledContentTextSpotContentTexts>

                <StyledContentTextIcon icon="arrowRight" color="blue" />
            </StyledContentTextSpotContent>

            {callToActions?.length ? (
                <StyledActions>
                    {callToActions
                        ?.filter(Boolean) // filtering null's out of the array - yep.
                        .map(({ text, url, id, style }) => (
                            <Button
                                key={`${url}-${id}`}
                                variant={getEpiButtonVariant(style)}
                                children={buttonText ? buttonText : text}
                                noIcon
                            />
                        ))}
                </StyledActions>
            ) : null}
        </StyledContentWrapper>
    );
};
export default M21GridItemContent;
