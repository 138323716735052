import { useInfiniteQuery } from '@tanstack/react-query';
import { buildURL, cancellableFetch, useDebounce } from '$shared/utils';
import { SEARCH_CONTENT_URL } from '../service/endpoints';
import {
    ISearchResultCategoryViewModel,
    ISearchResultItemViewModel,
    ISearchResultViewModel,
    SearchResultCategory,
} from '~/lib/data-contract';
import { useMemo } from 'react';

interface PagedContentSearchResponse {
    page: number;
    pageSize: number;
    response: ISearchResultViewModel;
}

function searchContent(
    searchTerm: string,
    page: number,
    pageSize: number,
    signal?: AbortSignal
): Promise<PagedContentSearchResponse> {
    return cancellableFetch<ISearchResultViewModel>({
        signal,
        url: buildURL(SEARCH_CONTENT_URL, {
            query: searchTerm,
            page,
            pageSize,
        }),
    }).then((response) => ({
        page,
        pageSize,
        response,
    }));
}

export const useSearchContent = (searchTerm: string, page = 1, pageSize = 20) => {
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const { data, fetchNextPage, isError, isFetchingNextPage, isInitialLoading } = useInfiniteQuery(
        ['useSearchContent', debouncedSearchTerm, page, pageSize],
        ({ pageParam = { page, pageSize }, signal }) =>
            searchContent(debouncedSearchTerm, pageParam.page, pageParam.pageSize, signal),
        {
            enabled: Boolean(debouncedSearchTerm),
            getNextPageParam: ({ page, pageSize }) => ({
                page: page + 1,
                pageSize,
            }),
        }
    );

    const { informationPages, plpPages, totalInformationPages, totalPlpPages } = useMemo(
        () =>
            (data?.pages ?? []).reduce(
                (a, { response: v }, pageIndex) => {
                    (v.categories as (ISearchResultCategoryViewModel & {
                        totalResults: number;
                    })[]).forEach(({ category, results, totalResults = 0 }) => {
                        if (category === SearchResultCategory.categoryListPage) {
                            a.plpPages.push(...results);
                            a.totalPlpPages = totalResults;
                        } else if (
                            category === SearchResultCategory.articlePage ||
                            category === SearchResultCategory.categoryDetailPage ||
                            category === SearchResultCategory.contentPage ||
                            category === SearchResultCategory.newsPage
                        ) {
                            if (pageIndex === 0) {
                                a.totalInformationPages = a.totalInformationPages + totalResults;
                            }

                            a.informationPages.push(...results);
                        }
                    });

                    return a;
                },
                {
                    informationPages: new Array<ISearchResultItemViewModel>(),
                    plpPages: new Array<ISearchResultItemViewModel>(),
                    totalInformationPages: 0,
                    totalPlpPages: 0,
                }
            ),
        [data?.pages]
    );

    return {
        fetchNextPage,
        informationPages,
        isError,
        isFetchingNextPage,
        isInitialLoading,
        plpPages,
        totalInformationPages,
        totalPlpPages,
    };
};
