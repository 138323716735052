import create from 'zustand';
import { CHECKOUT_STEP } from '../models';

type CheckoutStepState = {
    steps: CHECKOUT_STEP[];
    activeStep: CHECKOUT_STEP;
    setActiveStep: (step: CHECKOUT_STEP) => void;
    isStepYetToBeActive: (step: CHECKOUT_STEP) => boolean;
    nextStep: () => CHECKOUT_STEP;
    previousStep: () => CHECKOUT_STEP;
    resetState(): void;
};

export const useCheckoutStepsState = create<CheckoutStepState>((set, get) => ({
    steps: [CHECKOUT_STEP.INFORMATION, CHECKOUT_STEP.DELIVERY, CHECKOUT_STEP.CONFIRM],
    activeStep: CHECKOUT_STEP.INFORMATION,
    setActiveStep: (activeStep: CHECKOUT_STEP) => set({ activeStep }),
    nextStep: () => {
        const { steps, activeStep } = get();
        const next = steps.indexOf(activeStep) + 1;
        const nextStep = steps[next] ? next : steps.length - 1;
        return steps[nextStep];
    },
    previousStep: () => {
        const { steps, activeStep } = get();
        const previous = steps.indexOf(activeStep) - 1;
        const previousStep = steps[previous] ? previous : 0;
        return steps[previousStep];
    },
    isStepYetToBeActive: (step: CHECKOUT_STEP) => {
        const { steps, activeStep } = get();
        return steps.indexOf(step) > steps.indexOf(activeStep);
    },
    resetState: () => set({ activeStep: CHECKOUT_STEP.INFORMATION }),
}));
