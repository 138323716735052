import { ButtonIcon } from '$shared/components/button-icon/button-icon';
import { Icon } from '$shared/components/icon';
import { useTranslation } from '$shared/utils';
import { theme } from '$theme';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, useCallback, useMemo, useState } from 'react';
import { ifNotProp } from 'styled-tools';
import { useFavorites } from './use-favorites';

type FavoritesToggleProps = {
    sanitizedItemNumber: string;
};

const selectedKeyframe = keyframes({
    '0%': {
        bottom: 0,
        transform: 'rotate(0deg)',
    },
    '33%': { transform: 'rotate(-8deg)' },
    '50%': {
        bottom: 1,
        transform: 'rotate(0deg)',
    },
    '66%': { transform: 'rotate(8deg)' },
    '100%': {
        bottom: 0,
        transform: 'rotate(0deg)',
    },
});

type StyledButtonProps = {
    isSelected: boolean;
    toggleWiggle: boolean;
};

export const StyledFavoritesToggle = styled(ButtonIcon)<StyledButtonProps>(
    ({ isSelected, toggleWiggle }) => ({
        animation: toggleWiggle ? `${selectedKeyframe} 0.3s ease-in-out` : undefined,
        willChange: 'transform, bottom',
        svg: {
            color: isSelected ? theme.colors.blue : undefined,
            fill: isSelected ? theme.colors.blue : undefined,
        },
        '&:active': {
            svg: {
                color: theme.colors.blue50,
                fill: theme.colors.blue50,
            },
        },

        '&:disabled': {
            svg: {
                color: theme.colors.grey50,
                fill: theme.colors.grey50,
            },
        },
    }),
    ifNotProp('isSelected', {
        '&:hover': {
            svg: {
                color: theme.colors.grey50,
                fill: theme.colors.grey50,
            },
        },
        '&:active': {
            svg: {
                color: theme.colors.blue50,
                fill: theme.colors.blue50,
            },
        },
    })
);

export const FavoritesToggle: FC<FavoritesToggleProps> = ({ sanitizedItemNumber }) => {
    const { translate } = useTranslation();
    const { addFavorite, deleteFavorite, isBusy, isFavorite } = useFavorites();
    const [wiggle, setWiggle] = useState(false);

    const animateWiggle = () => {
        setWiggle(true);
        setTimeout(() => setWiggle(false), 500);
    };

    const isPressed = useMemo(() => isFavorite(sanitizedItemNumber), [
        isFavorite,
        sanitizedItemNumber,
    ]);

    const toggle = useCallback(
        (sanitizedItemNumber: string) =>
            isPressed ? deleteFavorite(sanitizedItemNumber) : addFavorite(sanitizedItemNumber),
        [sanitizedItemNumber, isPressed, deleteFavorite, addFavorite]
    );

    return (
        <StyledFavoritesToggle
            label={translate('favorites.add-to-favorites')}
            handleClick={() => (toggle(sanitizedItemNumber), animateWiggle())}
            aria-pressed={isPressed}
            isLoading={isBusy}
            isSelected={isPressed}
            toggleWiggle={wiggle}
            maxButtonHeight={24}
            children={<Icon icon="starOutline" size="md" />}
        />
    );
};
