import { useTranslation } from '$shared/utils';
import React, { FC } from 'react';

import {
    StyledProductHeader,
    StyledProductHeaderNumber,
    StyledProductHeaderPrice,
    StyledProductHeaderStatus,
    StyledProductLineHeaderGrid,
    StyledProductLineBasket,
} from './style';

export type ProductLineProps = {
    hideOnMobile?: boolean;
};

export const ProductLineHeader: FC<ProductLineProps> = ({ hideOnMobile }) => {
    const { translate } = useTranslation();
    return (
        <StyledProductLineHeaderGrid hideOnMobile={hideOnMobile}>
            <StyledProductHeader
                children={translate('product-list-page.product-headers.product-name')}
            ></StyledProductHeader>

            <StyledProductHeaderNumber
                children={translate('product-list-page.product-headers.part-number')}
            ></StyledProductHeaderNumber>

            <StyledProductHeaderPrice
                children={translate('product-list-page.product-headers.price')}
            ></StyledProductHeaderPrice>

            <StyledProductHeaderStatus
                children={translate('product-list-page.product-headers.stock-status')}
            ></StyledProductHeaderStatus>

            <StyledProductLineBasket
                children={translate('product-list-page.product-headers.quantity')}
            ></StyledProductLineBasket>
        </StyledProductLineHeaderGrid>
    );
};
