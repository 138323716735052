import styled from '@emotion/styled';
import { breakpoints, theme } from '$theme';
import Tag from '$shared/components/tag';
import Link from '../link/link';
import { Icon } from '../icon';
export const StyledSubheadline = styled.div({
    fontWeight: theme.fontWeights.bold,
    fontSize: theme.fontSizes.sm,
    marginTop: '0.75em',
    div: {
        color: theme.colors.grey80,
    },
});
export const StyledArticle = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: theme.space[5],
    paddingTop: theme.space[5],
    borderTop: 'solid 1px ' + theme.colors.grey40,
});
export const StyledImageColumn = styled.div({
    display: 'inline-block',
    width: '100%',

    [breakpoints.sm]: {
        width: '35%',
        paddingRight: theme.space[5],
    },
});
export const StyledDetailsColumn = styled.div({
    marginTop: '0.5em',
    width: '95%',
    [breakpoints.sm]: {
        display: 'inline-block',
        width: '65%',
    },
});
export const StyledArticleTopRow = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const StyledArticleFilterList = styled.div({});
export const StyledArticleFilterTag = styled(Tag)({
    marginRight: theme.space[2],
});
export const StyledDate = styled.p(({ theme }) => ({
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.colors.grey70,
    fontSize: theme.fontSizes.xs,
    marginTop: '0.5em',
    letterSpacing: '.1rem',
    verticalAlign: 'bottom',
}));

export const StyledLinkHeader = styled(Link)({
    '&, &:hover, &:visited, &:active': {
        textDecoration: 'none',
    },
});
export const StyledHeadlineWithIcon = styled.div({
    position: 'relative',
});
export const StyledHeadlineIcon = styled(Icon)({
    position: 'absolute',
    right: 0,
    width: '16px',
});
