import { theme } from '$theme';
import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';
import { inputRequiredStyle } from '../input-element/styled';

type StyledTextAreaProps = {
    withLabel: boolean;
    isActive: boolean;
};

type StyledLabelProps = {
    hasFocus?: boolean;
    isActive?: boolean;
    isValid?: boolean;
    required?: boolean;
};

export const StyledTextarea = styled.textarea<StyledTextAreaProps>(
    ({ theme }) => ({
        resize: 'none',
        height: 120,
        backgroundColor: theme.colors.white,
        color: theme.colors.defaultText,
        border: `2px solid ${theme.colors.grey40}`,
        lineHeight: theme.lineHeights.single,
        position: 'relative',
        cursor: 'type',
        outline: 0,
        width: '100%',
        boxSizing: 'border-box',
        textAlign: 'left',
        display: 'block',
        padding: `${theme.space[3]} ${theme.space[4]}`,
        fontSize: theme.fontSizes.md,
        fontFamily: theme.fonts.body,
        '&::placeholder': {
            transition: '0.1s color',
            color: theme.colors.grey70,
        },
    }),
    ifProp('withLabel', ({ theme }) => ({
        padding: `25px ${theme.space[4]} 5px`,
    })),
    ifProp(
        { withLabel: true, isActive: false },
        {
            [`&::placeholder`]: {
                color: 'transparent',
            },
        }
    )
);

export const StyledLabel = styled.span<StyledLabelProps>(
    ({ theme, hasFocus }) => ({
        position: 'absolute',
        top: theme.space[4],
        left: theme.space[4],
        fontSize: theme.fontSizes['md'],
        transitionDuration: '0.1s',
        color: hasFocus ? theme.colors.primary : theme.colors.grey80,
        width: `calc(100% - ${theme.space[4]} - ${theme.space[4]})`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        transformOrigin: 'left top',
        pointerEvents: 'none',
    }),
    ifNotProp('isValid', ({ theme }) => ({
        color: theme.colors.negative,
    })),
    ifProp('isActive', {
        top: theme.space[2],
        transform: 'scale(0.75)',
        width: '110%',
    }),
    ifProp('required', { ...inputRequiredStyle })
);
