import React, { useMemo, useState } from 'react';
import { ShowMoreButton } from '$shared/components/show-more-button';
import { formatString } from '$utils/string-format.helper';
import { useTranslation } from '$utils/translation';
import { IPages } from '~/lib/data-contract';

import Headline from '$shared/components/headline';
import { Icon } from '$shared/components/icon';
import {
    StyledOrderContact,
    StyledOrderDate,
    StyledOrderIcon,
    StyledOrderNumber,
    StyledOrderStatus,
    StyledSecondOrderItem,
    StyledOrderListItem,
    StyledOrderTrackAndTrace,
} from './style';

import AvoidContainerPadding from '$shared/components/avoid-container-padding';
import { OrderHeaders } from './order-headers/order-headers';
import { SearchInput } from '$shared/components/form/components/form-fields/search-input/search-input';
import {
    StyledGenericList,
    StyledGenericProfileGrid,
    StyledProfileButton,
    StyledProfileHeadline,
    StyledProfileList,
    StyledProfileSearch,
} from '../style';
import { useOrders } from '$shared/utils/orders/use-orders';
import { usePrettyDate } from '$shared/utils/pretty-date.helper';
import { useDebounce } from '$shared/utils';
import Link from '$shared/components/link';
import { OrderDownload } from './order-download/order-download';
import { getMyPagesTitle } from '$shared/utils/my-pages.helper';

type Props = {
    actAsAgreementPage?: boolean;
};

export const P220MyOrdersPage = <T extends IPages>({
    metaTitle,
    pageElements,
    actAsAgreementPage,
}: Props & T) => {
    const { translate } = useTranslation();
    const [query, setQuery] = useState<string | undefined>();
    const debouncedQuery = useDebounce(query, 200);
    const { orderData, loadMore, isFetching } = useOrders(debouncedQuery, actAsAgreementPage);
    const prettifyDate = usePrettyDate();

    const pageTitle = useMemo(() => {
        const titleOverwrite = getMyPagesTitle(pageElements);
        return titleOverwrite || metaTitle;
    }, [pageElements, metaTitle]);

    return (
        <StyledGenericProfileGrid>
            <StyledProfileHeadline>
                <Headline noMargin size={2}>
                    {pageTitle}
                </Headline>
            </StyledProfileHeadline>
            <StyledProfileSearch>
                <SearchInput
                    placeholder={translate(
                        actAsAgreementPage
                            ? 'my-pages.agreements.agreements-search'
                            : 'my-pages.orders.orders-search'
                    )}
                    onChange={setQuery}
                    value={query}
                />
            </StyledProfileSearch>
            <StyledProfileList>
                <AvoidContainerPadding butOnlyOnThePhone={true}>
                    <StyledGenericList>
                        <OrderHeaders />
                        {orderData?.orders?.map((item, i) => (
                            <StyledOrderListItem key={i}>
                                <StyledOrderIcon>
                                    <Icon icon="documentPDF" size={30} />
                                </StyledOrderIcon>

                                <StyledOrderNumber>
                                    <OrderDownload
                                        ariaLabel={translate(
                                            actAsAgreementPage
                                                ? 'my-pages.agreements.download-pdf'
                                                : 'my-pages.orders.download-pdf'
                                        )}
                                        orderNumber={item.orderNumber}
                                    />
                                    <StyledSecondOrderItem>
                                        {item.customerOrderNumber}
                                    </StyledSecondOrderItem>
                                </StyledOrderNumber>

                                <StyledOrderContact>{item.contactName}</StyledOrderContact>

                                <StyledOrderDate>
                                    {item.orderDate ? prettifyDate(item.orderDate as string) : '-'}
                                </StyledOrderDate>

                                <StyledOrderStatus>{item.orderStatus}</StyledOrderStatus>
                                <StyledOrderTrackAndTrace>
                                    {item.trackAndTraceNumber || item.trackAndTraceLink ? (
                                        item.trackAndTraceLink ? (
                                            <Link
                                                href={item.trackAndTraceLink}
                                                target="_blank"
                                                plain={true}
                                            >
                                                {translate(
                                                    'my-pages.orders.track-and-trace.orders-follow'
                                                )}
                                            </Link>
                                        ) : (
                                            item.trackAndTraceNumber
                                        )
                                    ) : (
                                        translate(
                                            'my-pages.orders.track-and-trace.order-tracking-info-missing'
                                        )
                                    )}
                                </StyledOrderTrackAndTrace>
                            </StyledOrderListItem>
                        ))}
                    </StyledGenericList>
                </AvoidContainerPadding>
            </StyledProfileList>
            <StyledProfileButton>
                <ShowMoreButton
                    buttonText={translate('my-pages.load-more')}
                    progressText={formatString(
                        translate('my-pages.showing-x-of-x'),
                        orderData?.orders?.length || 0,
                        orderData?.total || 0
                    )}
                    handleClick={() => loadMore()}
                    isLoading={isFetching}
                    progress={{
                        total: orderData?.total,
                        current: orderData?.orders?.length,
                        incrementSize: 24,
                    }}
                />
            </StyledProfileButton>
        </StyledGenericProfileGrid>
    );
};
