import React from 'react';
import { StyledIcon } from '$shared/components/button';
import Link from '$shared/components/link';
import styled from '@emotion/styled';
import { SocialLinkViewModel, SocialType } from '~/lib/data-contract';
import GridItem, { GridItemProps } from './grid-item';
import { Icon } from '$shared/components/icon';
import { theme } from '$theme';
import { getTarget } from '$shared/utils';

type SocialAreaProps = GridItemProps & {
    links?: SocialLinkViewModel[];
};

const socialIcons = {
    facebook: <Icon icon="facebook" color="blue" size={20} />,
    linkedIn: <Icon icon="linkedin" color="blue" size={20} />,
    instagram: <Icon icon="instagram" color="blue" size={20} />,
    pinterest: <Icon icon="pinterest" color="blue" size={20} />,
    youtube: <Icon icon="youtube" color="blue" size={20} />,
} as { [key in keyof typeof SocialType]: JSX.Element };

const StyledLinksContainer = styled.div(() => ({
    display: 'grid',
    gap: theme.space[6],
    marginLeft: theme.space[4],
    gridTemplateColumns: 'repeat(5, min-content)',

    [`${StyledIcon}`]: {
        ':first-of-type:last-of-type': {
            padding: `${theme.space[1]}`,
            paddingRight: `${theme.space[3]}`,
        },
    },
}));

const SocialLink = ({ socialType, link }: SocialLinkViewModel) => {
    const { target, url, title } = link;
    const socialIcon = socialIcons[socialType] || null;

    return (
        <Link href={url} target={target || getTarget(url)} title={title}>
            {socialIcon}
        </Link>
    );
};

export const SocialArea = ({ headline, subHeadline, links = [] }: SocialAreaProps) => (
    <GridItem headline={headline} subHeadline={subHeadline} area="social">
        <StyledLinksContainer>
            {links.map((link) => (
                <div key={link.link.id}>
                    <SocialLink {...link} />
                </div>
            ))}
        </StyledLinksContainer>
    </GridItem>
);

export default SocialArea;
