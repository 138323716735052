import { useAuthentication } from '$shared/hooks/useAuthentication';
import { useTranslation } from '$shared/utils';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import { useCallback, FC } from 'react';
import { StyledSignInButton, StyledWrapper } from './sign-in-for-price-styles';

export const SignInForPrice: FC = () => {
    const { asPath, isReady } = useKyoceraRouter();
    const { signIn } = useAuthentication();
    const { translate } = useTranslation();

    const signInAndReturn = useCallback(() => signIn(isReady ? asPath : undefined), [
        asPath,
        isReady,
        signIn,
    ]);

    return (
        <StyledWrapper>
            {translate('product-detail-page.core-data.log-in-for-prices-prefix')}
            <StyledSignInButton
                children={translate('product-detail-page.core-data.log-in-for-prices-log-in')}
                onClick={signInAndReturn}
            />
            {translate('product-detail-page.core-data.log-in-for-prices-postfix')}
        </StyledWrapper>
    );
};
