import React from 'react';
import { useTheme } from '@emotion/react';
import { IM10HeroModule } from '~/lib/data-contract';
import { M10Hero } from './m10-hero';

export const M10HeroBlock = ({
    backgroundColor,
    callToAction,
    headline,
    height,
    horizontalAlignment,
    media,
    mediaHorizontalAlignment,
    mediaLayout,
    subHeadline,
    textColor,
    verticalAlignment,
    id,
}: IM10HeroModule) => {
    const theme = useTheme();

    return (
        <M10Hero
            id={id}
            actionText={callToAction?.text}
            actionUrl={callToAction?.url}
            actionTarget={callToAction?.target}
            backgroundColor={backgroundColor || theme.colors.grey20}
            contentAlignment={horizontalAlignment}
            contentPosition={verticalAlignment}
            headline={headline?.text}
            hideHeadlineOnMobile={headline?.hideOnMobile}
            headlineTag={headline?.tag}
            headlineStyleAs={headline?.style}
            mediaAlignment={mediaHorizontalAlignment}
            mediaSize={mediaLayout}
            media={media}
            height={height}
            subHeadline={subHeadline?.text}
            subHeadlineTag={subHeadline?.tag}
            subHeadlineStyleAs={subHeadline?.style}
            hideSubHeadlineOnMobile={subHeadline?.hideOnMobile}
            textColor={textColor}
        />
    );
};
