import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Notification } from './notification';
import { useNotification } from '$features/notifications/hooks';
import { StyledList } from './styled';
import { bezierValues, durations } from './animations';

/**
 * Displays UI based on notification state
 * can be used in root or a scoped location
 *
 */
export const Notifications = () => {
    const { notifications, dismiss } = useNotification();

    return (
        <StyledList
            layout
            transition={{
                layout: {
                    duration: durations.moderate01,
                    ease: bezierValues.standard.productive,
                },
            }}
        >
            <AnimatePresence>
                {notifications.map((props) => (
                    <li key={props.id}>
                        <Notification onDismiss={() => dismiss(props.id)} {...props} />
                    </li>
                ))}
            </AnimatePresence>
        </StyledList>
    );
};
