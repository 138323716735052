import React, { FC } from 'react';
import { useNavigation } from '$shared/utils';
import { NavList } from './components/nav-list';
import { NavItem } from './components/nav-item';
import { StyledSidebar, StyledNavItemSmallHeader } from './style';
import { usePage } from '$templates/pages';
import { theme } from '$theme';

const Sidebar: FC = () => {
    const { id } = usePage();
    const { currentSideNav, initialOpenMenuIndices } = useNavigation(id);

    return (
        <StyledSidebar>
            <NavList list={currentSideNav}>
                {({ link, id, children }, index) => (
                    <NavItem
                        headerSize={6}
                        link={link}
                        key={id}
                        initAsOpen={initialOpenMenuIndices?.[1] === index}
                        list={
                            children && (
                                <NavList list={children}>
                                    {({ link, children }, index) => (
                                        <NavItem
                                            header={(isActive) => (
                                                <StyledNavItemSmallHeader
                                                    variant="small"
                                                    isActive={isActive}
                                                >
                                                    {link.title}
                                                </StyledNavItemSmallHeader>
                                            )}
                                            link={link}
                                            initAsOpen={initialOpenMenuIndices?.[2] === index}
                                            list={
                                                children && (
                                                    <NavList list={children}>
                                                        {({ link }, index) => (
                                                            <NavItem
                                                                header={(isActive) => (
                                                                    <StyledNavItemSmallHeader
                                                                        variant="small"
                                                                        isActive={isActive}
                                                                        paddingLeft={theme.space[6]}
                                                                    >
                                                                        {link.title}
                                                                    </StyledNavItemSmallHeader>
                                                                )}
                                                                link={link}
                                                                initAsOpen={
                                                                    initialOpenMenuIndices?.[3] ===
                                                                    index
                                                                }
                                                            />
                                                        )}
                                                    </NavList>
                                                )
                                            }
                                        />
                                    )}
                                </NavList>
                            )
                        }
                    />
                )}
            </NavList>
        </StyledSidebar>
    );
};

export default Sidebar;
