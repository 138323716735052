import { useTranslation } from '$shared/utils';
import React, { memo } from 'react';
import { useCheckoutSteps } from '~/features/checkout';
import { Step } from './components/step/step';
import { StyledStepList, StyledStepListItem } from './styled';

export const StepNavigation = memo(() => {
    const { translate } = useTranslation();
    const { steps, isStepYetToBeActive, activeStep, goToStep } = useCheckoutSteps();

    return (
        <StyledStepList>
            <StyledStepListItem>
                <Step
                    handleClick={() => goToStep(steps[0])}
                    title={translate('checkout-page.steps.step-one')}
                    disabled={isStepYetToBeActive(steps[0])}
                    isSelected={activeStep === steps[0]}
                />
            </StyledStepListItem>
            <StyledStepListItem>
                <Step
                    handleClick={() => goToStep(steps[1])}
                    title={translate('checkout-page.steps.step-two')}
                    disabled={isStepYetToBeActive(steps[1])}
                    isSelected={activeStep === steps[1]}
                />
            </StyledStepListItem>
            <StyledStepListItem>
                <Step
                    handleClick={() => goToStep(steps[2])}
                    title={translate('checkout-page.steps.step-three')}
                    disabled={isStepYetToBeActive(steps[2])}
                    isSelected={activeStep === steps[2]}
                />
            </StyledStepListItem>
        </StyledStepList>
    );
});
