import { CSSObject, keyframes } from '@emotion/react';

const pulseAnimation = keyframes({
    '0%,100%': { opacity: 0.85 },
    '50%': { opacity: 0.5 },
});

/**
 * Adds "fade pulse" effect, can be used to indicate a loading state
 */
export const pulse: CSSObject = {
    animation: `${pulseAnimation} 1.5s infinite`,
};
