import React, { FC } from 'react';
import { splitIntoSubArrays } from '$shared/utils';
import {
    StyledSpan,
    StyledSubNavLink,
    StyledSubNavList,
    StyledSubNavListItem,
    StyledMegaSubSubSubMenu,
} from './style';
import { IN04MainNavigationSubItemPage } from '~/lib/data-contract';
export type MegaSubSubSubMenuProps = {
    lists?: IN04MainNavigationSubItemPage[]
};

export const MegaSubSubSubMenu: FC<MegaSubSubSubMenuProps> = ({
    lists,
}) => {
    if (!lists) { return null }
    const linkColumns = splitIntoSubArrays(lists, 8)
    return (
        <StyledMegaSubSubSubMenu>
            {linkColumns.map((colLinks, I) => (
                <StyledSubNavList key={I}>
                    {colLinks?.map(({ id, link }) => (
                        <StyledSubNavListItem key={id}>
                            <StyledSubNavLink href={link?.url} plain={true}>
                                {({ isActive }) => (
                                    <StyledSpan isActive={isActive}>
                                        {link?.title || link?.text}
                                    </StyledSpan>
                                )}

                            </StyledSubNavLink>
                        </StyledSubNavListItem>
                    ))}
                </StyledSubNavList>
            ))}
        </StyledMegaSubSubSubMenu>
    );
};
export default MegaSubSubSubMenu;
