import React, { FC } from 'react';
import Headline from '$shared/components/headline';
import styled from '@emotion/styled';
import { IP160ArticlePage } from '~/lib/data-contract';
import { breakpoints } from '$theme';
import Link from '$shared/components/link';
import Video from '$shared/components/video';
import { RawHtml, useRawHtmlWithNewLinesAsBreaks } from '$shared/components/raw-html';
import Tag from '$shared/components/tag';
import { DynamicBlockList } from '$templates/blocks';
import { IModules } from '~/lib/data-contract';
import {
    StyledAsideArea,
    StyledContentArea,
    StyledMainArea,
    StyledModulesWrapper,
} from '../P60ModulePage/style';
import Sidebar from '$shared/components/sidebar-navigation/sidebar';
import Image from '$shared/components/image';
import { prettyDate, useTranslation } from '$shared/utils';

const StyledHeader = styled.div({
    textAlign: 'center',
});
const StyledSubheader = styled.p(({ theme }) => ({
    color: theme.colors.grey90,
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    margin: '0.5em',
}));

const StyledDate = styled.p(({ theme }) => ({
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.colors.grey80,
    fontSize: theme.fontSizes['2xs'],
    margin: '0.5em',
    letterSpacing: '.1rem',
}));
const StyledHero = styled.div({
    width: '100%',
    marginTop: '1em',
    marginBottom: '0.5em',
});
const StyledCaption = styled.p(({ theme }) => ({
    fontStyle: 'italic',
    fontSize: theme.fontSizes.xs,
    marginTop: '0.5em',
    marginBottom: '2.5em',
    color: theme.colors.grey50,
}));

const StyledButton = styled.div({
    display: 'inline',
    a: {
        marginTop: '1em',
    },
    marginRight: '1em',
    [breakpoints.sm]: {
        display: 'block',
    },
});
const StyledHeadline = styled(Headline)({});

const StyledLearnMore = styled.div({
    width: '100%',
    marginBottom: '3em',
    [breakpoints.sm]: {
        display: 'inline-block',
        position: 'sticky',
        top: '4rem',
    },
});
const StyledTagGroup = styled.span({
    textAlign: 'center',
    span: {
        marginLeft: '0.5em',
        marginRight: '0.5em',
    },
});

const OnlyOnMobile = styled.span({
    display: 'block',
    [breakpoints.sm]: {
        display: 'none',
    },
});

export const P160ArticlePage: FC<IP160ArticlePage & { formattingCulture?: string }> = ({
    media,
    headline,
    text,
    publishedDate,
    formattingCulture,
    articleElements,
    pageElements,
    relevantLinks,
    categories,
    hideSideMenu,
    hidePublishedDate,
    topAreaPageElements,
}) => {
    const { translate } = useTranslation();
    const relevantLinksRender = relevantLinks && (
        <StyledLearnMore>
            <StyledHeadline size={5}>{translate('articles.learn-more')}</StyledHeadline>
            {relevantLinks.map((button) => {
                return (
                    <StyledButton>
                        <Link
                            variant="primary"
                            children={button?.text}
                            key={button?.id}
                            target={button.target}
                            href={button?.url}
                        />
                    </StyledButton>
                );
            })}
        </StyledLearnMore>
    );

    return (
        <>
            <StyledModulesWrapper>
                {/* The wrapping divs are here to make a last-child selector for the DynamicBlockList work */}
                <DynamicBlockList elements={topAreaPageElements} />
            </StyledModulesWrapper>
            <StyledMainArea>
                <StyledContentArea>
                    <StyledHeader>
                        <StyledTagGroup>
                            {categories?.map(
                                ({ label, id }) => label && <Tag text={label} key={id} />
                            )}
                        </StyledTagGroup>
                        <Headline
                            children={useRawHtmlWithNewLinesAsBreaks(headline?.text || '')}
                            as={headline?.tag || 'h1'}
                            size={headline?.style}
                            hideOnMobile={headline?.hideOnMobile}
                        />

                        <StyledSubheader>
                            <RawHtml html={text?.html} />
                        </StyledSubheader>

                        {!hidePublishedDate && publishedDate && (
                            <StyledDate>{prettyDate(publishedDate, formattingCulture)}</StyledDate>
                        )}
                    </StyledHeader>
                    <StyledHero {...media}>
                        {media?.video ? (
                            <Video
                                src={media?.video?.src}
                                loop={media?.video?.loop}
                                posterSrc={media?.desktop?.src}
                                playing={media?.video?.autoPlay}
                            />
                        ) : media?.desktop?.src ? (
                            <Image width={18} height={7} src={media?.desktop?.src || ''} />
                        ) : null}
                        <StyledCaption>{media?.caption}</StyledCaption>
                    </StyledHero>
                </StyledContentArea>
            </StyledMainArea>

            <StyledMainArea>
                <StyledAsideArea>
                    {!hideSideMenu && <Sidebar />}
                    {relevantLinksRender}
                </StyledAsideArea>

                <OnlyOnMobile>{relevantLinksRender}</OnlyOnMobile>
                <StyledContentArea hasSideMenu={!hideSideMenu}>
                    <DynamicBlockList elements={articleElements as IModules[]} />
                </StyledContentArea>
            </StyledMainArea>
            <DynamicBlockList elements={pageElements} />
        </>
    );
};
