import styled from '@emotion/styled';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { keyframes } from '@emotion/react';

export const StyledAccordion = styled.div(({ theme }) => ({
    width: '100%',
    borderTop: `1px solid ${theme.colors.grey40}`,
}));

export const StyledAccordionTrigger = styled(RadixAccordion.Trigger)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.space[4]} 0`,

    // Button reset
    backgroundColor: 'rgba(255, 255, 255, 0)',
    border: 'none',
    width: '100%',
    cursor: 'pointer',
    textAlign: 'left',

    '&:focus-visible': {
        outline: `3px solid ${theme.colors.blue30}`,
    },
}));

const slideDown = keyframes({
    from: { height: 0 },
    to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
    from: { height: 'var(--radix-accordion-content-height)' },
    to: { height: 0 },
});

export const StyledAccordionIconWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    transitionDuration: '.2s',
    marginLeft: theme.space[4],
    '[data-state=open] > &': {
        transform: 'rotate(180deg)',
    },
}));

export const StyledAccordionContent = styled(RadixAccordion.Content)(({ theme }) => ({
    overflow: 'hidden',
    padding: 2,

    '&[data-state="open"]': {
        animation: `${slideDown} 200ms ease-out`,
        marginBottom: theme.space[4],
    },
    '&[data-state="closed"]': {
        animation: `${slideUp} 200ms ease-out`,
    },
}));
