import { Link } from '$shared/components/link';
import { StyledNavButton } from '$shared/components/button';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';

export const Styled130 = styled.div({
    width: '100%',
    paddingBottom: '0px',
    borderBottom: 'solid 1px ' + theme.colors.grey40,
});

export const Styled130Header = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    [breakpoints.sm]: {
        flexWrap: 'nowrap',
    },
});

export const StyledArticleNavButton = styled(StyledNavButton.withComponent(Link))(() => ({
    padding: '0 0 1px',
    marginTop: '2px',
    [breakpoints.sm]: {
        padding: '0 0 1px',
    },
    '&, &:hover': {
        textDecoration: 'none',
    },
}));

export const StyledNavListGrid = styled.div({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto',
});

export const StyledNavListGridHeaderCol = styled.div({
    fontSize: theme.fontSizes.lg,
    color: theme.colors.grey70,
    paddingRight: theme.space[3],
    margin: `2px 0 ${theme.space[3]}`,
});
export const StyledNavListGridFilterCol = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.space[3],
});
