import React from 'react';
import { BasketLineSkeleton } from '../basket-line/basket-line-skeleton';

const basketLineArr = new Array(4).fill(undefined);

export const BasketListSkeleton = () => (
    <>
        {basketLineArr.map((_, index) => (
            <li key={index}>
                <BasketLineSkeleton />
            </li>
        ))}
    </>
);
