import React, { useMemo, useState } from 'react';
import { ShowMoreButton } from '$shared/components/show-more-button';
import { formatString } from '$utils/string-format.helper';
import { useTranslation } from '$utils/translation';
import Headline from '$shared/components/headline';
import { ProductList } from '$shared/components/product-list';
import { SearchInput } from '$shared/components/form/components/form-fields/search-input/search-input';
import { IP250MyFavouritesPage } from '~/lib/data-contract';
import {
    StyledGenericProfileGrid,
    StyledProfileHeadline,
    StyledProfileSearch,
    StyledProfileList,
    StyledProfileButton,
} from '../style';
import { useFavorites } from '$features/favorites/use-favorites';
import { useProductSearchByItemNumbers } from '$shared/utils/search/hooks/use-search-products-by-item-numbers';
import { ProductListSkeleton } from '$shared/components/skeleton/product-list-skeleton/product-list-skeleton';
import { getMyPagesTitle } from '$shared/utils/my-pages.helper';

const PAGE_SIZE = 24;

export const P250MyFavouritesPage = ({ metaTitle, pageElements }: IP250MyFavouritesPage) => {
    const { translate } = useTranslation();
    const [query, setQuery] = useState('');
    const { favorites } = useFavorites();
    const {
        productsWithPrices,
        productData,
        fetchNextPage,
        isFetching,
        isInitialLoading,
    } = useProductSearchByItemNumbers(query, Array.from(favorites || []), {
        allowEmptySearch: true,
        pageSize: PAGE_SIZE,
        ignoreCustomerMarket: true,
    });
    const pageTitle = useMemo(() => {
        const titleOverwrite = getMyPagesTitle(pageElements);
        return titleOverwrite || metaTitle;
    }, [pageElements, metaTitle]);

    return (
        <StyledGenericProfileGrid>
            <StyledProfileHeadline>
                <Headline noMargin size={2}>
                    {pageTitle}
                </Headline>
            </StyledProfileHeadline>
            <StyledProfileSearch>
                <SearchInput
                    placeholder={translate('my-pages.favorites.favorites-search')}
                    value={query}
                    onChange={setQuery}
                />
            </StyledProfileSearch>
            <StyledProfileList>
                {isInitialLoading ? (
                    <ProductListSkeleton />
                ) : (
                    <ProductList products={productsWithPrices} isLoading={isInitialLoading} />
                )}
            </StyledProfileList>
            <StyledProfileButton>
                <ShowMoreButton
                    buttonText={translate('my-pages.load-more')}
                    handleClick={() => fetchNextPage()}
                    isLoading={isFetching}
                    progressText={formatString(
                        translate('my-pages.showing-x-of-x'),
                        productsWithPrices.length || 0,
                        productData?.total || 0
                    )}
                    progress={{
                        current: productsWithPrices.length,
                        total: productData?.total,
                        incrementSize: PAGE_SIZE,
                    }}
                />
            </StyledProfileButton>
        </StyledGenericProfileGrid>
    );
};
