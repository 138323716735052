import { cancellableFetch } from '$shared/utils';
import { IFormSubmitResult } from '~/lib/data-contract';
import { FORM_SUBMIT_URL } from './endpoints';

export type epiFormSubmissionServiceProps = {
    form: any;
    formGuid?: string;
    formUrl?: string;
    validationToken?: string;
    culture?: string;
    market?: string;
};

export const epiFormSubmissionService = ({
    form,
    formGuid,
    formUrl,
    validationToken,
    culture,
    market,
}: epiFormSubmissionServiceProps) => {
    const formData = new FormData();
    for (const property in form) {
        const crr = form[property];

        if (crr?.[0] instanceof Blob) {
            if (Array.isArray(crr)) {
                crr.forEach((file) => {
                    formData.append(property, file);
                });
            } else {
                formData.append(property, crr[0]);
            }
        } else if (Array.isArray(crr)) {
            crr.forEach((propVal) => {
                formData.append(property, propVal?.value || propVal);
            });
        } else {
            const formProperty = crr instanceof Object ? JSON.stringify(crr) : crr;
            formData.append(property, formProperty ?? '');
        }
    }

    formData.append('__FormGuid', `${formGuid}`);
    formData.append('__FormLanguage', `${culture}`);
    formData.append('__FormMarket', `${market}`);
    formData.append('__FormUrl', `${formUrl}`);

    if (validationToken) {
        formData.append('__RequestVerificationToken', `${validationToken}`);
    }

    return cancellableFetch<IFormSubmitResult>({
        url: FORM_SUBMIT_URL,
        options: {
            method: 'POST',
            headers: {
                // 'Content-Type': is NOT set, because this allows for the browser can set it and add the dynamic boundary value. FX: boundary=----WebKitFormBoundaryzp8u4jIAa0qEwBjE...
            },
            body: formData,
        },
    });
};
