import { useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { buildURL, cancellableFetch, useDebounce, useFrame } from '$shared/utils';
import { SEARCH_FIELD_DEBOUNCE, SEARCH_URL } from '../service/endpoints';
import { SearchResponseViewModel } from '~/lib/data-contract';
import { usePriceAndStockCount } from '$shared/utils/product/hooks/use-price-and-stock-count';
import { ProductLineItem } from '$shared/components/product-line/product-line.model';
import { productMapper } from './product-mapper';
import { useAuthentication } from '$shared/hooks/useAuthentication';

type SearchOptions = {
    pageSize?: number;
    keepPreviousData?: boolean;
    allowEmptySearch?: boolean;
    ignoreCustomerMarket?: boolean;
};

export const useProductSearchByItemNumbers = (
    query: string,
    sanitizedItemNumbers: string[] = [],
    options: SearchOptions = {
        pageSize: 24,
        keepPreviousData: true,
        allowEmptySearch: false,
        ignoreCustomerMarket: false,
    }
) => {
    const { userFound } = useAuthentication();
    const { market } = useFrame();
    const pageSize = options.pageSize || 24;
    const [queryKeyBuster, setQueryKeyBuster] = useState<string[]>();
    const { loadMore, productPricesAndStockCounts } = usePriceAndStockCount(
        ['useProductSearchByItemNumbers', query, ...(queryKeyBuster || [])],
        queryKeyBuster
    );
    const debouncedSearchQuery = useDebounce(query, SEARCH_FIELD_DEBOUNCE);

    const {
        data: productData,
        isFetching, // refetching
        fetchNextPage,
        isLoading, // first load
        isFetchingNextPage,
        isInitialLoading,
        isError,
    } = useInfiniteQuery<SearchResponseViewModel>(
        [
            'searchCommerce',
            debouncedSearchQuery,
            ...sanitizedItemNumbers,
            JSON.stringify(options),
            userFound,
        ],
        ({ signal, pageParam = { from: 0, take: pageSize } }) =>
            cancellableFetch<SearchResponseViewModel>({
                signal,
                url: buildURL(`${SEARCH_URL}/productSearch/searchByItemNumbers?`, {
                    searchText: debouncedSearchQuery,
                    field: 'sanitizedItemNumber',
                    values: sanitizedItemNumbers,
                    from: pageParam.from,
                    take: pageParam.take,
                    ignoreCustomerMarket: options.ignoreCustomerMarket,
                    ...(!userFound ? { customerMarket: market?.anonymousCustomerMarketCode } : {}),
                }),
            }),
        {
            getNextPageParam: ({ pageNumber: previousPage = 0 }) => {
                const nextPage = previousPage + 1;
                return {
                    from: nextPage * pageSize,
                    take: pageSize,
                };
            },
            enabled:
                (!!debouncedSearchQuery || !!options.allowEmptySearch) &&
                !!sanitizedItemNumbers.length,
            keepPreviousData: options.keepPreviousData,
        }
    );

    const flattenedData = useMemo(
        () =>
            productData?.pages?.reduce((acc, curr) => {
                return {
                    products: [...(acc?.products || []), ...(curr?.products || [])],
                    total: curr?.total,
                };
            }, {} as SearchResponseViewModel),
        [productData]
    );

    const mappedResults = useMemo<ProductLineItem[]>(() => {
        const mappedItems = new Array<ProductLineItem>();
        const unmappedItemIds = new Array<string>();

        flattenedData?.products?.forEach((f) => {
            if (!f.originalItemNumber) return;
            const priceAndstock = productPricesAndStockCounts.get(f.originalItemNumber as string);

            if (priceAndstock) {
                mappedItems.push(productMapper({ ...f, ...priceAndstock }, false));
            } else {
                mappedItems.push(productMapper(f, true));
                unmappedItemIds.push(f.originalItemNumber as string);
            }
        });

        if (unmappedItemIds.length) {
            if (!queryKeyBuster) {
                setQueryKeyBuster(unmappedItemIds);
            } else {
                loadMore(unmappedItemIds);
            }
        }

        return mappedItems;
    }, [flattenedData, productPricesAndStockCounts]);

    return {
        productData: flattenedData,
        productsWithPrices: mappedResults,
        isInitialLoading,
        isFetching: isLoading && isFetching,
        isFetchingNextPage,
        fetchNextPage,
        isError,
    };
};
