import React from 'react';
import {
    StyledBasketLinebasket,
    StyledBasketLineGrid,
    StyledBasketLineImage,
    StyledBasketLineName,
    StyledBasketLinePrice,
    StyledBasketLineFavorites,
    StyledBasketLineTotalPrice,
    StyledBasketWrapper,
    StyledPriceStockWrapper,
} from './style';
import { StyledSkeletonListLineImage } from '../skeleton/product-line-skeleton/style';
import { Skeleton } from '../skeleton';

export const BasketLineSkeleton = () => {
    return (
        <>
            <StyledBasketLineGrid>
                <StyledBasketLineImage>
                    <StyledSkeletonListLineImage>
                        <Skeleton pulsating width={'100%'} css={{ aspectRatio: '1' }} />
                    </StyledSkeletonListLineImage>
                </StyledBasketLineImage>
                <StyledBasketLineName>
                    <Skeleton
                        pulsating
                        height={'1em'}
                        width={'80%'}
                        css={{ marginBottom: '2px' }}
                    />
                    <Skeleton pulsating height={'1em'} width={'45%'} css={{ marginTop: '2px' }} />
                    <Skeleton pulsating height={'1em'} width={'55%'} css={{ marginTop: '2px' }} />
                </StyledBasketLineName>
                <StyledBasketLinePrice>
                    <Skeleton
                        pulsating
                        height={'1em'}
                        width={'100%'}
                        css={{ maxWidth: '100px', minWidth: '60px' }}
                    />
                </StyledBasketLinePrice>
                <StyledPriceStockWrapper>
                    <Skeleton
                        pulsating
                        height={'1.5em'}
                        width={'100%'}
                        css={{ maxWidth: '100px', minWidth: '60px' }}
                    />
                    <StyledBasketLineTotalPrice>
                        <Skeleton
                            pulsating
                            height={'1em'}
                            width={'100%'}
                            css={{ maxWidth: '100px', minWidth: '60px', marginLeft: 'auto' }}
                        />
                    </StyledBasketLineTotalPrice>
                </StyledPriceStockWrapper>
                <StyledBasketWrapper>
                    <StyledBasketLineFavorites>
                        <Skeleton
                            pulsating
                            height={'24px'}
                            width={'24px'}
                            css={{ marginRight: '5px' }}
                        />
                    </StyledBasketLineFavorites>
                    <StyledBasketLinebasket>
                        <Skeleton pulsating height={'40px'} width={'121px'} />
                        <Skeleton pulsating height={'40px'} width={'40px'} />
                    </StyledBasketLinebasket>
                </StyledBasketWrapper>
            </StyledBasketLineGrid>
        </>
    );
};
