import styled from '@emotion/styled';
import { InputElementProps } from '../controllable-elements/input-element';

export const StyledHelpText = styled.div<Pick<InputElementProps, 'helpTextPlacement'>>(
    ({ theme, helpTextPlacement = 'left' }) => ({
        color: theme.colors.grey70,
        display: 'flex',
        padding: '2px',
        fontSize: theme.fontSizes['2xs'],
        justifyContent: helpTextPlacement === 'right' ? 'flex-end' : 'flex-start',
        margin: `${theme.space[2]} 0`,
    })
);
