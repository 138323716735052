import React, { FC } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { IFormElement } from '~/lib/data-contract';
import { Textarea } from '$shared/components/form';

export type TextareaEpiFieldProps = IFormElement & {
    rules?: RegisterOptions;
};

export const TextareaEpiField: FC<TextareaEpiFieldProps> = ({
    name = '',
    label,
    placeholder,
    tooltipText,
    rules,
}) => {
    return (
        <Textarea
            name={name}
            label={label}
            placeholder={placeholder}
            helpText={tooltipText}
            rules={rules}
        />
    );
};

export default TextareaEpiField;
