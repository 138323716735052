export const reducedExtensions = (extensionString: string) => {
    let accExt = extensionString;
    if (accExt.includes('.jpg') || accExt.includes('.jpeg')) {
        accExt = accExt
            .replace('.jpg,', '')
            .replace('.jpg', '')
            .replace('.jpeg,', '')
            .replace('.jpeg', '');
        accExt += ', image/jpeg';
        // Example: '.jpg, .png, .jpeg, .gif' => ' .png, .gif, image/jpeg'
    }
    if (extensionString.includes('.png')) {
        accExt = accExt.replace('.png,', '').replace('.png', '');
        accExt += ', image/png';
        // Example: ' .png, .gif, image/jpeg' => '  .gif, image/jpeg, image/png'
    }

    // cleanup for safty,
    accExt = accExt.replace(' , ', '');
    // removes comma separated whitespace ex: '.jpg, , .png' => '.jpg, .png' since
    // comma separated whitespace will be read as a wildcard of the browser, and allow users to upload all file types
    accExt.trim();

    return accExt;
};
