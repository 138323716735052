import styled from '@emotion/styled';
import { StyledTextOnImage } from '$shared/components/text-on-image-box';

export const StyledSubSubMenuArea = styled.div(() => ({
    display: 'flex',
    width: '100%',
}));

export const MegaSubMenuPromotions = styled.div(({ theme }) => ({
    display: 'flex',
    flex: 'auto',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    padding: `${theme.space[7]}`,
    paddingRight: 0,
    marginLeft: 'auto',

    [`${StyledTextOnImage}`]: {
        padding: `0 0 ${theme.space.gutter} ${theme.space.gutter}`,
    },
}));
