import { useUserStore } from "$features/authentication/use-user-store";
import { HierarchyTreeNode } from "$features/search/hierarchy-tree/hierarchy-tree-node";
import { buildURL, cancellableFetch, useDebounce, useFrame } from "$shared/utils";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { HierarchyFacetViewModel } from "~/lib/data-contract";
import { SEARCH_FIELD_DEBOUNCE, SEARCH_URL } from "../service/endpoints";

const searchHierarchyQueryKey = 'useSearchHierarchy';
const searchHierarchyUrl = `${SEARCH_URL}/productsearch/getsearchhierarchy`;

function mapToTreeItem(item: HierarchyFacetViewModel, parentPath = ''): HierarchyTreeNode {
    const path = `${parentPath}/${item.displayName}`;
    return {
        children: (item.subHierarchyFacetViewModel ?? []).map(child => mapToTreeItem(child, path)),
        count: item.count || 0,
        id: item.key || undefined,
        label: item.displayName || '',
        path,
    }
}

export const useSearchHierarchy = (searchQuery: string) => {
    const { market } = useFrame();
    const customerNumber = useUserStore((state) => state.customerNumber);
    const debouncedSearchQuery = useDebounce(searchQuery, SEARCH_FIELD_DEBOUNCE);

    const url = useMemo(() => buildURL(searchHierarchyUrl, {
        customerMarket: customerNumber || market?.anonymousCustomerMarketCode,
        searchText: debouncedSearchQuery,
    }), [customerNumber, debouncedSearchQuery]);

    const { data } = useQuery(
        [searchHierarchyQueryKey, debouncedSearchQuery],
        () => cancellableFetch<HierarchyFacetViewModel[]>({ url }), {
            enabled: Boolean(debouncedSearchQuery),
        }
    );

    return {
        rootNodes: (data || []).map(item => mapToTreeItem(item)),
    };
};
