import React, { FC, useEffect } from 'react';
import Script from 'next/script';
import { useFrame } from '$shared/utils';
import { useCookieConsent } from '$shared/hooks/useCookieConsent';

export const CookiebotScript: FC = () => {
    const { culture } = useFrame();
    const { setConsent } = useCookieConsent();

    useEffect(() => {
        //  Retrieve state of consent, which matches the CookieConsentSettings type - then set the state in useCookieConsent.
        window?.addEventListener('CookiebotOnLoad', (e) => {
            setConsent((e as any)?.target?.Cookiebot?.consent);
        });
    }, []);

    return (
        <Script
            data-blockingmode="auto"
            data-cbid="62638fdd-6379-4483-b572-bdfd4da43bed"
            data-culture={culture}
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            strategy="beforeInteractive"
            type="text/javascript"
        />
    );
};
