import { FilterContextProps, FilterDataExchanger, FilterProvider } from '$shared/utils/filter';
import { FC, ReactNode } from 'react';
import { useFilterQueryState } from '../hooks/useFilterQueryState';
import { AdditionalFilterQueryOptions, useFilterQuery } from '../service/useFilterQuery';

type FilterProps = {
    filterScopeId?: string;
    children?: ((props: FilterContextProps) => ReactNode) | ReactNode;
    stateOverwrites?: ReturnType<typeof useFilterQuery>;
    filterDataExchanger?: FilterDataExchanger<AdditionalFilterQueryOptions>;
};

export const Filter: FC<FilterProps> = ({
    children,
    filterDataExchanger,
    filterScopeId,
    stateOverwrites,
    ...additionalOptions
}) => {
    if (!filterScopeId) return null;

    const {
        activeQueryState,
        setFacet,
        setSearchText,
        resetFilter,
        goToNextPage,
    } = useFilterQueryState(filterScopeId);

    const filter = filterDataExchanger?.({
        params: activeQueryState,
        filterId: filterScopeId,
        ...additionalOptions,
    });

    const props = {
        ...filter,
        activeQueryState,
        goToNextPage,
        resetFilter,
        setFacet,
        setSearchText,
        ...stateOverwrites,
    };

    return (
        <FilterProvider {...props}>
            {typeof children === 'function' ? children(props) : children}
        </FilterProvider>
    );
};
