import { useRawHtml } from './hooks';
import { StyledRichText } from './styled';

export type RawHtmlProps = {
    className?: string;
    html?: string;
};

export const RawHtml = ({ html, className }: RawHtmlProps) => {
    return <StyledRichText children={useRawHtml(html || '')} className={className} />;
};
