import React, { useEffect } from 'react';
import { FC, useState } from 'react';
import { MegaMenuProps } from '../mega-menu';
import MegaMenuContent from '../mega-menu-content';
import MegaMenuLeftMenu from '../mega-menu-left-menu';

export const MegaSubMenu: FC<MegaMenuProps> = ({ menu }) => {
    const [activeSubMenuIndex, setActiveSubMenuIndex] = useState(0);

    useEffect(() => {
        setActiveSubMenuIndex(0);
    }, [menu]);

    switch (menu?.type) {
        case 'n01MainNavigationWithLeftItemsPage':
            return (
                <>
                    <MegaMenuLeftMenu
                        {...menu}
                        activeIndex={activeSubMenuIndex}
                        onSetActiveIndex={setActiveSubMenuIndex}
                    />
                    {menu?.children?.[activeSubMenuIndex] && (
                        <MegaMenuContent {...menu.children[activeSubMenuIndex]} />
                    )}
                </>
            );

        case 'n02MainNavigationWithCenterItemsPage':
            return <MegaMenuContent {...menu} shownWithoutParent />;

        default:
            return null;
    }
};
export default MegaSubMenu;
