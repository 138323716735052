import { inputRequiredStyle } from '$shared/components/form/components/controllable-elements/input-element/styled';
import { StyledForm } from '$shared/components/form/components/form/styled';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

type StyledIndependentLabelProps = {
    required?: boolean;
    isInvalid?: boolean;
};
export const StyledIndependentLabel = styled.label<StyledIndependentLabelProps>(
    {
        display: 'block',
        marginBottom: theme.space[2],
    },
    ifProp('required', { ...inputRequiredStyle }),
    ifProp('isInvalid', {
        color: theme.colors.negative,
        [`&::placeholder`]: {
            color: theme.colors.negative,
        },
    })
);
export const StyledChoiceOption = styled.div({
    marginBottom: theme.space[2],
});
export const StyledChoiceLabel = styled.label<StyledIndependentLabelProps>(
    {
        display: 'flex',
    },
    ifProp('required', { ...inputRequiredStyle })
);

export type EpiFormWrapperProps = {
    hasOverlay?: boolean;
    wideFormat?: boolean;
};

export const EpiFormLayout = styled.div<EpiFormWrapperProps>(
    ifProp('wideFormat', () => ({
        [breakpoints.sm]: {
            display: 'flex',
            gap: theme.space[8],
        },
    }))
);
export const EpiFormRTE = styled.div<EpiFormWrapperProps>(
    ifProp('wideFormat', () => ({
        [breakpoints.sm]: {
            width: '50%',
        },
    }))
);
export const EpiFormWrapper = styled.div<EpiFormWrapperProps>(
    () => ({
        maxWidth: '700px',
        position: 'relative',
    }),
    ifProp('wideFormat', () => ({
        [breakpoints.sm]: {
            width: '50%',
        },
    })),
    ifProp('hasOverlay', () => ({
        [`${StyledForm}`]: {
            filter: 'blur(6px)',
            opacity: '.5',
            transitionDuration: '.5s',
        },
    }))
);

export const EpiFormErrorMessage = styled.div({
    padding: theme.space[4],
    background: theme.colors.red05,
    borderRadius: theme.space[4],
    color: theme.colors.red,
});

export const EpiFormMessage = styled.div({
    width: '100%',
    textAlign: 'center',
    marginBottom: theme.space[4],
});
