import { getAddresses } from '$features/order-service/get-addresses';
import { AddressDto } from '$order-models/*';
import { Option } from '$shared/components/form';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = 'useSavedDeliveryAddresses';

export function useSavedDeliveryAddresses() {
    const { data = [], isLoading } = useQuery([queryKey], getAddresses);

    const defaultAddressAvailable = useMemo(
        () => data.some((address) => !!address.primaryAddress),
        [data]
    );

    const options = useMemo(
        () =>
            data.map<Option<AddressDto>>((address, i) => ({
                checked: defaultAddressAvailable ? !!address.primaryAddress : !i,
                label: address.name || '',
                option: address,
                value: address.addressNumber || '',
            })),
        [data]
    );

    return {
        addressOptions: options,
        isLoadingAddresses: isLoading,
        savedAddresses: data,
    };
}
