import React, { FC, ReactNode, useEffect, useState } from 'react';
import AvoidContainerPadding from '$shared/components/avoid-container-padding';
import { ProductList } from '$shared/components/product-list';
import { FilterResult, useFilter } from '$utils/filter';
import { productMapper } from '../utils/productMapper';
import { useTranslation } from '$utils/translation';
import { StyledRawHtml } from './style';
import { PriceAndStockViewModel } from '~/lib/data-contract';
import { usePriceAndStockCount } from '$shared/utils/product/hooks/use-price-and-stock-count';
import { ProductLineItem } from '$shared/components/product-line/product-line.model';
import { ProductListSkeleton } from '$shared/components/skeleton/product-list-skeleton/product-list-skeleton';
import { useStockStatus } from '$features/stock/hooks/use-stock-status';
import { StockStatus } from '$features/stock/stock-status';

interface FilterProductListProps {
    id: string;
    results?: FilterResult[];
    children?: ReactNode;
    isLoading?: boolean;
    isLoadingInitially?: boolean;
    showOnlyInStock?: boolean;
}

export type ResultsWithStock = FilterResult & PriceAndStockViewModel;

const filterStockOnly = (item: ProductLineItem, showOnlyInStock: boolean) =>
    showOnlyInStock ? useStockStatus(item.stock?.deliveryEstimate) === StockStatus.Status0 : true;

export const FilterProductListControlled: FC<FilterProductListProps> = ({
    children,
    id,
    results,
    isLoading,
    isLoadingInitially,
    showOnlyInStock,
}) => {
    const { translate } = useTranslation();
    const { setStateOverwrites } = useFilter();

    const [mappedResults, setMappedResults] = useState<ProductLineItem[]>();
    const [queryKeyBuster, setQueryKeyBuster] = useState<string[]>();

    useEffect(() => {
        if (mappedResults?.length) {
            const resultsOverwrite = {
                ...(showOnlyInStock ? { inStockTotal: mappedResults?.length } : {}),
            };
            setStateOverwrites?.(resultsOverwrite);
        }
    }, [showOnlyInStock, mappedResults]);

    const {
        loadMore,
        productPricesAndStockCounts,
        isBusy: retrievePriceAndStockIsBusy,
    } = usePriceAndStockCount(['FilterProductList', id, ...(queryKeyBuster || [])], queryKeyBuster);

    useEffect(() => {
        if (results) {
            const mappedItems = new Array<ProductLineItem>();
            const unmappedItemIds = new Array<string>();

            results?.forEach((r) => {
                if (!r.itemNumber) return;
                const priceAndstock = productPricesAndStockCounts.get(r.itemNumber);

                if (priceAndstock) {
                    mappedItems.push(productMapper({ ...r, ...priceAndstock }, false));
                } else {
                    mappedItems.push(productMapper(r, true));
                    unmappedItemIds.push(r.itemNumber);
                }
            });

            if (!mappedResults || !mappedResults.length) {
                setQueryKeyBuster(mappedItems.map((i) => i.primaryProductNumber || ''));
                if (!showOnlyInStock) {
                    setMappedResults(mappedItems);
                } else if (!unmappedItemIds.length) {
                    setMappedResults(
                        mappedItems.filter((item) => filterStockOnly(item, showOnlyInStock))
                    );
                }
                return;
            }

            if (unmappedItemIds.length) {
                loadMore(unmappedItemIds);
            }

            setMappedResults(
                mappedItems.filter((item) => filterStockOnly(item, !!showOnlyInStock))
            );
        }
    }, [results, productPricesAndStockCounts]);

    return (
        <AvoidContainerPadding butOnlyOnThePhone={true}>
            {isLoadingInitially ||
            mappedResults === undefined ||
            (showOnlyInStock && retrievePriceAndStockIsBusy) ? (
                <ProductListSkeleton />
            ) : mappedResults?.length ? (
                <>
                    <ProductList products={mappedResults} isLoading={isLoading} />
                    {children}
                </>
            ) : (
                <StyledRawHtml html={translate('product-list-page.no-products-found')} />
            )}
        </AvoidContainerPadding>
    );
};
