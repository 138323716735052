import { theme } from '$theme';
import styled from '@emotion/styled';
import { breakpoints } from '$theme';
import Link from '$shared/components/link';
import SpotContent, { SpotContentProps } from '$shared/components/spot-content';
import { Icon } from '$shared/components/icon';
import { StyledHeadline } from '$shared/components/headline';
import { StyledText } from '$shared/components/text';
import { StyledButton } from '$shared/components/button';
import { StyledRichText } from '$shared/components/raw-html/styled';
import { StyledLink } from '$shared/components/link/style';

export const StyledMediaTextLink = styled(Link)({
    '&,:hover': {
        textDecoration: 'none',
    },
    width: '100%',
    margin: 0,
    fontSize: theme.fontSizes.md,
    [breakpoints.sm]: {
        padding: '0px 20px',
    },
});

export const StyledMediaTextImageWrapper = styled.div(
    () => ({
        width: 75,
        height: 'auto',
        paddingRight: theme.space[3],
        flexGrow: 0,
        flexShrink: 0,

        [breakpoints.xs]: {
            '> *': {
                height: '60px !important', // NextImage ratio fix
            },
        },

        [breakpoints.sm]: {
            padding: `${theme.space[4]} 0`,
            width: '100%',
        },
        
        '& img': {
            backgroundColor: 'initial',
        },
    })
);

export const StyledActions = styled.div(() => ({
    display: 'flex',
    marginBottom: theme.space['5'],

    [`${StyledButton}`]: {
        [breakpoints.xs]: {
            display: 'none',
        },
    },
}));

export const StyledMediaTextSpotContent = styled(SpotContent)<SpotContentProps>(() => ({
    flexDirection: 'row',
    flexGrow: 2,
    justifyContent: 'space-between',
    alignItems: 'center',

    [`${StyledRichText} ${StyledText}`]: {
        color: theme.colors.grey80,
    },

    [breakpoints.sm]: {
        alignItems: 'unset',
        textAlign: 'center',
        padding: `0 ${theme.space[4]}`,
    },
}));

export const StyledMediaTextSpotContentTexts = styled.div<SpotContentProps>(() => ({
    fontSize: theme.fontSizes['xs'],

    [breakpoints.xs]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [`${StyledRichText}`]: {
            display: 'none',
        },
    },
}));

export const StyledMediaTextIcon = styled(Icon)({
    display: 'none',
    [breakpoints.xs]: {
        display: 'block',
        flexShrink: 0,
    },
});

export const StyledMediaText = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    paddingRight: 15,

    [`${StyledHeadline}`]: {
        marginBottom: 0,
    },
    [`${StyledLink}`]: {
        display: 'none',
    },

    [breakpoints.sm]: {
        flexDirection: 'column',
        paddingRight: 0,
        [`${StyledHeadline}`]: {
            marginBottom: 20,
        },
        [`${StyledLink}`]: {
            display: 'flex',
        },
    },
});
