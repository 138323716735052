import React from 'react';
import { IM75MediaAndTextModule } from '~/lib/data-contract';
import { M75 } from './m75-media-text';

export const M75MediaTextBlock = ({
    headline,
    text,
    callToActions,
    doNotCrop,
    media,
    verticalAlignment,
    horizontalAlignment,
    backgroundColor,
    textColor,
    mediaSize,
    mediaHorizontalAlignment,
    id,
}: IM75MediaAndTextModule) => {
    return (
        <M75
            headline={headline?.text}
            headlineTag={headline?.tag}
            headlineStyleAs={headline?.style}
            headlineHidden={headline?.hideOnMobile}
            doNotCrop={doNotCrop}
            textHtml={text?.html}
            actions={callToActions}
            mediaAlignment={mediaHorizontalAlignment}
            contentAlignment={horizontalAlignment}
            contentPosition={verticalAlignment}
            backgroundColor={backgroundColor}
            textColor={textColor}
            videoPosterSrc={media?.desktop?.src}
            videoAutoPlay={media?.video?.autoPlay}
            mediaSize={mediaSize}
            media={media}
            id={id}
        />
    );
};
