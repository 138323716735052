import { useFrame, useTranslation } from '~/shared/utils';
import React, { FC, useEffect, useRef, useState } from 'react';
import { StyledSearch, StyledSearchDialog } from './search-bar-styles';
import { useSearchProducts } from '$shared/utils/search/hooks/use-search-products';
import { useSearchContent } from '$shared/utils/search/hooks/use-search-content';
import { searchKeys } from '$shared/utils/search/model/searchModel';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import { useNotification } from '$features/notifications/hooks';
import { useRouter } from 'next/router';
import { SearchInput } from '$shared/components/form/components/form-fields/search-input/search-input';
import { useClickAway } from 'react-use';

interface SearchBarProps {
    className?: string;
}

export const SearchBar: FC<SearchBarProps> = ({ className }) => {
    const { translate } = useTranslation();
    const { searchPage } = useFrame();
    const [searchActive, setSearchActive] = useState<boolean | undefined>();
    const [query, setQuery] = useState<string>('');
    const { push } = useKyoceraRouter();
    const { events } = useRouter();
    const { push: pushNotification } = useNotification();
    const ref = useRef(null);

    const {
        productsWithPrices,
        isFetching: isLoadingProducts,
        isError: commerceIsError,
    } = useSearchProducts(query, {
        keepPreviousData: false,
        pageSize: 3,
    });

    const {
        informationPages,
        plpPages,
        isInitialLoading: isLoadingContent,
        isError: contentIsError,
    } = useSearchContent(query, 1, 7);

    useClickAway(ref, () => {
        setSearchActive(false);
    });

    useEffect(() => {
        if (events) {
            const closeSearch = () => setSearchActive(false);
            events.on('routeChangeStart', () => closeSearch());
            return events.off('routeChangeStart', closeSearch);
        }
    }, [events]);

    const handleChangeSearchBar = async (value: string) => {
        setQuery(value);
        setSearchActive(!!value);
    };

    useEffect(() => {
        if (commerceIsError || contentIsError) {
            pushNotification({
                text: translate('search.search-bar.error'),
                severity: 'error',
            });
        }
    }, [commerceIsError, contentIsError]);

    return (
        <StyledSearch className={className} ref={ref}>
            <SearchInput
                isBusy={isLoadingContent || isLoadingProducts}
                onChange={handleChangeSearchBar}
                onFocus={() => setSearchActive(true)}
                onKeyDown={(e) =>
                    (e.code === 'Enter' || e.code === 'NumpadEnter') &&
                    push(`${searchPage?.url}?${searchKeys.queryParamKey}=${query}`)
                }
                placeholder={translate('search.search-bar.search-bar-placeholder')}
                value={query}
            />

            {searchActive && !!query.length && (
                <StyledSearchDialog
                    categories={plpPages}
                    isBusy={isLoadingContent || isLoadingProducts}
                    onClose={() => setSearchActive(false)}
                    pages={informationPages}
                    products={productsWithPrices}
                    query={query}
                />
            )}
        </StyledSearch>
    );
};
