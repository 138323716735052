import { http } from '~/lib/http';
import { URL } from 'url';
import type { IPages, IRedirect } from '~/lib/data-contract';

type Fetch<T> = Omit<Response, 'json'> & {
    json: () => Promise<T>;
};

type ParamType = {
    [key: string]: string | string[] | undefined;
};

const { API_URL } = process.env;

/**
 * Helper method for fetching content from the API
 *
 * @param path The API path without endpoint
 * @param params Additional params for the url
 * @param headers Headers to append
 */
async function fetchAPI<T>(
    path: string,
    params: ParamType = {},
    headers: HeadersInit = {},
    init: RequestInit = {}
): Promise<Fetch<T>> {
    // Build url
    const url = new URL(path, API_URL);

    // Add query params
    Object.entries(params).forEach(([key, value]) => {
        url.searchParams.append(key, String(value));
    });

    console.info('Querying', url.href, headers);

    const withAuthentication = true;

    const response = await http(
        url.href,
        {
            method: 'GET',
            headers: {
                ...headers,
                'Content-Type': 'application/json; charset=utf-8',
            },
            keepalive: true,
            ...init,
        },
        withAuthentication
    );

    const { status, statusText } = response;

    if (status >= 500) {
        console.error(`Failed to fetch API with url:${url}`);
        console.error(statusText);
    }

    return response;
}

/**
 * Helper method for requesting a page from the api.
 * Returns headers, status and data
 *
 * @param url The URL for the page
 * @param params Additional params for the request. Note these params are not added to the provided URL but to the API requst
 * @param headers Headers to append
 */
export async function fetchPage(
    url: string,
    params = {},
    headers: HeadersInit = {},
    init: RequestInit = {}
) {
    return await fetchAPI<IPages | IRedirect>(
        url,
        {
            ...params,
        },
        headers,
        init
    );
}
