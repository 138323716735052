import { breakpointSizes } from '$theme';
import { ReactNode, useMemo, useState } from 'react';
import { ProductImage } from '../image/product-image/product-image';
import { ModuleCarousel } from '../module-carousel';
import { Overlay } from '../overlay';

import {
    StyledImagePreviewerWrapper,
    StyledImagePreviewLargeImage,
    StyledOverlayContentWrapper,
    StyledOverlayCarouselWrapper,
    StyledImagePreviewLargeOverlay,
    ClickableBackground,
} from './style';

type ImagePreviewerItem<ItemData> = ItemData & {
    src?: string;
    alt?: string;
};

type ImagePreviewerProps<ItemData> = {
    itemsData?: ImagePreviewerItem<ItemData>[];
    children?: (itemData: ImagePreviewerItem<ItemData>) => ReactNode;
};

export const ImagePreviewer = <T,>({ itemsData, children }: ImagePreviewerProps<T>) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const items = useMemo(() => itemsData?.map((item) => children?.(item)), [itemsData]);
    const [openPreview, setOpenPreview] = useState(false);

    const productImageRender = useMemo(
        () => (
            <ProductImage
                src={itemsData?.[activeIndex]?.src}
                objectFit="contain"
                priority={true}
                key={itemsData?.[activeIndex]?.src}
                sizes={`(max-width: ${breakpointSizes.sm}px) 100vw, 50vw`}
            />
        ),
        [activeIndex, itemsData]
    );

    const moduleCarouselRender = <ModuleCarousel items={items} onItemSelect={setActiveIndex} />;

    const overlayContent = (
        <StyledOverlayContentWrapper>
            <ClickableBackground onClick={() => setOpenPreview(false)} />
            <StyledImagePreviewLargeOverlay>{productImageRender}</StyledImagePreviewLargeOverlay>
            <StyledOverlayCarouselWrapper>{moduleCarouselRender}</StyledOverlayCarouselWrapper>
        </StyledOverlayContentWrapper>
    );

    return (
        <StyledImagePreviewerWrapper>
            <Overlay
                content={overlayContent}
                fullScreen
                controlled
                open={openPreview}
                onOpenChange={setOpenPreview}
            >
                <StyledImagePreviewLargeImage>{productImageRender}</StyledImagePreviewLargeImage>
            </Overlay>
            {moduleCarouselRender}
        </StyledImagePreviewerWrapper>
    );
};
