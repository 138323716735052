import { Skeleton } from '$shared/components/skeleton';
import { EFacetType_Generic } from '$shared/utils/filter';
import { theme } from '$theme';
import styled from '@emotion/styled';
import { FC } from 'react';

type FacetSkeletonProps = {
    facetType?: EFacetType_Generic | null;
};

const StyledFacetSkeletonWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: theme.space[3],
    marginBottom: theme.space[2],
});

const facetSkeletonSwitch = (facetType: FacetSkeletonProps['facetType']) => {
    const skeletonCheckboxArr = [];

    for (let index = 0; index < 4; index++) {
        skeletonCheckboxArr.push(
            <StyledFacetSkeletonWrapper key={index}>
                <Skeleton pulsating height="26px" width="26px" />
                <Skeleton pulsating height="18px" width="7em" />
            </StyledFacetSkeletonWrapper>
        );
    }

    switch (facetType) {
        case EFacetType_Generic.DROPDOWN:
            return <Skeleton pulsating height="54px" css={{ maxWidth: '350px' }} />;
        default:
            return <>{skeletonCheckboxArr}</>;
    }
};

export const FacetSkeleton: FC<FacetSkeletonProps> = ({ facetType }) => (
    <>{facetSkeletonSwitch(facetType)}</>
);
