import { useCallback, useEffect, useState, KeyboardEvent } from 'react';
import { HierarchyTreeNode } from './hierarchy-tree-node';
import {
    flattenPaths,
    findNodeByPath,
    findParent,
    findNodeById,
    findAncestors,
} from './hierarchy-tree-utils';

export const useHierarchyTreeState = (
    nodes: HierarchyTreeNode[],
    onSelectionChanged: (selection?: string) => void,
    selection?: string
) => {
    const [focusedNode, setFocusedNode] = useState('');
    const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
    const [expandedNodesInitialized, setExpandedNodesInitialized] = useState(false);

    /**
     * Initializes focus and expands nodes, when ready.
     */
    useEffect(() => {
        if (!focusedNode && !selection && nodes.length) {
            setFocusedNode(nodes[0].path);
        }
        if (!expandedNodesInitialized && !selection && nodes.length) {
            setExpandedNodesInitialized(true);
            setExpandedNodes(flattenPaths(nodes, []));
        } else if (!expandedNodesInitialized && selection && nodes.length) {
            setExpandedNodesInitialized(true);
            const selectedNode = findNodeById(nodes, selection);
            if (selectedNode) {
                const ancestors = findAncestors(nodes, selectedNode);
                setExpandedNodes(ancestors?.map((ancestor) => ancestor.path) || []);
            }
        }

    }, [nodes, selection, expandedNodesInitialized]);

    const validateExpandedNodes = () => {
        const newExpandedNodes = flattenPaths(nodes, []);
        if(newExpandedNodes.length !== expandedNodes.length) { 
            setExpandedNodes(newExpandedNodes)
        }
    }

    const collapseNode = useCallback(
        (path: string) => {
            if (!expandedNodes.includes(path)) return;
            setExpandedNodes(expandedNodes.filter((p) => p !== path));
        },
        [expandedNodes]
    );

    const expandNode = useCallback(
        (path: string) => {
            if (expandedNodes.includes(path)) return;
            setExpandedNodes([...expandedNodes, path]);
        },
        [expandedNodes]
    );

    const focusNode = useCallback(
        (path: string) => {
            if (focusedNode === path) return;
            setFocusedNode(path);
        },
        [focusedNode]
    );

    const moveToLast = useCallback(() => {
        const flattenedPaths = flattenPaths(nodes, expandedNodes);
        const lastPath = flattenedPaths[flattenedPaths.length - 1];
        focusNode(lastPath);
    }, [focusNode, expandedNodes, nodes]);

    const moveToFirst = useCallback(() => {
        focusNode(nodes[0].path);
    }, [focusNode, nodes]);

    const moveToNext = useCallback(() => {
        const flattenedPaths = flattenPaths(nodes, expandedNodes);
        const nextIndex = flattenedPaths.indexOf(focusedNode) + 1;
        const nextPath = flattenedPaths[nextIndex];

        if (nextPath) focusNode(nextPath);
    }, [focusedNode, focusNode, expandedNodes, nodes]);

    const moveToPrevious = useCallback(() => {
        const flattenedPaths = flattenPaths(nodes, expandedNodes);
        const previousIndex = flattenedPaths.indexOf(focusedNode) - 1;
        const previousPath = flattenedPaths[previousIndex];

        if (previousPath) focusNode(previousPath);
    }, [focusedNode, focusNode, expandedNodes, nodes]);

    const stepIn = useCallback(() => {
        const node = findNodeByPath(nodes, focusedNode);
        if (!node) return;

        const hasChildren = node.children.length;
        if (!hasChildren) return;

        const isExpanded = expandedNodes.includes(focusedNode);

        if (isExpanded) {
            focusNode(node.children[0].path);
        } else {
            expandNode(node.path);
        }
    }, [focusedNode, focusNode, expandNode, expandedNodes, nodes]);

    const stepOut = useCallback(() => {
        const isExpanded = expandedNodes.includes(focusedNode);
        if (isExpanded) {
            collapseNode(focusedNode);
        } else {
            const parent = findParent(nodes, focusedNode);
            if (!parent) return;

            focusNode(parent.path);
        }
    }, [collapseNode, focusedNode, focusNode, expandedNodes, nodes]);

    const activate = useCallback(
        (nodePath: string) => {
            const node = findNodeByPath(nodes, nodePath);
            if (!node) return;

            const hasChildren = node.children.length;
            const isExpanded = expandedNodes.includes(nodePath);

            if (hasChildren && isExpanded) {
                collapseNode(nodePath);
            } else if (hasChildren) {
                expandNode(nodePath);
            } else if (node.id) {
                onSelectionChanged(node.id === selection ? undefined : node.id);
            }
        },
        [collapseNode, expandNode, expandedNodes, nodes, onSelectionChanged, selection]
    );

    const onKeyDown = useCallback(
        (e: KeyboardEvent<HTMLUListElement>) => {
            switch (e.code) {
                case 'ArrowDown':
                    moveToNext();
                    break;
                case 'ArrowLeft':
                    stepOut();
                    break;
                case 'ArrowRight':
                    stepIn();
                    break;
                case 'ArrowUp':
                    moveToPrevious();
                    break;
                case 'End':
                    moveToLast();
                    break;
                case 'Enter':
                case 'Space':
                    activate?.(focusedNode);
                    break;
                case 'Home':
                    moveToFirst();
                    break;
                default:
                    return;
            }

            e.preventDefault();
        },
        [
            activate,
            focusedNode,
            moveToFirst,
            moveToLast,
            moveToNext,
            moveToPrevious,
            stepIn,
            stepOut,
        ]
    );
    return {
        collapseNode,
        expandedNodes,
        expandedNodesInitialized,
        focusedNode,
        focusNode,
        onKeyDown,
        setExpandedNodes,
        setExpandedNodesInitialized,
        setFocusedNode,
        activate,
        validateExpandedNodes
    };
};
