import React, { ReactNode } from 'react';
import { StyledModule } from './styled';
import MaxWidth from '$shared/components/max-width';
// import { ModuleSpacing } from '~/lib/data-contract';

export type ModuleContainerProps = {
    // spacingTop?: ModuleSpacing['spacingTop'];
    // spacingBottom?: ModuleSpacing['spacingBottom']; TODO: BE plz

    /**
     * Prevents capping the width to the max content widht
     */
    fullWidth?: boolean;

    /**
     * Prevents capping the width to the max content widht
     */
    noSpacing?: boolean;

    /**
     * Add side gutters to the module. Usefull if the content
     * is not intended to go full width on mobile. eg. text modules
     */
    hasGutter?: boolean;
    children?: ReactNode;
    noOverflow?: boolean;
    id?: string;
};

export const ModuleContainer = ({ fullWidth, children, ...rest }: ModuleContainerProps) => {
    return (
        <StyledModule {...rest}>
            {fullWidth ? children : <MaxWidth children={children} />}
        </StyledModule>
    );
};
