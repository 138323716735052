import {
    buttonContentStyle,
    boxedButtonStyle,
    buttonStyle,
    variantPrimary,
} from '$shared/components/button';
import { checkboxElementStyle } from '$shared/components/form/components/controllable-elements/checkbox-element';
import { inputStyle } from '$shared/components/form/components/controllable-elements/input-element/styled';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';

export const StyledApsisForm = styled.div({
    label: {
        display: 'block',
    },

    input: {
        '&[type="email"], &[type="text"]': {
            ...inputStyle,
        },

        '&[type="checkbox"]': {
            ...checkboxElementStyle,

            '&:hover': {
                background: theme.colors.grey40,
            },

            '&:checked': {
                background: theme.colors.primary,

                '&:before, &:after': {
                    display: 'unset',
                },
            },

            '&:before, &:after': {
                content: '""',
                display: 'none',
                position: 'absolute',
                top: '50%',
                left: '50%',
                height: 2,
                background: 'white',
            },

            '&:before': {
                width: 6,
                transform: 'rotate(45deg) translate(-4px, 4px)',
            },
            '&:after': {
                width: 10,
                transform: 'rotate(-45deg) translate(-1px, -3px)',
            },
        },

        '&[type="button"]': {
            appearance: 'none',
            ...buttonStyle,
            ...variantPrimary,
            ...boxedButtonStyle,
            ...buttonContentStyle,
            fontWeight: theme.fontWeights.bold,
            fontSize: theme.fontSizes.md,
        },
    },

    h1: {
        // Main header
        '&:nth-child(1)': {
            lineHeight: theme.lineHeights.headline,
            fontFamily: theme.fonts.headline,
            fontWeight: theme.fontWeights.bold,
            textTransform: 'uppercase',
            wordBreak: 'break-word',
            marginBottom: theme.space[3],

            fontSize: theme.fontSizes['lg'],
            [breakpoints.md]: {
                fontSize: theme.fontSizes['xl'],
            },
        },
        // Sub header
        '&:nth-child(2)': {
            fontSize: theme.fontSizes['md'],
            color: theme.colors.grey80,
            marginBottom: theme.space[3],
        },
    },

    '.wrapper_for_form_fields': {
        '> label': {
            width: '100%',
            marginBottom: theme.space[2],
        },
    },

    '.wrapper_for_the_input_field': {
        [breakpoints.xs]: {
            label: {
                display: 'inline-block',
                width: 'calc(100% - 40px)',
                marginTop: '3px',
                marginLeft: ' 5px',
                verticalAlign: 'top',
            },
        },
        [breakpoints.md]: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',

            label: {
                marginLeft: theme.space[1],
                marginRight: theme.space[3],
            },
        },
        [breakpoints.lg]: {
            label: {
                marginLeft: theme.space[2],
                marginRight: theme.space[4],
            },
        },
    },

    '.al-input-field': {
        marginBottom: theme.space[3],
        '&:first-of-type': {
            label: {
                marginBottom: theme.space[2],
            },
        },
    },

    '.al-form-terms': {
        color: theme.colors.grey80,
        marginTop: theme.space[3],

        a: {
            color: theme.colors.blue,
        },
    },
});
