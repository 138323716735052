import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import {
    StyledOrderDetailsText,
    StyledOrderDetailsTitle,
} from '$features/checkout/components/steps/shared/order-details/style';

export const StyledReceiptWrapper = styled.div({
    display: 'grid',
    gap: theme.space[5],
    grid: '1fr / 1fr',
    marginTop: theme.space[4.5],

    [breakpoints.md]: {
        grid: 'auto-flow / 1fr 1fr',
    },
});

export const StyledReceiptButton = styled.div({
    marginTop: theme.space[4],
});

export const StyledReceiptBasketSummary = styled.div({
    marginTop: theme.space[4.5],
});

export const StyledReceiptBlockWrapper = styled.div({
    marginTop: theme.space[4.5],
    paddingBottom: theme.space[6],
});

export const StyledReceiptTextBlockWrapper = styled.div({
    borderTop: `1px solid ${theme.colors.grey40}`,
    paddingTop: theme.space[6],
    rowGap: theme.space[3],
});

export const StyledReceiptSeperator = styled.div({
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.grey40,
    marginBlock: theme.space[5],
});

export const StyledReceiptHeadline = styled(StyledOrderDetailsTitle)();
export const StyledReceiptTextBox = styled(StyledOrderDetailsText)();
