import { ButtonResetStyles } from '$shared/components/button';
import Link from '$shared/components/link';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

const StyledAnchor = styled.a({
    ...ButtonResetStyles,
});

export const StyledItem = styled(StyledAnchor)(({ theme }) => ({
    fontSize: theme.fontSizes.sm,
    textAlign: 'left',
    paddingTop: theme.space[4],
    paddingBottom: theme.space[4],
    width: '100%',
    boxSizing: 'border-box',
    // display: 'grid',
    // gridTemplateColumns: 'minmax(0, 1fr) auto',
    justifyContent: 'space-between',
    minHeight: '75px',
}));

export const StyledContent = styled.div<{ truncate?: boolean }>(
    {
        lineHeight: 1.2,
    },
    ifProp('truncate', () => ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }))
);

export const StyledSuffix = styled.div({
    textAlign: 'right',
    color: 'lightgrey',
    paddingLeft: 15,
    lineHeight: '30px',
    height: 25,
});

export const StyledLink = styled(Link)({
    color: 'inherit',
});
