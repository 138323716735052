import MaxWidth from '$shared/components/max-width';
import styled from '@emotion/styled';
import Button from '$shared/components/button';
import Headline from '$shared/components/headline';
import { breakpoints, theme } from '$theme';

export const StyledCheckoutMainArea = styled(MaxWidth)(() => ({}));

export const StyledCheckoutBlockSpacing = styled.div({
    marginBottom: theme.space[7],
});

export const StyledCheckoutHeadline = styled(Headline)({
    color: theme.colors.black,
    marginBottom: theme.space[4],
});

export const StyledCheckoutButton = styled(Button)({
    width: '100%',
});

export const StyledCheckoutTwoColWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'flex-start',
    gap: theme.space['5'],

    [breakpoints.md]: {
        gridTemplateColumns: '1fr clamp(300px, 25vw, 465px)',
        gap: '10%',
    },
});

export const StyledOrderReferenceWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    gap: theme.space['2'],

    [breakpoints.md]: {
        gridTemplateColumns: '1fr 1fr',
    },
});

export const StyledOrderReferenceText = styled.div({
    marginBottom: theme.space[4],
});
