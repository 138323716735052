import React from 'react';
import { PriceAndStockViewModel, IProduct } from '~/lib/data-contract';

type ProductProviderProps = Partial<
    IProduct &
        PriceAndStockViewModel & {
            isFetchingPriceAndStockCount: boolean;
        }
>;

export const ProductContext = React.createContext<ProductProviderProps>({});

export const ProductProvider = ({
    children,
    ...otherProps
}: React.PropsWithChildren<ProductProviderProps>) => (
    <ProductContext.Provider
        value={{
            ...otherProps,
        }}
    >
        {children}
    </ProductContext.Provider>
);
