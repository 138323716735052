import { breakpoints } from '$theme';
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledMaxWidth = styled.div(({ theme }) => ({
    width: '100%',
    maxWidth: theme.sizes.contentMaxWidth,
    padding: `0 ${theme.space.gutter}`,
    margin: `0 auto`,

    [breakpoints.xl]: {
        padding: 0,
    },
}));

export const maxWidthStyles = (theme: Theme) => ({
    width: '100%',
    maxWidth: theme.sizes.contentMaxWidth,
    padding: `0 ${theme.space.gutter}`,
    margin: `0 auto`,

    [breakpoints.xl]: {
        padding: 0,
    },
});
