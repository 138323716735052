import { useRef, useEffect, useLayoutEffect } from "react";

export const useUpdateEffect: typeof useEffect = (effect, deps) => {
    const firstRender = useRef<boolean>(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        return effect();
    }, deps);
};

export const useUpdateLayoutEffect: typeof useEffect = (effect, deps) => {
    const firstRender = useRef<boolean>(true);

    useLayoutEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        return effect();
    }, deps);
};
