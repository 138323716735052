import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { Link } from '$shared/components/link';
import { StyledHeadline } from '../headline';

export const StyledContentListWrapper = styled.div({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [breakpoints.xs]: {
        display: 'block',
    },
});
export const StyledContentList = styled.div({
    width: '100%',
});
export const StyledRow = styled(Link)({
    display: 'flex',
    padding: `${theme.space[5]} 0px`,

    textDecoration: 'none',
    ':first-of-type': {
        paddingTop: theme.space[2],
        marginTop: 0,
        [breakpoints.sm]: {
            marginTop: 0,
            paddingTop: 0,
        },
    },

    ':not(:last-of-type)': {
        borderBottom: 'solid 1px ' + theme.colors.grey30,
    },

    ':hover': {
        textDecoration: 'none',
    },
});
export const StyledImageColumn = styled.div({
    width: '100%',
    maxWidth: '475px',
    marginRight: theme.space[5],
});

export const StyledDetailsColumn = styled.div<{ hasMedia?: boolean }>(({ hasMedia }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: hasMedia ? '950px' : 'none',
    [`${StyledHeadline}    `]: {
        marginBottom: theme.space[4],
    },
}));
