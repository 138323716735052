import Headline from '$shared/components/headline';
import Text, { StyledText } from '$shared/components/text';
import styled from '@emotion/styled';
import React from 'react';

type GridItemHeaderProps = {
    headline?: string;
    subHeadline?: string;
};

export type GridItemProps = GridItemHeaderProps & {
    area?: string;
    children?: React.ReactNode;
};

export const StyledGridItem = styled.div<GridItemProps>(({ theme, area }) => ({
    gridArea: area,
    display: 'grid',
    gap: theme.space[3],
    gridAutoRows: 'max-content',
}));

export const StyledGridItemHeader = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.space[2],
    [`${StyledText}`]: {
        color: theme.colors.grey80,
    },
}));

const GridItemHeader = ({ headline, subHeadline }: GridItemHeaderProps) => (
    <StyledGridItemHeader>
        {headline ? <Headline size={4} children={headline} /> : null}
        {subHeadline ? <Text children={subHeadline} /> : null}
    </StyledGridItemHeader>
);

export const GridItem = ({ headline, subHeadline, area, children }: GridItemProps) => (
    <StyledGridItem area={area}>
        {headline || subHeadline ? (
            <GridItemHeader headline={headline} subHeadline={subHeadline} />
        ) : null}
        {children}
    </StyledGridItem>
);

export default GridItem;
