import { useTranslation } from '$shared/utils';
import React, { FC } from 'react';
import { GenericHeader } from './generic-list-headers';
import {
    StyledOrderHeaderGrid,
    StyledOrderIconHeader,
    StyledOrderNumberHeader,
    StyledOrderContactHeader,
    StyledOrderDateHeader,
    StyledOrderStatusHeader,
    StyledOrderTrackAndTraceHeader,
} from './style';

type Props = {
    agreementsContext?: boolean;
};
export const OrderHeaders: FC<Props> = ({ agreementsContext }) => {
    const { translate } = useTranslation();
    return (
        <StyledOrderHeaderGrid>
            <StyledOrderIconHeader />
            <StyledOrderNumberHeader>
                <GenericHeader
                    text={translate(
                        `my-pages.${agreementsContext ? 'agreements' : 'orders'}.order-number`
                    )}
                />
            </StyledOrderNumberHeader>

            <StyledOrderContactHeader>
                <GenericHeader
                    text={translate(
                        `my-pages.${agreementsContext ? 'agreements' : 'orders'}.contact`
                    )}
                />
            </StyledOrderContactHeader>

            <StyledOrderDateHeader>
                <GenericHeader
                    text={translate(`my-pages.${agreementsContext ? 'agreements' : 'orders'}.date`)}
                />
            </StyledOrderDateHeader>

            <StyledOrderStatusHeader>
                <GenericHeader
                    text={translate(
                        `my-pages.${agreementsContext ? 'agreements' : 'orders'}.status`
                    )}
                />
            </StyledOrderStatusHeader>
            <StyledOrderTrackAndTraceHeader>
                <GenericHeader
                    text={translate(
                        `my-pages.${agreementsContext ? 'agreements' : 'orders'}.track-order`
                    )}
                />
            </StyledOrderTrackAndTraceHeader>
        </StyledOrderHeaderGrid>
    );
};
