import { Selector, Option } from '$shared/components/form';
import React, { FC } from 'react';

import { RegisterOptions } from 'react-hook-form';
import { ISelectionFormElement, ISelectOption } from '~/lib/data-contract';

export type SelectorEpiFieldProps = ISelectionFormElement & {
    rules?: RegisterOptions;
};

export type ISelectorOptionsAdapter<TProduct> = (option: TProduct) => Option<TProduct>;

const epiOptionsAdapter: ISelectorOptionsAdapter<ISelectOption> = ({
    text,
    value = '',
    checked,
}) => ({
    label: text,
    value,
    checked,
});

export const SelectorEpiField: FC<SelectorEpiFieldProps> = ({
    name = '',
    rules,
    options,
    tooltipText,
    label,
    placeholder,
    allowMultiSelect,
}) => {
    return (
        <Selector
            allowMultiSelect={allowMultiSelect}
            helpText={tooltipText}
            label={label}
            name={name}
            options={options?.map(epiOptionsAdapter)}
            placeholder={placeholder}
            rules={rules}
        />
    );
};

export default SelectorEpiField;
