import { IGtmDataLayer, IGtmTrackingWindow } from './models/gtm-tracking';

let preloadBuffer: any[] = [];
/**
 * Wraps basic push function gtm.js. Use this instead of accessing window.dataLayer directly.
 * If the datalayer is not loaded yet, we push it to a buffer that can be flushed when gtm is loaded.
 *
 * @param: {object} gtmDataLayerObj - Standard GTM dataLayer object format.
 */
export function push(gtmDataLayerObj: IGtmDataLayer) {
    if (typeof window === 'undefined') {
        return;
    }

    const trackingWindow = window as IGtmTrackingWindow;
    if (trackingWindow.dataLayer) {
        trackingWindow.dataLayer.push?.(gtmDataLayerObj);
    } else {
        // dataLayer script has not been loaded yet
        // add it to the queue and when flushPreloadBuffer is called, we
        preloadBuffer.push(gtmDataLayerObj);
    }

    // Ensure callback fires even if tracking is disabled or blocked.
    if (typeof gtmDataLayerObj.eventCallback === 'function' && !trackingWindow.google_tag_manager) {
        gtmDataLayerObj.eventCallback();
    }
}

/**
 * Push all events to the datalayer that have been pushed before the
 * datalayer was available.
 *
 */
export function flushPreloadBuffer() {
    const trackingWindow = window as IGtmTrackingWindow;
    if (typeof trackingWindow.dataLayer === 'object') {
        preloadBuffer.forEach((b) => trackingWindow.dataLayer?.push(b));
        preloadBuffer = [];
    } else {
        throw new Error('Datalayer should have been initialized before flushing');
    }
}

/**
 * Pushes the start event and sets up additional global events.
 */
export function initGtm() {
    push({
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
    });
}

/** Used to track page views that don't cause a reload.
 * Ideal for ajax form submits that don't reaload the page.
 * @param: {string} path - the relative path to track
 */
export function trackVirtualPageview(path?: string, callback?: () => void) {
    push({
        event: 'virtualPageview',
        virtualPageUrl: path,
        eventCallback: callback,
    });
}
