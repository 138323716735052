import { Option, Selector } from '$shared/components/form';
import { useRawHtml } from '$shared/components/raw-html';
import { StyledSelectorTab, StyledSelectorTabSelection } from './selector-tab-styles';

interface SelectorTabProps<T> {
    fieldName: string;
    options: Option<T>[];
    selectionSummary: string | undefined;
}

export const SelectorTab = <T,>(props: SelectorTabProps<T>) => {
    return (
        <StyledSelectorTab>
            <Selector name={props.fieldName} options={props.options} />
            {props.selectionSummary && (
                <StyledSelectorTabSelection children={useRawHtml(props.selectionSummary)} />
            )}
        </StyledSelectorTab>
    );
};
