import { usePage } from './../../templates/pages/hooks/usePage';
import { useCallback } from 'react';

export const prettyDate = (dateString?: Date | string, culture?: string) => {
    if (!dateString) return;
    const dateParts = dateString.toString().split('T')[0].split('-');
    const date = new Date(Date.UTC(+dateParts[0], +dateParts[1] - 1, +dateParts[2]));
    return date.toLocaleDateString(culture, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
    });
};

export const usePrettyDate = (cultureInfo?: string) => {
    const { culture: defaultCulture } = usePage();

    const prettifyDate = useCallback(
        (dateString: Date | string) => {
            const dateParts = dateString.toString().split('T')[0].split('-');
            const date = new Date(Date.UTC(+dateParts[0], +dateParts[1] - 1, +dateParts[2]));
            return date.toLocaleDateString(cultureInfo || defaultCulture, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: 'UTC',
            });
        },
        [cultureInfo, defaultCulture]
    );

    return prettifyDate;
};
