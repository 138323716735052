import { useUserStore } from '$features/authentication/use-user-store';
import Headline from '$shared/components/headline';
import { Icon } from '$shared/components/icon';
import { useFrame, useTranslation } from '$shared/utils';
import { breakpointSizes } from '$theme';
import React, { useMemo } from 'react';
import { useMedia } from 'react-use';
import { IP210MyFrontPage } from '~/lib/data-contract';
import {
    StyledLandingPageContact,
    StyledLandingPageWrapper,
    StyledProfileAboutData,
    StyledProfileAboutText,
    StyledProfileAboutDataWrapper,
    StyledNavigation,
    MyPageNavLink,
    MyPageSignOutBtn,
} from './style';
import { getMyPagesTitle } from '$shared/utils/my-pages.helper';

export const P210MyFrontPage = ({ metaTitle, pageElements }: IP210MyFrontPage) => {
    const { header } = useFrame();
    const { user } = useUserStore();
    const isDesktop = useMedia(`(min-width: ${breakpointSizes.frame}px)`, false);
    const { translate } = useTranslation();

    const pageTitle = useMemo(() => {
        const titleOverwrite = getMyPagesTitle(pageElements);
        return titleOverwrite || metaTitle;
    }, [pageElements, metaTitle]);

    return (
        <StyledLandingPageWrapper>
            <Headline size={2}>{pageTitle}</Headline>
            <StyledProfileAboutText>{translate('my-pages.landing-text')}</StyledProfileAboutText>
            {!isDesktop && (
                <StyledNavigation>
                    {header?.myPages?.slice(1).map((myPageLink) => (
                        <MyPageNavLink
                            key={myPageLink.id}
                            href={myPageLink.url}
                            target={myPageLink.target}
                            title={myPageLink.title}
                        >
                            {myPageLink.text}
                            {myPageLink.url?.startsWith('https://') ? (
                                <Icon icon="externalLink" color="grey90" size={24} />
                            ) : (
                                <Icon icon="chevronRight" color="blue80" />
                            )}
                        </MyPageNavLink>
                    ))}
                    <MyPageSignOutBtn>{translate('authentication.sign-out')}</MyPageSignOutBtn>
                </StyledNavigation>
            )}
            <StyledProfileAboutDataWrapper>
                <StyledProfileAboutData>{user?.companyName}</StyledProfileAboutData>
                <StyledProfileAboutData>{user?.name}</StyledProfileAboutData>
                <StyledProfileAboutData>{user?.email}</StyledProfileAboutData>
                <StyledLandingPageContact>
                    {translate('my-pages.contact-text')}
                </StyledLandingPageContact>
                {/* TODO: Companyname and or Phonenumber is not present on the user object. It needs to be implemented. related to https://jira.impact.dk/browse/KYOC-472 */}
            </StyledProfileAboutDataWrapper>
        </StyledLandingPageWrapper>
    );
};
