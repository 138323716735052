import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { StyledNavButton } from '$shared/components/button';
import { StyledFade } from '../scroll-fade';
import { StyledMaxWidth } from '../max-width';
import { StyledModule } from '$templates/blocks/module-container';
import { StyledLink } from '../link/style';
import { pulse } from '$shared/utils';

export const StyledSubmenu = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderBottom: 'solid 1px ' + theme.colors.grey40,
    marginBottom: theme.space[3],
    paddingBottom: '0',

    [breakpoints.sm]: {
        marginBottom: theme.global.moduleBottomSpacing,
    },

    [`${StyledLink}`]: {
        display: 'none',
        marginLeft: theme.space[4],

        [breakpoints.sm]: {
            display: 'inline-flex',
            margin: theme.space[3],
            marginRight: 0,
        },
    },

    [`${StyledFade}`]: {
        // Fading elements
        '&:before, &:after': {
            bottom: '5px',
        },
    },
});

export const StyledTabButton = styled(StyledNavButton)<{ isBusy: boolean; isCompact: boolean }>(
    ({ isBusy, isCompact, theme }) => ({
        color: isBusy ? 'transparent' : undefined,
        marginRight: isCompact ? theme.space[4] : undefined,
        paddingBottom: isCompact ? theme.space[3] : undefined,
        paddingTop: isCompact ? theme.space[3] : undefined,
        position: 'relative',

        [breakpoints.sm]: {
            paddingBottom: isCompact ? theme.space[3] : undefined,
            paddingTop: isCompact ? 0 : undefined,
        },

        '::after': {
            background: theme.colors.grey40,
            borderRadius: 4,
            content: isBusy ? '""' : undefined,
            height: '1.2em',
            inset: 'calc(50% - .6em) 0 0',
            position: 'absolute',
            ...pulse,
        },
    })
);

export const StyledFadeWrapper = styled.div({
    overflow: 'hidden',
});

export const StyledDeviceButton = styled.div(({ theme }) => ({
    [breakpoints.sm]: {
        display: 'none',
    },

    [`${StyledLink}`]: {
        marginBottom: theme.space[3],
    },
}));

export const StyledTabMenu = styled.div({
    [`${StyledMaxWidth}`]: {
        padding: 0,
    },

    [`${StyledModule}`]: {
        ':last-child': {
            paddingBottom: 0,
        },
    },
});
