import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import Link from '../link';

interface BasketSummaryHeaderLinkProps {
    children: ReactNode;
    className?: string;
    href: string;
}

const StyledBasketSummaryHeaderLink = styled(Link)(({ theme }) => ({
    color: theme.colors.blue90,
    fontSize: theme.fontSizes.xs,

    '&:hover': {
        color: theme.colors.blue100,
    },
}));

export const BasketSummaryHeaderLink: FC<BasketSummaryHeaderLinkProps> = ({
    href,
    ...passProps
}) => {
    return <StyledBasketSummaryHeaderLink href={href} {...passProps} />;
};
