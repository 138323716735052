import Headline from '$shared/components/headline';
import { Link } from '$shared/components/link';
import { useRawHtmlWithNewLinesAsBreaks } from '$shared/components/raw-html';
import SpotContent, { SpotContentProps } from '$shared/components/spot-content';
import SpotMedia, { SpotMediaProps } from '$shared/components/spot-media';
import { getTextColor, usePotentialThemeColor } from '$theme';
import { useMemo } from 'react';
import { IResponsiveMedia, VerticalAlignment } from '~/lib/data-contract';
import { ModuleContainer } from '../module-container';
import {
    StyledDesktopMediaWrapper,
    StyledM10,
    StyledM10Content,
    StyledMediaWrapper,
    StyledMobileMediaWrapper,
} from './style';

export interface M10Props {
    /**
     * Hero headline
     */
    headline?: string;
    hideHeadlineOnMobile?: boolean;

    /**
     * Define the headline HTML tag. Useful for SEO
     */
    headlineTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';
    headlineStyleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

    /**
     * The tagline below the headline
     */
    subHeadline?: string;
    hideSubHeadlineOnMobile?: boolean;

    /**
     * Define the headline HTML tag. Useful for SEO
     */
    subHeadlineTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

    subHeadlineStyleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

    /**
     * The text for the button. If this is left empty
     * the button will not show
     */
    actionText?: string;

    /**
     * The url for the button
     */
    actionUrl?: string;

    /**
     * The url for the button
     */
    actionTarget?: string;

    /**
     * This is disabled for mobile
     */
    height?: 'normal' | 'low';

    /**
     * Background color for the container and content.
     * This will be the background color for the content area when
     * "mediaSize" is "split".
     */
    backgroundColor?: string;

    /**
     * Text color for the content.
     * If not provided it will be calculated based on
     * the background color
     */
    textColor?: string;

    /**
     * Either media is behind og beside the content
     */
    mediaSize?: 'full' | 'split';

    /**
     * Horizontally align content
     */
    contentAlignment?: SpotContentProps['alignment'];

    /**
     * Vertically align content
     */
    contentPosition?: SpotContentProps['position'];

    /**
     * Indicated if there is media and what type of media
     */
    mediaType?: SpotMediaProps['type'];

    /**
     * Source of media. Supports YouTube, Vimeo and selfhosted
     */
    media?: IResponsiveMedia;

    /**
     * Horizontally align content
     */
    mediaAlignment?: 'left' | 'right';

    /**
     * Used while loading video script and building initial buffer.
     * Required for selfhosted videos. For external videos this is optional
     */
    videoPosterSrc?: SpotMediaProps['videoPosterSrc'];

    id?: string;
}

export const M10Hero = ({
    contentAlignment = 'left',
    contentPosition = VerticalAlignment.center,
    height = 'normal',
    mediaSize = 'full',
    backgroundColor = 'none',
    mediaAlignment = 'right',
    headlineTag = 'h1',
    hideHeadlineOnMobile,
    headlineStyleAs = 'h1',
    subHeadlineTag = 'p',
    subHeadlineStyleAs = 'p',
    hideSubHeadlineOnMobile,
    textColor,
    headline,
    subHeadline,
    actionText,
    actionUrl,
    actionTarget,
    media,
    id,
}: M10Props) => {
    const backgroundColorValue = usePotentialThemeColor(backgroundColor);
    const textColorValue = useMemo(() => textColor || getTextColor(backgroundColorValue), [
        backgroundColorValue,
        textColor,
    ]);

    const { video, desktop, mobile, caption } = media || {};
    const mobileImage = mobile || desktop;
    const desktopImage = desktop || mobile;
    const mediaType = video ? 'video' : 'image';

    return (
        <ModuleContainer fullWidth id={id}>
            <StyledM10 backgroundColor={backgroundColorValue} height={height} mediaSize={mediaSize}>
                <StyledM10Content
                    backgroundColor={backgroundColorValue}
                    mediaSize={mediaSize}
                    contentAlignment={contentAlignment}
                    mediaAlignment={mediaAlignment}
                    height={height}
                >
                    <StyledMediaWrapper
                        mediaSize={mediaSize}
                        style={{ backgroundColor: backgroundColorValue }}
                    >
                        <StyledMobileMediaWrapper>
                            <SpotMedia
                                src={(video || mobileImage)?.src}
                                type={mediaType}
                                alt={(mobileImage as any)?.alt} // Property missing on the models from BE...
                                title={caption}
                                controls={false}
                                cover={true}
                                repositionKey={mediaSize + height}
                                videoLoop={video?.loop}
                                videoAutoPlay={video?.autoPlay}
                                videoPosterSrc={mobileImage?.src}
                            />
                        </StyledMobileMediaWrapper>
                        <StyledDesktopMediaWrapper>
                            <SpotMedia
                                src={(video || desktopImage)?.src}
                                type={mediaType}
                                alt={(desktopImage as any)?.alt} // Property missing on the models from BE...
                                title={caption}
                                controls={false}
                                cover={true}
                                repositionKey={mediaSize + height}
                                videoLoop={video?.loop}
                                videoAutoPlay={video?.autoPlay}
                                videoPosterSrc={desktopImage?.src}
                            />
                        </StyledDesktopMediaWrapper>
                    </StyledMediaWrapper>
                    <SpotContent
                        alignment={contentAlignment}
                        position={contentPosition}
                        textColor={textColorValue}
                        mediaType={mediaType}
                    >
                        {headline ? (
                            <Headline
                                children={useRawHtmlWithNewLinesAsBreaks(headline)}
                                as={headlineTag}
                                size={headlineStyleAs}
                                hideOnMobile={hideHeadlineOnMobile}
                            />
                        ) : null}
                        {subHeadline ? (
                            <Headline
                                children={useRawHtmlWithNewLinesAsBreaks(subHeadline)}
                                as={subHeadlineTag}
                                size={subHeadlineStyleAs}
                                hideOnMobile={hideSubHeadlineOnMobile}
                            />
                        ) : null}
                        {actionText ? (
                            <Link
                                variant="primary"
                                href={actionUrl}
                                target={actionTarget}
                                children={actionText}
                            />
                        ) : null}
                    </SpotContent>
                </StyledM10Content>
            </StyledM10>
        </ModuleContainer>
    );
};
