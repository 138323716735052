export const FILTER_URL = '/api/filter/proxy';
export const FILTER_PRODUCTS = `${FILTER_URL}/ProductSearch/GetSearchProducts`;
export const FILTER_REQUEST_FACET_PARAM = 'userFilters';
export const FILTER_REQUEST_HIERARCHY_PARAM = 'pageFilters';
export const FILTER_DEBOUCE = 300;
export const FILTER_PAGE_SIZE = 20;
export const FILTER_PAGE_KEY = 'page';
export const FILTER_SEARCH_TEXT_KEY = 'search-text';
export const FILTER_INTELLIGENT_KEY = 'filters';
export const FILTER_FAVORITES_OPTION_KEY = 'favorite-products';
export const FILTER_CUSTOMERS_PURCHASES_OPTION_KEY = 'pre-purchases';
export const FILTER_PROMOTED_PRODUCTS_OPTION_KEY = 'promoted-products';
export const FILTER_SHOW_ONLY_IN_STOCK_KEY = 'in-stock';
export const FILTER_SHOW_ONLY_IN_STOCK_OPTION_KEY = 'true';
export const FILTER_SHOW_ONLY_IN_STOCK_MAX = 100;
