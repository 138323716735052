import React, { useEffect } from 'react';
import { FC } from 'react';
import { ILink, IN01MainNavigationWithLeftItemsPage } from '~/lib/data-contract';

import {
    StyledHeadline,
    StyledIcon,
    StyledSubNavLink,
    StyledSubNavList,
    StyledSubNavListItem,
} from './style';

export type SubNavListItemProps = {
    activeMenuIndex?: number | null;
    link: ILink;
    hasChildren?: boolean;
    index: number;
    onHover?: (index: number) => void;
};

export const SubNavListItem: FC<SubNavListItemProps> = ({
    onHover,
    link,
    index,
    activeMenuIndex,
    hasChildren,
}) => {
    let timeoutID: number;
    useEffect(() => cleanup, []);
    function cleanup() {
        clearTimeout(timeoutID);
    }

    return (
        <StyledSubNavListItem
            onMouseEnter={() => {
                if (onHover) {
                    timeoutID = setTimeout(onHover, 200, index);
                }
            }}
            onMouseLeave={cleanup}
        >
            <StyledSubNavLink
                href={link?.url}
                plain={true}
                active={activeMenuIndex === index}
                cursorType="default"
            >
                <StyledHeadline size={4} noMargin>
                    {link?.title || link?.text}
                </StyledHeadline>
                {hasChildren && <StyledIcon icon={'chevronRight'} size="default" />}
            </StyledSubNavLink>
        </StyledSubNavListItem>
    );
};

export type MegaMenuLeftMenuProps = IN01MainNavigationWithLeftItemsPage & {
    activeIndex?: number;
    onSetActiveIndex?: (index: number) => void;
};

export const MegaMenuLeftMenu: FC<MegaMenuLeftMenuProps> = ({
    children,
    activeIndex,
    onSetActiveIndex,
    ...restProps
}) => (
    <StyledSubNavList {...restProps}>
        {children?.map(({ id, link, children }, index) => (
            <SubNavListItem
                key={id}
                onHover={onSetActiveIndex}
                link={link}
                hasChildren={!!children}
                activeMenuIndex={activeIndex}
                index={index}
            />
        ))}
    </StyledSubNavList>
);
export default MegaMenuLeftMenu;
