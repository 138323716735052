import React, { useState } from 'react';
import { FilterContextProps } from '..';
import { useFilterQuery } from '../service/useFilterQuery';
type stateOverwrites = ReturnType<typeof useFilterQuery>;
type FilterProviderProps = FilterContextProps & {
    setStateOverwrites?(state: stateOverwrites): void;
};

export const FilterContext = React.createContext<FilterProviderProps>({});

export const FilterProvider = ({
    children,
    ...otherProps
}: React.PropsWithChildren<FilterProviderProps>) => {
    const [overwrites, setOverwrites] = useState<stateOverwrites>();
    return (
        <FilterContext.Provider
            value={{ ...otherProps, ...overwrites, setStateOverwrites: setOverwrites }}
        >
            {children}
        </FilterContext.Provider>
    );
};
