import React, { FC } from 'react';
import { useProduct } from '$shared/utils/product';
import { productPDFSrc } from '$shared/utils';
import { Icon } from '$shared/components/icon';
import { StyledGenericList } from '$templates/pages/components/MyPages/style';
import { StyledPdpDownloadLine, StyledPdpDownloadLineText } from './style';

export const PdpDownloads: FC = () => {
    const { dataSheetPaths } = useProduct();

    return (
        <StyledGenericList>
            {dataSheetPaths?.map((sheet) => (
                <li key={sheet}>
                    <StyledPdpDownloadLine href={productPDFSrc(sheet)} target={'_blank'}>
                        <Icon icon={'download'} color="black" size={'lg'} />
                        <StyledPdpDownloadLineText>{sheet}</StyledPdpDownloadLineText>
                    </StyledPdpDownloadLine>
                </li>
            ))}
        </StyledGenericList>
    );
};
