import React, { FC } from 'react';
import Script from 'next/script';
import { useFrame } from '$shared/utils';

type GoogleTrackingProps = {
    onGtmLoad: () => void;
};

export const GoogleTrackingScripts: FC<GoogleTrackingProps> = ({
    onGtmLoad,
}) => {
    const { settings } = useFrame();
    const id = settings?.googleTagManagerId;

    if (!id) {
        return null;
    }

    return (
        <Script
            id="gtm-script"
            src={`https://www.googletagmanager.com/gtm.js?id=${id}`}
            onLoad={onGtmLoad}
        />
    );
};
