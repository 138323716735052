import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { StyledInvalidMessage } from './styled';
export type InvalidMessageProps = {
    forField?: string;
};
export const InvalidMessage: FC<InvalidMessageProps> = ({ forField }) => {
    const {
        formState,
        getFieldState,
    } = useFormContext();

    if (!forField) return null;

    const { error } = getFieldState(forField, formState);

    return error?.message ? <StyledInvalidMessage children={error?.message} /> : null;
};
