import React from 'react';
import { HeaderLogo } from './components/header-logo';
import { StyledHeader, StyledHeaderRow, StyledSearch } from './style';
import Divider from '$shared/components/divider';
import HeaderButtons from './components/header-buttons';
import MainNavigation from '$shared/components/main-navigation';
import { N35MobileMegaMenu } from '$features/navigation';

export const Header = () => {
    return (
        <StyledHeader>
            <StyledHeaderRow>
                <N35MobileMegaMenu />
                <HeaderLogo />
                <StyledSearch />
                <HeaderButtons />
            </StyledHeaderRow>
            <Divider />
            <StyledHeaderRow>
                <MainNavigation />
            </StyledHeaderRow>
            <Divider />
        </StyledHeader>
    );
};
export default Header;
