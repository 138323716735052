export const defaultTransition = {
    ease: 'easeOut',
    duration: 0.1,
};

export const delayedTransition = {
    ease: 'easeOut',
    duration: 0.1,
    delay: 0.1,
};

export const contentVariants = {
    hidden: {
        opacity: 0,
        translateY: 20,
        transition: defaultTransition,
    },
    visible: {
        opacity: 1,
        translateY: 0,
        transition: defaultTransition,
    },
};

export const onSamePathVariants = {
    hidden: {
        opacity: 0,
        transition: delayedTransition,
    },
    visible: {
        opacity: 1,
        transition: defaultTransition,
    },
};

export const spinnerVariants = {
    hidden: {
        opacity: 0,
        transition: defaultTransition,
    },
    visible: {
        opacity: 1,
        transition: delayedTransition,
    },
};
