import React, { FC, useEffect } from 'react';
import {
    StyledContent,
    StyledTextContent,
    StyledCloseButton,
    StyledNotification,
    StyledNotificationTimer,
    StyledTitle,
    StyledText,
    StyledIconWrapper,
} from './styled';
import { useTimeoutFn } from 'react-use';
import { useAnimation } from 'framer-motion';
import { bezierValues, durations } from './animations';
import { NotificationOptions, Severity } from './../hooks/useNotificationModel';
import { Icon } from '$shared/components/icon';

type Props = NotificationOptions & {
    onDismiss: () => void;
};

const variants = {
    initial: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            ease: bezierValues.entrance.productive,
            duration: durations.fast02,
        },
    },
    exit: {
        opacity: 0,
        scale: 0.9,
        transition: {
            ease: bezierValues.exit.productive,
            opacity: {
                duration: durations.moderate02,
            },
            scale: {
                duration: durations.fast02,
            },
        },
    },
};

export const Notification = ({
    text,
    headline,
    onDismiss,
    onClick,
    severity = 'error',
    duration,
    children,
}: Props) => {
    const [, cancel, restart] = useTimeoutFn(onDismiss, duration);
    const controls = useAnimation();

    const handleRestart = () => {
        restart();
        controls.start('timer');
    };

    const handleCancel = () => {
        cancel();
        controls.start({ scaleX: 1 });
    };

    const handleDismiss = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDismiss();
    };

    useEffect(() => {
        controls.start('timer');
    }, [controls]);

    useEffect(() => {
        if (!duration) {
            cancel();
        }
    }, [duration, cancel]);

    return (
        <StyledNotification
            layout
            transition={{
                layout: {
                    duration: durations.moderate01,
                    ease: bezierValues.exit.productive,
                },
            }}
            variants={variants}
            initial="initial"
            animate="visible"
            exit="exit"
            aria-live="polite"
            role="button"
            tabIndex={0}
            onClick={onClick || onDismiss}
            onFocus={handleCancel}
            onMouseEnter={handleCancel}
            onMouseLeave={duration ? handleRestart : undefined}
            onTouchStart={handleCancel}
            onTouchEnd={duration ? handleRestart : undefined}
        >
            <StyledContent>
                <StyledIconWrapper>
                    <NotificationIcon severity={severity} />
                </StyledIconWrapper>
                <StyledTextContent>
                    <StyledTitle>{headline}</StyledTitle>
                    <StyledText>{text}</StyledText>
                    {typeof children === 'function' ? children(onDismiss) : children}
                </StyledTextContent>
                <StyledCloseButton children={<Icon icon="close" />} onClick={handleDismiss} />
            </StyledContent>
            <StyledNotificationTimer
                severity={severity}
                variants={{
                    timer: {
                        scaleX: 0,
                        transition: {
                            duration: duration ? duration / 1000 : Infinity,
                            ease: 'linear',
                        },
                    },
                }}
                exit={{ transition: { duration: 0 } }}
                animate={controls}
                aria-hidden="true"
            />
        </StyledNotification>
    );
};

const NotificationIcon: FC<{ severity: Severity }> = ({ severity }) => {
    switch (severity) {
        case 'info':
            return <Icon icon="info" size="md" color="primary" />;
        case 'success':
            return <Icon icon="checkmarkThin" size="md" color="positive" />;
        case 'error':
            return <Icon icon="alert" size="md" color="negative" />;
    }
};
