import React from 'react';
import Quote, { QuoteProps } from '$shared/components/quote';
import { ModuleContainer } from '../module-container';

export type M90Props = QuoteProps & { id?: string };

export const M90 = ({ id, quote, author, contentAlignment = 'center' }: M90Props) => {
    if (!quote) {
        return null;
    }

    return (
        <ModuleContainer id={id}>
            <Quote quote={quote} author={author} contentAlignment={contentAlignment} />
        </ModuleContainer>
    );
};

export default M90;
