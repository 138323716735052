import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { AnimationDirectionType, animationVariations } from './animations';
import { MenuPage } from '../MenuPage';
import { INavigationNode, IMainNavigationNode } from '~/lib/data-contract';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import { lookupNodeById } from '../../utils/lookupNodeById';
import { lookupNodeByUrl } from '../../utils/lookupNodeByUrl';
import { lookupParentNode } from '../../utils/lookupParentNode';
import { useTranslation } from '~/shared/utils/translation';
import { useMobileMenu } from '../../hooks/useMobileMenu';
import { M21AnchorId } from '$templates/blocks/m21-grid/m21-grid-block';

const StyledWrapper = styled.div({
    position: 'relative',
    overflow: 'auto',
    flex: 1,
});

type MobileMegaMenuProps = {
    menu: INavigationNode[];
    activeNodeId?: string;
    activeNodeByUrl?: boolean;
};

type MenuState = {
    node?: INavigationNode;
    parentNode?: INavigationNode;
};

enum menuPageTypes {
    P30 = 'P30CategoryListPage',
    P35 = 'P35CategoryDetailPage',
}

export const MobileMegaMenu = ({ menu, activeNodeId, activeNodeByUrl }: MobileMegaMenuProps) => {
    const { asPath, events, push } = useKyoceraRouter();
    const { translate } = useTranslation();
    const { close } = useMobileMenu();

    useEffect(() => {
        events.on('routeChangeStart', close);
        return () => events.off('routeChangeStart', close);
    }, [events, close]);

    const [animationDirection, setAnimationDirection] = useState<AnimationDirectionType>(
        'forwards'
    );

    const [activeMenuState, setActiveMenuState] = useState<MenuState>(() => {
        let node;
        if (activeNodeId) {
            node = lookupNodeById(activeNodeId, menu);
        } else if (activeNodeByUrl) {
            node = lookupNodeByUrl(asPath, menu);
        }
        return node ? { node, parentNode: lookupParentNode(node, menu) } : {};
    });

    const onGoBackHandler = () => {
        onSelectNodeHandler(activeMenuState.parentNode);
        setAnimationDirection('backwards');
    };

    const onSelectNodeHandler = (node: IMainNavigationNode | undefined) => {
        if (node) {
            if (node.pageType == menuPageTypes.P30 || node.pageType == menuPageTypes.P35) {
                const anchor = M21AnchorId ? `#${M21AnchorId}` : '';
                const nextUrl = node.link.url + anchor;
                nextUrl && nextUrl === asPath ? close() : push(nextUrl);
                return;
            }
            const parentNode = lookupParentNode(node, menu);
            setActiveMenuState({ node, parentNode });
            setAnimationDirection('forwards');
        } else {
            // Go back to main menu
            setActiveMenuState({});
            setAnimationDirection('backwards');
        }
    };

    const { node, parentNode } = activeMenuState || {};
    const mainMenuName = translate('navigation.main-menu');

    return (
        <StyledWrapper>
            <AnimatePresence exitBeforeEnter initial={false} custom={animationDirection}>
                <motion.div
                    key={node?.id || 'main'}
                    variants={animationVariations}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    custom={animationDirection}
                    transition={{
                        type: 'tween',
                        duration: 0.15,
                        ease: [0.35, 0, 0.15, 1],
                    }}
                >
                    {!node && (
                        <MenuPage
                            topLevel
                            nodes={menu}
                            headlineText={mainMenuName}
                            onSelectNode={onSelectNodeHandler}
                            onClose={close}
                        />
                    )}

                    {node && (
                        <MenuPage
                            nodes={node.children || []}
                            headlineText={node.link?.title}
                            headlineLinkUrl={node.link?.url}
                            backText={parentNode?.link?.title || mainMenuName}
                            onGoBack={onGoBackHandler}
                            onSelectNode={onSelectNodeHandler}
                            onClose={close}
                        />
                    )}
                </motion.div>
            </AnimatePresence>
        </StyledWrapper>
    );
};
