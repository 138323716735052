import MaxWidth from '$shared/components/max-width';
import { StyledSpotContent } from '$shared/components/spot-content';
import { StyledImageMedia } from '$shared/components/spot-media';
import { StyledText } from '$shared/components/text';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { M10Props } from './m10-hero';

type StyledM10Props = Pick<M10Props, 'backgroundColor' | 'height' | 'mediaSize'>;
type StyledM10ContentProps = Pick<
    M10Props,
    'backgroundColor' | 'height' | 'mediaAlignment' | 'contentAlignment' | 'mediaSize'
>;
type StyledM10MediaWrapperProps = Pick<M10Props, 'mediaSize'>;

export const StyledMediaWrapper = styled.div<StyledM10MediaWrapperProps>({
    position: 'relative',
    height: '100%',
    width: '100%',

    [`${StyledImageMedia}`]: {
        '> *': {
            height: '100% !important', // NextImage issue
        },
    },
});

export const StyledM10 = styled.div<StyledM10Props>(
    ({ theme, backgroundColor }) => ({
        position: 'relative',
        display: 'grid',
        width: '100%',
        maxWidth: '1730px',
        backgroundColor: backgroundColor,
        padding: `${theme.space[4]} 0`,
    }),

    switchProp('height', {
        low: {
            minHeight: 200,
            [breakpoints.md]: {
                minHeight: 370,
                padding: `${theme.space[6]} 0`,
            },
        },
        normal: {
            minHeight: 400,
            [breakpoints.md]: {
                minHeight: 630,
                padding: `${theme.space[8]} 0`,
            },
        },
    }),

    switchProp('mediaSize', {
        split: () => ({
            padding: `0 0 ${theme.space[4]}`,
        }),
    })
);

export const StyledM10Content = styled(MaxWidth)<StyledM10ContentProps>(
    ({ backgroundColor }) => ({
        display: 'grid',
        gridTemplateAreas: '"content"',
        backgroundColor: backgroundColor,
        overflow: 'hidden',
        margin: '0 auto',

        [`${StyledSpotContent}`]: {
            maxWidth: '900px',
        },
        [`${StyledText}`]: {
            maxWidth: '500px',
        },
    }),

    switchProp('mediaSize', {
        full: ({ contentAlignment }) => ({
            justifyContent:
                contentAlignment === 'left'
                    ? 'start'
                    : contentAlignment === 'right'
                    ? 'end'
                    : contentAlignment,

            [`${StyledMediaWrapper}`]: {
                top: 0,
                left: 0,
                position: 'absolute',
            },
            [`${StyledSpotContent}`]: {
                width: '100%',
                margin: '0 auto',
                position: 'relative',
                zIndex: 0,
            },
        }),

        split: ({ mediaAlignment, theme }) => ({
            display: 'flex',
            flexDirection: 'column',
            [`${StyledMediaWrapper}`]: {
                position: 'relative',
                width: `calc(100% + 2 * ${theme.space[4]})`,
                height: '270px',
                top: `-${theme.space[4]}`,
                left: `-${theme.space[4]}`,
                right: `-${theme.space[4]}`,
            },
            [breakpoints.md]: {
                display: 'grid',
                gridTemplateColumns: '50%',
                gridTemplateAreas:
                    mediaAlignment === 'left' ? '"placeholder content"' : '"content placeholder"',
                [`${StyledSpotContent}`]: {
                    padding:
                        mediaAlignment === 'left'
                            ? `0 0 0 ${theme.space[4]}`
                            : `0 ${theme.space[4]} 0 0`,
                    [breakpoints.md]: {
                        padding:
                            mediaAlignment === 'left'
                                ? `0 0 0 ${theme.space['7']}`
                                : `0 ${theme.space['7']} 0 0`,
                    },
                },
                [`${StyledMediaWrapper}`]: {
                    padding: 0,
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    width: 'auto',
                    height: 'auto',
                    left: mediaAlignment === 'left' ? 0 : '50%',
                    right: mediaAlignment === 'right' ? 0 : '50%',
                },
            },
        }),
    })
);

export const StyledMobileMediaWrapper = styled.div({
    [breakpoints.xxs]: {
        display: 'none',
    },
});

export const StyledDesktopMediaWrapper = styled.div({
    display: 'none',
    [breakpoints.xxs]: {
        display: 'flex',
    },
});
