import React, { FC, useEffect, useState } from 'react';
import { useBasketUpsert } from '$shared/hooks/useBasket/useBasket';
import { formatString, useTranslation } from '$shared/utils';
import { useAddToBasketContext } from '$shared/utils/add-to-basket/useAddToBasketContext';
import Button from '../button';
import { ToolTip } from '../hover-card-tooltip/tool-tip';
import { Icon } from '../icon';
import { QuantityPicker } from '../quantity-picker';
import {
    StyledProductWrapper,
    StyledProductLineFavorites,
    StyledProductLineBasket,
    StyledAdditionalText,
} from './style';
import { FavoritesToggle } from '$features/favorites/favoritesToggle';
import { ServiceType } from '../extra-services/service-types';
import { useFetchPriceAndStock } from '$shared/hooks/use-fetch-price-and-stock';

export const ProductLineCtas: FC = () => {
    const { translate } = useTranslation();
    const {
        extraServices,
        inSizesOf: quantityStep = 1,
        isQuantityReady,
        minimumOrderSize: minimumQuantity = 1,
        quantity,
        resetBasketContext,
        sanitizedItemNumber,
        selectedServices,
        setQuantity,
        unit,
        price,
        promotedProduct,
        boxCount,
        primaryProductNumber,
    } = useAddToBasketContext();
    const { upsertBasket, isLoading, status } = useBasketUpsert();

    useEffect(() => {
        if (status === 'success') {
            resetBasketContext();
        }
    }, [status]);

    const [canFetchPrices, setCanFetchPrices] = useState(false);

    const { mappedPrices, isLoading: loadingPriceAndStock } = useFetchPriceAndStock({
        primaryProductNumber,
        sanitizedItemNumber,
        quantityValue: quantity.parsedValue,
        canFetch: canFetchPrices,
    });

    return (
        <StyledProductWrapper>
            <StyledProductLineFavorites>
                {sanitizedItemNumber && (
                    <ToolTip
                        content={translate('favorites.add-to-favorites')}
                        position="bottom"
                        arrow={true}
                    >
                        <FavoritesToggle sanitizedItemNumber={sanitizedItemNumber} />
                    </ToolTip>
                )}
            </StyledProductLineFavorites>
            <StyledProductLineBasket>
                <div onMouseOver={() => setCanFetchPrices(true)}>
                    <QuantityPicker
                        disabled={!isQuantityReady || !price}
                        min={minimumQuantity}
                        onChange={setQuantity}
                        prices={mappedPrices ?? []}
                        step={quantityStep}
                        unit={unit}
                        value={quantity.value}
                        loadingData={canFetchPrices && loadingPriceAndStock}
                    />
                </div>

                <Button
                    isLoading={isLoading}
                    disabled={
                        !quantity.isValid ||
                        quantity.parsedValue < 1 ||
                        !sanitizedItemNumber ||
                        !price
                    }
                    onClick={() => {
                        if (quantity.isValid && quantity.parsedValue > 0 && sanitizedItemNumber) {
                            upsertBasket({
                                quantity: quantity.parsedValue,
                                sanitizedItemNumber,
                                services:
                                    extraServices?.map((es) => {
                                        const serviceAsSelected = selectedServices.find(
                                            (ss) =>
                                                ss.service.sanitizedItemNumber ===
                                                es.sanitizedItemNumber
                                        );
                                        return {
                                            sanitizedItemNumber: es.sanitizedItemNumber,
                                            serviceType: (es.editable
                                                ? 'CheckboxAndString'
                                                : 'Checkbox') as ServiceType,
                                            selected: !!serviceAsSelected,
                                            serviceName: es.label,
                                            textInput: serviceAsSelected?.userText || undefined,
                                        };
                                    }) || [],
                            });
                        }
                    }}
                    variant="primary"
                >
                    <Icon icon="cart" color="white" size="md" />
                </Button>
            </StyledProductLineBasket>
            <StyledAdditionalText>
                {quantityStep > 1 && (
                    <p>
                        {formatString(
                            translate('product-detail-page.core-data.in-sizes-of'),
                            quantityStep,
                            unit
                        )}
                    </p>
                )}

                {(boxCount || 0) > 0 && (
                    <p>
                        {formatString(
                            translate('product-detail-page.core-data.in-sizes-of'),
                            boxCount,
                            translate('product-detail-page.core-data.stock-pieces')
                        )}
                    </p>
                )}

                {minimumQuantity > 1 && quantityStep <= 1 && (
                    <p>
                        {formatString(
                            translate('product-detail-page.core-data.minimum-quantity'),
                            minimumQuantity,
                            unit
                        )}
                    </p>
                )}
                {promotedProduct && <p>{translate('shared.quantity.while-stock-lasts')}</p>}
            </StyledAdditionalText>
        </StyledProductWrapper>
    );
};
