import styled from '@emotion/styled';
import React, { ComponentProps, CSSProperties, Fragment, ReactNode } from 'react';
import { switchProp } from 'styled-tools';

export type TextProps = {
    /**
     * Defines the text style
     */
    variant?: 'small' | 'mini';

    children?: React.ReactNode | string;
};

export const StyledText = styled.p<TextProps>(
    ({ theme }) => ({
        fontFamily: theme.fonts.body,
        lineHeight: theme.lineHeights.base,
        color: theme.colors.black,
        wordBreak: 'break-word',

        img: {
            maxWidth: '100%',
            height: 'auto',
        },
    }),
    switchProp('as', {
        i: {
            fontStyle: 'italic',
        },
        em: {
            fontStyle: 'italic',
        },
        strong: ({ theme }) => ({
            fontWeight: theme.fontWeights.bold,
        }),
        b: ({ theme }) => ({
            fontWeight: theme.fontWeights.bold,
        }),
    }),
    switchProp('variant', {
        small: ({ theme }) => ({
            fontSize: theme.fontSizes.xs,
        }),
        mini: ({ theme }) => ({
            fontSize: theme.fontSizes['3xs'],
        }),
    })
);

/**
 * Override rendered markup with `as` attribute. For instance you can render a h3 like a size 1:
 *
 * ```tsx
 * <Text as="span">An span in markup.</Headline>
 * ```
 */

const TextWrapper = ({ style, children }: { style?: CSSProperties; children: ReactNode }) =>
    // This wrapper component is here, because of a bug, where some elements would lose their inline style prop from the RTE - JiraID: KYO-963 (RTE buttons alignment issue)
    style?.textAlign ? (
        <span style={{ textAlign: style.textAlign }}>{children}</span>
    ) : (
        <Fragment children={children} />
    );

export const Text = React.forwardRef<HTMLParagraphElement, ComponentProps<typeof StyledText>>(
    (props, ref) => {
        return (
            <TextWrapper {...props}>
                <StyledText ref={ref} {...props} />
            </TextWrapper>
        );
    }
);

export default Text;
