import { ContentList } from '$shared/components/content-list';
import { ShowMoreButton } from '$shared/components/show-more-button';
import { formatString, useTranslation } from '$shared/utils';
import { FC, useMemo } from 'react';
import { ISearchResultItemViewModel } from '~/lib/data-contract';

interface SearchContentTabProps {
    contentResults: ISearchResultItemViewModel[];
    fetchNextPage: () => void;
    isFetchingPage: boolean;
    pageSize: number;
    totalContentResults: number;
}

export const SearchContentTab: FC<SearchContentTabProps> = (props) => {
    const { translate } = useTranslation();

    const mappedResults = useMemo(
        () =>
            props.contentResults.map((r) => ({
                headline: r.searchTitle || r.title,
                descriptionString: r.description || r.title,
                link: r.link,
            })),
        [props.contentResults]
    );

    return (
        <>
            <ContentList listItems={mappedResults} />

            {!!mappedResults.length && <ShowMoreButton
                buttonText={translate('search.search-results-page.load-more')}
                handleClick={props.fetchNextPage}
                isLoading={props.isFetchingPage}
                progressText={formatString(
                    translate('search.search-results-page.showing-x-of-x-results'),
                    props.contentResults.length,
                    props.totalContentResults
                )}
                progress={{
                    current: props.contentResults.length,
                    total: props.totalContentResults,
                    incrementSize: props.pageSize,
                }}
            />}
        </>
    );
};
