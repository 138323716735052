import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { StyledButton, StyledContent, StyledIcon } from '../button';

export const StyledCarouselWrapper = styled.div({
    overflow: 'hidden',
    position: 'relative',
});

export const StyledPagination = styled.div(({ theme }) => ({
    position: 'absolute',
    pointerEvents: 'none',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.space[2],
    top: '50%',
    left: 0,
    right: 0,
    transform: `translateY(calc(-50% - ${theme.space[3]}))`,

    [`${StyledButton}`]: {
        pointerEvents: 'all',
        backgroundColor: theme.colors.white,
        boxShadow: '1px 1px 10px -2px rgba(0, 0, 0, 0.2)',
        opacity: 0.8,
        marginInline: theme.space[1],
        padding: 0,

        [breakpoints.sm]: {
            marginInline: theme.space[2],
        },

        ':disabled': {
            opacity: 0,
        },
    },

    [`${StyledIcon}:first-of-type:last-of-type`]: {
        padding: '0.6em',

        [breakpoints.sm]: {
            padding: '1em',
        },
    },

    [`${StyledContent}`]: {
        paddingBlock: theme.space[2],
        paddingInline: theme.space[4],
    },
}));
