import { TabMenu } from '$shared/components/tab-menu';
import { StyledTabButton } from '$shared/components/tab-menu/style';
import styled from '@emotion/styled';

export const StyledTabMenu = styled(TabMenu)(({ theme }) => ({
    margin: `${theme.space[5]} -${theme.space.gutter} 0`,
    padding: `0 ${theme.space.gutter}`,

    [`${StyledTabButton}`]: {
        textTransform: 'capitalize',
        fontSize: theme.fontSizes.md,
        fontWeight: theme.fontWeights.semiBold,
        fontFamily: theme.fonts.body,
    },
}));
