import React, { FC } from 'react';
import { downloadPdf, useTranslation } from '$shared/utils';
import { DrawingLink, StyledProductLineDrawing } from './style';
import { useNotification } from '$features/notifications/hooks';
import { Icon } from '../icon';

type Props = {
    originalItemNumber: string;
};

export const ProductLineDownload: FC<Props> = ({ originalItemNumber }) => {
    const { translate } = useTranslation();
    const { push } = useNotification();

    const handleDownload = (originalItemNumber: string) => {
        const dismiss = push({
            severity: 'info',
            headline: translate('my-pages.purchases.download.notification-headline'),
            text: translate('my-pages.purchases.download.notification-text'),
            duration: 40000,
        });

        const handleError = () => {
            dismiss();
            push({
                severity: 'error',
                headline: translate('my-pages.purchases.download.notification-error-headline'),
                text: translate('my-pages.purchases.download.notification-error-text'),
            });
        };

        downloadPdf(originalItemNumber, true, dismiss, handleError);
    };

    return (
        <DrawingLink onClick={() => handleDownload(originalItemNumber)}>
            <StyledProductLineDrawing noImage>
                <Icon icon="documentPDF" color="black" size={40} />
            </StyledProductLineDrawing>
        </DrawingLink>
    );
};
