import React, { FC } from 'react';
import { FilterResult, useFilter } from '$utils/filter';
import { ShowMoreButton } from '$shared/components/show-more-button';
import { useTranslation } from '$utils/translation';
import { FILTER_PAGE_SIZE } from '$utils/filter/service/constants';
import { formatString } from '$utils/string-format.helper';
import { PriceAndStockViewModel } from '~/lib/data-contract';
import { FilterProductListControlled } from './filter-product-list-controlled';
import { StyledMoreButtonPadding } from './style';

interface FilterProductListProps {
    id: string;
}

export type ResultsWithStock = FilterResult & PriceAndStockViewModel;

export const FilterProductList: FC<FilterProductListProps> = ({ id }) => {
    const { translate } = useTranslation();

    const {
        goToNextPage,
        isLoading,
        isLoadingInitially,
        results,
        resultsCurrent,
        resultsTotal,
        userFilters,
    } = useFilter();

    const { showOnlyInStock } = userFilters || {};

    return (
        <FilterProductListControlled
            id={id}
            isLoading={isLoading}
            isLoadingInitially={isLoadingInitially}
            results={results}
            showOnlyInStock={showOnlyInStock}
        >
            {!showOnlyInStock && (
                <StyledMoreButtonPadding>
                    <ShowMoreButton
                        buttonText={translate('product-list-page.load-more-button')}
                        handleClick={goToNextPage}
                        isLoading={isLoading}
                        progress={{
                            incrementSize: FILTER_PAGE_SIZE,
                            total: resultsTotal,
                            current: resultsCurrent,
                        }}
                        progressText={formatString(
                            translate('product-list-page.show-more'),
                            resultsCurrent,
                            resultsTotal
                        )}
                    />
                </StyledMoreButtonPadding>
            )}
        </FilterProductListControlled>
    );
};
