import { useTranslation } from '$shared/utils';
import { FC, useMemo } from 'react';
import { StyledBadge } from './style';

export enum EBadgeType {
    PROMOTED_PRODUCT = 'PROMOTED_PRODUCT',
}

type BadgeProps = {
    type?: EBadgeType;
};

export const Badge: FC<BadgeProps> = ({ type = EBadgeType.PROMOTED_PRODUCT }) => {
    const { translate } = useTranslation();

    const text = useMemo(() => {
        switch (type) {
            case EBadgeType.PROMOTED_PRODUCT:
                return translate('badge.outlet');
        }
    }, [type]);

    return text ? (
        <div>
            <StyledBadge type={type}>{text}</StyledBadge>
        </div>
    ) : null;
};
