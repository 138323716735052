import { StyledText } from '$shared/components/text';
import Headline, { StyledHeadline } from '$shared/components/headline';
import Link from '$shared/components/link';
import styled from '@emotion/styled';
import React from 'react';
import { INavigationNode } from '~/lib/data-contract';
import GridItem from './grid-item';
import { breakpoints } from '$theme';
import { Accordion } from '$shared/components/accordion';
import { getTarget, useTranslation } from '$shared/utils';

type MenuAreaProps = {
    menu?: INavigationNode[] | undefined;
};

type MenuItemProps = {
    menuItem: INavigationNode;
};

const MobileMenu = styled.div(() => ({
    display: 'grid',
    [breakpoints.sm]: {
        display: 'none',
    },
}));
const DesktopMenu = styled.div(() => ({
    display: 'none',
    [breakpoints.sm]: {
        display: 'grid',
    },
}));

const StyledMenuContainer = styled.div(() => ({
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: '1fr',
    gridTemplateRow: 'min-content',
    gridAutoRows: 'max-content',
    [breakpoints.sm]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        rowGap: '2.5em',
    },
    [breakpoints.md]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [breakpoints.lg]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
    },
}));

const StyledMenuGroup = styled.div(() => ({
    display: 'block',
    gridAutoFlow: 'row',
    gridTemplateColumns: 'max-content',
    [breakpoints.xs]: {
        [`${StyledHeadline}`]: {
            pointerEvents: 'none',
        },
    },
    [breakpoints.sm]: {
        paddingRight: '2.5em',
        [`${StyledHeadline}`]: {
            marginBottom: '1em',
        },
    },
    [breakpoints.md]: {
        display: 'grid',
    },
}));

const StyledMenuLink = StyledText.withComponent(Link);
const StyledMenuItem = styled(StyledMenuLink)(({ theme }) => ({
    color: theme.colors.grey80,
    display: 'block',
    marginBottom: '1em',
}));

const MenuItem = ({ menuItem }: MenuItemProps) => {
    const { translate } = useTranslation();

    const { link, children } = menuItem;

    return (
        <StyledMenuGroup key={link.id}>
            <MobileMenu>
                <Accordion header={link.text}>
                    <StyledMenuItem
                        plain={true}
                        key={link.id}
                        href={link.url}
                        target={link.target || getTarget(link.url)}
                        title={link.title}
                        children={translate('navigation.see-all')}
                    />
                    {children?.map(({ link }) => (
                        <StyledMenuItem
                            plain={true}
                            key={`desk-${link.id}`}
                            href={link.url}
                            target={link.target || getTarget(link.url)}
                            title={link.title}
                            children={link.title || link.text}
                        />
                    ))}
                </Accordion>
            </MobileMenu>
            <DesktopMenu>
                <Headline as={Link} size={5} {...{ plain: true, href: link.url }}>
                    {link.title || link.text}
                </Headline>
                {children?.map(({ link }, index) => (
                    <StyledMenuItem
                        plain={true}
                        key={`mob-${index}`}
                        href={link.url}
                        target={link.target || getTarget(link.url)}
                        title={link.title}
                        children={link.title || link.text}
                    />
                ))}
            </DesktopMenu>
        </StyledMenuGroup>
    );
};

export const MenuArea = ({ menu }: MenuAreaProps) => (
    <GridItem area="menu">
        <StyledMenuContainer>
            {menu?.map((menuItem: MenuItemProps['menuItem']) => (
                <MenuItem key={`mi-${menuItem.id}`} menuItem={menuItem} />
            ))}
        </StyledMenuContainer>
    </GridItem>
);

export default MenuArea;
