import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';
import { Icon } from '$shared/components/icon';

export const StyledHierarchyTree = styled.ul(({ theme }) => ({
    color: theme.colors.black,
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.lineHeights.compact,
    userSelect: 'none',

    ['&:focus, &:focus-visible']: {
        outline: 'none',
    },

    [`&:focus .focused`]: {
        borderColor: theme.colors.blue90,
        outline: `2px solid ${theme.colors.blue50}`,
        outlineOffset: -2,
    },
}));

export const StyledHierarchyTreeItem = styled.li(({ theme }) => ({
    color: theme.colors.black,
}));

export const StyledHierarchyRootTreeItem = styled(StyledHierarchyTreeItem)(({ theme }) => ({
    [`&&& > ${StyledHierarchyNode} > ${StyledHierarchyLabel}`]: {
        fontWeight: theme.fontWeights.semiBold,
    },
}));

export const StyledHierarchyNode = styled.div<{ isSelected: boolean; hasChildren: boolean }>(
    ({ theme }) => ({
        position: 'relative',
        alignItems: 'center',
        cursor: 'pointer',
        display: 'grid',
        fontWeight: 400,
        gap: theme.space[2],
        grid: `auto / 1fr auto auto`,
        paddingBlock: theme.space[2],
    }),
    ifProp('isSelected', ({ theme }) => ({
        '&::before': {
            content: '""',
            position: 'absolute',
            background: theme.colors.primary,
            width: 2,
            top: 6,
            bottom: 6,
            left: -15,
        },
    })),
    ifNotProp('hasChildren', ({ theme }) => ({
        color: theme.colors.primary,
    }))
);

export const StyledHierarchyLabel = styled.div(() => ({}));

export const StyledHierarchySelection = styled.span(({ theme }) => ({
    color: theme.colors.primary,
    fontWeight: theme.fontWeights.regular,
}));

export const StyledHierarchyCount = styled.div(({ theme }) => ({
    color: theme.colors.grey80,
    fontSize: theme.fontSizes.xs,
}));

export const StyledHierarchyChevron = styled(Icon, {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ isOpen }) => ({
    fill: 'currentColor',
    transform: isOpen ? 'rotate(180deg)' : undefined,
    transition: 'transform 150ms',
}));

export const StyledHierarchyGroup = styled.ul<{ isOpen: boolean; isRootNode: boolean }>(
    ({ isOpen, isRootNode, theme }) => ({
        display: isOpen ? undefined : 'none',
        paddingInlineStart: theme.space[3],
        marginBlockEnd: isOpen && isRootNode ? theme.space[5] : 0,
        overflow: 'hidden',
    })
);
