import { ContactDto } from '$order-models/*';
import { cancellableFetch } from '../../shared/utils/request';
import { GET_CONTACTS_URL } from './order-endpoints';

const url = GET_CONTACTS_URL;
const method = 'GET';
const headers = { 'Content-Type': 'application/json' };

export function getContacts() {
    return cancellableFetch<ContactDto[]>({
        url,
        options: {
            method,
            headers,
        },
    });
}
