import { FC, ReactNode } from 'react';
import {
    ILink,
    IM85MediaAndTextItem,
    IProductMaterial,
    IResponsiveMedia,
    M21ItemStyle,
} from '~/lib/data-contract';

import { StyledMediaTextItemWrapper } from './style';
import M21GridItemContent from './variants/content/m21-grid-item-content';
import M21GridItemDefault from './variants/default/m21-grid-item-default';
import { StyledMediaTextLink } from './variants/default/style';
import M21GridItemShop from './variants/shop/m21-grid-item-shop';

export type M21GridItemProps = {
    link?: ILink;
    columnCount?: number;
    children?: ReactNode;
    itemStyle?: M21ItemStyle;
    commerceImage?: string;
    overwriteImage?: IResponsiveMedia;
    buttonText?: string;
    productMaterial?: IProductMaterial[];
} & Partial<IM85MediaAndTextItem>;

const LinkWrapper: FC<Pick<M21GridItemProps, 'link' | 'children'>> = ({ children, link }) =>
    link?.url ? (
        <StyledMediaTextLink href={link?.url} target={link?.target} title={link.title}>
            {children}
        </StyledMediaTextLink>
    ) : (
        <>{children}</>
    );

export const M21GridItem = ({ link, type, ...item }: M21GridItemProps) => {
    return (
        <StyledMediaTextItemWrapper>
            <LinkWrapper link={link}>
                {type === 'm85MediaAndTextItem' ? (
                    <M21GridItemDefault {...item} />
                ) : (
                    <>
                        {item.itemStyle === M21ItemStyle.contentLayout ? (
                            <M21GridItemContent {...item} />
                        ) : (
                            <M21GridItemShop {...item} />
                        )}
                    </>
                )}
            </LinkWrapper>
        </StyledMediaTextItemWrapper>
    );
};
export default M21GridItem;
