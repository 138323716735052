import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';

const OPTIONS = { shallow: true, scroll: false };

type UseUrlQueryState = {
    queryState?: Record<string, string[]>;
    setQueryState: (key: string, values?: string | string[]) => void;
};

const useUrlQueryState = (): UseUrlQueryState => {
    const router = useKyoceraRouter();
    const [pathname, searchQuery] = router.asPath.split('?');
    const urlSearchParameters = new URLSearchParams(searchQuery);

    const queryState = [...urlSearchParameters.entries()].reduce<{ [key: string]: string[] }>(
        (entries, [key, value]) => {
            return {
                ...entries,
                [key]: [value],
            };
        },
        {}
    );

    const setQueryState = (key: string, val?: string | string[]) => {
        const values = Array.isArray(val) ? val : val ? [val] : [];
        if (!values || values?.length < 1) {
            urlSearchParameters.delete(key);
        } else {
            values?.forEach((v) => urlSearchParameters.set(key, v));
        }
        router.replace({ pathname, query: urlSearchParameters.toString() }, undefined, OPTIONS);
    };

    return { queryState, setQueryState };
};

export default useUrlQueryState;
