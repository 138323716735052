import { theme } from '$theme';
import styled from '@emotion/styled';
import {
    variantLinkish,
    variantPrimary,
    variantPlain,
    variantSecondary,
    buttonStyle,
} from '../button/style';
import { switchProp, ifNotProp, ifProp } from 'styled-tools';
import { LinkProps } from './link';

export const StyledLink = styled.a<LinkProps>(
    {
        opacity: 1,
        '&[href]': {
            cursor: 'pointer',
        },

        '&[disabled]': {
            pointerEvents: 'none',
        },
    },
    ifProp('isLoading', {
        opacity: 0,
    }),
    ifNotProp('variant', {
        textDecoration: 'none',
        color: theme.colors.primary,

        '&:hover': {
            color: theme.colors.blue100,
        },
    }),
    switchProp('variant', {
        primary: () => ({
            ...buttonStyle,
            ...variantPrimary,
        }),
        secondary: () => ({
            ...buttonStyle,
            ...variantSecondary,
        }),
        plain: () => ({
            ...buttonStyle,
            ...variantPlain,
        }),
        linkish: () => ({
            ...buttonStyle,
            ...variantLinkish,
        }),
    })
);

export const StyledPlainLink = styled.a({
    color: theme.colors.primary,
    textDecoration: 'none',

    '&:hover': {
        color: theme.colors.blue100,
    },

    '&[href]': {
        cursor: 'pointer',
    },
});

export const StyledLinkContentWrapper = styled.div<{ isLoading?: boolean }>(({ isLoading }) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: isLoading ? 0 : 1,
}));
