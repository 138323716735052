import React from 'react';
import Headline from '$shared/components/headline';
import { getEpiButtonVariant, getTextColor, usePotentialThemeColor } from '$theme';
import { StyledActions, StyledM75, StyledM75Content } from './style';
import SpotMedia, { SpotMediaProps } from '$shared/components/spot-media';
import SpotContent, { SpotContentProps } from '$shared/components/spot-content';
import { ICallToAction, IResponsiveMedia, VerticalAlignment } from '~/lib/data-contract';
import { RawHtml, useRawHtmlWithNewLinesAsBreaks } from '$shared/components/raw-html';
import { Link } from '$shared/components/link';
import { ModuleContainer } from '../module-container';

export interface M75Props {
    /**
     * Hero headline
     */
    headline?: string;

    /**
     * Define the headline HTML tag. Useful for SEO
     */
    headlineTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';
    headlineStyleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

    headlineHidden?: boolean;
    /**
     * The tagline below the headline
     */
    textHtml?: string;

    /**
     * An array of actions
     */
    actions?: ICallToAction[];

    /**
     * Background color for the container and content.
     * This will be the background color for the content area when
     * "mediaSize" is "split".
     */
    backgroundColor?: string;

    /**
     * Text color for the content.
     * If not provided it will be calculated based on
     * the background color
     */
    textColor?: string;

    /**
     * Horizontally align content
     */
    contentAlignment?: SpotContentProps['alignment'];

    /**
     * Vertically align content
     */
    contentPosition?: SpotContentProps['position'];

    /**
     * Source of media. Supports YouTube, Vimeo and selfhosted
     */
    media?: IResponsiveMedia;

    /**
     * Horizontally align content
     */
    mediaAlignment?: 'left' | 'right';

    /**
     * Media size for desktop
     */
    mediaSize?: 'default' | 'small';

    /**
     * doNotCrop image
     */
    doNotCrop?: boolean;

    /**
     * Used while loading video script and building initial buffer.
     * Required for selfhosted videos. For external videos this is optional
     */
    videoPosterSrc?: SpotMediaProps['videoPosterSrc'];

    /**
     * Should the video autoplay
     */
    videoAutoPlay?: boolean;

    /**
     * Display iFrame instead of media
     */
    iFrameMedia?: JSX.Element;

    id?: string;
}

export const M75 = ({
    contentAlignment = 'left',
    contentPosition = VerticalAlignment.center,
    backgroundColor = 'none',
    headlineTag = 'h2',
    headlineStyleAs = 'h2',
    headlineHidden,
    actions = [],
    headline,
    textHtml,
    media,
    mediaSize = 'default',
    mediaAlignment = 'left',
    videoPosterSrc,
    videoAutoPlay,
    textColor,
    doNotCrop,
    iFrameMedia,
    id,
}: M75Props) => {
    const backgroundColorValue = usePotentialThemeColor(backgroundColor);
    const textColorValue = textColor
        ? usePotentialThemeColor(textColor)
        : getTextColor(backgroundColorValue);

    const { video, desktop, caption } = media || {};
    const image = desktop;
    const mediaType = video ? 'video' : 'image';
    const mediaItem = video || image;

    return (
        <ModuleContainer id={id}>
            <StyledM75 backgroundColor={backgroundColorValue}>
                <StyledM75Content
                    mediaSize={mediaSize}
                    mediaAlignment={mediaAlignment}
                    contentPosition={contentPosition}
                >
                    {iFrameMedia ? (
                        iFrameMedia
                    ) : (
                        <SpotMedia
                            src={mediaItem?.src}
                            type={mediaType}
                            alt={(mediaItem as any)?.alt} // Yet another property missing on the models from BE...
                            title={caption}
                            videoLoop={video?.loop}
                            videoAutoPlay={videoAutoPlay}
                            videoPosterSrc={videoPosterSrc}
                            width={doNotCrop ? mediaItem?.width : undefined}
                            height={doNotCrop ? mediaItem?.height : undefined}
                        />
                    )}
                    <SpotContent
                        alignment={contentAlignment}
                        position={contentPosition}
                        textColor={textColorValue}
                    >
                        {headline && (
                            <Headline
                                children={useRawHtmlWithNewLinesAsBreaks(headline)}
                                hideOnMobile={headlineHidden}
                                as={headlineTag}
                                size={headlineStyleAs}
                            />
                        )}
                        {textHtml ? <RawHtml html={textHtml} /> : null}
                        <StyledActions alignment={contentAlignment}>
                            {actions.map(({ text, url, style, target }, index) => (
                                <Link
                                    key={`${url}-${index}`}
                                    variant={getEpiButtonVariant(style)}
                                    href={url}
                                    target={target}
                                    children={text}
                                />
                            ))}
                        </StyledActions>
                    </SpotContent>
                </StyledM75Content>
            </StyledM75>
        </ModuleContainer>
    );
};

export default M75;
