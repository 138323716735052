import React from 'react';
import Headline from '$shared/components/headline';
import { Icon } from '$shared/components/icon';
import { RawHtml } from '$shared/components/raw-html';
import { StyledMediaTextSpotContentTexts, StyledShopWrapper } from './style';
import { M21GridItemProps } from '../../m21-grid-item';
import { formatString, useTranslation } from '$shared/utils';

export const M21GridItemShop = ({ headline, text }: M21GridItemProps) => {
    const { translate } = useTranslation();
    return (
        <StyledShopWrapper>
            <StyledMediaTextSpotContentTexts>
                {headline?.text && (
                    <Headline
                        children={formatString(
                            translate(
                                'content-blocks.m21-block.shop-layout.shop-layout-headline-prefix'
                            ),
                            headline.text
                        )}
                        as={headline.tag}
                        size={4}
                        noMargin
                        hideOnMobile={headline.hideOnMobile}
                    />
                )}
                {text?.html && <RawHtml html={text?.html} />}
            </StyledMediaTextSpotContentTexts>

            <Icon icon="arrowRight" color="blue" size={20} />
        </StyledShopWrapper>
    );
};
export default M21GridItemShop;
