import React, { FC } from 'react';
import { StyledAspectRatio, StyledAspectRatioContent, StyledAspectRatioProps } from './style';

export type AspectRatioProps = StyledAspectRatioProps;

/**
 * Use for any case where a `height` (or `min-height`) determined by a fixed aspect ratio is needed
 *
 * Set widescreen ratio:
 *
 * ```tsx
 * <AspectRatio ratio={16/9}>
 *     { content }
 * </AspectRatio>
 * ```
 *
 * Set ratio, but allow long content to expand height:
 *
 * ```tsx
 * <AspectRatio ratio={16/9} asMinHeight>
 *     { content potentially taller than aspect ratio }
 * </AspectRatio>
 * ```
 */

export const AspectRatio: FC<AspectRatioProps> = ({ ratio, children, ...restProps }) => (
    <StyledAspectRatio ratio={ratio} {...restProps}>
        {children && <StyledAspectRatioContent>{children}</StyledAspectRatioContent>}
    </StyledAspectRatio>
);

export default AspectRatio;
