import VideoPlayerLazy from 'react-player/lazy';
import { useRef, useState, useEffect, useMemo } from 'react';
import { fetchVideoMetadata } from './utils';
import VideoAspect from './components/video-aspect';
import VideoContainer from './components/video-container';
import PlayButton, { PlayButtonProps } from './components/play-button';
import { useCookieConsent } from '$shared/hooks/useCookieConsent';
import { StyledNoMarketingConsent } from './style';
import { isBrowser, useTranslation } from '$shared/utils';
import { RawHtml } from '../raw-html';

export interface VideoProps {
    /** Video source. Supports YouTube, Vimeo and selfhosted */
    src: string;

    /**
     * Required if selfhosted. Overwrites default poster for YouTube and Vimeo.
     * Default posters from external providers are very low resultion.
     * Providing a posterSrc is always prefered.
     */
    posterSrc?: string;

    /**
     * Show or hide controls. Only the play button will be visible until playing.
     * YouTube: Not all controls can be hidden from youtube.
     * Vimeo: Hidden controls must be enabled by video owner
     */
    controls?: boolean;

    /** Start the video muted. Prefered */
    muted?: boolean;

    /** Loop the video */
    loop?: boolean;

    /** Start playing imidiately */
    playing?: boolean;

    /**
     *  Trigger recalcuating dimensions and repositioning the video.
     *  Usefull in cases where the container resizes without an window resize event
     */
    repositionKey?: string;

    /**
     * Aspect ratio.
     * If not set will automatically determine (requires an additional requst)
     */
    aspectRatio?: number;

    /**
     * Set height to 100%
     */
    cover?: boolean;

    /**
     * Disable or overwride playbutton
     */
    playButton?: null | (({ playing }: PlayButtonProps) => JSX.Element);

    /**
     * Triggered when started playing
     */
    onPlay?: () => void;

    /**
     * Triggered when stopd playing
     */
    onPause?: () => void;

    /**
     * Video provider specific options
     * @see https://www.npmjs.com/package/react-player
     */
    embedConfig?: any;

    alt?: string;
    title?: string;
}

const defaultEmbedOptions = {
    youtube: {
        embedOptions: {
            rel: 0,
            modestbranding: 1,
        },
    },
};

export const Video = ({
    controls = true,
    muted = false,
    loop = false,
    playing = false,
    cover = false,
    src,
    posterSrc,
    repositionKey,
    onPlay,
    onPause,
    embedConfig = defaultEmbedOptions,
    playButton: VideoPlayButton = PlayButton,
    aspectRatio: defaultAspectRatio,
    alt,
    title,
}: VideoProps) => {
    if (!isBrowser) return null;

    const ref = useRef<HTMLDivElement>(null);

    const { translate } = useTranslation();
    const { marketing, statistics } = useCookieConsent();
    const hasConsent = useMemo(() => marketing && statistics, [marketing, statistics]);
    const hasNoMarketingConsent = useMemo(() => marketing === false && statistics === false, [
        marketing,
        statistics,
    ]);

    const [aspectRatio, setAspectRatio] = useState(defaultAspectRatio);
    const [isPlaying, setIsPlaying] = useState(playing);
    const [initialPlay, setInitialPlay] = useState(playing);
    const [thumbnailSrc, setThumbnailSrc] = useState(posterSrc);

    const styles = useMemo(
        () => ({
            backgroundImage: hasConsent
                ? initialPlay
                    ? 'none'
                    : thumbnailSrc
                    ? `url(${thumbnailSrc})`
                    : ''
                : '',
            paddingTop: cover ? 0 : aspectRatio ? `${aspectRatio * 100}%` : '56.25%', // Fallback to 16/9
        }),
        [thumbnailSrc, aspectRatio, initialPlay, hasConsent]
    );

    const onPlayHandler = () => {
        setInitialPlay(true);
        setIsPlaying(true);
        onPlay && onPlay();
    };

    const onPauseHandler = () => {
        setIsPlaying(false);
        onPause && onPause();
    };

    /**
     * Only reposition if covering. Otherwise fluid
     */
    useEffect(() => {
        if (!aspectRatio || !cover) {
            return;
        }

        /**
         * Determin if stretch horizontal or vertical
         */
        const scaleVideo = () => {
            const aspectElement = ref?.current;

            if (aspectElement) {
                const parentElement = aspectElement.parentElement as HTMLDivElement;
                const { width, height } = parentElement.getBoundingClientRect();

                const parentRatio = height / width;
                const verticalScale = parentRatio > aspectRatio;

                if (verticalScale) {
                    aspectElement.style.height = '100%';
                    aspectElement.style.width = '1000%';
                } else {
                    aspectElement.style.width = '100%';
                    aspectElement.style.height = '1000%';
                }
            }
        };

        scaleVideo();
        window?.addEventListener('resize', scaleVideo);
        return () => window?.removeEventListener('resize', scaleVideo);
    }, [aspectRatio, repositionKey]);

    useEffect(() => {
        if (!aspectRatio || !thumbnailSrc) {
            fetchVideoMetadata(src).then(
                ({ aspectRatio: newAspectRatio, thumbnailSrc: newThumbnailSrc }) => {
                    aspectRatio || setAspectRatio(newAspectRatio);
                    thumbnailSrc || setThumbnailSrc(newThumbnailSrc);
                }
            );
        }
    }, []);

    useEffect(() => {
        playing ? onPlayHandler() : onPauseHandler();
    }, [playing]);

    if (!aspectRatio) return null;

    return (
        <VideoContainer
            style={styles}
            cover={cover}
            playing={isPlaying}
            noConsent={hasNoMarketingConsent}
            onClick={() => VideoPlayButton && onPlayHandler()}
        >
            <VideoAspect ref={ref}>
                {initialPlay ? (
                    <VideoPlayerLazy
                        key={src}
                        url={hasConsent ? src : ''}
                        alt={alt}
                        title={title}
                        playing={isPlaying}
                        controls={controls}
                        playsinline={isPlaying}
                        muted={muted}
                        loop={loop}
                        dnt={1}
                        onPause={onPauseHandler}
                        onPlay={onPlayHandler}
                        config={embedConfig}
                    />
                ) : null}
            </VideoAspect>

            {hasConsent && VideoPlayButton ? <VideoPlayButton playing={isPlaying} /> : null}
            {hasNoMarketingConsent && (
                <StyledNoMarketingConsent>
                    <RawHtml html={translate('videos.no-consent-fallback')} />
                </StyledNoMarketingConsent>
            )}
        </VideoContainer>
    );
};

export default Video;
