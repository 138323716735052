import { Icon } from '$shared/components/icon';
import { ButtonResetStyles } from '$shared/components/button';
import Headline from '$shared/components/headline';
import Link from '$shared/components/link';
import styled from '@emotion/styled';

export const StyledMenuPage = styled.div(({ theme }) => ({
    height: '100%',
    padding: `${theme.space[3]} ${theme.space[4]}`,
}));

export const StyledBackButton = styled.button(({ theme }) => ({
    ...ButtonResetStyles,
    fontSize: theme.fontSizes.xs,
    lineHeight: '25px',
    height: '25px',
    textAlign: 'left',
    maxWidth: '90%',
    gridArea: 'back-button',

    span: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: 1.2,
        paddingLeft: 5,
    },

    svg: {
        marginRight: theme.space[1],
    },
}));

export const StyledHeaderLink = styled(Link)(({ theme }) => ({
    color: theme.colors.black,
    fontSize: theme.fontSizes.sm,
    textAlign: 'right',
    textDecoration: 'underline',
    lineHeight: theme.fontSizes.xl,
    marginTop: 3,
    gridArea: 'header-link',
}));

export const StyledHeaderLogoButton = styled.button(() => ({
    ...ButtonResetStyles,
}));
export const StyledHeaderIcon = styled(Icon)(() => ({
    gridArea: 'back-button',
    width: '100%',
    maxWidth: '120px',
    height: 'auto',
    maxHeight: '37px',
}));

export const StyledHeader = styled.div<{ hasGap?: boolean }>(({ theme, hasGap }) => ({
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    justifyContent: 'space-between',
    marginBottom: theme.space[2],
    gap: hasGap ? theme.space[4] : 0,
    gridTemplateColumns: 'minmax(0, 1fr) auto',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
        "back-button back-button"
        "headline header-link"
    `,
}));

export const StyledMenuHeadline = styled(Headline)({
    gridArea: 'headline',
});

export const StyledCloseButton = styled.div({
    ...ButtonResetStyles,

    position: 'absolute',
    top: 0,
    right: -2,
});
