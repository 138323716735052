import React, { ComponentProps, HTMLAttributes } from 'react';
import {
    StyledButton,
    StyledButtonContentWrapper,
    StyledContent,
    StyledIcon,
    StyledSpinnerWrapper,
} from './style';
import { Icon } from '../icon';
import Spinner from '../spinner';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    /**
     * Which theme variant to display button as
     */
    variant?: 'primary' | 'secondary' | 'plain' | 'linkish';

    /**
     * Add an icon to the left of the text
     */
    iconLeft?: React.ReactNode;

    /**
     * Add an icon to the right of the text
     */
    iconRight?: React.ReactNode;

    noIcon?: boolean;

    children?: React.ReactNode;

    isLoading?: boolean;
}

/**
 * Handles all the button styles across the site.
 *
 * The propper way to use as link is `as={Link}`. This hooks into the router and performance optimisations.
 */

export const Button = React.forwardRef<HTMLButtonElement, ComponentProps<typeof StyledButton>>(
    (
        { variant = 'primary', children, iconLeft, iconRight, isLoading, noIcon, ...restProps },
        ref
    ) => {
        // Default arrow right icon if iconRight is undefined
        // Checking for undefined supports disabling by passing null or false
        if (!noIcon && !iconLeft && !iconRight && variant === 'plain') {
            iconRight = <Icon icon="arrowRight" color="blue" size={20} />;
        }
        return (
            <StyledButton ref={ref} variant={variant} disabled={isLoading} {...restProps}>
                <StyledButtonContentWrapper isLoading={isLoading}>
                    {iconLeft ? <StyledIcon children={iconLeft} variant={variant} /> : null}
                    {children ? <StyledContent variant={variant}>{children}</StyledContent> : null}
                    {iconRight ? <StyledIcon children={iconRight} variant={variant} /> : null}
                </StyledButtonContentWrapper>
                {isLoading && (
                    <StyledSpinnerWrapper>
                        <Spinner />
                    </StyledSpinnerWrapper>
                )}
            </StyledButton>
        );
    }
);

export default Button;
