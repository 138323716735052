import { HeadlineProps } from '$shared/components/headline';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { ILink } from '~/lib/data-contract';
import {
    StyledHeadline,
    StyledNavItem,
    StyledNavLink,
    StyledNavItemButton,
    StyledNavItemIcon,
} from './style';

export interface INavItem {
    headerSize?: HeadlineProps['size'];
    header?: (isActive?: boolean) => ReactNode;
    link: ILink;
    list?: ReactNode;
    initAsOpen?: boolean;
}

export const NavItem: FC<INavItem> = ({ header, headerSize, link, list, initAsOpen }) => {
    const [showChildren, setShowChildren] = useState(initAsOpen);

    useEffect(() => {
        setShowChildren(initAsOpen);
    }, [initAsOpen]);

    return (
        <li>
            <StyledNavItem>
                <StyledNavLink href={link?.url} plain={true}>
                    {({ isActive }) =>
                        header ? (
                            header(isActive)
                        ) : (
                            <StyledHeadline
                                size={headerSize}
                                isActive={isActive}
                                asText
                                noMargin
                                // renderAsActive={index === activeMenuIndex}
                            >
                                {link?.title || link?.text}
                            </StyledHeadline>
                        )
                    }
                </StyledNavLink>

                {list && (
                    <StyledNavItemButton
                        onClick={() => setShowChildren(!showChildren)}
                        variant="plain"
                        isActive={!!showChildren}
                        iconRight={
                            <StyledNavItemIcon
                                icon="chevronDown"
                                color={showChildren ? 'blue' : undefined}
                            />
                        }
                    ></StyledNavItemButton>
                )}
            </StyledNavItem>

            {showChildren && list}
        </li>
    );
};

export default NavItem;
