import React from 'react';
import Link from '$shared/components/link';
import styled from '@emotion/styled';
import { FooterMetaBlockViewModel } from '~/lib/data-contract';
import GridItem, { GridItemProps } from './grid-item';
import { RawHtml } from '$shared/components/raw-html';
import { getTarget } from '$shared/utils';

type MetaAreaProps = GridItemProps & FooterMetaBlockViewModel;

const StyledLinksContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.space[3],
}));

export const MetaArea = ({ headline, subHeadline, links = [], copyright }: MetaAreaProps) => (
    <GridItem headline={headline} subHeadline={subHeadline} area="meta">
        <StyledLinksContainer>
            {links.map(({ target, url, title, text, id }) => (
                <div key={id}>
                    <Link
                        href={url}
                        target={target || getTarget(url)}
                        title={title}
                        children={title || text}
                    />
                </div>
            ))}
        </StyledLinksContainer>
        {copyright ? <RawHtml html={copyright} /> : null}
    </GridItem>
);

export default MetaArea;
