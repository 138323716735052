import { StyledMaxWidth } from '$shared/components/max-width';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';

export const StyledPdPGrid = styled(StyledMaxWidth)(() => ({
    display: 'grid',
    gap: theme.space.gutter,
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `"image"
                        "data"
                        "related-product"`,

    [breakpoints.sm]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateAreas: `"image data" 
                            "related-product related-product"`,
        gap: theme.space[7],
    },
}));
export const StyledPdPGridImage = styled.div(() => ({
    gridArea: 'image',
}));
export const StyledPdPGridData = styled.div(() => ({
    gridArea: 'data',
}));
export const StyledPdPSpecifications = styled.div(() => ({
    marginTop: theme.space[5],

    [breakpoints.lg]: {
        marginTop: theme.space[3],
    },
}));
export const StyledPdPGridBottomArea = styled.div(() => ({
    gridArea: 'related-product',
}));

export const StyledBorder = styled.div(() => ({
    border: `solid 1px ${theme.colors.grey30}`,
}));
