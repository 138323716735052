import { PriceQuantityViewModel } from '$order-models/*';
import { breakpointSizes } from '$theme';
import { useMedia } from 'react-use';
import { Popover } from '../popover/popover';
import { QuantitySpinner, QuantitySpinnerProps } from '../quantity-spinner/quantity-spinner';
import { QuantityDiscountPopover } from './quantity-discount-popover/quantity-discount-popover';
import { Skeleton } from '$shared/components/skeleton';

export type QuantityPickerProps = QuantitySpinnerProps & {
    prices?: PriceQuantityViewModel[];
    unit?: string;
    disableDiscountBelowMin?: boolean;
    onBlur?: () => void;
    loadingData?: boolean;
};

export const QuantityPicker = ({
    prices = [],
    unit = '',
    onBlur,
    disableDiscountBelowMin,
    loadingData = false,
    ...spinnerProps
}: QuantityPickerProps) => {
    const largeFrame = useMedia(`(min-width: ${breakpointSizes.frame}px)`, false);

    if (loadingData && prices.length === 0) {
        return (
            <Popover
                fullwidthOnMobile
                popoverContentProps={{
                    avoidCollisions: !largeFrame,
                }}
                content={() => (
                    <Skeleton
                        pulsating
                        height={'1em'}
                        width={'100%'}
                        css={{ height: '30px', minWidth: '300px' }}
                    />
                )}
            >
                {/* div needs to be there for popover to work properly */}
                <div>
                    <QuantitySpinner onBlur={onBlur} {...spinnerProps} />
                </div>
            </Popover>
        );
    }

    return prices.length > 0 ? (
        <Popover
            fullwidthOnMobile
            popoverContentProps={{
                avoidCollisions: !largeFrame,
            }}
            content={(close) => (
                <QuantityDiscountPopover
                    prices={prices}
                    unit={unit}
                    handleSelect={(values) => {
                        spinnerProps.onChange(values);
                        close();
                    }}
                    minQuantity={disableDiscountBelowMin ? spinnerProps.min : undefined}
                />
            )}
        >
            {/* div needs to be there for popover to work properly */}
            <div>
                <QuantitySpinner onBlur={onBlur} {...spinnerProps} />
            </div>
        </Popover>
    ) : (
        <QuantitySpinner onBlur={onBlur} {...spinnerProps} />
    );
};
