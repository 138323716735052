import React from 'react';
import { StyledNavList } from './style';

export interface INavList<TData> {
    children?: (item: TData, index: number) => JSX.Element;
    list?: TData[];
}

export function NavList<TData>({ children, list }: INavList<TData>) {
    if (!children || !list) {
        return null;
    }

    return <StyledNavList>{list.map((item, index) => children(item, index))}</StyledNavList>;
}

export default NavList;
