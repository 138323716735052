import React, { FC, ReactNode, useEffect, useState } from 'react';
import { StyledAppearOnHoverContent, StyledAppearOnRoot, StyledPopover } from './style';
import { Trigger, Portal, PopoverContentProps } from '@radix-ui/react-popover';
import { useDebounce } from '$shared/utils';
import { useRouter } from 'next/router';

/**
 * @description
 * Wraps any content or component, to display content on hover.
 *
 * @example
 * <Popover content={<AnyCoolComponent />} position="top">
 *    <p>Hover here for tool tips</p>
 * </Popover>;
 *
 */

type popUpPosition = 'left' | 'right' | 'top' | 'bottom';

type PopoverProps = {
    fullwidthOnMobile?: boolean;
    children?: ((close: () => void) => ReactNode) | ReactNode;
    content?: ((close: () => void) => ReactNode) | ReactNode;
    position?: popUpPosition;
    closeOnRouteChange?: boolean;
    popoverContentProps?: PopoverContentProps;
};

export const Popover: FC<PopoverProps> = ({
    children,
    content,
    position,
    fullwidthOnMobile,
    closeOnRouteChange,
    popoverContentProps,
}) => {
    const [showContent, setShowContent] = useState(false);
    const debouncedShowContent = useDebounce(showContent, 200);
    const router = useRouter();

    useEffect(() => {
        if (closeOnRouteChange && router.events) {
            const closeContent = () => setShowContent(false);
            router.events.on('routeChangeStart', closeContent);
            return () => router.events.off('routeChangeStart', closeContent);
        }
    }, [closeOnRouteChange, router.events]);

    return (
        <span
            onMouseEnter={() => {
                setShowContent(true);
            }}
            onFocus={() => {
                setShowContent(true);
            }}
            onMouseLeave={() => setShowContent(false)}
            onBlur={() => setShowContent(false)}
        >
            <StyledAppearOnRoot open={debouncedShowContent}>
                <Trigger asChild>
                    {typeof children === 'function'
                        ? children(() => setShowContent(false))
                        : children}
                </Trigger>
                <Portal>
                    <StyledAppearOnHoverContent
                        side={position}
                        onKeyDown={(key) => key.code === 'Escape' && setShowContent(false)}
                        {...popoverContentProps}
                    >
                        <StyledPopover fullwidthOnMobile={fullwidthOnMobile}>
                            {typeof content === 'function'
                                ? content(() => setShowContent(false))
                                : content}
                        </StyledPopover>
                    </StyledAppearOnHoverContent>
                </Portal>
            </StyledAppearOnRoot>
        </span>
    );
};
