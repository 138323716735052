import { theme } from '../../../../../../theme/theme';
import { ifProp } from 'styled-tools';
import styled from '@emotion/styled';
import { m } from 'framer-motion';
import Button from '$shared/components/button';

export const StepContentContainer = styled.div({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
});

export const StepLineContainer = styled(m.div)({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
});

export const StyledStepButton = styled
    .a<{ isSelected: boolean }>(
        ({ theme }) => ({
            flex: 1,
            fontFamily: theme.fonts.headline,
            fontWeight: theme.fontWeights.semiBold,
            fontSize: theme.fontSizes.lg,
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
            paddingBlock: theme.space[3],
            paddingInline: 0,
            margin: 0,
        }),
        ifProp('isSelected', {
            color: theme.colors.primary,
            borderBottom: `3px solid ${theme.colors.primary}`,
        })
    )
    .withComponent(Button);
