import styled from '@emotion/styled';
import { formatString, useTranslation } from '$shared/utils';
import { FC, useMemo } from 'react';
import { StockStatus } from '../stock-status';

interface StockMessageProps {
    className?: string;
    showStock: boolean;
    status: StockStatus;
    stockAvailable?: number;
    units?: string;
}

const StyledStockMessage = styled.span({
    display: 'inline-block',
    minHeight: '1em',
    width: '100%',
    textAlign: 'left',
});

export const StockMessage: FC<StockMessageProps> = (props) => {
    const { translate } = useTranslation();

    const stockMessage = useMemo(() => {
        if (props.showStock) {
            return `${props.units ? props.stockAvailable : ''} ${
                props.units || formatString(translate('stock.default-unit'), props.stockAvailable)
            }`;
        } else {
            return translate(`stock.stock-message-${props.status}`);
        }
    }, [props.status, props.showStock, props.stockAvailable]);

    return <StyledStockMessage children={stockMessage} />;
};
