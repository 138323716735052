import { useTranslation } from '$shared/utils';
import { FC, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
    StyledQuantitySpinner,
    StyledSpinnerButton,
    StyledSpinnerInput,
} from './quantity-spinner-styles';

export interface QuantitySpinnerValue {
    isValid: boolean;
    parsedValue: number;
    value: string;
}

export interface QuantitySpinnerProps {
    className?: string;
    disabled?: boolean;
    min?: number;
    max?: number;
    onChange: (value: QuantitySpinnerValue) => void;
    step?: number;
    value?: string | number;
    onBlur?: () => void;
}

function parseValue(value: string | number): number {
    return typeof value === 'number' ? value : parseFloat(value);
}

export const QuantitySpinner: FC<QuantitySpinnerProps> = ({
    className,
    disabled = false,
    min = 1,
    max,
    onChange,
    step = 1,
    value = 1,
    onBlur,
}) => {
    const { translate } = useTranslation();
    const [canDecrement, setCanDecrement] = useState(false);
    const [canIncrement, setCanIncrement] = useState(false);

    const parsedValue = useMemo(() => parseValue(value), [value]);

    useEffect(() => {
        setCanDecrement(parsedValue > min);
        setCanIncrement(!max || parsedValue + step <= max);
    }, [min, max, step, parsedValue]);

    const setValue = useCallback(
        (nextValue: string | number) => {
            if (disabled) return;
            const parsedNextValue = parseValue(nextValue);
            const isInteger = Number.isInteger(parsedNextValue);
            const isWithinLowerBounds = parsedNextValue >= min;
            const isWithinUpperBounds = !max || parsedNextValue <= max;
            const matchesStep = parsedNextValue % step === 0;
            const isValid = isInteger && isWithinLowerBounds && isWithinUpperBounds && matchesStep;

            onChange?.({
                isValid,
                parsedValue: parsedNextValue,
                value: isNaN(+nextValue) ? `${value}` : `${nextValue}`,
            });
        },
        [disabled, max, min, onChange, step]
    );

    const increment = useCallback(
        () =>
            canIncrement &&
            setValue(
                isNaN(parsedValue)
                    ? step
                    : parsedValue % step === 0
                    ? parsedValue + step
                    : Math.ceil(parsedValue / step) * step
            ),
        [canIncrement, parsedValue, setValue, step]
    );

    const decrement = useCallback(
        () =>
            canDecrement &&
            setValue(
                isNaN(parsedValue)
                    ? step
                    : parsedValue % step === 0
                    ? parsedValue - step
                    : Math.floor(parsedValue / step) * step
            ),
        [canDecrement, parsedValue, setValue, step]
    );

    const onInputKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            switch (e.key) {
                case 'ArrowDown':
                    decrement();
                    break;
                case 'ArrowUp':
                    increment();
                    break;
                default:
                    return;
            }

            e.preventDefault();
        },
        [decrement, increment]
    );

    const handleBlur = () => {
        onBlur?.();
        if (!value) {
            setValue(step);
        }

        // The use must never break the step bound, so if they try to, we increment to nearest step.
        // This is a bit invasive to be sure.
        const parsedNextValue = parseValue(value);

        if (parsedNextValue % step !== 0) {
            increment();
        }
    };

    return (
        <StyledQuantitySpinner
            aria-valuemax={max}
            aria-valuemin={min}
            className={className}
            onKeyDown={onInputKeyDown}
            role="spinbutton"
        >
            <StyledSpinnerButton
                aria-disabled={disabled || !canDecrement}
                aria-label={translate('forms.fields.spinbutton.decrement')}
                isIncrement={false}
                onMouseDown={(e) => e.preventDefault()}
                onClick={decrement}
                tabIndex={-1}
            />
            <StyledSpinnerInput
                characterLength={`${value}`.length}
                inputMode="tel"
                onChange={(e) => setValue(e.currentTarget.value)}
                onBlur={handleBlur}
                readOnly={disabled}
                value={value}
            />
            <StyledSpinnerButton
                aria-disabled={disabled || !canIncrement}
                aria-label={translate('forms.fields.spinbutton.increment')}
                isIncrement={true}
                onMouseDown={(e) => e.preventDefault()}
                onClick={increment}
                tabIndex={-1}
            />
        </StyledQuantitySpinner>
    );
};
