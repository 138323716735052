import { theme } from '$theme';
import { ifProp } from 'styled-tools';
import { StyledButton } from '$shared/components/button';
import styled from '@emotion/styled';
import { breakpoints } from '$theme';
import { MediaTextProps } from './media-text';
import Link from '$shared/components/link';
import SpotContent, { SpotContentProps } from '$shared/components/spot-content';
import { StyledHeadline } from '$shared/components/headline';
import { Icon } from '$shared/components/icon';
type MediaTextLayout = Pick<MediaTextProps, 'layoutVariant'>;
export const StyledMediaTextLink = styled(Link)({
    '&,:hover': {
        textDecoration: 'none',
    },
});
export const StyledActions = styled.div(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.space['2'],
    marginTop: theme.space['3'],

    [`${StyledButton}`]: {
        marginTop: 0,
        paddingTop: 0,
    },
}));
type SpotWrapperProps = {
    hasMedia?: boolean;
};
export const SpotMediaWrapper = styled.div<SpotWrapperProps & MediaTextLayout>(
    ({ theme }) => ({
        overflow: 'hidden',
        margin: `0 -${theme.space['4']} ${theme.space['5']}`,
        [breakpoints.sm]: {
            width: '100%',
            margin: `0 0 ${theme.space['5']}`,
        },
        ':empty': {
            flexBasis: 0,
        },
    }),
    ifProp({ hasMedia: false }, ({ theme }) => ({
        backgroundColor: `${theme.colors.backupImageBackgroundColor}`,
    })),
    ifProp(
        { layoutVariant: 'narrow' },
        {
            margin: 0,
        }
    )
);
export const StyledMediaTextSpotContent = styled(SpotContent)<SpotContentProps & MediaTextLayout>(
    () => ({
        [breakpoints.sm]: {
            width: '100%',
            justifyContent: 'space-between',
            marginBottom: 'auto',
        },
    }),
    ifProp(
        { layoutVariant: 'narrow' },
        {
            [breakpoints.xs]: {
                width: '100%',
                textAlign: 'left' as const,
                flexDirection: 'row' as const,
                justifyContent: 'space-between',
                marginLeft: theme.space[3],
            },
        }
    )
);
export const StyledMediaTextSpotContentTexts = styled.div<SpotContentProps & MediaTextLayout>(
    ifProp(
        { layoutVariant: 'narrow' },
        {
            [breakpoints.xs]: {
                // Line clamp to 3
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': '3',
                '-webkit-box-orient': 'vertical',
            },
        }
    )
);
export const StyledMediaTextIcon = styled(Icon)({
    flexShrink: 0,
    marginLeft: theme.space[3],
});
export const StyledMediaText = styled.div<MediaTextLayout>(
    {
        minWidth: 0,

        [breakpoints.sm]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
        },
        [`${StyledMediaTextIcon}`]: {
            display: 'none',
        },
    },
    ifProp(
        { layoutVariant: 'narrow' },
        {
            [breakpoints.xs]: {
                display: 'flex',
                alignItems: 'center',
                padding: `${theme.space[2]} 0`,
                borderBottom: `1px solid ${theme.colors.borderColor}`,

                [`${StyledMediaTextIcon}`]: {
                    display: 'block',
                },

                [`${StyledActions}`]: {
                    display: 'none',
                },

                [`${StyledHeadline}`]: {
                    textAlign: 'left' as const, // <3 styledcomponents
                    margin: 0,
                },

                [`${SpotMediaWrapper}`]: {
                    flexShrink: 0,
                    width: '65px',
                },
            },
        }
    )
);
