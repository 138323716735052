import { useFrame } from '$shared/utils';
import { useCallback, useMemo } from 'react';
import { INavigationNode, ISearchResultItemViewModel } from '~/lib/data-contract';

export const useEnrichedCategories = (categories?: ISearchResultItemViewModel[]) => {
    const { navigation } = useFrame();

    const findParentNode = useCallback(
        (category: ISearchResultItemViewModel) => {
            if (!navigation) return;

            const parentUrlToFind = category.link.url?.split('/').slice(0, 4).join('/');

            function getParentNode(
                navigationNode: INavigationNode,
                urlToFind: string
            ): INavigationNode | undefined {
                if (!navigationNode?.children) return;

                function flattenNavigation(navigationNodes: INavigationNode[]): INavigationNode[] {
                    const flatNavigation: INavigationNode[] = [];

                    navigationNodes.forEach((item) => {
                        if (item.children?.length) {
                            flatNavigation.push(...[item, ...flattenNavigation(item.children)]);
                        } else {
                            flatNavigation.push(item);
                        }
                    });

                    return flatNavigation;
                }

                return flattenNavigation(navigationNode?.children).find(
                    (nc) => nc.link.url === urlToFind
                );
            }

            return parentUrlToFind
                ? getParentNode(navigation, parentUrlToFind)?.link.title
                : undefined;
        },
        [navigation]
    );

    const enrichedCategories = useMemo(
        () =>
            (categories || []).map((c) => ({
                ...c,
                parentTitle: findParentNode(c),
            })),
        [categories]
    );

    return { enrichedCategories };
};
