import React, { FC } from 'react';
import {
    StyledLoadedBar,
    StyledProgressBar,
    StyledProgressBarContainer,
    StyledToLoadBar,
} from './style';

export type ProgressBarProps = {
    shownProducts?: number;
    totalProducts?: number;
    incrementSize?: number;
    isActive: boolean;
};

export const ProgressBar: FC<ProgressBarProps> = ({
    shownProducts = 0,
    totalProducts = 0,
    incrementSize = 0,
    isActive,
}) => {
    const loadedInset = (shownProducts / totalProducts) * 100;
    const incrementInset = (incrementSize / totalProducts) * 100;
    const toLoadInset = loadedInset + incrementInset > 100 ? 100 : loadedInset + incrementInset;

    return (
        <StyledProgressBarContainer>
            <StyledProgressBar>
                <StyledToLoadBar progressInPercent={isActive ? toLoadInset : loadedInset} />
                <StyledLoadedBar progressInPercent={loadedInset} />
            </StyledProgressBar>
        </StyledProgressBarContainer>
    );
};
