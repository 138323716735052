import * as colors from './colors';
export type ThemeType = typeof theme;

declare module '@emotion/react' {
    export interface Theme extends ThemeType {}
}

export const breakpointSizes = {
    /**
     * smaller phone sizes
     */

    xxs: 475,
    /**
     * Larger phones, tablet portrait
     */

    sm: 768,
    /**
     * When the frame should break between mobile / desktop
     */
    frame: 1024,
    /**
     * Tablets
     */
    md: 1024,
    /**
     * Desktops, laptops
     */
    lg: 1367,
    /**
     * Wide screens
     */
    xl: 1620,
    /**
     * Beyond max content width
     */
    max: 1920,
};

export const breakpoints = {
    /**
     * Mobile devices. Use orientation media queries for landscape styling
     */
    xxs: `@media (min-width: ${breakpointSizes.xxs}px)`,
    xs: '@media (max-width: 767px)',

    /**
     * Small tablets
     */
    msm: `@media (max-width: ${breakpointSizes.sm}px)`,

    sm: `@media (min-width: ${breakpointSizes.sm}px)`,
    /**
     * When the frame should break between mobile / desktop
     */
    frame: `@media (min-width: ${breakpointSizes.frame}px)`,
    /**
     * Large tablets, small laptops
     */
    mmd: `@media (max-width: ${breakpointSizes.md}px)`,
    md: `@media (min-width: ${breakpointSizes.md}px)`,
    /**
     * Desktops, laptops
     */
    lg: `@media (min-width: ${breakpointSizes.lg}px)`,
    /**
     * Wide screens
     */
    xl: `@media (min-width: ${breakpointSizes.xl}px)`,

    /**
     * Beyond max content width
     */
    max: `@media (min-width: ${breakpointSizes.max}px)`,
    /**
     * Prefers reduced motion
     */
    reducedMotion: '@media (prefers-reduced-motion)',
};

export const theme = {
    colors,
    fonts: {
        headline: 'myriad-pro-condensed',
        body: 'myriad-pro',
    },
    fontWeights: {
        regular: 400,
        semiBold: 600,
        bold: 700,
    },
    lineHeights: {
        single: '1',
        compact: '1.35',
        base: '1.5',
        summary: '1.75',
        headline: '1.05',
    },
    letterSpacings: {
        xs: '0.01em',
        sm: '0.02em',
        md: '0.03em',
    },
    fontSizes: {
        '3xs': '12px',
        '2xs': '13px',
        xs: '14px',
        sm: '15px',
        md: '16px',
        lg: '18px',
        xl: '25px',
        '2xl': '30px',
        '3xl': '40px',
        '4xl': '70px',
    },
    space: {
        '1': '5px',
        '2': '10px',
        '3': '15px',
        '4': '20px',
        '4.5': '25px',
        '5': '30px',
        '5.5': '35px',
        '6': '40px',
        '6.5': '45px',
        '7': '50px',
        '8': '60px',
        '9': '70px',
        '10': '80px',
        gutter: '20px',
    },
    sizes: {
        buttonHeight: 40,

        // Limit content
        contentMaxWidth: 1440,

        // Limit page width. Background colors etc.
        pageMaxWidth: 1730,
    },
    zIndices: {
        '1': 100,
        '2': 200,
        '3': 300,
        '4': 400,
        loadingOverlay: 498,
        loadingSpinner: 499,
        max: 999,
    },
    shadows: {
        popShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
    },
    /**
     * Grid is based on VW
     */
    grid: {
        columns: 12,
        maxWidth: 1440, //px
        offsetSizes: {
            md: 2.4, // vw
        },
        columnSizes: {
            md: 6.59, // vw
        },
    },
    easings: {
        smoothEase: 'cubic-bezier(0.35, 0, 0.15, 1)',
    },
    global: {
        headerHeightLG: '325px',
        headerHeightSM: '326px',
        headerBorderThickness: '8px',
        moduleBottomSpacing: '40px',
        moduleBottomSpacingMobile: '20px',
    },
    buttonFontSizes: {
        sm: '16px',
        md: '18px',
    },
};
