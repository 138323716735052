import styled from '@emotion/styled';

const Divider = styled.hr(({ theme }) => ({
    width: '100%',
    borderTop: `${theme.colors.borderColor}`,
    color: `${theme.colors.borderColor}`, //Needs Color attribute for Firefox
    margin: '0',
}));

export default Divider;
