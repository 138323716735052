import { IFacetOption } from '$shared/components/facets';
import { FilterFacetOption } from '$utils/filter';

export type IFacetOptionAdapter<TOption> = (option: TOption) => IFacetOption<TOption>;
export const facetOptionMapper: IFacetOptionAdapter<FilterFacetOption> = (option) => ({
    name: `${option.displayValue}`,
    key: `${option.key}`,
    selected: !!option.selected,
    // disabled: !option.count, // KYOC-1428 count is removed, so it should no longer disable the facet. Leaving this comment for documentation
    count: option.count,
    option,
});
