import React from 'react';
import GridItem from './grid-item';
import { ApsisNewsletter } from '$scripts/apsis/apsis-newsletter';

export const NewsLetterArea = () => {
    return (
        <GridItem area="newsletter">
            <ApsisNewsletter />
        </GridItem>
    );
};

export default NewsLetterArea;
