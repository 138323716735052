import { fetchPage } from '~/lib/api';
import { IRedirect } from '~/lib/data-contract';

export async function pageResolver(url: string, requestHeaders: HeadersInit) {
    const response = await fetchPage(url, {}, requestHeaders, { redirect: 'manual' });

    const { headers, status, statusText } = response;

    if (status > 300 && status < 400) {
        const location = headers.get('location');
        const destination = location && new URL(location).pathname;

        return {
            data: {
                type: 'redirect',
                destination,
                permanent: status === 301 || status === 308,
            } as IRedirect,
            headers,
            status,
            statusText,
        };
    }

    const data = await response.json();

    return {
        headers,
        status,
        statusText,
        data,
    };
}
