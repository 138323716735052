import { isBrowser } from '$shared/utils';
import Head from 'next/head';
import { IMetadata } from '~/lib/data-contract';

type PageMetaTagsProps = IMetadata & {
    keyId: string;
};

export const Meta = ({ metaTitle, metaTags, keyId }: PageMetaTagsProps) => {
    // If request is coming from azure hosted site (like: https://app-kyo-spa-we-prod.azurewebsites.net/en-dk or https://app-kyo-spa-we-qa.azurewebsites.net/) then we don't want to index the page
    const currentHostName = isBrowser ? window.location.hostname.toLowerCase() : '';

    const isAzureHost = isBrowser
        ? currentHostName === 'app-kyo-spa-we-prod.azurewebsites.net' ||
          currentHostName === 'app-kyo-spa-we-qa.azurewebsites.net'
        : false;

    // TODO: Remove this console.log after testing
    console.log({
        currentHostName,
        isAzureHost,
    });

    return (
        <Head>
            <title>{metaTitle}</title>

            <meta charSet="utf-8" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
            />

            {metaTags?.map(({ tag, ...props }, index) => {
                const key = `${keyId}-${index}`;

                switch (tag) {
                    case 'meta': {
                        return <meta key={key} {...props} />;
                    }
                    case 'link': {
                        return <link key={key} {...props} />;
                    }
                    case 'script': {
                        const { body, ...rest } = props as { body: string };
                        return (
                            <script
                                key={key}
                                {...rest}
                                dangerouslySetInnerHTML={{ __html: body }}
                            />
                        );
                    }
                }
            })}

            {isAzureHost && <meta name="robots" content="noindex, nofollow" />}
        </Head>
    );
};

export default Meta;
