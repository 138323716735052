import { ReactHookForm__InputProps } from '$shared/components/form/models/form-models';
import React, { FocusEvent, useCallback } from 'react';
import { useController } from 'react-hook-form';
import {
    SelectorElement,
    SelectorElementProps,
} from '../../controllable-elements/selector-element';

export type SelectorProps<T> = SelectorElementProps<T> & ReactHookForm__InputProps;
export const Selector = <T,>({ name, rules, ...props }: SelectorProps<T>) => {
    const {
        field: { value, onBlur, onChange },
        fieldState: { invalid, error },
    } = useController({
        defaultValue: props.allowMultiSelect
            ? props.options.filter((o) => o.checked)
            : props.options.find((o) => o.checked),
        name,
        rules,
    });

    const onBlurHandler = useCallback(
        (e: FocusEvent<HTMLSelectElement, Element>) => {
            onBlur?.();
            props.onBlur?.(e);
        },
        [onBlur, props.onBlur]
    );

    const { message: errorMessage } = error || {};

    return (
        <SelectorElement
            controlledValue={value}
            onChange={onChange}
            isInvalid={invalid}
            invalidMessage={errorMessage}
            isRequired={!!rules?.required}
            {...props}
            onBlur={onBlurHandler}
        />
    );
};

export default Selector;
