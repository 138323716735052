import styled from '@emotion/styled';
import { Icon } from '$shared/components/icon';
import { breakpoints, theme } from '$theme';
import Link from '$shared/components/link';
import { Button } from '$shared/components/button';

export const StyledIcon = styled(Icon)({
    width: '1.5rem',
    maxHeight: '1.5rem',

    [breakpoints.frame]: {
        marginBottom: '7px',
        width: '2rem',
        maxHeight: '2rem',
    },
});

export const StyledHeaderButtonWrapper = styled.div(() => ({
    display: 'flex',
    position: 'relative',
}));

export const StyledLabel = styled.span(({ theme }) => ({
    display: 'none',
    whiteSpace: 'nowrap',
    fontFamily: theme.fonts.body,
    fontSize: theme.fontSizes['3xs'],
    fontWeight: theme.fontWeights.semiBold,
    textTransform: 'uppercase',

    [breakpoints.frame]: {
        display: 'block',
    },
}));

export const StyledHeaderButton = styled.button(() => ({
    cursor: 'pointer',
    textAlign: 'center',
    background: 'rgba(0,0,0,0)',
    border: 'none',
    padding: '10px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    ':hover': {
        [`${StyledIcon}, ${StyledLabel}`]: {
            color: theme.colors.blue,
        },
    },
}));

export const BasketCountBadge = styled.div(({ theme }) => ({
    position: 'absolute',
    top: -12,
    right: -8,
    background: theme.colors.blue90,
    fontSize: 11,
    color: theme.colors.white,
    width: 16,
    height: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [breakpoints.sm]: {
        top: 0,
        right: 0,
    },
}));

export const StyledHeaderButtons = styled.div({
    display: 'flex',
});

export const StyledAuthenticationPanelContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[3],
    maxWidth: '100vw',
    padding: `${theme.space[4.5]} ${theme.space[4]}`,
    width: '100%',
    fontWeight: theme.fontWeights.semiBold,

    [breakpoints.sm]: {
        width: 380,
        padding: `${theme.space[4.5]} ${theme.space[3]}`,
    },
}));

export const StyledSignOutButton = styled.button(({ theme }) => ({
    all: 'unset',
    cursor: 'pointer',
    padding: `${theme.space[3]} 0`,
}));

export const MyPagesNavContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.colors.borderColor}`,
}));

export const StyledNavItem = styled(Link)(({ theme }) => ({
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.space[3]} 0`,
    marginBottom: theme.space[3],
    textDecoration: 'none',
    color: theme.colors.defaultText,

    ['&:hover']: {
        textDecoration: 'none',
        color: theme.colors.defaultText,
    },
}));

export const StyledSignInButton = styled(Button)();

export const StyledSignUpLink = styled(Link)();
