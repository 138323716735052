import React, { FC } from 'react';
import Script from 'next/script';
import { useFrame } from '$shared/utils';
import { StyledApsisForm } from './style';

export const ApsisNewsletter: FC = () => {
    const { footer } = useFrame();
    if (!footer?.newsletter?.apsisFormId) {
        return null;
    }

    // Apsis exposes the id for the form, with a prefix, here we ensure is removed.
    // Because we don't need it for the script part.
    const formId = footer?.newsletter?.apsisFormId?.replace('.afp-', '')?.replace('afp-', '');

    return (
        <StyledApsisForm>
            <Script
                strategy="lazyOnload"
                src="https://forms.apsisforms.com/formbuilder.js"
                type="text/javascript"
                onLoad={() => {
                    const apsisRef = (window as any)?.ApsisForms;

                    if (apsisRef) {
                        const form = new apsisRef.FormbuilderInstance();
                        form.init({
                            formId,
                        });
                    }
                }}
            />

            <div className={`afp-${formId}`} />
        </StyledApsisForm>
    );
};
