import React, { FC, ReactNode } from 'react';
import { StyledAppearOnHoverContent, StyledAppearOnRoot } from './style';
import { Portal, Trigger } from '@radix-ui/react-hover-card';

/**
 * @description
 * Wraps any content or component, to display content on hover.
 *
 * @example
 * <AppearOnHover content={<AnyCoolComponent />} position="top">
 *    <p>Hover here for tool tips</p>
 * </AppearOnHover>;
 *
 */

export type popUpPosition = 'left' | 'right' | 'top' | 'bottom';

export type AppearOnHoverProps = {
    children?: ReactNode;
    content?: ReactNode;
    position?: popUpPosition;
    arrow?: boolean;
};

export const AppearOnHover: FC<AppearOnHoverProps> = ({ children, content, position }) => {
    return (
        <StyledAppearOnRoot openDelay={200} closeDelay={300}>
            <Trigger asChild>{children}</Trigger>
            <Portal>
                <StyledAppearOnHoverContent side={position}>{content}</StyledAppearOnHoverContent>
            </Portal>
        </StyledAppearOnRoot>
    );
};
