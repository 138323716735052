import { FC, useMemo } from 'react';
import { StockIndicator } from '$features/stock/stock-indicator/stock-indicator';
import { StockStatus } from '$features/stock/stock-status';
import { Accordion } from '$shared/components/accordion';
import { Icon } from '$shared/components/icon';
import { CheckoutLine, CheckoutLineItem } from '../../../shared/checkout-line/checkout-line';
import { DeliveryMessage } from '../delivery-message/delivery-message';
import {
    StyledCheckoutAccordion,
    StyledCheckoutList,
    StyledCheckoutOrderHeader,
    StyledCheckoutOrderTitle,
    StyledCheckoutStockIndicator,
} from './style';
import { useStockStatus } from '$features/stock/hooks/use-stock-status';
import { formatString, useTranslation } from '$shared/utils';
import { useOrderStore } from '$features/checkout/hooks/use-order-store';
import { Aspect4OrderLineViewModel, CalculatedBasketLineServiceViewModel } from '$order-models/*';

export type DeliveryIntervalsProps = {
    initAsOpen?: boolean;
    controlledLines?: Aspect4OrderLineViewModel[];
    splitOrderOverwrite?: boolean; // Used on receipt page
};

export type DeliveryInterval = {
    stockStatus?: StockStatus;
    lines?: CheckoutLineItem[];
};

export type OrderLineItemWithSplit = {
    id?: string;
    lines: Aspect4OrderLineViewModel[];
    hasSplit?: boolean;
};

const checkoutItemAdapter = (item: Aspect4OrderLineViewModel): CheckoutLineItem => ({
    customerText2: item.customerText2,
    customerText3: item.customerText3,
    imageUrl: item.photoPath,
    productName: item.itemDescription,
    primaryProductNumber: item.itemNumber,
    quantity: item.quantity,
    productPrice: item.unitPricePretty,
    totalPrice: item.totalPricePretty || '',
    units: item.unitDescription || '',
    serviceDetails: item.serviceDetails as CalculatedBasketLineServiceViewModel[],
    promotedProduct: item.promotedProduct,
    key: `${item.basketLineId}-${item.deliveryEstimate}`,
});

const joinedDeliverySorter = (
    a: Aspect4OrderLineViewModel,
    b: Aspect4OrderLineViewModel
): number => {
    const aV = a.priceViewModel?.stock?.deliveryEstimate;
    const bV = b.priceViewModel?.stock?.deliveryEstimate;

    if (aV && bV) {
        if (aV < bV) {
            return 1;
        }
        if (aV > bV) {
            return -1;
        }
    }
    return 0;
};

const splitDeliverySorter = (
    a: Aspect4OrderLineViewModel,
    b: Aspect4OrderLineViewModel
): number => {
    const aV = a.priceViewModel?.stock?.deliveryEstimate;
    const bV = b.priceViewModel?.stock?.deliveryEstimate;

    if (aV && bV) {
        if (aV > bV) {
            return 1;
        }
        if (aV < bV) {
            return -1;
        }
    }
    return 0;
};

export const DeliveryIntervals: FC<DeliveryIntervalsProps> = ({
    initAsOpen = true,
    controlledLines,
    splitOrderOverwrite,
}) => {
    const { translate } = useTranslation();
    const { splitOrder } = useOrderStore();

    const shouldSplitOrder = splitOrderOverwrite !== undefined ? splitOrderOverwrite : splitOrder;

    const { quote } = useOrderStore();
    const { orderLines: quoteOrderLines } = quote || {};

    const orderLines = controlledLines || quoteOrderLines;

    const orderlinesWithSplit = useMemo(() => {
        return orderLines
            ?.reduce((acc, curr) => {
                const foundObj = acc.find((obj) => obj.id === curr.basketLineId);
                if (foundObj) {
                    foundObj.lines.push(curr);
                    foundObj.hasSplit = true;
                } else {
                    acc.push({
                        id: curr.basketLineId || '',
                        lines: [{ ...curr }],
                    });
                }
                return acc;
            }, [] as OrderLineItemWithSplit[])
            .filter((line) => line.hasSplit);
    }, [orderLines, shouldSplitOrder]);

    const splitOrderByStockStatus = useMemo(() => {
        const sortedLines = orderLines
            ?.map((line) => {
                const newLine = { ...line };
                const lineHasSplit = orderlinesWithSplit?.some(
                    (splitLine) => splitLine.id === newLine.basketLineId
                );
                if (
                    lineHasSplit &&
                    newLine.priceViewModel?.stock?.deliveryEstimate &&
                    newLine.deliveryEstimate !== newLine.priceViewModel.stock.deliveryEstimate
                ) {
                    newLine.priceViewModel.stock.deliveryEstimate = newLine.deliveryEstimate;
                    newLine.priceViewModel.stock.stockAvailable = -1;
                    newLine.priceViewModel.showStock = false;
                }
                // Use the main orderline delivery estimate instead of the stock one if it is different
                if (
                    !lineHasSplit &&
                    newLine.priceViewModel?.stock?.deliveryEstimate &&
                    newLine.deliveryEstimate !== newLine.priceViewModel.stock.deliveryEstimate
                ) {
                    newLine.priceViewModel.stock.deliveryEstimate = newLine.deliveryEstimate;
                }
                return newLine;
            })
            .sort(splitDeliverySorter);
        return sortedLines?.reduce((acc, curr) => {
            const { stock } = curr.priceViewModel || {};
            const filteredStockList = acc.filter((l) => l.stockStatus !== stock?.deliveryEstimate);
            const foundStockList = acc.find((l) => l.stockStatus === stock?.deliveryEstimate);

            const updatedDeliveryInterval: DeliveryInterval = {
                stockStatus: useStockStatus(stock?.deliveryEstimate),
                lines: [...(foundStockList?.lines || []), checkoutItemAdapter(curr)],
            };

            return [...filteredStockList, updatedDeliveryInterval];
        }, [] as DeliveryInterval[]);
    }, [orderLines, orderlinesWithSplit, shouldSplitOrder]);

    const notSplitOrderByStockStatus: DeliveryInterval[] = useMemo(() => {
        const sortedLines = orderLines
            ?.map((line) => {
                const newLine = { ...line };
                if (
                    newLine.priceViewModel?.stock?.deliveryEstimate &&
                    newLine.deliveryEstimate !== newLine.priceViewModel.stock.deliveryEstimate
                ) {
                    newLine.priceViewModel.stock.deliveryEstimate = newLine.deliveryEstimate;
                    newLine.priceViewModel.stock.stockAvailable = -1;
                    newLine.priceViewModel.showStock = false;
                }
                return newLine;
            })
            .sort(joinedDeliverySorter);
        return [
            {
                stockStatus:
                    (sortedLines?.[0]?.priceViewModel?.stock?.deliveryEstimate as StockStatus) ||
                    undefined,
                lines: sortedLines?.map(checkoutItemAdapter),
            },
        ];
    }, [orderLines, shouldSplitOrder]);

    return (
        <>
            {(shouldSplitOrder ? splitOrderByStockStatus : notSplitOrderByStockStatus)?.map(
                (deliveryInterval, index) => (
                    <StyledCheckoutAccordion key={`${deliveryInterval.stockStatus}-${index}`}>
                        <Accordion
                            initAsOpen={initAsOpen}
                            icon={<Icon icon="chevronDown" size={'md'} color="grey90" />}
                            header={
                                <StyledCheckoutOrderHeader>
                                    <StyledCheckoutOrderTitle>
                                        {formatString(
                                            translate('checkout-page.delivery-interval-title'),
                                            shouldSplitOrder ? index + 1 : ''
                                        )}
                                    </StyledCheckoutOrderTitle>
                                    <StyledCheckoutStockIndicator>
                                        {deliveryInterval.stockStatus && (
                                            <>
                                                <StockIndicator
                                                    status={deliveryInterval.stockStatus}
                                                />
                                                <DeliveryMessage
                                                    status={deliveryInterval.stockStatus}
                                                />
                                            </>
                                        )}
                                    </StyledCheckoutStockIndicator>
                                </StyledCheckoutOrderHeader>
                            }
                        >
                            <StyledCheckoutList>
                                {deliveryInterval?.lines?.map((line) => (
                                    <li key={line.key}>
                                        <CheckoutLine {...line} />
                                    </li>
                                ))}
                            </StyledCheckoutList>
                        </Accordion>
                    </StyledCheckoutAccordion>
                )
            )}
        </>
    );
};
