import React, { useState, useEffect, FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { motion, Variants } from 'framer-motion';
import { useNavigate } from '$shared/utils/use-navigate';
import { contentVariants, onSamePathVariants, spinnerVariants } from './page-transition.animation';
import { Loading } from '../loading';
import { ifProp } from 'styled-tools';

export const PageTransitionContent: FC<{ children: ReactNode }> = ({ children }) => {
    const [showTransition, setUseTransition] = useState(false);
    const [transitionVariants, setTransitionVariants] = useState<Variants>(contentVariants);
    const { navigateStart, url } = useNavigate();

    // We do not want to transition when navigating under the same path
    // we check the first paths and eject if those are alike
    const getVariants = (previous: string, current: string): Variants => {
        const [, prevPath] = previous.split('/');
        const [, currPath] = current.split('/');

        if (prevPath === currPath) {
            return onSamePathVariants;
        }

        return contentVariants;
    };

    useEffect(() => {
        const variants = getVariants(window.location.pathname, url);
        setTransitionVariants(variants);
        setUseTransition(navigateStart);
    }, [navigateStart]);

    return (
        <>
            <motion.div
                initial="visible"
                animate={showTransition ? 'hidden' : 'visible'}
                variants={transitionVariants}
            >
                {children}
            </motion.div>
            <SpinnerContainer
                initial="hidden"
                animate={showTransition ? 'visible' : 'hidden'}
                variants={spinnerVariants}
            >
                <Loading />
            </SpinnerContainer>
        </>
    );
};

const SpinnerContainer = styled(motion.div)(
    () => ({
        position: 'sticky',
        bottom: '50%',
        left: 0,
        right: 0,
        transform: 'translateY(50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
    }),
    ifProp(
        { animate: 'visible' },
        {
            margin: '50vh 0',
        }
    )
);
