import { ProductPriceAndStockViewModel } from '~/lib/data-contract';
import { cancellableFetch } from '../../shared/utils/request';
import { RETRIEVE_PRICE_AND_STOCK_URL } from './order-endpoints';

const url = RETRIEVE_PRICE_AND_STOCK_URL;
const method = 'POST';
const headers = { 'Content-Type': 'application/json' };

export function retrievePriceAndStock(
    originalItemNumbers: string[],
    signal?: AbortSignal,
    singlePrice = true
) {
    const request = {
        originalItemNumbers,
        singlePrice: singlePrice,
    };

    return cancellableFetch<ProductPriceAndStockViewModel[]>({
        signal,
        url,
        options: {
            method,
            headers,
            body: JSON.stringify(request),
        },
    });
}
