import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { useDropzone, FileWithPath, FileRejection, ErrorCode } from 'react-dropzone';
import { StyledHelpText } from '$shared/components/form/components/help-text';
import { StyledInvalidMessage } from '$shared/components/form/components/invalid-message';
import { Icon } from '$shared/components/icon';
import { formatString, useTranslation } from '$shared/utils';
import {
    FileInputAcceptStyle,
    FileInputBaseStyle,
    FileInputFocusedStyle,
    FileInputRejectStyle,
    StyledAttachedFileIcon,
    StyledFileInputLabel,
    StyledFileList,
    StyledFileListItem,
    StyledFileListItemName,
    StyledFileListItemRemoveButton,
    StyledRejectionListItem,
} from './styled';
import { controllableInputElementsProps } from '$shared/components/form';
import { reducedExtensions } from './helpers/file-input-helpers';

export type FileInputElementProps = controllableInputElementsProps & {
    controlledValue?: FileWithPath[];
    onChange?: (accecptedFiles?: FileWithPath[]) => void;
    isRequired?: boolean;
    allowMultipleFiles?: boolean;
    allowedExtensions?: string[];
    fileSizeMaxMb?: number;
};

const BYTES_IN_A_MEGABYTE = 1000000;

type FileInputElementRef = {
    reset(): void;
};
export const FileInputElement = forwardRef<FileInputElementRef, FileInputElementProps>(
    (
        {
            allowedExtensions,
            allowMultipleFiles,
            controlledValue,
            fileSizeMaxMb,
            helpText,
            invalidMessage,
            isInvalid,
            isRequired,
            label,
            onChange,
        },
        ref
    ) => {
        useImperativeHandle(ref, () => ({ reset }), [ref]);

        const [localStateFiles, setFiles] = useState<FileWithPath[]>([]);
        const [fileRejections, setFileRejections] = useState<FileRejection[]>();
        const files = controlledValue || localStateFiles;

        const onDrop = useCallback(
            (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
                const nextVal = [
                    ...(allowMultipleFiles ? files : acceptedFiles.length ? [] : files),
                    ...acceptedFiles,
                ];
                setFileRejections(fileRejections);
                handleUpdates(nextVal);
            },
            [files]
        );

        const handleUpdates = (newVal: FileWithPath[] = []) => {
            if (onChange) {
                onChange(newVal);
            } else {
                setFiles(newVal);
            }
        };

        const reset = () => {
            setFiles([]);
            setFileRejections([]);
        };

        function getErrorTranslation(code: string) {
            switch (code) {
                case ErrorCode.FileInvalidType:
                    return formatString(
                        translate('forms.fields.fileuploadfield.file-type'),
                        trimAllowedExtensions
                    );
                case ErrorCode.FileTooLarge:
                    return translate('forms.fields.fileuploadfield.large-file');
                case ErrorCode.FileTooSmall:
                    return translate('forms.fields.fileuploadfield.small-file');
                case ErrorCode.TooManyFiles:
                    return translate('forms.fields.fileuploadfield.file-quantity');
            }
        }
        const { translate } = useTranslation();

        const trimAllowedExtensions = allowedExtensions
            ?.map((ex) => ex.replace('.', ''))
            .map((ex) => '.' + ex)
            .join(', ');

        const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
            accept: reducedExtensions(trimAllowedExtensions || 'image/jpeg'), // Means it'll accept images as a default
            multiple: allowMultipleFiles,
            maxSize: fileSizeMaxMb ? fileSizeMaxMb * BYTES_IN_A_MEGABYTE : undefined,
            onDrop,
        });

        const style = useMemo(
            () => ({
                ...FileInputBaseStyle,
                ...(isFocused ? FileInputFocusedStyle : {}),
                ...(isDragAccept ? FileInputAcceptStyle : {}),
                ...(isInvalid || isDragReject ? FileInputRejectStyle : {}),
            }),
            [isFocused, isDragAccept, isDragReject, isInvalid]
        );

        const removeFile = (file: FileWithPath) => () => {
            const nextVal = [...files];
            nextVal.splice(nextVal.indexOf(file), 1);
            handleUpdates(nextVal);
        };
        const filesRender = (
            <StyledFileList>
                {files.map((file) => (
                    <StyledFileListItem key={file.path}>
                        <StyledAttachedFileIcon icon="paperClip" size={13} />
                        <StyledFileListItemName>{file.name}</StyledFileListItemName>
                        <StyledFileListItemRemoveButton
                            onClick={removeFile(file)}
                            iconRight={<Icon icon="close" size={15} />}
                            variant="plain"
                        />
                    </StyledFileListItem>
                ))}
            </StyledFileList>
        );

        return (
            <>
                {helpText ? <StyledHelpText children={helpText} /> : null}
                <section>
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <StyledFileInputLabel required={isRequired}>{label}</StyledFileInputLabel>
                    </div>
                    {invalidMessage && <StyledInvalidMessage children={invalidMessage} />}
                    {filesRender}
                    <ul>
                        {fileRejections?.map((rej, i) => (
                            <StyledRejectionListItem key={rej.file.name + i}>
                                <p>{rej.file.name}</p>
                                <ul>
                                    {rej.errors.map((err, i) => (
                                        <li key={err.code + i}>{getErrorTranslation(err.code)}</li>
                                    ))}
                                </ul>
                            </StyledRejectionListItem>
                        ))}
                    </ul>
                </section>
            </>
        );
    }
);
export default FileInputElement;
