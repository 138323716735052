import { theme } from '$theme';
import styled from '@emotion/styled';

export const StyledSpecificationWrapper = styled.div({
    position: 'relative',
});

export const StyledDownloadWrapper = styled.div({
    position: 'absolute',
    left: 'auto',
    top: '50%',
    marginLeft: theme.space[2],
    transform: 'translateY(-50%)',
    display: 'inline-block',
});

export const StyledDownload = styled.a({
    display: 'inline-flex',
    verticalAlign: 'middle',
});
