import styled from '@emotion/styled';

export const StyledPdpCoreWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[2],
    marginBottom: theme.space[3],
}));

export const StyledCoreDataSubheadline = styled.p(({ theme }) => ({
    textTransform: 'uppercase',
    color: theme.colors.grey90,
    fontSize: theme.fontSizes.md,
}));
