import { Icon } from '$shared/components/icon';
import React, { useMemo } from 'react';
import {
    IN03MainNavigationWithSubItemsPage,
    IN04MainNavigationSubItemPage,
    INavigationTopLevelNodes,
} from '~/lib/data-contract';
import { useFrame } from '~/shared/utils';
import { Drawer } from './components/Drawer';
import { MobileMegaMenu } from './components/MobileMegaMenu';
import { useMobileMenu } from './hooks/useMobileMenu';
import { StyledMenuIconButton } from './styled';
// import { MobileMetaMenu } from './components/MobileMetaMenu';

export type N35MobileMegaMenuProps = {
    /**
     * Set the id of the active node. Overwrides the activeNodeByUrl.
     * NOTE: Only checked on init
     */
    activeNodeId?: string;

    /**
     * Determin the active node by the location url.
     * NOTE: Only checked on init
     */
    activeNodeByUrl?: boolean;
};

/**
 * Mega menu for mobile. This menu renders each step as an menu page.
 */
export const N35MobileMegaMenu = ({
    activeNodeId,
    activeNodeByUrl = true,
}: N35MobileMegaMenuProps) => {
    const { isOpen, close, toggle } = useMobileMenu();
    const { mainMenu } = useFrame();

    type nodeChildren = INavigationTopLevelNodes | IN03MainNavigationWithSubItemsPage;
    const shimPlaceholderLevels = (
        node: nodeChildren,
        OGParent?: nodeChildren
    ): nodeChildren | IN04MainNavigationSubItemPage => {
        // Recursive function that shims away any empty one-child navigation levels
        if (node.children && node.children.length === 1) {
            const childNode = (node.children[0] as nodeChildren) || [];
            if (!node.link?.url || (node.link?.url === childNode.link?.url && childNode.children)) {
                return shimPlaceholderLevels(childNode, OGParent || node);
            }
        }
        return { ...node, link: { ...(OGParent?.link ? OGParent?.link : node.link) } };
    };

    const menu = useMemo(
        () => mainMenu?.navigationTopLevelNodes?.map((n) => shimPlaceholderLevels(n)),
        [mainMenu]
    );

    return (
        <>
            <StyledMenuIconButton
                variant="plain"
                iconLeft={<Icon icon="mobileMenu" size="md" />}
                onClick={toggle}
            />
            <Drawer open={isOpen} onClose={() => close()}>
                {isOpen && (
                    <MobileMegaMenu
                        menu={menu || []}
                        activeNodeByUrl={activeNodeByUrl}
                        activeNodeId={activeNodeId}
                    />
                )}
                {/* {!!metaMenu?.length && <MobileMetaMenu menu={metaMenu} />} TODO - WE need METAmenu data from BE */}
            </Drawer>
        </>
    );
};
