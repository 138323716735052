/* eslint-disable @typescript-eslint/no-empty-function */
import { useTranslation } from '$shared/utils';
import React, { FC, InputHTMLAttributes, useCallback, useRef, useState } from 'react';

import {
    StyledCancelButton,
    StyledCancelIcon,
    StyledSearchBarIcon,
    StyledSearchBarInput,
    StyledSearchBarWrapper,
    StyledSpinner,
} from './style';

type SearchInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    className?: string;
    isBusy?: boolean;
    onChange?: (value: string) => void;
};

export const SearchInput: FC<SearchInputProps> = ({
    className,
    isBusy = false,
    onChange = () => {},
    ...inputProps
}) => {
    const [show, setShow] = useState<boolean>(true);
    const { translate } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);

    const clearInput = useCallback(() => {
        onChange('');
        inputRef.current?.focus();
    }, [onChange, inputRef]);

    const hideMobileKeyboardOnReturn = (keyboardEvent: React.KeyboardEvent<HTMLInputElement>) => {
        const key = keyboardEvent.code || keyboardEvent.keyCode;
        if ((inputRef && key === 'Enter') || key === 13) {
            inputRef.current?.blur();

            // BUT in cases wrapped in Radix.dialog, we need the below "remount":
            setShow(false);
            setTimeout(() => setShow(true), 0);
        }
    };

    return (
        <StyledSearchBarWrapper className={className}>
            <StyledSearchBarIcon icon="search" size={30} />

            {show && (
                <StyledSearchBarInput
                    {...inputProps}
                    aria-busy={isBusy}
                    inputMode="search"
                    onChange={(e) => onChange(e.currentTarget.value)}
                    onKeyUp={hideMobileKeyboardOnReturn}
                    ref={inputRef}
                />
            )}
            {isBusy && <StyledSpinner />}

            {!isBusy && inputProps.value && (
                <StyledCancelButton
                    aria-label={translate('search.search-input-field.clear-field')}
                    onClick={() => clearInput()}
                >
                    <StyledCancelIcon icon="close" />
                </StyledCancelButton>
            )}
        </StyledSearchBarWrapper>
    );
};
