import { IParamsOfArrays } from '..';

// QueryParam variables
const KEY_SEPARATOR = '___';
const KEY_VAL_SEPARATOR = '--';
const VAL_SEPARATOR = '..';

export const useFilterQueryParams = () => {
    function paramsToString(params: IParamsOfArrays) {
        return Object.entries(params)?.reduce(
            (acc, [key, values]) =>
                acc +
                (acc ? KEY_SEPARATOR : '') +
                `${key}${KEY_VAL_SEPARATOR}${values?.reduce(
                    (acc, value) => acc + (acc ? VAL_SEPARATOR : '') + value,
                    ''
                )}`,
            ''
        );
    }

    function stringToParams(paramstring?: string): IParamsOfArrays | undefined {
        return paramstring?.split(KEY_SEPARATOR)?.reduce((acc, curr) => {
            const [facetKey, facetValueString] = curr?.split(KEY_VAL_SEPARATOR);
            const facetValues = facetValueString?.split(VAL_SEPARATOR);
            return { ...acc, [facetKey]: facetValues };
        }, {});
    }

    return { paramsToString, stringToParams };
};
