import { useFrame } from './../../../shared/utils/frame/hooks/use-frame';
import { useKyoceraRouter } from './../../../shared/hooks/useKyoceraRouter/useKyoceraRouter';
import { cancellableFetch, useTranslation } from '$shared/utils';
import { CREATE_ORDER_URL } from '$features/order-service/order-endpoints';
import { CreateOrderResponseViewModel } from '$order-models/*';
import { useState } from 'react';
import { useOrderStore } from './use-order-store';
import { useNotification } from '$features/notifications/hooks';
import { useReceiptStore } from './use-receipt-store';
import { useResetBasket } from '$shared/hooks/useBasket/useBasket';
import { useCheckoutStepsState } from './useCheckoutStepsState';
import { useQueryClient } from '@tanstack/react-query';
import { createQuoteQueryKey } from './use-create-quote';

export function useCreateOrder() {
    const queryClient = useQueryClient();
    const { push: pushNotification } = useNotification();
    const { push: routerPush } = useKyoceraRouter();
    const { receiptPage } = useFrame();
    const { quote } = useOrderStore();
    const { orderLines } = quote || {};
    const { createQuoteRequest, resetOrderStore } = useOrderStore();
    const request = createQuoteRequest();
    const { setReceipt } = useReceiptStore();
    const { resetAllBasketState } = useResetBasket();
    const { resetState: resetCheckoutStepState } = useCheckoutStepsState();
    const { translate } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const resetCheckout = () => {
        resetCheckoutStepState();
        resetOrderStore();
        resetAllBasketState();
        queryClient.removeQueries({ queryKey: [createQuoteQueryKey], exact: false });
    };

    const submitOrder = async () => {
        setIsLoading(true);
        try {
            const response = await cancellableFetch<CreateOrderResponseViewModel>({
                url: CREATE_ORDER_URL,
                options: {
                    method: 'POST',
                    body: JSON.stringify(request),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            });

            setReceipt?.(response, orderLines || []);
            if (receiptPage?.url) {
                routerPush(receiptPage?.url);
                resetCheckout();
            }
        } catch {
            pushNotification({
                severity: 'error',
                text: translate('checkout-page.notifications.create-order-failed'),
            });
        } finally {
            setIsLoading(false);
        }
    };

    return {
        submitOrder,
        isLoading,
    };
}
