import React, { FC } from 'react';
import { AppearOnHover, AppearOnHoverProps } from '../generic/appear-on-hover';
import {
    StyledToolTip,
    StyledToolTipText,
    StyledTooltipArrow,
    StyledToolTipWrapper,
    StyledToolTipIcon,
} from './style';

//  @example
// <ToolTip content={<AnyCoolComponent />} position="right" arrow="true">
//     <p>Hover here for tool tips</p>
//  </ToolTip>;

type toolTipProps = {
    allowLineBreak?: boolean;
    showDefaultTooltipIcon?: boolean;
};

export const ToolTip: FC<AppearOnHoverProps & toolTipProps> = ({
    content,
    children,
    position,
    arrow,
    allowLineBreak,
    showDefaultTooltipIcon,
}) => {
    return (
        <AppearOnHover
            content={
                <>
                    {arrow && <StyledTooltipArrow width={13} height={9} />}
                    <StyledToolTip>
                        <StyledToolTipText allowLineBreak={allowLineBreak}>
                            {content}
                        </StyledToolTipText>
                    </StyledToolTip>
                </>
            }
            position={position}
        >
            <StyledToolTipWrapper>
                <>
                    {children || <StyledToolTipIcon />}
                    {children && showDefaultTooltipIcon ? <StyledToolTipIcon /> : null}
                </>
            </StyledToolTipWrapper>
        </AppearOnHover>
    );
};
