import { theme } from '$theme';
import styled from '@emotion/styled';
import React, { FC } from 'react';

export type TagProps = {
    text: string;
};

export const StyledTag = styled.span({
    padding: '0.5em',
    display: 'inline-block',
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,

    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    textTransform: 'uppercase',
    marginTop: '0.5em',
    marginBottom: '0.5em',
});

export const Tag: FC<TagProps> = ({ text, ...props }) => {
    return <StyledTag {...props}>{text}</StyledTag>;
};

export default Tag;
