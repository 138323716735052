import React from 'react';
import { IModules } from '~/lib/data-contract';
import { DynamicBlock } from '../DynamicBlock';

type Props = {
    elements?: IModules[];
};

export const DynamicBlockList = ({ elements }: Props) => {
    if (!elements?.length) {
        return null;
    }
    return (
        <>
            {elements.map((element) => (
                <DynamicBlock key={element.id} {...element} />
            ))}
        </>
    );
};
