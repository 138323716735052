import { useTranslation } from '$shared/utils';
import {
    HorizontalAlignment,
    IM21CategoriesModule,
    IM21CategoryDetailItem,
    IM21CategoryListItem,
    IM21Items,
    M21ItemStyle,
    VerticalAlignment,
} from '~/lib/data-contract';
import { M21GridItemProps } from './components/m21-grid-item/m21-grid-item';
import M21 from './m21-grid';

type M21Item = IM21CategoryDetailItem | IM21CategoryListItem;

const M21ItemMapper = (item: M21Item): M21GridItemProps => {
    const { translate } = useTranslation();

    return {
        headline: {
            tag: 'h5',
            style: 'h4',
            text: item.title || '',
            hideOnMobile: false,
        },
        text: {
            html: item.description || '',
        },
        horizontalAlignment: HorizontalAlignment.center,
        verticalAlignment: VerticalAlignment.center,
        callToActions: item.link
            ? [
                  {
                      ...item.link,
                      text:
                          item.itemStyle === M21ItemStyle.contentLayout
                              ? translate('category-list.item-button-text')
                              : translate('product-category-list.button-text'),
                  },
              ]
            : [],
        link: item.link,
        itemStyle: item.itemStyle,
        commerceImage: item.commerceImage,
        overwriteImage: item.overwriteImage,
        productMaterial: 'productMaterials' in item ? item.productMaterials : [],
    };
};

const ItemMapper = (item: IM21Items): M21GridItemProps => {
    function isM21Item(item: IM21Items): item is M21Item {
        return (
            (item as M21Item).type === 'm21CategoryDetailItem' ||
            (item as M21Item).type === 'm21CategoryListItem'
        );
    }

    return isM21Item(item)
        ? M21ItemMapper(item)
        : {
              ...item,
              link:
                  item.callToActions?.length && item.callToActions?.length == 1
                      ? item.callToActions?.[0]
                      : undefined,
          };
};

export const M21AnchorId = 'm21'; // Used for/by navigation on mobile KYO-1106
export const M21GridBlock = ({ headline, items, id, buttonText }: IM21CategoriesModule) => {
    return (
        <M21
            headline={headline?.text}
            headlineTag={headline?.tag}
            headlineStyleAs={headline?.style}
            headlineHidden={headline?.hideOnMobile}
            buttonText={buttonText}
            items={items?.map(ItemMapper)}
            columns={4}
            id={id}
        />
    );
};
