import React, { FC } from 'react';
import { AnimationProps, motion } from 'framer-motion';
import { HighlightDescription, StyledCategory } from './search-result-styles';
import { useEnrichedCategories } from './use-category-meta';
import { formatString, useTranslation } from '$shared/utils';
import { HighlightWord } from '$shared/components/highlight-word';
import { ISearchResultItemViewModel } from '~/lib/data-contract';

interface SearchResultCategoriesProps {
    animation: AnimationProps;
    categories: ISearchResultItemViewModel[];
    highlightMatch: string;
}

export const SearchResultCategories: FC<SearchResultCategoriesProps> = (props) => {
    const { translate } = useTranslation();
    const { enrichedCategories } = useEnrichedCategories(props.categories);

    return (
        <>
            {enrichedCategories.map((category) => (
                <motion.li {...props.animation} key={category.id}>
                    <StyledCategory href={category.link.url}>
                        <HighlightWord input={category.title} match={props.highlightMatch} />
                        {!!category.parentTitle && (
                            <HighlightDescription
                                input={`${formatString(
                                    translate('search.search-bar.in-category'),
                                    category.parentTitle
                                )}`}
                                match={''}
                            />
                        )}
                    </StyledCategory>
                </motion.li>
            ))}
        </>
    );
};
