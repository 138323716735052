import React, { useMemo } from 'react';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import {
    BackArrow,
    CheckoutHeader,
    ConfirmOrderStep,
    DeliveryStep,
    InformationStep,
    useCheckoutSteps,
} from '$features/checkout';
import { CHECKOUT_STEP } from '$features/checkout/models';
import { IP310CheckoutPage } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';
import { StyledCheckoutMainArea } from './style';
import { StyledModulesWrapper } from '../P60ModulePage/style';

export const P310CheckoutPage = ({ pageElements, companiesWithPickup }: IP310CheckoutPage) => {
    const { steps, activeStep, goToPreviousStep } = useCheckoutSteps();
    const { back } = useKyoceraRouter();

    const onBack = steps[0] === activeStep ? back : goToPreviousStep;

    const step = useMemo(() => {
        switch (activeStep) {
            case CHECKOUT_STEP.INFORMATION:
                return <InformationStep />;
            case CHECKOUT_STEP.DELIVERY:
                return <DeliveryStep companiesWithPickup={companiesWithPickup} />;
            case CHECKOUT_STEP.CONFIRM:
                return <ConfirmOrderStep />;
        }
    }, [activeStep]);

    return (
        <>
            <CheckoutHeader />
            <StyledCheckoutMainArea>
                <BackArrow onBack={onBack} />
                {step}
                <StyledModulesWrapper>
                    <DynamicBlockList elements={pageElements} />
                </StyledModulesWrapper>
            </StyledCheckoutMainArea>
        </>
    );
};
