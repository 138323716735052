import { FC } from 'react';
import styled from '@emotion/styled';
import { basketSummaryGrid } from './basket-summary-styles';
import { useTranslation } from '$shared/utils';
import { Skeleton } from '../skeleton';

interface BasketSummaryTotalLineProps {
    className?: string;
    value: string;
    isLoading?: boolean;
}

const StyledBasketSummaryTotalLine = styled.li(({ theme }) => ({
    ...basketSummaryGrid,
    borderTop: `1px solid ${theme.colors.grey50}`,
    fontWeight: 600,
    marginTop: theme.space[1],
    paddingTop: theme.space[3],
}));

export const BasketSummaryTotalLine: FC<BasketSummaryTotalLineProps> = (props) => {
    const { translate } = useTranslation();
    return (
        <StyledBasketSummaryTotalLine className={props.className}>
            <span children={translate('checkout-page.basket-summary.total')} />
            {props.isLoading ? (
                <Skeleton pulsating height={'1.2em'} width={'100px'} />
            ) : (
                <span children={props.value} />
            )}
        </StyledBasketSummaryTotalLine>
    );
};
