import React from 'react';
import {ModuleContainer} from '../module-container';
import {Newsletter, NewsletterProps} from "$shared/components/newsletter";

export type M130Props = NewsletterProps & { id?: string };

export const M130 = ({ id, apsisFormId }: M130Props) => {
    if (!apsisFormId) {
        return null;
    }

    return (
        <ModuleContainer id={id}>
            <Newsletter apsisFormId={apsisFormId}/>
        </ModuleContainer>
    );
};