import { breakpoints } from '$theme';
import styled from '@emotion/styled';

const twoColumnBreakpoint = breakpoints.md;

export const StyledSelectorTab = styled.div(() => ({
    [twoColumnBreakpoint]: {
        alignItems: 'flex-start',
        display: 'grid',
        grid: 'auto / 19em 1fr',
    },
}));

export const StyledSelectorTabSelection = styled.div(({ theme }) => ({
    borderTop: `1px solid ${theme.colors.grey40}`,
    lineHeight: theme.lineHeights.summary,
    marginTop: theme.space[3],
    paddingTop: theme.space[3],

    [twoColumnBreakpoint]: {
        borderLeft: `1px solid ${theme.colors.grey40}`,
        borderTop: `none`,
        marginLeft: theme.space[5],
        marginTop: 0,
        paddingBottom: theme.space[1],
        paddingLeft: theme.space[5],
        paddingTop: 0,
    },
}));
