import React, { FC, useEffect, useState } from 'react';
import { DeprecatedDropdown } from '$shared/components/deprecatedDropdown';
import { RawHtml } from '$shared/components/raw-html';
import {
    buildURL,
    cancellableFetch,
    formatString,
    setCookieItem,
    useTranslation,
} from '$shared/utils';
import { useFrame } from '$shared/utils/frame/hooks/use-frame';
import { useMarket } from '$shared/utils/market/hooks/useMarket';
import { motion } from 'framer-motion';
import {
    LangSelector,
    BackgroundDiv,
    TextDiv,
    StyledFlex,
    StyledCloseIcon,
    StyledCancelButton,
    StyledSelectors,
    StyledContentWrapper,
    StyledContinueButton,
    StyledLanguageSelectorContainer,
} from './style';
import { IMarketSelectorLanguage, IMarketSelectorMarket } from '~/lib/data-contract';
import { queryMarketBannerData } from '$shared/utils/market/hooks/queryMarketBannerData';
import { marketKeys } from '$shared/utils/market/model/marketModel';
import { MARKET_GET_RELATIVE_URL } from '$shared/utils/market/service/endpoints';
import {
    getCultureInfo,
    getCultureInfoLink,
} from '$shared/utils/market/helpers/culture-info.helper';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';

export const LanguageSelector: FC = () => {
    const { setIsOpen } = useMarket();
    const { data: marketBanner } = queryMarketBannerData() || {};
    const { marketSelector } = useFrame();
    const { asPath } = useKyoceraRouter();

    const [selectedMarketIndex, setSelectedMarketIndex] = useState<number | null>(null);
    const [selectedLanguageIndex, setSelectedLanguageIndex] = useState<number | null>(null);

    const { translate } = useTranslation();

    const selectedLanguage = () => {
        return selectedMarketIndex != undefined &&
            marketSelector &&
            selectedLanguageIndex != undefined
            ? marketSelector.markets[selectedMarketIndex].languages[selectedLanguageIndex]
            : null;
    };
    const selectedMarket = () => {
        return selectedMarketIndex != undefined && marketSelector
            ? marketSelector.markets[selectedMarketIndex]
            : null;
    };
    const marketDisplayName = (selector_market: IMarketSelectorMarket | null) => {
        return selector_market?.label;
    };
    const marketAbbreviation = (selector_market: IMarketSelectorMarket | null) => {
        return selector_market?.id;
    };
    const languageDisplayName = (selector_culture: IMarketSelectorLanguage | null) => {
        return selector_culture?.text || '';
    };
    const languageAbbreviation = (selector_culture: IMarketSelectorLanguage | null) => {
        return selector_culture?.culture;
    };

    const saveChoices = async () => {
        const newCulture = languageAbbreviation(selectedLanguage());
        const newMarket = marketAbbreviation(selectedMarket());
        setIsOpen(false);
        if (newCulture && newMarket) {
            sessionStorage.setItem(marketKeys.marketChosen, marketKeys.marketIsChosen);

            // Save the new culture and market in cookies
            setCookieItem(marketKeys.cultureSelectedByUser, newCulture);
            setCookieItem(marketKeys.marketSelectedByUser, newMarket);

            const { url } = getCultureInfo(asPath);
            const serializedUrl = buildURL(MARKET_GET_RELATIVE_URL, {
                relativeUrl: url,
            });

            if (location && serializedUrl) {
                const nextUrl = await cancellableFetch<string>({
                    url: serializedUrl,
                    options: {
                        headers: {
                            CultureInfo: `${newCulture}-${newMarket}`,
                        },
                    },
                });
                location.href = getCultureInfoLink(nextUrl, newCulture, newMarket);
            }
        }
    };

    useEffect(() => {
        if (marketSelector && marketBanner) {
            for (let i = 0; i < marketSelector.markets.length; i++) {
                if (
                    marketAbbreviation(marketSelector.markets[i]) ==
                    marketBanner?.marketLink?.marketId
                ) {
                    setSelectedMarketIndex(i);
                    for (let j = 0; j < marketSelector.markets[i].languages.length; j++) {
                        if (
                            languageAbbreviation(marketSelector.markets[i].languages[j]) ==
                            marketBanner?.marketLink?.culture
                        ) {
                            setSelectedLanguageIndex(j);
                        }
                    }
                }
            }
        }
    }, [marketBanner]);

    const framer_container = {
        hidden: {
            opacity: 0,
            y: -100,
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'spring',
                bounce: 0,
                duration: 0.3,
            },
        },
    };

    const close_button = {
        hidden: {
            opacity: 0,
        },
        show: {
            opacity: 1,
            transition: {
                delay: 0.4,
                duration: 0.2,
            },
        },
    };

    return (
        <>
            <motion.div
                key="languageSelector"
                variants={framer_container}
                initial="hidden"
                animate="show"
            >
                <BackgroundDiv>
                    <StyledLanguageSelectorContainer>
                        <StyledContentWrapper>
                            <TextDiv>
                                <RawHtml
                                    html={
                                        marketBanner?.marketLink?.text
                                            ? formatString(
                                                  translate('market.banner.text'),
                                                  marketBanner?.marketLink?.text
                                              )
                                            : translate(
                                                  'market.banner.market-selector-instructions'
                                              )
                                    }
                                />
                                <StyledCancelButton
                                    onClick={() => {
                                        setIsOpen(false);
                                        sessionStorage.setItem(
                                            marketKeys.marketChosen,
                                            marketKeys.marketIsChosen
                                        );
                                    }}
                                >
                                    <motion.span
                                        key="closeButton"
                                        variants={close_button}
                                        initial="hidden"
                                        animate="show"
                                    >
                                        <StyledCloseIcon icon={'close'}></StyledCloseIcon>
                                    </motion.span>
                                </StyledCancelButton>
                            </TextDiv>
                            <StyledFlex>
                                <StyledSelectors>
                                    <LangSelector>
                                        {marketSelector ? (
                                            <DeprecatedDropdown
                                                label={translate(
                                                    'market.banner.market-selector-label'
                                                )}
                                                placeholder={translate(
                                                    'market.banner.market-selector-placeholder-text'
                                                )}
                                                options={marketSelector.markets.map(
                                                    (location, i) => {
                                                        const marketName = marketDisplayName(
                                                            location
                                                        );
                                                        return {
                                                            value: marketName || '',
                                                            index: i,
                                                        };
                                                    }
                                                )}
                                                value={marketDisplayName(selectedMarket()) || ''}
                                                onSelection={(option) => {
                                                    option?.index != undefined
                                                        ? setSelectedMarketIndex(option?.index)
                                                        : null;
                                                    setSelectedLanguageIndex(0);
                                                }}
                                            ></DeprecatedDropdown>
                                        ) : null}
                                    </LangSelector>
                                    <LangSelector>
                                        <DeprecatedDropdown
                                            label={translate(
                                                'market.banner.language-selector-label'
                                            )}
                                            placeholder={translate(
                                                'market.banner.language-selector-placeholder-text'
                                            )}
                                            options={
                                                selectedMarketIndex != undefined && marketSelector
                                                    ? marketSelector.markets?.[
                                                          selectedMarketIndex
                                                      ].languages.map((language, i) => {
                                                          return {
                                                              value:
                                                                  languageDisplayName(language) ||
                                                                  '',
                                                              index: i,
                                                          };
                                                      })
                                                    : []
                                            }
                                            value={languageDisplayName(selectedLanguage())}
                                            onSelection={(option) => {
                                                option?.index != undefined
                                                    ? setSelectedLanguageIndex(option?.index)
                                                    : null;
                                            }}
                                        ></DeprecatedDropdown>
                                    </LangSelector>
                                </StyledSelectors>
                                <StyledContinueButton variant="primary" onClick={saveChoices}>
                                    {translate('market.banner.buttontext')}
                                </StyledContinueButton>
                            </StyledFlex>
                        </StyledContentWrapper>
                    </StyledLanguageSelectorContainer>
                </BackgroundDiv>
            </motion.div>
        </>
    );
};
export default LanguageSelector;
