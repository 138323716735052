import styled from '@emotion/styled';
import Headline, { HeadlineProps } from '$shared/components/headline';
import Link from '$shared/components/link';
import { ifProp } from 'styled-tools';
import { theme } from '$theme';
import { Icon } from '$shared/components/icon';
import Button from '$shared/components/button';

type StyledMainNavHeadline = HeadlineProps & {
    isActive?: boolean;
    renderAsActive?: boolean;
};
export const StyledHeadline = styled(Headline)<StyledMainNavHeadline>(
    { textTransform: 'uppercase' },
    ifProp(
        ({ isActive, renderAsActive }: StyledMainNavHeadline) => isActive || renderAsActive,
        ({ theme }) => ({
            color: `${theme.colors.blue}`,
        })
    )
);

export const StyledNavLink = styled(Link)(({ theme }) => ({
    display: 'inline-block',
    textDecoration: 'none',
    width: '100%',
    padding: `${theme.space[3]} 0`,
    wordBreak: 'break-word',
    ':hover': {
        [`${StyledHeadline}`]: {
            color: `${theme.colors.blue}`,
        },
    },
}));

export const StyledNavItemIcon = styled(Icon)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    transition: '0.1s ease-in-out',
}));

export const StyledNavItemButton = styled(Button)<{ isActive: boolean }>(
    () => ({
        alignSelf: 'stretch',
        border: 'none',
        ':hover': {
            [`${StyledNavItemIcon}`]: {
                path: {
                    fill: `${theme.colors.blue}`,
                },
            },
        },
        ':focus': {
            border: 'none',
            outline: 'none',
        },
        ':active': {
            border: 'none',
        },
    }),
    ifProp('isActive', {
        [`${StyledNavItemIcon}`]: {
            transform: 'rotate(180deg)',
        },
    })
);

export const StyledNavItem = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.borderColor}`,
});
