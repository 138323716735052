import { theme } from '$theme';
import { CSSObject, keyframes } from '@emotion/react';

const shimmerAnimation = keyframes({
    '100%': { maskPosition: 'left' },
});

/**
 * Adds "shimmer" effect, can be used to indicate a loading state
 */
export const shimmer: CSSObject = {
    backgroundColor: theme.colors.grey40,
    '@supports(-webkit-mask-image: url(#mask)) or (mask-image: url(#mask))': {
        mask: 'linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%',
        animation: `${shimmerAnimation} 2s infinite`,
    },
};
