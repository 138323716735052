import React, { FC } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { IDateFormElement } from '~/lib/data-contract';
import { DateSelector } from '$shared/components/form';

export type DateEpiFieldProps = IDateFormElement & {
    rules?: RegisterOptions;
};
export const DateEpiField: FC<DateEpiFieldProps> = ({
    name = '',
    label,
    rules,
    tooltipText,
    placeholder,
    showDate,
    showTimestamp,
    timeZoneInfoText,
    dateSettings,
    timeSettings,
}) => {
    return (
        <DateSelector
            name={name}
            rules={rules}
            label={label}
            placeholderText={placeholder}
            showTimeSelect={showTimestamp}
            showTimeSelectOnly={showTimestamp && !showDate}
            weekdays={dateSettings?.weekdays}
            firstSelectable={dateSettings?.firstSelectable}
            timeSelectMinTimeHour={timeSettings?.startHour}
            timeSelectMinTimeMinute={timeSettings?.startMinute}
            timeSelectMaxTimeHour={timeSettings?.endHour}
            timeSelectMaxTimeMinute={timeSettings?.endMinute}
            timeSelectMinuteInterval={timeSettings?.timeIncrementInMinutes}
            InfoText={timeZoneInfoText}
            helpText={tooltipText}
        />
    );
};

export default DateEpiField;
