import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { ModuleContainerProps } from './module-container';

export const StyledModule = styled.div<ModuleContainerProps>(
    ({ theme, noSpacing }) => ({
        maxWidth: theme.sizes.pageMaxWidth,
        margin: '0 auto',

        paddingTop: noSpacing ? 0 : theme.global.moduleBottomSpacingMobile,
        [breakpoints.sm]: {
            paddingTop: noSpacing ? 0 : theme.global.moduleBottomSpacing,
        },
    }),
    ifProp('noOverflow', () => ({
        overflow: 'hidden',
    })),
    ifProp('hasGutter', ({ theme }) => ({
        [`@media (max-width: ${theme.sizes.contentMaxWidth})`]: {
            paddingLeft: 25,
            paddingRight: 25,
        },
    }))
);
