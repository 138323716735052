import { PriceQuantityViewModel } from '$order-models/*';
import { QuantitySpinnerValue } from '$shared/components/quantity-spinner/quantity-spinner';
import { useTranslation } from '$shared/utils';
import * as React from 'react';
import { FC } from 'react';
import {
    QuantityDiscountPopoverItem,
    QuantityDiscountPopoverWrapper,
    QuantityDiscountPopoverTitle,
    QuantityDiscountPopoverValues,
    StyledPriceHoverValue,
    StyledLabelAndPriceText,
    StyledPriceHoverLabel,
    DiscountLabelAndGrossPrice,
} from './style';

export type QuantityDiscountPopoverProps = {
    prices?: PriceQuantityViewModel[];
    unit?: string;
    minQuantity?: number;
    handleSelect?(value: QuantitySpinnerValue): void;
};

export const QuantityDiscountPopover: FC<QuantityDiscountPopoverProps> = ({
    prices = [],
    unit = '',
    minQuantity,
    handleSelect,
}) => {
    const { translate } = useTranslation();

    if (prices.length === 1 && (!prices[0].discountLabel || prices[0].discountLabel.length === 0)) {
        return null;
    }

    return (
        <QuantityDiscountPopoverWrapper>
            <QuantityDiscountPopoverTitle children={translate('prices.discount')} />
            {prices.map((discount, index) => (
                <QuantityDiscountPopoverItem
                    key={`popoverItem-${index}`}
                    onClick={() =>
                        handleSelect?.({
                            isValid: true,
                            parsedValue: discount.quantity || 0,
                            value: `${discount.quantity}`,
                        })
                    }
                    disabled={minQuantity ? (discount.quantity || 0) < minQuantity : false}
                >
                    {prices.length > 1 ? (
                        <QuantityDiscountPopoverValues>
                            <div>
                                {discount.quantity} {unit}{' '}
                            </div>
                            <div>
                                {discount.prettyNetPrice} / {unit}
                            </div>
                        </QuantityDiscountPopoverValues>
                    ) : null}
                    {discount.discountLabel && discount.discountLabel.length > 0 ? (
                        <DiscountLabelAndGrossPrice>
                            <StyledLabelAndPriceText>
                                <StyledPriceHoverLabel>
                                    {translate('shared.basketline.discount-hover-label')}:
                                </StyledPriceHoverLabel>{' '}
                                <StyledPriceHoverValue>
                                    {discount.discountLabel}
                                </StyledPriceHoverValue>
                            </StyledLabelAndPriceText>

                            <StyledLabelAndPriceText>
                                <StyledPriceHoverLabel>
                                    {translate('shared.basketline.gross-price-hover-label')}:
                                </StyledPriceHoverLabel>{' '}
                                <StyledPriceHoverValue>
                                    {discount.prettyGrossPrice}
                                </StyledPriceHoverValue>
                            </StyledLabelAndPriceText>
                        </DiscountLabelAndGrossPrice>
                    ) : null}
                </QuantityDiscountPopoverItem>
            ))}
        </QuantityDiscountPopoverWrapper>
    );
};
