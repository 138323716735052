import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from '$shared/utils';
import {
    Option,
    SelectorElement,
} from '$shared/components/form/components/controllable-elements/selector-element';
import { useUserStore } from './use-user-store';
import { StyledActOnBehalfLabel } from './act-on-behalf-styles';
import Divider from '$shared/components/divider';
import { ACT_ON_BEHALF_KEY, ACT_ON_BEHALF_MARKET_KEY } from './act-on-behalf-constants';
import { usePage } from '$templates/pages';

export const ActOnBehalf: FC = () => {
    const { translate } = useTranslation();
    const { actOnBehalfOfCustomer, user } = useUserStore();
    const { market, culture } = usePage();

    const mappedOptions = useMemo(
        () =>
            (user?.actOnBehalfOn || []).reduce((a, v) => {
                if (v.name && v.companyNumber) {
                    a.push({
                        label: v.name,
                        checked:
                            !!actOnBehalfOfCustomer && v.companyNumber === actOnBehalfOfCustomer,
                        value: v.companyNumber,
                    });
                }

                return a;
            }, new Array<Option<string>>()),
        [actOnBehalfOfCustomer, user?.actOnBehalfOn]
    );

    const onChange = useCallback(
        (selection: string) => {
            if (!location || actOnBehalfOfCustomer === selection) {
                return;
            }

            const actOnBehalf = user?.actOnBehalfOn?.find(
                (actUser) => actUser.companyNumber === selection
            );

            const impersonationUrl = `/${culture}-${market}?${ACT_ON_BEHALF_KEY}=${encodeURIComponent(
                selection
            )}${
                actOnBehalf
                    ? `&${ACT_ON_BEHALF_MARKET_KEY}=${encodeURIComponent(
                          actOnBehalf?.customerMarket || ''
                      )}`
                    : ''
            }`;

            location.href = impersonationUrl;
        },
        [actOnBehalfOfCustomer]
    );

    return mappedOptions.length ? (
        <>
            <StyledActOnBehalfLabel
                children={translate('authentication.act-on-behalf.customer-number-select-label')}
                htmlFor="act-on-behalf-select"
            />
            <SelectorElement
                id="act-on-behalf-select"
                options={mappedOptions}
                placeholder={translate(
                    'authentication.act-on-behalf.customer-number-select-placeholder-text'
                )}
                onChange={(option) => onChange((option as Option<string>).value)}
            />
            <Divider />
        </>
    ) : null;
};
