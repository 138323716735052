import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';

type StyledInputProps = {
    withLabel: boolean;
    isActive: boolean;
};

type StyledLabelProps = {
    hasFocus: boolean;
    isActive: boolean;
    isValid: boolean;
};

type StyledInputFieldWrapperProps = {
    isValid: boolean;
};

export const StyledInput = styled.input<StyledInputProps>(
    ({ theme }) => ({
        outline: 0,
        border: 'none',
        width: '100%',
        boxSizing: 'border-box',
        textAlign: 'left',
        display: 'block',
        padding: `${theme.space[3]} 5px ${theme.space[3]} ${theme.space[4]}`,
        fontSize: theme.fontSizes.md,
        '&::placeholder': {
            transition: '0.1s color',
            color: theme.colors.grey50,
        },
    }),
    ifProp('withLabel', ({ theme }) => ({
        padding: `25px 5px 5px ${theme.space[4]}`,
    })),
    ifProp(
        { withLabel: true, isActive: false },
        {
            [`&::placeholder`]: {
                color: 'rgba(255, 255, 255, 0)',
            },
        }
    )
);

export const StyledLabel = styled.label<StyledLabelProps>(
    ({ theme, hasFocus }) => ({
        position: 'absolute',
        top: '50%',
        left: theme.space[4],
        fontSize: theme.fontSizes['md'],
        transition: '0.1s transform, 0.1s width',
        color: hasFocus ? theme.colors.primary : theme.colors.grey50,
        transform: 'translateY(-50%)',
        width: `calc(100% - ${theme.space[4]} - ${theme.space[4]})`,
        height: theme.space[4],
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        transformOrigin: 'left top',
        pointerEvents: 'none',
        zIndex: 2,
    }),
    ifNotProp('isValid', ({ theme }) => ({
        color: theme.colors.negative,
    })),
    ifProp('isActive', {
        transform: 'scale(0.75) translateY(-20px)',
        width: '110%',
    })
);

export const StyledHelpText = styled.div(({ theme }) => ({
    fontSize: theme.fontSizes['2xs'],
    color: theme.colors.grey70,
    marginBottom: theme.space[2],
}));

export const StyledInvalidMessage = styled(StyledHelpText)(({ theme }) => ({
    color: theme.colors.negative,
    [`+ ${StyledHelpText}`]: {
        display: 'none',
    },
}));

export const StyledInputField = styled.div(({ theme }) => ({
    height: 54,
    backgroundColor: theme.colors.white,
    border: `2px solid ${theme.colors.grey40}`,
    lineHeight: theme.lineHeights.single,
    position: 'relative',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr',
    cursor: 'type',
}));

export const StyledInputFieldWrapper = styled.div<StyledInputFieldWrapperProps>(
    ({ theme, isValid }) => ({
        position: 'relative',
        [`&:active ${StyledLabel}`]: {
            color: isValid ? theme.colors.primary : theme.colors.negative,
        },
    })
);

export const StyledAdditionalContent = styled.span(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.space[4],
    fontSize: theme.fontSizes.xs,
    pointerEvents: 'none',

    svg: {
        overflow: 'visible',
    },
}));
