import React from 'react';
import { IM230IPaperModule } from '~/lib/data-contract';
import M75 from '../m75-media-text/m75-media-text';
import M230IPaper from './m230-ipaper';

export const M230IPaperBlock = ({
    headline,
    text,
    iframe,
    backgroundColor,
    textColor,
    horizontalAlignment,
    verticalAlignment,
    id,
}: IM230IPaperModule) => {
    return (
        <M75
            headline={headline?.text}
            headlineTag={headline?.tag}
            headlineStyleAs={headline?.style}
            textHtml={text?.html}
            contentAlignment={horizontalAlignment}
            contentPosition={verticalAlignment}
            mediaAlignment={iframe.iFrameHorizontalAlignment}
            backgroundColor={backgroundColor}
            textColor={textColor}
            id={id}
            iFrameMedia={<M230IPaper {...iframe} />}
        />
    );
};
