import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { StyledMediaTextLink } from './variants/default/style';
export const StyledMediaTextItemWrapper = styled.div(() => ({
    // Shared styles all both item types of m21
    minHeight: 92,

    ':not(:last-of-type)': {
        borderBottom: `solid 1px ${theme.colors.grey40}`,
    },

    [breakpoints.sm]: {
        border: `1px solid ${theme.colors.borderColor}`,
        margin: '0 -1px -1px 0',
        display: 'flex',
        alignItems: 'center',
        minHeight: '385px',
        [`${StyledMediaTextLink}`]: {
            padding: 0,
            height: '100%',
        },

        ':hover': {
            zIndex: 1,
            border: `1px solid ${theme.colors.primary}`,
            transitionDuration: '.3s',
        },
    },
}));
