import React, { FC, useEffect, useState } from 'react';
import Button from '$shared/components/button';
import { Icon } from '$shared/components/icon';
import { QuantityPicker } from '$shared/components/quantity-picker';
import {
    StyledDiv,
    StyledQuantityAdditionalText,
    StyledQuantityBasketLine,
    StyledQuantityPriceHeadline,
} from './style';
import { formatString, useTranslation } from '$shared/utils';
import { SignInForPrice } from '../sign-in-for-price/sign-in-for-price';
import { useAuthentication } from '$shared/hooks/useAuthentication';
import { useBasketUpsert } from '$shared/hooks/useBasket/useBasket';
import { useAddToBasketContext } from '$shared/utils/add-to-basket/useAddToBasketContext';
import { ToolTip } from '$shared/components/hover-card-tooltip/tool-tip';
import { FavoritesToggle } from '$features/favorites/favoritesToggle';
import { ServiceType } from '$shared/components/extra-services/service-types';
import { useProduct } from '$shared/utils/product';
import { useFetchPriceAndStock } from '$shared/hooks/use-fetch-price-and-stock';

export type QuantityPriceTotal = {
    extraServices?: unknown;
};

export const PdpQuantityToBasket: FC<QuantityPriceTotal> = () => {
    const { isAuthenticated, userFound } = useAuthentication();
    const { translate } = useTranslation();
    const {
        extraServices,
        inSizesOf: quantityStep = 1,
        isQuantityReady,
        minimumOrderSize: minimumQuantity = 1,
        quantity,
        resetBasketContext,
        sanitizedItemNumber,
        selectedServices,
        setQuantity,
        unit,
        price,
        promotedProduct,
        boxCount,
    } = useAddToBasketContext();
    const { isFetchingPriceAndStockCount, originalItemNumber } = useProduct();
    const { upsertBasket, isLoading, status } = useBasketUpsert();

    useEffect(() => {
        if (status === 'success') {
            resetBasketContext();
        }
    }, [status]);

    const [canFetchPrices, setCanFetchPrices] = useState(false);

    const { mappedPrices, isLoading: loadingPriceAndStock } = useFetchPriceAndStock({
        primaryProductNumber: originalItemNumber,
        sanitizedItemNumber: sanitizedItemNumber,
        quantityValue: quantity.parsedValue,
        canFetch: canFetchPrices,
    });

    if (isAuthenticated && !userFound) {
        return null;
    }

    return (
        <StyledQuantityBasketLine>
            {isAuthenticated ? (
                <>
                    <div>
                        {price ? (
                            <StyledQuantityPriceHeadline children={price} noMargin size={3} />
                        ) : (
                            !isFetchingPriceAndStockCount &&
                            translate('product-detail-page.core-data.contact-for-price')
                        )}
                    </div>

                    {sanitizedItemNumber && (
                        <ToolTip
                            content={translate('favorites.add-to-favorites')}
                            position="bottom"
                            arrow={true}
                        >
                            <FavoritesToggle sanitizedItemNumber={sanitizedItemNumber} />
                        </ToolTip>
                    )}
                </>
            ) : (
                <SignInForPrice />
            )}

            <StyledDiv onMouseOver={() => setCanFetchPrices(true)}>
                <QuantityPicker
                    disabled={!isAuthenticated || !isQuantityReady || !price}
                    min={minimumQuantity}
                    onChange={setQuantity}
                    prices={mappedPrices ?? []}
                    step={quantityStep}
                    unit={unit}
                    value={quantity.value}
                    loadingData={canFetchPrices && loadingPriceAndStock}
                />
            </StyledDiv>

            <Button
                children={translate('product-detail-page.core-data.add-to-basket')}
                isLoading={isLoading}
                disabled={!isAuthenticated || !quantity.isValid || !sanitizedItemNumber || !price}
                onClick={() =>
                    !!sanitizedItemNumber &&
                    upsertBasket({
                        quantity: quantity.parsedValue,
                        sanitizedItemNumber,
                        services:
                            extraServices?.map((es) => {
                                const serviceAsSelected = selectedServices.find(
                                    (ss) =>
                                        ss.service.sanitizedItemNumber === es.sanitizedItemNumber
                                );
                                return {
                                    sanitizedItemNumber: es.sanitizedItemNumber,
                                    selected: !!serviceAsSelected,
                                    serviceName: es.label,
                                    serviceType: (es.editable
                                        ? 'CheckboxAndString'
                                        : 'Checkbox') as ServiceType,
                                    textInput: serviceAsSelected?.userText || undefined,
                                };
                            }) || [],
                    })
                }
                iconLeft={<Icon icon="cart" color="white" size={20} />}
                variant="primary"
            />

            <StyledQuantityAdditionalText>
                {quantityStep > 1 && (
                    <p>
                        {formatString(
                            translate('product-detail-page.core-data.in-sizes-of'),
                            quantityStep,
                            unit
                        )}
                    </p>
                )}
                {(boxCount || 0) > 0 &&
                    formatString(
                        translate('product-detail-page.core-data.in-sizes-of'),
                        boxCount,
                        translate('product-detail-page.core-data.stock-pieces')
                    )}
                {minimumQuantity > 1 && quantityStep <= 1 && (
                    <p>
                        {formatString(
                            translate('product-detail-page.core-data.minimum-quantity'),
                            minimumQuantity,
                            unit
                        )}
                    </p>
                )}
                {promotedProduct && <p>{translate('shared.quantity.while-stock-lasts')}</p>}
            </StyledQuantityAdditionalText>
        </StyledQuantityBasketLine>
    );
};
