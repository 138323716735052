import { Icon } from '$shared/components/icon';
import Link from '$shared/components/link';
import { useTranslation } from '$shared/utils';
import { prettyDate } from '$shared/utils/pretty-date.helper';
import { sortAlphabetically, sortByDate } from '$shared/utils/sorting.helper';
import React, { FC, useMemo, useState } from 'react';
import { IJobVacanciesItem } from '~/lib/data-contract';
import { JobVacancyTableProps } from '../../models/models';
import { JobsTableHeadline, StyledJobsDesktopTable, StyledJobTableHeadlineIcon } from './style';

function sortJobItemByDate(a: IJobVacanciesItem, b: IJobVacanciesItem) {
    return sortByDate(a.publishedDate, b.publishedDate);
}
function sortJobItemByBusinessArea(a: IJobVacanciesItem, b: IJobVacanciesItem) {
    if (a?.businessAreaName && b?.businessAreaName) {
        return sortAlphabetically(a.businessAreaName, b.businessAreaName);
    }
    return 0;
}
enum SortableColumnIds {
    published_date = 'PUBLISHED_DATE',
    business_area = 'BUSINESS_AREA',
}

function getSorting(id: string) {
    switch (id) {
        case SortableColumnIds.published_date:
            return sortJobItemByDate;

        case SortableColumnIds.business_area:
            return sortJobItemByBusinessArea;
    }
}

function isColumnSortable(columnId?: string) {
    return !!Object.values(SortableColumnIds).find((id) => id.toString() === columnId);
}
type sortingState = {
    id?: string;
    reversed?: boolean;
};

const DesktopJobLayout: FC<JobVacancyTableProps> = ({ items, setSorting, formattingCulture }) => {
    const [currSorting, setCrrSorting] = useState<sortingState>();
    const { translate } = useTranslation();

    const columns = useMemo(
        () => [
            {
                id: 'JOB_TITLE',
                text: translate('jobvacancies.column.jobtitle'),
            },
            {
                id: 'BUSINESS_AREA',
                text: translate('jobvacancies.column.businessarea'),
            },
            {
                id: 'LOCATION',
                text: translate('jobvacancies.column.location'),
            },
            {
                id: 'PUBLISHED_DATE',
                text: translate('jobvacancies.column.publisheddate'),
            },
        ],
        []
    );

    const handleSorting = (id?: string) => {
        if (!id) {
            return;
        }
        const sortingFunction = getSorting(id);
        if (sortingFunction) {
            const nextItems = [...(items || [])]?.sort(sortingFunction);
            if (id === currSorting?.id && !currSorting.reversed) {
                nextItems.reverse();
                setCrrSorting({ id, reversed: true });
            } else {
                setCrrSorting({ id, reversed: false });
            }
            setSorting && setSorting(nextItems);
        }
    };

    return (
        <StyledJobsDesktopTable>
            <tr>
                {columns?.map((column, i) => (
                    <th key={`${column.id}${i}`}>
                        <JobsTableHeadline
                            isColumnSortable={isColumnSortable(column?.id)}
                            size={5}
                            noMargin
                            onClick={() => handleSorting(column?.id)}
                        >
                            {column.text}
                            {currSorting?.id === column?.id && (
                                <StyledJobTableHeadlineIcon>
                                    {currSorting?.reversed ? (
                                        <Icon icon={'chevronDown'} color={'primary'} />
                                    ) : (
                                        <Icon icon={'chevronUp'} color={'primary'} />
                                    )}
                                </StyledJobTableHeadlineIcon>
                            )}
                        </JobsTableHeadline>
                    </th>
                ))}
            </tr>
            {items?.map((item, i) => (
                <tr key={`${item.jobTitle}${i}`}>
                    <td>
                        <Link href={item.link?.url} target="_blank">
                            {item.jobTitle}
                        </Link>
                    </td>
                    <td>{item.businessAreaName} </td>
                    <td>{item.locationName} </td>
                    <td> {prettyDate(item.publishedDate, formattingCulture)} </td>
                    <td>
                        <Link href={item.link?.url} target="_blank">
                            <Icon icon={'arrowRight'} color={'primary'} />
                        </Link>
                    </td>
                </tr>
            ))}
        </StyledJobsDesktopTable>
    );
};
export default DesktopJobLayout;
