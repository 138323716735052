import styled, { CSSObject } from '@emotion/styled';

export const basketSummaryGrid: CSSObject = {
    alignItems: 'center',
    display: 'grid',
    grid: 'auto / 1fr auto',
};

export const StyledBasketSummary = styled.div<{ hasHeader: boolean }>(({ theme, hasHeader }) => ({
    background: theme.colors.grey20,
    color: theme.colors.black,
    fontSize: theme.fontSizes.md,
    lineHeight: 1.5,
    padding: hasHeader ? theme.space[3] : `${theme.space[5]} ${theme.space[3]}`,
}));

export const StyledBasketSummaryHeader = styled.header(({ theme }) => ({
    ...basketSummaryGrid,
    marginBottom: theme.space[5],
}));

export const StyledBasketSummaryTitle = styled.h1(() => ({
    fontWeight: 700,
}));

export const StyledBasketSummaryList = styled.ul(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[2],
    marginBottom: theme.space[5],
}));

export const StyledBasketSummaryTerms = styled.div(({ theme }) => ({
    marginBottom: theme.space[5],
}));

export const StyledLoadingContainer = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: `0 ${theme.space[4]} ${theme.space[6]}`,
}));
