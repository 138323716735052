import { theme } from '$theme';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';

type StyledInputProps = {
    hideField?: boolean;
    isActive: boolean;
    showAsDisabled?: boolean;
    withLabel: boolean;
};

type StyledLabelProps = {
    hasFocus?: boolean;
    isActive?: boolean;
    isValid?: boolean;
    required?: boolean;
    showAsDisabled?: boolean;
};

type StyledInputFieldWrapperProps = {
    isValid: boolean;
    showAsDisabled?: boolean;
};

export const inputRequiredStyle: CSSObject = {
    '::after': {
        content: "' *'",
    },
};

export const inputStyle: CSSObject = {
    position: 'relative',
    display: 'block',
    width: '100%',
    height: 54,
    fontSize: theme.fontSizes.md,
    fontFamily: theme.fonts.body,
    lineHeight: theme.lineHeights.single,
    color: theme.colors.defaultText,
    backgroundColor: theme.colors.white,
    padding: `${theme.space[3]} ${theme.space[4]}`,
    border: `2px solid ${theme.colors.borderColor}`,
    outline: 0,

    '&::placeholder': {
        transition: '0.1s color',
        color: theme.colors.grey70,
    },
};

export const StyledInput = styled.input<StyledInputProps>(
    { ...inputStyle },

    ifProp('withLabel', ({ theme }) => ({
        padding: `25px ${theme.space[4]} 5px`,
    })),

    ifProp(
        { withLabel: true, isActive: false },
        {
            [`&::placeholder`]: {
                color: 'transparent',
            },
        }
    ),

    ifProp('hideField', () => ({
        height: 0,
        opacity: 0,
        padding: 0,
        border: 0,
        margin: 0,
    })),

    ifProp('showAsDisabled', ({ theme }) => ({
        background: theme.colors.grey30,
        borderColor: theme.colors.grey40,
        color: theme.colors.grey70,
    })),
);

export const StyledLabel = styled.span<StyledLabelProps>(
    ({ theme, hasFocus }) => ({
        position: 'absolute',
        top: '50%',
        left: theme.space[4],
        fontSize: theme.fontSizes['md'],
        transition: '0.1s transform, 0.1s width',
        color: hasFocus ? theme.colors.primary : theme.colors.grey80,
        transform: 'translateY(-50%)',
        width: `calc(100% - ${theme.space[4]} - ${theme.space[4]})`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        transformOrigin: 'left top',
        pointerEvents: 'none',
    }),
    ifNotProp('isValid', ({ theme }) => ({
        color: theme.colors.negative,
    })),
    ifProp('isActive', {
        transform: 'scale(0.75) translateY(-20px)',
        width: '110%',
    }),
    ifProp('required', { ...inputRequiredStyle }),

    ifProp('showAsDisabled', ({ theme }) => ({
        color: theme.colors.grey70,
    })),
);

export const StyledInputFieldWrapper = styled.div<StyledInputFieldWrapperProps>(
    ({ theme, isValid }) => ({
        position: 'relative',
        [`&:active ${StyledLabel}`]: {
            color: isValid ? theme.colors.primary : theme.colors.negative,
        },
    }),

    ifProp('showAsDisabled', ({ theme }) => ({
        [`&:active ${StyledLabel}`]: {
            color: theme.colors.grey70,
        },
    })),
);

export const StyledAdditionalContent = styled.span(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.space[4],
    fontSize: theme.fontSizes.xs,
    pointerEvents: 'none',

    svg: {
        overflow: 'visible',
    },
}));
