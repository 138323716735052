import React, { InputHTMLAttributes, useImperativeHandle, useRef } from 'react';
import { useInputField } from '$shared/utils';
import {
    StyledRadioButtonWrapper,
    StyledRadioButtonElement,
    StyledRadioButton,
    StyledCustomRadioButton,
    StyledLabel,
    StyledLabelText,
    StyledRadioButtonHelpTexts,
} from './style';
import { controllableInputElementsProps } from '$shared/components/form';
import { StyledInvalidMessage } from '../../invalid-message';
import { StyledHelpText } from '../../help-text';

export type RadioButtonElementProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'placeholder'> &
    controllableInputElementsProps & {
        /**
         * In case you wanna do your own
         */
        hideInvalidMessage?: boolean;
    };

export const RadioButtonElement = React.forwardRef<HTMLInputElement, RadioButtonElementProps>(
    ({ label, helpText, isInvalid, invalidMessage, id, hideInvalidMessage, ...rest }, ref) => {
        const inputRef = useRef<HTMLInputElement>(null);
        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        const isValid = !invalidMessage && !isInvalid;

        const { fieldId, helpTextId, showHelpText } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        return (
            <StyledLabel key={fieldId}>
                <StyledRadioButtonWrapper valid={isValid}>
                    <StyledRadioButtonElement>
                        <StyledRadioButton {...rest} type="radio" id={fieldId} ref={inputRef} />
                        <StyledCustomRadioButton />
                    </StyledRadioButtonElement>
                    {label ? <StyledLabelText title={label} children={label} /> : null}
                </StyledRadioButtonWrapper>
                <StyledRadioButtonHelpTexts>
                    {invalidMessage && !hideInvalidMessage ? (
                        <StyledInvalidMessage children={invalidMessage} />
                    ) : null}

                    {showHelpText ? <StyledHelpText id={helpTextId} children={helpText} /> : null}
                </StyledRadioButtonHelpTexts>
            </StyledLabel>
        );
    }
);

export default RadioButtonElement;
