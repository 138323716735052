import { theme } from '$theme';
import styled from '@emotion/styled';
import { ifNotProp } from 'styled-tools';

export const StyledRadioButton = styled.input({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    cursor: 'inherit',
    height: '100%',
    margin: 0,
    opacity: 0,
    padding: 0,
    zIndex: 1,
});

export const StyledRadioButtonElement = styled.div({
    appearance: 'none',
    position: 'relative',
    cursor: 'pointer',
    height: theme.space['4.5'],
    width: theme.space['4.5'],
});

export const StyledCustomRadioButton = styled.div({
    position: 'absolute',
    width: 24,
    height: 24,
    border: `1px solid ${theme.colors.grey70}`,
    borderRadius: '100%',
    backgroundColor: theme.colors.white,
});

export const StyledRadioButtonWrapper = styled.div<{ valid: boolean }>(
    ({ theme }) => ({
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: `25px 1fr`,
        gap: theme.space[3],

        [`${StyledCustomRadioButton}`]: {
            ':after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: 'inherit',
                width: 0,
                height: 0,
                backgroundColor: theme.colors.blue50,
                opacity: 0,
                zIndex: 1,
                pointerEvents: 'none',
                transition:
                    'width 120ms ease-in-out, height 120ms ease-in-out, opacity 100ms ease-in-out',
            },
        },

        // ACTIVE
        ':active': {
            [`input:active + ${StyledCustomRadioButton}`]: {
                borderColor: theme.colors.blue90,

                ':after': {
                    width: 10,
                    height: 10,
                    opacity: 1,
                },
            },
        },

        // HOVER
        [`input:hover + ${StyledCustomRadioButton}`]: {
            borderColor: theme.colors.blue90,
            backgroundColor: theme.colors.grey10,
        },

        //FOCUS
        [`input:focus-within:not([disabled]) + ${StyledCustomRadioButton}`]: {
            borderColor: theme.colors.blue50,
            outline: `solid 3px ${theme.colors.blue50}`,
        },

        // CHECKED
        [`input:checked + ${StyledCustomRadioButton}`]: {
            borderColor: theme.colors.blue90,
            backgroundColor: theme.colors.white,

            ':after': {
                backgroundColor: theme.colors.blue,
                width: 12,
                height: 12,
                opacity: 1,
            },
        },

        // DISABLED
        [`input:disabled:not(:checked)`]: {
            borderColor: theme.colors.grey50,
            backgroundColor: theme.colors.grey20,
        },
        [`input:disabled + ${StyledCustomRadioButton}`]: {
            borderColor: theme.colors.grey50,

            ':after': {
                backgroundColor: theme.colors.grey40,
            },
        },
    }),
    ifNotProp('valid', ({ theme }) => ({
        [`${StyledLabel}`]: {
            color: theme.colors.negative,
        },
    }))
);

// LABEL
export const StyledLabel = styled.label(() => ({
    cursor: 'pointer',
}));

export const StyledLabelText = styled.span(({ theme }) => ({
    display: 'block',
    color: theme.colors.grey80,
    fontSize: theme.fontSizes.sm,
    //padding: `4px 0 0 ${theme.space[3]}`,
    //lineHeight: '18px',
}));

export const StyledRadioButtonHelpTexts = styled.div(({ theme }) => ({
    marginLeft: `calc(25px + ${theme.space[3]})`,
}));
