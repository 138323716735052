import { QuantitySpinnerValue } from '$shared/components/quantity-spinner/quantity-spinner';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { ExtraService, IProduct, PriceAndStockViewModel } from '~/lib/data-contract';

type AddToBasketProviderProps = Pick<IProduct, 'extraServices' | 'sanitizedItemNumber' | 'unit'> &
    Pick<PriceAndStockViewModel, 'inSizesOf' | 'prices' | 'minimumOrderSize' | 'boxCount'> & {
        isQuantityReady: boolean;
        quantity: QuantitySpinnerValue;
        resetBasketContext: () => void;
        showExtraServices: boolean;
        selectedServices: SelectedService[];
        setQuantity: (quantity: QuantitySpinnerValue) => void;
        setSelectedServices: (selectedServices: SelectedService[]) => void;
        setShowExtraServices: (show: boolean) => void;
        price: string | null | undefined;
        promotedProduct?: boolean;
        primaryProductNumber?: string | null | undefined;
    };

type SelectedService = {
    service: ExtraService;
    userText?: string;
};

// disable eslint because contexts and TS are weird.
export const AddToBasketContext = React.createContext<AddToBasketProviderProps>({} as any); // eslint-disable-line

export const AddToBasketProvider = ({
    children,
    ...otherProps
}: PropsWithChildren<
    Omit<
        AddToBasketProviderProps,
        | 'quantity'
        | 'resetBasketContext'
        | 'showExtraServices'
        | 'selectedServices'
        | 'setQuantity'
        | 'setSelectedServices'
        | 'setShowExtraServices'
        | 'price'
    >
>) => {
    const [selectedServices, setSelectedServices] = useState<SelectedService[]>([]);
    const [showExtraServices, internalSetShowServices] = useState(false);
    const [internalQuantity, internalSetQuantity] = useState<QuantitySpinnerValue>();

    const defaultQuantity = useMemo(() => {
        const defaultValue = otherProps.minimumOrderSize || otherProps.inSizesOf || 1;
        return {
            isValid: true,
            parsedValue: defaultValue,
            value: `${defaultValue}`,
        };
    }, [otherProps.inSizesOf, otherProps.minimumOrderSize]);

    useEffect(() => {
        if (internalQuantity || !otherProps.isQuantityReady) return;
        internalSetQuantity(defaultQuantity);
    }, [otherProps.isQuantityReady]);

    const quantity = useMemo(() => {
        return internalQuantity
            ? {
                  ...internalQuantity,
                  isValid: otherProps.minimumOrderSize
                      ? internalQuantity.parsedValue >= otherProps.minimumOrderSize
                      : internalQuantity.isValid,
              }
            : {
                  isValid: false,
                  parsedValue: 1,
                  value: '1',
              };
    }, [internalQuantity, otherProps.minimumOrderSize]);

    useEffect(() => {
        if (quantity && quantity.parsedValue > 0) {
            setShowExtraServices(true);
        }
    }, [quantity]);

    const setQuantity = useCallback(
        (value: QuantitySpinnerValue) => {
            if (!otherProps.isQuantityReady) return;

            internalSetQuantity(value);
        },
        [internalSetQuantity, otherProps.isQuantityReady]
    );

    const setShowExtraServices = useCallback(
        (show: boolean) => {
            if (show && otherProps.extraServices?.length) {
                internalSetShowServices(true);
            } else {
                internalSetShowServices(false);
            }
        },
        [internalSetShowServices, otherProps.extraServices]
    );

    const resetBasketContext = useCallback(() => {
        setSelectedServices([]);
        setShowExtraServices(false);
        internalSetQuantity(defaultQuantity);
    }, [internalSetQuantity, otherProps.inSizesOf, setSelectedServices, setShowExtraServices]);

    const price = useMemo(() => otherProps.prices?.[0]?.prettyNetPrice || null, [
        otherProps.prices,
    ]);

    return (
        <AddToBasketContext.Provider
            value={{
                ...otherProps,
                quantity,
                resetBasketContext,
                selectedServices,
                showExtraServices,
                setQuantity,
                setSelectedServices,
                setShowExtraServices,
                price,
            }}
        >
            {children}
        </AddToBasketContext.Provider>
    );
};
