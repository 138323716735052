import React, { FC } from 'react';
import { AnimationProps, motion } from 'framer-motion';
import { HighlightWord } from '$shared/components/highlight-word';
import { ProductLineItem } from '$shared/components/product-line/product-line.model';
import {
    StyledProduct,
    StyledProductImage,
    StyledProductItemNumber,
    StyledProductLabel,
    StyledProductName,
    StyledProductPrice,
    StyledProductSecondaryName,
} from './search-result-styles';
import { ProductImage } from '$shared/components/image';
import { Badge } from '$shared/components/badge/badge';

interface SearchResultProductsProps {
    animation: AnimationProps;
    products: ProductLineItem[];
    highlightMatch: string;
}

export const SearchResultProducts: FC<SearchResultProductsProps> = (props) => {
    return (
        <>
            {props.products.map((product) => (
                <motion.li {...props.animation} key={product.sanitizedItemNumber}>
                    <StyledProduct href={product.canonicalUrl as string}>
                        <StyledProductImage>
                            <ProductImage src={product.imageUrl} width={46} height={46} />
                        </StyledProductImage>

                        <StyledProductLabel>
                            {product.promotedProduct && <Badge />}
                            <StyledProductName>
                                <HighlightWord
                                    input={product.productName as string}
                                    match={props.highlightMatch}
                                />
                            </StyledProductName>
                            {!!product?.productNameSecondary && (
                                <StyledProductSecondaryName>
                                    {product.productNameSecondary}
                                </StyledProductSecondaryName>
                            )}

                            <StyledProductItemNumber children={product.primaryProductNumber} />
                        </StyledProductLabel>

                        <StyledProductPrice children={product.prices?.[0]?.prettyNetPrice} />
                    </StyledProduct>
                </motion.li>
            ))}
        </>
    );
};
