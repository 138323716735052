import create from 'zustand';
import { CookieConsentState } from './useCookieConsentModel';

/**
 * Hook that lets you listion to which cookie consent areas are currently set.
 *
 * Usage example:
 *
 *  const { marketing } = useCookieConsent();
 *  ...
 *  { marketing && <ComponentThatSetsCookies /> }
 */

export const useCookieConsent = create<CookieConsentState>((set) => ({
    setConsent: (settings) => {
        set((state) => ({
            ...state,
            ...settings,
        }));
    },
}));
