import { ProductProvider } from '$shared/utils/product';
import { usePriceAndStockCount } from '$shared/utils/product/hooks/use-price-and-stock-count';
import { useMemo } from 'react';
import { IP50ProductDetailPage } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';
import { StyledModulesWrapper } from '../P60ModulePage/style';
import { PdpCore } from './components/pdp-core';
import { PdpImageArea } from './components/pdp-image-area';
import { PdpSpecifications } from './components/pdp-specifications';
import { PdpTabs } from './components/pdp-tabs';
import {
    StyledPdPGridData,
    StyledPdPGrid,
    StyledPdPGridImage,
    StyledPdPGridBottomArea,
    StyledPdPSpecifications,
    StyledBorder,
} from './style';

type P50ProductDetailPageProps = IP50ProductDetailPage;

export const P50ProductDetailPage = ({ pageElements, product }: P50ProductDetailPageProps) => {
    const originalItemNumber = product?.originalItemNumber || '';

    const { productPricesAndStockCounts, isBusy } = usePriceAndStockCount(
        ['P50ProductDetailPage', originalItemNumber],
        [originalItemNumber]
    );

    const priceAndStockCount = useMemo(() => productPricesAndStockCounts.get(originalItemNumber), [
        originalItemNumber,
        productPricesAndStockCounts,
    ]);

    return (
        <ProductProvider {...product} {...priceAndStockCount} isFetchingPriceAndStockCount={isBusy}>
            <StyledPdPGrid>
                <StyledPdPGridImage>
                    <PdpImageArea />
                </StyledPdPGridImage>
                <StyledPdPGridData>
                    <PdpCore />
                    <StyledPdPSpecifications>
                        <StyledBorder>
                            <PdpSpecifications />
                        </StyledBorder>
                    </StyledPdPSpecifications>
                </StyledPdPGridData>
                <StyledPdPGridBottomArea>
                    <PdpTabs />
                </StyledPdPGridBottomArea>
            </StyledPdPGrid>
            <StyledModulesWrapper>
                <DynamicBlockList elements={pageElements} />
            </StyledModulesWrapper>
        </ProductProvider>
    );
};
