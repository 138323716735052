import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const StyledAspectRatioContent = styled.div({
    position: 'relative',
});

export type StyledAspectRatioProps = {
    /**
     * Give any custom ratio for element to scale to, for instance  square `ratio={1}`, widescreen `ratio={16/9}` etc.
     */
    ratio: number;
    /**
     * If `asMinHeight={true}` the `ratio` will only define the minimum height of the element and let it grow with the content
     *
     * Inspired by: https://www.trysmudford.com/blog/minimum-aspect-ratio/
     */
    asMinHeight?: boolean;
    /**
     * Override built-in styling.
     *
     * Example:
     * `style={{ overflow: 'visible'}}`
     */
    style?: React.CSSProperties;
    children?: React.ReactNode;
};

export const StyledAspectRatio = styled.div<StyledAspectRatioProps>(
    ({ ratio }) => ({
        position: 'relative',
        display: 'block',
        overflow: 'hidden',

        /**
         * Usage of pseudo-elements for aspect ratio inspired by:
         * https://www.trysmudford.com/blog/minimum-aspect-ratio/
         *
         * Has 2 benifits:
         * 1) Allows for content to make container grow (see article)
         * 2) A side-effect of this approach, is that it is not reliant
         *    on the containing elment to have `width: 100%`, so setting
         * 	  a custom `width` or `max-width` is supported.
         */
        '&:before': {
            content: '""',
            float: 'left',
            paddingBottom: `${100 / ratio}%`,
        },
        '&:after': {
            content: '""',
            display: 'table',
            clear: 'both',
        },
    }),
    ifProp('asMinHeight', {}, () => ({
        [`${StyledAspectRatioContent}`]: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    }))
);
