import { useTranslation } from '~/shared/utils';
import React, { FC, useEffect, useState } from 'react';
import { SearchInput } from '$shared/components/form/components/form-fields/search-input/search-input';
import { useFilter } from '$shared/utils/filter';

export const FacetSearchBar: FC = () => {
    const { searchText, setSearchText } = useFilter();
    const { translate } = useTranslation();
    const [value, setValue] = useState('');

    useEffect(() => setValue(searchText || ''), [searchText]);

    useEffect(() => {
        if (searchText === value) return;

        const debounce = setTimeout(() => {
            setSearchText?.(`${value}`);
        }, 500);

        return () => clearTimeout(debounce);
    }, [searchText, value]);

    return (
        <SearchInput
            onChange={(v) => setValue(v)}
            placeholder={translate('product-filter.filter-search-bar')}
            value={value}
        />
    );
};
