import React from 'react';
import InputField from '$shared/components/deprecatedDropdown/deprecateInput';
import { useCombobox } from 'downshift';
import { OptionComponent } from './option';
import ChevronDown from '$icons/chevron-down.svg';
import { StyledIcon, StyledSelector, StyledOptions } from './style';
import { motion } from 'framer-motion';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type Option = {
    value: string;
    index: number;
    title?: string;
    disabled?: boolean;
};

export type SelectorProps = InputProps & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * The selected Option
     */
    value?: string;

    options: Option[];
    onSelection?: (option: Option | undefined | null) => void;
};

export const DeprecatedDropdown = React.forwardRef<HTMLInputElement, SelectorProps>(
    ({ options, onSelection, onClick, ...rest }, ref) => {
        const {
            isOpen,
            getInputProps,
            getComboboxProps,
            getMenuProps,
            highlightedIndex,
            getItemProps,
            closeMenu,
            openMenu,
        } = useCombobox<Option>({
            items: options,
            itemToString: (item) => item?.value || '',
            onSelectedItemChange: ({ selectedItem }) => {
                onSelection && onSelection(selectedItem);
                closeMenu();
            },
        });

        const containerProps = getComboboxProps();

        const inputProps = getInputProps({
            ...rest,
            ref,
            readOnly: true,
            type: 'text',
            onClick: (event) => {
                onClick && onClick(event);
                isOpen ? closeMenu() : openMenu();
            },
        });

        const openIcon = (
            <StyledIcon isOpen={isOpen}>
                <ChevronDown />
            </StyledIcon>
        );

        const framer_container = {
            hidden: { opacity: 0 },
            show: {
                opacity: 1,
                transition: {
                    staggerChildren: 5,
                },
            },
        };

        const framer_item = {
            hidden: { opacity: 0 },
            show: { opacity: 1 },
        };

        return (
            <StyledSelector {...containerProps}>
                <InputField {...inputProps} isActive={isOpen} append={openIcon}>
                    <motion.ul variants={framer_container} initial="hidden" animate="show">
                        <StyledOptions {...getMenuProps()}>
                            {isOpen &&
                                options.map((item, index) => (
                                    <motion.li variants={framer_item} key={item.value}>
                                        <OptionComponent
                                            selected={highlightedIndex === index}
                                            key={item.value}
                                            {...item}
                                            {...getItemProps({
                                                item,
                                                disabled: item.disabled,
                                                index,
                                            })}
                                        >
                                            {item.title || item.value}
                                        </OptionComponent>
                                    </motion.li>
                                ))}
                        </StyledOptions>
                    </motion.ul>
                </InputField>
            </StyledSelector>
        );
    }
);

export default DeprecatedDropdown;
