import styled from '@emotion/styled';
import { breakpoints } from '$theme';

type StyledGridProps = {
    columnCount: number;
};

export const StyledHeader = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: theme.space['3'],
}));

export const StyledGrid = styled.div<StyledGridProps>(({ columnCount }) => ({
    display: 'grid',

    [breakpoints.sm]: {
        gridTemplateColumns: `repeat(${Math.min(columnCount, 2)}, 1fr)`,
        gap: 'none',
    },
    [breakpoints.md]: {
        gridTemplateColumns: `repeat(${Math.min(columnCount, 3)}, 1fr)`,
    },
    [breakpoints.lg]: {
        gridTemplateColumns: `repeat(${Math.min(columnCount, 4)}, 1fr)`,
    },
}));
