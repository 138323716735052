import React, { FC, useState } from 'react';
import Button from '../button';
import { ProgressBar } from '../progress-bar';
import { ShowMoreProductsWrapper, StyledShowMoreText } from './style';

export type ShowMoreProducts = {
    buttonText?: string;
    className?: string;
    handleClick?(): void;
    isLoading?: boolean;
    progress: {
        total?: number;
        current?: number;
        incrementSize?: number;
    };
    progressText?: string;
};

export const ShowMoreButton: FC<ShowMoreProducts> = ({
    buttonText,
    className,
    handleClick,
    isLoading,
    progress,
    progressText,
}) => {
    const [hover, setHover] = useState(false);
    return (
        <ShowMoreProductsWrapper className={className}>
            <StyledShowMoreText>{progressText}</StyledShowMoreText>
            <ProgressBar
                shownProducts={progress.current}
                totalProducts={progress.total}
                incrementSize={progress.incrementSize}
                isActive={hover}
            />

            <Button
                isLoading={isLoading}
                disabled={isLoading || progress.current === progress.total}
                onClick={handleClick}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {buttonText}
            </Button>
        </ShowMoreProductsWrapper>
    );
};
