import { ContactDto, ContactViewModel } from '$order-models/*';
import create from 'zustand';
import { persist } from 'zustand/middleware';

type userType = ContactViewModel & {
    companyName?: string;
};

type ContactState = ContactDto & {
    user?: userType;
    actOnBehalfOfCustomer?: string;
    actOnBehalfOfCustomerMarket?: string;
    setActOnBehalfOfCustomer(customerNumber?: string): void;
    setActOnBehalfOfCustomerMarket(customerMarket?: string): void;
    setUser(state?: userType): void;
    resetUserStore(): void;
};

export const useUserStore = create<ContactState>(
    persist(
        (set) => ({
            setActOnBehalfOfCustomer: (actOnBehalfOfCustomer) => set({ actOnBehalfOfCustomer }),
            setActOnBehalfOfCustomerMarket: (actOnBehalfOfCustomerMarket) => set({ actOnBehalfOfCustomerMarket }),
            setUser: (user) => set({ user }),
            resetUserStore: () => set({ user: undefined, actOnBehalfOfCustomer: undefined }),
        }),
        {
            name: 'kyocera-user-store',
            getStorage: () => sessionStorage,
        }
    )
);
