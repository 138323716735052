import React, { FC, useMemo } from 'react';
import { productPDFSrc, useTranslation } from '$shared/utils';
import { ColumnList } from '$shared/components/column-list';
import { useProduct } from '$shared/utils/product';
import Headline from '$shared/components/headline';
import { StyledDownload, StyledDownloadWrapper, StyledSpecificationWrapper } from './style';
import { Icon } from '$shared/components/icon';
import { ToolTip } from '$shared/components/hover-card-tooltip/tool-tip';

export const Specifications: FC = () => {
    const product = useProduct();
    const { translate } = useTranslation();

    const SpecificationData = useMemo(
        () =>
            product?.specifications?.map((s) => ({
                dataLabel: (
                    <StyledSpecificationWrapper>
                        {s.name}
                        {s.url && (
                            <StyledDownloadWrapper>
                                <ToolTip content={translate('generals.details')} arrow={true}>
                                    <StyledDownload href={productPDFSrc(s.url)} target="_blank">
                                        <Icon icon={'info'} color="blue" size={22} />
                                    </StyledDownload>
                                </ToolTip>
                            </StyledDownloadWrapper>
                        )}
                    </StyledSpecificationWrapper>
                ),
                dataItem: `${s.value} ${s.suffix}`,
            })),
        [translate, product?.specifications]
    );

    const List = useMemo(
        () => [
            {
                dataLabel: (
                    <Headline size={6} noMargin>
                        {translate('product-detail-page.specification-data.specifications')}
                    </Headline>
                ),
            },
            ...(SpecificationData || []),
        ],
        [SpecificationData]
    );

    if (!SpecificationData?.length) return null;
    else return <ColumnList listItems={List} />;
};
