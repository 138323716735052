import { useTranslation } from '$shared/utils';
import React, { FC } from 'react';

import {
    StyledBasketHeaderImage,
    StyledBasketHeader,
    StyledBasketHeaderPrice,
    StyledBasketHeaderStatus,
    StyledBasketLineHeaderGrid,
    StyledBasketHeaderFavorites,
    StyledBasketHeaderBasket,
    StyledBasketHeaderTotalPrice,
} from './style';

export type BasketLineProps = {
    hideOnMobile?: boolean;
};

export const BasketLineHeader: FC<BasketLineProps> = ({ hideOnMobile }) => {
    const { translate } = useTranslation();
    return (
        <StyledBasketLineHeaderGrid hideOnMobile={hideOnMobile}>
            <StyledBasketHeaderImage />

            <StyledBasketHeader
                children={translate('product-list-page.product-headers.product-name')}
            ></StyledBasketHeader>

            <StyledBasketHeaderPrice
                children={translate('product-list-page.product-headers.price')}
            ></StyledBasketHeaderPrice>

            <StyledBasketHeaderStatus
                children={translate('product-list-page.product-headers.stock-status')}
            ></StyledBasketHeaderStatus>

            <StyledBasketHeaderFavorites></StyledBasketHeaderFavorites>

            <StyledBasketHeaderBasket
                children={translate('product-list-page.product-headers.quantity')}
            ></StyledBasketHeaderBasket>

            <StyledBasketHeaderTotalPrice
                children={translate('basket.line-heading-total')}
            ></StyledBasketHeaderTotalPrice>
        </StyledBasketLineHeaderGrid>
    );
};
