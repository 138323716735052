import React, { FC, memo, ReactNode } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import Headline from '../headline';
import { Icon } from '../icon';
import {
    DialogChildrenContainer,
    DialogClose,
    DialogContent,
    DialogContentWrapper,
    DialogHeader,
    DialogOverlay,
} from './style';
import { use100vh } from 'react-div-100vh';

type SidePanelVariants = 'normal' | 'custom';

type Props = {
    show?: boolean;
    onChange?: (open: boolean) => void;
    /**
     * 'normal' to get a header with close button.
     * 'custom' to get a clean canvas.
     */
    variant?: SidePanelVariants;
    maxWidth?: number;
    title?: string;
    children?: ReactNode;
    maxContentHeight?: number | string;
};

export const SidePanel: FC<Props> = memo(
    ({
        show,
        onChange,
        children,
        title,
        variant = 'normal',
        maxWidth = 650,
        maxContentHeight,
    }: Props) => {
        const maxHeight = use100vh();

        return (
            <RadixDialog.Root open={show} onOpenChange={onChange}>
                <DialogOverlay />
                <DialogContent maxWidth={maxWidth} style={{ maxHeight: maxHeight || '100vh' }}>
                    <DialogContentWrapper>
                        {variant === 'custom' ? (
                            <>{children}</>
                        ) : (
                            <>
                                <DialogHeader>
                                    <Headline as="h1" size={2} noMargin>
                                        {title}
                                    </Headline>
                                    <DialogClose>
                                        <Icon icon="close" size={24} />
                                    </DialogClose>
                                </DialogHeader>
                                <DialogChildrenContainer
                                    style={
                                        maxContentHeight
                                            ? { maxHeight: maxContentHeight, paddingBottom: '60px' }
                                            : {}
                                    }
                                >
                                    {children}
                                </DialogChildrenContainer>
                            </>
                        )}
                    </DialogContentWrapper>
                </DialogContent>
            </RadixDialog.Root>
        );
    }
);
