import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

type StyledDrawerContainerProps = StyledDrawerProps;
type StyledBackdropProps = StyledDrawerProps;

type StyledDrawerProps = {
    open: boolean;
};

export const StyledDrawerContainer = styled.div<StyledDrawerContainerProps>(({ open }) => ({
    display: 'contents',
    pointerEvents: open ? 'auto' : 'none',
}));

export const StyledDrawer = styled.div<{ open?: boolean; offset: number }>(
    ({ theme, offset = 0 }) => ({
        zIndex: theme.zIndices[3],
        position: 'fixed',
        width: ['93%', '93vw'],
        top: offset, // distance to top of viewport
        bottom: 0,
        left: 0,
        background: theme.colors.white,
        scrollBehavior: 'smooth',
        overflowY: 'auto',
        transitionDuration: '150ms',
        transitionTimingFunction: theme.easings.smoothEase,
        transitionProperty: 'transform, opacity',
        transform: ['translate(0, 0)', 'translate3d(0,0,0)'],
    }),
    ifProp(
        'open',
        {
            transform: ['translate(0, 0)', 'translate3d(0,0,0)'],
            opacity: 1,
        },
        {
            transform: ['translate(-200px, 0)', 'translate3d(-200px,0,0)'],
            opacity: 0,
        }
    )
);

export const StyledBackdrop = styled.div<StyledBackdropProps>(({ open, theme }) => ({
    display: open ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndices[2],
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
}));

export const StyledContent = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
}));
