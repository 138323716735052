import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledStepList = styled.ul(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',

    [breakpoints.xxs]: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        gap: theme.space[6],
    },
}));

export const StyledStepListItem = styled.li(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
}));
