import React, { FC, useMemo, useRef } from 'react';
import { useProduct } from '$shared/utils/product';
import { useProductSearhBySanitizedItemNumbers } from '$shared/hooks/useProductSearch/useProductSearch';
import { useOnScreen } from '$shared/utils';
import { FilterProductListControlled } from '$templates/blocks/m170-filter/components/filter-product-list-controlled';
import { filterResultMapper } from '$shared/utils/filter/utils/filter-mappers';

interface ProductListTabProps {
    sanitizedItemNumbers: string[];
}

export const ProductListTab: FC<ProductListTabProps> = (props) => {
    const { sanitizedItemNumber } = useProduct();
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);

    const { results, isFetching, isInitialLoading } = useProductSearhBySanitizedItemNumbers(
        isVisible && !!props.sanitizedItemNumbers.length,
        props.sanitizedItemNumbers
    );

    const mappedResults = useMemo(() => results && results.map((r) => filterResultMapper(r)), [
        results,
    ]);

    return (
        <div ref={ref}>
            <FilterProductListControlled
                id={sanitizedItemNumber || ''}
                isLoading={isFetching}
                isLoadingInitially={isInitialLoading}
                results={mappedResults}
            ></FilterProductListControlled>
        </div>
    );
};
