import React, { useMemo } from 'react';
import { ColumnList } from '$shared/components/column-list';
import { useTranslation } from '$shared/utils';
import { ColumnListItemProps } from '$shared/components/column-list/column-list';
import { useProduct } from '$shared/utils/product';
import { Stock } from '$features/stock/stock/stock';
import { useAuthentication } from '$shared/hooks/useAuthentication';
import styled from '@emotion/styled';

const StyledStock = styled(Stock)(({ theme }) => ({
    color: theme.colors.grey80,
    fontSize: theme.fontSizes.xs,
}));

export const PdpCoreItems = () => {
    const { isAuthenticated, userFound } = useAuthentication();
    const { translate } = useTranslation();
    const product = useProduct();

    const coreData = useMemo(() => {
        const data: ColumnListItemProps[] = [
            {
                dataLabel: translate('product-detail-page.core-data.item-no'),
                dataItem: product.originalItemNumber,
            },
        ];

        if (product.alternativeItemNumber) {
            data.push({
                dataLabel: translate('product-detail-page.core-data.alternative-item-number'),
                dataItem: product.alternativeItemNumber,
            });
        }
        if (isAuthenticated && userFound) {
            data.push({
                dataLabel: translate('product-detail-page.core-data.stock'),
                dataItem: <StyledStock {...product} units={product.unit} />,
            });
        }

        return data;
    }, [isAuthenticated, product, translate]);

    return <ColumnList listItems={coreData} />;
};
