import { theme } from '$theme';
import styled from '@emotion/styled';

export const StyledBasketGrid = styled.li(() => ({
    display: 'grid',
    gridTemplateRows: '1fr auto',
    alignItems: 'center',
}));

export const StyledBasketTotalPrice = styled.span(() => ({
    paddingRight: theme.space[2],
}));

export const StyledLoadingContainer = styled.div(({ theme }) => ({
    padding: theme.space[5],
}));

export const EmptyBasketContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.space[2],
    padding: theme.space[6],
}));
