import { FC, HTMLAttributes, ReactNode } from 'react';
import { Skeleton } from '../skeleton';
import { StyledButtonIcon } from './style';

export type ButtonProps = HTMLAttributes<HTMLButtonElement> & {
    className?: string;
    isLoading?: boolean;
    label: string;
    children: ReactNode;
    maxButtonHeight?: number;
    handleClick: () => void;
};

export const ButtonIcon: FC<ButtonProps> = ({
    isLoading,
    label,
    className,
    handleClick,
    children,
    maxButtonHeight,
    ...buttonProps
}) => {
    return (
        <StyledButtonIcon
            {...buttonProps}
            className={className}
            onClick={handleClick}
            disabled={isLoading}
            aria-busy={isLoading}
            aria-label={label}
            maxHeight={maxButtonHeight}
        >
            {isLoading ? <Skeleton pulsating width="24px" height="24px" /> : children}
        </StyledButtonIcon>
    );
};
