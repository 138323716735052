import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactHookForm__InputProps } from '../../../models/form-models';
import { InputElement, InputElementProps } from '../../controllable-elements/input-element';

export type InputProps = InputElementProps & ReactHookForm__InputProps;

export const Input: FC<InputProps> = ({ name, rules, defaultValue, ...rest }) => {
    const {
        formState,
        getFieldState,
        register,
        watch,
    } = useFormContext();

    const watchValue = watch(name, defaultValue);
    const { error } = getFieldState(name, formState);

    return (
        <InputElement
            isActive={watchValue}
            isInvalid={!!error}
            invalidMessage={error?.message}
            isRequired={!!rules?.required}
            {...rest}
            {...(register && name && register(name, rules))}
        />
    );
};
