import Link from '$shared/components/link';
import { theme } from '$theme';
import { Theme } from '@emotion/react';
import styled, { CSSObject } from '@emotion/styled';

export const StyledLandingPageWrapper = styled.div(() => ({}));

export const StyledProfileAboutText = styled.p(() => ({
    marginBottom: theme.space[4],
    marginTop: theme.space[4],
    fontSize: theme.fontSizes.md,
}));

export const StyledProfileAboutDataWrapper = styled.div(() => ({
    borderLeft: `1px solid ${theme.colors.grey40}`,
    display: 'flex',
    flexFlow: 'column wrap',
    paddingLeft: theme.space[4],
    rowGap: theme.space[1],
}));

export const StyledProfileAboutData = styled.p(() => ({
    marginBottom: theme.space[1],
    fontSize: theme.fontSizes.md,
}));

export const StyledLandingPageContact = styled.p(() => ({
    color: theme.colors.grey80,
    fontSize: theme.fontSizes.xs,
}));

export const StyledNavigation = styled.nav(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.space[5],
}));

const getMyPageNavItemStyles = (theme: Theme): CSSObject => ({
    all: 'unset',
    boxSizing: 'border-box',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.colors.grey90,
    padding: `${theme.space[4]} 0`,
    textDecoration: 'none',
    fontSize: 14,
    width: '100%',
    borderBottom: `1px solid ${theme.colors.borderColor}`,
});

export const MyPageNavLink = styled(Link)(({ theme }) => ({
    ...getMyPageNavItemStyles(theme),
}));

export const MyPageSignOutBtn = styled.button(({ theme }) => ({
    ...getMyPageNavItemStyles(theme),
    borderBottom: 'none',
}));
