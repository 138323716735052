import styled from '@emotion/styled';
import { breakpoints, theme } from '$theme';
import {
    StyledInputField,
    StyledLabel,
} from '$shared/components/deprecatedDropdown/deprecateInput';
import { Icon } from '$shared/components/icon';
import Button from '$shared/components/button';
import { Container } from '$shared/components/container';

export const BackgroundDiv = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0',
    backgroundColor: theme.colors.primary,
    fontSize: theme.fontSizes['sm'],
    width: '100%',
}));

export const StyledLanguageSelectorContainer = styled(Container)({
    position: 'relative',
});

export const StyledContentWrapper = styled.div({
    padding: `calc(${theme.space[5]} - ${theme.global.headerBorderThickness}) 0 ${theme.space[5]}`,
    margin: 'auto',

    [breakpoints.sm]: {
        maxWidth: '700px',
        padding: `calc(${theme.space[6]} - ${theme.global.headerBorderThickness}) 0 ${theme.space[6]}`,
    },
});
export const StyledContinueButton = styled(Button)({
    backgroundColor: theme.colors.blue90,
    justifyContent: 'center',
    width: '100%',
    marginTop: '10px',
    [breakpoints.sm]: {
        width: '20%',
        marginTop: '0',
    },
});
export const StyledFlex = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
});

export const TextDiv = styled.div(({ theme }) => ({
    position: 'relative',
    color: theme.colors.white,
    textAlign: 'center',
    width: '100%',
    paddingRight: theme.space[5],
    marginBottom: theme.space[2],
}));

export const StyledSelectors = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    [breakpoints.sm]: {
        width: '75%',
    },
});

export const LangSelector = styled.div(({ theme }) => ({
    width: '48%',
    [breakpoints.sm]: {
        marginRight: '15px',
        marginBottom: '0',
    },
    [`${StyledInputField}`]: {
        height: '58px',
        fontSize: theme.fontSizes.md,
    },
    [`${StyledLabel}, input`]: {
        fontSize: '16px', // fixing iOS zoom
        [breakpoints.sm]: {
            fontSize: theme.fontSizes['md'],
        },
    },
}));

export const StyledCancelButton = styled.button(() => ({
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    background: 'rgba(0,0,0,0)',
    border: 'none',
}));

export const StyledCloseIcon = styled(Icon)(({ theme }) => ({
    color: theme.colors.white,
    width: '18px',
    height: '18px',

    [breakpoints.sm]: {
        width: '20px',
        height: '20px',
    },
}));
