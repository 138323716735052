import styled, { CSSObject } from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { darken } from 'color2k';
import { ButtonProps } from './button';
import { breakpoints, theme } from '$theme';

export const ButtonResetStyles: CSSObject = {
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    boxSizing: 'border-box',
    color: 'inherit',
    cursor: 'pointer',
    display: 'inline-flex',
    flexShrink: 0,
    fontFamily: theme.fonts.body,
    letterSpacing: 0,
    lineHeight: 1,
    margin: 0,
    outline: 'none',
    padding: 0,
    textDecoration: 'none',
    textRendering: 'inherit',
    userSelect: 'none',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    WebkitUserSelect: 'none',
};

export const buttonStyle: CSSObject = {
    ...ButtonResetStyles,
    alignItems: 'center',
    fontFamily: theme.fonts.headline,
    fontWeight: theme.fontWeights.bold,
    justifyContent: 'center',
    maxWidth: '100%',
    position: 'relative',

    '&[disabled]': {
        cursor: 'auto',
    },
    '&:hover': {
        textDecoration: 'none',
    },
};

export const boxedButtonStyle: CSSObject = {
    minHeight: `${theme.sizes.buttonHeight}px`,
    letterSpacing: theme.letterSpacings.sm,
    textTransform: 'uppercase',
    transition: `background-color 50ms`,
};

export const buttonContentStyle: CSSObject = {
    overflow: 'hidden',
    padding: `0 ${theme.space['4.5']}`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export const StyledContent = styled.div<ButtonProps>(
    {
        ...buttonContentStyle,
        padding: '1em 1.5em',
        fontSize: theme.buttonFontSizes.sm,
    },

    switchProp('variant', {
        primary: ({ theme }) => ({
            padding: `0 ${theme.space['4.5']}`,

            [breakpoints.sm]: {
                fontSize: theme.buttonFontSizes.md,
            },
        }),
        secondary: ({ theme }) => ({
            padding: `0 ${theme.space['4.5']}`,
        }),
        plain: ({ theme }) => ({
            padding: `0 ${theme.space['4.5']}`,
        }),
    }),

    {
        '&:first-of-type:not(:last-of-type)': { paddingRight: 0 },
        '&:last-of-type:not(:first-of-type)': { paddingLeft: 0 },
    }
);

export const variantPrimary: CSSObject = {
    ...boxedButtonStyle,
    color: theme.colors.white,
    backgroundColor: theme.colors.blue80,

    '&:focus:not([disabled])': {
        outline: `2px solid ${theme.colors.blue50}`,
    },

    '&:hover:not([disabled])': {
        backgroundColor: theme.colors.blue90,
    },

    '&:active:not([disabled])': {
        backgroundColor: theme.colors.blue70,
    },

    '&[disabled]': {
        backgroundColor: theme.colors.grey50,
    },
};

export const variantSecondary: CSSObject = {
    ...boxedButtonStyle,
    color: theme.colors.primary,
    backgroundColor: 'transparent',
    border: `2px solid ${theme.colors.primary}`,

    '&:hover:not([disabled])': {
        color: theme.colors.white,
        backgroundColor: darken(theme.colors.primary, 0.05),
    },

    '&[disabled]': {
        borderColor: theme.colors.grey50,
        color: theme.colors.grey60,
    },
};

export const variantPlain: CSSObject = {
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.bold,
    fontSize: theme.fontSizes.md,
    padding: '0px 1px',
    border: '1px solid transparent',
    [`${StyledContent}`]: {
        padding: '1px 0px',
        borderWidth: '0 0 1px',
        borderStyle: 'solid',
        borderColor: 'rgba(255, 255, 255, 0)',
    },
    '&:hover': {
        borderBottomColor: theme.colors.primary,
        color: 'currentcolor',
    },
    '&:active': {
        borderBottomColor: theme.colors.primary,
        color: 'currentcolor',
    },
    '&:focus': {
        outline: `2px solid ${theme.colors.blue50}`,
        borderColor: theme.colors.primary,
        color: 'currentcolor',
    },

    '&[disabled]': {
        color: theme.colors.grey60,
        border: '1px solid transparent',
        svg: {
            color: theme.colors.grey60,
        },
    },
};

export const variantLinkish: CSSObject = {
    fontSize: theme.fontSizes?.sm,
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.regular,
    color: theme.colors.primary,
    [`${StyledContent}`]: {
        padding: '1px 0px',
    },
    '&:hover': {
        color: 'currentcolor',
    },
    '&:active': {
        color: 'currentcolor',
    },
    '&[disabled]': {
        color: theme.colors.grey60,
    },
};

export const StyledButton = styled.button<ButtonProps>(
    { ...buttonStyle },

    switchProp('variant', {
        primary: () => ({
            ...variantPrimary,
        }),
        secondary: () => ({
            ...variantSecondary,
        }),
        plain: () => ({
            ...variantPlain,
        }),
        linkish: () => ({
            ...variantLinkish,
        }),
    })
);

export const StyledButtonContentWrapper = styled.div<ButtonProps>(({ isLoading }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    opacity: isLoading ? 0 : 1,
}));

export const StyledSpinnerWrapper = styled.div(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
}));

export const StyledIcon = styled.span<ButtonProps>(
    {
        padding: '1em 1.5em',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        transition: '0.2s ease-in-out',
        transformOrigin: 'center',
    },
    switchProp('variant', {
        primary: ({ theme }) => ({
            paddingBottom: 0,
            paddingLeft: theme.space[4],
            paddingRight: theme.space[4],
            paddingTop: 0,

            '&:first-of-type': { paddingRight: theme.space[2] },
            '&:last-of-type': { paddingLeft: theme.space[2] },
        }),
        secondary: {
            paddingLeft: '1.2em',
            paddingRight: '1.2em',
            '&:first-of-type': {
                paddingRight: 0,
                marginRight: '-0.5em',
            },
            '&:last-of-type': {
                paddingLeft: 0,
                marginLeft: '-0.5em',
            },
            '&:first-of-type:last-of-type': {
                padding: '1em',
                marginLeft: 0,
                marginRight: 0,
            },
            path: {
                fill: 'currentColor',
            },
        },
        plain: {
            padding: '10px 0px 10px 14px',
            marginLeft: 0,
        },
    })
);

interface NavButtonProps {
    active: boolean;
}
export const styledNavButtonBorderThickness = '4px';
export const StyledNavButton = styled.button<NavButtonProps>(({ active, theme }) => ({
    cursor: 'pointer',
    fontWeight: theme.fontWeights.regular,
    fontFamily: theme.fonts.headline,
    fontSize: theme.fontSizes.lg,
    color: active ? theme.colors.primary : theme.colors.grey80,
    backgroundColor: 'transparent',
    padding: `calc(${theme.space[3]} + ${styledNavButtonBorderThickness}) 0 ${theme.space[3]}`,
    border: 'none',
    borderBottom:
        `solid ${styledNavButtonBorderThickness} ` +
        (active ? theme.colors.primary : 'transparent'),
    marginRight: theme.space[3],
    marginBottom: 0,
    textTransform: 'uppercase',

    [breakpoints.sm]: {
        padding: `calc(${theme.space[5]} + ${styledNavButtonBorderThickness}) 0 ${theme.space[5]}`,
    },

    [breakpoints.xl]: {
        marginRight: theme.space[5],
    },
    '&:hover': {
        '@media (hover: hover)': {
            borderColor: theme.colors.primary,
        },
    },
}));
