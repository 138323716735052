import { pageElementPrefetch } from './page-element-prefetch';
import { PrefetchContext } from './prefetch-data';

/**
 * Prefetch page data using the provided `QueryClient`, for server-side rendering data.
 * @param page The page to prefetch data for.
 * @param queryClient The `QueryClient` used for fetching data.
 */
export async function pagePrefetch(context: PrefetchContext): Promise<void[]> {
    // If the page has no elements, return an empty array.
    if (!context.page.pageElements) {
        return Promise.resolve([]);
    }

    const prefetchElements = context.page.pageElements.map((element) =>
        pageElementPrefetch(element, context)
    );
    return Promise.all(prefetchElements);
}
