import { readableColorIsBlack } from 'color2k';
import { theme } from '$theme';

export const getTextColor = (backgroundColor?: string, defaultTextColor = theme.colors.black) => {
    if (!backgroundColor || ['rgba(255, 255, 255, 0)', 'none'].includes(backgroundColor)) {
        return defaultTextColor;
    }

    // Check contrast
    return readableColorIsBlack(backgroundColor) ? theme.colors.black : theme.colors.white;
};
