import React, { FC, useEffect, useRef } from 'react';
import { useFrame } from '$shared/utils';
import { ModuleContainer } from '../module-container';
import { IM210CookieDeclarationModule } from '~/lib/data-contract';

export const CookieDeclarationBlock: FC<IM210CookieDeclarationModule> = ({ id }) => {
    const divRef = useRef<HTMLDivElement>(null);
    const { culture = '' } = useFrame();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://consent.cookiebot.com/62638fdd-6379-4483-b572-bdfd4da43bed/cd.js';
        script.id = 'CookieDeclaration';
        script.type = 'text/javascript';
        script.async = true;
        script.setAttribute('data-culture', culture);

        divRef?.current?.appendChild(script);

        // Return statement will be run on component unmount
        return () => {
            divRef?.current?.removeChild(script);
        };
    }, []);

    return (
        <ModuleContainer id={id} noOverflow>
            <div ref={divRef} />
        </ModuleContainer>
    );
};
