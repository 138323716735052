import Button, { StyledIcon } from '$shared/components/button';
import { breakpoints } from '$theme';
import styled from '@emotion/styled';

export const StyledMenuIconButton = styled(Button)(() => ({
    maxWidth: '42px',

    [breakpoints.frame]: {
        // Switch from device to desktop design
        display: 'none',
    },

    [`${StyledIcon}`]: {
        paddingLeft: 0,
    },
}));
