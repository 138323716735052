import React from "react";
import Script from "next/script";
import {StyledNewsletterForm} from "$templates/blocks/m130-newsletter/style";

export type NewsletterProps = {

    /**
     * The Apsis id for newsletter
     */
    apsisFormId?: string;
};

export const Newsletter = ({apsisFormId}: NewsletterProps) => {
    if (!apsisFormId) {
        return null;
    }

    return (
        <StyledNewsletterForm>
            <Script
                strategy="lazyOnload"
                src="https://forms.apsisforms.com/formbuilder.js"
                type="text/javascript"
                onLoad={() => {
                    const apsisRef = (window as any)?.ApsisForms;

                    if (apsisRef) {
                        const form = new apsisRef.FormbuilderInstance();
                        form.init({
                            formId: apsisFormId,
                        });
                    }
                }}
            />
            <div className={`afp-${apsisFormId}`}/>
        </StyledNewsletterForm>
    );
};

export default Newsletter;
