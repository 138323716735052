import { theme } from '$theme';
import styled from '@emotion/styled';

export const ShowMoreProductsWrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: 400,
    margin: '0px auto',
});

export const StyledShowMoreText = styled.div({
    fontSize: theme.fontSizes['3xs'],
    marginBottom: theme.space[1],
});
