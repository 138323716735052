import { useTranslation } from '$shared/utils';
import { FC, useMemo } from 'react';
import {
    DetailItem,
    OrderDetails,
} from '$features/checkout/components/steps/shared/order-details/order-details';
import { useReceiptStore } from '$features/checkout/hooks/use-receipt-store';

export const OrderReceiptDetails: FC = () => {
    const { translate } = useTranslation();
    const { receipt } = useReceiptStore();

    const details: DetailItem[] = useMemo(
        () => [
            {
                title: translate('checkout-page.summary-details.delivery-address'),
                lines: [
                    receipt?.deliveryAddress?.companyName,
                    receipt?.deliveryAddress?.streetAndNumber,
                    receipt?.deliveryAddress?.zipAndCity,
                ],
            },
            {
                title: translate('checkout-page.summary-details.invoice-address'),
                lines: [
                    receipt?.paymentAddress?.companyName,
                    receipt?.paymentAddress?.streetAndNumber,
                    receipt?.paymentAddress?.zipAndCity,
                ],
            },
            {
                title: translate('checkout-page.summary-details.contact-person'),
                lines: [
                    receipt?.contact?.fullname,
                    receipt?.contact?.eMail,
                    receipt?.contact?.phone,
                ],
            },
            {
                title: translate('checkout-page.summary-details.order-reference'),
                lines: [receipt?.customerOrderNumber],
            },
            {
                title: translate('checkout-page.summary-details.order-number'),
                lines: [receipt?.orderNumber?.toString()],
            },
            {
                title: translate('checkout-page.summary-details.payment'),
                lines: [receipt?.paymentTerms],
            },
            // KYOC-1353 hiding delivery info
            // {
            //     title: translate('checkout-page.summary-details.delivery'),
            //     lines: [receipt?.deliveryMethod],
            // },
        ],
        [receipt]
    );

    return <OrderDetails details={details} />;
};
