import styled from '@emotion/styled';
import { FC } from 'react';
import { StockStatus } from '../stock-status';

interface StockIndicatorProps {
    className?: string;
    status: StockStatus;
}

const colorMap = {
    status0: '#349564',
    status1: '#7AD09C',
    status2: '#FFD645',
    status3: '#FF9E45',
    status4: '#DE2C24',
};

const StyledIndicator = styled.div<{ color: string }>(({ color }) => ({
    aspectRatio: '1',
    backgroundColor: color,
    borderRadius: '100%',
    display: 'inline-block',
    width: 12,
}));

export const StockIndicator: FC<StockIndicatorProps> = (props) => {
    const color = colorMap[props.status];

    return <StyledIndicator className={props.className} color={color} role="none presentation" />;
};
