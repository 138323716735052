import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';

type toggleParamInQueryArgs = {
    query?: ParsedUrlQuery;
    key?: string;
    value?: string;
};

type toggleParamInQueryOutput = {
    query: ParsedUrlQueryInput;
    isActive: boolean;
};

/*
 * Got a query, and do yuo wanna be able to set or remove an already set param? then use this function.
 * It'll return calc and return an updated query AND an indicator, on whether the [key]:val you've supplied, is alread active in the given query.
 */
export const toggleParamInQuery = ({
    query,
    key,
    value,
}: toggleParamInQueryArgs): toggleParamInQueryOutput => {
    const queryVal = key && query?.[key];
    const queryValAsArray = queryVal ? (Array.isArray(queryVal) ? queryVal : [queryVal]) : [];
    const alreadyInQuery = !!(value && queryValAsArray.includes(value));
    const nextArr = value
        ? alreadyInQuery
            ? queryValAsArray.filter((v) => v != value)
            : [...queryValAsArray, value]
        : [];

    return {
        query: {
            ...query,
            ...(key ? { [key]: nextArr } : {}),
        },
        isActive: alreadyInQuery,
    };
};
