import styled from '@emotion/styled';
import Flex from '$shared/components/flex';
import Container from '$shared/components/container';
import { ifProp } from 'styled-tools';

type StyleMegaMenuProps = {
    isActive?: boolean;
};
export const StyledMegaMenu = styled(Flex)<StyleMegaMenuProps>(
    ({ theme }) => ({
        position: 'absolute',
        top: '100%',
        left: '0',
        right: '0',
        transform: 'translate(0, -1px)',
        visibility: 'hidden',
        opacity: 0,
        zIndex: 1,
        transition: 'opacity .2s .1s,transform .2s .1s,visibility .2s .1s',
        willChange: 'opacity,transform',
        background: `${theme.colors.white}`,
        borderTop: `1px solid ${theme.colors.borderColor}`,

        '&:before': {
            pointerEvents: 'none',
            content: '""',
            position: 'absolute',
            top: '100%',
            left: '0',
            right: '0',
            height: '100vh',
            transform: 'translateX(0)',
            backgroundColor: 'rgba(0,0,0,0.5)',
        },
    }),
    ifProp('isActive', () => ({
        opacity: 1,
        transitionDelay: '0s',
        visibility: 'visible',
    }))
);

export const StyledMegaMenuContainer = styled(Container)(() => ({
    display: 'flex',
}));
