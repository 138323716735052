import { GetServerSideProps, GetServerSidePropsResult } from 'next';
import React, { useEffect } from 'react';
import { IncomingHttpHeaders } from 'http';
import { Layout } from '../Layout';
import { IPages } from '~/lib/data-contract';
import { PageProvider } from '../../context/PageProvider';
import { pageResolver, getIsInitialLoad } from '../../utils';
import { P160ArticlePage } from '../P160ArticlePage';
import { P60ModulePage } from '../P60ModulePage';
import { P20FrontPage } from '../P20FrontPage';
import { P120SearchPage } from '../P10SearchPage';
import { P30ProductOverviewPage } from '../P30ProductOverviewPage';
import { P35ProductOverviewDetailPage } from '../P35ProductOverviewDetailPage';
import { useGtm } from '$shared/hooks/useGTM/use-gtm';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import { P50ProductDetailPage } from '../P50ProductDetailPage';
import { P310CheckoutPage } from '../P310CheckoutPage';
import { P320ReceiptPage } from '../P320ReceiptPage';
import {
    getCultureInfo,
    getCultureInfoLink,
} from '$shared/utils/market/helpers/culture-info.helper';
import { P210MyFrontPage } from '../MyPages/P210MyFrontPage/P210MyFrontPage';
import { P230MyPurchasesPage } from '../MyPages/P230MyPurchasesPage/P230MyPurchasesPage';
import { P220MyOrdersPage } from '../MyPages/P220MyOrdersPage/P220MyOrdersPage';
import { P250MyFavouritesPage } from '../MyPages/P250MyFavouritesPage/P250MyFavouritesPage';
import { P300BasketPage } from '../P300BasketPage';
import { fetchUserInfo } from '$features/authentication/fetch-user-info';
import {
    ACT_ON_BEHALF_HEADER,
    ACT_ON_BEHALF_KEY,
    ACT_ON_BEHALF_MARKET_HEADER,
    ACT_ON_BEHALF_MARKET_KEY,
} from '$features/authentication/act-on-behalf-constants';
import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import { prefetchData } from '$templates/pages/utils/prefetch-data';
import { getSession } from 'next-auth/react';
import { marketKeys } from '$shared/utils/market/model/marketModel';
import { useUserStore } from '$features/authentication/use-user-store';

const Page = ({ page }: { page: IPages }) => {
    switch (page.type) {
        case 'p20FrontPage':
            return <P20FrontPage {...page} />;
        case 'p210MyFrontPage':
            return <P210MyFrontPage {...page} />;
        case 'p230MyPurchasesPage':
            return <P230MyPurchasesPage {...page} />;
        case 'p220MyOrdersPage':
            return <P220MyOrdersPage {...page} />;
        case 'p240MySupplementaryAgreementsPage':
            return <P220MyOrdersPage {...page} actAsAgreementPage />;
        case 'p250MyFavouritesPage':
            return <P250MyFavouritesPage {...page} />;
        case 'p60ModulePage':
            return <P60ModulePage {...page} />;
        case 'p160ArticlePage':
            return <P160ArticlePage {...page} />;
        case 'p120SearchPage':
            return <P120SearchPage {...page} />;
        case 'p30CategoryListPage':
            return <P30ProductOverviewPage {...page} />;
        case 'p35CategoryDetailPage':
            return <P35ProductOverviewDetailPage {...page} />;
        case 'p50ProductDetailPage':
            return <P50ProductDetailPage {...page} />;
        case 'p300BasketPage':
            return <P300BasketPage {...page} />;
        case 'p310CheckoutPage':
            return <P310CheckoutPage {...page} />;
        case 'p320ReceiptPage':
            return <P320ReceiptPage {...page} />;
    }
    throw Error(`Cannot render page type ${page.type}`);
};

const pageTypesWithoutHeader = ['p310CheckoutPage'];

const pageTypesWithoutBreadcrumb = ['p300BasketPage', 'p310CheckoutPage', 'p320ReceiptPage'];

const myPagePageTypes = [
    'p210MyFrontPage',
    'p230MyPurchasesPage',
    'p220MyOrdersPage',
    'p240MySupplementaryAgreementsPage',
    'p250MyFavouritesPage',
];

export type DynamicPageProps = {
    dehydratedState?: DehydratedState;
    page: IPages;
    url?: string;
    isCrmLogin?: boolean;
    [ACT_ON_BEHALF_KEY]?: string;
    [ACT_ON_BEHALF_MARKET_KEY]?: string;
};

export const DynamicPage = ({ page }: DynamicPageProps) => {
    const { trackVirtualPageview } = useGtm();
    const { asPath, events } = useKyoceraRouter();
    fetchUserInfo();
    useEffect(() => {
        trackVirtualPageview(asPath);
    }, [page]);

    const hideHeader = pageTypesWithoutHeader.includes(page.type);
    const hideBreadcrumb = pageTypesWithoutBreadcrumb.includes(page.type);
    const isMyPagePage = myPagePageTypes.includes(page.type);

    const { user, actOnBehalfOfCustomer, actOnBehalfOfCustomerMarket } = useUserStore();

    useEffect(() => {
        const onRouteChange = () => {
            if (location && actOnBehalfOfCustomer) {
                const url = new URL(location.href);
                // If the url already has ACT_ON_BEHALF_KEY, we don't want to add it again. Just return
                if (url.searchParams.has(ACT_ON_BEHALF_KEY)) {
                    return;
                }

                // If url contains hash, we don't want to add ACT_ON_BEHALF_KEY. Just return
                if (url.hash && url.hash.length > 0) {
                    return;
                }

                // If url last segment is receipt or checkout, just return
                const urlSegments = url.pathname.split('/');
                const lastSegment = urlSegments[urlSegments.length - 1];
                if (lastSegment === 'receipt' || lastSegment === 'checkout') {
                    return;
                }

                url.searchParams.append(ACT_ON_BEHALF_KEY, actOnBehalfOfCustomer);
                if (actOnBehalfOfCustomerMarket) {
                    url.searchParams.append(ACT_ON_BEHALF_MARKET_KEY, actOnBehalfOfCustomerMarket);
                } else {
                    const actOnBehalfData = user?.actOnBehalfOn?.find(
                        (act) => act.companyNumber === actOnBehalfOfCustomer
                    );

                    if (actOnBehalfData?.customerMarket) {
                        url.searchParams.append(
                            ACT_ON_BEHALF_MARKET_KEY,
                            actOnBehalfData.customerMarket
                        );
                    }
                }
                location.href = url.href;
            }
        };
        events.on('routeChangeComplete', onRouteChange);

        return () => {
            events.off('routeChangeComplete', onRouteChange);
        };
    }, [actOnBehalfOfCustomer, actOnBehalfOfCustomerMarket, user]);

    return (
        <PageProvider page={page}>
            <Layout hideHeader={hideHeader} hideBreadcrumb={hideBreadcrumb} isMyPage={isMyPagePage}>
                <Page page={page} />
            </Layout>
        </PageProvider>
    );
};

/**
 * Helper method for looking up an array of headers
 */
const lookupHeaders = (headers: IncomingHttpHeaders, headerKeys: string[]) => {
    return headerKeys.reduce((result, key) => {
        const value = headers[key];

        if (value != undefined) {
            result[key] = String(value);
        }

        return result;
    }, {} as { [key: string]: string });
};

/**
 * Fetch the page and return as props
 * CacheControl is set based on the response header from the page request
 */
export const getDynamicPageProps: GetServerSideProps<DynamicPageProps> = async (context) => {
    const { res, req, resolvedUrl, query } = context;
    const headersFromCMS = ['cache-control'];

    // Forward headers to the CMS
    const headersToCMS = ['accept-language', 'cf-ipcountry', 'X-Forwarded-For'];
    const marketHeader = 'CultureInfo';
    const requestHeaders = lookupHeaders(req.headers, headersToCMS);

    const {
        url,
        cultureInfo,
        urlWithoutMarketSlug,
        market: resolvedMarket,
        culture: resolvedCulture,
    } = getCultureInfo(resolvedUrl);

    // Try to get culture info and market from cookies.
    const cultureSelectedByUserCookie = req.headers['cookie']
        ?.split('; ')
        .find((entry) => entry.startsWith(marketKeys.cultureSelectedByUser))
        ?.split('=')?.[1];

    const marketSelectedByUserCookie = req.headers['cookie']
        ?.split('; ')
        .find((entry) => entry.startsWith(marketKeys.marketSelectedByUser))
        ?.split('=')?.[1];

    // If we have culture and market from the URL, we want to use that instead of the one stored in the cookie.
    if (cultureInfo) {
        requestHeaders[marketHeader] = cultureInfo;
    } else if (marketSelectedByUserCookie && cultureSelectedByUserCookie) {
        requestHeaders[
            marketHeader
        ] = `${cultureSelectedByUserCookie}-${marketSelectedByUserCookie}`.toLowerCase();
    }

    // Resolving impersonation details
    const actOnBehalfOf = query[ACT_ON_BEHALF_KEY] as string | undefined;
    if (actOnBehalfOf) {
        requestHeaders[ACT_ON_BEHALF_HEADER] = actOnBehalfOf;
    }
    const actOnBehalfOfMarket = query[ACT_ON_BEHALF_MARKET_KEY] as string | undefined;
    if (actOnBehalfOfMarket) {
        requestHeaders[ACT_ON_BEHALF_MARKET_HEADER] = actOnBehalfOfMarket;
    }

    if (!actOnBehalfOfMarket) {
        const actOnBehalfMarketCookie = req.headers['cookie']
            ?.split('; ')
            .find((entry) => entry.startsWith(marketKeys.marketActOnHehalfCookieKey))
            ?.split('=')?.[1];
        if (actOnBehalfMarketCookie) {
            requestHeaders[ACT_ON_BEHALF_MARKET_HEADER] = actOnBehalfMarketCookie;
        }
    }

    // If not behind proxy we still want to provide the client IP.
    if (!requestHeaders['X-Forwarded-For'] && req.socket.remoteAddress) {
        requestHeaders['X-Forwarded-For'] = req.socket.remoteAddress;
    }

    const session = await getSession({ req });
    if (session?.accessToken) {
        requestHeaders['Authorization'] = `Bearer ${session.accessToken}`;
    }

    try {
        const isInitialLoad = getIsInitialLoad(context);
        const { data: page, headers } = await pageResolver(url, requestHeaders);
        const { culture: pageCulture, market: pageMarket } = (page as IPages) || {};

        if (pageCulture && pageMarket) {
            const pageMarketLanguage = `${pageCulture}-${pageMarket}`.toLowerCase();
            const marketExists = pageMarketLanguage === cultureInfo;

            if (!marketExists) {
                return {
                    redirect: {
                        destination: `/${pageMarketLanguage}${
                            urlWithoutMarketSlug && '/' + urlWithoutMarketSlug
                        }`,
                        permanent: false,
                    },
                };
            }
        }

        if (page.type === 'redirect') {
            const { destination, permanent } = page;
            const nextUrl = getCultureInfoLink(destination, resolvedCulture, resolvedMarket);
            return { redirect: { destination: nextUrl, permanent } };
        }

        // Map headers from page response.
        // Allows the CMS to handle how pages are cached etc.
        headersFromCMS.forEach((headerKey) => {
            const header = headers.get(headerKey);
            header && res.setHeader(headerKey, header);
        });

        const response: GetServerSidePropsResult<DynamicPageProps> = {
            props: {
                page,
            },
        };

        if (actOnBehalfOf) {
            (response.props as DynamicPageProps)[ACT_ON_BEHALF_KEY] = actOnBehalfOf;
        }

        if (actOnBehalfOfMarket) {
            (response.props as DynamicPageProps)[ACT_ON_BEHALF_MARKET_KEY] = actOnBehalfOfMarket;
        }

        if (isInitialLoad) {
            /**
             * Create a new `QueryClient` for server-side queries. This stores prefetched
             * data, which is added as the de-hydrated props on our DynamicPage.
             * The client is created on each request, to ensure no data is shared between
             * requests/users.
             */
            const queryClient = new QueryClient();
            await prefetchData({
                serverContext: context,
                httpHeaders: requestHeaders,
                page,
                queryClient,
            });

            /**
             * There's some issues with dehydrated infinite queries, which can be
             * mitigated by doing this parse/stringify trick.
             * See https://github.com/TanStack/query/issues/1458#issuecomment-747716357
             */
            const dehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient)));

            /**
             * I'm not sure why this isn't garbage-collected, but apparently we save
             * server memory by clearing it manually.
             */
            queryClient.clear();

            (response.props as DynamicPageProps).dehydratedState = dehydratedState;
        }

        return response;
    } catch (error) {
        console.error(`Faild loading: ${resolvedUrl}`);
        if (error && typeof error === 'object') {
            console.error((error as { stack: unknown }).stack);
        }

        return {
            notFound: true,
        };
    }
};
