import React, { FC, ReactNode, useState } from 'react';
import { AnimationProps, motion } from 'framer-motion';
import { StyledSearchResults } from './search-results-styles';
import { Headline } from '$shared/components/headline';
import { SearchResultsSkeleton } from '../search-results-skeleton/search-results-skeleton';

interface SearchResultsProps {
    children: (animationProps: AnimationProps) => ReactNode;
    className?: string;
    isBusy: boolean;
    itemsLength: number;
    skeletonItemsLength: number;
    title?: string;
}

export const SearchResults: FC<SearchResultsProps> = (props) => {
    const [animation] = useState<AnimationProps>({
        initial: { opacity: 0 },
        animate: { opacity: 1 },
    });

    if (!props.isBusy && !props.itemsLength) return null;

    return (
        <StyledSearchResults className={props.className}>
            {props.title && <Headline as="h3" children={props.title} size={5} />}

            {props.isBusy && <SearchResultsSkeleton length={props.skeletonItemsLength} />}

            {!props.isBusy && <motion.ol children={props.children(animation)} {...animation} />}
        </StyledSearchResults>
    );
};
