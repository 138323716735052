import styled from '@emotion/styled';

export const StyledButtonIcon = styled.button<{ maxHeight?: number }>(({ maxHeight }) => ({
    appearance: 'none',
    border: 'none',
    background: 'none',
    padding: '0',
    cursor: 'pointer',
    position: 'relative',
    height: 'auto',
    maxHeight: maxHeight,
}));
