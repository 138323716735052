import { breakpoints } from '$theme';
import styled from '@emotion/styled';

const StyledContainer = styled.div(({ theme }) => ({
    maxWidth: theme.grid.maxWidth,
    width: '100%',
    margin: '0 auto',
    padding: `0 ${theme.space.gutter}`,

    [breakpoints.xl]: {
        padding: 0,
    },
}));

export const Container = StyledContainer;

export default Container;
