import React from 'react';
import Headline from '$shared/components/headline';
import { IArticleFilterItem, ICategory, IM160ArticleFilterModule } from '~/lib/data-contract';
import {
    Styled130,
    Styled130Header,
    StyledArticleNavButton,
    StyledNavListGrid,
    StyledNavListGridFilterCol,
    StyledNavListGridHeaderCol,
} from './style';
import Link from '$shared/components/link';
import { ModuleContainer } from '../module-container';
import { Article } from '$shared/components/article';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import { toggleParamInQuery } from '$shared/utils/query.helper';
import { useTranslation } from '$shared/utils';
import { useRawHtmlWithNewLinesAsBreaks } from '$shared/components/raw-html';
import { getEpiButtonVariant } from '$theme';

export type M160ArticleFilterProps = Omit<IM160ArticleFilterModule, 'articleItems'> & {
    articleItems?: (IArticleFilterItem & { id?: string })[]; // BE forgot to put ID on the model, but it's there
};

export const M160ArticleFilter = ({
    headline,
    callToAction,
    articleItems,
    areaCategories,
    topicCategories,
    typeCategories,
    id,
}: M160ArticleFilterProps) => {
    const { asPath, query } = useKyoceraRouter();
    const { slug, ...currentQuery } = query || {};

    const { translate } = useTranslation();

    const [pathname] = asPath.split('?');

    const navButtonRenderer = ({ label, id, type }: ICategory) => {
        const { isActive, query } = toggleParamInQuery({
            query: currentQuery,
            key: type,
            value: id,
        });

        return (
            <StyledArticleNavButton
                scroll={false} //to prevent filter from jumping to top
                key={type + id}
                active={isActive}
                href={{
                    pathname,
                    query,
                }}
            >
                {label}
            </StyledArticleNavButton>
        );
    };

    return (
        <ModuleContainer id={id}>
            <Styled130>
                <Styled130Header>
                    <Headline
                        children={useRawHtmlWithNewLinesAsBreaks(headline?.text || '')}
                        as={headline?.tag || 'h4'}
                        size={headline?.style || 2}
                        hideOnMobile={headline?.hideOnMobile}
                    ></Headline>

                    {headline?.text
                        ? callToAction?.text && (
                              <Link
                                  variant={getEpiButtonVariant(callToAction?.style)}
                                  href={callToAction?.url}
                                  children={callToAction?.text}
                              />
                          )
                        : ''}
                </Styled130Header>
                <StyledNavListGrid>
                    {/* areaCategories ROW */}
                    {!!areaCategories?.length && (
                        <>
                            <StyledNavListGridHeaderCol>
                                {translate('articles.filter.area-category-header')}
                            </StyledNavListGridHeaderCol>
                            <StyledNavListGridFilterCol>
                                {areaCategories?.map(navButtonRenderer)}
                            </StyledNavListGridFilterCol>
                        </>
                    )}

                    {/* topicCategories ROW */}
                    {!!topicCategories?.length && (
                        <>
                            <StyledNavListGridHeaderCol>
                                {translate('articles.filter.topic-category-header')}
                            </StyledNavListGridHeaderCol>
                            <StyledNavListGridFilterCol>
                                {topicCategories?.map(navButtonRenderer)}
                            </StyledNavListGridFilterCol>
                        </>
                    )}

                    {/* typeCategories ROW */}
                    {!!typeCategories?.length && (
                        <>
                            <StyledNavListGridHeaderCol>
                                {translate('articles.filter.type-category-header')}
                            </StyledNavListGridHeaderCol>
                            <StyledNavListGridFilterCol>
                                {typeCategories?.map(navButtonRenderer)}
                            </StyledNavListGridFilterCol>
                        </>
                    )}
                </StyledNavListGrid>

                {articleItems?.map(
                    ({
                        categories,
                        headline,
                        id,
                        media,
                        publishedDate,
                        link,
                        formattingCulture,
                    }) => (
                        <Article
                            media={media}
                            link={link}
                            headline={headline?.text}
                            headlineStyle={'h3'}
                            headlineTag={'h3'}
                            hideOnMobile={headline?.hideOnMobile}
                            tags={categories?.map((c) => c.label)}
                            key={id}
                            publishedDate={publishedDate}
                            dateFormattingCulture={formattingCulture}
                        />
                    )
                )}
            </Styled130>
        </ModuleContainer>
    );
};
