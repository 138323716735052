import * as React from 'react';
import { FC, Fragment, useState } from 'react';
import { useMedia } from 'react-use';
import { IBreadcrumb } from '~/lib/data-contract';

import {
    StyledBreadcrumbs,
    StyledBreadcrumbIcon,
    StyledBreadcrumbLink,
    StyledBreadcrumbText,
    StyledDotsDiv,
} from './style';

export interface BreadcrumbProps extends IBreadcrumb {
    // choose textsize
    fontSize?: 'xs' | 'sm' | 'md' | 'lg' | 'default';
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ items, fontSize }) => {
    const [expand, setExpand] = useState(false);
    const tinyScreen = useMedia(`(max-width: ${600}px)`, false);

    const compress = tinyScreen && (items?.length || 0) > 4;

    if (items && items?.length < 2) {
        return null;
    }

    return (
        <StyledBreadcrumbs>
            {items?.map(({ title, url }, index) => (
                <Fragment key={`${title}` + index}>
                    {(expand ||
                        !compress ||
                        items?.length < 4 || // If we are to compress the breadcrumbs, only show index 1, 3, 4
                        index === 1 ||
                        index > items.length - 3) && (
                        <StyledBreadcrumbLink
                            title={title}
                            href={index === items.length - 1 ? undefined : url}
                        >
                            <StyledBreadcrumbText fontSize={fontSize}>{title}</StyledBreadcrumbText>
                            <StyledBreadcrumbIcon icon={'chevronRight'} />
                        </StyledBreadcrumbLink>
                    )}
                    {!expand && compress && items.length > 4 && index == 2 && (
                        <StyledDotsDiv onClick={() => setExpand(true)}>...</StyledDotsDiv>
                    )}
                </Fragment>
            ))}
        </StyledBreadcrumbs>
    );
};

export default Breadcrumb;
