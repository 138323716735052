import React, { FC } from 'react';
import { INavigationTopLevelNodes } from '~/lib/data-contract';
import { StyledMegaMenu, StyledMegaMenuContainer } from './style';
import MegaSubMenu from './mega-sub-menu/mega-sub-menu';

export type MegaMenuProps = {
    menu?: INavigationTopLevelNodes;
};

export const MegaMenu: FC<MegaMenuProps> = ({ menu }) => {
    return (
        <StyledMegaMenu isActive={!!menu}>
            <StyledMegaMenuContainer>
                {menu && <MegaSubMenu menu={menu}></MegaSubMenu>}
            </StyledMegaMenuContainer>
        </StyledMegaMenu>
    );
};
export default MegaMenu;
