import React, { useEffect, useState } from 'react';
import {
    Option,
    SelectorElement,
} from '$shared/components/form/components/controllable-elements/selector-element';
import { IFacetComponent, IFacetOption } from '..';
import { useTranslation } from '$utils/translation';

export type IFacetSelectorProps<T> = IFacetComponent<T> & {
    allowMultiSelect?: boolean;
    menuPlacement?: 'top' | 'bottom' | 'auto';
};

const FacetSelector = <T,>({
    options,
    handleChange,
    allowMultiSelect,
    menuPlacement,
}: IFacetSelectorProps<T>) => {
    const { translate } = useTranslation();
    const [mappedOptions, setMappedOptions] = useState<Option<IFacetOption<T>>[]>();

    useEffect(() => {
        if (options !== undefined) {
            setMappedOptions(
                options?.map(
                    (o): Option<IFacetOption<T>> => ({
                        value: o.name || '',
                        checked: o.selected,
                        label: o.name,
                        option: o,
                        isDisabled: o.disabled,
                    })
                )
            );
        }
    }, [options]);

    type optiontype<T> =
        | Option<IFacetOption<T>>
        | readonly Option<IFacetOption<T>>[]
        | null
        | undefined;

    const mapHandleChangeOptions = (change: optiontype<T>) =>
        (Array.isArray(change) ? change : [change]).map((o) => o?.option);

    const handleSelect = (option: optiontype<T>) => {
        const optionArr: Option<IFacetOption<T>>[] = Array.isArray(option) ? option : [option];
        const shouldHandleChangeOverwrite =
            allowMultiSelect || (optionArr.length === 1 ? !optionArr[0]?.checked : true);
        handleChange?.(mapHandleChangeOptions(option), shouldHandleChangeOverwrite);

        const interimOptions = mappedOptions?.map((mappedOption) => {
            const foundOption = optionArr.find((o) => o?.value === mappedOption.value);
            return foundOption
                ? { ...mappedOption, checked: allowMultiSelect ? true : !foundOption.checked }
                : { ...mappedOption, checked: allowMultiSelect ? mappedOption.checked : false };
        });

        setMappedOptions(interimOptions);
    };

    const filterOptions = {
        stringify: (option: Option<IFacetOption<T>>) =>
            `${option.label} ${option.label?.replaceAll('.', ',')}`, // Customer request: KYOC-1148 - joins search string, so both comma and period are treated the same. "10.5" becomes "10.5 10,5" when the filter-option looks for matches
    };

    const clearAll = () => {
        handleChange?.(mapHandleChangeOptions(mappedOptions));
    };

    return (
        <SelectorElement
            allowMultiSelect={allowMultiSelect}
            options={mappedOptions || []}
            onClear={clearAll}
            menuPlacement={menuPlacement}
            filterOptionsConfig={filterOptions}
            controlledValue={mappedOptions?.filter((o) => o.checked)}
            placeholder={translate('product-filter.select')}
            onChange={(option) => option && handleSelect(option)}
        />
    );
};

export default FacetSelector;
