import Sidebar from '$shared/components/sidebar-navigation/sidebar';
import React from 'react';
import { IP60ModulePage } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';
import { StyledAsideArea, StyledContentArea, StyledMainArea, StyledModulesWrapper } from './style';

type P60ModulePageProps = IP60ModulePage;

export const P60ModulePage = ({
    pageElements,
    topAreaPageElements,
    hideSideMenu,
}: P60ModulePageProps) => (
    <>
        <StyledModulesWrapper>
            {/* The wrapping divs are here to make a last-child selector for the DynamicBlockList work */}
            <DynamicBlockList elements={topAreaPageElements} />
        </StyledModulesWrapper>
        <StyledMainArea>
            {!hideSideMenu && (
                <StyledAsideArea>
                    <Sidebar />
                </StyledAsideArea>
            )}
            <StyledContentArea hasSideMenu={!hideSideMenu}>
                <DynamicBlockList elements={pageElements} />
            </StyledContentArea>
        </StyledMainArea>
    </>
);
