import { Input } from '$shared/components/form';
import { useTranslation } from '$shared/utils';
import {
    StyledCheckoutBlockSpacing,
    StyledCheckoutHeadline,
    StyledOrderReferenceText,
    StyledOrderReferenceWrapper,
} from '$templates/pages/components/P310CheckoutPage/style';
import { FC } from 'react';

export const OrderReference: FC = () => {
    const { translate } = useTranslation();

    return (
        <StyledCheckoutBlockSpacing>
            <StyledCheckoutHeadline
                children={translate('checkout-page.order-reference')}
                size="h3"
            ></StyledCheckoutHeadline>

            <StyledOrderReferenceText
                children={translate('checkout-page.order-reference-help-text')}
            ></StyledOrderReferenceText>

            <StyledOrderReferenceWrapper>
                <Input
                    name="customerReferenceNumber"
                    label={translate('checkout-page.order-reference')}
                    rules={{
                        maxLength: {
                            value: 16,
                            message: translate(
                                'checkout-page.validation-message-order-reference-length'
                            ),
                        },
                        required: translate('checkout-page.validation-message-order-reference'),
                    }}
                />
            </StyledOrderReferenceWrapper>
        </StyledCheckoutBlockSpacing>
    );
};
