import { FC, useMemo } from 'react';
import { StyledSearchResultSkeleton } from './search-results-skeleton-styles';

interface SearchResultsSkeletonProps {
    length: number;
}

export const SearchResultsSkeleton: FC<SearchResultsSkeletonProps> = (props) => {
    const items = useMemo(() => [...Array(props.length)], [props.length]);
    return (
        <>
            {items.map((_, i) => (
                <StyledSearchResultSkeleton height="71px" key={i} pulsating />
            ))}
        </>
    );
};
