import React from 'react';
import { useBasketUpsert } from '$shared/hooks/useBasket/useBasket';
type BasketPageProviderProps = {
    isLoading?: boolean;
    upsertBasket?: ReturnType<typeof useBasketUpsert>['upsertBasket'];
};

export const BasketPageContext = React.createContext<BasketPageProviderProps>({});

export const BasketPageProvider = ({
    children,
    ...otherProps
}: React.PropsWithChildren<BasketPageProviderProps>) => (
    <BasketPageContext.Provider
        value={{
            ...otherProps,
        }}
    >
        {children}
    </BasketPageContext.Provider>
);
