import { theme } from '$theme';
import styled from '@emotion/styled';

export const StyledDeliveryOptions = styled.div({
    marginBottom: theme.space[4],
});

export const StyledDeliveryDescription = styled.p({
    color: theme.colors.grey90,
    lineHeight: theme.lineHeights.base,
    marginBottom: theme.space[4],
});

export const StyledRadioButtonGroup = styled.div({
    display: 'flex',
    flexFlow: 'column',
    gap: theme.space[3],
    marginBottom: theme.space[4],
});

export const StyledPickupWrapper = styled.div({
    borderTop: `1px solid ${theme.colors.grey40}`,
    paddingTop: theme.space[4],
    marginBottom: theme.space[4],
});
