import React, { FC, useEffect } from 'react';
import { useCheckoutSteps } from '~/features/checkout/hooks/useCheckoutSteps';
import { DeliveryIntervals } from '$features/checkout';
import { BasketSummaryHeaderLink } from '$shared/components/basket-summary/basket-summary-header-link';
import { useFrame, useTranslation } from '$shared/utils';
import { DeliveryOptions } from './delivery-options/delivery-options';
import { BasketSummary } from '$shared/components/basket-summary/basket-summary';
import { useCreateQuote } from '$features/checkout/hooks/use-create-quote';
import { useOrderStore } from '$features/checkout/hooks/use-order-store';
import { QuoteSummaryLines } from '../shared/quote-summary-lines';
import {
    StyledCheckoutButton,
    StyledCheckoutHeadline,
    StyledCheckoutTwoColWrapper,
} from '$templates/pages/components/P310CheckoutPage/style';

interface DeliveryStepProps {
    companiesWithPickup?: string;
}

export const DeliveryStep: FC<DeliveryStepProps> = ({ companiesWithPickup }) => {
    const { basketPage } = useFrame();
    const { translate } = useTranslation();
    const { goToNextStep } = useCheckoutSteps();

    const { pickupOrder, splitOrder } = useOrderStore();

    const { createNewQuote, creatingQuoteIsLoading } = useCreateQuote();

    useEffect(() => {
        createNewQuote();
    }, [splitOrder, pickupOrder]);

    return (
        <StyledCheckoutTwoColWrapper>
            <div>
                <StyledCheckoutHeadline size="h3">
                    {translate('checkout-page.delivery-details')}
                </StyledCheckoutHeadline>
                <DeliveryOptions companiesWithPickup={companiesWithPickup} />
                <DeliveryIntervals />
            </div>
            <div>
                <BasketSummary
                    actionLink={
                        <StyledCheckoutButton
                            onClick={goToNextStep}
                            isLoading={creatingQuoteIsLoading}
                            disabled={creatingQuoteIsLoading}
                        >
                            {translate('checkout-page.continue')}
                        </StyledCheckoutButton>
                    }
                    headerLink={
                        <BasketSummaryHeaderLink
                            children={translate('checkout-page.edit-order')}
                            href={basketPage?.url || ''}
                        />
                    }
                    listItems={<QuoteSummaryLines />}
                    showHeader={true}
                />
            </div>
        </StyledCheckoutTwoColWrapper>
    );
};
