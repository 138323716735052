import { StyledInvalidMessage } from '$shared/components/form/components/invalid-message';
import { useTranslation } from '$shared/utils';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormContext } from 'react-hook-form';
import { IReCaptcha } from '~/lib/data-contract';

export type ReCAPTCHAFieldProps = IReCaptcha;

const RECAPTCHA_FIELD_NAME = 'g-recaptcha-response';

export const ReCAPTCHAField = forwardRef<ReCAPTCHA | null, ReCAPTCHAFieldProps>(
    ({ siteKey }, ref) => {
        const { translate } = useTranslation();
        const recaptchaRef = useRef<ReCAPTCHA>(null);
        useImperativeHandle(ref, () => recaptchaRef.current as ReCAPTCHA, [ref]);

        const {
            formState,
            getFieldState,
            register,
            setValue,
        } = useFormContext();

        const { error } = getFieldState(RECAPTCHA_FIELD_NAME, formState);
        const errorMessage = error?.message;

        useEffect(() => {
            register(RECAPTCHA_FIELD_NAME, {
                required: translate('forms.fields.recaptcha.required-field-message'),
            });
        }, []);

        const onVerifyCaptcha = (token?: string | null) => {
            setValue(RECAPTCHA_FIELD_NAME, token);
        };

        return siteKey ? (
            <>
                <ReCAPTCHA onChange={onVerifyCaptcha} sitekey={siteKey} ref={recaptchaRef} />
                {errorMessage && <StyledInvalidMessage children={errorMessage} />}
            </>
        ) : null;
    }
);

export default ReCAPTCHAField;
