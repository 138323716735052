import React, { InputHTMLAttributes, useImperativeHandle, useRef, useState } from 'react';
import { StyledInvalidMessage } from '../../invalid-message';
import { useInputField } from '../../../hooks/useInputField';
import { StyledHelpText } from '../../help-text';
import {
    StyledInputFieldWrapper,
    StyledLabel,
    StyledInput,
    StyledAdditionalContent,
} from './styled';
import { controllableInputElementsProps } from '$shared/components/form';

export type InputElementProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> &
    controllableInputElementsProps;

export const InputElement = React.forwardRef<HTMLInputElement, InputElementProps>(
    (
        {
            className,
            invalidMessage,
            isActive,
            label,
            helpText,
            id,
            children,
            isInvalid,
            append,
            isRequired,
            value,
            helpTextPlacement,
            readOnly,
            disabled,
            ...rest
        },
        ref
    ) => {
        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        const [hasFocus, setHasFocus] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);
        const isInputActive = !!value || isActive || hasFocus;
        const isValid = !invalidMessage && !isInvalid;
        const showAsDisabled = !!readOnly || !!disabled;

        const { fieldId, helpTextId, invalidMessageId, showHelpText } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        const onFocusHandler = () => setHasFocus(true);
        const onBlueHandler = () => {
            setHasFocus(false);
        };

        const hideMobileKeyboardOnReturn = (
            keyboardEvent: React.KeyboardEvent<HTMLInputElement>
        ) => {
            const key = keyboardEvent.code || keyboardEvent.keyCode;
            if ((inputRef && key === 'Enter') || key === 13) {
                inputRef.current?.blur();
            }
        };

        return (
            <label className={className}>
                <StyledInputFieldWrapper
                    key={fieldId}
                    isValid={isValid}
                    onBlur={onBlueHandler}
                    onFocus={onFocusHandler}
                    showAsDisabled={showAsDisabled}
                >
                    <StyledInput
                        disabled={disabled}
                        id={fieldId}
                        isActive={isInputActive}
                        readOnly={readOnly}
                        ref={inputRef}
                        showAsDisabled={showAsDisabled}
                        value={value}
                        withLabel={!!label}
                        onKeyUp={hideMobileKeyboardOnReturn}
                        {...rest}
                    />

                    {label && (
                        <StyledLabel
                            children={label}
                            hasFocus={hasFocus}
                            isActive={isInputActive}
                            isValid={isValid}
                            required={isRequired}
                            showAsDisabled={showAsDisabled}
                            title={label}
                        ></StyledLabel>
                    )}
                </StyledInputFieldWrapper>

                {children}

                {append ? <StyledAdditionalContent children={append} /> : null}

                {invalidMessage ? (
                    <StyledInvalidMessage id={invalidMessageId} children={invalidMessage} />
                ) : null}

                {showHelpText ? (
                    <StyledHelpText
                        id={helpTextId}
                        children={helpText}
                        helpTextPlacement={helpTextPlacement}
                    />
                ) : null}
            </label>
        );
    }
);
