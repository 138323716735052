import React, { useRef, useState } from 'react';
import { IFormSubmitResult, IM110FormsModule } from '~/lib/data-contract';
import { Form, Input } from '$shared/components/form';
import Button from '$shared/components/button';
import { useMutation } from '@tanstack/react-query';
import { epiFormSubmissionService } from '$shared/utils/forms';
import { DynamicField } from './components/dynamic-field';
import { ReCAPTCHAField } from './components/reCAPTCHA-field';
import { StyledFormElement } from '$shared/components/form/components/form/styled';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { RawHtml } from '$shared/components/raw-html';
import {
    EpiFormErrorMessage,
    EpiFormLayout,
    EpiFormMessage,
    EpiFormRTE,
    EpiFormWrapper,
} from './style';
import { FormOverlay } from './components/form-overlay';
import { useTranslation } from '$shared/utils';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import { usePage } from '$templates/pages';
import { ModuleContainer } from '../module-container';

export const M110FormsModule = ({ form, text, id }: IM110FormsModule) => {
    if (!form) {
        return null;
    }

    const page = usePage();
    const { culture, market } = page || {};
    const { translate } = useTranslation();
    const { asPath, push } = useKyoceraRouter();
    const methods = useForm();
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const wideFormFormat =
        page.type === 'p60ModulePage' || page.type === 'p160ArticlePage' ? page.hideSideMenu : true; // Because the P60 content page, has the option of a side menu - Wide format is not always possible.
    const [showOverlay, setShowOverlay] = useState(false);
    const [submitResponseData, setSubmitResponseData] = useState<IFormSubmitResult | null>();

    const { mutate, isLoading, isError, isSuccess, reset: resetQuery } = useMutation(
        epiFormSubmissionService,
        {
            onSuccess: (data: IFormSubmitResult) => {
                if (data.redirectUrl) {
                    push(data.redirectUrl);
                } else {
                    setSubmitResponseData(data);
                    setShowOverlay(true);
                }
            },
        }
    );

    const resetForm = () => {
        resetQuery();
        methods.reset();
        recaptchaRef?.current?.reset();
        setShowOverlay(false);
        setSubmitResponseData(null);
    };

    const onSubmit = (data: any) => {
        mutate({
            form: data,
            formGuid: form.id,
            formUrl: asPath,
            validationToken: form.antiForgery?.token,
            culture,
            market,
        });
    };

    return (
        <ModuleContainer id={id}>
            <EpiFormLayout wideFormat={wideFormFormat}>
                {text?.html && (
                    <EpiFormRTE wideFormat={wideFormFormat}>
                        <RawHtml html={text?.html} />
                    </EpiFormRTE>
                )}

                <EpiFormWrapper wideFormat={wideFormFormat} hasOverlay={showOverlay}>
                    <Form methods={methods} onSubmit={onSubmit}>
                        {form?.steps?.map(({ groups }, index) => (
                            <React.Fragment key={index}>
                                {groups?.map(({ elements }, index) => (
                                    <React.Fragment key={index}>
                                        {elements?.map((element) => (
                                            <StyledFormElement key={element.name}>
                                                <DynamicField {...element} />
                                            </StyledFormElement>
                                        ))}

                                        {form?.antiForgery?.honeypotFieldName && (
                                            <Input
                                                name={form?.antiForgery?.honeypotFieldName}
                                                hideField
                                            />
                                        )}

                                        <StyledFormElement>
                                            <ReCAPTCHAField
                                                siteKey={form.antiForgery?.reCaptcha?.siteKey}
                                                ref={recaptchaRef}
                                            />
                                        </StyledFormElement>
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}
                        <StyledFormElement>
                            <Button type="submit" isLoading={isLoading} disabled={isSuccess}>
                                {translate('forms.submitting.submit-button')}
                            </Button>
                        </StyledFormElement>
                    </Form>

                    {showOverlay && (
                        <FormOverlay>
                            <EpiFormMessage>
                                <RawHtml
                                    html={
                                        submitResponseData?.message
                                            ? submitResponseData?.message
                                            : submitResponseData?.isSuccess
                                            ? translate('forms.submitting.default-success-message')
                                            : translate('forms.submitting.default-error-message')
                                    }
                                />
                            </EpiFormMessage>

                            <Button onClick={resetForm}>
                                {translate('forms.submitting.reset-form-after-success-button')}
                            </Button>
                        </FormOverlay>
                    )}

                    {isError && (
                        <EpiFormErrorMessage>
                            <RawHtml html={translate('forms.submitting.default-error-message')} />
                        </EpiFormErrorMessage>
                    )}
                </EpiFormWrapper>
            </EpiFormLayout>
        </ModuleContainer>
    );
};

export default M110FormsModule;
