import React from 'react';
import { BasketSummaryLine } from '$shared/components/basket-summary/basket-summary-line';
import { BasketSummaryTotalLine } from '$shared/components/basket-summary/basket-summary-total-line';
import { useTranslation } from '$shared/utils';
import { useReceiptStore } from '$features/checkout/hooks/use-receipt-store';

export const ReceiptSummaryLines = () => {
    const { translate } = useTranslation();
    const { receipt } = useReceiptStore();

    return (
        <>
            <BasketSummaryLine
                label={translate('receipt-page.summary.subtotal')}
                value={receipt?.orderTotalExVatExFreightPretty || ''}
            />
            <BasketSummaryLine
                label={translate('checkout-page.basket-summary.estimated-delivery-cost')}
                value={receipt?.freightPretty || ''}
            />
            <BasketSummaryLine
                label={translate('checkout-page.basket-summary.vat')}
                value={receipt?.vatPretty || ''}
            />
            <BasketSummaryTotalLine value={receipt?.orderTotalPretty || ''} />
        </>
    );
};
