import { Icon } from '$shared/components/icon';
import { theme } from '$theme';
import styled, { CSSObject } from '@emotion/styled';
import { ifNotProp } from 'styled-tools';

export const StyledCheckbox = styled.input({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    opacity: 0,
    padding: 0,

    ':hover:not([disabled])': {
        cursor: 'inherit',
    },
});

export const StyledLabel = styled.label<{ disabled?: boolean }>(({ disabled }) => ({
    cursor: disabled ? 'auto' : 'pointer',
}));

export const StyledLabelText = styled.div(({ theme }) => ({
    color: theme.colors.grey80,
    fontSize: theme.fontSizes.sm,
    lineHeight: '18px',
}));

export const checkboxIndicatorStyle: CSSObject = {
    position: 'absolute',
    inset: -1,
    border: '1px solid rgba(255, 255, 255, 0)',
    borderRadius: 'inherit',
};
export const StyledCheckboxIndicator = styled.div({
    ...checkboxIndicatorStyle,
});

export const StyledCheckboxIcon = styled(Icon)({
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
});

export const checkboxElementStyle: CSSObject = {
    appearance: 'none',
    position: 'relative',
    width: theme.space['4.5'],
    height: theme.space['4.5'],
    border: `1px solid ${theme.colors.grey70}`,
    borderRadius: 4,
    backgroundColor: theme.colors.white,
};

export const StyledCheckboxElement = styled.div({ ...checkboxElementStyle });

export const StyledCheckboxWrapper = styled.div<{ valid: boolean }>(
    ({ theme }) => ({
        alignItems: 'center',
        display: 'grid',
        gap: theme.space[3],
        gridTemplateColumns: `25px 1fr`,
        isolation: 'isolate',
        [`${StyledCheckboxIndicator}`]: {
            ':after': {
                content: '""',
                position: 'absolute',
                inset: '0 0 auto auto',
                borderRadius: 'inherit',
                width: '100%',
                height: '100%',
                backgroundColor: 'inherit',
                zIndex: 1,
                pointerEvents: 'none',
                transition: 'width 300ms ease-in-out',
            },
        },
        // HOVER
        ':hover, :focus-within:not([disabled])': {
            [`${StyledCheckboxIndicator}`]: {
                borderColor: theme.colors.blue90,
                backgroundColor: theme.colors.grey10,
            },
            [`input:checked:not([disabled]) + ${StyledCheckboxIndicator}`]: {
                borderColor: theme.colors.blue90,
                backgroundColor: theme.colors.blue90,
            },
        },

        //FOCUS
        ':focus-within:not([disabled])': {
            [`${StyledCheckboxIndicator}`]: {
                borderColor: theme.colors.blue50,
                outline: `3px solid ${theme.colors.blue50}`,
            },
        },
        // CHECKED
        [`input:checked ~ ${StyledCheckboxIcon}`]: {
            display: 'block',
        },
        [`input:checked + ${StyledCheckboxIndicator}`]: {
            borderColor: theme.colors.blue90,
            backgroundColor: theme.colors.blue,
            transition: 'background-color 50ms ease-in-out',

            ':after': {
                width: '0',
                height: '100%',
            },
        },
        // DISABLED
        [`input:disabled:not(:checked)`]: {
            borderColor: theme.colors.grey50,
            backgroundColor: theme.colors.grey20,
        },
        [`input:disabled + ${StyledCheckboxIndicator}`]: {
            borderColor: theme.colors.grey50,
            backgroundColor: theme.colors.grey30,
        },
        [`input:disabled ~ ${StyledCheckboxIcon}`]: {
            color: theme.colors.grey50,
        },
    }),
    ifNotProp('valid', ({ theme }) => ({
        [`${StyledLabel}`]: {
            color: theme.colors.negative,
        },
    }))
);

export const StyledCheckboxHelpTexts = styled.div(({ theme }) => ({
    marginLeft: `calc(25px + ${theme.space[3]})`,
}));
