import { HierarchyTree } from '$features/search/hierarchy-tree/hierarchy-tree';
import { StyledAccordion, StyledAccordionContent } from '$shared/components/accordion/style';
import Button from '$shared/components/button';
import { Container } from '$shared/components/container';
import { Headline } from '$shared/components/headline';
import { maxWidthStyles } from '$shared/components/max-width';
import { ProductList } from '$shared/components/product-list';
import { ShowMoreButton } from '$shared/components/show-more-button';
import { ProductListSkeleton } from '$shared/components/skeleton/product-list-skeleton/product-list-skeleton';
import Spinner from '$shared/components/spinner';
import { breakpoints } from '$theme';
import styled from '@emotion/styled';

export const StyledHierarchyTree = styled(HierarchyTree)<{ isDesktop: boolean }>(
    ({ isDesktop, theme }) => ({
        gridArea: '1 / 1 / 3 / 2',
        marginInline: isDesktop ? 0 : theme.space[4],
    })
);

export const StyledSearchProductsTab = styled.div(({ theme }) => ({
    alignItems: 'flex-start',
    gap: theme.space['6.5'],
    grid: 'auto 1fr / 22em 1fr',

    [breakpoints.frame]: {
        display: 'grid',
    },

    [`${StyledAccordionContent}`]: {
        padding: 0,
        overflow: 'initial' // Filter dropdowns in the bottom is cut off because of overflow
    },
}));

export const StyledSpinner = styled(Spinner)(({ theme }) => ({
    display: 'flex',
    margin: `${theme.space['5.5']} auto ${theme.space[4.5]}`,
}));

export const StyledCategoriesButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    margin: `${theme.space['5.5']} 0 ${theme.space[4.5]}`,
    width: '100%',

    [breakpoints.frame]: {
        display: 'none',
    },
}));

export const StyledProductListSkeleton = styled(ProductListSkeleton)(() => ({
    gridArea: '1 / 2 / 2 / 3',
}));

export const StyledProductList = styled(ProductList)(() => ({
    gridArea: '1 / 2 / 2 / 3',
}));

export const StyledHeading = styled(Headline)(() => ({
    gridArea: '1 / 1 / 2 / 3',
}));

export const StyledShowMoreButton = styled(ShowMoreButton)(({ theme }) => ({
    gridArea: '2 / 2 / 3 / 3',
    justifySelf: 'center',
    margin: `${theme.space['5.5']} 0 0`,
    width: '100%',
}));

export const StyledHierarchyDesktopWrapper = styled.div(({ theme }) => ({
    paddingLeft: theme.space[3],
}));

export const StyledAccordionHeader = styled.div(({ theme }) => ({
    fontSize: theme.fontSizes.md,
    fontWeight: theme.fontWeights.semiBold,
}));

export const StyledAccordionContainer = styled(Container)({
    [`${StyledAccordion}:first-of-type`]: {
        borderTop: 'none',
    },
});

export const MobileSearchProductsHeader = styled.div(({ theme }) => ({
    position: 'sticky',
    background: theme.colors.white,
    top: 0,
    right: 0,
    left: 0,
    zIndex: theme.zIndices[1],
    gridArea: 'name',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.space[4]} 0`,

    p: {
        fontSize: theme.fontSizes.sm,
        textTransform: 'uppercase',
        color: theme.colors.grey90,
        lineHeight: '140%',
    },

    [breakpoints.frame]: {
        ...maxWidthStyles(theme),
        position: 'static',
        display: 'flex',
    },
}));

export const MobileCta = styled.button(({ theme }) => ({
    all: 'unset',
    lineHeight: '150%',
    fontWeight: theme.fontWeights.semiBold,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: theme.space[2],
}));
