import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { HierarchyTreeNode } from './hierarchy-tree-node';
import {
    StyledHierarchyTree,
    StyledHierarchyCount,
    StyledHierarchyLabel,
    StyledHierarchyChevron,
    StyledHierarchyGroup,
    StyledHierarchyTreeItem,
    StyledHierarchyNode,
    StyledHierarchyRootTreeItem,
} from './hierarchy-tree-styles';
import { useHierarchyTreeState } from './use-hierarchy-tree-state';

interface HierarchyTreeProps {
    className?: string;
    nodes: HierarchyTreeNode[];
    onSelectionChanged: (selection: string | undefined) => void;
    selection: string | undefined;
    hierarchyId?: string;
}

let increment = 1;

export const HierarchyTree: FC<HierarchyTreeProps> = (props) => {
    const [idPrefix] = useState(`hierarchy-tree-${increment++}-`);
    const scrollToElement = useRef<HTMLDivElement>(null);
    const [currentHierarchyId, setCurrentHierarchyId] = useState<string>();

    useEffect(() => {
        if(!currentHierarchyId && !!props.hierarchyId) {
            setCurrentHierarchyId(props.hierarchyId);
            return
        }
        if (!!currentHierarchyId && props.hierarchyId && currentHierarchyId !== props.hierarchyId) {
            setCurrentHierarchyId(props.hierarchyId);
            setFocusedNode('');
            validateExpandedNodes();
        }
    }, [props.hierarchyId]);

    const {
        activate,
        expandedNodes,
        focusedNode,
        focusNode,
        setFocusedNode,
        onKeyDown,
        expandedNodesInitialized,
        validateExpandedNodes
    } = useHierarchyTreeState(props.nodes, props.onSelectionChanged, props.selection);

    // Validating expanded nodes when nodes change. Duo to some race condition, sometimes it doesn't initialize properly
    useEffect(() => {
        if (
            props.nodes &&
            expandedNodesInitialized &&
            !props.selection &&
            currentHierarchyId === props.hierarchyId
        ) {
            validateExpandedNodes();
        }
    }, [props.nodes]);

    const renderNodeProps = useCallback(
        (node: HierarchyTreeNode, isRootNode: boolean) => {
            const hasChildren = !!node.children.length;
            const canSelect = !hasChildren && !!node.id;
            const isFocused = focusedNode === node.path;
            const isExpanded = expandedNodes.includes(node.path);
            const isSelected = canSelect && props.selection === node.id;

            return {
                children: (
                    <>
                        <StyledHierarchyNode
                            className={isFocused ? 'focused' : undefined}
                            isSelected={isSelected}
                            hasChildren={hasChildren}
                            onClick={(e) => {
                                focusNode(node.path);
                                activate(node.path);
                                e.stopPropagation();
                            }}
                            ref={isFocused ? scrollToElement : undefined}
                        >
                            <StyledHierarchyLabel>{node.label}</StyledHierarchyLabel>
                            {!hasChildren && <StyledHierarchyCount children={`${node.count}`} />}
                            {hasChildren && (
                                <StyledHierarchyChevron icon="chevronUp" isOpen={isExpanded} />
                            )}
                        </StyledHierarchyNode>

                        {hasChildren && (
                            <StyledHierarchyGroup
                                isOpen={isExpanded}
                                isRootNode={isRootNode}
                                role="group"
                            >
                                {node.children.map((child) => (
                                    <StyledHierarchyTreeItem {...renderNodeProps(child, false)} />
                                ))}
                            </StyledHierarchyGroup>
                        )}
                    </>
                ),
                id: idPrefix + node.path,
                key: node.id || node.path,
                role: 'treeitem',
                'aria-selected': canSelect ? isSelected : undefined,
                'aria-expanded': isExpanded,
            };
        },
        [activate, focusedNode, focusNode, idPrefix, expandedNodes]
    );

    useLayoutEffect(() => {
        if (scrollToElement.current) {
            scrollToElement.current.scrollIntoView({
                block: 'nearest',
            });
        }
    }, [scrollToElement.current]);

    const rootNodes = props.nodes.map((node) => (
        <StyledHierarchyRootTreeItem {...renderNodeProps(node, true)} />
    ));

    return rootNodes.length ? (
        <StyledHierarchyTree
            aria-activedescendant={idPrefix + focusedNode}
            children={rootNodes}
            className={props.className}
            onKeyDown={onKeyDown}
            role="tree"
            tabIndex={0}
        />
    ) : null;
};
