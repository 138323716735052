import React, { FC } from 'react';
import { AnimationProps, motion } from 'framer-motion';
import { HighlightWord } from '$shared/components/highlight-word';
import { HighlightDescription, StyledSearchContent } from './search-result-styles';
import { ISearchResultItemViewModel } from '~/lib/data-contract';

interface SearchResultPagesProps {
    animation: AnimationProps;
    pages: ISearchResultItemViewModel[];
    highlightMatch: string;
}

export const SearchResultPages: FC<SearchResultPagesProps> = (props) => {
    return (
        <>
            {props.pages.map((page) => (
                <motion.li {...props.animation} key={page.id}>
                    <StyledSearchContent href={page.link.url}>
                        <HighlightWord
                            match={props.highlightMatch}
                            input={page.searchTitle}
                            fontSize="lg"
                        />
                        <HighlightDescription
                            match={props.highlightMatch}
                            input={page.description}
                            oneLine
                        />
                    </StyledSearchContent>
                </motion.li>
            ))}
        </>
    );
};
