import { Skeleton } from '..';
import {
    StyledPriceStockWrapper,
    StyledProductLineBasket,
    StyledProductLineFavorites,
    StyledProductLineGrid,
    StyledProductLineImage,
    StyledProductLineName,
    StyledProductLineNumber,
    StyledProductLinePrice,
    StyledProductLineStatus,
    StyledProductWrapper,
} from '../../product-line/style';
import { StyledSkeletonListLineImage } from './style';

export const ProductLineSkeleton = () => (
    <StyledProductLineGrid>
        <StyledProductLineImage noImage>
            <StyledSkeletonListLineImage>
                <Skeleton pulsating width={'100%'} css={{ aspectRatio: '1' }} />
            </StyledSkeletonListLineImage>
        </StyledProductLineImage>

        <StyledProductLineName>
            <Skeleton pulsating height={'1em'} width={'80%'} css={{ marginBottom: '2px' }} />
            <Skeleton pulsating height={'1em'} width={'50%'} css={{ marginTop: '2px' }} />
        </StyledProductLineName>

        <StyledProductLineNumber>
            <Skeleton pulsating height={'1em'} width={'50%'} css={{ marginBottom: '2px' }} />
            <Skeleton pulsating height={'1em'} width={'80%'} css={{ marginTop: '2px' }} />
        </StyledProductLineNumber>
        <StyledPriceStockWrapper>
            <StyledProductLineStatus>
                <Skeleton
                    pulsating
                    height={'1.5em'}
                    width={'100%'}
                    css={{ maxWidth: '100px', minWidth: '60px' }}
                />
            </StyledProductLineStatus>
            <StyledProductLinePrice>
                <Skeleton
                    pulsating
                    height={'1em'}
                    width={'100%'}
                    css={{ maxWidth: '100px', minWidth: '60px' }}
                />
            </StyledProductLinePrice>
        </StyledPriceStockWrapper>
        <StyledProductWrapper>
            <StyledProductLineFavorites>
                <Skeleton pulsating height={'24px'} width={'24px'} css={{ marginRight: '5px' }} />
            </StyledProductLineFavorites>
            <StyledProductLineBasket>
                <Skeleton pulsating height={'40px'} width={'50%'} css={{ marginRight: '5px' }} />
                <Skeleton pulsating height={'40px'} width={'40%'} />
            </StyledProductLineBasket>
        </StyledProductWrapper>
    </StyledProductLineGrid>
);
