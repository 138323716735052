import { StyledExtraServiceWrapper } from './style';
import React, { FC } from 'react';
import { useTranslation } from '$shared/utils';
import { ExtraServicesItem } from './extra-services-item';
import { usePriceAndStockCount } from '$shared/utils/product/hooks/use-price-and-stock-count';
import { ExtraService } from '~/lib/data-contract';
import { useAddToBasketContext } from '$shared/utils/add-to-basket/useAddToBasketContext';
import { useAuthentication } from '$shared/hooks/useAuthentication';

export type ExtraServiceProps = {
    arrow?: boolean;
    productLineLayout?: boolean;
};

export const ExtraServices: FC<ExtraServiceProps> = ({
    arrow = true,
    productLineLayout = false,
}) => {
    const { isAuthenticated } = useAuthentication();
    const { translate } = useTranslation();
    const { extraServices, selectedServices, setSelectedServices } = useAddToBasketContext();
    const { productPricesAndStockCounts, isBusy } = usePriceAndStockCount(
        ['ExtraServicePrices', ...(extraServices?.map((es) => `${es.sanitizedItemNumber}`) || [])],
        extraServices?.map((es) => `${es.sanitizedItemNumber}`) || []
    );

    const addSelectedService = (service: ExtraService, userText?: string) => {
        setSelectedServices([
            ...selectedServices.filter(
                (ss) => ss.service.sanitizedItemNumber !== service.sanitizedItemNumber
            ),
            {
                service,
                userText,
            },
        ]);
    };

    const removeSelectedService = (service: ExtraService) => {
        setSelectedServices([
            ...selectedServices.filter(
                (ss) => ss.service.sanitizedItemNumber !== service.sanitizedItemNumber
            ),
        ]);
    };

    return (
        <StyledExtraServiceWrapper
            hasArrow={arrow}
            isBusy={!!isAuthenticated && isBusy}
            productLineLayout={productLineLayout}
        >
            <p>{translate('product-detail-page.core-data.extra-services')}</p>
            <ul>
                {extraServices?.map((service, index) => (
                    <ExtraServicesItem
                        text={service.label}
                        enabled={isAuthenticated}
                        editable={service.editable}
                        onSelectionChange={(checked, userText) =>
                            checked
                                ? addSelectedService(service, userText)
                                : removeSelectedService(service)
                        }
                        selected={selectedServices.some(
                            (ss) => ss.service.sanitizedItemNumber === service.sanitizedItemNumber
                        )}
                        price={
                            service.sanitizedItemNumber
                                ? (productPricesAndStockCounts?.get(service.sanitizedItemNumber)
                                      ?.prices?.[0]?.prettyNetPrice as string)
                                : undefined
                        }
                        key={index}
                    />
                ))}
            </ul>
        </StyledExtraServiceWrapper>
    );
};
