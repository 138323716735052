import { buildURL, cancellableFetch } from "$shared/utils";
import { DELETE_FAVORITE_URL } from "./my-pages-endpoints";

const baseUrl = DELETE_FAVORITE_URL;
const method = 'DELETE';

export async function deleteFavorite(sanitizedItemNumber: string, signal?: AbortSignal): Promise<string[]> {
    const url = buildURL(baseUrl, { sanitizedItemNumber });

    return cancellableFetch<string[]>({
        signal,
        url,
        options: {
            method,
        },
    });
}
