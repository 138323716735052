import Button, { StyledContent } from '$shared/components/button';
import { theme } from '$theme';
import styled from '@emotion/styled';

export const StyledHeader = styled.header(() => ({
    position: 'sticky',
}));

export const StyledHeaderLogoWrapper = styled.div({
    paddingBlock: theme.space[3],

    svg: {
        height: 25,
    },
});

export const StyledHeaderNavigation = styled.div({
    borderBlock: `1px solid ${theme.colors.grey30}`,
    marginBottom: theme.space[3],
});

export const StyledHeaderBackButton = styled(Button)({
    textTransform: 'uppercase',
    paddingBlock: theme.space[2],

    [`${StyledContent}`]: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: theme.fontWeights.semiBold,
        fontSize: theme.fontSizes.sm,
        gap: theme.space[3],

        svg: {
            height: 24,
            width: 24,
        },
    },
});
