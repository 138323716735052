import { ServiceType } from '$shared/components/extra-services/service-types';
import { ProductLineItem } from '$shared/components/product-line/product-line.model';
import { PriceAndStockViewModel, SimpleProductViewModel } from '~/lib/data-contract';

export type SearchResultWithStockAndPrice = SimpleProductViewModel & PriceAndStockViewModel;

export type ProductAdapter<TProduct> = (
    product: TProduct,
    isFetchingPriceAndStockCount: boolean
) => ProductLineItem;
export const productMapper: ProductAdapter<SearchResultWithStockAndPrice> = (
    {
        extraServices,
        furtherStock,
        photoPath,
        originalItemNumber: primaryProductNumber,
        alternativeItemNumber: secondaryProductNumber,
        inSizesOf,
        minimumOrderSize,
        prices,
        sanitizedItemNumber,
        showFurhterStock,
        showStock,
        stock,
        unit,
        itemDescription1: productName,
        itemDescription2: productNameSecondary,
        canonicalProductUrl,
        promotedProduct,
        drawingExists,
        originalItemNumber,
        lastPurchasedPretty,
        boxCount,
    },
    isFetchingPriceAndStockCount
): ProductLineItem => ({
    extraServices: extraServices?.map((es) => ({
        editable: (es.serviceType as ServiceType) === 'CheckboxAndString',
        label: es.itemDescription1 as string,
        sanitizedItemNumber: es.sanitizedItemNumber as string,
    })),
    canonicalUrl: canonicalProductUrl,
    furtherStock,
    imageUrl: photoPath,
    inSizesOf,
    isFetchingPriceAndStockCount,
    minimumOrderSize,
    prices,
    primaryProductNumber,
    productName,
    productNameSecondary,
    productPrice: prices?.[0]?.prettyNetPrice,
    sanitizedItemNumber,
    secondaryProductNumber,
    showFurhterStock,
    showStock,
    stock,
    units: unit as string,
    promotedProduct,
    drawingExists,
    originalItemNumber,
    lastPurchasedPretty,
    boxCount,
});
