import { theme } from '$theme';
import styled from '@emotion/styled';

export const StyledPdpDownloadLine = styled.a({
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.space[4]} ${theme.space[2]}`,
    textDecorationColor: theme.colors.primary,
});

export const StyledPdpDownloadLineText = styled.p({
    fontSize: theme.fontSizes?.sm,
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.regular,
    color: theme.colors.primary,
    marginLeft: theme.space[2],
});
