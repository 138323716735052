import { Button } from '$shared/components/button';
import Container from '$shared/components/container';
import { Headline } from '$shared/components/headline';
import { Icon } from '$shared/components/icon';
import Link from '$shared/components/link';
import { PageTransitionContent } from '$shared/components/page-transition';
import { useAuthentication } from '$shared/hooks/useAuthentication';
import { useFrame, useTranslation } from '$shared/utils';
import { getCultureInfoLink } from '$shared/utils/market/helpers/culture-info.helper';
import { usePage } from '$templates/pages/hooks/usePage';
import { breakpoints, breakpointSizes } from '$theme';
import { CSSObject, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import React, { FC, PropsWithChildren } from 'react';
import { useMedia } from 'react-use';
import { IBreadcrumb } from '~/lib/data-contract';

type Props = {
    breadcrumb?: IBreadcrumb | undefined;
};

export const MyPagesNestedLayout: FC<PropsWithChildren<Props>> = ({ children }) => {
    const { header } = useFrame();
    const { loading, isAuthenticated, signIn, signOut } = useAuthentication();
    const { asPath, back } = useRouter();
    const { culture, market } = usePage();
    const { translate } = useTranslation();
    const isDesktop = useMedia(`(min-width: ${breakpointSizes.frame}px)`, false);

    const frontPageUrl = culture ? `/${culture}` : '/';

    // Avoid stutter from states that need to be set and consequential rerender stutter.
    if (loading) {
        return <></>;
    }

    if (!loading && !isAuthenticated) {
        return (
            <>
                <BackToFrontpage href={frontPageUrl}>
                    <Icon icon="chevronLeft" color="blue90" />
                    {translate('my-pages.unauthorized.back-to-front-page')}
                </BackToFrontpage>
                <UnauthorizedWrapper>
                    <Headline size={2}>{translate('my-pages.unauthorized.title')}</Headline>
                    <p>{translate('my-pages.unauthorized.description')}</p>
                    <SignInButton onClick={() => signIn()}>
                        {translate('my-pages.unauthorized.sign-in-cta')}
                    </SignInButton>
                    {!!translate('my-pages.unauthorized.description-2') && (
                        <p>{translate('my-pages.unauthorized.description-2')}</p>
                    )}
                    {!!translate('my-pages.unauthorized.contact-cta') && (
                        <ContactLink
                            variant="plain"
                            {...header?.contact}
                            href={header?.contact?.url}
                        >
                            {translate('my-pages.unauthorized.contact-cta')}
                        </ContactLink>
                    )}
                </UnauthorizedWrapper>
            </>
        );
    }

    return (
        <>
            {asPath !== header?.myPages?.[0]?.url && (
                <BackToProfile onClick={back}>
                    <Icon icon="chevronLeft" color="blue90" />
                    {translate('my-pages.back-to-profile')}
                </BackToProfile>
            )}
            <LayoutWrapper>
                {isDesktop && (
                    <MyPagesNav>
                        {header?.myPages?.map((myPageLink) => (
                            <MyPageNavLink
                                key={myPageLink.id}
                                active={
                                    !!myPageLink.url &&
                                    asPath === getCultureInfoLink(myPageLink.url, culture, market)
                                }
                                href={myPageLink.url}
                                target={myPageLink.target}
                                title={myPageLink.title}
                            >
                                {myPageLink.text}
                                {myPageLink.url?.startsWith('https://') && (
                                    <Icon icon="externalLink" color="grey90" size={24} />
                                )}
                            </MyPageNavLink>
                        ))}
                        <MyPageSignOutBtn onClick={signOut}>
                            {translate('authentication.sign-out')}
                        </MyPageSignOutBtn>
                    </MyPagesNav>
                )}
                <MyPagesContent>
                    <PageTransitionContent>{children}</PageTransitionContent>
                </MyPagesContent>
            </LayoutWrapper>
        </>
    );
};

const LayoutWrapper = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.space[5],

    [breakpoints.frame]: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: theme.space[7],
        gap: theme.space[5],
    },
}));

const UnauthorizedWrapper = styled(Container)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: `calc(100vh - ${theme.global.headerHeightSM})`,
    position: 'relative',

    [breakpoints.md]: {
        // Switch from device to desktop design
        minHeight: `calc(100vh - ${theme.global.headerHeightLG})`,
    },

    '&&&': {
        padding: theme.space[8],
    },
}));

const BackToProfile = styled.button(({ theme }) => ({
    all: 'unset',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: theme.fonts.body,
    fontSize: theme.fontSizes.xs,
    fontWeight: 600,
    gap: theme.space[2],
    marginLeft: theme.space.gutter,
    marginTop: theme.space[3],
    color: theme.colors.grey90,
    textTransform: 'uppercase',

    [breakpoints.frame]: {
        display: 'none',
    },
}));

const BackToFrontpage = styled(Link)(({ theme }) => ({
    all: 'unset',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: theme.fonts.body,
    fontSize: theme.fontSizes.xs,
    fontWeight: 600,
    gap: theme.space[2],
    marginLeft: theme.space.gutter,
    marginTop: theme.space[3],
    color: theme.colors.grey90,
    textTransform: 'uppercase',

    [breakpoints.frame]: {
        display: 'none',
    },
}));

const MyPagesNav = styled.aside({
    display: 'column',
    width: '100%',

    [breakpoints.frame]: {
        maxWidth: '260px',
    },

    [breakpoints.lg]: {
        maxWidth: '330px',
    },
});

const MyPagesContent = styled.section({
    [breakpoints.frame]: {
        flex: 1,
    },
});

const getMyPageNavItemStyles = (theme: Theme, active: boolean): CSSObject => ({
    all: 'unset',
    alignItems: 'center',
    borderLeft: `4px solid ${active ? theme.colors.blue80 : 'transparent'}`,
    boxSizing: 'border-box',
    color: active ? theme.colors.blue80 : theme.colors.defaultText,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.space[4]} ${theme.space[3]}`,
    textDecoration: 'none',
    width: '100%',

    ['&:hover']: {
        textDecoration: 'none',
        color: theme.colors.blue90,
    },
});

const MyPageNavLink = styled(Link)<{ active: boolean }>(({ theme, active }) => ({
    ...getMyPageNavItemStyles(theme, active),
}));

const MyPageSignOutBtn = styled.button(({ theme }) => ({
    ...getMyPageNavItemStyles(theme, false),
}));

const SignInButton = styled(Button)(({ theme }) => ({
    margin: theme.space[8],
}));

const ContactLink = styled(Link)(({ theme }) => ({
    marginTop: theme.space[2],
}));
