import React from 'react';
import { IP30CategoryListPage } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';
import { StyledModulesWrapper } from '../P60ModulePage/style';

type P30ProductOverviewPageProps = IP30CategoryListPage;

export const P30ProductOverviewPage = ({ pageElements }: P30ProductOverviewPageProps) => (
    <StyledModulesWrapper>
        <DynamicBlockList elements={pageElements} />
    </StyledModulesWrapper>
);
