import * as NextImage from 'next/image';
import { ImageProps } from 'next/image';
import { FC, useState } from 'react';
import fallbackProductImage from '../../../../public/assets/panorama.svg';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const imageServiceBaseUrl = publicRuntimeConfig.NEXT_PUBLIC_API_IMAGESERVICE_URL;

const customLoader = ({ src, width }: { src?: string; width?: number }) => {
    // Proxy absolute urls to cms globalassets folder
    if (
        src?.includes('https://') &&
        src?.includes('globalassets') &&
        !src?.includes(imageServiceBaseUrl)
    ) {
        const path = new URL(src).pathname;
        return `/image/proxy${
            path?.includes('?') ? `${path}&width=${width}` : `${path}?width=${width}`
        }`;
    }
    return src?.includes('?') ? `${src}&width=${width}` : `${src}?width=${width}`;
};

export type ImagePropsWithOptionalSrc = Omit<ImageProps, 'src'> & {
    src?: ImageProps['src'] | null;
};

export const Image: FC<ImagePropsWithOptionalSrc> = ({ width = 16, height = 9, ...imageProps }) => {
    const [errorState, setErrorState] = useState(false);

    return (
        <>
            {errorState || !imageProps.src ? (
                <NextImage.default
                    layout="responsive"
                    objectFit="cover"
                    width={width}
                    height={height}
                    {...(imageProps as ImageProps)}
                    src={fallbackProductImage}
                />
            ) : (
                <NextImage.default
                    loader={customLoader}
                    layout="responsive"
                    objectFit="cover"
                    width={width}
                    height={height}
                    onError={() => setErrorState(true)}
                    {...(imageProps as ImageProps)}
                />
            )}
        </>
    );
};

export default Image;
