import React, { InputHTMLAttributes, ReactNode, useImperativeHandle, useRef } from 'react';
import { useInputField } from '$shared/utils';
import {
    StyledCheckboxWrapper,
    StyledCheckboxElement,
    StyledCheckbox,
    StyledCheckboxIndicator,
    StyledLabel,
    StyledLabelText,
    StyledCheckboxHelpTexts,
    StyledCheckboxIcon,
} from './style';
import { controllableInputElementsProps } from '$shared/components/form';
import { StyledInvalidMessage } from '../../invalid-message';
import { StyledHelpText } from '../../help-text';

export type CheckboxElementProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'placeholder'> &
    Omit<controllableInputElementsProps, 'label'> & {
        /**
         * In case you wanna do your own
         */
        hideInvalidMessage?: boolean;
        label?: ReactNode;
    };

export const CheckboxElement = React.forwardRef<HTMLInputElement, CheckboxElementProps>(
    (
        { label, helpText, isInvalid, invalidMessage, id, hideInvalidMessage, disabled, ...rest },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);
        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        const isValid = !invalidMessage && !isInvalid;

        const { fieldId, helpTextId, showHelpText } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        return (
            <StyledLabel disabled={disabled} key={fieldId}>
                <StyledCheckboxWrapper valid={isValid}>
                    <StyledCheckboxElement>
                        <StyledCheckbox
                            {...rest}
                            type="checkbox"
                            id={fieldId}
                            ref={inputRef}
                            disabled={disabled}
                        />
                        <StyledCheckboxIndicator />
                        <StyledCheckboxIcon icon="checkmarkThin" color="white" size={15} />
                    </StyledCheckboxElement>
                    {label ? <StyledLabelText children={label} /> : null}
                </StyledCheckboxWrapper>
                <StyledCheckboxHelpTexts>
                    {invalidMessage && !hideInvalidMessage ? (
                        <StyledInvalidMessage children={invalidMessage} />
                    ) : null}

                    {showHelpText ? <StyledHelpText id={helpTextId} children={helpText} /> : null}
                </StyledCheckboxHelpTexts>
            </StyledLabel>
        );
    }
);

export default CheckboxElement;
