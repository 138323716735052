import { BasketSummaryAction } from '$shared/components/basket-summary/basket-summary-action';
import { formatString, useFrame, useTranslation } from '$shared/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { BasketList } from '$shared/components/basket-list';
import { IP300BasketPage } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';
import {
    StyledBasketCount,
    StyledBasketPage,
    StyledBasketSummary,
    StyledBasketTitle,
    StyledDynamicblocks,
} from './style';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import Headline from '$shared/components/headline';
import { useBasketUpsert } from '$shared/hooks/useBasket/useBasket';
import Container from '$shared/components/container';
import { ServiceType } from '$shared/components/extra-services/service-types';
import { BackArrow } from '$features/checkout';
import { BasketPageProvider } from './context/BasketPageProvider';
import { Skeleton } from '$shared/components/skeleton';
import { useCreateQuote } from '$features/checkout/hooks/use-create-quote';
import { useOrderStore } from '$features/checkout/hooks/use-order-store';
import { QuoteSummaryLines } from '$features/checkout/components/steps/shared/quote-summary-lines';
import { StyledModulesWrapper } from '../P60ModulePage/style';

export const P300BasketPage = ({ pageElements }: IP300BasketPage) => {
    const { translate } = useTranslation();
    const { checkoutPage } = useFrame();

    const giveNotificationsOnBasketUpdates = false;
    const { isLoading: basketIsUpdating, upsertBasket } = useBasketUpsert(
        giveNotificationsOnBasketUpdates
    );
    const { back, push } = useKyoceraRouter();
    const [removingZeroPriceLines, setRemovingZeroPriceLines] = useState(false);
    const [prepareRedirect, setPrepareRedirect] = useState(false);

    const { createNewQuote, creatingQuoteInitialLoading, isRefetching } = useCreateQuote();
    useEffect(() => {
        createNewQuote();
    }, []);

    const { quote } = useOrderStore();

    const quoteCount = useMemo(() => {
        return quote?.orderLines?.reduce((acc, cur) => acc + (cur?.quantity || 0), 0);
    }, [quote]);

    useEffect(() => {
        if (isRefetching) {
            return;
        }
        if (removingZeroPriceLines && prepareRedirect) {
            if (quote?.orderLines?.length) {
                push(checkoutPage?.url || '');
            }
            setRemovingZeroPriceLines(false);
            setPrepareRedirect(false);
        }
    }, [removingZeroPriceLines, prepareRedirect, quote, isRefetching]);

    const servicesAreInvalid = useMemo(
        () =>
            quote?.orderLines?.some((line) =>
                line.serviceDetails?.some(
                    (bls) =>
                        bls.selected &&
                        (bls.serviceType as ServiceType) === 'CheckboxAndString' &&
                        !bls.textInput
                )
            ),
        [quote]
    );

    const linesWithZeroPrice = useMemo(
        () => quote?.orderLines?.some((line) => line.unitPrice === 0),
        [quote]
    );

    const onCheckoutWithZeroPrice = async (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        event.preventDefault();
        setRemovingZeroPriceLines(true);
        const linesToRemove = quote?.orderLines?.filter((line) => line.unitPrice === 0);
        const allLinesRemoved = quote?.orderLines?.length === linesToRemove?.length;
        if (linesToRemove) {
            await Promise.all(
                linesToRemove.map(async (line) => {
                    await upsertBasket?.({
                        quantity: 0,
                        sanitizedItemNumber: line.sanitizedItemNumber as string,
                        lineId: line.basketLineId as string,
                    });
                })
            )
                .catch(() => {
                    setRemovingZeroPriceLines(false);
                    setPrepareRedirect(false);
                })
                .finally(() => {
                    if (allLinesRemoved) {
                        setRemovingZeroPriceLines(false);
                        setPrepareRedirect(false);
                    } else {
                        setTimeout(() => setPrepareRedirect(true), 500);
                    }
                });
        }
    };

    const basketIsLoading =
        basketIsUpdating || creatingQuoteInitialLoading || isRefetching || removingZeroPriceLines;

    return (
        <Container>
            <BackArrow onBack={back} />
            <BasketPageProvider {...{ isLoading: basketIsLoading, upsertBasket }}>
                <StyledBasketPage>
                    <div>
                        <StyledBasketTitle>
                            <Headline
                                as="h1"
                                size={3}
                                noMargin
                                style={{ textTransform: 'uppercase' }}
                            >
                                {translate('basket.cart-title')}
                            </Headline>
                            {!creatingQuoteInitialLoading && (
                                <StyledBasketCount>
                                    {basketIsLoading ? (
                                        <Skeleton pulsating height={'1.2em'} width={'100px'} />
                                    ) : (
                                        formatString(
                                            translate('basket.no-of-items-in-basket'),
                                            quoteCount
                                        )
                                    )}
                                </StyledBasketCount>
                            )}
                        </StyledBasketTitle>
                        <BasketList
                            isLoading={creatingQuoteInitialLoading}
                            basketLines={quote?.orderLines || []}
                        />
                    </div>
                    <div>
                        <StyledBasketSummary
                            isLoading={creatingQuoteInitialLoading}
                            actionLink={
                                <BasketSummaryAction
                                    isLoading={basketIsLoading}
                                    children={translate('basket.go-to-checkout')}
                                    enabled={!!quote?.orderLines?.length && !servicesAreInvalid}
                                    href={checkoutPage?.url || ''}
                                    onClickOverwrite={
                                        linesWithZeroPrice ? onCheckoutWithZeroPrice : undefined
                                    }
                                />
                            }
                            listItems={<QuoteSummaryLines isLoading={basketIsLoading} />}
                            showHeader={true}
                        />
                    </div>
                    <StyledDynamicblocks>
                        <StyledModulesWrapper>
                            <DynamicBlockList elements={pageElements} />
                        </StyledModulesWrapper>
                    </StyledDynamicblocks>
                </StyledBasketPage>
            </BasketPageProvider>
        </Container>
    );
};
