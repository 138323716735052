import React, { FC, useMemo } from 'react';
import { Aspect4OrderLineViewModel } from '$order-models/*';
import { BasketLine } from '../basket-line/basket-line';
import { BasketLineHeader } from '../basket-line/basket-line-header';
import { BasketListSkeleton } from './basket-list-skeleton';
import { EmptyBasketContainer, StyledBasketGrid } from './style';
import { ProductLineItem } from '../product-line/product-line.model';
import { StyledGenericList } from '$templates/pages/components/MyPages/style';
import { useRouter } from 'next/router';
import { useTranslation } from '$shared/utils';
import Button from '../button';
import Headline from '../headline';

export type BasketListProps = {
    basketLines?: Aspect4OrderLineViewModel[];
    isLoading?: boolean;
};

export type BasketLineItem = ProductLineItem & {
    totalPrice?: string;
    discount?: boolean;
};

export type BasketLineItemWithSplit = {
    id?: string;
    lines: Aspect4OrderLineViewModel[];
    hasSplit?: boolean;
    totalQuantity?: number;
};

export const BasketList: FC<BasketListProps> = ({ basketLines, isLoading }) => {
    const { translate } = useTranslation();
    const { back } = useRouter();

    const orderlinesWithSplit = useMemo(() => {
        return basketLines
            ?.reduce((acc, curr) => {
                const foundObj = acc.find((obj) => obj.id === curr.basketLineId);
                if (foundObj) {
                    foundObj.lines.push(curr);
                    foundObj.hasSplit = true;
                    if (foundObj?.totalQuantity && curr?.quantity) {
                        foundObj.totalQuantity = foundObj.totalQuantity += curr.quantity;
                    }
                } else {
                    acc.push({
                        id: curr.basketLineId || '',
                        totalQuantity: curr.quantity,
                        lines: [{ ...curr }],
                    });
                }
                return acc;
            }, [] as BasketLineItemWithSplit[])
            .filter((line) => line.hasSplit);
    }, [basketLines]);

    if (!basketLines?.length && !isLoading) {
        return (
            <EmptyBasketContainer>
                <Headline size={4} as="h2">
                    {translate('basket.empty-basket-header')}
                </Headline>
                <Button onClick={back} variant="linkish">
                    {translate('basket.continue-shopping')}
                </Button>
            </EmptyBasketContainer>
        );
    }

    return (
        <StyledGenericList>
            <li>
                <BasketLineHeader hideOnMobile />
            </li>
            {isLoading ? (
                <BasketListSkeleton />
            ) : (
                <>
                    {basketLines?.map((basketLine) => (
                        <StyledBasketGrid
                            key={`${basketLine.basketLineId}-${basketLine.deliveryEstimate}`}
                        >
                            <BasketLine
                                basketLine={basketLine}
                                splitBasketLine={orderlinesWithSplit?.find(
                                    (line) => line.id === basketLine.basketLineId
                                )}
                            />
                        </StyledBasketGrid>
                    ))}
                </>
            )}
        </StyledGenericList>
    );
};
