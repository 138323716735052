import { useOrderStore } from '$features/checkout/hooks/use-order-store';
import { useTranslation } from '$shared/utils';
import { FC, useMemo } from 'react';
import { DetailItem, OrderDetails } from '../../shared/order-details/order-details';

export const ConfirmOrderDetails: FC = () => {
    const { translate } = useTranslation();
    const { quote } = useOrderStore();

    const details: DetailItem[] = useMemo(
        () => [
            {
                title: translate('checkout-page.summary-details.delivery-address'),
                lines: [
                    quote?.deliveryAddress?.companyName,
                    quote?.deliveryAddress?.streetAndNumber,
                    quote?.deliveryAddress?.zipAndCity,
                ],
            },
            {
                title: translate('checkout-page.summary-details.invoice-address'),
                lines: [
                    quote?.paymentAddress?.companyName,
                    quote?.paymentAddress?.streetAndNumber,
                    quote?.paymentAddress?.zipAndCity,
                ],
            },
            {
                title: translate('checkout-page.summary-details.contact-person'),
                lines: [quote?.contact?.fullname, quote?.contact?.eMail, quote?.contact?.phone],
            },
            {
                title: translate('checkout-page.summary-details.order-reference'),
                lines: [quote?.customerOrderNumber],
            },
        ],
        [quote]
    );

    return <OrderDetails details={details} />;
};
