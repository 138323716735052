import React, { FC, useEffect, useMemo } from 'react';
import { FilterFacetOption, useFilter } from '$shared/utils/filter';
import FacetCheckbox from '$shared/components/facets/facet-checkbox';
import { FacetWrapper } from '$shared/components/facets/shared/facet-wrapper';
import { IFacetOption } from '$shared/components/facets';
import { useTranslation } from '$shared/utils';
import { useAuthentication } from '$shared/hooks/useAuthentication';
import {
    FILTER_SHOW_ONLY_IN_STOCK_OPTION_KEY,
    FILTER_SHOW_ONLY_IN_STOCK_KEY,
    FILTER_SHOW_ONLY_IN_STOCK_MAX,
} from '$shared/utils/filter/service/constants';
import { FacetSkeleton } from '../facet-skeleton';
import { Popover } from '$shared/components/popover/popover';
import { StyledToolTipIcon } from '$shared/components/hover-card-tooltip/tool-tip/style';
import { StyledTooltipContent } from '../style';

export const StockFacet: FC = () => {
    const { translate } = useTranslation();
    const { isAuthenticated, userFound } = useAuthentication();
    const { setFacet, isLoadingInitially, userFilters, resultsTotal } = useFilter();
    const { showOnlyInStock } = userFilters || {};

    const disabled = !!(resultsTotal && resultsTotal > FILTER_SHOW_ONLY_IN_STOCK_MAX);

    const option: IFacetOption<FilterFacetOption>[] = useMemo(() => {
        return [
            {
                key: FILTER_SHOW_ONLY_IN_STOCK_OPTION_KEY,
                name: translate('product-filter.filters.stock.stock-option'),
                selected: showOnlyInStock,
                disabled,
            },
        ];
    }, [showOnlyInStock, disabled]);

    useEffect(() => {
        if (disabled) {
            // Unsetting facet if more than 100 results
            setFacet?.({
                facet: FILTER_SHOW_ONLY_IN_STOCK_KEY,
                options: [],
                overwrite: true,
            });
        }
    }, [resultsTotal]);

    if (!isAuthenticated && !userFound) return null;

    return (
        <FacetWrapper
            headline={translate('product-filter.filters.stock.stock-facet')}
            additionalContent={
                <Popover
                    content={
                        <StyledTooltipContent>
                            {translate('product-filter.filters.stock.stock-tooltip')}
                        </StyledTooltipContent>
                    }
                >
                    <StyledToolTipIcon />
                </Popover>
            }
        >
            {isLoadingInitially ? (
                <FacetSkeleton />
            ) : (
                <FacetCheckbox
                    options={option}
                    initialLoadSize={3}
                    handleChange={() =>
                        setFacet?.({
                            facet: FILTER_SHOW_ONLY_IN_STOCK_KEY,
                            options: [FILTER_SHOW_ONLY_IN_STOCK_OPTION_KEY],
                        })
                    }
                />
            )}
        </FacetWrapper>
    );
};
