import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { StyledButton } from '$shared/components/button';
import { StyledHeadline } from '$shared/components/headline';
import { StyledText } from '$shared/components/text';
import { SpotContentProps } from './spot-content';
import { breakpoints } from '$theme';
import { StyledRichText } from '../raw-html/styled';

export const StyledSpotContent = styled.div<SpotContentProps>(
    ({ theme, textColor, backgroundColor }) => ({
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        color: textColor ?? 'currentcolor',
        backgroundColor: backgroundColor,
        gridArea: 'content',
        overflow: 'hidden',

        [`& ${StyledHeadline}`]: {
            maxWidth: '100%',
            color: textColor ?? 'inherit',
        },
        [`${StyledText}`]: {
            maxWidth: '100%',
            color: textColor ?? 'inherit',
        },
        [`${StyledRichText}`]: {
            maxWidth: '100%',

            [breakpoints.sm]: {
                marginBottom: theme.space[3],

                '>*:last-child': {
                    marginBottom: 0,
                },
            },
        },
        [`& ${StyledButton}`]: {
            margin: `0 0 ${theme.space[1]} `,
            marginRight: theme.space[4],

            ':last-child': {
                marginRight: 0,
            },
        },
    }),
    ifProp('padded', ({ theme }) => ({
        padding: theme.space['4'],
        [breakpoints.md]: {
            padding: theme.space['7'],
        },
    })),
    switchProp('alignment', {
        left: {
            alignItems: 'flex-start',
        },
        right: {
            alignItems: 'flex-end',
            textAlign: 'right',
        },
        center: {
            alignItems: 'center',
            textAlign: 'center',
        },
    }),
    switchProp('position', {
        topWithoutPadding: {
            justifyContent: 'flex-start',

            [breakpoints.sm]: {
                paddingTop: '0',
            },
        },
        top: {
            justifyContent: 'flex-start',
        },
        center: {
            justifyContent: 'center',
        },
        bottom: {
            justifyContent: 'flex-end',
        },
    }),
    ifProp({ mediaType: 'video' }, () => ({
        pointerEvents: 'none',

        [`& ${StyledButton}`]: {
            pointerEvents: 'all',
        },
    }))
);
