import { signIn as signInNextAuth, signOut as signOutNextAuth, useSession } from 'next-auth/react';
import getConfig from 'next/config';
import { useMarket } from '$shared/utils';
import { useKyoceraRouter } from '../useKyoceraRouter';
import { getCultureInfoString } from '$shared/utils/market/helpers/culture-info.helper';
import { useUserStore } from '$features/authentication/use-user-store';
import shallow from 'zustand/shallow';
import { useMemo } from 'react';

const { publicRuntimeConfig } = getConfig();
const SIGNOUT_URL = `https://${publicRuntimeConfig.NEXT_PUBLIC_AZURE_AD_B2C_TENANT_NAME}.b2clogin.com/${publicRuntimeConfig.NEXT_PUBLIC_AZURE_AD_B2C_TENANT_NAME}.onmicrosoft.com/${publicRuntimeConfig.NEXT_PUBLIC_AZURE_AD_B2C_PRIMARY_USER_FLOW}/oauth2/v2.0/logout?post_logout_redirect_uri=${publicRuntimeConfig.NEXT_PUBLIC_SPA_URL}`;

export const useAuthentication = () => {
    const { asPath } = useKyoceraRouter();
    const { contactFound, resetUserStore } = useUserStore(
        ({ user, resetUserStore }) => ({
            contactFound: user?.contactFound,
            resetUserStore,
        }),
        shallow
    );
    const { status } = useSession();
    const isAuthenticated = status === 'loading' ? undefined : status === 'authenticated';

    const userFound = useMemo(() => {
        if (
            status === 'authenticated' ||
            status === 'unauthenticated' ||
            contactFound !== undefined
        ) {
            return contactFound;
        } else {
            return false;
        }
    }, [status, contactFound]);

    const signOut = async () => {
        const { market, culture } = useMarket.getState();
        resetUserStore();
        await signOutNextAuth({ redirect: false });
        if (typeof window !== 'undefined') {
            window.location.href = `${SIGNOUT_URL}/${getCultureInfoString(culture, market)}`;
        }
    };

    const signIn = (callbackUrl?: string) => {
        signInNextAuth(
            'azure-ad-b2c',
            {
                callbackUrl: callbackUrl || asPath,
            },
            { ui_locales: useMarket.getState().culture }
        );
    };

    const signUp = () => {
        const { market, culture } = useMarket.getState();
        const callbackUrlWithCulture = `/${culture}-${market}`;

        signInNextAuth(
            'azure-ad-b2c-signup',
            {
                callbackUrl: callbackUrlWithCulture,
            },
            { ui_locales: useMarket.getState().culture }
        );
    };

    return {
        isAuthenticated,
        loading: status === 'loading',
        userFound,
        signOut,
        signIn,
        signUp,
    };
};
