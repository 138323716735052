export function sortByDate(a: string | Date, b: string | Date) {
    return new Date(b).getTime() - new Date(a).getTime();
}
export function sortAlphabetically(a: string, b: string) {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
}
