import Headline from '$shared/components/headline';
import Link from '$shared/components/link';
import { useRawHtmlWithNewLinesAsBreaks } from '$shared/components/raw-html';
import { HeaderTag } from '~/lib/data-contract';
import { ModuleContainer } from '../module-container';
import M21GridItem, { M21GridItemProps } from './components/m21-grid-item/m21-grid-item';
import { M21AnchorId } from './m21-grid-block';
import { StyledGrid, StyledHeader } from './style';

export type M21Props = {
    /**
     * Module headline
     */
    columns?: number;

    /**
     * Module headline
     */
    headline?: string;

    /**
     * Define the headline HTML tag. Useful for SEO
     */
    headlineTag?: HeaderTag;

    /**
     * Define the headline HTML tag. Useful for SEO
     */
    headlineStyleAs?: HeaderTag;

    headlineHidden?: boolean;
    /**
     * The text for the button. If this is left empty
     * the button will not show
     */
    actionText?: string;

    /**
     * The url for the button
     */
    actionUrl?: string;

    items?: M21GridItemProps[];

    id?: string;

    /**
     * Overwrite button text for M21 content item
     */
    buttonText?: string;
};
export const M21 = ({
    columns,
    headline,
    headlineTag = 'h2',
    headlineStyleAs,
    headlineHidden,
    actionText,
    actionUrl,
    buttonText,
    items,
    ...props
}: M21Props) => {
    const columnCount = columns || 3;

    return (
        <ModuleContainer {...props}>
            <StyledHeader id={M21AnchorId}>
                {headline && (
                    <Headline
                        as={headlineTag}
                        size={headlineStyleAs}
                        hideOnMobile={headlineHidden}
                        children={useRawHtmlWithNewLinesAsBreaks(headline)}
                        noMargin
                    />
                )}
                {actionText && <Link href={actionUrl} variant="plain" children={actionText} />}
            </StyledHeader>

            <StyledGrid columnCount={columnCount}>
                {items?.map((item, index) => (
                    <M21GridItem
                        key={index}
                        columnCount={columnCount}
                        {...item}
                        buttonText={buttonText}
                    />
                ))}
            </StyledGrid>
        </ModuleContainer>
    );
};

export default M21;
