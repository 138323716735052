import styled from '@emotion/styled';
import * as React from 'react';
import { FC, memo } from 'react';
import { ifProp, switchProp } from 'styled-tools';

export type Props = {
    match?: string;
    input?: string;
    className?: string;
    oneLine?: boolean;
    fontSize?: 'xs' | 'md' | 'lg';
};

export const HighlightWord: FC<Props> = memo(({ match, input, className, oneLine, fontSize }) => {
    const regex = new RegExp(`(${match})`, 'gi');
    return !!match && !!input?.match(regex) ? (
        <StyledWord className={className} oneLine={oneLine} fontSize={fontSize}>
            {input?.split(regex).map((word, i) => (
                <React.Fragment key={i}>
                    {word.toLowerCase() === match.toLowerCase() ? (
                        <StyledMatch>{word}</StyledMatch>
                    ) : (
                        word
                    )}
                </React.Fragment>
            ))}
        </StyledWord>
    ) : (
        <StyledWord className={className} oneLine={oneLine} fontSize={fontSize}>
            {input}
        </StyledWord>
    );
});

const StyledWord = styled.div<{ oneLine?: boolean; fontSize?: 'xs' | 'md' | 'lg' }>(
    ifProp({ oneLine: true }, () => ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    })),
    switchProp('fontSize', {
        xs: ({ theme }) => ({
            fontSize: theme.fontSizes.xs,
        }),
        md: ({ theme }) => ({
            fontSize: theme.fontSizes.md,
        }),
        lg: ({ theme }) => ({
            fontSize: theme.fontSizes.lg,
        }),
    })
);

const StyledMatch = styled.span(({ theme }) => ({
    fontWeight: 600,
    color: theme.colors.defaultText,
}));
