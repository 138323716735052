import { StyledGenericList } from '$templates/pages/components/MyPages/style';
import React, { FC } from 'react';
import { ProductLine } from '../product-line';
import { ProductLineHeader } from '../product-line/product-line-header';
import { ProductLineItem } from '../product-line/product-line.model';
import { StyledProductList } from './style';

export type ProductListProps = {
    className?: string;
    isLoading?: boolean;
    products?: ProductLineItem[];
};

export const ProductList: FC<ProductListProps> = ({ className, isLoading, products }) => {
    return (
        <StyledProductList className={className} isLoading={isLoading}>
            <StyledGenericList>
                <li>
                    <ProductLineHeader hideOnMobile={true} />
                </li>
                {products?.map((product) => (
                    <li key={`${product.sanitizedItemNumber}`}>
                        <ProductLine {...product} />
                    </li>
                ))}
            </StyledGenericList>
        </StyledProductList>
    );
};
