import { Button } from '$shared/components/button';
import { CarouselLight, CarouselRef } from '$shared/components/carousel-light';
import React, { useRef, useState } from 'react';
import { Icon } from '../icon';
import { StyledCarouselWrapper, StyledPagination } from './styled';

export type ModuleCarouselProps = {
    headline?: string;
    items?: React.ReactNode[];
    onItemSelect?(index: number): void;
};

/**
 * Renders items inside a carousel with pagination.
 * Ensures that the carousel is always rendered with the same width.
 *
 */
export const ModuleCarousel = ({ items = [], onItemSelect }: ModuleCarouselProps) => {
    const [atEnd, setAtEnd] = useState(true);
    const [atBegining, setAtBegining] = useState(true);
    const carouselRef = useRef<CarouselRef>(null);

    // Purely used to get the perseived module width.
    // Cant calculate based on carousel since it's edge to edge
    const moduleWidthRef = useRef<HTMLDivElement>(null);

    const hidePagination = atBegining && atEnd;

    const determinPagination = () => {
        if (carouselRef.current) {
            const { scrollDistance, scrollLeft } = carouselRef.current;

            setAtBegining(scrollLeft === 0);
            setAtEnd(scrollLeft === scrollDistance);
        }
    };

    // By default scrolls the entire width of the module
    const onClickHandlerGenerator = (multiplier = 1) => () => {
        const { current: carousel } = carouselRef;
        const { current: moduleWidth } = moduleWidthRef;

        if (carousel && moduleWidth) {
            const distance = multiplier * moduleWidth.clientWidth;
            carousel.scrollBy({ left: distance, behavior: 'smooth' });
        }
    };

    return (
        <StyledCarouselWrapper>
            {!hidePagination ? (
                <StyledPagination>
                    <Button
                        onClick={onClickHandlerGenerator(-1)}
                        iconLeft={<Icon icon="chevronLeft" />}
                        disabled={atBegining}
                    />
                    <Button
                        onClick={onClickHandlerGenerator(1)}
                        iconRight={<Icon icon="chevronRight" />}
                        disabled={atEnd}
                    />
                </StyledPagination>
            ) : null}

            <div ref={moduleWidthRef}>
                <CarouselLight
                    items={items}
                    ref={carouselRef}
                    onResize={determinPagination}
                    onScroll={determinPagination}
                    onItemSelect={onItemSelect}
                />
            </div>
        </StyledCarouselWrapper>
    );
};
