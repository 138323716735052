import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';

export const StockPopoverWrapper = styled.div({
    [breakpoints.sm]: {
        width: '100vw',
        maxWidth: '370px',
    },
});

export const StockPopoverItem = styled.div({
    display: 'grid',
    gridTemplateAreas: `"stockPopoverCircle stockPopoverStatus stockPopoverCount"
                        "stockPopoverCircle stockPopoverText stockPopoverText"`,
    gridTemplateColumns: '22px 1fr',
    alignItems: 'baseline',
    padding: theme.space[3],
    marginTop: '-1px',
    borderBottom: `1px solid ${theme.colors.grey30}`,
    fontSize: theme.fontSizes.xs,
    lineHeight: 1.6,

    [breakpoints.sm]: {
        gridTemplateAreas: `"stockPopoverCircle stockPopoverStatus stockPopoverCount"
                            "stockPopoverCircle stockPopoverText stockPopoverText"`,
        gridTemplateColumns: '22px 1fr',
        alignItems: 'baseline',
    },
});
export const StyledStockPopoverCircle = styled.div({
    gridArea: 'stockPopoverCircle',
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.colors.black,
    fontWeight: theme.fontWeights.bold,
});
export const StyledStockPopoverCount = styled.div({
    gridArea: 'stockPopoverCount',
    color: theme.colors.black,
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.fontWeights.semiBold,
    justifySelf: 'flex-end',
    whiteSpace: 'nowrap',
});
export const StyledStockStatus = styled.div({
    gridArea: 'stockPopoverStatus',
    fontWeight: theme.fontWeights.semiBold,
    color: theme.colors.black,
});
export const StyledStockPopoverText = styled.div({
    gridArea: 'stockPopoverText',
    color: theme.colors.grey90,
});
