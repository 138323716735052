import React, { FC } from 'react';
import styled, { CSSObject } from '@emotion/styled';
import { cover as stretch, pulse } from '$shared/utils';

interface SkeletonProps {
    className?: string;
    cover?: boolean;
    css?: CSSObject;
    height?: string;
    pulsating?: boolean;
    shimmer?: boolean;
    width?: string;
}

const StyledSkeleton = styled.div<SkeletonProps>(
    ({ theme, width, height, cover, pulsating, css }) => ({
        width: `${width}`,
        height: `${height}`,
        borderRadius: 4,
        backgroundColor: theme.colors.grey40,
        css,
        ...(cover ? { ...stretch } : {}),
        ...(pulsating ? { ...pulse } : {}),
    })
);

export const Skeleton: FC<SkeletonProps> = (props) => <StyledSkeleton {...props} />;
