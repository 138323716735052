import Headline from '$shared/components/headline';
import React, { useState } from 'react';
import { IM220JobVacanciesModule } from '~/lib/data-contract';
import { ModuleContainer } from '../module-container';
import DesktopJobLayout from './components/desktop-jobs';
import MobileJobLayout from './components/mobile-jobs';
export const M220JobBlock = ({
    headline,
    items,
    noResultsText,
    formattingCulture,
    id,
}: IM220JobVacanciesModule) => {
    const [sortedItems, setItems] = useState(items);
    return (
        <ModuleContainer id={id}>
            <Headline
                as={headline?.tag || 'h2'}
                size={headline?.style}
                hideOnMobile={headline?.hideOnMobile}
            >
                {headline?.text}
            </Headline>
            {sortedItems?.length ? (
                <>
                    <DesktopJobLayout
                        items={sortedItems}
                        setSorting={setItems}
                        formattingCulture={formattingCulture}
                    />
                    <MobileJobLayout items={sortedItems} formattingCulture={formattingCulture} />
                </>
            ) : (
                <Headline size={5}>{noResultsText} </Headline>
            )}
        </ModuleContainer>
    );
};
