import { ButtonProps } from '../../shared/components/button/button';

export const getEpiButtonVariant = (stringFromEpiServer?: string): ButtonProps['variant'] => {
    switch (stringFromEpiServer) {
        // These values are hardcoded / spit out of epiServer, and BE can not give us a model for them...
        case 'buttonBlue':
            return 'primary';
        default:
            return 'plain';
    }
};
