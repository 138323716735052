import * as React from 'react';
import { FC } from 'react';
import Video from '$shared/components/video';
import Link from '../link/link';
import { RawHtml, useRawHtmlWithNewLinesAsBreaks } from '../raw-html';
import {
    StyledArticle,
    StyledDate,
    StyledDetailsColumn,
    StyledArticleTopRow,
    StyledImageColumn,
    StyledSubheadline,
    StyledArticleFilterList,
    StyledArticleFilterTag,
    StyledLinkHeader,
    StyledHeadlineWithIcon,
    StyledHeadlineIcon,
} from './style';
import { HeaderTag, ILink, IResponsiveMedia } from '~/lib/data-contract';
import { Icon } from '../icon';
import Headline, { HeadlineProps } from '../headline';
import Image from '../image';
import { breakpointSizes } from '$theme';
import { usePrettyDate } from '$shared/utils/pretty-date.helper';

export interface ArticleProps {
    media?: IResponsiveMedia;
    tags?: (string | undefined)[];
    link?: ILink;
    headline?: string;
    hideOnMobile?: boolean;
    headlineTag?: HeaderTag;
    headlineStyle?: HeadlineProps['size'];
    htmlText?: string;
    publishedDate?: Date | string;
    dateFormattingCulture?: string;
}

export const Article: FC<ArticleProps> = ({
    headline,
    hideOnMobile,
    headlineStyle,
    headlineTag = 'h4',
    htmlText,
    link,
    media,
    publishedDate,
    tags,
}) => {
    const prettifyDate = usePrettyDate();

    return (
        <StyledArticle>
            <StyledImageColumn>
                {media?.video ? (
                    <Video
                        src={media?.video.src}
                        loop={media?.video.loop}
                        playing={media?.video.autoPlay}
                    />
                ) : (
                    <Link href={link?.url} target={link?.target}>
                        <Image
                            width={475}
                            height={320}
                            src={media?.desktop?.src || ''}
                            sizes={`(max-width: ${breakpointSizes.sm}px) 100vw, 430px`}
                        />
                    </Link>
                )}
            </StyledImageColumn>
            <StyledDetailsColumn>
                <StyledArticleTopRow>
                    <StyledArticleFilterList>
                        {tags?.map(
                            (tag, i) => tag && <StyledArticleFilterTag text={tag} key={i + tag} />
                        )}
                    </StyledArticleFilterList>
                    {!!tags?.length && (
                        <Link href={link?.url} target={link?.target}>
                            <Icon icon={'arrowRight'} color="blue" />
                        </Link>
                    )}
                </StyledArticleTopRow>
                <StyledLinkHeader href={link?.url} target={link?.target}>
                    {tags?.length ? (
                        <Headline
                            hideOnMobile={hideOnMobile}
                            children={useRawHtmlWithNewLinesAsBreaks(headline || '')}
                            as={headlineTag}
                            size={headlineStyle}
                        />
                    ) : (
                        <StyledHeadlineWithIcon>
                            <Headline
                                as={headlineTag}
                                size={headlineStyle}
                                hideOnMobile={hideOnMobile}
                                children={useRawHtmlWithNewLinesAsBreaks(headline || '')}
                            />
                            <StyledHeadlineIcon icon={'arrowRight'} color="blue" />
                        </StyledHeadlineWithIcon>
                    )}
                </StyledLinkHeader>
                <StyledSubheadline>
                    <RawHtml html={htmlText} />
                </StyledSubheadline>
                {!!publishedDate && <StyledDate>{prettifyDate(publishedDate)}</StyledDate>}
            </StyledDetailsColumn>
        </StyledArticle>
    );
};
