import { UrlObject } from 'url';

/**
 * getCultureInfoLink is for converting a CMS url to the FE maintained CultureInfo url structure
 * it only returns a string(url)
 */
export const getCultureInfoLink = (url: string, culture: string, market: string): string => {
    const nextLanguage = `/${culture}-${market}/`.toLowerCase();
    const languageRegex = new RegExp('^/([^/]+)/?'); // IF a url starts as a reletive path with "/...anything.../" - we know is a url from epiServer, and we know is has country as first slug. so here we replace that with our cultureInfo format /{culture}-{market}/
    return url.replace(languageRegex, nextLanguage);
};

/**
 * getCultureInfoUrlObj same purpose as above, but this is for converting the Url type of the nextjs link (NextLinkProps['href'])
 * returns as given, an UrlObject or string.
 */
type Url = string | UrlObject;
export const getCultureInfoUrlObj = (url: Url, culture: string, market: string): Url => {
    if (typeof url === 'string') {
        return url.startsWith('/api') ? url : getCultureInfoLink(url, culture, market);
    } else {
        return { ...url, pathname: getCultureInfoLink(url?.pathname || '', culture, market) };
    }
};

export const getCultureInfoString = (culture?: string, market?: string) =>
    culture && market && `${culture}-${market}`;

/**
 * getCultureInfo takes an (possibly)already CultureInfo encoded url, e.g. "/da-dk/tools/metal..."
 * it then determins and returns a calcMarketUrlDataProps type object.
 */
type calcMarketUrlDataProps = {
    url: string;
    urlWithoutMarketSlug: string;
    culture: string;
    market: string;
    cultureInfo: string;
};

export const getCultureInfo = (url: string): calcMarketUrlDataProps => {
    const regex = new RegExp('^/([a-zA-Z0-9]{2}(?:-[a-zA-Z0-9]{2,})?-[a-zA-Z0-9]{2,})/?');
    const [marketSlug] = url.match(regex) || [];

    const cultureInfo = marketSlug?.replaceAll('/', '')?.toLowerCase() || '';
    const cultureAndMarket = cultureInfo?.split('-') || [];
    let culture;
    let market;
    if(cultureAndMarket.length > 2) {
        culture = `${cultureAndMarket[0]}-${cultureAndMarket[1]}`
        market = cultureAndMarket[2];
    } else {
        culture = cultureAndMarket[0];
        market = cultureAndMarket[1];
    }

    const urlWithoutMarketSlug = url.replace(regex, '')?.replace(/^\//, '').replace(/\/$/, ''); // here removing market slug, initial and trailing slash
    const requestUrl = `${culture ? '/' + culture : ''}/${urlWithoutMarketSlug}`.replace(/\/$/, ''); // here removing trailing slash;

    return {
        culture,
        cultureInfo,
        market,
        url: requestUrl,
        urlWithoutMarketSlug,
    };
};
