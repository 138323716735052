import React, { FC } from 'react';
import Headline from '$shared/components/headline';
import { Icon } from '$shared/components/icon';
import { formatString, useTranslation } from '$shared/utils';
import { StyledProductListHeader, StyledFilterButton } from '../style';
import { useFilter } from '$shared/utils/filter';

type Props = {
    onFiltersToggled?: () => void;
    totalResults?: number;
    title?: string;
};
export const FilterHeader: FC<Props> = ({ totalResults, onFiltersToggled, title }) => {
    const { translate } = useTranslation();
    const { resultsTotal, inStockTotal } = useFilter();

    return (
        <StyledProductListHeader>
            <div>
                {!!title && <Headline size={2}>{title}</Headline>}
                <p>
                    {formatString(
                        translate('product-list-page.product-results'),
                        totalResults || inStockTotal || resultsTotal
                    )}
                </p>
            </div>
            {!!onFiltersToggled && (
                <StyledFilterButton onClick={onFiltersToggled}>
                    <Icon icon="filter" size={20} />
                    {translate('product-list-page.mobile-filters-title')}
                </StyledFilterButton>
            )}
        </StyledProductListHeader>
    );
};
