import Headline from '$shared/components/headline';
import Link from '$shared/components/link';
import { RawHtml, useRawHtmlWithNewLinesAsBreaks } from '$shared/components/raw-html';
import SpotMedia from '$shared/components/spot-media';
import { breakpointSizes, getEpiButtonVariant, theme } from '$theme';
import React, { FC, ReactNode } from 'react';
import { ILink, IM85MediaAndTextItem } from '~/lib/data-contract';
import {
    StyledActions,
    SpotMediaWrapper,
    StyledMediaText,
    StyledMediaTextLink,
    StyledMediaTextSpotContent,
    StyledMediaTextIcon,
    StyledMediaTextSpotContentTexts,
} from './style';
import { M80ItemLayout } from '../../m80-media-text-bundle';

export type MediaTextProps = {
    /**
     * Context for Image sizes
     */
    link?: ILink;
    columnCount?: number;
    layoutVariant?: M80ItemLayout;
    children?: ReactNode;
} & IM85MediaAndTextItem;

const LinkWrapper: FC<Pick<MediaTextProps, 'link' | 'children'>> = ({ children, link }) =>
    link?.url ? (
        <StyledMediaTextLink href={link?.url} target={link?.target} title={link.title}>
            {children}
        </StyledMediaTextLink>
    ) : (
        <>{children}</>
    );

export const MediaText = ({
    headline,
    text,
    callToActions,
    media,
    columnCount,
    horizontalAlignment,
    verticalAlignment,
    link,
    layoutVariant,
}: MediaTextProps) => {
    const { video, desktop, caption } = media || {};

    const image = desktop;
    const mediaType = video ? 'video' : 'image';
    const mediaItem = video || image;
    const imageSizes = `(max-width: ${breakpointSizes.sm}px) 100vw, ${
        columnCount ? theme.grid.maxWidth / columnCount + 'px' : '100vw'
    }`;

    return (
        <LinkWrapper link={link}>
            <StyledMediaText layoutVariant={layoutVariant}>
                <SpotMediaWrapper hasMedia={!!mediaItem?.src} layoutVariant={layoutVariant}>
                    {mediaItem && (
                        <SpotMedia
                            src={mediaItem.src}
                            alt={(mediaItem as any)?.alt} // Yet another property missing on the models from BE...
                            title={caption}
                            imageSizes={imageSizes}
                            type={mediaType}
                            videoPosterSrc={image?.src}
                            videoLoop={video?.loop}
                            videoAutoPlay={video?.autoPlay}
                        />
                    )}
                </SpotMediaWrapper>

                <StyledMediaTextSpotContent
                    alignment={horizontalAlignment}
                    position={verticalAlignment}
                    layoutVariant={layoutVariant}
                >
                    <StyledMediaTextSpotContentTexts layoutVariant={layoutVariant}>
                        {headline?.text && (
                            <Headline
                                children={useRawHtmlWithNewLinesAsBreaks(headline.text)}
                                as={headline.tag}
                                size={headline.style}
                                hideOnMobile={headline.hideOnMobile}
                            />
                        )}
                        {text?.html && <RawHtml html={text?.html} />}
                    </StyledMediaTextSpotContentTexts>

                    {layoutVariant === 'narrow' && (
                        <StyledMediaTextIcon icon="arrowRight" color="blue" size={20} />
                    )}
                </StyledMediaTextSpotContent>

                {callToActions?.length ? (
                    <StyledActions>
                        {callToActions
                            ?.filter((x) => x) // filtering null's out of the array - yep.
                            .map(({ text, url, id, style, target }) => (
                                <Link
                                    key={`${url}-${id}`}
                                    variant={getEpiButtonVariant(style)}
                                    href={url}
                                    target={target}
                                    children={text}
                                />
                            ))}
                    </StyledActions>
                ) : null}
            </StyledMediaText>
        </LinkWrapper>
    );
};
export default MediaText;
