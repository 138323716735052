import React, { FC, useMemo } from 'react';
import { Facet, FilterFacetOption, useFilter } from '$shared/utils/filter';
import FacetCheckbox from '$shared/components/facets/facet-checkbox';
import { FacetWrapper } from '$shared/components/facets/shared/facet-wrapper';
import { IFacetOption } from '$shared/components/facets';
import { FacetSkeleton } from '../facet-skeleton';
import {
    FILTER_CUSTOMERS_PURCHASES_OPTION_KEY,
    FILTER_FAVORITES_OPTION_KEY,
    FILTER_INTELLIGENT_KEY,
    FILTER_PROMOTED_PRODUCTS_OPTION_KEY,
} from '$shared/utils/filter/service/constants';
import { useTranslation } from '$shared/utils';
import { useAuthentication } from '$shared/hooks/useAuthentication';

export const IntelligentSortingFacet: FC = () => {
    const { translate } = useTranslation();
    const { isAuthenticated, userFound } = useAuthentication();
    const { setFacet, isLoadingInitially, userFilters } = useFilter();
    const { favoriteProduct, customersPurchased, promotedProducts } = userFilters || {};

    const IntelligentSortingOptions: IFacetOption<FilterFacetOption>[] = useMemo(() => {
        return [
            {
                key: FILTER_FAVORITES_OPTION_KEY,
                name: translate('product-filter.filters.favorites'),
                selected: favoriteProduct,
            },
            {
                key: FILTER_CUSTOMERS_PURCHASES_OPTION_KEY,
                name: translate('product-filter.filters.previously-purchased'),
                selected: customersPurchased,
            },
            {
                key: FILTER_PROMOTED_PRODUCTS_OPTION_KEY,
                name: translate('product-filter.filters.promoted-products'),
                selected: promotedProducts,
            },
        ];
    }, [userFilters]);

    if (!isAuthenticated && !userFound) return null;

    const handleFacetChange = <T,>({
        facet,
        change,
        overwrite,
    }: {
        change: IFacetOption<T> | IFacetOption<T>[];
        facet: Facet;
        overwrite?: boolean;
    }) => {
        // Because our Facets requre their options in a specific model,
        // we only need the { option } of a returned change.
        // so here we map over retuned option changes, to end with an array of filter options.
        const options = Array.isArray(change) ? change.map((c) => `${c.key}`) : change.key;
        setFacet?.({ facet: `${facet.id}`, options, overwrite });
    };

    return (
        <FacetWrapper headline={translate('product-filter.filters.intelligent-sorting-facet-name')}>
            {isLoadingInitially ? (
                <FacetSkeleton />
            ) : (
                <FacetCheckbox
                    options={IntelligentSortingOptions}
                    initialLoadSize={3}
                    handleChange={(change) =>
                        handleFacetChange({
                            change,
                            facet: {
                                id: FILTER_INTELLIGENT_KEY,
                            },
                        })
                    }
                />
            )}
        </FacetWrapper>
    );
};
