import { controllableInputElementsProps } from '$shared/components/form';
import { useTranslation } from '$shared/utils';
import React, { InputHTMLAttributes, useMemo, useState } from 'react';
import Select from 'react-select';
import { createFilter } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { StyledHelpText } from '../../help-text';
import { StyledInvalidMessage } from '../../invalid-message';
import { customStyles } from './styled';

type InputProps = Omit<InputHTMLAttributes<HTMLSelectElement>, 'onChange'>;

export type Option<T> = {
    value: string;
    label?: string;
    checked?: boolean;
    isDisabled?: boolean;
    option?: T;
};

export type SelectorElementProps<TOption> = InputProps &
    controllableInputElementsProps & {
        allowMultiSelect?: boolean;
        options: Option<TOption>[];
        menuPlacement?: 'top' | 'bottom' | 'auto';
        filterOptionsConfig?: Record<
            string,
            string | boolean | ((option: FilterOptionOption<TOption>) => string) // Because the interface "Config" is not exported from react-selects models
        >;
        controlledValue?: Option<TOption> | readonly Option<TOption>[] | null;
        onClear?: () => void;
        onChange?: (
            option: Option<TOption> | readonly Option<TOption>[] | null | undefined
        ) => void;
    };

export const SelectorElement = <T,>({
    options,
    controlledValue,
    onChange,
    onClear,
    allowMultiSelect,
    placeholder,
    isRequired,
    invalidMessage,
    isInvalid,
    helpText,
    filterOptionsConfig,
    menuPlacement = 'bottom',
}: SelectorElementProps<T>) => {
    const { translate } = useTranslation();
    const [localState, setLocalState] = useState<Option<T> | readonly Option<T>[] | null>(
        options.filter((o) => o.checked)
    );

    const currValue = controlledValue || localState;

    const handleChange = (option: Option<T> | readonly Option<T>[] | null) => {
        if (onChange) {
            onChange(option);
        }
        if (!controlledValue) {
            setLocalState(option);
        }
    };

    const styles = useMemo(() => customStyles({ isInvalid, menuPlacement }), [
        isInvalid,
        menuPlacement,
    ]);

    return (
        <>
            <Select
                styles={styles}
                value={currValue}
                defaultValue={currValue}
                onChange={(e) => {
                    if (e === null) {
                        onClear?.();
                    } else {
                        handleChange(e);
                    }
                }}
                isClearable={!!onClear}
                isMulti={allowMultiSelect}
                options={options}
                menuPlacement={menuPlacement}
                menuPosition={menuPlacement === 'auto' ? 'fixed' : undefined}
                filterOption={createFilter(filterOptionsConfig)}
                placeholder={placeholder && `${placeholder} ${isRequired ? ' *' : ''}`}
                noOptionsMessage={() => translate('forms.fields.dropdown.no-options-text')}
            />

            {invalidMessage ? (
                <StyledInvalidMessage children={invalidMessage} />
            ) : helpText ? (
                <StyledHelpText children={helpText} />
            ) : null}
        </>
    );
};

export default SelectorElement;
