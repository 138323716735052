import { FC } from 'react';
import styled from '@emotion/styled';
import { basketSummaryGrid } from './basket-summary-styles';
import { Skeleton } from '../skeleton';

interface BasketSummaryLineProps {
    className?: string;
    label?: string;
    value: string;
    isLoading?: boolean;
}

const StyledBasketSummaryLine = styled.li(({ theme }) => ({
    ...basketSummaryGrid,
    alignItems: 'flex-end',
    color: theme.colors.grey90,
}));

export const BasketSummaryLine: FC<BasketSummaryLineProps> = (props) => {
    return (
        <StyledBasketSummaryLine className={props.className}>
            {props.isLoading ? (
                <Skeleton pulsating height={'1.5em'} width={'100px'} />
            ) : (
                <span children={props.label} />
            )}
            {props.isLoading ? (
                <Skeleton pulsating height={'1.2em'} width={'100px'} />
            ) : (
                <span children={props.value} />
            )}
        </StyledBasketSummaryLine>
    );
};
