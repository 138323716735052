import React from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { usePage } from '$templates/pages';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import { getCultureInfoUrlObj } from '$shared/utils/market/helpers/culture-info.helper';
import { StyledLink, StyledPlainLink, StyledLinkContentWrapper } from './style';
import { ButtonProps, StyledContent, StyledIcon, StyledSpinnerWrapper } from '../button';
import { Icon } from '../icon';
import Spinner from '../spinner';

type AnchorProps = React.HTMLProps<HTMLAnchorElement>;

export interface LinkProps
    extends Pick<AnchorProps, 'target' | 'title' | 'className' | 'disabled'>,
        Pick<NextLinkProps, 'passHref'> {
    /**
     * The path or URL to navigate to. This is the only required prop
     */
    href?: NextLinkProps['href'];

    /**
     * Refetch the page in the background. Any `<Link />` that is in the viewport (initially or through scroll) will be preloaded.
     * When prefetch is set to false, prefetching will still occur on hover.
     * Pages using Static Generation will preload JSON files with the data for faster page transitions.
     * _Prefetching is only enabled in production_.
     */
    prefetch?: NextLinkProps['prefetch'];

    /**
     *  Replace the current history state instead of adding a new url into the stack.
     */
    replace?: NextLinkProps['replace'];

    /**
     *  Scroll to the top of the page after a navigation
     */
    scroll?: NextLinkProps['scroll'];

    /**
     * Update the path of the current page without rerunning `getStaticProps`, `getServerSideProps` or `getInitialProps`
     */
    shallow?: NextLinkProps['shallow'];

    /**
     * Renders a plain link without underline
     */
    plain?: boolean;

    /**
     * Set the variant of the link type
     */
    variant?: ButtonProps['variant'];

    /**
     * Add an icon to the left of the text
     */
    iconLeft?: React.ReactNode;

    /**
     * Add an icon to the right of the text
     */
    iconRight?: React.ReactNode;

    noIcon?: boolean;

    isLoading?: boolean;

    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;

    /**
     * Lets you use isActive on children via function as a child
     */
    children?: ((props: { isActive?: boolean }) => React.ReactNode) | React.ReactNode;
}

export const Link = ({
    plain,
    href,
    prefetch,
    replace,
    shallow,
    scroll = true,
    variant,
    iconLeft,
    iconRight,
    noIcon,
    children,
    isLoading,
    ...rest
}: LinkProps) => {
    const { breadcrumb } = usePage() || {};
    const { market, culture } = usePage();
    const router = useKyoceraRouter();

    /*
     * Convert links to use market-url strategi
     */
    const url = href && getCultureInfoUrlObj(href, culture, market);

    const isLinkInBreadcrumbs = !!breadcrumb?.items?.find((b) => b?.url === url);

    const isActive = router.asPath === url || isLinkInBreadcrumbs;

    const contents: React.ReactNode =
        typeof children === 'function' ? children({ isActive }) : children;

    // Set scroll to false, if href is Id anchor #ID
    if (typeof url === 'string' && url?.includes('#')) {
        scroll = false;
    }

    // Default arrow right icon if iconRight is undefined
    // Checking for undefined supports disabling by passing null or false
    if (!noIcon && !iconLeft && !iconRight && variant === 'plain') {
        iconRight = <Icon icon="arrowRight" color="blue" size={20} />;
    }

    /**
     * Use a plain <a /> when compositioned with a button,
     * to prevent style conflicts
     */
    const linkComponent =
        !variant || plain ? (
            <StyledPlainLink {...rest}>{contents}</StyledPlainLink>
        ) : (
            <StyledLink variant={variant} {...rest}>
                <StyledLinkContentWrapper isLoading={isLoading}>
                    {iconLeft ? <StyledIcon children={iconLeft} variant={variant} /> : null}
                    {variant ? (
                        <StyledContent variant={variant}>{contents}</StyledContent>
                    ) : (
                        contents
                    )}
                    {iconRight ? <StyledIcon children={iconRight} variant={variant} /> : null}
                </StyledLinkContentWrapper>
                {isLoading && (
                    <StyledSpinnerWrapper>
                        <Spinner />
                    </StyledSpinnerWrapper>
                )}
            </StyledLink>
        );

    return url ? (
        <NextLink
            href={url}
            passHref={true}
            prefetch={prefetch}
            replace={replace}
            scroll={scroll}
            shallow={shallow}
            children={linkComponent}
        />
    ) : (
        linkComponent
    );
};

export default Link;
