import { isBrowser } from './browser.helper';

/*\
|*|
|*|  :: cookies.js ::
|*|
|*|  A complete cookies reader/writer framework with full unicode support.
|*|
|*|  Revision #3 - July 13th, 2017
|*|
|*|  https://developer.mozilla.org/en-US/docs/Web/API/document.cookie
|*|  https://developer.mozilla.org/User:fusionchess
|*|  https://github.com/madmurphy/cookies.js
|*|
|*|  This framework is released under the GNU Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  Syntaxes:
|*|
|*|  * setCookieItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * getCookieItem(name)
|*|  * removeItem(name[, path[, domain]])
|*|  * hasItem(name)
|*|  * keys()
|*|
\*/

export function getCookieItem(sKey: string) {
    if (!isBrowser) {
        return;
    }
    if (!sKey) {
        return null;
    }

    return (
        decodeURIComponent(
            document.cookie.replace(
                new RegExp(
                    '(?:(?:^|.*;)\\s*' +
                        encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') +
                        '\\s*\\=\\s*([^;]*).*$)|^.*$'
                ),
                '$1'
            )
        ) || null
    );
}

export function setCookieItem(
    sKey: string,
    sValue: string,
    vEnd: number | Date | string = Infinity,
    sPath = '/',
    sDomain?: string,
    bSecure?: boolean,
    sameSite?: string
) {
    if (!isBrowser) {
        return;
    }

    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
        return false;
    }

    let sExpires = '';

    if (vEnd) {
        switch (vEnd.constructor) {
            case Number:
                sExpires =
                    vEnd === Infinity
                        ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
                        : '; max-age=' + vEnd;
                /*
                Note: Despite officially defined in RFC 6265, the use of `max-age` is not compatible with any
                version of Internet Explorer, Edge and some mobile browsers. Therefore passing a number to
                the end parameter might not work as expected. A possible solution might be to convert the the
                relative time to an absolute time. For instance, replacing the previous line with:
                */
                /*
                sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; expires=" + (new Date(vEnd * 1e3 + Date.now())).toUTCString();
                */
                break;
            case String:
                sExpires = '; expires=' + vEnd;
                break;
            case Date:
                sExpires = '; expires=' + (vEnd as Date).toUTCString();
                break;
        }
    }

    // tslint:disable-next-line: max-line-length
    document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(sValue)}${sExpires}${
        sDomain ? '; domain=' + sDomain : ''
    }${sPath ? '; path=' + sPath : ''}${bSecure ? '; secure' : ''}${
        sameSite ? '; samesite=' + sameSite : ''
    }`;
    return true;
}

export function removeCookieItem(sKey: string, sPath = '/', sDomain?: string) {
    if (!isBrowser) {
        return;
    }
    if (!hasItem(sKey)) {
        return false;
    }

    // tslint:disable-next-line: max-line-length
    document.cookie = `${encodeURIComponent(sKey)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${
        sDomain ? '; domain=' + sDomain : ''
    }${sPath ? '; path=' + sPath : ''}`;

    return true;
}

export function hasItem(sKey: string) {
    if (!isBrowser) {
        return;
    }
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
        return false;
    }
    return new RegExp(
        '(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\='
    ).test(document.cookie);
}

export function keys() {
    if (!isBrowser) {
        return;
    }
    const aKeys = document.cookie
        .replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '')
        .split(/\s*(?:=[^;]*)?;\s*/);

    for (let nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
        aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
    }

    return aKeys;
}
