import { StyledButton, StyledButtonContentWrapper, StyledContent } from './../button/style';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { SignInForPrice } from '$templates/pages/components/P50ProductDetailPage/components/pdp-core/components/sign-in-for-price/sign-in-for-price';
import { ProductImage } from '../image';
import { Spinner } from '../spinner';

export const StyledProductLineGrid = styled.div(() => ({
    display: 'grid',
    padding: `${theme.space.gutter}`,
    fontSize: theme.fontSizes.xs,
    gap: theme.space[3],
    gridTemplateRows: '0.5fr',
    gridTemplateColumns: 'clamp(95px, 25vw, 130px) 2fr',
    gridTemplateAreas: `
    "image name"
    "image number"
    "image pricestock" 
    "wrapper wrapper"`,

    [breakpoints.md]: {
        padding: `${theme.space[3]} `,
        gap: `0 ${theme.space[2]}`,
        alignItems: 'center',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr 1.5fr 1fr 1fr 1fr 24px 220px',
        gridTemplateAreas: `"image name number price status favorites basket"`,
    },
    [breakpoints.lg]: {
        alignItems: 'center',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '.5fr 1.2fr 0.8fr 0.8fr 0.8fr 24px 220px',
        gridTemplateAreas: `"image name number price status favorites basket"`,
    },
}));

export const StyledProductLineHeaderGrid = styled(StyledProductLineGrid)<{
    hideOnMobile?: boolean;
}>(
    () => ({
        display: 'flex',
        alignContent: 'center',
        [breakpoints.sm]: {
            width: '100%',
            marginBottom: 0,
        },
    }),
    ifProp('hideOnMobile', {
        display: 'none',
        [breakpoints.md]: {
            display: 'grid',
        },
    })
);

export const StyledProductHeader = styled.div(() => ({
    gridArea: 'name',
}));

export const StyledProductHeaderNumber = styled.div(() => ({
    gridArea: 'number',
}));

export const StyledProductHeaderPrice = styled.div(() => ({
    gridArea: 'price',
}));

export const StyledProductHeaderStatus = styled.div(() => ({
    gridArea: 'status',
}));

export const StyledProductHeaderFavorites = styled.div(() => ({
    gridArea: 'favorites',
}));

export const StyledProductLineImage = styled.div<{ noImage: boolean }>(({ noImage }) => ({
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    backgroundColor: theme.colors.white,
    border: `solid 1px ${theme.colors.grey30}`,
    gridArea: 'image',
    marginRight: theme.space[2],
    textDecoration: 'none',
    aspectRatio: '1',
    height: 'fit-content',
    maxWidth: '150px',
    padding: 2,
    ...(noImage
        ? {}
        : {
              '&:hover': {
                  border: `solid 2px ${theme.colors.grey30}`,
              },
          }),

    [breakpoints.lg]: {
        maxWidth: '64px',
    },

    img: {
        backgroundColor: 'inherit;',
    },
}));

export const StyledProductLineDrawing = styled(StyledProductLineImage)({
    justifyContent: 'center',
    alignItems: 'center',
    gridArea: undefined,
});

export const DrawingLink = styled.a({
    gridArea: 'image',
});

export const StyledProductLineName = styled.div(() => ({
    gridArea: 'name',
}));

export const StyledProductLineNameLink = styled.span(({ theme }) => ({
    color: theme.colors.grey90,
    fontWeight: 600,
    textDecoration: 'none',
    transition: '150ms color',

    '&:hover': {
        color: theme.colors.blue90,
        textDecoration: 'underline',
    },
}));

export const StyledProductLineNumber = styled.div(({ theme }) => ({
    color: theme.colors.blue90,
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.fontSizes.xs,
    fontWeight: 600,
    gridArea: 'number',
    textDecoration: 'none',
    transition: '150ms color',

    '&:hover': {
        color: theme.colors.blue90,
        textDecoration: 'underline',

        [`${StyledProductLineSecondary}`]: {
            color: 'inherit',
        },
    },
}));

export const StyledProductLineSecondary = styled.div(({ theme }) => ({
    color: theme.colors.grey90,
    fontWeight: 400,
}));

export const StyledPriceStockWrapper = styled.div(() => ({
    gridArea: 'pricestock',
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    gap: theme.space[2],

    [breakpoints.md]: {
        display: 'contents',
        gridArea: 'auto',
    },
}));

export const StyledProductLinePrice = styled.div<{ discount?: boolean }>(
    {
        gridArea: 'price',
        textAlign: 'end',
        flex: '1',
        whiteSpace: 'nowrap',
        fontWeight: theme.fontWeights.semiBold,
        color: theme.colors.grey90,

        [breakpoints.xxs]: {
            textAlign: 'right',
        },
        [breakpoints.md]: {
            textAlign: 'left',
            whiteSpace: 'normal',
        },
    }
    // KYOC-1200 - not needed/wanted for now
    // ifProp('discount', {
    //     color: theme.colors.red50,
    // })
);

export const StyledProductLineStatus = styled.div(() => ({
    gridArea: 'status',
    display: 'inline',
}));

export const StyledProductWrapper = styled.div(() => ({
    gridArea: 'wrapper',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.space[3],

    [breakpoints.md]: {
        display: 'contents',
        gridArea: 'auto',
    },
}));

export const StyledProductLineFavorites = styled.div(() => ({
    gridArea: 'favorites',
}));

export const StyledProductLineBasket = styled.div(() => ({
    gridArea: 'basket',
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'space-between',
    justifySelf: 'right',
    alignItems: 'center',
    gap: theme.space[2],
    paddingLeft: theme.space[2],

    [`${StyledButton}`]: {
        fontSize: theme.fontSizes.xs,

        // CART ICON SPECIFIED
        [`${StyledContent}`]: {
            padding: `0 17px 0 15px`,
        },
    },

    [breakpoints.md]: {
        paddingLeft: theme.space[1],
        width: '220px',

        [`${StyledButton}`]: {
            fontSize: theme.fontSizes.lg,

            [`${StyledContent}`]: {
                padding: `0 ${theme.space[5]}`,
            },
        },
    },

    [breakpoints.xl]: {
        width: '100%',
    },

    [`${StyledButtonContentWrapper}`]: {
        height: 40,
        lineHeight: 0,
    },
}));

export const StyledProductLineSignIn = styled(SignInForPrice)(() => ({}));

export const ExtraServicesContainer = styled.div({
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'flex-end',
});

export const StyledAdditionalText = styled.div({
    gridRow: 2,
    gridColumn: 7,
    fontSize: 11,
    maxWidth: '130px',

    [breakpoints.md]: {
        fontSize: theme.fontSizes['3xs'],
    },
});

export const OverlayImageWrapper = styled.div(({ theme }) => ({
    alignItems: 'center',
    background: theme.colors.white,
    display: 'flex',
    justifyContent: 'center',
    minHeight: 100,
    minWidth: 100,
    padding: theme.space[4],

    img: {
        maxWidth: `calc(90vw - ${theme.space[6]})`,
        maxHeight: `calc(90vh - ${theme.space[6]})`,
    },
}));

export const StyledProductImage = styled(ProductImage)(() => ({
    backgroundColor: theme.colors.white,
}));

export const StyledSpinner = styled(Spinner)({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
});
