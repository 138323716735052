import React, { FC } from 'react';
import { useProduct } from '$shared/utils/product';
import { ImagePreviewer } from '$shared/components/image-previewer';
import { ProductImage } from '$shared/components/image';

export const PdpImageArea: FC = () => {
    const { photoPath, sketchPath, description1 } = useProduct();
    const itemsData = [photoPath, sketchPath];
    const itemDataMapper = (srcArr?: (string | undefined)[]) => {
        return srcArr?.filter(Boolean).map((src) => ({ src }));
    };

    return (
        <ImagePreviewer itemsData={itemDataMapper(itemsData)}>
            {(item) => (
                <ProductImage
                    width={50}
                    height={50}
                    alt={description1}
                    src={item.src}
                    style={{ pointerEvents: 'none' }}
                />
            )}
        </ImagePreviewer>
    );
};
