import { Link } from '$shared/components/link';
import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { SearchResults } from '../search-results/search-results';

const dialogBreakpoint = breakpoints.frame;

export const StyledSearchDialog = styled.div<{ multiColumn: boolean }>(
    ({ multiColumn, theme }) => ({
        background: theme.colors.white,
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
        display: 'grid',
        grid:'repeat(5, auto) / 1fr',

        [dialogBreakpoint]: {
            grid: multiColumn ? 'auto 1fr auto / 50% 1fr' : undefined,
        },
    })
);

export const StyledNoResults = styled.div(({ theme }) => ({
    padding: theme.space[5],
    textAlign: 'center',
}));

export const StyledSearchResults = styled(SearchResults)<{
    darkBackground?: boolean;
    tall?: boolean;
    noBottomSpacing?: boolean;
}>(({ darkBackground, tall, noBottomSpacing, theme }) => ({
    gridRowEnd: tall ? 'span 2' : undefined,
    minWidth: 0, // Prevent text overflow

    [dialogBreakpoint]: {
        background: darkBackground ? theme.colors.grey20 : undefined,
        paddingBottom: noBottomSpacing ? 0 : theme.space[5],
    },
}));

export const StyledSeeAll = styled(Link)<{ multiColumn: boolean }>(({ multiColumn, theme }) => ({
    display: 'block',
    fontWeight: 700,
    color: theme.colors.defaultText,
    gridArea: '-2 / 1 / -1 / -1',
    padding: theme.space[5],

    '&:hover': {
        color: 'inherit',
    },

    [dialogBreakpoint]: {
        textAlign: multiColumn ? 'center' : undefined,
    },
}));
