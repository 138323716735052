import styled from '@emotion/styled';
import { QuoteProps } from './quote';

type StyledQuoteProps = Pick<QuoteProps, 'contentAlignment'>;

export const StyledQuote = styled.div<StyledQuoteProps>(({ contentAlignment }) => ({
    fontStyle: 'italic',
    textAlign: contentAlignment,
    maxWidth: '750px',
    margin: 'auto'
}));

export const StyledAuthorTagQuote = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.xs,
    color: theme.colors.grey80,
    fontStyle: 'normal',
    paddingTop: '10px',
}));
