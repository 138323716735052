import { addFavorite } from '$features/my-pages-service/add-favorite';
import { deleteFavorite } from '$features/my-pages-service/delete-favorite';
import { getAllFavorites } from '$features/my-pages-service/get-all-favorites';
import { useNotification } from '$features/notifications/hooks';
import { useAuthentication } from '$shared/hooks/useAuthentication';
import { formatString, useTranslation } from '$shared/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { TranslationKey } from '~/lib/data-contract';

const favoritesQueryKey = ['useFavorites'];
const errorKeyGetAll: TranslationKey = 'favorites.error-loading-favorites';
const errorKeyAdd: TranslationKey = 'favorites.error-adding-favorite';
const errorKeyDelete: TranslationKey = 'favorites.error-removing-favorite';

let errorDebounce: number | undefined;

export function useFavorites() {
    const { isAuthenticated } = useAuthentication();
    const { push } = useNotification();
    const { translate } = useTranslation();
    const queryClient = useQueryClient();

    const logErrorDebounced = useCallback(
        (error: unknown, consoleMessage: string, notificationMessage: string) => {
            if (errorDebounce) {
                clearTimeout(errorDebounce);
            }

            errorDebounce = (setTimeout(() => {
                console.error(consoleMessage, error);
                push({ text: notificationMessage });
            }, 300) as unknown) as number;
        },
        [push]
    );

    const { data = [], isInitialLoading } = useQuery<string[]>(
        favoritesQueryKey,
        ({ signal }) => getAllFavorites(signal),
        {
            enabled: Boolean(isAuthenticated),
            onError: (error) =>
                logErrorDebounced(
                    error,
                    '[useFavorites] Error getting favorites.',
                    translate(errorKeyGetAll)
                ),
        }
    );

    const addFavoriteMutation = useMutation(addFavorite, {
        onMutate: async (sanitizedItemNumber) => {
            // Cancel ongoing get-requests.
            queryClient.cancelQueries(favoritesQueryKey);

            // Store previous value, for fallback.
            const previousValue = queryClient.getQueryData<string[]>(favoritesQueryKey);

            // Update data optimistically.
            queryClient.setQueryData<string[]>(favoritesQueryKey, (value) =>
                value ? [...value, sanitizedItemNumber] : [sanitizedItemNumber]
            );

            // Return data for `onError` and `onSettled`.
            return { previousValue };
        },

        onError: (error, sanitizedItemNumber, context) => {
            logErrorDebounced(
                error,
                `[useFavorites] Error adding favorite: '${sanitizedItemNumber}'.`,
                formatString(translate(errorKeyAdd), sanitizedItemNumber)
            );
            queryClient.setQueryData(favoritesQueryKey, context?.previousValue);
        },

        onSettled: () => {
            queryClient.invalidateQueries(favoritesQueryKey);
        },
    });

    const deleteFavoriteMutation = useMutation(deleteFavorite, {
        onMutate: async (sanitizedItemNumber) => {
            // Cancel ongoing get-requests.
            queryClient.cancelQueries(favoritesQueryKey);

            // Store previous value, for fallback.
            const previousValue = queryClient.getQueryData<string[]>(favoritesQueryKey);

            // Update data optimistically.
            queryClient.setQueryData<string[]>(
                favoritesQueryKey,
                (value) => value && value.filter((s) => s !== sanitizedItemNumber)
            );

            // Return data for `onError` and `onSettled`.
            return { previousValue };
        },

        onError: (error, sanitizedItemNumber, context) => {
            logErrorDebounced(
                error,
                `[useFavorites] Error deleting favorite: '${sanitizedItemNumber}'.`,
                formatString(translate(errorKeyDelete), sanitizedItemNumber)
            );
            queryClient.setQueryData(favoritesQueryKey, context?.previousValue);
        },

        onSettled: () => {
            queryClient.invalidateQueries(favoritesQueryKey);
        },
    });

    const favorites = useMemo(() => new Set(data), [data]);
    const isFavorite = useCallback(
        (sanitizedItemNumber: string) => favorites.has(sanitizedItemNumber),
        [favorites]
    );

    return {
        addFavorite: addFavoriteMutation.mutateAsync,
        deleteFavorite: deleteFavoriteMutation.mutateAsync,
        isBusy: isInitialLoading,
        isFavorite,
        favorites,
    };
}
