// TODO: should be cooked into the "fetcher.ts" - so we don't have two fetchers.

import { toast } from 'react-toastify';
import { http } from '~/lib/http';

type cancellableFetchProps = { signal?: AbortSignal; url: string; options?: RequestInit };

export function cancellableFetch<T = unknown>({
    signal,
    url,
    options,
}: cancellableFetchProps): Promise<T> {
    return http(url, {
        method: 'GET',
        ...options,
        signal,
    }).then((res) => {
        if (!res.ok) {
            toast.error('Network response was not ok');
            throw new Error('Network response was not ok');
        }

        return res.json() as Promise<T>;
    });
}
