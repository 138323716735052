import React, { FC } from 'react';
import { useMarket } from '$shared/utils/market/hooks/useMarket';
import { marketKeys } from '$shared/utils/market/model/marketModel';
import {
    StyledHeaderButtons,
    StyledIcon,
    StyledLabel,
    StyledHeaderButton,
    StyledHeaderButtonWrapper,
    BasketCountBadge,
} from './style';
import { Link } from '$shared/components/link';
import { useFrame } from '$shared/utils';
import { AuthenticationPanel } from './authentication-panel';
import { useSimpleBasket } from '$shared/hooks/useBasket/useBasket';
import { useAuthentication } from '$shared/hooks/useAuthentication';
import { usePage } from '$templates/pages';

export const HeaderButtons: FC = () => {
    const { market, culture } = usePage();
    const { languageSelectorIsOpen, setIsOpen } = useMarket();
    const { isAuthenticated, userFound } = useAuthentication();
    const { basketPage } = useFrame();

    const { basketCount } = useSimpleBasket();

    return (
        <StyledHeaderButtons>
            <StyledHeaderButtonWrapper>
                <StyledHeaderButton
                    onClick={() => {
                        sessionStorage.setItem(marketKeys.marketChosen, marketKeys.marketIsChosen);
                        setIsOpen(!languageSelectorIsOpen);
                        window.scrollTo(0, 0);
                    }}
                >
                    <StyledIcon icon="language" size="lg" />
                    <StyledLabel>{(market + ' / ' + culture).toUpperCase()}</StyledLabel>
                </StyledHeaderButton>
            </StyledHeaderButtonWrapper>

            <AuthenticationPanel />

            {isAuthenticated && userFound && basketPage && (
                <StyledHeaderButtonWrapper>
                    <Link href={basketPage.url} title={basketPage.title} target={basketPage.target}>
                        <StyledHeaderButton>
                            <StyledIcon icon="cart" size="lg" />
                            <StyledLabel>{basketPage.text}</StyledLabel>
                            {!!basketCount && <BasketCountBadge>{basketCount}</BasketCountBadge>}
                        </StyledHeaderButton>
                    </Link>
                </StyledHeaderButtonWrapper>
            )}
        </StyledHeaderButtons>
    );
};
export default HeaderButtons;
