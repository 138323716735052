import styled from '@emotion/styled';

export const StyledWrapper = styled.div(({ theme }) => ({
    color: theme.colors.grey90,
    fontSize: theme.fontSizes.xs,
}));

export const StyledSignInButton = styled.button(({ theme }) => ({
    appearance: 'none',
    background: 'none',
    border: 'none',
    color: theme.colors.blue90,
    cursor: 'pointer',
    font: 'inherit',
    padding: 0,
    textDecoration: 'underline',

    '&:hover': {
        color: theme.colors.blue100,
    },
}));
