import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactHookForm__InputProps } from '../../../models/form-models';
import {
    TextareaElement,
    TextareaElementProps,
} from '../../controllable-elements/textarea-element';

export type TextareaProps = TextareaElementProps & ReactHookForm__InputProps;

export const Textarea: FC<TextareaProps> = ({ name, rules, defaultValue, ...rest }) => {
    const {
        formState,
        getFieldState,
        register,
        watch,
    } = useFormContext();

    const watchValue = watch(name, defaultValue);
    const { error } = getFieldState(name, formState);

    return (
        <TextareaElement
            isActive={watchValue}
            isInvalid={!!error}
            invalidMessage={error?.message}
            isRequired={!!rules?.required}
            {...rest}
            {...(register && name && register(name, rules))}
        />
    );
};
