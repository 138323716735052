import React from 'react';
import { ImageMedia } from '.';
import Video from '$shared/components/video';
import { StyledSpotMedia } from './style';

export type SpotMediaProps = {
    /**
     * Indicated if there is media and what type of media
     */
    type?: 'video' | 'image';

    /**
     * Source of media. Supports YouTube, Vimeo and selfhosted
     */
    src?: string;

    alt?: string;
    title?: string;

    /**
     * display videoPlayer controls
     */
    controls?: boolean;

    /**
     * Make sure the media covers parent. This positions the component absolute
     */
    cover?: boolean;

    /**
     * Unique string used to trigger repositioning video.
     * @see Video component for more info
     */
    repositionKey?: string;

    /**
     * Used while loading video script and building initial buffer.
     * Required for selfhosted videos. For external videos this is optional
     */
    videoPosterSrc?: string;

    /**
     * Should the video loop
     */
    videoLoop?: boolean;

    /**
     * Should the video autoplay
     */
    videoAutoPlay?: boolean;

    /**
     * size attr for images: ex. (max-width: ${breakpointSizes.sm}px) 100vw, 300px
     */
    imageSizes?: string;

    width?: number;
    height?: number;
};

export const SpotMedia = ({
    type = 'image',
    cover = false,
    controls,
    src,
    videoLoop,
    videoAutoPlay,
    videoPosterSrc,
    repositionKey,
    imageSizes,
    ...rest
}: SpotMediaProps) => {
    if (!src) return null;

    return (
        <StyledSpotMedia cover={cover}>
            {type === 'image' ? (
                <ImageMedia src={src} cover={cover} imageSizes={imageSizes} {...rest} />
            ) : null}
            {type === 'video' ? (
                <Video
                    muted={true}
                    playing={videoAutoPlay}
                    controls={controls}
                    src={src}
                    loop={videoLoop}
                    repositionKey={repositionKey}
                    posterSrc={videoPosterSrc}
                    cover={cover}
                    {...rest}
                />
            ) : null}
        </StyledSpotMedia>
    );
};
export default SpotMedia;
