import React from 'react';
import { IP35CategoryDetailPage } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';
import { StyledModulesWrapper } from '../P60ModulePage/style';

type P35ProductOverviewDetailPageProps = IP35CategoryDetailPage;

export const P35ProductOverviewDetailPage = ({
    pageElements,
}: P35ProductOverviewDetailPageProps) => (
    <StyledModulesWrapper>
        <DynamicBlockList elements={pageElements} />
    </StyledModulesWrapper>
);
