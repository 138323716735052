import { TabMenu } from '$shared/components/tab-menu';
import { DynamicBlockList } from '$templates/blocks';
import { StyledModulesWrapper } from '$templates/pages/components/P60ModulePage/style';

import React, { FC } from 'react';
import { ILink, IPageElement, ISubmenuItem } from '~/lib/data-contract';
import { IModules } from '~/lib/data-contract';
import { ModuleContainer } from '../module-container';

export type SubmenuModuleProps = {
    buttonLink?: ILink;
    items?: ISubmenuItem[];
    id?: string;
};

const extractModules = (blocks: IPageElement[] | undefined) => {
    if (blocks) {
        if (blocks?.length > 0) {
            return (
                <StyledModulesWrapper>
                    <DynamicBlockList elements={blocks as IModules[]} />
                </StyledModulesWrapper>
            );
        }
    }
};

export const Submenu: FC<SubmenuModuleProps> = ({ buttonLink, items, id }) => {
    return (
        <ModuleContainer id={id} noSpacing>
            <TabMenu
                buttonLink={buttonLink}
                tabs={items?.map((item: ISubmenuItem) => {
                    return { tabName: item.tabName, content: extractModules(item.modules) };
                })}
            />
        </ModuleContainer>
    );
};
