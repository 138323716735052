import { BasketSummary } from '$shared/components/basket-summary/basket-summary';
import { breakpoints } from '$theme';
import styled from '@emotion/styled';

const twoColumnsBreakpoint = breakpoints.sm;

export const StyledBasketPage = styled.div(({ theme }) => ({
    display: 'block',
    gap: theme.space[5],
    grid: 'auto-flow / 1fr',

    [twoColumnsBreakpoint]: {
        display: 'grid',
        grid: 'auto-flow / 2fr 1fr',
    },
}));

export const StyledBasketHeader = styled.div(({ theme }) => ({
    marginTop: theme.space[4.5],
    marginBottom: theme.space[6],
}));

export const StyledBackButton = styled.button(({ theme }) => ({
    all: 'unset',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: theme.fonts.body,
    fontSize: theme.fontSizes.xs,
    fontWeight: 600,
    gap: theme.space[2],
    textTransform: 'uppercase',
}));

export const StyledBasketTitle = styled.span(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.space[2],
    marginBottom: theme.space[4],
}));

export const StyledBasketCount = styled.span(({ theme }) => ({
    fontSize: theme.fontSizes.md,
    lineHeight: '150%',
}));

export const StyledBasketSummary = styled(BasketSummary)(({ theme }) => ({
    alignSelf: 'flex-start',
    margin: theme.space[3],

    [twoColumnsBreakpoint]: {
        margin: 0,
    },
}));

export const StyledDynamicblocks = styled.div(() => ({
    gridColumnEnd: 'span 2',
}));
