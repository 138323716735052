import { theme } from '$theme';
import styled from '@emotion/styled';
import { rgba } from 'color2k';
import { StyledCarouselItem } from '../carousel-light/styled';
import { StyledCarouselWrapper } from '../module-carousel/styled';

export const StyledImagePreviewerWrapper = styled.div({
    display: 'grid',
    gap: theme.space[4],

    [`${StyledCarouselItem}`]: {
        height: '105px',
        width: '105px',
    },

    img: {
        backgroundColor: theme.colors.white,
    },
});

export const StyledOverlayContentWrapper = styled.div({
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: theme.space[4],

    [`${StyledCarouselItem}`]: {
        height: '70px',
        width: '70px',
    },
});

export const StyledImagePreviewLargeImage = styled.div({
    position: 'relative',
    padding: theme.space[3],
    cursor: 'zoom-in',

    img: {
        backgroundColor: theme.colors.white,
    },

    ':after': {
        content: '""',
        position: 'absolute',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
});

export const ClickableBackground = styled.div({
    position: 'absolute',
    inset: 0,
});

export const StyledImagePreviewLargeOverlay = styled.div({
    position: 'relative',
    padding: theme.space[3],
    maxWidth: 'calc(100vh - 40px)',
    margin: '0 auto',
    top: '50%',
    transform: 'translate(0, -50%)',

    img: {
        backgroundColor: theme.colors.white,
    },

    ':after': {
        content: '""',
        position: 'absolute',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
});

export const StyledOverlayCarouselWrapper = styled.div({
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    [`${StyledCarouselWrapper}`]: {
        position: 'relative',
        backgroundColor: rgba(0, 0, 0, 0.2),
        boxShadow: '1px 1px 15px -5px rgba(0, 0, 0, 0.2)',
        padding: theme.space[2],
        margin: theme.space[4],
    },
});
