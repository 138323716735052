import { breakpointSizes } from '$theme';
import { useEffect, useMemo, useState } from 'react';

interface WindowSize {
    blockSize: number | undefined;
    inlineSize: number | undefined;
}

const debounceMs = 100;
const defaultSize: WindowSize = { blockSize: undefined, inlineSize: undefined };
const eventName = 'resize';
const listenerOptions = { passive: true };

export function useWindowSize() {
    const [size, setSize] = useState<WindowSize>(defaultSize);

    useEffect(() => {
        if (!window) return;

        let debounce: number;

        const listener = () => {
            if (debounce) {
                clearTimeout(debounce);
            }

            debounce = (setTimeout(() => {
                setSize({
                    blockSize: window.innerHeight,
                    inlineSize: window.innerWidth,
                });
            }, debounceMs) as unknown) as number;
        };

        setSize({
            blockSize: window.innerHeight,
            inlineSize: window.innerWidth,
        });
        window.addEventListener(eventName, listener, listenerOptions);

        return () => window.removeEventListener(eventName, listener);
    }, []);

    const isDesktop = useMemo(
        () => (size.inlineSize ? size.inlineSize >= breakpointSizes.frame : undefined),
        [size.inlineSize]
    );

    return {
        isDesktop,
        size,
    };
}
