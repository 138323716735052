import { Stock } from '$order-models/*';
import { formatString, useTranslation } from '$shared/utils';
import { FC, useMemo } from 'react';
import { useDeliveryEstimate } from '../hooks/use-delivery-estimate';
import { useStockMessage } from '../hooks/use-stock-message';
import { useStockStatus } from '../hooks/use-stock-status';
import { useWarehouseName } from '../hooks/use-warehouse-name';
import { StockIndicator } from '../stock-indicator/stock-indicator';
import { PriceAndStockProps } from '../stock-props';
import { StockStatus } from '../stock-status';
import {
    StockPopoverWrapper,
    StyledStockPopoverCircle,
    StyledStockPopoverCount,
    StyledStockPopoverText,
    StyledStockStatus,
    StockPopoverItem,
} from './stock-status-pop-up-styles';

interface StockStatusPopUpItem {
    deliveryEstimate: string;
    label: string;
    status: StockStatus;
    stockAvailable?: string | null;
}

function mapStock(
    stock: Stock,
    showStock: boolean,
    units: string | undefined
): StockStatusPopUpItem {
    const status = useStockStatus(stock.deliveryEstimate);
    const { translate } = useTranslation();

    return {
        deliveryEstimate: useDeliveryEstimate(status),
        label: showStock ? useWarehouseName(stock.location) : useStockMessage(status),
        status,
        stockAvailable:
            showStock && stock.stockAvailable !== undefined && stock.stockAvailable > -1
                ? units
                    ? `${stock.stockAvailable} ${units}`
                    : formatString(translate('stock.default-unit'), stock.stockAvailable)
                : null,
    };
}

export const StockStatusPopUp: FC<PriceAndStockProps> = (props) => {
    const stocks = useMemo(() => {
        const stocks = props.stock ? [props.stock] : [];
        if (props.showFurhterStock && props.furtherStock) stocks.push(...props.furtherStock);
        return stocks;
    }, [props.furtherStock, props.showFurhterStock, props.stock]);

    const items = stocks.map((s, i) =>
        mapStock(s, i === 0 ? !!props.showStock : !!props.showFurhterStock, props.units)
    );

    return (
        <StockPopoverWrapper>
            {items.map((item, index) => (
                <StockPopoverItem key={index}>
                    <StyledStockPopoverCircle>
                        <StockIndicator status={item.status} />
                    </StyledStockPopoverCircle>
                    {item.stockAvailable && (
                        <StyledStockPopoverCount children={item.stockAvailable} />
                    )}
                    <StyledStockStatus children={item.label} />
                    <StyledStockPopoverText children={item.deliveryEstimate} />
                </StockPopoverItem>
            ))}
        </StockPopoverWrapper>
    );
};
