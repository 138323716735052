import { ProductLineItem } from '$shared/components/product-line/product-line.model';
import { formatString, useFrame, useTranslation } from '$shared/utils';
import { searchKeys } from '$shared/utils/search/model/searchModel';
import { FC, KeyboardEvent, useCallback, useMemo } from 'react';
import { ISearchResultItemViewModel } from '~/lib/data-contract';
import { SearchResultCategories } from '../search-result/search-result-categories';
import { SearchResultPages } from '../search-result/search-result-pages';
import { SearchResultProducts } from '../search-result/search-result-products';
import {
    StyledNoResults,
    StyledSearchDialog,
    StyledSearchResults,
    StyledSeeAll,
} from './search-dialog-styles';

interface SearchDialogProps {
    categories: ISearchResultItemViewModel[];
    className?: string;
    isBusy: boolean;
    onClose: () => void;
    pages: ISearchResultItemViewModel[];
    products: ProductLineItem[];
    query: string;
}

export const SearchDialog: FC<SearchDialogProps> = (props) => {
    const { searchPage } = useFrame();
    const { translate } = useTranslation();

    const onKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.code === 'Escape') {
                props.onClose();
                e.preventDefault();
            }
        },
        [props.onClose]
    );

    const results = useMemo(
        () => props.products.length + props.pages.length + props.categories.length,
        [props.products, props.pages, props.categories]
    );

    const showSeeAllButton = useMemo(() => !props.isBusy && !!results, [props.isBusy, results]);

    const multiColumn = useMemo(
        () =>
            props.isBusy ||
            (!!props.pages.length && (!!props.products.length || !!props.categories.length)),
        [props.isBusy, props.pages, props.products, props.categories]
    );

    return (
        <StyledSearchDialog
            aria-description={translate('search.search-bar.dialog-description')}
            aria-label={translate('search.search-bar.dialog-label')}
            className={props.className}
            multiColumn={multiColumn}
            onKeyDown={onKeyDown}
            role="dialog"
        >
            {!props.isBusy && !results && (
                <StyledNoResults children={translate('search.search-bar.suggestions-no-results')} />
            )}

            <StyledSearchResults
                children={(animation) => (
                    <SearchResultPages
                        animation={animation}
                        highlightMatch={props.query}
                        pages={props.pages}
                    />
                )}
                isBusy={props.isBusy}
                itemsLength={props.pages.length}
                skeletonItemsLength={5}
                tall={true}
                noBottomSpacing={!!props.categories.length || !!props.products.length}
            />

            <StyledSearchResults
                children={(animation) => (
                    <SearchResultCategories
                        animation={animation}
                        highlightMatch={props.query}
                        categories={props.categories.slice(0, 4)}
                    />
                )}
                darkBackground={multiColumn}
                isBusy={props.isBusy}
                itemsLength={props.categories.length > 4 ? 4 : props.categories.length }
                skeletonItemsLength={3}
                title={translate('search.search-bar.product-categories')}
                noBottomSpacing={!!props.products.length}
            />

            <StyledSearchResults
                children={(animation) => (
                    <SearchResultProducts
                        animation={animation}
                        highlightMatch={props.query}
                        products={props.products}
                    />
                )}
                darkBackground={multiColumn}
                isBusy={props.isBusy}
                itemsLength={props.products.length}
                skeletonItemsLength={3}
                title={translate('search.search-bar.product-suggestions')}
            />

            {showSeeAllButton && (
                <StyledSeeAll
                    children={formatString(
                        translate('search.search-bar.suggestions-see-all'),
                        props.query
                    )}
                    href={`${searchPage?.url}?${searchKeys.queryParamKey}=${props.query}`}
                    multiColumn={multiColumn}
                />
            )}
        </StyledSearchDialog>
    );
};
