import { useEffect, useState } from 'react';

type UseInputFieldProps = {
    id?: string;
    helpText?: string;
    invalidMessage?: string;
    isInvalid?: boolean;
};

const generateID = () => Math.random().toString(36).substring(7);

export const useInputField = ({ id, helpText, isInvalid, invalidMessage }: UseInputFieldProps) => {
    // Make sure there is an ID
    // use useState to prevent generating different IDs
    // across SSR and client
    const [fieldId, setFieldId] = useState(id);

    // Define ids
    const labelId = `${fieldId}-label`;
    const helpTextId = `${fieldId}-description`;
    const invalidMessageId = `${fieldId}-error`;

    // should always refer to invalidMessageId if invalid and has invalidMessage
    const describedById = isInvalid || invalidMessage ? invalidMessageId : helpTextId;

    useEffect(() => {
        if (!fieldId) {
            const uniqueId = id || generateID();
            setFieldId(uniqueId);
        }
    }, [id, fieldId]);

    return {
        fieldId,
        labelId,
        helpTextId,
        invalidMessageId,
        describedById,
        showHelpText: helpText && describedById === helpTextId,
        showInvalidMessage: invalidMessage && describedById === invalidMessageId,
    };
};

// TODO: by now, this file should be unused and can be deleted, thanks.
