import { buildURL, cancellableFetch } from "$shared/utils";
import { ADD_FAVORITE_URL } from "./my-pages-endpoints";

const baseUrl = ADD_FAVORITE_URL;
const method = 'POST';

export async function addFavorite(sanitizedItemNumber: string, signal?: AbortSignal): Promise<string[]> {
    const url = buildURL(baseUrl, { sanitizedItemNumber });

    return cancellableFetch<string[]>({
        signal,
        url,
        options: {
            method,
        },
    });
}
