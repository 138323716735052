import Container from '$shared/components/container';
import Headline from '$shared/components/headline';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import { formatString, useTranslation } from '$shared/utils';
import { useSearchContent } from '$shared/utils/search/hooks/use-search-content';
import { FC, useEffect, useMemo, useState } from 'react';
import { IP120SearchPage } from '~/lib/data-contract';
import { useSearchHierarchy } from '$shared/utils/search/hooks/use-search-hierarchy';
import { StyledTabMenu } from './p120-search-page-styles';
import { SearchProductsTab } from './search-products-tab/search-products-tab';
import { SearchContentTab } from './search-content-tab/search-content-tab';
import { Filter } from '$shared/utils/filter/components';
import {
    AdditionalFilterQueryOptions,
    useFilterQuery,
} from '$shared/utils/filter/service/useFilterQuery';
import { ParsedUrlQuery } from 'querystring';

const maxResults = 10000;
const pageSize = 16;
const HIERARCHY_QUERY_KEY = 'hierarchy';

export const P120SearchPage: FC<IP120SearchPage> = () => {
    const { query, replace, pathname } = useKyoceraRouter();
    const { translate } = useTranslation();
    const [selectedHierarchyId, setSelectedHierarchyId] = useState<string | undefined>(
        query[HIERARCHY_QUERY_KEY] as string
    );
    const [productCount, setProductCount] = useState(0);

    const searchTerm = useMemo(
        () => (Array.isArray(query.search) ? query.search[0] : query.search),
        [query.search]
    );

    // Handle query param for hierarchy decoupled from the filter.
    useEffect(() => {
        const updatedQuery: ParsedUrlQuery = JSON.parse(JSON.stringify(query));
        if (!selectedHierarchyId) {
            delete updatedQuery[HIERARCHY_QUERY_KEY];
        } else {
            updatedQuery[HIERARCHY_QUERY_KEY] = selectedHierarchyId;
        }
        replace(
            {
                pathname,
                query: {
                    ...updatedQuery,
                },
            },
            undefined
        );
    }, [selectedHierarchyId]);

    const { rootNodes } = useSearchHierarchy(searchTerm);

    const {
        fetchNextPage: fetchContentResults,
        informationPages: contentResults,
        isFetchingNextPage: isLoadingContentResults,
        isInitialLoading: isInitialLoadingContentResults,
        totalInformationPages: contentCount,
    } = useSearchContent(searchTerm, 1, pageSize);

    return (
        <Container>
            <Headline
                children={formatString(
                    translate('search.search-results-page.showing-search-results-for'),
                    searchTerm
                )}
                size={2}
            />
            <Filter
                filterDataExchanger={useFilterQuery}
                filterScopeId={searchTerm}
                {...({
                    hierarchyNode: selectedHierarchyId,
                } as AdditionalFilterQueryOptions)}
            >
                <StyledTabMenu
                    tabs={[
                        {
                            content: (
                                <SearchProductsTab
                                    onProductCountChange={setProductCount}
                                    hierarchyNodes={rootNodes}
                                    selectedHierarchyId={selectedHierarchyId}
                                    setSelectedHierarchyId={setSelectedHierarchyId}
                                    searchTerm={searchTerm}
                                />
                            ),
                            tabHash: 'products',
                            tabName: formatString(
                                translate('search.search-results-page.products-result-tab-header'),
                                productCount
                            ),
                        },
                        {
                            content: (
                                <SearchContentTab
                                    contentResults={contentResults}
                                    fetchNextPage={fetchContentResults}
                                    isFetchingPage={isLoadingContentResults}
                                    pageSize={pageSize}
                                    totalContentResults={contentCount}
                                />
                            ),
                            isBusy: isInitialLoadingContentResults,
                            tabHash: 'content',
                            tabName: formatString(
                                translate('search.search-results-page.page-results-tab-header'),
                                contentCount === maxResults ? `${maxResults}+` : contentCount
                            ),
                        },
                    ]}
                />
            </Filter>
        </Container>
    );
};
