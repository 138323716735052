import React, { FC } from 'react';
import { StyledCoreProductList } from './style';

export type ColumnListProps = {
    listItems?: ColumnListItemProps[];
};

export type ColumnListItemProps = {
    dataLabel?: string | React.ReactNode;
    dataItem?: string | boolean | React.ReactNode;
};

export const ColumnList: FC<ColumnListProps> = ({ listItems }) => {
    return (
        <StyledCoreProductList>
            {listItems?.map((item, i) => (
                <React.Fragment key={i}>
                    <dt>{item?.dataLabel}</dt>
                    <dd> {item?.dataItem}</dd>
                </React.Fragment>
            ))}
        </StyledCoreProductList>
    );
};
