import React from 'react';
import { StepContentContainer, StyledStepButton } from './styled';

type Props = {
    title: string;
    disabled: boolean;
    isSelected: boolean;
    handleClick(): void;
};

export const Step = ({ title, disabled, isSelected, handleClick }: Props) => {
    return (
        <StepContentContainer>
            <StyledStepButton
                onClick={handleClick}
                isSelected={isSelected}
                disabled={disabled}
                variant="plain"
                noIcon
            >
                {title}
            </StyledStepButton>
        </StepContentContainer>
    );
};
