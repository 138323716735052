import { ServiceType } from '$shared/components/extra-services/service-types';
import { ProductLineItem } from '$shared/components/product-line/product-line.model';
import { ResultsWithStock } from '../components/filter-product-list';

export type ProductAdapter<TProduct> = (
    product: TProduct,
    isFetchingPriceAndStockCount: boolean
) => ProductLineItem;
export const productMapper: ProductAdapter<ResultsWithStock> = (
    {
        discount,
        extraServices,
        furtherStock,
        imageUrl,
        inSizesOf,
        itemNumber: primaryProductNumber,
        alternativeItemNumber: secondaryProductNumber,
        minimumOrderSize,
        name: productName,
        description: productNameSecondary,
        prices,
        sanitizedItemNumber,
        showFurhterStock,
        showStock,
        stock,
        unit,
        canonicalProductUrl,
        promotedProduct,
        boxCount,
    },
    isFetchingPriceAndStockCount
): ProductLineItem => ({
    extraServices: extraServices?.map((es) => ({
        editable: (es.serviceType as ServiceType) === 'CheckboxAndString',
        label: es.itemDescription1 as string,
        sanitizedItemNumber: es.sanitizedItemNumber as string,
    })),
    discount,
    furtherStock,
    imageUrl,
    canonicalProductUrl,
    isFetchingPriceAndStockCount,
    inSizesOf,
    minimumOrderSize,
    prices,
    primaryProductNumber,
    secondaryProductNumber,
    productName,
    productNameSecondary,
    productPrice: prices?.[0]?.prettyNetPrice,
    sanitizedItemNumber,
    showFurhterStock,
    showStock,
    stock,
    units: unit || '',
    promotedProduct,
    boxCount,
});
