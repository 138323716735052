export const black = '#231F20';
export const white = '#FFFFFF';

export const green90 = '#5AA196';
export const green60 = '#349564';
export const green50 = '#6BAEA4';
export const green20 = '#7AD09C';
export const green10 = '#DFFBE2';
export const greenBase50 = '#50B180';
export const green = green50;

export const brown90 = '#837016';
export const brown50 = '#99831D';
export const brown = brown50;

export const purple90 = '#7E75A8';
export const purple50 = '#978EBF';
export const purple = purple50;

export const red90 = '#BF2822';
export const red50 = '#DA3831';
export const red05 = '#fff3f3';
export const red = red50;

export const blue100 = '#144a71';
export const blue90 = '#1C71AC';
export const blue80 = '#2383c6';
export const blue70 = '#619fd5';
export const blue50 = '#8cb5e0';
export const blue30 = '#b7ceeb';
export const blue = blue80;

export const grey90 = '#525151';
export const grey80 = '#717070';
export const grey70 = '#928F90';
export const grey60 = '#ACA9A9';
export const grey50 = '#C7C5C4';
export const grey40 = '#E7E5E5';
export const grey30 = '#F1F0EF';
export const grey20 = '#F8F7F7';
export const grey10 = '#FAFAFA';
export const grey = grey50;
export const backupImageBackgroundColor = grey20;

export const primary = blue;
export const secondary = grey;

export const positive = green;
export const neutral = brown;
export const negative = red;
export const borderColor = grey40;

export const yellow = '#FFFF00';
export const defaultText = black;

//Semantic colors
export const yellowBase50 = '#FFEC33';
export const yellow40 = '#FFD573';
export const yellow10 = '#FFF7D9';
export const orangeBase50 = '#FF7226';
export const orange40 = '#FF9F5C';
export const orange10 = '#FFEDD3';
export const redBase50 = '#DE2C24';
export const red40 = '#FF948F';
export const red10 = '#FFDAD9';
