import React from 'react';
import { IPromotionBlock } from '~/lib/data-contract';
import Image from '$shared/components/image';
import Link from '$shared/components/link';
import {
    StyledIcon,
    StyledTextOnImage,
    StyledTextOnImageContentWrapper,
    StyledTextOnImageContent,
    StyledTextOnImageHeadline,
    StyledTextOnImageText,
    StyledTextOnImageWrapper,
} from './style';
import AspectRatio from '$shared/components/aspect-ratio';
import { breakpointSizes, getTextColor, usePotentialThemeColor } from '$theme';

export type TextOnImageProps = Partial<IPromotionBlock> & {
    imageSizesAttr?: string;
};

// TODO: src={media?.desktop?.src used to be "portrait" is now desktop - not sure if it fits

export const TextOnImage = ({
    header,
    description,
    link,
    media,
    imageSizesAttr,
    textColor,
    backgroundColor = 'none',
}: TextOnImageProps) => {
    const backgroundColorValue = usePotentialThemeColor(backgroundColor);
    const textColorValue = textColor
        ? usePotentialThemeColor(textColor)
        : getTextColor(backgroundColorValue);

    return (
        <StyledTextOnImage>
            <Link href={link?.url} title={link?.title}>
                <AspectRatio ratio={8 / 10}>
                    <StyledTextOnImageWrapper backgroundColor={backgroundColorValue}>
                        {media?.desktop?.src && (
                            <Image
                                width={8}
                                height={10}
                                src={media?.desktop?.src}
                                sizes={
                                    imageSizesAttr ||
                                    `(max-width: ${breakpointSizes.sm}px) 100vw, 300px`
                                }
                            />
                        )}
                    </StyledTextOnImageWrapper>
                    <StyledTextOnImageContentWrapper textColor={textColorValue}>
                        <StyledTextOnImageContent>
                            <StyledTextOnImageHeadline
                                size={3}
                                children={header}
                                textColor={textColorValue}
                            />
                            <StyledTextOnImageText
                                children={description}
                                textColor={textColorValue}
                            />
                        </StyledTextOnImageContent>
                        <StyledIcon icon={'arrowRight'} />
                    </StyledTextOnImageContentWrapper>
                </AspectRatio>
            </Link>
        </StyledTextOnImage>
    );
};

export default TextOnImage;
