import styled from '@emotion/styled';
import container from '$shared/components/container';
import { breakpoints } from '$theme';
import { SearchBar } from '$features/search/search-bar/search-bar';

export const StyledHeader = styled.header(() => ({
    position: 'relative',
}));

export const StyledHeaderRow = styled(container)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',

    [breakpoints.md]: {
        flexWrap: 'nowrap',
    },
}));

export const StyledSearch = styled(SearchBar)(() => ({
    order: 5,

    [breakpoints.md]: {
        order: 'unset',
    },
}));
