import React, { memo, ReactNode } from 'react';
import { StyledBackdrop, StyledContent, StyledDrawer, StyledDrawerContainer } from './styled';

type DrawerProps = {
    open: boolean;
    children: ReactNode;
    onClose?: () => void;
};

export const Drawer = memo(({ children, open, onClose }: DrawerProps) => {
    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Escape') {
            onClose?.();
        }
    };

    return (
        <StyledDrawerContainer open={open} onKeyDown={onKeyDownHandler}>
            <StyledDrawer open={open} offset={0}>
                <StyledContent children={children} />
            </StyledDrawer>
            <StyledBackdrop open={open} onClick={onClose} />
        </StyledDrawerContainer>
    );
});
