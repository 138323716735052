import { theme } from '$theme';
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC, ReactNode } from 'react';
import { Skeleton } from '../skeleton';
import { StyledBasketSummaryHeader, StyledBasketSummaryList } from './basket-summary-styles';

type BasketSummarySkeletonProps = {
    showHeader?: boolean;
    terms?: ReactNode;
};

const SummarySkeletonFlexObj: CSSObject = {
    display: 'flex',
    alignItems: 'center',
};

const StyledSummarySkeletonLine = styled.div({
    ...SummarySkeletonFlexObj,
    justifyContent: 'space-between',
});

const StyledSummarySkeletonTotalLine = styled.div({
    ...SummarySkeletonFlexObj,
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.colors.grey50}`,
    marginTop: theme.space[1],
    paddingTop: theme.space[3],
});

const StyledSummarySkeletonTerms = styled.div({
    ...SummarySkeletonFlexObj,
    marginBottom: theme.space[5],
});

export const BasketSummarySkeleton: FC<BasketSummarySkeletonProps> = (props) => {
    return (
        <>
            {props.showHeader && (
                <StyledBasketSummaryHeader>
                    <Skeleton pulsating height={'1.5em'} width={'45%'} />
                </StyledBasketSummaryHeader>
            )}

            <StyledBasketSummaryList
                children={
                    <>
                        <StyledSummarySkeletonLine>
                            <Skeleton pulsating height={'1.5em'} width={'30%'} />
                            <Skeleton pulsating height={'1.5em'} width={'45%'} />
                        </StyledSummarySkeletonLine>

                        <StyledSummarySkeletonTotalLine>
                            <Skeleton pulsating height={'1.5em'} width={'30%'} />
                            <Skeleton pulsating height={'1.5em'} width={'45%'} />
                        </StyledSummarySkeletonTotalLine>
                    </>
                }
            />

            {props.terms && (
                <StyledSummarySkeletonTerms>
                    <Skeleton
                        pulsating
                        height={'25px'}
                        width={'25px'}
                        css={{ marginRight: '15px' }}
                    />
                    <Skeleton pulsating height={'1.2em'} width={'60%'} />
                </StyledSummarySkeletonTerms>
            )}
        </>
    );
};
