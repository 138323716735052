import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { MediaTextProps } from './components/media-text';

type StyledGridProps = {
    columnCount: number;
} & Pick<MediaTextProps, 'layoutVariant'>;

export const StyledHeader = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: theme.space['3'],
}));

export const StyledGrid = styled.div<StyledGridProps>(
    ({ theme, columnCount }) => ({
        display: 'grid',
        gap: theme.space['5'],
        [breakpoints.sm]: {
            gridTemplateColumns: `repeat(${Math.min(columnCount, 2)}, 1fr)`,
        },
        [breakpoints.md]: {
            gridTemplateColumns: `repeat(${Math.min(columnCount, 3)}, 1fr)`,
        },
        [breakpoints.lg]: {
            gridTemplateColumns: `repeat(${Math.min(columnCount, 4)}, 1fr)`,
        },
    }),

    ifProp(
        { layoutVariant: 'narrow' },
        {
            [breakpoints.xs]: {
                gap: 0,
            },
        }
    )
);
