import React from 'react';
import { StyledAuthorTagQuote, StyledQuote } from './style';
import Headline from '$shared/components/headline';

export type QuoteProps = {
    /**
     * Hero quote
     */
    quote?: string;

    /**
     * The author below the headline
     */
    author?: string;

    /**
     * Horizontally align content
     */
    contentAlignment?: 'left' | 'center' | 'right';
};

export const Quote = ({ quote, author, contentAlignment = 'center' }: QuoteProps) => {
    if (!quote) {
        return null;
    }

    return (
        <StyledQuote contentAlignment={contentAlignment}>
            <Headline size={2} as="h3">"{quote}"</Headline>
            <StyledAuthorTagQuote>{author}</StyledAuthorTagQuote>
        </StyledQuote>
    );
};

export default Quote;
