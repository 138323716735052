import React from 'react';
import { IPages } from '~/lib/data-contract';

type PageProviderProps = {
    children: React.ReactNode;
} & PageProviderContext;

type PageProviderContext = {
    page: IPages;
};

export const PageContext = React.createContext<PageProviderContext>({
    page: {} as IPages,
});

export const PageProvider = ({ children, ...value }: PageProviderProps) => {
    return <PageContext.Provider value={value} children={children} />;
};
