export enum marketKeys {
    //Session Storage Variables and values
    //(can only take string as value, so simulating boolean)
    marketCookieKey = 'MarketId',
    marketChosen = 'marketChosen',
    marketIsChosen = 'true',
    marketActOnHehalfCookieKey = 'ActOnBehalfMarketCookie',
    cultureSelectedByUser = 'cultureSelectedByUser',
    marketSelectedByUser = 'marketSelectedByUser',
}
