import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { StyledText } from '../text';

export const StyledTable = styled(StyledText.withComponent('table'))<{
    padding?: string;
    borderSpacing?: string;
}>(
    ({ theme, padding }) => ({
        borderCollapse: 'collapse',
        border: '10px',
        width: '100%',

        'td, th': {
            padding: `${padding ? `${padding}`.replace('px', '') + 'px' : `${theme.space[4]}`} 0`, // Because the padding property comes as either '5' or '5px' when used in rawHTML
            paddingRight: `${
                padding ? `${padding}`.replace('px', '') + 'px' : `${theme.space[4]}`
            }`,
            textAlign: 'left',

            boxSizing: 'border-box',
        },

        th: {
            fontWeight: theme.fontWeights.bold,
        },

        thead: {
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
        },

        tr: {
            borderBottom: `1px solid ${theme.colors.grey50}`,
        },
        p: {
            marginBottom: 0,
        },

        'tbody > tr > th:first-of-type': {
            position: 'sticky',
            left: 0,
        },
    }),
    ifProp(
        'borderSpacing',
        ({ borderSpacing }) =>
            ({
                borderCollapse: `separate !important`, // to overwrite inlinestyles from epiServer, when used in rawHTML
                borderSpacing,
            } as any)
    )
);
