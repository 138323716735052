import { GET_CUSTOMER_URL } from '$features/order-service/order-endpoints';
import { ContactViewModel, CustomerDto } from '$order-models/*';
import { useAuthentication } from '$shared/hooks/useAuthentication';
import { cancellableFetch } from '$shared/utils';
import { useEffect } from 'react';
import { GET_CONTACT_URL } from './endpoints';
import { useUserStore } from './use-user-store';

export function fetchUserInfo() {
    const { isAuthenticated } = useAuthentication();
    const setUser = useUserStore(({ setUser }) => setUser);

    const fetchUser = async () => {
        const userData = await cancellableFetch<ContactViewModel>({
            url: GET_CONTACT_URL,
        });
        setUser(userData);

        let CHECKOUT_customerData: CustomerDto = {};
        try {
            // Now, just to get a company name... BE made me do this. KYOC-1034
            CHECKOUT_customerData = await cancellableFetch<CustomerDto>({
                url: GET_CUSTOMER_URL,
                options: {
                    headers: {
                        ...(userData.customerNumber
                            ? { CustomerNumber: userData.customerNumber }
                            : {}),
                    },
                },
            });
        } catch (err) {
            return console.warn(err, 'GetCustomer failed');
        }
        // setUser is called twice. Because lots of queries are awaiting a known user
        setUser({
            ...userData,
            ...(CHECKOUT_customerData?.name ? { companyName: CHECKOUT_customerData.name } : {}),
        });
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchUser();
        } else if (isAuthenticated !== undefined) {
            setUser({ contactFound: false });
        }
    }, [isAuthenticated]);
}
