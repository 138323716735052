import React, { FC, ReactNode, useState } from 'react';
import {
    DefaultOptions,
    DehydratedState,
    Hydrate,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';

interface RequestProviderProps {
    children: ReactNode;
    dehydratedState?: DehydratedState;
}

const defaultOptions: DefaultOptions = {
    queries: {
        refetchOnWindowFocus: false,
        staleTime: 30000,
    },
};

export const RequestProvider: FC<RequestProviderProps> = (props) => {
    const [queryClient] = useState(new QueryClient({ defaultOptions }));

    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate children={props.children} state={props.dehydratedState} />
        </QueryClientProvider>
    );
};
