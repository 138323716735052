import React from 'react';
import { breakpointSizes, getEpiButtonVariant, theme } from '$theme';
import { RawHtml, useRawHtmlWithNewLinesAsBreaks } from '$shared/components/raw-html';
import { Image } from '$shared/components/image/image';
import { M21GridItemProps } from '../../m21-grid-item';
import Headline from '$shared/components/headline';
import { Button } from '$shared/components/button';
import {
    StyledActions,
    StyledMediaText,
    StyledMediaTextSpotContent,
    StyledMediaTextIcon,
    StyledMediaTextSpotContentTexts,
    StyledMediaTextImageWrapper,
} from './style';

export const M21GridItemDefault = ({
    headline,
    text,
    callToActions,
    media,
    columnCount,
    horizontalAlignment,
    verticalAlignment,
}: M21GridItemProps) => {
    const { desktop, caption } = media || {};
    const image = desktop;
    const mediaItem = image;
    const imageSizes = `(max-width: ${breakpointSizes.sm}px) 100vw, ${
        columnCount ? theme.grid.maxWidth / columnCount + 'px' : '100vw'
    }`;

    return (
        <StyledMediaText>
            <StyledMediaTextImageWrapper>
                <Image
                    src={mediaItem?.src || ''}
                    alt={(mediaItem as any)?.alt} // Yet another property missing on the models from BE...
                    title={caption}
                    width={16}
                    height={9}
                    sizes={imageSizes}
                    objectFit="contain"
                />
            </StyledMediaTextImageWrapper>
            <StyledMediaTextSpotContent
                alignment={horizontalAlignment}
                position={verticalAlignment}
            >
                <StyledMediaTextSpotContentTexts>
                    {headline?.text && (
                        <Headline
                            children={useRawHtmlWithNewLinesAsBreaks(headline.text)}
                            as={headline.tag}
                            size={5}
                            hideOnMobile={headline.hideOnMobile}
                        />
                    )}
                    {text?.html && <RawHtml html={text?.html} />}
                </StyledMediaTextSpotContentTexts>

                <StyledMediaTextIcon icon="arrowRight" color="blue" />
            </StyledMediaTextSpotContent>

            {callToActions?.length ? (
                <StyledActions>
                    {callToActions
                        ?.filter(Boolean) // filtering null's out of the array - yep.
                        .map(({ text, url, id, style }) => (
                            <Button
                                key={`${url}-${id}`}
                                variant={getEpiButtonVariant(style)}
                                children={text}
                                noIcon
                            />
                        ))}
                </StyledActions>
            ) : null}
        </StyledMediaText>
    );
};
export default M21GridItemDefault;
