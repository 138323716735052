import Link from '$shared/components/link';
import parse, {
    attributesToProps,
    domToReact,
    Element,
    HTMLReactParserOptions,
} from 'html-react-parser';
import Image from '$shared/components/image';
import React, { useMemo } from 'react';
import { HeaderTag } from '~/lib/data-contract';
import { StyledCenterer, StyledEpiColumn, StyledEpiColumnParent } from '../styled';
import Text from '$shared/components/text';
import Headline from '$shared/components/headline';
import { ScrollFade } from '$shared/components/scroll-fade';
import Table from '$shared/components/table';
import { useAuthentication } from '$shared/hooks/useAuthentication';
import { Button } from '$shared/components/button';

type RegularTextTags = typeof regularTextTags[number];
const regularTextTags = [
    'p',
    'b',
    'strong',
    'i',
    'em',
    'u',
    'blockquote',
    'code',
    'pre',
    'li',
] as const;

const getParsingOptions = (extraParams?: { signUpAction?: () => void }): HTMLReactParserOptions => {
    const options: HTMLReactParserOptions = {
        /**
         * Required to prevent warning about whitespace in tables - if tables are used.
         * Might removed intended whitespace.
         *
         * @see https://github.com/remarkablemark/html-react-parser#trim
         */
        trim: false,

        /**
         * Replace HTML tags with react components
         * @see https://github.com/remarkablemark/html-react-parser#replace-element-attributes
         */
        replace: (domNode) => {
            const { attribs, children, name } = domNode as Element;
            const props = attributesToProps(attribs || {});

            if (!attribs) {
                return;
            }

            if (regularTextTags.includes(name as RegularTextTags)) {
                let tag = name as RegularTextTags | 'div';

                const tagAllowedAsChildOfAPTag = (tagName: string) => {
                    // Crazy fix, to ensure that we do not render a <p> tag IF it has block elements as children.
                    // And since the epiServer RTE wraps almost everything in p-tags, this has ment a lot of mark-up errors.
                    // now P tags are only rendered if they have text or inlien in them.
                    switch (tagName) {
                        case 'text':
                        case 'b':
                        case 'br':
                        case 'span':
                        case 'code':
                        case 'em':
                        case 'embed':
                        case 'i':
                        case 'iframe':
                        case 'small':
                        case 'strong':
                        case 'sub':
                        case 'sup':
                        case 'svg':
                        case 'u':
                            return true;

                        default:
                            return false;
                    }
                };

                if (
                    tag === 'p' && // So if we are about to render a P tag, lets check if it has block-element children...
                    (children as any[]).find((c) => !tagAllowedAsChildOfAPTag(c.name || c.type))
                ) {
                    // ...if so, we'll make it into a <div> instead.
                    tag = 'div';
                }

                return <Text {...props} as={tag} children={domToReact(children, options)} />;
            }

            if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(name)) {
                return (
                    <Headline
                        size={name as HeaderTag}
                        {...props}
                        children={domToReact(children, options)}
                    />
                );
            }

            if (name === 'a') {
                if (
                    props['data-epi-link-style'] == 'BUTTON' ||
                    props['data-epi-link-style'] == 'LINK_WITH_ARROW'
                ) {
                    if (
                        extraParams &&
                        extraParams.signUpAction &&
                        props?.href?.includes('https://kyocerab2c.b2clogin.com')
                    ) {
                        return (
                            <Button
                                {...props}
                                onClick={() => extraParams.signUpAction?.()}
                                children={domToReact(children, options)}
                                variant={
                                    props['data-epi-link-style'] == 'LINK_WITH_ARROW'
                                        ? 'plain'
                                        : 'primary'
                                }
                            />
                        );
                    }
                    return (
                        <Link
                            {...props}
                            children={domToReact(children, options)}
                            variant={
                                props['data-epi-link-style'] == 'LINK_WITH_ARROW'
                                    ? 'plain'
                                    : 'primary'
                            }
                        />
                    );
                }

                return (
                    <Link href={props.href} {...props} children={domToReact(children, options)} />
                );
            }

            if (name === 'button') {
                return <Link {...props} children={domToReact(children, options)} />;
            }

            if (name === 'img') {
                const nextImg = <Image src={props?.src || ''} layout="intrinsic" {...props} />;
                const shouldBeCentered = // Workaround to ensure centering in RTE works on page: KYO-1033
                    props?.style?.marginLeft === 'auto' && props?.style?.marginRight === 'auto';
                return shouldBeCentered ? <StyledCenterer>{nextImg}</StyledCenterer> : nextImg;
            }

            if (name === 'table') {
                return (
                    <ScrollFade>
                        <Table
                            {...props}
                            padding={props['cellPadding']}
                            borderSpacing={props['cellSpacing']}
                            children={domToReact(children, options)}
                        />
                    </ScrollFade>
                );
            }

            if (props['data-epi-column']) {
                return <StyledEpiColumn children={domToReact(children, options)} />;
            }
            if (props['data-epi-column-parent']) {
                return (
                    <StyledEpiColumnParent
                        column={props['data-epi-column-parent']}
                        children={domToReact(children, options)}
                    />
                );
            }
        },
    };

    return options;
};

export const useRawHtml = (html: string) => {
    const { signUp } = useAuthentication();
    const options = getParsingOptions({ signUpAction: signUp });

    return useMemo(() => {
        return parse(html, options);
    }, [html]);
};

export const useRawHtmlWithNewLinesAsBreaks = (html: string) => {
    const { signUp } = useAuthentication();
    const options = getParsingOptions({ signUpAction: signUp });

    return useMemo(() => {
        return parse(html.replace(/\n/g, '<br>'), options);
    }, [html]);
};
