import { useTranslation } from '$shared/utils';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { StyledFieldset, StyledInput } from './contact-information-form-styles';

const unregisterOptions = {
    keepValue: true,
};

export const ContactInformationForm: FC = () => {
    const { translate } = useTranslation();
    const { unregister } = useFormContext();

    useEffect(() => {
        return () => {
            unregister('contactInfo.emailAddress', unregisterOptions);
            unregister('contactInfo.fullName', unregisterOptions);
            // unregister('contactInfo.persistContactInfo', unregisterOptions); // comment further down ----v
            unregister('contactInfo.phoneNumber', unregisterOptions);
        };
    }, []);

    return (
        <StyledFieldset>
            <StyledInput
                label={translate('checkout-page.contact-form.name')}
                name="contactInfo.fullName"
                rules={{
                    maxLength: {
                        value: 40,
                        message: translate(
                            'checkout-page.contact-form.validation-message-name-length'
                        ),
                    },
                    required: translate('checkout-page.contact-form.validation-message-name'),
                }}
            />

            <StyledInput
                label={translate('checkout-page.contact-form.e-mail')}
                name="contactInfo.emailAddress"
                rules={{
                    maxLength: {
                        value: 50,
                        message: translate(
                            'checkout-page.contact-form.validation-message-e-mail-length'
                        ),
                    },
                    pattern: {
                        value: /^(\S+)@(\S+)\.(\S+)$/,
                        message: translate('checkout-page.contact-form.validation-message-e-mail'),
                    },
                    required: translate('checkout-page.contact-form.validation-message-e-mail'),
                }}
            />

            <StyledInput
                label={translate('checkout-page.contact-form.phone-number')}
                name="contactInfo.phoneNumber"
                rules={{
                    maxLength: {
                        value: 20,
                        message: translate(
                            'checkout-page.contact-form.validation-message-phone-number-length'
                        ),
                    },
                }}
            />

            {/*
            *
            * For now this is not needed, because BE(and further on Aspect4) does not support saving contacts.
            ***** 
            <StyledCheckbox
                label={translate('checkout-page.contact-form.save-contact')}
                name="contactInfo.persistContactInfo"
            /> */}
        </StyledFieldset>
    );
};
