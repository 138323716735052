import styled from '@emotion/styled';
import Link from '$shared/components/link';
import Headline from '$shared/components/headline';
import { ifProp } from 'styled-tools';

export const StyledMegaSubSubMenu = styled.div(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    flex: '1 1 auto',
}));
export const StyledHeadline = styled(Headline)();
export const StyledSubSubMenuList = styled.div(
    ({ theme }) => ({
        padding: `${theme.space[7]}`,
    }),
    ifProp('shownWithoutParent', {
        paddingLeft: 0,
    })
);
export const StyledSubNavLink = styled(Link)(
    ({ theme }) => ({
        paddingBottom: 0,

        ':hover': {
            color: `${theme.colors.blue}`,
            textDecoration: 'none',
        },
    }),
    ifProp('href', ({ theme }) => ({
        ':hover': {
            [`${StyledHeadline}`]: {
                color: `${theme.colors.blue}`,
            },
        },
    }))
);
