import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import { usePage } from './../../../templates/pages/hooks/usePage';
import { useFrame } from '$shared/utils';
import { getCultureInfoLink } from '$shared/utils/market/helpers/culture-info.helper';
import { CHECKOUT_STEP } from '~/features/checkout/models';
import { useCheckoutStepsState } from './useCheckoutStepsState';

export const useCheckoutSteps = () => {
    const checkoutStepsState = useCheckoutStepsState();
    const { replace } = useKyoceraRouter();
    const { checkoutPage } = useFrame();
    const { market, culture } = usePage();

    const goToStep = (step: CHECKOUT_STEP) => {
        checkoutStepsState.setActiveStep(step);
        const href = `${checkoutPage?.url}#${step}`;
        const culturedLink = getCultureInfoLink(href, culture, market);
        replace(culturedLink, culturedLink, { shallow: true });
        window.scrollTo(0, 0);
    };

    const goToNextStep = () => {
        const nextStep = checkoutStepsState.nextStep();
        goToStep(nextStep);
    };

    const goToPreviousStep = () => {
        const previousStep = checkoutStepsState.previousStep();
        goToStep(previousStep);
    };

    return {
        ...checkoutStepsState,
        goToStep,
        goToNextStep,
        goToPreviousStep,
    };
};
