import { StyledAccordionTrigger } from '$shared/components/accordion/style';
import { theme } from '$theme';
import styled from '@emotion/styled';

export const StyledCheckoutAccordion = styled.div({
    marginTop: theme.space['4.5'],
    borderBottom: `1px solid ${theme.colors.grey40}`,
    backgroundColor: theme.colors.white,

    [`${StyledAccordionTrigger}`]: {
        paddingInline: theme.space[3],
        transition: 'background-color 0.1s ease-in-out',

        '&:hover': {
            backgroundColor: theme.colors.grey10,
        },
    },
});

export const StyledCheckoutOrderHeader = styled.div({
    display: 'inline-flex',
    gap: theme.space[3],
    //backgroundColor: theme.colors.white,
    color: theme.colors.grey90,
});

export const StyledCheckoutStockIndicator = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: theme.space[2],
});

export const StyledCheckoutOrderTitle = styled.p({
    fontWeight: theme.fontWeights.semiBold,
});

export const StyledCheckoutList = styled.ul({
    '> li': {
        color: theme.colors.grey90,

        '&:nth-of-type(2n+2)': {
            backgroundColor: theme.colors.grey20,
        },
    },
});
