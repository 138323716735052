import React from 'react';
import styled from '@emotion/styled';
import { Icon } from '$shared/components/icon';
import { Link } from '$shared/components/link';
import { breakpoints } from '$theme';
import { usePage } from '$templates/pages';

const StyledLink = styled(Link)(({ theme }) => ({
    width: '100%',
    maxWidth: '120px',
    margin: `${theme.space[3]} auto`,
    outline: 'none',

    [breakpoints.frame]: {
        maxWidth: '170px',
        paddingRight: '0',
        margin: `${theme.space[6]} 0`,
    },
}));

const StyledIcon = styled(Icon)(() => ({
    width: '100%',
    height: 'auto',
    maxHeight: 37,
}));

export const HeaderLogo = () => {
    const { culture } = usePage();

    const frontPageUrl = culture ? `/${culture}` : '/';

    return (
        <StyledLink href={frontPageUrl}>
            <StyledIcon icon="logo" />
        </StyledLink>
    );
};
export default HeaderLogo;
