import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';

export const PreviousPurchasedListItemLine = styled.li({
    display: 'grid',

    [breakpoints.md]: {
        gridTemplateColumns: '1fr 10fr',
    },

    [breakpoints.lg]: {
        gridTemplateColumns: '1fr 7fr',
    },
});

export const StyledlastPurchasedText = styled.p({
    padding: `${theme.space.gutter}`,

    [breakpoints.mmd]: {
        gridRow: 2,
        textAlign: 'right',
        paddingTop: 0,
    },

    [breakpoints.md]: {
        margin: 'auto',
    },
});
export const StyledlastPurchasedValue = styled.span({
    display: 'none',
    [breakpoints.md]: {
        display: 'block',
    },
});

export const StyledlastPurchasedTranslation = styled.span({
    [breakpoints.md]: {
        display: 'none',
    },
});

export const StyledlastPurchasedHeader = styled.span({
    [breakpoints.mmd]: {
        display: 'none',
    },
});
