import React, { FC } from 'react';
import { useController } from 'react-hook-form';
import { ReactHookForm__InputProps } from '$shared/components/form/models/form-models';
import DateSelectorElement, {
    DateSelectorElementProps,
} from '../../controllable-elements/date-selector-element';

export type DateSelectorProps = Omit<DateSelectorElementProps, 'onChange'> &
    ReactHookForm__InputProps;

export const DateSelector: FC<DateSelectorProps> = ({
    name = '',
    rules,
    placeholderText,
    ...rest
}) => {
    const {
        field: { onChange, value },
        fieldState: { invalid, error },
    } = useController({
        name,
        rules,
    });

    const { message: errorMessage } = error || {};

    return (
        <DateSelectorElement
            {...rest}
            selected={value}
            placeholderText={
                placeholderText ? `${placeholderText} ${rules?.required ? ' *' : ''}` : undefined
            }
            onChange={onChange}
            isActive={value}
            isInvalid={invalid}
            invalidMessage={errorMessage}
        />
    );
};

export default DateSelector;
