import { theme } from '$theme';
import styled from '@emotion/styled';
import { breakpoints } from '$theme';
import { SpotContentProps } from '$shared/components/spot-content';
import { StyledHeadline } from '$shared/components/headline';
import { StyledRichText } from '$shared/components/raw-html/styled';

export const StyledShopWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.grey20,
    width: '100%',
    height: '100%',
    paddingLeft: 10,
    paddingRight: 15,
    borderLeft: `7px solid ${theme.colors.primary}`,

    [breakpoints.sm]: {
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        padding: theme.space[4],
        borderLeft: 'none',
        borderBottom: `5px solid ${theme.colors.primary}`,
    },
});
export const StyledMediaTextSpotContentTexts = styled.div<SpotContentProps>(() => ({
    fontSize: theme.fontSizes['xs'],

    [`${StyledRichText}`]: {
        color: theme.colors.grey80,

        [breakpoints.sm]: {
            marginBottom: theme.space[4],
        },
    },

    [`${StyledHeadline}`]: {
        marginBottom: 20,
    },
    [breakpoints.xs]: {
        [`${StyledHeadline}`]: {
            marginBottom: 0,
        },

        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        [`${StyledRichText}`]: {
            display: 'none',
        },
    },
}));
