import { ReactNode, VFC } from 'react';
import CheckboxElement from '../form/components/controllable-elements/checkbox-element';

interface BasketSummaryTermsProps {
    className?: string;
    label: ReactNode;
    onChange: (value: boolean) => void;
    value: boolean;
}

export const BasketSummaryTerms: VFC<BasketSummaryTermsProps> = (props) => {
    return (
        <CheckboxElement
            label={props.label}
            onChange={(e) => props.onChange(e.currentTarget.checked)}
            checked={props.value}
        />
    );
};
