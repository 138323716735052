import { Popover } from '$shared/components/popover/popover';
import { Skeleton } from '$shared/components/skeleton';
import { FC } from 'react';
import { useStockStatus } from '../hooks/use-stock-status';
import { StockIndicator } from '../stock-indicator/stock-indicator';
import { StockMessage } from '../stock-message/stock-message';
import { PriceAndStockProps } from '../stock-props';
import { StockStatusPopUp } from '../stock-status-pop-up/stock-status-pop-up';
import { StyledStockOnHoverButton, StyledStockOnHoverWrapper } from './stock-styles';

type StockProps = PriceAndStockProps & {
    className?: string;
};

export const Stock: FC<StockProps> = (props) => {
    const indicatorStatus = useStockStatus(props.stock?.deliveryEstimate);

    return (
        <>
            {props.isFetchingPriceAndStockCount ? (
                <Skeleton
                    pulsating
                    height={'1.5em'}
                    width={'100%'}
                    css={{ maxWidth: '100px', minWidth: '66px' }}
                />
            ) : (
                <Popover content={<StockStatusPopUp {...props} />}>
                    <StyledStockOnHoverWrapper>
                        <StyledStockOnHoverButton className={props.className}>
                            <StockIndicator status={indicatorStatus} />
                            <StockMessage
                                showStock={!!props.showStock}
                                status={indicatorStatus}
                                stockAvailable={props.stock?.stockAvailable}
                                units={props.units}
                            />
                        </StyledStockOnHoverButton>
                    </StyledStockOnHoverWrapper>
                </Popover>
            )}
        </>
    );
};
