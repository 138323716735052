/*
 * Format string helper
 * repalces {0} in strings, mainly used in translations.
 *
 * Example:
 * formatString('Hi {0} you look {1} today...', 'Frederik', 'good')
 *
 * or another example from the project:
 * {formatString(translate('navigation.back-button'), getBackButtonName(mainMenu, menuLocation))}
 */

export function formatString(
    preformattedString?: string,
    ...args: Array<string | number | undefined>
): string {
    if (typeof preformattedString !== 'string') {
        return '';
    }

    return preformattedString.replace(/{(\d+)}/g, (match, i: number) => {
        return `${typeof args[i] !== 'undefined' ? args[i] : match}`;
    });
}

export function capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function stripHtml(htmlString: string | undefined): string {
    if (typeof htmlString !== 'string') {
        return '';
    }

    const s = htmlString.replace(/<[^>]*>?/gm, '').replace(/\s+/g, ' ');

    return removeExtraWhitespace(s).trim();
}

export function removeExtraWhitespace(s: string): string {
    return s.replace(/\s+/g, ' ');
}

export function stringToNumber(s: string): number {
    const parsed = parseInt(s);
    return isNaN(parsed) ? 0 : parsed;
}
