import { useMotionValue } from 'framer-motion';
import { forwardRef, ReactNode, UIEvent, useEffect, useRef, useState } from 'react';
import { useMedia } from 'react-use';
import {
    StyledScrollArea,
    StyledScrollbar,
    StyledThumb,
    StyledViewport,
} from '$shared/components/scroll-area/styled';
import { weakKey } from '~/shared/utils/jsx';
import { useCarouselLightRef } from './hooks/useCarouselLightRef';
import { StyledGrabWrapper, StyledItemsWrapper, StyledCarouselItem } from './styled';

export type CarouselLightProps = {
    items: ReactNode[];
    onResize?: (scrollDistance: number) => void;
    onScroll?: (event?: UIEvent<HTMLDivElement>) => void;
    onItemSelect?(index: number): void;
};

export type CarouselRef = {
    scrollDistance: number;
    scrollLeft: number;
    scrollBy: (options?: ScrollToOptions) => void;
};

/**
 * A lighter alternative to third party carousels, uses framer motion drag and radix scrollArea
 *
 * Supports:
 *  - Custom scroll bar
 *  - Drag
 *  - Tabulating
 *  - Momentum
 * Potiential features to be implemented:
 *  - Bounds resistance
 *  - Arrow support
 *  - Pagination
 */
export const CarouselLight = forwardRef<CarouselRef, CarouselLightProps>(
    ({ items = [], onResize, onScroll, onItemSelect }, ref) => {
        const onlyTouch = useMedia('(any-hover:none)', false);

        // The element that will scroll on drag
        const scrollElementRef = useRef<HTMLDivElement>(null);

        // Keep track of scroll mode without triggering a render
        const scrollModeRef = useRef<'auto' | 'drag' | 'initial'>('initial');

        const [scrollDistance, setScrollDistance] = useState(0);

        const [chosenItem, setChosenItem] = useState(0);

        const dragValue = useMotionValue(0);

        const showScrollbar = scrollDistance > 0;
        const enableDrag = showScrollbar && !onlyTouch;

        useCarouselLightRef({ ref, scrollDistance, scrollElementRef, scrollModeRef, dragValue });

        useEffect(() => {
            const { current: scrollElement } = scrollElementRef;

            if (!scrollElement) {
                return;
            }

            // Keep track of scroll element to calulate scroll distance
            // and to set the right offset to the right
            const observer = new window.ResizeObserver((entries) => {
                if (entries[0]) {
                    const { current: scrollElement } = scrollElementRef;

                    if (scrollElement) {
                        const { scrollWidth, clientWidth } = scrollElement;
                        const scrollDistance = Math.max(scrollWidth - clientWidth, 0);

                        setScrollDistance(scrollDistance);
                        onResize?.(scrollDistance);
                    }
                }
            });

            // Track changes made via the motion drag.
            const unsubscribe = dragValue.onChange((value) => {
                const { current: scrollElement } = scrollElementRef;
                const { current: mode } = scrollModeRef;

                if (scrollElement && mode === 'drag') {
                    scrollElement.scrollLeft = -value;
                }
            });

            observer.observe(scrollElement);

            return () => {
                observer.disconnect();
                unsubscribe();
            };
        });

        // Reset drag state when start dragging
        // in case drag position has been altered by user
        const onMouseDownHandler = () => {
            const { current: scrollElement } = scrollElementRef;

            if (scrollElement) {
                const scrollLeft = scrollElement.scrollLeft;
                dragValue.set(-scrollLeft);
            }
        };

        // Prevent default on the initial click, after dragging.
        // This is to prevent cases where the user let's go
        // over an anchor tag and the browser nagivates to that page.
        const onClickCaptureHandler = (event: React.MouseEvent<HTMLElement>) => {
            if (scrollModeRef.current === 'drag') {
                event.preventDefault();
            }
        };

        // Make sure dragValue is always updated
        const onScrollHandler = (event: UIEvent<HTMLDivElement>) => {
            const { scrollLeft } = event.currentTarget;

            dragValue.set(-scrollLeft);
            onScroll?.(event);
        };

        return (
            <StyledScrollArea onClickCapture={onClickCaptureHandler}>
                <StyledGrabWrapper
                    drag={enableDrag ? 'x' : false}
                    _dragX={dragValue}
                    dragElastic={0}
                    dragConstraints={{
                        right: 0,
                        left: -scrollDistance,
                    }}
                    onDragStart={() => (scrollModeRef.current = 'drag')}
                    onMouseDown={onMouseDownHandler}
                >
                    <StyledViewport
                        onTouchStart={() => (scrollModeRef.current = 'auto')}
                        onWheel={() => (scrollModeRef.current = 'auto')}
                        ref={scrollElementRef}
                        onScroll={onScrollHandler}
                    >
                        <StyledItemsWrapper>
                            {items.map((item, index) => {
                                const key = weakKey(item);

                                return (
                                    <StyledCarouselItem
                                        key={key}
                                        onMouseEnter={() => {
                                            setChosenItem(index);
                                            return onItemSelect?.(index);
                                        }}
                                        style={{
                                            border: '2px solid transparent',
                                            borderColor:
                                                index === chosenItem ? '#2383C6' : 'transparent',
                                        }}
                                    >
                                        {item}
                                    </StyledCarouselItem>
                                );
                            })}
                        </StyledItemsWrapper>
                    </StyledViewport>
                </StyledGrabWrapper>
                <div
                    style={{
                        position: 'relative',
                        padding: '15px 0',
                        zIndex: 1,
                        visibility: showScrollbar ? 'visible' : 'hidden',
                        display: showScrollbar ? 'block' : 'none',
                    }}
                >
                    <StyledScrollbar onMouseDown={() => (scrollModeRef.current = 'auto')}>
                        <StyledThumb />
                    </StyledScrollbar>
                </div>
            </StyledScrollArea>
        );
    }
);
