import { StyledToolTipIcon } from '$shared/components/hover-card-tooltip/tool-tip/style';
import { useTranslation } from '$shared/utils';
import React, { FC } from 'react';
import { StyledDocumentDownloadAnchor } from './style';

type props = {
    url: string;
};

export const DocumentDownload: FC<props> = ({ url }) => {
    const { translate } = useTranslation();
    return (
        <StyledDocumentDownloadAnchor
            href={url}
            target="_blank"
            title={translate('product-filter.details')}
        >
            <StyledToolTipIcon />
            {/* KYOC-1178 states that download and tooltip should be same "icon" / component */}
        </StyledDocumentDownloadAnchor>
    );
};
