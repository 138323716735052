import { RawHtml } from '$shared/components/raw-html';
import React from 'react';
import { IHeadline } from '~/lib/data-contract';
import { ModuleContainer } from '../module-container';

export interface M100Props {
    /**
     * Headline
     */
    headline?: IHeadline | undefined;

    /**
     * Accordion Items
     */
    text?: { html: string };

    height?: string;

    horizontalAlignment?: string;

    verticalAlignment?: string;

    id?: string;
}

export const M100 = ({ id, text }: M100Props) => {
    if (!text?.html) {
        return null;
    }

    return (
        <ModuleContainer id={id}>
            <RawHtml html={text.html} />
        </ModuleContainer>
    );
};
