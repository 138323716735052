import { motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';
import { StyledFormOverlay } from './style';

export type FormOverlayProps = {
    //
};

export const FormOverlay: FC<PropsWithChildren<FormOverlayProps>> = ({ children }) => {
    return (
        <motion.div animate={{ opacity: 1 }}>
            <StyledFormOverlay>{children}</StyledFormOverlay>
        </motion.div>
    );
};

export default FormOverlay;
