import { Icon } from '$shared/components/icon';
import React, { FC, ReactNode } from 'react';
import { StyledToggleButton, StyledIconWrapper } from '../../style';

export type GenericHeaderProps = {
    text?: string;
    onToggle?: () => void;
    isActive?: boolean;
    icon?: ReactNode;
};
export const GenericHeader: FC<GenericHeaderProps> = ({ onToggle, icon, isActive, text }) => {
    const handleToggle = () => {
        onToggle?.();
    };
    return (
        <StyledToggleButton onClick={handleToggle}>
            {text}
            {!!onToggle && (
                <StyledIconWrapper isActive={isActive}>
                    {icon ? icon : <Icon icon="chevronDown" size={12} />}
                </StyledIconWrapper>
            )}
        </StyledToggleButton>
    );
};
