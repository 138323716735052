import React, { FC, useEffect, useState } from 'react';
import { DatePickerContainer, StyledDatepicker, StyledDatepickerIntoText } from './styled';
import { calcTimeDate, getLocale, mapWeekdayToDateDay } from './date-helpers';
import { registerLocale, ReactDatePickerProps } from 'react-datepicker';
import { useTranslation } from '$shared/utils';
import { Weekday } from '~/lib/data-contract';
import getDay from 'date-fns/getDay';
import { controllableInputElementsProps } from '$shared/components/form';
import { StyledHelpText } from '../../help-text';
import { StyledInvalidMessage } from '../../invalid-message';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledInputFieldWrapper, StyledLabel } from '../input-element/styled';
import { usePage } from '$templates/pages';

export type DateSelectorElementProps = ReactDatePickerProps &
    controllableInputElementsProps & {
        firstSelectable?: string | Date;
        InfoText?: string;
        showTimeSelect?: boolean;
        showTimeSelectOnly?: boolean;
        timeSelectMaxTimeHour?: number;
        timeSelectMaxTimeMinute?: number;
        timeSelectMinTimeHour?: number;
        timeSelectMinTimeMinute?: number;
        timeSelectMinuteInterval?: number;
        weekdays?: Weekday[];
    };

const DateSelectorElement: FC<DateSelectorElementProps> = (props) => {
    const {
        firstSelectable,
        helpText,
        InfoText,
        invalidMessage,
        isInvalid,
        label,
        showTimeSelect,
        showTimeSelectOnly,
        timeSelectMaxTimeHour,
        timeSelectMaxTimeMinute,
        timeSelectMinTimeHour,
        timeSelectMinTimeMinute,
        timeSelectMinuteInterval,
        weekdays,
        isActive,
    } = props;

    const { culture } = usePage();
    const { translate } = useTranslation();
    const [locale, setLocale] = useState<string>();

    useEffect(() => {
        if (culture) {
            registerLocale(culture, getLocale(culture));
            setLocale(culture);
        }
    }, [culture]);

    const isDateAllowed = (date: Date) => {
        const day = getDay(date);
        const allowedDays = weekdays?.map(mapWeekdayToDateDay);
        return allowedDays?.find((allowedDay) => allowedDay === day) !== undefined;
    };

    const firstSelectableDate = firstSelectable ? new Date(firstSelectable) : null;
    const [hasFocus, setHasFocus] = useState(false);
    const isInputActive = isActive || hasFocus;
    const isValid = !invalidMessage && !isInvalid;
    const onFocusHandler = () => setHasFocus(true);
    const onBlueHandler = () => {
        setHasFocus(false);
    };

    return locale ? (
        <DatePickerContainer onFocus={onFocusHandler} onBlur={onBlueHandler}>
            <StyledInputFieldWrapper isValid={isValid}>
                <StyledDatepicker
                    calendarStartDay={1} // Starts weeks on mondays
                    dateFormat={showTimeSelect ? (showTimeSelectOnly ? 'p' : 'Pp') : 'P'} // https://date-fns.org/v2.0.0-beta.2/docs/format
                    disabledKeyboardNavigation
                    filterDate={isDateAllowed}
                    locale={locale}
                    minTime={calcTimeDate(timeSelectMinTimeHour, timeSelectMinTimeMinute)}
                    maxTime={calcTimeDate(timeSelectMaxTimeHour, timeSelectMaxTimeMinute)}
                    minDate={firstSelectableDate}
                    timeCaption={translate('forms.fields.datepicker.time-caption')}
                    timeIntervals={timeSelectMinuteInterval || 15}
                    {...props}
                    placeholderText={isInputActive ? props.placeholderText : ''}
                    withLabel={!!label}
                >
                    {InfoText && <StyledDatepickerIntoText>{InfoText}</StyledDatepickerIntoText>}
                </StyledDatepicker>

                <StyledLabel
                    isValid={isValid}
                    isActive={isInputActive}
                    hasFocus={hasFocus}
                    title={InfoText}
                >
                    {label}
                </StyledLabel>
            </StyledInputFieldWrapper>
            {invalidMessage ? (
                <StyledInvalidMessage children={invalidMessage} />
            ) : (
                helpText && <StyledHelpText children={helpText} />
            )}
        </DatePickerContainer>
    ) : null;
};

export default DateSelectorElement;
