import { useUserStore } from '$features/authentication/use-user-store';
import { retrievePriceAndStock } from '$features/order-service/retrieve-price-and-stock';
import { useAuthentication } from '$shared/hooks/useAuthentication';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { PriceAndStockViewModel, ProductPriceAndStockViewModel } from '~/lib/data-contract';

type ProductPricesAndStockCountsMap = Map<string, PriceAndStockViewModel>;

interface UsePriceAndStockCountState {
    /**
     * `true`, if the hook is currently fetching a set of prices and stock counts.
     */
    isBusy: boolean;
    /**
     * Fetches prices and stock counts for the given `originalItemNumbers`, and appends
     * them to `productPricesAndStocks`.
     */
    loadMore: (originalItemNumbers: string[]) => void;
    /**
     * A map of original item-numbers, and their fetched prices and stock counts.
     */
    productPricesAndStockCounts: ProductPricesAndStockCountsMap;
}

function createMap(): ProductPricesAndStockCountsMap {
    return new Map();
}

function reduceViewModel(
    map: ProductPricesAndStockCountsMap,
    vm: ProductPriceAndStockViewModel[]
): ProductPricesAndStockCountsMap {
    vm.forEach(({ itemNumber, priceStock }) => {
        if (itemNumber && priceStock) map.set(itemNumber, priceStock);
    });

    return map;
}

/**
 * @param queryKey An identifier for caching the queried data.
 * and stock counts.
 * @param originalItemNumbers _Optional._ The item-numbers used to fecth initial prices
 * and stock counts.
 */
export function usePriceAndStockCount(
    queryKey: string[],
    originalItemNumbers?: string[]
): UsePriceAndStockCountState {
    const { userFound } = useAuthentication();
    const { actOnBehalfOfCustomer, actOnBehalfOfCustomerMarket } = useUserStore();

    const { data, fetchNextPage, isFetching: isBusy } = useInfiniteQuery(
        ['usePriceAndStockCount', ...queryKey, actOnBehalfOfCustomer, actOnBehalfOfCustomerMarket],
        ({ pageParam = originalItemNumbers }) => retrievePriceAndStock(pageParam),
        {
            enabled: Boolean(userFound && !!originalItemNumbers?.length),
        }
    );

    const loadMore = useCallback(
        (originalItemNumbers: string[]) => {
            userFound && !!originalItemNumbers && fetchNextPage({ pageParam: originalItemNumbers });
        },
        [fetchNextPage, userFound]
    );

    const productPricesAndStockCounts = useMemo(() => {
        const map = createMap();
        if (data) data.pages.reduce(reduceViewModel, map);
        return map;
    }, [data]);

    return {
        isBusy,
        loadMore,
        productPricesAndStockCounts,
    };
}
