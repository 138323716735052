import { useNotification } from '$features/notifications/hooks';
import { buildURL, cancellableFetch, useFrame, useTranslation } from '$shared/utils';
import { FILTER_URL } from '$shared/utils/filter/service/constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { ProductViewModel, TranslationKey } from '~/lib/data-contract';
import { useAuthentication } from '../useAuthentication';

export const useProductSearhBySanitizedItemNumbers = (
    enabled?: boolean,
    sanitizedItemNumbers?: string[]
) => {
    const { push } = useNotification();
    const { userFound } = useAuthentication();
    const { market } = useFrame();
    const { translate } = useTranslation();
    const queryClient = useQueryClient();

    const buildSerializedUrl = useCallback(
        (sanitizedItemNumbers?: string[]) => {
            return buildURL(`${FILTER_URL}/ProductSearch/SearchByField`, {
                field: 'originalItemNumber',
                values: sanitizedItemNumbers,
                ...(!userFound ? { customerMarket: market?.anonymousCustomerMarketCode } : {}),
            });
        },
        [sanitizedItemNumbers]
    );

    const queryKey = [...(sanitizedItemNumbers || [])];

    const { data: results, isLoading, isFetching, isInitialLoading } = useQuery<ProductViewModel[]>(
        queryKey,
        ({ signal }) =>
            cancellableFetch({ signal, url: `${buildSerializedUrl(sanitizedItemNumbers)}` }),
        {
            // Only request when we have stuff to fetch
            enabled,

            retry: (failureCount, error) => {
                if (error instanceof Response && error?.status === 404) {
                    queryClient.removeQueries(queryKey, { exact: true });
                    push({
                        severity: 'error',
                        text: translate('' as TranslationKey),
                    });
                    return false;
                }
                return failureCount < 3;
            },
            onError: async (err) => {
                console.error(err);
                push({
                    text: translate('' as TranslationKey),
                });
            },
        }
    );

    return { results, isFetching: isLoading && isFetching, isInitialLoading };
};
