import React, { FC, memo, ReactNode } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '$theme';
import { Icon } from '../icon';
import { use100vh } from 'react-div-100vh';

export type OverlayProps = {
    children?: ReactNode;
    content?: ReactNode;
    disabled?: boolean;
    fullScreen?: boolean;
    controlled?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
};

export const Overlay: FC<OverlayProps> = memo(
    ({
        children,
        content,
        disabled = false,
        fullScreen = false,
        controlled,
        open,
        onOpenChange,
    }: OverlayProps) => {
        const maxHeight = use100vh();

        return (
            <RadixDialog.Root {...(controlled ? { open, onOpenChange } : {})}>
                {disabled ? (
                    children
                ) : (
                    <RadixDialog.Trigger asChild>{children}</RadixDialog.Trigger>
                )}
                <RadixDialog.Portal>
                    <DialogOverlay>
                        <DialogContent
                            style={{
                                maxHeight: maxHeight || '100vh',
                                ...(fullScreen ? { width: '100vw', height: '100vh' } : {}),
                            }}
                        >
                            <DialogClose>
                                <Icon icon="close" />
                            </DialogClose>
                            <DialogContentWrapper>{content}</DialogContentWrapper>
                        </DialogContent>
                    </DialogOverlay>
                </RadixDialog.Portal>
            </RadixDialog.Root>
        );
    }
);

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});

const contentShow = keyframes({
    '0%': { opacity: 0, top: '52%' },
    '100%': { opacity: 1, top: '50%' },
});

const DialogOverlay = styled(RadixDialog.Overlay)({
    backgroundColor: 'rgba(0,0,0, 0.6)',
    position: 'fixed',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 1000ms cubic-bezier(0.16, 1, 0.3, 1) both`,
    },
    zIndex: 99997,
});

const DialogContent = styled(RadixDialog.Content)({
    backgroundColor: 'white',
    boxShadow:
        'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    maxWidth: '100vw',
    display: 'flex',
    overflowY: 'scroll',
    zIndex: 99998,
    '@media (prefers-reduced-motion: no-preference)': {
        willChange: 'transform',
        animation: `${contentShow} 500ms cubic-bezier(0.16, 1, 0.3, 1) both`,
    },
    '&:focus': { outline: 'none' },
});

const DialogClose = styled(RadixDialog.Close)({
    position: 'absolute',
    top: '8px',
    right: '5px',
    backgroundColor: theme.colors.white,
    boxShadow: 'none',
    border: 'none',
    cursor: 'pointer',
    zIndex: 99999,
    svg: {
        height: '25px',
        width: '25px',
        color: theme.colors.grey60,
    },
    ':hover': {
        svg: {
            color: theme.colors.black,
        },
    },
});

const DialogContentWrapper = styled.div({
    height: '100%',
    width: '100%',
});

export default Overlay;
