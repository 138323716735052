import { StyledButton, StyledButtonContentWrapper } from './../button/style';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import Link from '../link';
import { keyframes } from '@emotion/react';

// GRID
export const StyledBasketLineGrid = styled.div(() => ({
    display: 'grid',
    padding: theme.space.gutter,
    fontSize: theme.fontSizes.xs,
    columnGap: theme.space[4],
    rowGap: theme.space[2],
    alignItems: 'center',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'clamp(95px, 25vw, 130px) 2fr',
    gridTemplateAreas: `
    "image name"
    "image pricestock"
    "wrapper wrapper"`,

    [breakpoints.md]: {
        rowGap: 0,
        padding: `${theme.space[3]} `,
        alignItems: 'center',
        gridTemplateColumns: '75px 1.2fr 1fr .8fr 22px 2fr 1fr',
        gridTemplateAreas: `"image name price status favorites basket total"`,
    },
}));

// HEADINGS
export const StyledBasketLineHeaderGrid = styled(StyledBasketLineGrid)<{
    hideOnMobile?: boolean;
}>(
    () => ({
        display: 'flex',
        alignContent: 'center',
        marginBottom: 0,
        [breakpoints.sm]: {
            width: '100%',
        },
    }),
    ifProp('hideOnMobile', {
        display: 'none',
        [breakpoints.md]: {
            display: 'grid',
        },
    })
);

export const StyledBasketHeaderImage = styled.div(() => ({
    gridArea: 'image',
}));
export const StyledBasketHeader = styled.div(() => ({
    gridArea: 'name',
}));
export const StyledBasketHeaderPrice = styled.div(() => ({
    gridArea: 'price',
}));
export const StyledBasketHeaderStatus = styled.div(() => ({
    gridArea: 'status',
}));
export const StyledBasketHeaderFavorites = styled.div(() => ({
    gridArea: 'favorites',
}));
export const StyledBasketHeaderBasket = styled.div(() => ({
    gridArea: 'basket',
}));
export const StyledBasketHeaderTotalPrice = styled.div(() => ({
    gridArea: 'total',
    justifySelf: 'end',
}));

//LIST ITEMS
export const StyledBasketLineImage = styled.div(() => ({
    gridArea: 'image',
    alignSelf: 'start',

    img: {
        backgroundColor: theme.colors.white,
    },
}));

export const StyledBasketLineName = styled.div(() => ({
    gridArea: 'name',
    alignSelf: 'baseline',
    color: theme.colors.black,
    lineHeight: theme.lineHeights.base,

    [breakpoints.md]: {
        alignSelf: 'center',
    },
}));

export const StyledBasketLinePrimary = styled(Link)(() => ({
    fontWeight: theme.fontWeights.semiBold,
    marginBottom: theme.space[1],
    color: theme.colors.grey90,

    [breakpoints.md]: {
        marginBottom: '0',
    },
}));

export const StyledBasketLineSecondary = styled.p(() => ({
    color: theme.colors.blue,
}));

export const StyledBasketLineItemNo = styled.p(() => ({
    color: theme.colors.grey80,
}));

export const StyledBasketLinePrice = styled.div(() => ({
    gridArea: 'price',
    display: 'none',
    fontWeight: theme.fontWeights.regular,
    color: theme.colors.grey90,

    [breakpoints.md]: {
        display: 'block',
    },
}));

export const StyledBasketWrapper = styled.div(() => ({
    gridArea: 'wrapper',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.space[3],

    [breakpoints.md]: {
        display: 'contents',
        gridArea: 'auto',
    },
}));

export const StyledPriceStockWrapper = styled.div(() => ({
    gridArea: 'pricestock',
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    gap: theme.space[2],

    [breakpoints.md]: {
        display: 'contents',
        gridArea: 'auto',
    },
}));

export const StyledBasketLineStatus = styled.div(() => ({
    gridArea: 'status',
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.space[3],
}));

export const StyledBasketLineFavorites = styled.div(() => ({
    gridArea: 'favorites',
    width: 22,
}));

export const StyledBasketLinebasket = styled.div(() => ({
    gridArea: 'basket',
    display: 'inline-flex',
    gap: theme.space[3],
    justifyContent: 'space-between',
    justifySelf: 'left',
    alignItems: 'center',

    [`${StyledButton}`]: {
        fontSize: theme.fontSizes.xs,
    },
    [breakpoints.md]: {
        [`${StyledButton}`]: {
            fontSize: theme.fontSizes.lg,
        },
    },
    [`${StyledButtonContentWrapper}`]: {
        height: 40,
        lineHeight: 0,
    },
}));

export const StyledBasketQuantityWrapper = styled.div(() => ({
    position: 'relative',
}));

type StyledQuantityAdditionalText = {
    toggleWiggle: boolean;
};
const selectedKeyframe = keyframes({
    '0%': {
        transform: 'translateX(2px)',
    },
    '33%': { transform: 'translateX(-2px)' },
    '66%': { transform: 'rotate(2px)' },
    '100%': {
        transform: 'rotate(0px)',
    },
});
export const StyledQuantityAdditionalText = styled.p<StyledQuantityAdditionalText>(
    ({ toggleWiggle }) => ({
    gridRow: 4,
    gridColumn: 2,
    maxWidth: '130px',
    fontSize: 11,
    textAlign: 'left',
    paddingTop: theme.space[1],
    animation: toggleWiggle ? `${selectedKeyframe} 0.3s ease-in-out` : undefined,

    [breakpoints.md]: {
        gridRow: 2,
        gridColumn: 6,
        fontSize: theme.fontSizes['3xs'],
        paddingTop: 0,
    },
}));

export const StyledBasketLineDelete = styled.button(() => ({
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
    width: theme.space[6],
    height: theme.space[6],
    border: `1px solid ${theme.colors.grey40}`,
    backgroundColor: theme.colors.white,
    outline: 'none',

    ':focus': {
        outline: `1px solid ${theme.colors.primary}`,
        zIndex: 1,
    },
}));

export const StyledBasketLineTotalPrice = styled.div(() => ({
    gridArea: 'total',
    textAlign: 'right',
    flex: '1',
    whiteSpace: 'nowrap',
    fontWeight: theme.fontWeights.semiBold,
}));

export const StyledBasketLineDiscounts = styled.div(() => ({
    display: 'flex',
    width: '100%',
    maxWidth: '550px',
    marginLeft: 'auto',
    padding: `0 ${theme.space[3]} ${theme.space[3]} 0`,
}));


export const StyledOutletWrapper = styled.div(() => ({
    [breakpoints.md]: {
        alignSelf: 'center',
        textAlign: 'center',
        gridColumnStart: 'price',
        gridColumnEnd: 'total',
    },
}));