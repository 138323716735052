import React from 'react';
import { IIframe } from '~/lib/data-contract';
import AspectRatio from '$shared/components/aspect-ratio';

export type M230Props = Partial<IIframe>;

export const M230IPaper = ({ source, width, height }: M230Props) => {
    if (!source) {
        return null;
    }

    const ratio = width && height ? width / height : 16 / 9;

    return (
        <AspectRatio ratio={ratio}>
            <iframe
                style={{ display: 'block', width: '100%', height: '100%' }}
                src={source}
                allow="fullscreen"
                allowFullScreen
                scrolling="no"
            />
        </AspectRatio>
    );
};

export default M230IPaper;
