import { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';

type Props = {
    column?: boolean;
    flex?: boolean;
    justifyContent?:
        | 'start'
        | 'center'
        | 'end'
        | 'flex-start'
        | 'flex-end'
        | 'space-between'
        | 'space-around'
        | 'space-evenly';
    alignItems?:
        | 'start'
        | 'center'
        | 'end'
        | 'flex-start'
        | 'flex-end'
        | 'space-between';
    spacingBottom?: 'small' | 'medium' | 'large';
    wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
    grow?: boolean;
    gap?: 'extrasmall' | 'small' | 'medium' | 'large';
    className?: string;
    style?: CSSProperties;
};
export const StyledFlex = styled.div<Props>(
    ({ flex, column, justifyContent, alignItems, wrap, grow }) => ({
        display: 'flex',
        flex: flex ? 1 : 'initial',
        flexDirection: column ? 'column' : 'row',
        justifyContent: justifyContent ? justifyContent : 'initial',
        alignItems: alignItems ? alignItems : 'initial',
        flexWrap: wrap || 'initial',
        width: grow ? '100%' : undefined,
    }),
    switchProp('spacingBottom', {
        small: ({ theme: { space } }) => ({
            marginBottom: space[3],
        }),
        medium: ({ theme: { space } }) => ({
            marginBottom: space[5],
        }),
        large: ({ theme: { space } }) => ({
            marginBottom: space[7],
        }),
    }),
    switchProp('gap', {
        extrasmall: ({ theme: { space }, column }) => ({
            '& > * + *': {
                //fix for gap, since gap isn't supported on safari until 14.1
                marginLeft: !column ? space[1] : 'initial',
                marginTop: column ? space[1] : 'initial',
            },
        }),
        small: ({ theme: { space }, column }) => ({
            '& > * + *': {
                //fix for gap, since gap isn't supported on safari until 14.1
                marginLeft: !column ? space[3] : 'initial',
                marginTop: column ? space[3] : 'initial',
            },
        }),
        medium: ({ theme: { space }, column }) => ({
            '& > * + *': {
                //fix for gap, since gap isn't supported on safari until 14.1
                marginLeft: !column ? space[5] : 'initial',
                marginTop: column ? space[5] : 'initial',
            },
        }),
        large: ({ theme: { space }, column }) => ({
            '& > * + *': {
                //fix for gap, since gap isn't supported on safari until 14.1
                marginLeft: !column ? space[7] : 'initial',
                marginTop: column ? space[7] : 'initial',
            },
        }),
    })
);

export const Flex = StyledFlex;
export default Flex;
