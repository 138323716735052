import { usePage } from '$templates/pages';
import { buildURL, cancellableFetch } from '$shared/utils';
import { MARKET_BANNER_URL } from '../service/endpoints';
import { useQuery } from '@tanstack/react-query';
import { IMarketBanner } from '~/lib/data-contract';

export const queryMarketBannerData = () => {
    const { culture, market, id: pageId } = usePage();
    const serializedUrl = buildURL(MARKET_BANNER_URL, { pageId });

    return useQuery<IMarketBanner>(['marketBanner', market, culture], ({ signal }) =>
        cancellableFetch({ signal, url: serializedUrl })
    );
};
