import React, { FC, useMemo } from 'react';
import {
    StyledPriceStockWrapper,
    StyledProductLineGrid,
    StyledProductLineName,
    StyledProductLineNameLink,
    StyledProductLineNumber,
    StyledProductLinePrice,
    StyledProductLineSecondary,
    StyledProductLineSignIn,
    StyledProductLineStatus,
} from './style';
import { useKyoceraRouter } from '$shared/hooks/useKyoceraRouter';
import { useAuthentication } from '$shared/hooks/useAuthentication';
import { Stock } from '$features/stock/stock/stock';
import { Price } from '$features/price/price';
import { ProductLineItem } from './product-line.model';
import { AddToBasketProvider } from '$shared/utils/add-to-basket/AddToBasketProvider';
import { ExtraService } from '~/lib/data-contract';
import { ProductLineCtas } from './product-line-ctas';
import Link from '../link';
import { Badge } from '../badge/badge';
import { ProductLineImage } from './product-line-image';
import { ProductLineDownload } from './product-line-download';

export type ProductLineProps = ProductLineItem;

export const ProductLine: FC<ProductLineProps> = (productLine) => {
    const { asPath, isReady } = useKyoceraRouter();
    const { isAuthenticated, userFound } = useAuthentication();

    const url = useMemo(() => {
        if (isReady) {
            const [path] = asPath.split('?');

            // Remove the last segment
            const pathSegments = path.split('/');
            pathSegments.pop();
            const newPath = pathSegments.join('/');

            return `${newPath}/${productLine.sanitizedItemNumber}`;
        } else {
            return productLine.canonicalProductUrl || '';
        }
    }, [asPath, isReady, productLine.sanitizedItemNumber]);

    return (
        <StyledProductLineGrid>
            {productLine.drawingExists && !!productLine.originalItemNumber ? (
                <ProductLineDownload originalItemNumber={productLine.originalItemNumber} />
            ) : (
                <ProductLineImage
                    imageUrl={productLine.imageUrl as string}
                    alt={productLine.productName as string}
                />
            )}

            <StyledProductLineName>
                {productLine.promotedProduct && <Badge />}
                <Link href={url} passHref>
                    <StyledProductLineNameLink>
                        {productLine.productName}
                        <br />
                        {productLine.productNameSecondary}
                    </StyledProductLineNameLink>
                </Link>
            </StyledProductLineName>

            <StyledProductLineNumber>
                <Link href={url} passHref>
                    <span tabIndex={-1}>{productLine.primaryProductNumber}</span>
                    {productLine.secondaryProductNumber && (
                        <span tabIndex={-1}>
                            <StyledProductLineSecondary
                                children={productLine.secondaryProductNumber}
                            />
                        </span>
                    )}
                </Link>
            </StyledProductLineNumber>

            {isAuthenticated ? (
                userFound ? (
                    <>
                        <StyledPriceStockWrapper>
                            <StyledProductLineStatus>
                                <Stock {...productLine} />
                            </StyledProductLineStatus>
                            <StyledProductLinePrice discount={productLine.promotedProduct}>
                                <Price {...productLine} />
                            </StyledProductLinePrice>
                        </StyledPriceStockWrapper>
                        <AddToBasketProvider
                            extraServices={productLine.extraServices as ExtraService[]}
                            isQuantityReady={!productLine.isFetchingPriceAndStockCount}
                            minimumOrderSize={productLine.minimumOrderSize}
                            inSizesOf={productLine.inSizesOf}
                            prices={productLine.prices}
                            unit={productLine.units}
                            sanitizedItemNumber={productLine.sanitizedItemNumber as string}
                            promotedProduct={productLine.promotedProduct}
                            boxCount={productLine.boxCount}
                            primaryProductNumber={productLine.primaryProductNumber}
                        >
                            <ProductLineCtas />
                        </AddToBasketProvider>
                    </>
                ) : (
                    <></> // User not known to Aspect4
                )
            ) : (
                <StyledProductLineSignIn />
            )}
        </StyledProductLineGrid>
    );
};
