import { useTranslation } from '~/shared/utils';
import { CSSProperties, FC } from 'react';
import { StyledResetButton } from './style';
import { Icon } from '$shared/components/icon';

export type ResetFilterButtonProps = {
    text?: string;
    handleClick?(): void;
    style?: CSSProperties;
};
export const ResetFilterButton: FC<ResetFilterButtonProps> = ({ text, handleClick, style }) => {
    const { translate } = useTranslation();

    return (
        <StyledResetButton
            style={style}
            onClick={handleClick}
            variant="plain"
            iconRight={<Icon icon="close" color="blue" size={20} />}
        >
            {text || translate('product-filter.clear-filter')}
        </StyledResetButton>
    );
};

export default ResetFilterButton;
