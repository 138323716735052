import React, { useMemo, useState } from 'react';
import { SearchInput } from '$shared/components/form/components/form-fields/search-input/search-input';
import Headline from '$shared/components/headline';
import { ShowMoreButton } from '$shared/components/show-more-button';
import { formatString } from '$shared/utils';
import { useSearchProducts } from '$shared/utils/search/hooks/use-search-products';
import { useTranslation } from '$utils/translation';
import { IP230MyPurchasesPage } from '~/lib/data-contract';
import {
    StyledGenericProfileGrid,
    StyledProfileHeadline,
    StyledProfileSearch,
    StyledProfileList,
    StyledProfileButton,
    StyledGenericList,
} from '../style';
import { ProductLineHeader } from '$shared/components/product-line/product-line-header';
import { ProductLine } from '$shared/components/product-line';
import {
    PreviousPurchasedListItemLine,
    StyledlastPurchasedHeader,
    StyledlastPurchasedText,
    StyledlastPurchasedTranslation,
    StyledlastPurchasedValue,
} from './style';
import { StyledProductLineHeaderGrid } from '$shared/components/product-line/style';
import { getMyPagesTitle } from '$shared/utils/my-pages.helper';

const PAGE_SIZE = 20;

export const P230MyPurchasesPage = ({ metaTitle, pageElements }: IP230MyPurchasesPage) => {
    const { translate } = useTranslation();
    const [query, setQuery] = useState('');

    const pageTitle = useMemo(() => {
        const titleOverwrite = getMyPagesTitle(pageElements);
        return titleOverwrite || metaTitle;
    }, [pageElements, metaTitle]);

    const filterOnlyPurchasedProducts = true;
    const {
        productsWithPrices,
        productData,
        fetchNextPage,
        isFetching,
        isFetchingNextPage,
    } = useSearchProducts(
        query,
        {
            allowEmptySearch: true,
            pageSize: PAGE_SIZE,
        },
        filterOnlyPurchasedProducts
    );

    return (
        <StyledGenericProfileGrid>
            <StyledProfileHeadline>
                <Headline noMargin size={2}>
                    {pageTitle}
                </Headline>
            </StyledProfileHeadline>
            <StyledProfileSearch>
                <SearchInput
                    placeholder={translate('my-pages.purchases.purchases-search')}
                    value={query}
                    onChange={setQuery}
                />
            </StyledProfileSearch>
            <StyledProfileList>
                <StyledGenericList>
                    <PreviousPurchasedListItemLine>
                        <StyledlastPurchasedHeader>
                            <StyledProductLineHeaderGrid>
                                <p>
                                    {translate('my-pages.purchases.latest-purchased-column-header')}
                                </p>
                            </StyledProductLineHeaderGrid>
                        </StyledlastPurchasedHeader>
                        <ProductLineHeader hideOnMobile={true} />
                    </PreviousPurchasedListItemLine>
                    {productsWithPrices?.map((product) => (
                        <PreviousPurchasedListItemLine key={`${product.primaryProductNumber}`}>
                            <StyledlastPurchasedText>
                                <StyledlastPurchasedTranslation>
                                    {formatString(
                                        translate(
                                            'my-pages.purchases.latest-purchased-value-prefix'
                                        ),
                                        `${product.lastPurchasedPretty}`
                                    )}
                                </StyledlastPurchasedTranslation>
                                <StyledlastPurchasedValue>
                                    {product.lastPurchasedPretty}
                                </StyledlastPurchasedValue>
                            </StyledlastPurchasedText>
                            <ProductLine {...product} />
                        </PreviousPurchasedListItemLine>
                    ))}
                </StyledGenericList>
            </StyledProfileList>
            <StyledProfileButton>
                <ShowMoreButton
                    buttonText={translate('my-pages.load-more')}
                    handleClick={() => fetchNextPage()}
                    isLoading={isFetching || isFetchingNextPage}
                    progressText={formatString(
                        translate('my-pages.showing-x-of-x'),
                        productsWithPrices.length || 0,
                        productData?.total || 0
                    )}
                    progress={{
                        current: productsWithPrices.length,
                        total: productData?.total,
                        incrementSize: PAGE_SIZE,
                    }}
                />
            </StyledProfileButton>
        </StyledGenericProfileGrid>
    );
};
