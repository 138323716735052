import { theme } from '$theme';
import styled from '@emotion/styled';
import Text from '../text';

export const StyledSidebar = styled.div(() => ({
    marginBottom: theme.space[10],
}));

export type StyledNavItemSmallHeaderProps = {
    isActive?: boolean;
    paddingLeft?: string;
};
export const StyledNavItemSmallHeader = styled(Text)<StyledNavItemSmallHeaderProps>(
    ({ isActive, paddingLeft }) => ({
        color: isActive ? theme.colors.blue : theme.colors.defaultText,
        paddingLeft: paddingLeft || theme.space[4],

        ':hover': {
            color: `${theme.colors.blue}`,
        },
    })
);
