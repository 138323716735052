import React from 'react';
import Headline from '$shared/components/headline';
import Video from '$shared/components/video';
import { Icon } from '$shared/components/icon';
import Image from '$shared/components/image';
import { ILink, IResponsiveMedia, ICallToAction, IRichText } from '~/lib/data-contract';
import {
    StyledContentList,
    StyledContentListWrapper,
    StyledDetailsColumn,
    StyledImageColumn,
    StyledRow,
} from './style';
import { breakpointSizes } from '$theme';
import { RawHtml } from '../raw-html';
import { useTranslation } from '$shared/utils';

export type ContentListProps = {
    headline?: string;
    listItems?: ContentListItemProps[];
    callToAction?: ICallToAction;
    filterHeadline?: string;
    filter?: boolean;
};

export type ContentListItemProps = {
    tag?: string;
    filterValue?: string;
    headline?: string;
    descriptionRichText?: IRichText;
    descriptionString?: string;
    moreInfo?: React.ReactNode;
    link?: ILink;
    externalLink?: string;
    media?: IResponsiveMedia | any; // TODO: no any here plz
};

export const ContentList = ({ listItems }: ContentListProps) => {
    const { translate } = useTranslation();

    return (
        <StyledContentListWrapper>
            <StyledContentList>
                {listItems?.length ? (
                    listItems?.map((item, i) => (
                        <StyledRow
                            key={i}
                            href={item?.link?.url || item?.externalLink}
                            target={item?.link?.target}
                        >
                            {item.media ? (
                                <StyledImageColumn key={i}>
                                    {item?.media?.video ? (
                                        <Video
                                            src={item?.media?.video.src}
                                            loop={item?.media?.video.loop}
                                            playing={item?.media?.video.autoPlay}
                                        />
                                    ) : (
                                        <Image
                                            width={16}
                                            height={9}
                                            src={item?.media?.desktop?.src}
                                            sizes={`(max-width: ${breakpointSizes.sm}px) 100vw, 300px`}
                                        />
                                    )}
                                </StyledImageColumn>
                            ) : (
                                ''
                            )}
                            <StyledDetailsColumn hasMedia={!!item.media}>
                                <div>
                                    <Headline as="h4" size={3} noMargin>
                                        {item?.headline}
                                    </Headline>
                                    <div>
                                        {item?.descriptionString ? (
                                            <p>{item?.descriptionString}</p>
                                        ) : item?.descriptionRichText ? (
                                            <RawHtml html={item?.descriptionRichText?.html} />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>

                                {item?.moreInfo && <div>{item?.moreInfo}</div>}
                                <Icon icon={'arrowRight'} color="blue" />
                            </StyledDetailsColumn>
                        </StyledRow>
                    ))
                ) : (
                    <Headline size={4} noMargin>
                        {translate('search.search-bar.suggestions-no-results')}
                    </Headline>
                )}
            </StyledContentList>
        </StyledContentListWrapper>
    );
};
