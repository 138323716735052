import {
    FacetViewModel,
    ISimpleMetaFieldViewModel,
    ProductViewModel,
    SearchResponseViewModel,
} from '~/lib/data-contract';
import { Facet, EFacetType_Generic, Filter, FilterResult } from '..';
import { AdditionalFilterQueryOptions } from '../service/useFilterQuery';

export function filterResultMapper(result: ProductViewModel): FilterResult {
    return {
        itemNumber: result.originalItemNumber,
        name: result.itemDescription1,
        description: result.itemDescription2,
        unit: result.unit,
        imageUrl: result.photoPath,
        ...result,
    };
}

function getFacetType(type?: string | null): EFacetType_Generic | undefined {
    switch (type) {
        case 'Checkbox':
            return EFacetType_Generic.CHECKBOX;
        case 'Dropdown':
            return EFacetType_Generic.DROPDOWN;
    }
}

export function cmsFacetMapper(
    facet: ISimpleMetaFieldViewModel & Pick<FacetViewModel, 'options'>
): Facet {
    return {
        ...facet,
        title: facet.displayName,
        id: facet.fieldName,
        options: facet.options,
        type: getFacetType(facet.htmlControlType),
    };
}

export function resultFacetMapper(facet: FacetViewModel): Facet {
    return {
        order: facet.sortOrder,
        dataType: facet.dataType as string,
        title: facet.title,
        id: facet.key,
        options: facet.options,
        suffix: facet.suffix as string,
        type: getFacetType(facet.htmlControlType),
        url: facet.url ?? undefined,
    };
}

function facetFilter(
    facetsFromCMS: ISimpleMetaFieldViewModel[],
    searchResultFacets: FacetViewModel[],
    allowEmptyFacets = false
) {
    if (facetsFromCMS.length) {
        return facetsFromCMS
            ?.filter((f) => f.useAsFacet)
            ?.map((facet) => ({
                ...facet,
                options: searchResultFacets?.find(({ key }) => key === facet.fieldName)?.options,
            }))
            ?.filter(({ options }) => (allowEmptyFacets ? true : options?.length)) // Removing any facets without options
            ?.map(cmsFacetMapper);
    }

    return searchResultFacets?.map(resultFacetMapper);
}

export function filterMapper({
    products,
    facets: searchResultFacets,
    total,
    facetsFromCMS,
    allowEmptyFacets,
}: SearchResponseViewModel & { allowEmptyFacets?: boolean } & Pick<
        AdditionalFilterQueryOptions,
        'facetsFromCMS'
    > = {}): Filter {
    return {
        facets: facetFilter(facetsFromCMS || [], searchResultFacets || [], allowEmptyFacets),
        results: products?.map(filterResultMapper),
        resultsCurrent: products?.length,
        resultsTotal: total,
    };
}
