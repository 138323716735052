import { cancellableFetch } from "$shared/utils";
import { GET_ALL_FAVORITES_URL } from "./my-pages-endpoints";

const url = GET_ALL_FAVORITES_URL;
const method = 'GET';

export async function getAllFavorites(signal?: AbortSignal): Promise<string[]> {
    return cancellableFetch<string[]>({
        signal,
        url,
        options: {
            method,
        },
    });
}
