import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { useDetectHorisontalScroll, useDraggableScroll } from '$shared/utils';

type StyledFadeProps = {
    active?: boolean;
    hasOverlow?: boolean;
};
export const StyledFade = styled.div<StyledFadeProps>(
    ({ theme }) => ({
        position: 'relative',
        width: '100%',

        // Fading elements
        '&:before, &:after': {
            pointerEvents: 'none',
            content: '""',
            position: 'absolute',
            opacity: 0,
            top: 0,
            left: '-1px',
            bottom: 0,
            width: '100px',
            backgroundImage: `linear-gradient(90deg, ${theme.colors.white}, rgba(255, 255, 255, 0))`,
            transitionDuration: '0.2s',
        },
    }),
    ifProp('active', {
        '&:before': {
            opacity: 1,
        },
    }),
    ifProp('hasOverlow', ({ theme }) => ({
        '&:after': {
            opacity: 1,
            left: 'unset',
            right: '-1px',
            backgroundImage: `linear-gradient(90deg, rgba(255, 255, 255, 0), ${theme.colors.white})`,
        },
    }))
);

const StyledScroll = styled.div<StyledFadeProps>(
    () => ({
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        WebkitOverflowScrolling: 'touch',

        // Hide scrollbar:
        msOverflowStyle: '-ms-autohiding-scrollbar',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },

        '&:after': {
            pointerEvents: 'none',
        },
    }),
    ifProp('hasOverlow', {
        // So that one can scroll far enough to hide the right fade
        '&:after': {
            display: 'inline-block',
            content: '""',
            minWidth: '75px',
        },
    })
);

export const ScrollFade: FC<PropsWithChildren> = ({ children }) => {
    if (!children) {
        return null;
    }

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [hasOverlow, setOverflow] = useState(false);
    const { hasScrolled } = useDetectHorisontalScroll(scrollContainerRef);
    const { onMouseDown } = useDraggableScroll(scrollContainerRef, {
        direction: 'horizontal',
        disabled: !hasOverlow,
    });

    useEffect(() => {
        const element = scrollContainerRef?.current;
        if (element) {
            const elementOverflows = element.clientWidth < element.scrollWidth;
            if (hasOverlow !== elementOverflows) {
                setOverflow(elementOverflows);
            }
        }
    }, [scrollContainerRef]);

    return (
        <StyledFade active={hasScrolled} hasOverlow={hasOverlow}>
            <StyledScroll
                ref={scrollContainerRef}
                onMouseDown={onMouseDown}
                hasOverlow={hasOverlow}
            >
                {children}
            </StyledScroll>
        </StyledFade>
    );
};

export default ScrollFade;
