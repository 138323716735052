import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

type AvoidContainerPaddingProps = {
    butOnlyOnThePhone?: boolean;
};

const StyledAvoidContainerPadding = styled.div<AvoidContainerPaddingProps>(
    () => ({
        [breakpoints.xl]: {
            width: '100%',
            margin: 0,
        },
    }),
    ifProp(
        { butOnlyOnThePhone: false },
        {
            width: `calc(100% + ${theme.space.gutter} + ${theme.space.gutter})`,
            margin: `0 -${theme.space.gutter}`,
        }
    ),
    ifProp(
        { butOnlyOnThePhone: true },
        {
            [breakpoints.msm]: {
                width: `calc(100% + ${theme.space.gutter} + ${theme.space.gutter})`,
                margin: `0 -${theme.space.gutter}`,
            },
        }
    )
);

export const AvoidContainerPadding = StyledAvoidContainerPadding;

export default AvoidContainerPadding;
