import React, { CSSProperties, FC, ReactNode, useId } from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { Icon } from '$shared/components/icon';
import { Headline, HeadlineProps } from '$shared/components/headline';
import {
    StyledAccordion,
    StyledAccordionContent,
    StyledAccordionTrigger,
    StyledAccordionIconWrapper,
} from './style';

export type AccordionProps = {
    /**
     * Text for the headline OR a component that will replace the default header and be used to trigger for the accordion
     */
    header?: string | ReactNode;

    headerSize?: HeadlineProps['size'];

    /**
     * If you want to disable the icon...
     */
    disableIcon?: boolean;

    /**
     * Overwrite the default icon
     */
    icon?: ReactNode;

    /**
     * Should the accordion item start as open.
     */
    initAsOpen?: boolean;

    children?: React.ReactNode;

    contentContainerStyle?: CSSProperties;

    /**
     * For externally controlling the state of the accordion
     */
    onValueChange?: (value: string) => void;

    /**
     * For externally controlling the state of the accordion
     * Open: accordionId (overrideId)
     * closed: ''
     */
    value?: string;

    /**
     * AccordionId to allow for external state management of accordion.
     */
    overrideId?: string;
};

/**
 * @description
 * Wraps content (children) in an accordion.
 *
 * @example
 *  <Accordion
 *      icon={<Icon icon="instagram" size="lg" />}
 *      header="Woah this is a cool accordion" />
 *      <p>Accordion body here!</p>
 *  </Accordion>
 */

export const Accordion: FC<AccordionProps> = ({
    children,
    contentContainerStyle,
    disableIcon,
    header,
    headerSize = 5,
    icon,
    initAsOpen,
    onValueChange,
    overrideId,
    value,
}) => {
    // Ensures present id.
    const id = useId();

    return (
        <StyledAccordion>
            <RadixAccordion.Root
                onValueChange={onValueChange}
                value={value}
                type="single"
                collapsible
                defaultValue={initAsOpen ? id : undefined}
            >
                <RadixAccordion.Item value={overrideId || id}>
                    <StyledAccordionTrigger>
                        <>
                            {typeof header === 'string' ? (
                                <Headline size={headerSize} noMargin>
                                    {header}
                                </Headline>
                            ) : (
                                header
                            )}
                            {!disableIcon && (
                                <StyledAccordionIconWrapper>
                                    {icon ? icon : <Icon icon="arrowDown" size="lg" color="blue" />}
                                </StyledAccordionIconWrapper>
                            )}
                        </>
                    </StyledAccordionTrigger>
                    <StyledAccordionContent style={contentContainerStyle}>
                        {children}
                    </StyledAccordionContent>
                </RadixAccordion.Item>
            </RadixAccordion.Root>
        </StyledAccordion>
    );
};
