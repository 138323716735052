import React from 'react';
import { StyledOption } from './style';

export type OptionProps = {
    /**
     * The value of the option
     */
    value: string;

    /**
     * Should the option be disabled
     */
    disabled?: boolean;

    /**
     * Is the option highlighted
     */
    selected?: boolean;

    children?: React.ReactChild;
};

export const OptionComponent = React.forwardRef<HTMLLIElement, OptionProps>(
    ({ value, selected, children, ...rest }, ref) => {
        return (
            <StyledOption selected={selected} key={value} ref={ref} {...rest}>
                {children || value}
            </StyledOption>
        );
    }
);

export default OptionComponent;
