import React, { FC, useEffect, useMemo } from 'react';
import { BasketSummaryLine } from '$shared/components/basket-summary/basket-summary-line';
import { BasketSummaryTotalLine } from '$shared/components/basket-summary/basket-summary-total-line';
import { formatString, useTranslation } from '$shared/utils';

import { useCreateQuote } from '$features/checkout/hooks/use-create-quote';
import { useOrderStore } from '$features/checkout/hooks/use-order-store';

type props = { hideFreight?: boolean; isLoading?: boolean };
export const QuoteSummaryLines: FC<props> = ({ hideFreight, isLoading: isLoadingControlled }) => {
    const { translate } = useTranslation();
    const { createNewQuote, creatingQuoteIsLoading } = useCreateQuote();
    const { quote, pickupOrder, splitOrder } = useOrderStore();

    const quoteCount = useMemo(() => {
        return quote?.orderLines?.reduce((acc, cur) => acc + (cur?.quantity || 0), 0);
    }, [quote]);

    useEffect(() => {
        createNewQuote();
    }, [splitOrder, pickupOrder]);

    const subtotalLabel =
        quoteCount === 1
            ? formatString(
                  translate('checkout-page.basket-summary.subtotal-single-item'),
                  quoteCount
              )
            : formatString(
                  translate('checkout-page.basket-summary.subtotal-multiple-items'),
                  quoteCount
              );

    const isLoading = isLoadingControlled || creatingQuoteIsLoading;

    return (
        <>
            <BasketSummaryLine
                isLoading={isLoading}
                label={subtotalLabel}
                value={
                    !hideFreight
                        ? quote?.orderTotalExVatExFreightPretty || ''
                        : quote?.orderTotalExVatPretty || ''
                }
            />
            {!hideFreight && (
                <BasketSummaryLine
                    isLoading={isLoading}
                    label={translate('checkout-page.basket-summary.estimated-delivery-cost')}
                    value={quote?.freightPretty || ''}
                />
            )}
            <BasketSummaryLine
                isLoading={isLoading}
                label={translate('checkout-page.basket-summary.vat')}
                value={quote?.vatPretty || ''}
            />
            <BasketSummaryTotalLine value={quote?.orderTotalPretty || ''} isLoading={isLoading} />
        </>
    );
};
