import React, { FC } from 'react';
import {
    StyledCheckoutLineGrid,
    StyledBasketLineImage,
    StyledBasketLineName,
    StyledBasketLinePrice,
    StyledBasketLinePrimary,
    StyledBasketLineAmount,
    StyledBasketLineTotalPrice,
    StyledPriceStockWrapper,
    StyledBasketLineSecondary,
    StyledServiceLine,
    StyledServiceLines,
    ServiceLineName,
    ServiceLinePrice,
    ServiceLinePreText,
    ServiceLineNote,
    StyledBasketLineItemNo,
} from './style';

import { breakpointSizes } from '$theme';
import { ProductImage } from '$shared/components/image';
import { ProductLineItem } from '$shared/components/product-line/product-line.model';
import { CalculatedBasketLineServiceViewModel } from '$order-models/*';
import { Badge, EBadgeType } from '$shared/components/badge/badge';

export type CheckoutLineItem = ProductLineItem & {
    totalPrice?: string;
    quantity?: number;
    serviceDetails?: CalculatedBasketLineServiceViewModel[];
};

export const CheckoutLine: FC<CheckoutLineItem> = (checkoutLine) => {
    return (
        <StyledCheckoutLineGrid>
            <StyledBasketLineImage>
                <ProductImage
                    src={checkoutLine.imageUrl}
                    sizes={`(max-width: ${breakpointSizes.sm}px) 33vw, 15vw`}
                />
            </StyledBasketLineImage>
            <StyledBasketLineName>
                {checkoutLine?.promotedProduct && <Badge type={EBadgeType.PROMOTED_PRODUCT} />}
                <StyledBasketLinePrimary>{checkoutLine?.productName}</StyledBasketLinePrimary>
                <StyledBasketLineSecondary>{checkoutLine?.customerText2}</StyledBasketLineSecondary>
                <StyledBasketLineItemNo>
                    {checkoutLine?.primaryProductNumber}
                </StyledBasketLineItemNo>
            </StyledBasketLineName>
            <StyledPriceStockWrapper>
                <StyledBasketLineAmount>
                    {checkoutLine.quantity} {checkoutLine.units}
                </StyledBasketLineAmount>
                <StyledBasketLinePrice>{checkoutLine.productPrice}</StyledBasketLinePrice>
            </StyledPriceStockWrapper>
            <StyledBasketLineTotalPrice>{checkoutLine.totalPrice}</StyledBasketLineTotalPrice>
            <StyledServiceLines>
                {checkoutLine?.serviceDetails
                    ?.filter((sd) => sd.selected)
                    ?.map((serviceDetail) => (
                        <StyledServiceLine key={serviceDetail.sanitizedItemNumber}>
                            <ServiceLinePreText>+</ServiceLinePreText>
                            <ServiceLineName>{serviceDetail.serviceName}</ServiceLineName>
                            <ServiceLinePrice>{serviceDetail.formattedPrice}</ServiceLinePrice>
                            {!!serviceDetail.textInput && (
                                <ServiceLineNote>{`"${serviceDetail.textInput}"`}</ServiceLineNote>
                            )}
                        </StyledServiceLine>
                    ))}
            </StyledServiceLines>
        </StyledCheckoutLineGrid>
    );
};
