import React from 'react';
import { IModules, IP20FrontPage } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';
import { StyledModulesWrapper } from '../../P60ModulePage/style';

type P20FrontPageProps = IP20FrontPage;

export const P20FrontPage = (props: P20FrontPageProps) => {
    return (
        <StyledModulesWrapper>
            <DynamicBlockList elements={props.pageElements as IModules[]} />
        </StyledModulesWrapper>
    );
};
