import { theme } from '$theme';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { EBadgeType } from './badge';

export const StyledBadge = styled.div<{ type?: EBadgeType }>(
    {
        display: 'inline-block',
        fontSize: theme.fontSizes['3xs'],
        borderRadius: 2,
        paddingBlock: 3,
        paddingInline: 5,
        marginBottom: theme.space[1],
        lineHeight: 1,
    },
    switchProp('type', {
        PROMOTED_PRODUCT: {
            color: theme.colors.black,
            backgroundColor: theme.colors.yellowBase50,
        },
    })
);
