import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import StyledButton from '$shared/components/button';
import React from 'react';
import { Icon } from '$shared/components/icon';
import { theme } from '$theme';

export interface PlayButtonProps {
    playing?: boolean;
}

export const StyledPlayButton = styled(StyledButton)<PlayButtonProps>(
    {
        border: 'none',
        appearance: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'opacity 0.1s, transform 0.1s ease-in-out',
        padding: `0 ${theme.space[2]}`,

        svg: {
            transform: 'inherit',
            transition: 'inherit',
            width: '1em',
            height: '1em',
        },
    },
    switchProp('playing', {
        true: {
            opacity: 0,
            pointerEvents: 'none',
        },
        false: {
            opacity: 1,
        },
    })
);

export default ({ playing }: PlayButtonProps) => (
    <StyledPlayButton
        variant="primary"
        playing={playing}
        iconRight={<Icon icon="play" color="white" />}
    />
);
