import React, { FC, useCallback, useState } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';
import { StyledHelpText } from '$shared/components/form/components/help-text';
import { InvalidMessage } from '$shared/components/form/components/invalid-message';
import { IChoiceFormElement } from '~/lib/data-contract';
import { StyledChoiceOption, StyledIndependentLabel } from '../../style';
import CheckboxElement from '$shared/components/form/components/controllable-elements/checkbox-element';
import RadioButtonElement from '$shared/components/form/components/controllable-elements/radio-button-element';

export type ChoiceEpiFieldProps = Partial<Omit<IChoiceFormElement, 'type'>> & {
    rules?: RegisterOptions;
};

export const ChoiceEpiField: FC<ChoiceEpiFieldProps> = ({
    name = '',
    options,
    tooltipText,
    label,
    allowMultiSelect,
    rules,
}) => {
    const defaultOptions = options?.filter((o) => o.checked).map((o) => o.value);

    const { 
        field: { value: controllerValue, onChange }
    } = useController({
        name,
        rules,
        defaultValue: defaultOptions,
    });

    const [currentSelection, setCurrentSelection] = useState((controllerValue ?? []) as string[]);

    const handleChange = useCallback(
        (changedValue: string) => {
            let changedSelection = [...currentSelection];
            const isSelected = currentSelection.includes(changedValue);

            if (allowMultiSelect) {
                changedSelection = isSelected
                    ? currentSelection.filter((v) => v !== changedValue)
                    : [...currentSelection, changedValue];
            } else if (!isSelected) {
                changedSelection = [changedValue];
            }

            onChange(changedSelection);
            setCurrentSelection(changedSelection);
        },
        [currentSelection, onChange]
    );

    return (
        <>
            <StyledIndependentLabel required={!!rules?.required}>{label}</StyledIndependentLabel>
            {tooltipText ? <StyledHelpText children={tooltipText} /> : null}
            {options?.map(({ text, value }) => (
                <StyledChoiceOption key={`${text}${value}`}>
                    {allowMultiSelect || options?.length === 1 ? (
                        <CheckboxElement
                            label={text}
                            value={value}
                            name={name}
                            checked={!!value && currentSelection.includes(value)}
                            hideInvalidMessage={true}
                            onChange={(e) => handleChange(e.target.value)}
                        />
                    ) : (
                        <RadioButtonElement
                            label={text}
                            value={value}
                            name={name}
                            checked={!!value && currentSelection.includes(value)}
                            hideInvalidMessage={true}
                            onChange={(e) => handleChange(e.target.value)}
                        />
                    )}
                </StyledChoiceOption>
            ))}
            <InvalidMessage forField={name} />
        </>
    );
};
