import { theme } from '../../../theme/theme';
import styled from '@emotion/styled';
import { InputElement } from '$shared/components/form/components/controllable-elements/input-element';
import { ifProp } from 'styled-tools';
import { pulse } from '$shared/utils';

export const StyledExtraServiceWrapper = styled.div<{
    hasArrow: boolean;
    isBusy: boolean;
    productLineLayout: boolean;
}>(
    ({ isBusy }) => ({
        ...(isBusy ? pulse : {}),
        width: '100%',
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.grey40}`,
        padding: theme.space[2],
        fontSize: theme.fontSizes.sm,
        position: 'relative',
    }),
    ifProp('productLineLayout', () => ({
        maxWidth: 572,
    })),
    ifProp('hasArrow', () => ({
        '&:after': {
            content: '""',
            position: 'absolute',
            right: 22,
            top: -11,
            width: 22,
            height: 22,
            backgroundColor: 'inherit',
            transform: 'rotate(45deg)',
            borderTop: `1px solid ${theme.colors.grey40}`,
            borderLeft: `1px solid ${theme.colors.grey40}`,
        },
    }))
);

export const StyledItemWrapper = styled.div({
    display: 'flex',
});

export const StyledExtraServiceListItem = styled.li<{ hasInput: boolean }>(
    ({ theme, hasInput }) => ({
        padding: `${theme.space[2]} ${theme.space[1]} ${hasInput ? 0 : theme.space[2]}`,
    })
);

export const StyledServiceSpan = styled.span({
    display: 'flex',
    color: theme.colors.grey80,
    width: '100%',
    justifyContent: 'space-between',
});

export const StyledPrice = styled.p(({ theme }) => ({
    color: theme.colors.black,
    whiteSpace: 'nowrap',
    marginLeft: theme.space[1],
    display: 'flex',
    alignItems: 'center',
}));

export const StyledExtraServiceInput = styled(InputElement)({
    display: 'block',
    width: '100%',
    marginTop: theme.space[2],
    marginBottom: theme.space[2],
});
