import React, { FC } from 'react';
import { useController } from 'react-hook-form';
import { ReactHookForm__InputProps } from '../../../models/form-models';
import {
    FileInputElement,
    FileInputElementProps,
} from '../../controllable-elements/file-input-element';

export type FileInputProps = FileInputElementProps & ReactHookForm__InputProps;

export const FileInput: FC<FileInputProps> = ({
    name,
    label,
    rules,
    helpText,
    allowMultipleFiles,
    allowedExtensions,
    fileSizeMaxMb,
}) => {
    const {
        field: { value, onChange },
        fieldState: { invalid, error },
    } = useController({
        name,
        rules,
    });

    const { message: errorMessage } = error || {};

    return (
        <FileInputElement
            label={label}
            onChange={onChange}
            controlledValue={value}
            invalidMessage={errorMessage}
            isInvalid={invalid}
            helpText={helpText}
            allowMultipleFiles={allowMultipleFiles}
            allowedExtensions={allowedExtensions}
            fileSizeMaxMb={fileSizeMaxMb}
            isRequired={!!rules?.required}
        />
    );
};
