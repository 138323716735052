import React from 'react';
import {
    StyledReceiptBasketSummary,
    StyledReceiptBlockWrapper,
    StyledReceiptButton,
    StyledReceiptTextBlockWrapper,
    StyledReceiptTextBox,
    StyledReceiptWrapper,
} from './style';
import { formatString, useFrame, useTranslation } from '$shared/utils';
import { IP320ReceiptPage } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';
import Container from '$shared/components/container';
import { DeliveryIntervals } from '$features/checkout';
import { BasketSummary } from '$shared/components/basket-summary/basket-summary';
import { OrderReceiptDetails } from './order-receipt-details/order-receipt-details';
import Link from '$shared/components/link';
import { useReceiptStore } from '$features/checkout/hooks/use-receipt-store';
import { ReceiptSummaryLines } from './receipt-summary-lines';
import { useRawHtml } from '$shared/components/raw-html';
import { StyledCheckoutHeadline } from '../../P310CheckoutPage/style';
import { StyledModulesWrapper } from '../../P60ModulePage/style';

export const P320ReceiptPage = ({ pageElements }: IP320ReceiptPage) => {
    const { translate } = useTranslation();
    const { header } = useFrame();
    const { myPages } = header || {};
    const receiptPage = myPages?.find((p) => (p as any).type === 'P220MyOrdersPage'); // BE Models not correct
    const { receipt, lines } = useReceiptStore();

    return (
        <Container>
            <StyledReceiptWrapper>
                <div>
                    <StyledReceiptBlockWrapper>
                        <StyledCheckoutHeadline size="h3">
                            {translate('receipt-page.headline')}
                        </StyledCheckoutHeadline>
                        <StyledReceiptTextBox>
                            {useRawHtml(
                                formatString(
                                    translate('receipt-page.email-confirmation'),
                                    receipt?.contact?.eMail || ''
                                )
                            )}
                        </StyledReceiptTextBox>

                        <StyledReceiptButton>
                            {receiptPage?.url && (
                                <Link variant="secondary" href={receiptPage?.url}>
                                    {translate('receipt-page.my-orders-button')}
                                </Link>
                            )}
                        </StyledReceiptButton>
                    </StyledReceiptBlockWrapper>

                    <StyledReceiptTextBlockWrapper>
                        <OrderReceiptDetails />
                    </StyledReceiptTextBlockWrapper>
                </div>
                <div>
                    <DeliveryIntervals initAsOpen={false} controlledLines={lines} splitOrderOverwrite={receipt?.splitDelivery} />
                    <StyledReceiptBasketSummary>
                        <BasketSummary
                            actionLink={[]}
                            headerLink={[]}
                            listItems={<ReceiptSummaryLines />}
                            showHeader={false}
                        />
                    </StyledReceiptBasketSummary>
                </div>
            </StyledReceiptWrapper>

            <StyledModulesWrapper>
                <DynamicBlockList elements={pageElements} />
            </StyledModulesWrapper>
        </Container>
    );
};
