export type Encodable = string | number | boolean | undefined;
export type QueryParams = { [key: string]: Encodable | Encodable[] };

/**
 * Allows pasing number and boolean as values.
 * Follows the `a=1&a=2` standard and not the default
 * `a=1,2` standard
 */
const buildSearchParams = (params: QueryParams) => {
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (value === null || typeof value === 'undefined') {
            return;
        }
        const valueArray = Array.isArray(value) ? value.sort() : [value];
        valueArray.forEach((v) => v && searchParams.append(key, v.toString()));
    });

    searchParams.sort();
    return searchParams;
};

/**
 * Get base, hash and search part of url
 
 * `baseUrl` the origin with pathname.
 * `search` search queries without `?`.
 * `hash` hashtag value without `#`.
 */
const parseUrl = (url: string) => {
    // Remove everything after the first instance of ? or #
    const baseUrl = url.replace(/(\?|#).*/, '');
    const queryString = url.replace(baseUrl, '');

    // Assume hashtag comes first
    // eg. #hash-id?color=red
    const [hashValue = '', searchValue = ''] = queryString.split('?');

    let hash = hashValue.replace('#', '');
    let search = searchValue;

    // Reverse if hashtag in search
    if (searchValue.includes('#')) {
        const [searchValue, hashValue] = search.split('#');
        search = searchValue.replace('?', '');
        hash = hashValue;
    }

    return {
        baseUrl,
        search,
        hash,
    };
};

/**
 * Adds query params to an url. Ensuring a deterministic url.
 * Useful when using the url as a unique identifier and need to compare
 *
 * NOTE: hashtag values are deliberately excluded eg. https://example.com/#hashtag-id
 */
export const buildURL = <T extends string | undefined,>(url: T, params?: QueryParams) => {
    if (!url) return url as T;
    const { baseUrl, search } = parseUrl(url);
    const searchParams = new URLSearchParams(search);

    if (params) {
        const serializedParams = buildSearchParams(params);
        serializedParams.forEach((value, key) => searchParams.append(key, value));
    }

    // Ensures deterministic params
    searchParams.sort();

    const newSearch = searchParams.toString();
    return (newSearch ? `${baseUrl}?${newSearch}` : baseUrl) as T;
};
