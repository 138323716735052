import React, { FC, useCallback, useMemo } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';
import { StyledHelpText } from '$shared/components/form/components/help-text';
import { InvalidMessage } from '$shared/components/form/components/invalid-message';
import { ISingleChoiceFormElement } from '~/lib/data-contract';
import { StyledChoiceLabel, StyledChoiceOption } from '../../style';
import CheckboxElement from '$shared/components/form/components/controllable-elements/checkbox-element';
import { RawHtml } from '$shared/components/raw-html';

export type SingleChoiceEpiFieldProps = Partial<Omit<ISingleChoiceFormElement, 'type'>> & {
    rules?: RegisterOptions;
};

export const SingleChoiceEpiField: FC<SingleChoiceEpiFieldProps> = ({
    name = '',
    option,
    tooltipText,
    rules,
}) => {
    const { text, value } = option || {};
    const defaultValue = option?.checked && value;

    const {
        field: { value: controllerValue, onChange },
    } = useController({
        name,
        rules,
        ...{ defaultValue },
    });

    const isChecked = useMemo(() => value === controllerValue, [controllerValue, value]);

    const handleChange = useCallback(() => {
        onChange(isChecked ? false : value || true);
    }, [isChecked, onChange, value]);

    return (
        <>
            {tooltipText ? <StyledHelpText children={tooltipText} /> : null}
            <StyledChoiceOption key={`${text}${value}`}>
                <StyledChoiceLabel required={!!rules?.required}>
                    <CheckboxElement
                        name={name}
                        value={value}
                        checked={isChecked}
                        onChange={handleChange}
                        hideInvalidMessage={true}
                    />
                    <RawHtml html={text} />
                </StyledChoiceLabel>
            </StyledChoiceOption>
            <InvalidMessage forField={name} />
        </>
    );
};
