import cs from 'date-fns/locale/lt';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fi from 'date-fns/locale/fi';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import lt from 'date-fns/locale/lt';
import nn from 'date-fns/locale/nn';
import pl from 'date-fns/locale/pl';
import ru from 'date-fns/locale/ru';
import sv from 'date-fns/locale/sv';
import tr from 'date-fns/locale/tr';
import us from 'date-fns/locale/en-US';
import { Weekday } from '~/lib/data-contract';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';

export function getLocale(locale: string) {
    switch (locale) {
        case 'cs':
            return cs;
        case 'da':
            return da;
        case 'de':
            return de;
        case 'es':
            return es;
        case 'fi':
            return fi;
        case 'fr':
            return fr;
        case 'it':
            return it;
        case 'lt':
            return lt;
        case 'no':
            return nn;
        case 'pl':
            return pl;
        case 'ru':
            return ru;
        case 'sv':
            return sv;
        case 'tr':
            return tr;
        default:
            return us;
    }
}

export function mapWeekdayToDateDay(weekday: Weekday) {
    switch (weekday) {
        case Weekday.sunday:
            return 0;
        case Weekday.monday:
            return 1;
        case Weekday.tuesday:
            return 2;
        case Weekday.wednesday:
            return 3;
        case Weekday.thursday:
            return 4;
        case Weekday.friday:
            return 5;
        case Weekday.saturday:
            return 6;
    }
}

export function calcTimeDate(hours?: number, minutes?: number) {
    return hours !== undefined && minutes !== undefined
        ? setHours(setMinutes(new Date(), minutes), hours)
        : undefined;
}
