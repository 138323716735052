import { RawHtml } from '$shared/components/raw-html';
import { theme } from '$theme';
import styled from '@emotion/styled';

export const StyledRawHtml = styled(RawHtml)({
    margin: '0 auto',
    maxWidth: '23em',
});

export const StyledMoreButtonPadding = styled.div({
    paddingTop: theme.space[4],
});

export const StyledTooltipContent = styled.div(({ theme }) => ({
    padding: theme.space.gutter,
    maxWidth: 'min(380px, calc(100vw - 40px))',
}));
