import styled from '@emotion/styled';
import Headline, { HeadlineProps } from '$shared/components/headline';
import Link from '$shared/components/link';
import MegaMenu from './mega-menu';
import { ifProp } from 'styled-tools';
import { breakpoints } from '$theme';
import { CSSProperties } from 'react';

export const StyledMainMenuNav = styled.nav(() => ({
    display: 'none',
    width: '100%',

    [breakpoints.md]: {
        // Switch from device to desktop design
        display: 'block',
    },
}));

type StyledMainNavHeadline = HeadlineProps & {
    isActive?: boolean;
    renderAsActive?: boolean;
    cursorType: CSSProperties['cursor'];
};
export const StyledHeadline = styled(Headline)<StyledMainNavHeadline>(
    ifProp(
        ({ isActive, renderAsActive }: StyledMainNavHeadline) => isActive || renderAsActive,
        ({ theme }) => ({
            color: `${theme.colors.blue}`,
        })
    ),
    ifProp(
        ({ cursorType }: StyledMainNavHeadline) => !!cursorType,
        ({ cursorType }) => ({
            cursor: cursorType,
        })
    )
);

export const StyledMegaMenu = styled(MegaMenu)();

export const StyledNavLink = styled(Link)(({ theme }) => ({
    display: 'inline-block',
    textDecoration: 'none',
    padding: `${theme.space[5]} ${theme.space[4]}`,

    ':hover': {
        [`${StyledHeadline}`]: {
            color: `${theme.colors.blue}`,
        },
    },
}));

export const StyledMainNavigationListItem = styled.li(({ theme }) => ({
    ':first-of-type': {
        [`${StyledNavLink}`]: {
            paddingLeft: 0,
        },
    },
    ':hover': {
        [`${StyledHeadline}`]: {
            color: `${theme.colors.blue}`,
        },
    },
}));
