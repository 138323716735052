import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Icon } from '../icon';
import { QuantityPicker } from '../quantity-picker';
import {
    StyledBasketLinebasket,
    StyledBasketLineGrid,
    StyledBasketLineName,
    StyledBasketLinePrice,
    StyledBasketLinePrimary,
    StyledBasketLineDelete,
    StyledBasketLineFavorites,
    StyledBasketLineTotalPrice,
    StyledBasketWrapper,
    StyledPriceStockWrapper,
    StyledBasketLineDiscounts,
    StyledQuantityAdditionalText,
    StyledBasketQuantityWrapper,
    StyledBasketLineSecondary,
    StyledBasketLineImage,
    StyledBasketLineItemNo,
    StyledOutletWrapper,
} from './style';
import { FavoritesToggle } from '$features/favorites/favoritesToggle';
import { QuantitySpinnerValue } from '../quantity-spinner/quantity-spinner';
import { StyledExtraServiceWrapper } from '../extra-services/style';
import { formatString, useTranslation } from '$shared/utils';
import { ExtraServicesItem } from '../extra-services/extra-services-item';
import { Stock } from '$features/stock/stock/stock';
import { ServiceType } from '../extra-services/service-types';
import Link from '../link';
import { Skeleton } from '../skeleton';
import { useBasketPage } from '$templates/pages/components/P300BasketPage/hooks/useBasketPage';
import { Badge, EBadgeType } from '../badge/badge';
import { ProductLineImage } from '../product-line/product-line-image';
import { Aspect4OrderLineViewModel, CalculatedBasketLineServiceViewModel } from '$order-models/*';
import { BasketLineItemWithSplit } from '../basket-list/basket-list';
import { useFetchPriceAndStock } from '$shared/hooks/use-fetch-price-and-stock';

export type BasketLineProps = {
    basketLine: Aspect4OrderLineViewModel;
    splitBasketLine?: BasketLineItemWithSplit;
};

export const BasketLine: FC<BasketLineProps> = memo(({ basketLine, splitBasketLine }) => {
    const { priceViewModel: priceAndStock } = basketLine;
    const { upsertBasket, isLoading: basketPageIsLoading } = useBasketPage();
    const { translate } = useTranslation();
    const [focusMinText, setFocusMinText] = useState(false);

    const [serviceSelection, setServiceSelection] = useState<
        CalculatedBasketLineServiceViewModel[]
    >(basketLine.serviceDetails || []);

    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        if (!basketPageIsLoading) {
            // reset loadingstate of this line, when the basket is done updating...
            setLoading(false);
        }
    }, [basketPageIsLoading]);

    // If a quote already exists the quantity will be set with the old quota data
    // Update quantity the value from basket is different from local
    useEffect(() => {
        if (quantity?.parsedValue && quantity.parsedValue !== basketLine.quantity) {
            setQuantity({
                isValid: true,
                parsedValue: basketLine.quantity || 1,
                value: `${basketLine.quantity}`,
            });
        }
    }, [basketLine, splitBasketLine]);

    const splitLinesQuantity = useMemo(() => {
        const otherLines = splitBasketLine?.lines.filter(
            (line) => line.deliveryEstimate !== basketLine.deliveryEstimate
        );
        let otherQuantity = 0;
        otherLines?.forEach((line) => (otherQuantity += line.quantity || 0));
        return otherQuantity;
    }, [splitBasketLine]);

    const [quantity, setQuantity] = useState<QuantitySpinnerValue>({
        isValid: true,
        parsedValue: basketLine.quantity || 1,
        value: `${basketLine.quantity}`,
    });
    const [prevQuantity, setPrevQuantity] = useState<QuantitySpinnerValue>();
    const debounceRef = useRef<NodeJS.Timeout>();

    const onQuantityBlur = () => {
        const minValue = splitBasketLine
            ? calcSplitLineMinQuantity(priceAndStock?.minimumOrderSize)
            : priceAndStock?.minimumOrderSize;
        if (quantity.parsedValue < (minValue || 0)) {
            animateMinText();
            if (prevQuantity && minValue && prevQuantity.parsedValue >= minValue) {
                setQuantity(prevQuantity);
            }
        }
    };

    const upsertBasketLine = (
        serviceSelection: CalculatedBasketLineServiceViewModel[],
        quantity: number,
        hasServiceUpdates?: boolean
    ) => {
        // Avoid upserting if product reqs are not met.
        if (
            ((quantity ===
                (splitBasketLine
                    ? calcSplitLineQuantity(basketLine.quantity || 0)
                    : basketLine.quantity) &&
                quantity !== 0) ||
                (quantity < (priceAndStock?.minimumOrderSize || 1) && quantity !== 0) ||
                quantity % (priceAndStock?.inSizesOf || 0) !== 0) &&
            !hasServiceUpdates
        ) {
            return;
        }

        // We are doing this on changes, so we do a debounce.
        debounceRef.current && clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
            setLoading(true);
            upsertBasket?.({
                quantity: quantity,
                sanitizedItemNumber: basketLine.sanitizedItemNumber as string,
                lineId: basketLine.basketLineId as string,
                services: serviceSelection.map((ss) => ({
                    selected: ss.selected,
                    serviceType: ss.serviceType,
                    textInput: ss.textInput,
                    serviceName: ss.serviceName,
                    sanitizedItemNumber: ss.sanitizedItemNumber,
                })),
            });
        }, 300);
    };

    const calcSplitLineQuantity = (quantity: number) => {
        return quantity + splitLinesQuantity;
    };

    const calcSplitLineMinQuantity = (minOrderSize?: number) => {
        if (!minOrderSize) {
            return undefined;
        }
        if ((splitBasketLine?.totalQuantity || 0) <= minOrderSize) {
            return minOrderSize;
        }
        const newMinQuantity = minOrderSize - splitLinesQuantity;
        return newMinQuantity ? newMinQuantity : minOrderSize;
    };

    const animateMinText = () => {
        setFocusMinText(true);
        setTimeout(() => setFocusMinText(false), 200);
    };

    const canonicalUrl = basketLine.canonicalProductUrl || '';

    const [canFetchPrices, setCanFetchPrices] = useState(false);

    const { mappedPrices, isLoading: loadingPriceAndStock } = useFetchPriceAndStock({
        primaryProductNumber: basketLine.itemNumber,
        sanitizedItemNumber: basketLine.sanitizedItemNumber,
        quantityValue: quantity.parsedValue,
        canFetch: canFetchPrices,
    });

    return (
        <>
            <StyledBasketLineGrid>
                <StyledBasketLineImage>
                    <ProductLineImage
                        imageUrl={basketLine.photoPath as string}
                        alt={basketLine.itemDescription as string}
                    />
                </StyledBasketLineImage>
                <StyledBasketLineName>
                    {basketLine?.promotedProduct && <Badge type={EBadgeType.PROMOTED_PRODUCT} />}
                    <StyledBasketLinePrimary href={canonicalUrl}>
                        {basketLine?.itemDescription}
                    </StyledBasketLinePrimary>
                    <StyledBasketLineSecondary>
                        <Link href={canonicalUrl} passHref>
                            {basketLine?.customerText2}
                        </Link>
                    </StyledBasketLineSecondary>
                    {!!basketLine?.customerText3 && <p>{basketLine?.customerText3}</p>}
                    {!!basketLine?.itemNumber && (
                        <StyledBasketLineItemNo>{basketLine?.itemNumber}</StyledBasketLineItemNo>
                    )}
                </StyledBasketLineName>
                {basketLine.unitPrice ? (
                    <>
                        <StyledBasketLinePrice>{basketLine.unitPricePretty}</StyledBasketLinePrice>
                        <StyledPriceStockWrapper>
                            <Stock
                                {...priceAndStock}
                                stock={{
                                    ...priceAndStock?.stock,
                                    deliveryEstimate: splitBasketLine
                                        ? basketLine.deliveryEstimate
                                        : basketLine.deliveryEstimate !==
                                          priceAndStock?.stock?.deliveryEstimate
                                        ? basketLine.deliveryEstimate
                                        : priceAndStock?.stock?.deliveryEstimate,
                                }}
                                showStock={
                                    splitBasketLine &&
                                    basketLine.deliveryEstimate !==
                                        priceAndStock?.stock?.deliveryEstimate
                                        ? false
                                        : priceAndStock?.showStock
                                }
                                units={basketLine.unitDescription || ''}
                            />
                            <StyledBasketLineTotalPrice>
                                {isLoading || (isLoading && basketPageIsLoading) ? (
                                    <Skeleton pulsating height={'1.2em'} width={'100px'} />
                                ) : (
                                    basketLine.totalPricePretty
                                )}
                            </StyledBasketLineTotalPrice>
                        </StyledPriceStockWrapper>
                        <StyledBasketWrapper>
                            <StyledBasketLineFavorites>
                                <FavoritesToggle
                                    sanitizedItemNumber={basketLine.sanitizedItemNumber as string}
                                />
                            </StyledBasketLineFavorites>
                            <StyledBasketLinebasket>
                                <StyledBasketQuantityWrapper
                                    onMouseOver={() => setCanFetchPrices(true)}
                                >
                                    <QuantityPicker
                                        value={quantity.value}
                                        min={
                                            splitBasketLine
                                                ? calcSplitLineMinQuantity(
                                                      priceAndStock?.minimumOrderSize
                                                  )
                                                : priceAndStock?.minimumOrderSize
                                        }
                                        step={priceAndStock?.inSizesOf}
                                        unit={basketLine.unitDescription || ''}
                                        onChange={(e) => {
                                            setPrevQuantity(quantity);
                                            setQuantity(e);
                                            upsertBasketLine(
                                                serviceSelection,
                                                splitBasketLine
                                                    ? calcSplitLineQuantity(e.parsedValue)
                                                    : e.parsedValue
                                            );
                                        }}
                                        prices={mappedPrices ?? []}
                                        onBlur={onQuantityBlur}
                                        disableDiscountBelowMin
                                        loadingData={canFetchPrices && loadingPriceAndStock}
                                    />
                                </StyledBasketQuantityWrapper>

                                <StyledBasketLineDelete
                                    onClick={() =>
                                        splitBasketLine
                                            ? upsertBasketLine(
                                                  serviceSelection,
                                                  calcSplitLineQuantity(0)
                                              )
                                            : upsertBasketLine([], 0)
                                    }
                                >
                                    <Icon icon="trash" color="black" size="md" />
                                </StyledBasketLineDelete>
                            </StyledBasketLinebasket>
                        </StyledBasketWrapper>

                        <StyledQuantityAdditionalText toggleWiggle={focusMinText}>
                            {(priceAndStock?.inSizesOf || 0) > 1 &&
                                formatString(
                                    translate('product-detail-page.core-data.in-sizes-of'),
                                    priceAndStock?.inSizesOf,
                                    basketLine.unitDescription || ''
                                )}
                            {(priceAndStock?.boxCount || 0) > 0 &&
                                formatString(
                                    translate('product-detail-page.core-data.in-sizes-of'),
                                    priceAndStock?.boxCount,
                                    translate('product-detail-page.core-data.stock-pieces')
                                )}

                            {(priceAndStock?.minimumOrderSize || 0) > 1 &&
                                (priceAndStock?.inSizesOf || 0) <= 1 &&
                                formatString(
                                    translate('product-detail-page.core-data.minimum-quantity'),
                                    priceAndStock?.minimumOrderSize,
                                    basketLine.unitDescription || ''
                                )}
                            {basketLine?.promotedProduct && (
                                <p>{translate('shared.quantity.while-stock-lasts')}</p>
                            )}
                        </StyledQuantityAdditionalText>
                    </>
                ) : (
                    <StyledOutletWrapper>
                        <p>{translate('basket.contact-for-price')}</p>
                    </StyledOutletWrapper>
                )}
            </StyledBasketLineGrid>
            {!!serviceSelection?.length && (
                <StyledBasketLineDiscounts>
                    <StyledExtraServiceWrapper hasArrow isBusy={false} productLineLayout={false}>
                        <p>{translate('product-detail-page.core-data.extra-services')}</p>
                        <ul>
                            {serviceSelection?.map((service, index) => (
                                <ExtraServicesItem
                                    text={service.serviceName as string}
                                    presetValue={service.textInput as string}
                                    enabled={true}
                                    editable={
                                        (service.serviceType as ServiceType) === 'CheckboxAndString'
                                    }
                                    onSelectionChange={(selected, textInput) => {
                                        const anyUpdate =
                                            (!!textInput &&
                                                textInput !== (service.textInput as string)) ||
                                            (service.serviceType as ServiceType) === 'Checkbox';

                                        const updatedServiceSelection = [...serviceSelection];
                                        updatedServiceSelection[index].selected = selected;
                                        updatedServiceSelection[index].textInput = textInput;

                                        setServiceSelection(updatedServiceSelection);
                                        if (anyUpdate || !selected) {
                                            const servicesHasUpdates = true;
                                            upsertBasketLine(
                                                updatedServiceSelection,
                                                quantity.parsedValue,
                                                servicesHasUpdates
                                            );
                                        }
                                    }}
                                    selected={!!service.selected}
                                    price={service.formattedPrice as string}
                                    key={index}
                                />
                            ))}
                        </ul>
                    </StyledExtraServiceWrapper>
                </StyledBasketLineDiscounts>
            )}
        </>
    );
});
