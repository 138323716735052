import { StyledExtraServiceWrapper } from '$shared/components/extra-services/style';
import { breakpoints, theme } from '$theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const StyledGenericProfileGrid = styled.div(() => ({
    display: 'grid',
    gap: theme.space.gutter,
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateAreas: `"header header header"
                        "search search search"
                        "list list list"
                        " button button button "
                   `,

    [breakpoints.md]: {
        gridTemplateColumns: '1fr 0.5fr 1fr',
        gridTemplateAreas: `"header header search"
                            "list list list"
                            ". button ."
                       `,
    },
}));
export const StyledProfileHeadline = styled.div(() => ({
    gridArea: 'header',
    alignSelf: 'center',
}));

export const StyledProfileSearch = styled.div(() => ({
    gridArea: 'search',
}));

export const StyledProfileList = styled.div(() => ({
    gridArea: 'list',
}));

export const StyledProfileButton = styled.div(() => ({
    gridArea: 'button',
    textAlign: 'center',
}));

export const StyledGenericList = styled.ul({
    '> li': {
        width: '100%',
        color: theme.colors.grey90,

        [`${StyledExtraServiceWrapper}`]: {
            padding: theme.space['4.5'],
        },

        '&:nth-of-type(2n+3)': {
            backgroundColor: theme.colors.grey20,
        },
        '&:nth-of-type(2n)': {
            backgroundColor: theme.colors.white,
        },
        '&:first-of-type': {
            borderBottom: `solid 1px ${theme.colors.grey30}`,
        },
    },
});

type ActiveProps = {
    isActive?: boolean;
};
export const StyledToggleButton = styled.button({
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.colors.grey90,
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
    paddingRight: theme.space[4],
    paddingLeft: 0,
});

export const StyledIconWrapper = styled.div<ActiveProps>(
    {
        display: 'flex',
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        svg: { color: theme.colors.grey90 },
    },
    ifProp('isActive', {
        transform: 'rotate(180deg)translateY(50%)',
        transitionDuration: '.2s',
    })
);
