import { TranslationKey } from '~/lib/data-contract';
import { useCallback } from 'react';
import { usePage } from '$templates/pages';
import { useQuery } from '@tanstack/react-query';
import { cancellableFetch } from '$shared/utils/request';
import { Translations } from '../models/translations';
import { buildURL } from '../../request';

const { API_URL } = process.env;
const clientTranslationUrl = '/api/proxy/content/translations';
const TRANSLATION_QUERY_KEY = 'createTranslationQueryOptions';
const serverTranslationUrl = API_URL + '/api/content/translations';

interface UseTranslation {
    translate: (key: TranslationKey) => string;
}

export function useTranslation(): UseTranslation {
    const { culture } = usePage();
    const { data } = useQuery([TRANSLATION_QUERY_KEY, culture], () => {
        return cancellableFetch<Translations>({ url: clientTranslationUrl });
    });

    const translate = useCallback(
        (key: TranslationKey) => {
            const translation = data?.[key] ?? '';
            if (!translation) console.warn(`Missing translation: ${key}`);
            return translation;
        },
        [data]
    );

    return { translate };
}

export type TranslationQueryOptions = [[string, string], () => Promise<Translations>];

function createTranslationQueryOptions(
    translationUrl: string,
    culture: string,
    httpHeaders?: HeadersInit
): TranslationQueryOptions {
    const serializedUrl = buildURL(translationUrl, { culture });
    const options = httpHeaders ? { headers: httpHeaders } : undefined;
    const queryKey: [string, string] = [TRANSLATION_QUERY_KEY, culture];
    const request = () => cancellableFetch<Translations>({ url: serializedUrl, options });

    return [queryKey, request];
}

export function createTranslationQueryOptionsForServer(
    culture: string,
    httpHeaders: HeadersInit
): TranslationQueryOptions {
    return createTranslationQueryOptions(serverTranslationUrl, culture, httpHeaders);
}
