import styled from '@emotion/styled';
import Link, { LinkProps } from '$shared/components/link';
import Headline from '$shared/components/headline';
import { Icon } from '$shared/components/icon';
import { ifProp } from 'styled-tools';
import { CSSProperties } from 'react';

export const StyledHeadline = styled(Headline)();
export const StyledIcon = styled(Icon)(({ theme }) => ({
    flexShrink: 0,
    marginLeft: `${theme.space[4]}`,
}));
export const StyledSubNavList = styled.ul(({ theme }) => ({
    flexShrink: 0,
    width: '250px',
    padding: `${theme.space[5]} 0`,
    borderRight: `1px solid ${theme.colors.borderColor}`,
}));
export const StyledSubNavListItem = styled.li(() => ({}));

type StyledSubNavLinkProps = LinkProps & {
    active?: boolean;
    cursorType?: CSSProperties['cursor'];
};
export const StyledSubNavLink = styled(Link)<StyledSubNavLinkProps>(
    ({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.space[4]} 0`,
        paddingRight: `${theme.space[7]}`,

        ':hover': {
            textDecoration: 'none',

            [`${StyledHeadline}`]: {
                color: `${theme.colors.blue}`,
            },
            [`${StyledIcon}`]: {
                path: {
                    fill: `${theme.colors.blue}`,
                },
            },
        },
    }),
    ifProp('active', ({ theme }) => ({
        [`${StyledHeadline}`]: {
            color: `${theme.colors.blue}`,
        },
        [`${StyledIcon}`]: {
            path: {
                fill: `${theme.colors.blue}`,
            },
        },
    })),
    ifProp(
        ({ cursorType }: StyledSubNavLinkProps) => !!cursorType,
        ({ cursorType }) => ({
            cursor: cursorType,
        })
    )
);
