/**
 * Generate unique key from objects,
 * can be used when no unique key is present on dataset
 */
 const generateKey = (): (<T>(obj: T) => string) => {
    const map = new WeakMap();
    let index = 0;

    const weakKey = <T extends Record<string, unknown>>(obj: T): string => {
        let key = map.get(obj);

        if (!key) {
            key = `key-${index++}`;
            map.set(obj, key);
        }
        return JSON.stringify(key);
    };

    return weakKey as <T>(obj: T) => string;
};

export const weakKey = generateKey();
